import type {ExternalsMap, ExternalsBuilderMap, Pointer} from '@wix/document-services-types'
import type {ExternalsHandler} from '../../constants'
import type {DetailedValue, ExtendedRefInfo} from '@wix/import-export-utils'
import type {DalValue} from '@wix/document-manager-core'
import _ from 'lodash'
import {getNewItemRef} from '../../utils'

export const defaultRefsHandler: ExternalsHandler = {
    pack: (ref: DetailedValue, externals: ExternalsBuilderMap, refPointer: Pointer) =>
        externals.nonOwnedRefs.add(refPointer.id),
    unpack: (ref: DetailedValue, externals: ExternalsMap, refFieldInfo: ExtendedRefInfo, item: DalValue) => {
        const newId = getNewItemRef(ref.value, refFieldInfo, externals.externalRefs)
        _.set(item, ref.path, newId)
    }
}
