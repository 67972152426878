import _ from 'lodash'
import type {DocumentServicesObject} from '@wix/document-services-types'
import type {OverlayManager} from '../overlayManager/overlayManager'
import type {DAL} from '@wix/document-manager-core'
import type {MobileAlgoApi} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/mobileAlgo'
import {NEW_ALGO} from '../constants'
import {getSections} from './utils/getSections'
import type {StructureIterators} from '@wix/document-manager-extensions/dist/src/extensions/mobileAlgo/types'

const displayOrderIndicationsNew = (overlayManager: OverlayManager, structureIterators: StructureIterators) => {
    structureIterators.forEachComponent(component => {
        _.forEach(component.components, (child, index) => {
            overlayManager.markComponent([child], NEW_ALGO, `new-algo:${index}`, 'yellow', '0', '')
        })
    })
}

const displayOrder = async (mobileAlgo: MobileAlgoApi, sections: string[], overlayManager: OverlayManager) => {
    for (const id of sections) {
        const ctx = mobileAlgo.context.create(['grouping', 'order'])
        const {structureIterators} = await mobileAlgo.runWithContext({id, type: 'DESKTOP'}, ctx)
        displayOrderIndicationsNew(overlayManager, structureIterators)
    }
}

export const displayOrderSingleSection = async (
    mobileAlgo: MobileAlgoApi,
    sectionId: string,
    overlayManager: OverlayManager
) => {
    await displayOrder(mobileAlgo, [sectionId], overlayManager)
}

export const displayOrderAllSections = async (
    documentServices: DocumentServicesObject,
    mobileAlgo: MobileAlgoApi,
    overlayManager: OverlayManager,
    dal: DAL
) => {
    const currentPageSections = getSections(dal, documentServices.pages.getCurrentPageId())
    await displayOrder(mobileAlgo, currentPageSections, overlayManager)
}
