import type {PublicMethodDefinition, PublicMethodUtils, PS} from '@wix/document-services-types'
import _ from 'lodash'
import santaCoreUtils from '@wix/santa-core-utils'
import {bi, biEvents} from '@wix/document-services-implementation'

export interface Deprecation {
    wrapDeprecatedFunction(
        privateServices: PS,
        methodDef: PublicMethodDefinition,
        methodName?: string
    ): PublicMethodDefinition
    warn(ps: PS, methodDef: PublicMethodDefinition, methodName: string): void
    setThrowOnDeprecation(ps: PS, newVal: boolean): void
}

export function deprecationUtil(publicMethodUtils: PublicMethodUtils): Deprecation {
    const {isDeprecated, wrap} = publicMethodUtils

    const wrapDeprecated = (ps: PS, methodDef: PublicMethodDefinition, methodName: string) =>
        wrap(methodDef, (next: Function) => {
            warn(ps, methodDef, methodName)
            return next()
        })

    const limited = new Map()

    const reachedLimit = (limit: number, methodName: string): boolean => {
        if (!limited.has(methodName)) {
            limited.set(methodName, 1)
            return false
        }
        const current = limited.get(methodName)
        if (current >= limit) {
            return true
        }
        limited.set(methodName, current + 1)
        return false
    }

    function reportBI(ps: PS, methodName: string) {
        const dsOrigin = ps.config.origin
        const params = {
            methodName,
            dsOrigin
        }
        bi.event(ps, biEvents.DEPRECATED_PUBLIC_API_CALL, params)
    }

    /**
     * @param {ps} ps
     * @param methodDef
     * @param methodName
     */
    function warn(ps: PS, methodDef: PublicMethodDefinition, methodName: string) {
        const {deprecationOptions} = methodDef
        const deprecationMessage = `${deprecationOptions!.message}|${methodName}`
        if (ps.runtimeConfig.shouldThrowOnDeprecation) {
            throw new Error(`DocumentServices|Deprecation|${deprecationMessage}`)
        }
        if (reachedLimit(deprecationOptions!.limit, methodName)) {
            return
        }
        reportBI(ps, methodName)
        santaCoreUtils.log.warnDeprecation(deprecationMessage)
    }

    return {
        wrapDeprecatedFunction(privateServices: PS, methodDef: PublicMethodDefinition, methodName: string) {
            if (isDeprecated(methodDef)) {
                return wrapDeprecated(privateServices, methodDef, methodName)
            }
            return methodDef
        },
        warn,
        setThrowOnDeprecation(ps: PS, newVal: boolean) {
            _.set(ps, ['runtimeConfig', 'shouldThrowOnDeprecation'], newVal)
            ps.extensionAPI.deprecation.setShouldThrowOnDeprecation(newVal)
        }
    }
}
