import {
    createStoreFromJS,
    DalJsStore,
    DalValue,
    DalValueChangeCallback,
    DocumentManager,
    setDebug,
    SnapshotDal
} from '@wix/document-manager-core'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import {
    Action,
    constants,
    extensions,
    GetTransactionsResponse,
    RMApi,
    Transaction
} from '@wix/document-manager-extensions'
import type {DocumentServicesObject, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {downloadGraphDataForChad, openGraphWindow} from '../dot'
import {graphComponents as graphComps, OptionalGraphArgs} from '../graphComponents'
import type {Adapter} from './adapter'
import {componentHierarchy, registerForDalValueChanges, StateComparer, stripSignatures} from './debugUtils'
import {Httm} from './httm'
import {organise} from './orderedMergedStore'
import {createMobileAlgoDebugTools} from './mobileAlgoDebugTools/mobileAlgoDebugTools'

type CSaveApi = extensions.continuousSave.CSaveApi['continuousSave']
type Relationships = extensions.relationships.RelationshipsAPI['relationships']

const {VIEW_MODES, PAGE_DATA_TYPES} = constants

interface HistoryItem {
    sig: string
    based: string
    local: boolean
    id: string
    value: any
    s: SnapshotDal
}

export interface ShowTransactionArgs {
    namespaceWhitelist?: string[]
    idWhiteList?: string[]
    valueFilter?(v: DalValue): boolean
    fromLastRevision?: boolean
    afterTxId?: string | undefined
    untilTxId?: string | undefined
    branchId?: string | undefined
}

export function createDebug(documentManager: DocumentManager) {
    const {dal} = documentManager

    const getCreationTime = (id: string) => {
        const parts = id.split('-')
        const timeGeneratedPostfix = _.last(parts)!.slice(0, 8) //sometimes we have a counter added at the end of ids, we want only the first 8
        const milli = parseInt(timeGeneratedPostfix, 36)
        if (!isNaN(milli) && parts.length > 1 && timeGeneratedPostfix.length === 8) {
            const date = new Date()
            date.setTime(milli)
            return date
        }
        return 'Unable to find object generation time based on id'
    }
    const dataStores = (_.values(VIEW_MODES) as string[]).concat(_.keys(PAGE_DATA_TYPES))

    const getCreationTimeTable = () =>
        _(dal._getCommittedStoreAsJson())
            .pick(dataStores)
            .reduce((byTime, dataMap) => {
                _.forEach(dataMap, (v, k) => {
                    byTime[k] = getCreationTime(k)
                })
                return byTime
            }, {})

    const getItemHistory = (pointer: Pointer) => {
        let snapshot = dal._snapshots.last()
        const historyResult: HistoryItem[] = []
        while (snapshot) {
            const snapshotStore = snapshot.getStore()
            if (snapshotStore.has(pointer)) {
                const value = snapshotStore.get(pointer)
                historyResult.push({
                    sig: _.get(value, ['metaData', 'sig']),
                    based: snapshotStore.get(dal.getBasedOnSignaturePointer(pointer)),
                    local: !snapshot._isForeign,
                    id: snapshot.id,
                    value,
                    s: snapshot
                })
            }
            snapshot = snapshot._previousSnapshot
        }
        return _.reverse(historyResult)
    }

    const csave = (): CSaveApi => documentManager.extensionAPI.continuousSave as CSaveApi

    const rejectNext = () => {
        csave().rejectNext()
    }

    const getAllSnapshots = () => {
        const tagToId = {
            ..._.mapValues(dal._getAllTags(), _.last),
            '>>>> Last Approved <<<<': dal.getLastApprovedSnapshot().id
        }

        const calcSnapshotSize = (snapshot: SnapshotDal) => {
            let size = 0
            snapshot._snapshotStore._store.forEach(map => {
                size += map.size
            })
            return size
        }

        return _.reduce(
            dal._snapshots.toArray(),
            (res, snapshot, index) => {
                res[Number(index).toString().padStart(2, '0')] = {
                    id: _.chain(snapshot.id.substring(8)).truncate({length: 16}).padEnd(16, '.').value(),
                    size: calcSnapshotSize(snapshot),
                    value: snapshot,
                    tags: _(tagToId)
                        .pickBy(v => v === snapshot.id)
                        .keys()
                        .join(', ')
                }
                return res
            },
            {}
        )
    }

    const relationships = (): Relationships => documentManager.extensionAPI.relationships as Relationships

    const searchTransactionsBy = (transactions: Transaction[], filter: (action: Action) => boolean) =>
        _(transactions)
            .mapValues(transaction => ({...transaction, actions: _.filter(transaction.actions, filter)}))
            .omitBy(({actions}) => _.isEmpty(actions))
            .value()

    const searchTransactions = (transactions: Transaction[], itemId: string, deep: boolean) => {
        const itemRef = `#${itemId}`

        const deepSearch = (toScanInternal: any): boolean => {
            if (_.isObject(toScanInternal)) {
                if (toScanInternal[itemId]) {
                    return true
                }
                return _.some(_.values(toScanInternal), deepSearch)
            }
            if (_.isArray(toScanInternal)) {
                return _.some(_.values(toScanInternal), deepSearch)
            }
            return toScanInternal === itemId || toScanInternal === itemRef
        }
        const shallowSearch = (action: Action) => {
            const {id, value} = action
            if (itemId === id) {
                return true
            }
            return _.some(_.values(value), internalValue => internalValue === itemRef || internalValue === itemId)
        }
        const searchFunc = deep ? deepSearch : shallowSearch

        return searchTransactionsBy(transactions, searchFunc)
    }

    const addTransaction = (change: DalJsStore) => {
        dal.rebase(createStoreFromJS(change), dal.getLastApprovedSnapshot().id, 'debug')
    }

    /**
     * Log all dal value changes for the given namespace or namespaces. Only the dal value properties that changed will be shown with their old and new values
     * @param namespaceWhitelist - only show changes in these namespaces or all namespaces if this argument is not provided
     */
    const logDalValueChangesForNamespaces = (namespaceWhitelist?: string | string[]) => {
        registerForDalValueChanges(dal, namespaceWhitelist)
    }

    // Log all dal value changes for the given id or ids. Only the dal value properties that changed will be shown with their old and new values
    const logDalValueChangesForIds = (idWhiteList: string | string[]) => {
        registerForDalValueChanges(dal, undefined, idWhiteList)
    }

    const getComponentsByType = (compType: string, viewMode: string) => {
        compType = compType.toLowerCase()
        return _.pickBy(dal._getMergedStoreAsJson()[viewMode], val =>
            val?.componentType?.toLowerCase().includes(compType)
        )
    }

    const stateComparer = new StateComparer(dal)

    const showTransactions = async (args: ShowTransactionArgs = {}) => {
        const defaultArgs: ShowTransactionArgs = {
            namespaceWhitelist: [],
            idWhiteList: [],
            valueFilter: () => true,
            fromLastRevision: true,
            afterTxId: undefined,
            untilTxId: undefined,
            branchId: undefined
        }
        const allArgs = _.mapValues(defaultArgs, (val, key) => args[key] ?? val)

        console.log(
            `%cshowTransactions Options:\n    ${Object.entries(allArgs)
                .map(e => `${e[0]}: ${e[1]}`)
                .join('\n    ')}`,
            'font-size: 1.2em; color: lightblue'
        )

        const {namespaceWhitelist, idWhiteList, valueFilter, fromLastRevision, afterTxId, untilTxId, branchId} = allArgs

        const tx: GetTransactionsResponse = await (fromLastRevision && !afterTxId
            ? csave().getTransactionsFromLastRevision(untilTxId, branchId)
            : csave().getTransactions(afterTxId, untilTxId, branchId))

        tx.transactions!.forEach((transaction: Transaction) => {
            const filter = (action: Action): boolean => {
                const {namespace, id, value} = action
                return !(
                    (namespaceWhitelist.length && !namespaceWhitelist.includes(namespace!)) ||
                    (idWhiteList.length && !idWhiteList.includes(id!)) ||
                    !valueFilter(value)
                )
            }
            const {dateCreated, actions, transactionId} = transaction
            if (!actions!.find(filter)) {
                return
            }
            console.log(
                `%c----- ${transactionId}   ${new Date(dateCreated!).toUTCString()} -----`,
                'font-size: 1.3em; font-family: courier'
            )
            actions!.forEach((action: Action) => {
                if (!filter(action)) {
                    return
                }
                const {op, namespace, id, value} = action
                const ptr = `{ ${namespace} : ${id} }`
                switch (op) {
                    case 'REPLACE':
                        console.log(`%cCHANGED ${ptr}`, 'color: yellow', value)
                        break
                    case 'REMOVE':
                        console.log(`%cREMOVED ${ptr}`, 'color: red')
                        break
                    case 'ADD':
                        console.log(`%cADDED ${ptr}`, 'color: lime', value)
                        break
                }
            })
        })
    }

    const openRevision = (revision: string, untilTransactionId: string) => {
        const {rendererModel} = documentManager.extensionAPI as RMApi
        const metaSiteId = rendererModel.getMetaSiteId()
        const siteId = rendererModel.getSiteId()
        const url = `https://editor.wix.com/html/editor/web/renderer/revisions/view/${siteId}/${revision}?metaSiteId=${metaSiteId}&debug=dm&isEdited=true&dsOrigin=Editor1.4&disableSave=true&untilTransactionId=${untilTransactionId}`
        _.invoke(window, ['open'], url, '_blank').focus()
    }

    const getDisplayedStructure = () => {
        const viewerManager = window.dsAdapter.host.viewerManager as ViewerManager
        const viewMode = viewerManager.viewerSiteAPI.getViewMode()
        const pagePointer = {
            id: viewerManager.viewerSiteAPI.getFocusedRootId(),
            type: viewMode
        }
        const bfsQueue = [pagePointer]
        const allComps = {}
        const allData = {}
        while (bfsQueue.length > 0) {
            const currentPtr = bfsQueue.shift()!
            const currentNode = viewerManager.dal.get(currentPtr)
            allComps[currentPtr.id] = currentNode

            if (currentNode.dataQuery) {
                const dataItemId = currentNode.dataQuery.replace('#', '')
                const dataItemPtr = {id: dataItemId, type: 'data'}
                const dataItem = viewerManager.dal.get(dataItemPtr)
                allData[dataItemId] = dataItem
            }

            // eslint-disable-next-line lodash/prefer-map
            _(currentNode.components)
                .map(compId => ({id: compId, type: currentPtr.type}))
                .filter(compPtr => !allComps[compPtr.id])
                .forEach(compPtr => bfsQueue.push(compPtr))
        }
        return organise({
            DESKTOP: {},
            MOBILE: {},
            [viewMode]: allComps,
            data: allData
        })
    }

    return {
        dal,
        extensionAPI: documentManager.extensionAPI,
        pointers: documentManager.pointers,
        getApprovedStore: () => dal._getApprovedStoreAsJson(),
        getTentativeStore: () => dal._getTentativeStoreAsJson(),
        getCommittedStore: () => dal._getCommittedStoreAsJson(),
        getMergedStore: () => dal._getMergedStoreAsJson(),
        getCreationTimeTable,
        getCreationTime,
        setDebug,
        getItemHistory,
        rejectNext,
        showTransactions,
        getTransactions: async (afterTransactionId?: string, untilTransactionId?: string, branchId?: string) =>
            await csave().getTransactions(afterTransactionId, untilTransactionId, branchId),
        searchTransactions,
        searchTransactionsBy,
        getAndSearchTransactions: async (itemId: string, deep: boolean = false, afterTransactionId?: string) => {
            const transactions = await (afterTransactionId
                ? csave().getTransactions(afterTransactionId)
                : csave().getTransactionsFromLastRevision())
            const results = searchTransactions(transactions.transactions!, itemId, deep)
            console.log(results)
            return results
        },
        getStore: async (branchId?: string, afterTransactionId?: string, untilTransactionId?: string) =>
            await csave().getStore(branchId, afterTransactionId, untilTransactionId),
        getTransactionsFromLastRevision: async (untilTransactionId: string, branchId?: string) =>
            await csave().getTransactionsFromLastRevision(untilTransactionId, branchId),
        getAllSnapshots,
        httm: async () => {
            const tx: GetTransactionsResponse = await csave().getTransactionsFromLastRevision(undefined, undefined)
            const instance = new Httm(dal, tx.transactions!)
            instance.showStatus()
            return instance
        },
        stripSignatures,
        logDalValueChangesForNamespaces,
        logDalValueChangesForIds,
        stateComparer,
        desktopCompsByType: (compType: string) => getComponentsByType(compType, VIEW_MODES.DESKTOP),
        mobileCompsByType: (compType: string) => getComponentsByType(compType, VIEW_MODES.MOBILE),
        desktopHierarchy: (compId: string, fields: string[] | string = ['id', 'type', 'componentType']) =>
            componentHierarchy(dal, VIEW_MODES.DESKTOP, compId, fields),
        mobileHierarchy: (compId: string, fields: string[] | string = ['id', 'type', 'componentType']) =>
            componentHierarchy(dal, VIEW_MODES.MOBILE, compId, fields),
        registerForDalChanges: (callback: DalValueChangeCallback) => dal.registrar.registerForChangesCallback(callback),
        unregisterForDalChanges: (callback: DalValueChangeCallback) =>
            dal.registrar.unregisterForChangesCallback(callback),
        dalValue: (id: string, namespace: string = 'DESKTOP') => _.cloneDeep(dal.get({type: namespace, id})),
        addTransaction,
        graph: (pointer: Pointer) => {
            if (pointer.type === 'MOBILE') {
                throw new Error('MOBILE not supported')
            }
            openGraphWindow(pointer, dal, relationships())
        },
        graphComponents: (pointers: Pointer | Pointer[], optionalArgs: OptionalGraphArgs = {}) => {
            graphComps(dal, relationships(), pointers, optionalArgs)
        },
        downloadGraphDataForChad: () => downloadGraphDataForChad(dal, relationships()),
        openRevision,
        orderedMergedStore: () => organise(dal._getMergedStoreAsJson()),
        getDisplayedStructure,
        mobileAlgoDebugTools: createMobileAlgoDebugTools(documentManager)
    }
}

const init = (documentManager: DocumentManager) => {
    window.dsDebug = createDebug(documentManager)
}

const registerReady = (adapter: Adapter, documentServices: DocumentServicesObject) => {
    if (!window.dsDebug) {
        throw new Error('')
    }

    const {viewerApiTrace} = adapter.host.viewerManager
    const {documentManager} = adapter.host
    const {ps} = adapter
    const adapterDebug = {
        viewerApiTrace,
        ps,
        documentManager
    }

    const dsAdapter = _.assign({}, adapterDebug, adapter, window.dsDebug, {documentServices})
    window.dsAdapter = dsAdapter
    if (['editor_x', 'Editor1.4', 'responsiveBlocks', 'studio'].includes(adapter.config.origin) && window.parent) {
        window.parent.dsAdapter = dsAdapter
    }
}

export {init, registerReady}
