import type {PublicMethodUtils, PS} from '@wix/document-services-types'
import {
    component,
    dataModel,
    componentsMetaData,
    mobileActions,
    mobileConversionFacade,
    menuContainer,
    mobilePresetsHandler,
    mobileHintsValidator,
    mergeAggregator,
    quickActionBarManager,
    mobileSettings,
    quickActions,
    quickActionsVersionUtils
} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {deprecate, extensionPublicAPI} = publicMethodUtils
    const {dataManipulation: extensionDataManipulation} = extensionPublicAPI
    return {
        methods: {
            components: {
                properties: {
                    mobile: {
                        fork: publicMethodUtils.defineDataManipulationAction(component.properties.mobile.fork),
                        join: publicMethodUtils.defineDataManipulationAction(component.properties.mobile.join),
                        isForked: publicMethodUtils.defineGetter(component.properties.mobile.isForked)
                    }
                },
                modes: {
                    updateMobileDisplayedModeProperty: publicMethodUtils.defineDataManipulationAction(
                        mobileActions.setComponentDisplayMode,
                        {
                            noBatchingAfter: true
                        }
                    ),
                    getCompMobileMode: publicMethodUtils.defineGetter(component.modes.getCompMobileMode)
                },
                mobileHints: {
                    update: publicMethodUtils.defineAction(dataModel.updateMobileHintsHidden),
                    get: publicMethodUtils.defineGetter(dataModel.getMobileHintsItem)
                },
                /**
                 * Returns mobile view configuration for a component
                 * @member documentServices.components.getMobileConversionConfig
                 * @param {AbstractComponent} componentReference
                 * @returns {Object|undefined}
                 */
                getMobileConversionConfig: publicMethodUtils.defineGetter(
                    componentsMetaData.public.getMobileConversionConfig
                )
            },
            mobile: {
                reLayoutPage: publicMethodUtils.defineDataManipulationAction(mobileActions.reLayoutPage, {
                    noBatching: true
                }),
                isOptimized: publicMethodUtils.defineGetter(mobileSettings.isOptimized),
                enableOptimizedView: publicMethodUtils.defineDataManipulationAction(mobileSettings.enableOptimizedView),
                mobileOnlyComponents: {
                    enableBackToTopButton: deprecate(
                        publicMethodUtils.defineDataManipulationAction(mobileActions.enableBackToTopButton),
                        'replaced with documentMode.enableBackToTopButton'
                    ),
                    toggleBackToTopButton: publicMethodUtils.defineDataManipulationAction(
                        mobileActions.toggleBackToTopButton
                    ),
                    isLegacyBackToTopButton: publicMethodUtils.defineGetter(mobileActions.isLegacyBackToTopButton),
                    isMobileOnlyComponent: publicMethodUtils.defineGetter(mobileActions.isMobileOnlyComponent),
                    isNativeMobileOnlyComponent: publicMethodUtils.defineGetter(
                        mobileActions.isNativeMobileOnlyComponent
                    ),
                    isMobileOnlyComponentExistOnStructure: publicMethodUtils.defineGetter(
                        mobileActions.isMobileOnlyComponentExistOnStructure
                    ),
                    mobileOnlyComps: mobileActions.mobileOnlyComps,
                    getTinyMenuDefaultPosition: publicMethodUtils.defineGetter(mobileActions.getTinyMenuDefaultPosition)
                },
                hiddenComponents: {
                    get: publicMethodUtils.defineGetter(mobileActions.hiddenComponents.getFiltered),
                    hide: publicMethodUtils.defineDataManipulationAction(mobileActions.hiddenComponents.hide, {
                        isUpdatingAnchors: true
                    }),
                    set: publicMethodUtils.defineDataManipulationAction(mobileActions.hiddenComponents.set, {
                        noBatching: true
                    }),
                    show: publicMethodUtils.defineDataManipulationAction(mobileActions.hiddenComponents.show, {
                        noBatching: true,
                        getReturnValue: mobileActions.getMobileComponentToShow
                    })
                },
                menuContainer: {
                    get: publicMethodUtils.defineGetter(menuContainer.get),
                    isExist: publicMethodUtils.defineGetter(menuContainer.isExist),
                    upgrade: publicMethodUtils.defineDataManipulationAction(menuContainer.upgrade),
                    toggle: {
                        get: publicMethodUtils.defineGetter(menuContainer.toggle.get)
                    }
                },
                actionBar: {
                    enable: publicMethodUtils.defineDataManipulationAction(quickActions.enable),
                    isEnabled: publicMethodUtils.defineGetter(quickActions.isEnabled),
                    version: {
                        get: publicMethodUtils.defineGetter(quickActionsVersionUtils.getVersion),
                        upgrade: publicMethodUtils.defineDataManipulationAction(quickActionsVersionUtils.upgrade),
                        revert: publicMethodUtils.defineDataManipulationAction(quickActionsVersionUtils.revert)
                    },
                    colorScheme: {
                        get: publicMethodUtils.defineGetter(quickActions.colorScheme.get),
                        set: publicMethodUtils.defineDataManipulationAction(quickActions.colorScheme.set)
                    },
                    actions: {
                        enable: publicMethodUtils.defineDataManipulationAction(quickActions.actions.enable),
                        getEnabled: publicMethodUtils.defineGetter(quickActions.actions.getEnabled),
                        update: publicMethodUtils.defineDataManipulationAction(quickActions.actions.update),
                        get: publicMethodUtils.defineGetter(quickActions.actions.get),
                        options: quickActions.QUICK_ACTIONS_PROPS,
                        socialLinksOptions: quickActions.SOCIAL_LINKS_IDS
                    }
                },
                text: {
                    implicitFontSize: publicMethodUtils.defineGetter(mobileActions.implicitFontSize)
                },
                qab: {
                    isEnabled: publicMethodUtils.defineGetter(quickActionBarManager.hasQuickActionBar),
                    get: publicMethodUtils.defineGetter(quickActionBarManager.getQuickActionBarPointer),
                    addAction: publicMethodUtils.defineDataManipulationAction(quickActionBarManager.addAction),
                    clearActions: publicMethodUtils.defineDataManipulationAction(quickActionBarManager.clearActions),
                    getActions: publicMethodUtils.defineGetter(quickActionBarManager.getActions)
                },
                preloader: {
                    enable: publicMethodUtils.defineDataManipulationAction(mobileSettings.preloader.enable),
                    isEnabled: publicMethodUtils.defineGetter(mobileSettings.preloader.isEnabled),
                    update: publicMethodUtils.defineDataManipulationAction(mobileSettings.preloader.update),
                    get: publicMethodUtils.defineGetter(mobileSettings.preloader.get),
                    options: mobileSettings.PRELOADER_PROPS
                }
            },
            mobileConversion: {
                syncMobileSite: publicMethodUtils.defineDataManipulationAction(mobileConversionFacade.syncMobileSite, {
                    isUpdatingAnchors: false,
                    noBatching: true
                }),
                resetMobileLayoutOnAllPages: publicMethodUtils.defineDataManipulationAction(
                    mobileConversionFacade.runMobileConversionOnAllPages,
                    {
                        isUpdatingAnchors: false,
                        noBatching: true
                    }
                ),
                runMobileMergeOnPageById: publicMethodUtils.defineDataManipulationAction(
                    mobileConversionFacade.runMobileMergeOnPageById,
                    {
                        isUpdatingAnchors: false,
                        noBatching: true
                    }
                ),
                runMobileMergeOnAllPagesWithoutLayoutOverwrite: publicMethodUtils.defineDataManipulationAction(
                    mobileConversionFacade.runMobileMergeOnAllPagesWithoutLayoutOverwrite,
                    {
                        isUpdatingAnchors: false,
                        noBatching: true
                    }
                ),
                createMobileHintsForPageComponents: publicMethodUtils.defineDataManipulationAction(
                    mobileConversionFacade.createMobileHintsForPageComponents
                ),
                resetModifiedByUserIndications: extensionDataManipulation('mobileHints.resetModifiedByUserIndications'),
                debugApi: {
                    getComponentsOrder: publicMethodUtils.defineGetter(mobileConversionFacade.getComponentsOrder)
                }
            },
            validate: {
                allMobileHints: publicMethodUtils.defineGetter(mobileHintsValidator.validateAllMobileHints)
            }
        },
        initMethod(ps: PS) {
            mobilePresetsHandler.initialize()
            mobileActions.initialize(ps)
            mergeAggregator.initialize(ps)
            mobileSettings.initialize(ps)
            mobileHintsValidator.initialize()
        }
    }
}
