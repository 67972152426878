import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import variantsUtils from '../variants/variantsUtils'
import constants from '../constants/constants'
import validations from './validations'
import metaDataUtils from '../componentsMetaData/metaDataUtils'
import hooks from '../hooks/hooks'
import {updateConsideringOverrides} from '../overrides/overrides'

const getResponsiveLayoutPointer = (ps: PS, compPointer: Pointer) =>
    variantsUtils.getComponentDataPointerConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)

export function getResponsiveLayout(ps: PS, compPointer: Pointer) {
    return variantsUtils.getComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)
}

export function updateResponsiveLayout(ps: PS, compPointer: CompRef, newLayout) {
    validations.validateSingleLayoutObject(newLayout)
    const compType = metaDataUtils.getComponentType(ps, compPointer)
    const layoutPointer = getResponsiveLayoutPointer(ps, compPointer)
    const modifiedNewLayout = hooks.executeHookAndUpdateValue(
        ps,
        hooks.HOOKS.RESPONSIVE_LAYOUT.BEFORE_UPDATE,
        compType,
        [compPointer, layoutPointer],
        newLayout
    )

    return updateConsideringOverrides(ps, compPointer, modifiedNewLayout, constants.DATA_TYPES.layout)
}
