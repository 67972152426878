import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ComponentAfterAddDataEvent} from '../hooks'
import type {ActionsAndBehaviorsExtensionAPI} from '../../actionsAndBehaviors'

export const removeBehaviorsFromAddedPage = (createExtArgs: CreateExtArgs, data: ComponentAfterAddDataEvent) => {
    const {compToAddPointer} = data
    const {extensionAPI} = createExtArgs
    const {actionsAndBehaviors} = extensionAPI as ActionsAndBehaviorsExtensionAPI
    actionsAndBehaviors.removeComponentsBehaviorsWithFilter(compToAddPointer, {behavior: {name: 'openPopup'}})
}
