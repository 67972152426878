const _ = require('lodash')

const PROPERTY_TO_REMOVE_PREFIX = '_'
function markPropertyToRemove(propName) {
    return `${PROPERTY_TO_REMOVE_PREFIX}${propName}`
}

function isPropertyToRemove(propName) {
    return _.startsWith(propName, PROPERTY_TO_REMOVE_PREFIX)
}

function removePropertyToRemovePrefix(cssProp) {
    return cssProp?.replace(PROPERTY_TO_REMOVE_PREFIX, '')
}

/**
 * @param {string} color
 */
function rgbToHex(color) {
    const rgbRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*([\d.]+))?\)$/
    const match = color.match(rgbRegex)

    const r = parseInt(match[1], 10)
    const g = parseInt(match[2], 10)
    const b = parseInt(match[3], 10)
    const a = match[4] !== undefined ? parseFloat(match[4]) : 1

    const hexR = r.toString(16).padStart(2, '0')
    const hexG = g.toString(16).padStart(2, '0')
    const hexB = b.toString(16).padStart(2, '0')
    const hexA = Math.round(a * 255)
        .toString(16)
        .padStart(2, '0')

    return `#${hexR}${hexG}${hexB}${a < 1 ? hexA : ''}`
}

/**
 * @param {string} value
 * @param {string} [delimiter=" "]
 * @returns {string[]}
 */
function splitWithRgb(value, delimiter = ' ') {
    const delimiterRegex = delimiter.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    const rgbSafeRegex = new RegExp(
        `(?:rgb(a)?\\(\\d{1,3},\\s*\\d{1,3},\\s*\\d{1,3}(?:,\\s*[\\d.]+)?\\)|[^${delimiterRegex}])+`,
        'g'
    )
    return value.match(rgbSafeRegex) ?? []
}

module.exports = {
    markPropertyToRemove,
    isPropertyToRemove,
    removePropertyToRemovePrefix,
    rgbToHex,
    splitWithRgb
}
