import type {CreateExtensionArgument, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef} from '@wix/document-services-types'

export type NicknameContextProvider = (compPointer: CompRef) => null | CompRef

export interface NicknameContextAPI {
    setContextProvider(provider: NicknameContextProvider): void
    getNicknameContext(compPointer: CompRef): CompRef | null
}

export type NicknameContextExtensionAPI = ExtensionAPI & {
    nicknameContext: NicknameContextAPI
}

const createExtension = ({}: CreateExtensionArgument): Extension => {
    let nicknameContextProvider: NicknameContextProvider
    const createExtensionAPI = (): NicknameContextExtensionAPI => {
        const getNicknameContext = (compPointer: CompRef) =>
            nicknameContextProvider ? nicknameContextProvider(compPointer) : null

        const setContextProvider = (provider: NicknameContextProvider) => {
            nicknameContextProvider = provider
        }
        return {
            nicknameContext: {
                setContextProvider,
                getNicknameContext
            }
        }
    }
    return {
        name: 'nicknameContext',
        dependencies: new Set(['dataModel', 'components']),
        createExtensionAPI
    }
}

export {createExtension}
