import type {ComponentSchemasDefinition, PS} from '@wix/document-services-types'
import componentHooksRegistrar from '../hooks/componentHooksRegistrar'
import {contextAdapter} from '../utils/contextAdapter'
import _ from 'lodash'
import {ReportableError} from '@wix/document-manager-utils'

const DEFAULT_OPTIONS = {
    registerComponentDefinitionAndSchemas: (
        ps: PS,
        compType: string,
        c: ComponentSchemasDefinition,
        allowOverrides: boolean
    ) => ps.extensionAPI.schemaAPI.registerComponentDefinitionAndSchemas(compType, c, allowOverrides), //wrapper function due to spies in dsCompRegistrar.spec
    registerSchemas: true
}

function informNoComponentType() {
    contextAdapter.utils.fedopsLogger.captureError(
        new ReportableError({
            errorType: 'invalidComponentRegistration',
            message: 'Could not dynamically register a component without componentType'
        }),
        {tags: {dsComponentRegistration: true, missingComponentType: true}}
    )
}

function informNoComponentDefinition(/*componentType*/) {
    // console.error(
    //     `Could not dynamically register ${componentType} as there is no component definition for this component!`
    // )
    // contextAdapter.utils.fedopsLogger.captureError(
    //     new ReportableError({
    //         errorType: 'invalidComponentRegistration',
    //         message: `Could not dynamically register ${componentType} as there is no component definition for this component!`
    //     }),
    //     {tags: {dsComponentRegistration: true, noComponentDefinition: true}}
    // )
}

function informUnauthorizedComponentOverride(componentType: string) {
    contextAdapter.utils.fedopsLogger.captureError(
        new ReportableError({
            errorType: 'invalidComponentRegistration',
            message: `Could not dynamically register ${componentType} as it is not externalizable`
        }),
        {tags: {dsComponentRegistration: true, notExternalizable: true}}
    )
}

const getRegistrarOptions = (ps: PS) => ({
    ...DEFAULT_OPTIONS,
    registerSchemas: ps.config.schemaDevMode
})

function registerComponent(ps: PS, component) {
    registerComponentWithOptions(ps, component, getRegistrarOptions(ps))
}

function registerComponentWithOptions(
    ps,
    {
        componentType,
        componentDefinition,
        hooks,
        metaData,
        dataSchema,
        propertiesSchema
    }: {componentType: string; componentDefinition; hooks; metaData; dataSchema; propertiesSchema},
    options = DEFAULT_OPTIONS
) {
    const {registerComponentDefinitionAndSchemas, registerSchemas} = {...DEFAULT_OPTIONS, ...options}

    if (!componentType) {
        informNoComponentType()
        return
    }

    const currentDefinition = ps.extensionAPI.schemaAPI.getDefinition(componentType)

    if (!currentDefinition && (!registerSchemas || !componentDefinition)) {
        informNoComponentDefinition(/*componentType*/)
        return
    }

    if (currentDefinition && !currentDefinition.externalizable) {
        informUnauthorizedComponentOverride(componentType)
        return
    }

    if (metaData) {
        contextAdapter.utils.fedopsLogger.breadcrumb(`registering metaData for component of type ${componentType}`)
        ps.extensionAPI.componentsMetadata.registrar.unregisterComponentMetadata(componentType)
        ps.extensionAPI.componentsMetadata.registrar.registerComponentMetadata(componentType, metaData)
    }

    if (hooks) {
        contextAdapter.utils.fedopsLogger.breadcrumb(`registering hooks for component of type ${componentType}`)
        componentHooksRegistrar.registerExternalHooks(componentType, hooks)
    }

    if (registerSchemas) {
        _.forEach(componentDefinition, compDef => {
            compDef.externalizable = true
        })
        registerComponentDefinitionAndSchemas(
            ps,
            componentType,
            {
                componentDefinition,
                dataSchemas: dataSchema,
                propertiesSchemas: propertiesSchema,
                componentType: undefined
            },
            registerSchemas
        )
    }
}

async function loadAndRegisterComponent(ps, componentType: string, loader, options) {
    const comp = await loader()
    registerComponentWithOptions(ps, {componentType, ...comp}, options)
}

async function registerComponentsFromExternalRegistryWithOptions(
    ps,
    registry,
    {libraries, mode},
    options: {registerSchemas?: boolean} = {registerSchemas: false}
) {
    const optionsWithDefaults = {...DEFAULT_OPTIONS, ...options}

    const asyncLoadOps = []
    registry({
        mode,
        libraries,
        registerComponent: (componentType: string, loader) => {
            const loadOp = loadAndRegisterComponent(ps, componentType, loader, optionsWithDefaults)
            asyncLoadOps.push(loadOp)
        }
    })

    await Promise.all(asyncLoadOps)
}

/** Register all components of an external registry
 *
 * The registry should comply with the interface specified in @wix/editor-elements-registry/2.0/documentManagement
 * Namely, the registry should provide a `default` method which accepts an object with a `registerComponent` method.
 * The registry will call `registerComponent` for each component it holds. It will pass as params the name
 * of the components and an async loader function that resolves to the component specification.
 * The component specification is the same object that `registerComponent` expects.
 *
 * @param registry
 * @param registryOptions
 * @returns {Promise<void>}
 */
async function registerComponentsFromExternalRegistry(ps, registry, registryOptions) {
    await registerComponentsFromExternalRegistryWithOptions(ps, registry, registryOptions, DEFAULT_OPTIONS)
}

export default {
    getRegistrarOptions,
    registerComponent,
    registerComponentWithOptions,
    registerComponentsFromExternalRegistry,
    registerComponentsFromExternalRegistryWithOptions
}
