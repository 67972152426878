import type {CompStructure, Pointer, PS} from '@wix/document-services-types'
import siteWidth from '../../structure/siteWidth/siteWidth'
import {convertCompToNewSiteWidth} from '../../structure/siteWidth/siteWidthConversion'

const convertCompToNewSiteWidthHook = (ps: PS, comp: Pointer, container: Pointer, compDefinition: CompStructure) => {
    if (ps.siteAPI.isMobileView()) return

    const componentSerializedSiteWidth = compDefinition?.siteWidth ?? 980
    const currentSiteWidth = siteWidth.getSiteWidth(ps)

    if (componentSerializedSiteWidth === currentSiteWidth) return

    return convertCompToNewSiteWidth(ps, comp, null, componentSerializedSiteWidth, currentSiteWidth)
}

export {convertCompToNewSiteWidthHook}
