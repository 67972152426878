const MOBILE_VARIANT_ID = 'MOBILE-VARIANT'

const generateId = (namespace, uniqueIdGenerator) => uniqueIdGenerator.getUniqueId(namespace, '-', {bucket: namespace})

const createDataItemAndAddToPage =
    (namespace, jsonDataNamespace, builder) =>
    ({pageJson, uniqueIdGenerator}, data) => {
        const id = generateId(namespace, uniqueIdGenerator)
        const dataItem = builder(id, data)
        pageJson.data[jsonDataNamespace][id] = dataItem
        return dataItem
    }
const createRefArray = (namespace, jsonDataNamespace) =>
    createDataItemAndAddToPage(namespace, jsonDataNamespace, id => ({
        id,
        type: 'RefArray',
        values: []
    }))
const createDataItem = (namespace, jsonDataNamespace) =>
    createDataItemAndAddToPage(namespace, jsonDataNamespace, (id, layoutData) => ({
        id,
        ...layoutData
    }))
const createMobileVariantRelation = (namespace, jsonDataNamespace) =>
    createDataItemAndAddToPage(namespace, jsonDataNamespace, (id, relationData) => {
        return {
            id,
            type: 'VariantRelation',
            variants: [`#${MOBILE_VARIANT_ID}`],
            ...relationData
        }
    })

module.exports = {
    generateId,
    createRefArray,
    createDataItem,
    createMobileVariantRelation
}
