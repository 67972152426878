import {MockChannel, MockDuplexer} from '../channelUtils/mockChannel'
import type {Approved, BatchTransactions, Rejected, StaleEditorEnvironment} from './csaveTypes'

export type EventCB = (payload?: any) => Promise<void>

export class MockCSChannel extends MockChannel {
    approved(payload: Approved) {
        this.events.approved(payload)
    }

    rejected(payload: Rejected) {
        this.events.rejected(payload)
    }
    batchTransactions(payload: BatchTransactions) {
        this.events.batchTransactions(payload)
    }

    majorSiteChange(payload?: StaleEditorEnvironment) {
        this.events.majorSiteChange(payload)
    }
}

export class MockCSDuplexer extends MockDuplexer {
    private _rejectNext: boolean = false
    createChannel() {
        return new MockCSChannel()
    }

    approved(payload: Approved): void {
        const channel = this._channel! as MockCSChannel
        channel.approved(payload)
    }

    rejected(payload: Rejected): void {
        const channel = this._channel! as MockCSChannel
        channel.rejected(payload)
    }
    batchTransactions(payload: BatchTransactions) {
        const channel = this._channel! as MockCSChannel
        channel.batchTransactions(payload)
    }
    majorSiteChange(payload?: StaleEditorEnvironment) {
        const channel = this._channel! as MockCSChannel
        channel.majorSiteChange(payload)
    }

    rejectNext() {
        this._rejectNext = true
    }

    shouldReject() {
        return this._rejectNext
    }

    async simulateApproval(correlationId: string, transactionId: string) {
        if (this._rejectNext) {
            await this.rejected({correlationId})
            this._rejectNext = false
        } else {
            await this.approved({correlationId, transactionId})
        }
    }
}
