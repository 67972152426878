import _ from 'lodash'
import {convertNestedPageStructureToFlat} from '../utils/flattenStructure'
import {constants, translationUtils} from '@wix/document-manager-extensions'
import {DocumentManager, pointerUtils, DalValue, store as dmStore} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {ServerStore} from './mainInitialization'
const {PAGE_DATA_TYPE_TO_POINTER_TYPE, MULTILINGUAL_TYPES} = constants
const {getPointer} = pointerUtils
const {createStore} = dmStore

type AddFunc = (pointer: Pointer, value: any) => void

const getTranslationPointer = (pageId: string, lang: string, itemId: string) => ({
    ...getPointer(translationUtils.getTranslationItemKey(lang, itemId), MULTILINGUAL_TYPES.multilingualTranslations),
    pageId
})

const addPageData = (pageId: string, dataMap: any, addFunc: AddFunc) => {
    _.forEach(dataMap, (typeMap, type) => {
        _.forEach(typeMap, (data, id) => {
            const pointerType = PAGE_DATA_TYPE_TO_POINTER_TYPE[type]
            const pointer = getPointer(id, pointerType)
            const dataWithPageAndId = _.merge(data, {metaData: {pageId}, id})
            addFunc(pointer, dataWithPageAndId)
        })
    })
}

const addPageStructure = (nestedPage: any, isMobile: boolean, addFunc: AddFunc) => {
    const pageId = nestedPage.id
    const flatPage = convertNestedPageStructureToFlat(nestedPage, pageId, isMobile)
    if (isMobile && nestedPage.mobileMetaData) {
        flatPage[pageId].metaData = {...nestedPage.mobileMetaData, pageId}
    }

    const pointerType = isMobile ? 'MOBILE' : 'DESKTOP'
    _.forEach(flatPage, (value, compId) => {
        const pointer = getPointer(compId, pointerType)
        addFunc(pointer, value)
    })
}

const addPageTranslations = (pageId: string, translations: any, addFunc: AddFunc) => {
    if (!_.isEmpty(translations)) {
        _.forEach(translations, (translationData, lang) => {
            const dataMap = _.get(translationData, ['data', 'document_data'], {})
            _.forEach(dataMap, (dataItem, dataId) => {
                if (dataId !== 'masterPage') {
                    const pointer = getTranslationPointer(pageId, lang, dataId)
                    const valueWithPageId = _.merge(dataItem, {metaData: {pageId}})
                    addFunc(pointer, valueWithPageId)
                }
            })
        })
    }
}

const addPages = (store: ServerStore, addFunc: AddFunc, postOperation?: Function) => {
    const pageIdsNoMaster = _(store.pagesData).keys().without('masterPage').value()
    const allPageIds = pageIdsNoMaster.concat('masterPage') // Making sure masterPage is last

    _.forEach(allPageIds, pageId => {
        const pageJson = store.pagesData[pageId]

        addPageData(pageId, pageJson.data, addFunc)
        addPageStructure(pageJson.structure, false, addFunc)
        addPageStructure(pageJson.structure, true, addFunc)
        addPageTranslations(pageId, pageJson.translations, addFunc)
        if (postOperation) {
            postOperation()
        }
    })
}

const getRemovalCandidates = (documentManager: DocumentManager, store: ServerStore) => {
    const removalCandidates = {}
    const addFunc = (pointer: Pointer, value: any) =>
        _.setWith(removalCandidates, [pointer.type, pointer.id], value, Object)
    addPages(store, addFunc)

    return removalCandidates
}

const createAddFunc =
    (setter: (pointer: Pointer, value: DalValue) => void, removalCandidates?: any) =>
    (pointer: Pointer, value: any) => {
        if (removalCandidates) {
            _.unset(removalCandidates, [pointer.type, pointer.id])
        }
        setter(pointer, value)
    }

const addStoreToDal = (
    documentManager: DocumentManager,
    store: ServerStore,
    removalCandidates?: any,
    postOperation?: Function
) => {
    addPages(
        store,
        createAddFunc((pointer, value) => documentManager.dal.setIfChanged(pointer, value), removalCandidates),
        postOperation
    )
}

const mergeToDalApprovedStore = (
    documentManager: DocumentManager,
    store: ServerStore,
    removalCandidates?: any,
    id?: string
) => {
    const changes = createStore()
    addPages(
        store,
        createAddFunc((pointer, value) => changes.set(pointer, value), removalCandidates)
    )
    documentManager.dal.mergeToApprovedStore(changes, id)
}
const convertStore = (store: ServerStore) => {
    const changes = createStore()
    addPages(store, (pointer: Pointer, value: any) => {
        changes.set(pointer, value)
    })
    return changes
}

const removeData = (removalCandidates: any, remover: (pointer: Pointer) => void) => {
    _.forEach(removalCandidates, (typeMap, type) => {
        _.forEach(typeMap, (data, id) => {
            const pointer = getPointer(id, type)
            remover(pointer)
        })
    })
}

const removeDataFromDal = (documentManager: DocumentManager, removalCandidates: any) => {
    removeData(removalCandidates, pointer => documentManager.dal.remove(pointer))
}

const mergeDeletionsToDalApprovedStore = (documentManager: DocumentManager, removalCandidates: any, id?: string) => {
    const changes = createStore()
    removeData(removalCandidates, pointer => changes.remove(pointer))
    documentManager.dal.mergeToApprovedStore(changes, id)
}

export {
    getRemovalCandidates,
    addStoreToDal,
    mergeToDalApprovedStore,
    removeDataFromDal,
    mergeDeletionsToDalApprovedStore,
    convertStore
}
