import type {CreateExtArgs, DalValue, DmApis, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointer, StyleRef, VariantPointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {MASTER_PAGE_ID, VIEW_MODES} from '../../constants/constants'
import _ from 'lodash'
import type {SchemaExtensionAPI} from '../schema/schema'
import type {RelationshipsAPI} from '../relationships'
import type {VariantsExtensionAPI} from '../variants/variants'

export interface GlobalStyle {
    id: string
    type: 'GlobalStyle'
    label: string
    componentCategory: string
}

interface GlobalStyleAPI extends ExtensionAPI {
    get(pointer: Pointer): GlobalStyle
    list(): Pointer[]
    getByComponent(compPointer: Pointer): Pointer | null
    listByComponentCategories(): Map<string, Pointer[]>
    listComponentsByGlobalStyle(pointer: Pointer): Pointer[]
    add(name: string, compCategory: string, style?: StyleRef): Pointer
    update(pointer: Pointer, name: string): void
    remove(pointer: Pointer): void
    attachComponent(compPointer: Pointer, globalStylePointer: Pointer): void
    detachComponent(compPointer: Pointer): void
    style: {
        get(variantPointer: VariantPointer): DalValue
        update(variantPointer: VariantPointer, style: StyleRef): void
        remove(variantPointer: VariantPointer): void
    }
}

interface GlobalStyleExtensionAPI extends ExtensionAPI {
    globalStyle: GlobalStyleAPI
}

/**
 * @returns {Extension}
 */
const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, extensionAPI, pointers}: CreateExtArgs): GlobalStyleExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const {variants} = extensionAPI as VariantsExtensionAPI
        const {schemaAPI} = extensionAPI as SchemaExtensionAPI
        const {relationships} = extensionAPI as RelationshipsAPI

        const get = (pointer: Pointer): GlobalStyle => {
            return <GlobalStyle>_.omit(dataModel.getItem(pointer.id, pointer.type, MASTER_PAGE_ID), 'styleId')
        }

        const list = (): Pointer[] => {
            return pointers.data.getStyleItemsWithPredicate(value => value.type === 'GlobalStyle', MASTER_PAGE_ID)
        }

        const getByComponent = (compPointer: Pointer): Pointer | null => {
            return schemaAPI.getReferencePointer(compPointer, 'style', 'globalStyleId')
        }

        const listByComponentCategories = (): Map<string, Pointer[]> => {
            const globalStylesByCategories = new Map<string, Pointer[]>()
            const globalStylesPointers = list()

            globalStylesPointers.forEach(globalStylePointer => {
                const {componentCategory} = get(globalStylePointer)
                if (!globalStylesByCategories.has(componentCategory)) {
                    globalStylesByCategories.set(componentCategory, [])
                }
                globalStylesByCategories.get(componentCategory)!.push(globalStylePointer)
            })

            return globalStylesByCategories
        }

        const listComponentsByGlobalStyle = (pointer: Pointer): Pointer[] => {
            return relationships
                .getReferencesToPointer(pointer)
                .filter(reference => (Object.values(VIEW_MODES) as string[]).includes(reference.type))
        }

        const attachComponent = (compPointer: Pointer, globalStylePointer: Pointer): void => {
            const globalStyleIdPointer = pointers.getInnerPointer(compPointer, 'globalStyleId')
            dal.set(globalStyleIdPointer, globalStylePointer.id)
        }

        const detachComponent = (compPointer: Pointer): void => {
            const globalStyleIdPointer = pointers.getInnerPointer(compPointer, 'globalStyleId')
            dal.remove(globalStyleIdPointer)
        }

        const updateStyle = (variantPointer: VariantPointer, style: StyleRef): void => {
            variants.createOrUpdateConsideringVariants(variantPointer, style, 'style', 'styleId')
        }

        const add = (name: string, componentCategory: string, style?: StyleRef): Pointer => {
            const globalStylePointer = dataModel.addItem(
                {
                    type: 'GlobalStyle',
                    label: name,
                    componentCategory
                },
                'style',
                MASTER_PAGE_ID
            )

            if (style) {
                updateStyle(globalStylePointer, style)
            }

            return globalStylePointer
        }

        const update = (pointer: Pointer, name: string): void => {
            const globalStyle = dataModel.getItem(pointer.id, pointer.type, MASTER_PAGE_ID)
            if (!globalStyle) {
                return
            }
            globalStyle.label = name
            dataModel.addItemWithRefReuse(globalStyle, 'style', MASTER_PAGE_ID, globalStyle.id)
        }

        const remove = (pointer: Pointer): void => {
            const componentsToDetach = listComponentsByGlobalStyle(pointer)
            componentsToDetach.forEach(compPointer => detachComponent(compPointer))
            dataModel.removeItemRecursivelyIncludingPermanentNodes(pointer)
        }

        const getStyle = (variantPointer: VariantPointer): DalValue => {
            return variants.getItemConsideringVariantsForPointer(variantPointer, 'style', 'styleId')
        }

        const removeStyle = (variantPointer: VariantPointer) => {
            variants.removeDataConsideringVariants(variantPointer, 'style', 'styleId')
        }

        return {
            globalStyle: {
                get,
                list,
                add,
                update,
                remove,
                getByComponent,
                listByComponentCategories,
                listComponentsByGlobalStyle,
                attachComponent,
                detachComponent,
                style: {
                    get: getStyle,
                    update: updateStyle,
                    remove: removeStyle
                }
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {globalStyle} = extensionAPI as GlobalStyleExtensionAPI
        return {
            globalStyle: {
                get: globalStyle.get,
                list: globalStyle.list,
                add: globalStyle.add,
                update: globalStyle.update,
                remove: globalStyle.remove,
                getByComponent: globalStyle.getByComponent,
                listByComponentCategories: globalStyle.listByComponentCategories,
                listComponentsByGlobalStyle: globalStyle.listComponentsByGlobalStyle,
                attachComponent: globalStyle.attachComponent,
                detachComponent: globalStyle.detachComponent,
                style: {
                    get: globalStyle.style.get,
                    update: globalStyle.style.update,
                    remove: globalStyle.style.remove
                }
            }
        }
    }

    return {
        name: 'globalStyle',
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension, GlobalStyleExtensionAPI}
