import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            localEditor: {
                save: publicMethodUtils.extensionPublicAPI.immediate('localEditor.exportToCli')
            }
        }
    }
}
