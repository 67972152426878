import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ComponentAfterRootAddDataEvent} from '../hooks'
import type {MeshLayoutExtApi} from '../../meshLayout/meshLayout'

export const convertToMeshAfterAddHook = (createExtArgs: CreateExtArgs, data: ComponentAfterRootAddDataEvent) => {
    const {compToAddPointer, containerPointer} = data
    const {coreConfig, dal, pointers, extensionAPI} = createExtArgs
    const {meshLayout} = extensionAPI as MeshLayoutExtApi
    const {experimentInstance} = coreConfig
    if (!experimentInstance.isOpen('dm_meshLayout')) {
        return
    }

    const alreadyMigrated = !!dal.get(pointers.getInnerPointer(compToAddPointer, ['layoutQuery']))

    if (alreadyMigrated) {
        return
    }

    const isPage = pointers.structure.isPage(compToAddPointer)

    if (isPage) {
        return meshLayout.convertToMeshLayout(compToAddPointer.id)
    }
    meshLayout.convertToMeshLayout(containerPointer.id, {ignoreRoot: true})
}
