import _ from 'lodash'
import type {
    ComponentExport,
    CompRef,
    ImportExportOptions,
    PS,
    RawComponentExport,
    WMLFiles
} from '@wix/document-services-types'
import hooks from '../hooks/hooks'

type PossibleExportFormats = WMLFiles | ComponentExport

/**
 * Factory for conversion function being performed in public api
 * on asyncPreDataManipulation step.
 *
 * NOTE. asyncPreDataManipulation and getReturnValue being used in public api
 * alter the arguments array being provided. So index is used in order to let
 * the function know which of the arguments is the original export to convert
 *
 * @param {string} convertExtensionApiPath - path to conversion api to be invoked
 * @param {number} exportIndex - index of original export in arguments array being provided
 */
const createConvertApi =
    (convertExtensionApiPath: string, exportIndex: number): Function =>
    async (ps: PS, ...args: any[]): Promise<void> => {
        try {
            const rawComponentExport: RawComponentExport = await _.invoke(
                ps.extensionAPI.importExport,
                convertExtensionApiPath,
                args[exportIndex]
            )
            ps.setOperationsQueue.asyncPreDataManipulationComplete(rawComponentExport)
        } catch (e) {
            ps.setOperationsQueue.asyncPreDataManipulationComplete(null, e as Error)
        }
    }

const executeAfterImportHook = (ps: PS) => hooks.executeHook(hooks.HOOKS.IMPORT_EXPORT.AFTER_IMPORT, null!, [ps])

export default {
    components: {
        replace: (
            ps: PS,
            componentExport: RawComponentExport,
            componentToReplace: CompRef,
            originalExport: PossibleExportFormats,
            options?: ImportExportOptions
        ): CompRef => {
            const res = ps.extensionAPI.importExport.components.replace(componentToReplace, componentExport, options)
            executeAfterImportHook(ps)
            return res
        },
        add: (
            ps: PS,
            rawComponentExport: RawComponentExport,
            componentToAddPointer: CompRef,
            containerPointer: CompRef,
            originalExport: PossibleExportFormats,
            options?: ImportExportOptions
        ) => {
            const componentRef = ps.extensionAPI.importExport.components.add(
                componentToAddPointer,
                containerPointer,
                rawComponentExport,
                options
            )
            executeAfterImportHook(ps)
            return componentRef
        }
    },
    pages: {
        replace: (
            ps: PS,
            pageExport: RawComponentExport,
            pageToReplace: CompRef,
            originalExport: PossibleExportFormats,
            options?: ImportExportOptions
        ): CompRef => {
            const res = ps.extensionAPI.importExport.pages.replace(pageToReplace, pageExport, options)
            executeAfterImportHook(ps)
            return res
        },
        add: (
            ps: PS,
            rawPageExport: RawComponentExport,
            componentToAddPointer: CompRef,
            originalExport: PossibleExportFormats,
            options?: ImportExportOptions
        ): CompRef => {
            const res = ps.extensionAPI.importExport.pages.add(componentToAddPointer, rawPageExport, options)
            executeAfterImportHook(ps)
            return res
        }
    },
    wml: {
        convertWmlToRawComponentExport: createConvertApi('wml.convertWmlToRawComponentExport', 1),
        convertWmlToRawComponentExportWithContainerArgument: createConvertApi('wml.convertWmlToRawComponentExport', 2),
        exportComponent: (ps: PS, componentPointer: CompRef, options?: ImportExportOptions): WMLFiles =>
            ps.extensionAPI.importExport.wml.exportComponent(componentPointer, options),
        exportPage: (ps: PS, pagePointer: CompRef, options?: ImportExportOptions): WMLFiles =>
            ps.extensionAPI.importExport.wml.exportPage(pagePointer, options)
    },
    core: {
        convertCoreToRawComponentExport: createConvertApi('core.convertCoreToRawComponentExport', 1),
        convertCoreToRawComponentExportWithContainerArgument: createConvertApi(
            'core.convertCoreToRawComponentExport',
            2
        ),
        exportComponent: (ps: PS, componentPointer: CompRef, options?: ImportExportOptions): ComponentExport =>
            ps.extensionAPI.importExport.core.exportComponent(componentPointer, options),
        exportPage: (ps: PS, pagePointer: CompRef, options?: ImportExportOptions): ComponentExport =>
            ps.extensionAPI.importExport.core.exportPage(pagePointer, options)
    }
}
