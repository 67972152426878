import type {
    ConversionDal,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry,
    StructureStageData,
    VirtualStructureEntryItem
} from '../../types'
import type {StructureEntryItem} from '../../conversionDal/types'
import {getStructurePointer, getVirtualStructurePointer} from '../../conversionDal/pointerUtils'
import {createStructureIterators} from '../../conversionDal/utils/structureIterators'
import type {Pointer} from '@wix/document-services-types'
import {VIRTUAL_TAG} from '../grouping/constants'

const compareByZRules = (
    virtualStructureA: VirtualStructureEntryItem,
    virtualStructureB: VirtualStructureEntryItem
) => {
    return (
        virtualStructureA.measurements!.y - virtualStructureB.measurements!.y ||
        virtualStructureA.measurements!.x - virtualStructureB.measurements!.x
    )
}

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const getRealChildrenAfterOrder = (
        conversionDal: ConversionDal,
        virtualStructurePointer: Pointer,
        realChildren: string[] = []
    ): string[] => {
        const virtualStructure = conversionDal.get(virtualStructurePointer)

        for (const childId of virtualStructure.components) {
            const virtualChildPointer = getVirtualStructurePointer({id: childId})
            const child = conversionDal.get(virtualChildPointer)

            if (child.componentType === VIRTUAL_TAG) {
                getRealChildrenAfterOrder(conversionDal, virtualChildPointer, realChildren)
            } else {
                realChildren.push(childId)
            }
        }

        return realChildren
    }

    const orderComponents = ({conversionDal, structureIterators}: StructureStageData) => {
        const virtualStructureIterators = createStructureIterators(
            conversionDal,
            getVirtualStructurePointer(structureIterators.getRootPointer())
        )

        virtualStructureIterators.forEachComponent(virtualStructureValue => {
            conversionDal.modify(getVirtualStructurePointer({id: virtualStructureValue.id}), value => ({
                ...value,
                components: [...value.components].sort((childIdA, childIdB) =>
                    compareByZRules(
                        conversionDal.get(getVirtualStructurePointer({id: childIdA})),
                        conversionDal.get(getVirtualStructurePointer({id: childIdB}))
                    )
                )
            }))
        })

        structureIterators.forEachComponent(({id}: StructureEntryItem) => {
            const structurePointer = getStructurePointer({id})
            const realChildrenOrdered: string[] = getRealChildrenAfterOrder(
                conversionDal,
                getVirtualStructurePointer({id})
            )
            conversionDal.modify(structurePointer, (value: StructureEntryItem) => ({
                ...value,
                components: realChildrenOrdered
            }))
        })
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.ANALYZE, orderComponents)
    }

    return {register, name: 'order', dependencies: []}
}
