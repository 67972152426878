import _ from 'lodash'
import type {DocumentServicesObject, Pointer} from '@wix/document-services-types'
import type {MobileAlgoApi} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/mobileAlgo'
import type {OverlayManager} from '../overlayManager/overlayManager'
import {type DAL, pointerUtils} from '@wix/document-manager-core'
import type {
    GroupApi,
    GroupingResult
} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/plugins/grouping/types'
import type {DebugToolsConfig} from '../types'
import {NEW_ALGO, VIRTUAL_TAG} from '../constants'
import {getSections} from './utils/getSections'
import {getColor} from './utils/color'
import type {ConversionDal} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/types'
import type {RMApi} from '@wix/document-manager-extensions/src/extensions/rendererModel'
import {getPageBoId, sendInputToBo} from './utils/boApi'

const {getPointer, getPointerFromPointer} = pointerUtils
const getPluginApi = (mobileAlgo: MobileAlgoApi) => {
    const ctx = mobileAlgo.context.create(['grouping'])
    const apis = mobileAlgo.plugins.getApis(ctx)
    const {grouping} = apis as GroupApi
    return {ctx, grouping}
}
export type IdMapper = (elem: GroupingResult) => string
const friendlyId: IdMapper = (elem: GroupingResult) => {
    if (elem.tag === 'Group') {
        return elem.id
    }
    return `${_.last(elem.component!.componentType!.split('.'))} (${elem.id.slice(-4)})`
}

export const logGroupTree = async (mobileAlgo: MobileAlgoApi, sectionId: string, idMapper: IdMapper = friendlyId) => {
    const {ctx, grouping} = getPluginApi(mobileAlgo)
    await mobileAlgo.runWithContext({id: sectionId, type: 'DESKTOP'}, ctx)
    const dfsLogBuilder = (groupTree: GroupingResult, indent: number = 0) => {
        let result = `${'|\t'.repeat(indent)}${idMapper(groupTree)}\n`
        for (const childTree of groupTree.children ?? []) {
            result += dfsLogBuilder(childTree, indent + 1)
        }
        return result
    }

    console.log(dfsLogBuilder(grouping.getAiGroupTree()))
}

const groupIndicationWrapper = (
    conversionDal: ConversionDal,
    rootPointer: Pointer,
    overlayManager: OverlayManager,
    config: DebugToolsConfig
) => {
    const color = getColor(config)
    const virtualStructure = conversionDal.get(rootPointer)

    if (virtualStructure.componentType === VIRTUAL_TAG) {
        console.log(`%c ${virtualStructure.id}`, `color:${color};`)
        console.log(virtualStructure)
        overlayManager.markComponent(
            virtualStructure.realChildren ?? [],
            NEW_ALGO,
            virtualStructure.id,
            color,
            '',
            '-23px'
        )
    }

    for (const childId of virtualStructure.components ?? []) {
        groupIndicationWrapper(conversionDal, getPointerFromPointer(childId, rootPointer), overlayManager, config)
    }
}

const displayGroups = async (
    mobileAlgo: MobileAlgoApi,
    sections: string[],
    overlayManager: OverlayManager,
    config: DebugToolsConfig
) => {
    for (const sectionId of sections) {
        const {ctx} = getPluginApi(mobileAlgo)
        const {conversionDal, structureIterators} = await mobileAlgo.runWithContext(
            {id: sectionId, type: 'DESKTOP'},
            ctx
        )
        const virtualStructureRootPointer = getPointer(structureIterators.getRootPointer().id, 'virtualStructure')
        groupIndicationWrapper(conversionDal, virtualStructureRootPointer, overlayManager, config)
    }
}

export const displayGroupsAllSections = async (
    documentServices: DocumentServicesObject,
    mobileAlgo: MobileAlgoApi,
    overlayManager: OverlayManager,
    dal: DAL,
    config: DebugToolsConfig
) => {
    const currentPageSections = getSections(dal, documentServices.pages.getCurrentPageId())
    await displayGroups(mobileAlgo, currentPageSections, overlayManager, config)
}

export const displayGroupsSingleSection = async (
    mobileAlgo: MobileAlgoApi,
    sectionId: string,
    overlayManager: OverlayManager,
    config: DebugToolsConfig
) => {
    await displayGroups(mobileAlgo, [sectionId], overlayManager, config)
}

const sendRequest = async (): Promise<GroupingResult> => {
    return {
        id: 'id',
        tag: 'Group',
        children: [
            {
                id: 'id_1',
                tag: 'Group',
                children: []
            }
        ]
    }
}

function addPageComponent(
    dal: DAL,
    pageId: string,
    sectionData: {
        domData: {domLayout: {}; zIndexMap: {}}
        siteData: {components: {}}
    }
) {
    const pageComponent = dal.get({id: pageId, type: 'DESKTOP'})
    const pageInput = {
        type: pageComponent.type,
        parent: '',
        componentType: pageComponent.componentType,
        components: pageComponent.components
    }
    sectionData.siteData.components[pageId] = pageInput
}

export const sendPageLayoutInputToBo = async (
    documentServices: DocumentServicesObject,
    mobileAlgo: MobileAlgoApi,
    siteAPI: RMApi,
    dal: DAL
) => {
    const {ctx, grouping} = getPluginApi(mobileAlgo)
    grouping.registerServer(sendRequest)
    const pageId = documentServices.pages.getCurrentPageId()
    const pagePublicUrl = documentServices.pages.getPageUrl(pageId)
    const {info} = await getPageBoId(pagePublicUrl, siteAPI)
    const currentPageBoId = info.siteId
    console.log({currentPageBoId})
    const currentPageSections = getSections(dal, pageId)
    let sectionData = {
        siteData: {
            components: {}
        },
        domData: {
            domLayout: {},
            zIndexMap: {}
        }
    }

    for (const sectionId of currentPageSections) {
        await mobileAlgo.runWithContext({id: sectionId, type: 'DESKTOP'}, ctx)
        const sectionDataInput = grouping.getSectionDataInput()
        sectionDataInput.siteData.components = {
            ...sectionData.siteData.components,
            ...sectionDataInput.siteData.components
        }
        sectionDataInput.domData.domLayout = {...sectionData.domData!.domLayout, ...sectionDataInput.domData.domLayout}
        sectionDataInput.domData.zIndexMap = {...sectionData.domData!.zIndexMap, ...sectionDataInput.domData.zIndexMap}
        sectionData = sectionDataInput
    }
    addPageComponent(dal, pageId, sectionData)
    console.log({sectionData})
    await sendInputToBo(currentPageBoId, sectionData, siteAPI)
}
