import _ from 'lodash'
import type {DalPointers} from '../types'
import type {
    DalValue,
    DeserializationMappers,
    Pointer,
    VariantRelation,
    VariantsReplacementOperation
} from '@wix/document-services-types'
import {createReferredId, refCompDelimiter} from './inflationUtils'
import {getIdFromRef} from './dataUtils'
import {RELATION_DATA_TYPES} from '../constants/constants'
import {stripHashIfExists} from './refArrayUtils'
import type {DataExtensionAPI} from '../extensions/data'
import type {ExtensionAPI} from '@wix/document-manager-core'

interface RelationData {
    originalVariants: Set<string>
    transformedVariants: Set<string>
    relationPointer?: Pointer
    from?: string
    to?: string
    value?: DalValue
    indexInRefArray?: number
}

interface ScopedValueToMaintain {
    variants: string[]
    value: DalValue
    indexInRefArray: number
}

const hasActiveVariants = ({dal, pointers}: DalPointers, componentId: string) => {
    const activeVariantPointer = pointers.activeVariants.getActiveVariant(componentId)
    return !_.isEmpty(dal.get(activeVariantPointer))
}

const isLocalVariant = (variant: string | Pointer, separateRemoteVariants?: boolean) => {
    if (!separateRemoteVariants) {
        return true
    }
    const id = _.isString(variant) ? variant : variant.id
    return !id.includes(refCompDelimiter)
}

const transformToRelationData = (relation: VariantRelation) => {
    const relationVariants = (relation.variants || []).map(variant => stripHashIfExists(variant))

    return {
        to: relation.to,
        from: relation.from,
        originalVariants: new Set(relationVariants),
        transformedVariants: new Set(relationVariants)
    }
}
const sortRelationsBySpecificity = (
    {originalVariants: originalVariants1}: any,
    {originalVariants: originalVariants2}: any
) => originalVariants1.size - originalVariants2.size

const applyOperations = (operations: VariantsReplacementOperation[], relationsData: RelationData[]) => {
    for (const operation of operations) {
        const {from, to} = operation

        for (const {originalVariants, transformedVariants} of relationsData) {
            const shouldApplyOperation = from.every(variant => originalVariants.has(variant.id))

            if (shouldApplyOperation) {
                for (const variant of from) {
                    transformedVariants.delete(variant.id)
                }

                for (const variant of to) {
                    transformedVariants.add(variant.id)
                }
            }
        }
    }
}
const getValidRelations = (
    relationsData: RelationData[],
    isValidRelationPredicate: (variants: string[]) => boolean = () => true,
    relationsPointersToRemove: Pointer[] = []
) => {
    const validRelations = {}
    let relationToDefault

    for (const {relationPointer, indexInRefArray, from, to, originalVariants, transformedVariants} of relationsData) {
        const variants = [...transformedVariants]

        if (transformedVariants.size === 0) {
            relationToDefault = {relationPointer, to}
            relationsPointersToRemove.push(relationPointer as Pointer)
            continue
        }

        if (!isValidRelationPredicate(variants)) {
            relationsPointersToRemove.push(relationPointer as Pointer)
            continue
        }

        const relationKey = variants.sort().join(',')

        if (validRelations[relationKey]) {
            relationsPointersToRemove.push(validRelations[relationKey].relationPointer)
        }

        validRelations[relationKey] = {
            ...(relationPointer && {relationPointer}),
            ...(indexInRefArray && {indexInRefArray}),
            from,
            to,
            originalVariants,
            transformedVariants
        }
    }

    return {
        validRelations,
        relationToDefault,
        relationsPointersToRemove
    }
}
const sortRelationsByIndexInRefArray = (
    relationData1: RelationData | ScopedValueToMaintain,
    relationData2: RelationData | ScopedValueToMaintain
    // @ts-ignore
) => relationData1.indexInRefArray - relationData2.indexInRefArray

const createVariantRelation = (variants: string[], from: string, to: string | DalValue, id?: string) => ({
    ...(id && {id}),
    type: RELATION_DATA_TYPES.VARIANTS,
    variants: variants.map(variant => (typeof variant === 'string' ? `#${getIdFromRef(variant)}` : variant)),
    from: `#${getIdFromRef(from)}`,
    to: typeof to === 'string' ? `#${getIdFromRef(to)}` : to
})

const replaceVariantsOnSerializedOverrides = (
    sourceRefArray: VariantRelation[],
    operations: VariantsReplacementOperation[] = []
) => {
    const relationsData: RelationData[] = []

    if (!Array.isArray(sourceRefArray) || !Array.isArray(operations) || operations.length === 0) {
        return sourceRefArray
    }

    for (const [indexInRefArray, refArrayValue] of sourceRefArray.entries()) {
        relationsData.push({indexInRefArray, ...transformToRelationData(refArrayValue)})
    }

    relationsData.sort(sortRelationsBySpecificity)
    applyOperations(operations, relationsData)

    const {validRelations, relationToDefault} = getValidRelations(relationsData)
    const refArray = (Object.values(validRelations) as RelationData[])
        .sort(sortRelationsByIndexInRefArray)
        .map(({from, to, transformedVariants}: RelationData) =>
            createVariantRelation([...transformedVariants], from as string, to)
        )

    if (relationToDefault?.to) {
        refArray.unshift(relationToDefault.to as any)
    }

    return refArray
}

const updateVariantRelations = (
    extensionAPI: ExtensionAPI,
    compToAddPointer: Pointer,
    dataItem: Record<string, any>,
    mappers: DeserializationMappers
) => {
    const {variantMapper, oldToNewIdMap} = mappers ?? {}
    const refArrayValuesReducer = (cleanRefArray: any, refArrayValue: any) => {
        if (refArrayValue.type === RELATION_DATA_TYPES.VARIANTS) {
            const [, ...referredStructureIds] = refArrayValue.from.split(refCompDelimiter)

            const updatedVariants = refArrayValue.variants.map(
                (oldVariant: any) => oldToNewIdMap[getIdFromRef(oldVariant)]
            )
            const newVariants = updatedVariants.filter(Boolean)

            if (newVariants.length !== refArrayValue.variants.length) {
                return cleanRefArray
            }

            refArrayValue = (extensionAPI as DataExtensionAPI).data.variantRelation.create(
                newVariants,
                createReferredId(compToAddPointer.id, referredStructureIds),
                refArrayValue.to
            )
        }

        cleanRefArray.push(refArrayValue)

        return cleanRefArray
    }
    dataItem.values = replaceVariantsOnSerializedOverrides(dataItem.values, variantMapper)
    dataItem.values = oldToNewIdMap ? dataItem.values.reduce(refArrayValuesReducer, []) : dataItem.values
}

export {
    hasActiveVariants,
    isLocalVariant,
    replaceVariantsOnSerializedOverrides,
    applyOperations,
    updateVariantRelations
}
