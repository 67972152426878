import structure from '../../structure/structure'
import socialComp from './utils/socialComp'
import type {PS} from '@wix/document-services-types'
import type {ComponentAfterAddDataFromExtEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

export const upgradeWFacebookLinkOnAddition = (
    ps,
    compPointer,
    compDefinition,
    optionalCustomId,
    mappers,
    containerPointer
) => {
    socialComp.upgradeUrl(ps, compPointer, 'facebook.likes', {
        forceMainPage: structure.isShowOnAllPages(ps, containerPointer)
    })
}
export const upgradeWFacebookLinkOnAdditionFromExt = (ps: PS, data: ComponentAfterAddDataFromExtEvent) => {
    const {compToAddPointer, containerPointer, componentDefinition, optionalCustomId, mappers} = data
    upgradeWFacebookLinkOnAddition(
        ps,
        compToAddPointer,
        componentDefinition,
        optionalCustomId,
        mappers,
        containerPointer
    )
}
