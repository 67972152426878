import {removePrefix, ReportableError} from '@wix/document-manager-utils'
import type {CompRef, Pointer, PS, YesNoDC} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import _ from 'lodash'
import constants from '../constants/constants'
import mlUtils from './multilingual'
import type {ScopesExtensionAPI} from '@wix/document-manager-extensions'

const masterPage = 'wysiwyg.viewer.components.WSiteStructure'

const REF_PREFIX = '#'

const SPECIAL_COMP_TYPES = {
    masterPage
}

const {DATA_TYPES, COMP_DATA_QUERY_KEYS} = constants

function pointerToString(componentPointer: Pointer) {
    if (!componentPointer) {
        return '(null)'
    }
    let s = `type: ${componentPointer.type} id: ${componentPointer.id}`
    if ('innerPath' in componentPointer) {
        s += ` inner: ${componentPointer.innerPath}`
    }
    return s
}

let prevPointer: Pointer
let prevType: string | undefined

function getComponentType(ps: PS, componentPointer: Pointer): string | undefined {
    if (componentPointer) {
        const specialCompType = SPECIAL_COMP_TYPES[componentPointer.id]
        if (specialCompType) {
            return specialCompType
        }

        if (componentPointer === prevPointer) {
            return prevType
        }

        const typePointer = ps.pointers.getInnerPointer(componentPointer, 'componentType')
        const result = ps.dal.full.get(typePointer) || ps.dal.get(typePointer)
        if (result || ps.dal.full.isExist(componentPointer)) {
            prevPointer = componentPointer
            prevType = result
            return result
        }
    }
    throw new ReportableError({
        errorType: 'nonExistingCompPointer',
        message: `non existing component pointer: ${pointerToString(componentPointer)}`
    })
}

function isLivePreviewActive(ps: PS) {
    return ps.config.origin !== 'appBuilder'
}

function replaceRuntimeRefWithOriginal(ps: PS, compPointer: Pointer) {
    if (!ps || !compPointer || !isLivePreviewActive(ps)) {
        return compPointer
    }
    const itemId = displayedOnlyStructureUtil.getRepeaterItemId(compPointer.id)
    if (itemId) {
        if (isRepeaterRuntimeItem(ps, itemId, compPointer as CompRef)) {
            const templateId = displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)
            const templatePointer = ps.pointers.getPointer(templateId, compPointer.type, {...compPointer})

            delete templatePointer.scope
            const scope = ps.extensionAPI.scopes.extractScopeFromPointer(templatePointer)
            if (scope) {
                templatePointer.scope = scope
            }

            return templatePointer
        }
    }
    return compPointer
}

function getCompData(ps: PS, compPointer: Pointer) {
    const dataQueryPtr = ps.pointers.getInnerPointer(compPointer, COMP_DATA_QUERY_KEYS[DATA_TYPES.data])
    const dataQuery = stripHashIfExists(ps.dal.get(dataQueryPtr))

    if (dataQuery) {
        const originalLanguage = mlUtils.getLanguageByUseOriginal(ps, true)

        const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointer)

        const dataItemPtr = ps.pointers.data.getItem(DATA_TYPES.data, dataQuery, pagePointer.id)
        //This is a temp fix for DM-3181 should be removed when issue is really fixed
        dataItemPtr.useLanguage = originalLanguage

        return ps.dal.full.get(dataItemPtr)
    }
}

function getParentOrOwner(ps: PS, compPointer: CompRef): CompRef | undefined {
    const parentPointer = ps.pointers.full.components.getParent(compPointer) as CompRef
    if (parentPointer) {
        return parentPointer
    }

    const {scopes} = ps.extensionAPI as ScopesExtensionAPI
    const scopePointer = scopes.extractScopeFromPointer(compPointer)
    if (scopePointer) {
        return scopes.getScopeOwner(scopePointer, compPointer.type)
    }
}

function getRepeaterAncestor(ps: PS, compPointer: CompRef): CompRef | undefined {
    let parentPointer = compPointer
    do {
        parentPointer = getParentOrOwner(ps, parentPointer)
    } while (parentPointer && !ps.extensionAPI.componentDefinition.isRepeater(getComponentType(ps, parentPointer)))

    return parentPointer
}

function isRepeaterRuntimeItem(ps: PS, itemId: string, compPointer: CompRef): boolean {
    const repeaterPointer = getRepeaterAncestor(ps, compPointer)

    if (!repeaterPointer) {
        return false
    }

    const staticItems = _.get(getCompData(ps, repeaterPointer), ['items'], [])

    return !_.includes(staticItems, itemId)
}

/**
 * @param {string} input
 * @param {boolean} strict - if true, will return null incase input is not a string (default true)
 * @return {string|null}
 */
function stripHashIfExists(input: string, strict: boolean = true): string | null {
    const defaultValue = strict ? null : input
    return _.isString(input) ? removePrefix(input, REF_PREFIX) : defaultValue
}

const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

function isGuid(value: string) {
    return guidRegex.test(value)
}

export interface SuccessResult {
    success: true
}

export interface ErrorResult {
    success: false
    error: string
}

export type Result = SuccessResult | ErrorResult

export const isError = (r: Result): r is ErrorResult => r.success === false

export const isHeadless = (ps: PS) =>
    _.get(ps.config, 'origin') === 'DMMigrator' || _.get(ps.config, 'origin') === 'DMMigratorX'

export const getMeasureMapValue = <T>(ps: PS, componentPointer: CompRef, measure: string, defaultValue: T): T => {
    if (isHeadless(ps)) {
        return defaultValue
    }

    const {id: pageId} = ps.pointers.components.getPageOfComponent(componentPointer)
    if (pageId !== ps.siteAPI.getFocusedRootId() && pageId !== ps.siteAPI.getCurrentUrlPageId()) {
        return defaultValue
    }
    return ps.siteAPI.getSiteMeasureMap()[measure][componentPointer.id] as T
}

function moveItemInArray<T>(items: T[], fromIndex: number, toIndex: number): T[] {
    items.splice(toIndex, 0, ...items.splice(fromIndex, 1))

    return items
}

export default {
    YES: 'yes' as YesNoDC,
    NO: 'no' as YesNoDC,
    DONT_CARE: "don't care" as YesNoDC,
    isHeadless,
    stripHashIfExists,
    getComponentType,
    replaceRuntimeRefWithOriginal,
    isGuid,
    moveItemInArray,
    getRepeaterAncestor
}
