import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import structure from '../../structure/structure'
import dataModel from '../../dataModel/dataModel'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import constants from '../../constants/constants'
import design from '../../variants/design'
import repeaterUtils from '../../utils/repeater'
import experiment from 'experiment-amd'
import {
    getRepeatersNestingOrder,
    getCompIdWithRepeatersNesting
} from '@wix/document-manager-extensions/src/utils/inflationUtils'
import dsUtils from '../../utils/utils'
import type {BeforeGetRepeatedPointerEvent} from '@wix/document-manager-extensions/src/extensions/dataModel/hooks'
import hooks from '../hooks'

const REPEATER_TYPE = 'wysiwyg.viewer.components.Repeater'
const {DATA_TYPES} = constants
const {displayedOnlyStructureUtil} = santaCoreUtils

function afterLayoutChanged(
    ps: PS,
    compPointer: Pointer,
    updatedLayout,
    updateCompLayoutCallbackForHooks,
    isTriggeredByHook,
    previousLayout
) {
    if (updatedLayout.width !== previousLayout.width) {
        const itemPointer = _.head(ps.pointers.components.getChildren(compPointer)) as Pointer
        const itemLayout = ps.dal.get(ps.pointers.getInnerPointer(itemPointer, ['layout']))
        structure.updateCompLayout(ps, itemPointer, itemLayout, true)
    }
}

function addRepeatedDataItems(compStructure, flags) {
    flags.repeatedItemIds = compStructure.data.items
}

const dataModelMethods = {
    dataQuery: {
        add: dataModel.addSerializedDataItemToPage,
        get(ps: PS, compPointer: Pointer) {
            const dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
            return dataModel.serializeDataItem(ps, DATA_TYPES.data, dataItemPointer, true, true)
        },
        getPointer(ps: PS, compPointer: Pointer) {
            return dataModel.getDataItemPointer(ps, compPointer)
        },
        getItemById: dataModel.getDataItemById,
        delete: dataModel.deleteDataItem
    },
    designQuery: {
        add: dataModel.addSerializedDesignItemToPage,
        get(ps: PS, compPointer: Pointer) {
            const designItemPointer = design.getDesignItemPointer(ps, compPointer)
            return dataModel.serializeDataItem(ps, DATA_TYPES.design, designItemPointer, true)
        },
        getPointer(ps: PS, compPointer: Pointer) {
            return design.getDesignItemPointer(ps, compPointer)
        },
        getItemById: design.getDesignItemById,
        delete: dataModel.deleteDesignItem
    }
}

const syncTranslationsAfterAdd = (
    ps: PS,
    newDisplayedDataItemPointer: Pointer,
    templateDataPointer: Pointer,
    dataType: string,
    pageId: string
) => {
    if (dataType !== DATA_TYPES.data) {
        return
    }

    const translationLanguages = ps.extensionAPI.multilingualTranslations.getLanguagesForItem(templateDataPointer.id)

    for (const languageCode of translationLanguages) {
        const dataItemTranslationPointer = ps.pointers.multilingualTranslations.translationDataItem(
            pageId,
            languageCode,
            templateDataPointer.id
        )

        if (ps.dal.isExist(dataItemTranslationPointer)) {
            const translatedDataItem = dataModel.getDataItemByIdInLang(
                ps,
                templateDataPointer.id,
                pageId,
                true,
                languageCode
            )

            dataModel.addSerializedDataItemToPage(
                ps,
                pageId,
                translatedDataItem,
                newDisplayedDataItemPointer.id,
                languageCode
            )
        }
    }
}

function getAllItemIdsForDuplication(compPointer: Pointer, replacedItemDepth: number, replacedItemId: string) {
    const itemIds = getRepeatersNestingOrder(compPointer.id)
    if (itemIds.length > replacedItemDepth) {
        itemIds[itemIds.length - 1 - replacedItemDepth] = replacedItemId
    } else {
        itemIds.push(replacedItemId)
    }
    return itemIds
}

function duplicateCompsDataItems(
    ps: PS,
    pagePointer: Pointer,
    itemId: string,
    replacedItemDepth: number,
    compPointer: Pointer
) {
    _.forEach(dataModelMethods, function (func, dataType) {
        const itemIds = getAllItemIdsForDuplication(compPointer, replacedItemDepth, itemId)
        let query = ps.dal.get(ps.pointers.getInnerPointer(compPointer, dataType))
        if (query) {
            query = dsUtils.stripHashIfExists(query)
            const originalDataId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(query)
            const api = dataModelMethods[dataType]
            const componentType = dsUtils.getComponentType(ps, compPointer)
            const duplicatedDataItem = api.get(ps, compPointer)
            const originalDataPointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(
                api.getPointer(ps, compPointer)
            )
            const shouldAddRepeatedData =
                duplicatedDataItem &&
                (repeaterUtils.shouldAddRepeatedData(ps, compPointer, api.getPointer(ps, compPointer)) ||
                    ps.dal.full.isExist(originalDataPointer))
            if (shouldAddRepeatedData) {
                const newDataItemPointer = ps.pointers.getPointer(
                    getCompIdWithRepeatersNesting(originalDataId, itemIds),
                    originalDataPointer.type
                )
                api.add(ps, pagePointer.id, duplicatedDataItem, newDataItemPointer.id)
                hooks.executeHook(hooks.HOOKS.DATA.AFTER_DUPLICATE_REPEATER_ITEM_OVERRIDE, componentType, [
                    ps,
                    newDataItemPointer,
                    originalDataPointer
                ])
                syncTranslationsAfterAdd(
                    ps,
                    newDataItemPointer,
                    originalDataPointer,
                    newDataItemPointer.type,
                    pagePointer.id
                )
            }
        }
    })
}

/**
 *
 * @param newItems The items array of the new data
 * @param currentItems The items array of the current data
 * @param itemId The new item id that needs to be duplicated
 * @returns {number} The index of the item in the current data that needs to be duplicated
 */
function getOriginalItemIndexToDuplicate(newItems, currentItems, itemId: string) {
    let itemIndex = Math.max(_.indexOf(newItems, itemId), 0)
    while (itemIndex > 0) {
        itemIndex--
        const currentItemIndex = _.indexOf(currentItems, newItems[itemIndex])
        if (currentItemIndex >= 0) {
            return currentItemIndex
        }
    }

    return 0
}

function deleteCompsDataItems(ps: PS, compPointer: Pointer) {
    _.forEach(dataModelMethods, function (api) {
        api.delete(ps, compPointer)
    })
}

function beforeUpdateRepeaterData(ps: PS, compPointer: Pointer, dataItem) {
    const pagePointer =
        ps.pointers.components.getPageOfComponent(compPointer) ||
        ps.pointers.full.components.getPageOfComponent(compPointer)
    const currentItems = _.get(dataModel.getDataItem(ps, compPointer), 'items')
    const newItems = _.filter(dataItem.items, function (id) {
        return !_.includes(currentItems, id)
    })
    const removedItems = _.filter(currentItems, function (id) {
        return !_.includes(dataItem.items, id)
    })
    const depth = getRepeatersNestingOrder(compPointer.id).length
    const children = ps.pointers.components.getChildren(compPointer)
    _.forEach(newItems, function (itemId) {
        const originalDataIndex = getOriginalItemIndexToDuplicate(dataItem.items, currentItems, itemId)
        const duplicatedChildPointer = children[originalDataIndex]
        if (!duplicatedChildPointer) return
        const allDuplicatedComps = [duplicatedChildPointer].concat(
            ps.pointers.components.getChildrenRecursively(duplicatedChildPointer)
        )
        _.forEach(allDuplicatedComps, _.partial(duplicateCompsDataItems, ps, pagePointer, itemId, depth))
    })

    _.forEach(currentItems, function (itemId, index) {
        if (!_.includes(dataItem.items, itemId)) {
            const deletedChildPointer = children[index]
            const allDuplicatedComps = [deletedChildPointer].concat(
                ps.pointers.components.getChildrenRecursively(deletedChildPointer)
            )
            _.forEach(allDuplicatedComps, _.partial(deleteCompsDataItems, ps))
        }
    })

    const componentTemplateIdPointer = ps.pointers.displayedOnlyComponents.getComponentTemplateId(compPointer.id)

    if (!_.isEmpty(removedItems) && _.includes(removedItems, ps.dal.get(componentTemplateIdPointer))) {
        ps.dal.set(componentTemplateIdPointer, _.head(dataItem.items))
    }
}

function afterUpdateRepeaterData(ps: PS, compPointer: Pointer) {
    const isRefComponent = displayedOnlyStructureUtil.isRefPointer(compPointer)
    if (!isRefComponent && !experiment.isOpen('dm_preventSyncingRepeaterTemplateWithFirstItem')) {
        syncAllRepeaterTemplateCompsWithFirstItem(ps, compPointer)
    }
}

function overrideItemIdToOriginalId(dataItem, displayedId: string) {
    if (dataItem) {
        return _.assign(dataItem, {id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(displayedId)})
    }
    return dataItem
}

function updateDataItemFromDisplayedToFull(
    ps: PS,
    pageId: string,
    sourcePointer: Pointer,
    destPointer: Pointer,
    propertyToUpdate,
    useLanguage
) {
    let dataQuery = ps.dal.get(ps.pointers.getInnerPointer(sourcePointer, propertyToUpdate))
    if (!dataQuery) {
        return
    }
    dataQuery = dsUtils.stripHashIfExists(dataQuery)
    const newDataItem = overrideItemIdToOriginalId(
        dataModelMethods[propertyToUpdate].getItemById(ps, dataQuery, pageId, true),
        dataQuery
    )
    if (newDataItem) {
        const templateDataQuery = dsUtils.stripHashIfExists(
            ps.dal.full.get(ps.pointers.getInnerPointer(destPointer, propertyToUpdate))
        )
        if (!useLanguage) {
            dataModelMethods[propertyToUpdate].delete(ps, destPointer)
        }
        dataModelMethods[propertyToUpdate].add(ps, pageId, newDataItem, templateDataQuery, useLanguage)
    }
}

function updateTemplateAccordingToFirstRepeaterItem(
    ps: PS,
    displayedPointer: Pointer,
    dataPropsToUpdate,
    useLanguage?
) {
    const pagePointer = ps.pointers.components.getPageOfComponent(displayedPointer)
    const templateCompId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(displayedPointer.id)
    const templateCompPointer = ps.pointers.full.components.getComponent(templateCompId, pagePointer)

    _.forEach(dataPropsToUpdate, function (propToUpdate) {
        updateDataItemFromDisplayedToFull(
            ps,
            pagePointer.id,
            displayedPointer,
            templateCompPointer,
            propToUpdate,
            useLanguage
        )
    })
}

function isDescendantOfFirstRepeaterItem(ps: PS, repeaterPointer: Pointer, compPointer: Pointer) {
    const repeaterItems = ps.pointers.components.getChildren(repeaterPointer)
    if (_.size(repeaterItems) > 0) {
        const firstDisplayedItem = repeaterItems[0]
        return (
            santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(firstDisplayedItem.id) ===
            santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(compPointer.id)
        )
    }
    return false
}

const getRepeaterAncestor = (ps: PS, comp) => {
    const pointers = santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(comp.id)
        ? ps.pointers
        : ps.pointers.full
    return pointers.components.getAncestorByPredicate(
        comp,
        ancestor => dsUtils.getComponentType(ps, ancestor) === REPEATER_TYPE
    )
}

function isFirstDisplayedItemOfRepeater(ps: PS, compPointer: Pointer) {
    if (santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)) {
        const repeaterPointer = getRepeaterAncestor(ps, compPointer)
        if (repeaterPointer) {
            if (isDescendantOfFirstRepeaterItem(ps, repeaterPointer, compPointer)) {
                return true
            }
        }
    }
    return false
}

function syncRepeaterTemplateDataWithFirstItem(propertyToUpdate, ps: PS, compPointer: Pointer, dataItem, useLanguage) {
    if (
        !experiment.isOpen('dm_preventSyncingRepeaterTemplateWithFirstItem') &&
        isFirstDisplayedItemOfRepeater(ps, compPointer)
    ) {
        updateTemplateAccordingToFirstRepeaterItem(ps, compPointer, [propertyToUpdate], useLanguage)
    }
}

function syncAllRepeaterTemplateCompsWithFirstItem(ps: PS, repeaterPointer: Pointer) {
    const children = ps.pointers.components.getChildren(repeaterPointer)
    if (_.size(children) > 0) {
        const compsToSync = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(children[0])
        _.forEach(compsToSync, function (compPointer) {
            updateTemplateAccordingToFirstRepeaterItem(ps, compPointer, ['dataQuery', 'designQuery'])
        })
    }
}

function afterAdd(ps: PS, componentRef: Pointer) {
    if (!experiment.isOpen('dm_preventSyncingRepeaterTemplateWithFirstItem')) {
        syncAllRepeaterTemplateCompsWithFirstItem(ps, componentRef)
    }
}

const getRepeatedItemPointer = (pointer: Pointer, itemId: string) => {
    return {...pointer, id: santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(pointer.id, itemId)}
}

const isRemovingMobileInstanceOfDesktopComp = (ps: PS, compPointer: Pointer) =>
    ps.pointers.components.isMobile(compPointer) && !componentsMetaData.public.isMobileOnly(ps, compPointer)

const beforeRemove = (ps: PS, component) => {
    if (isRemovingMobileInstanceOfDesktopComp(ps, component)) {
        return
    }
    const repeaterAncestor = getRepeaterAncestor(ps, component)
    if (repeaterAncestor) {
        const repeaterData = dataModel.getDataItem(ps, repeaterAncestor)
        const {items} = repeaterData
        const templateDataItemPointer = dataModel.getDataItemPointer(ps, component)
        const templateDesignItemPointer = dataModel.getDesignItemPointer(ps, component)
        items.forEach((itemId: string) => {
            if (ps.dal.isExist(templateDataItemPointer)) {
                const repeatedDataItemPointer = getRepeatedItemPointer(templateDataItemPointer, itemId)
                dataModel.removeItemRecursivelyByType(ps, repeatedDataItemPointer)
            }

            if (ps.dal.isExist(templateDesignItemPointer)) {
                const repeatedDesignItemPointer = getRepeatedItemPointer(templateDesignItemPointer, itemId)
                dataModel.removeItemRecursivelyByType(ps, repeatedDesignItemPointer)
            }
        })
    }
}

const beforeGetRepeatedItemPointerHook = (ps: PS, {}: BeforeGetRepeatedPointerEvent, componentPointer: Pointer) =>
    dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)

export default {
    afterAdd,
    beforeGetRepeatedItemPointerHook,
    beforeRemove,
    addRepeatedDataItems,
    afterLayoutChanged,
    beforeUpdateRepeaterData,
    afterUpdateRepeaterData,
    syncTranslationsAfterAdd,
    syncRepeaterTemplateDataWithFirstItem: syncRepeaterTemplateDataWithFirstItem.bind(this, 'dataQuery'),
    syncRepeaterTemplateDesignWithFirstItem: syncRepeaterTemplateDataWithFirstItem.bind(this, 'designQuery')
}
