import type {CreateExtensionArgument, DAL, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointers} from '@wix/document-services-types'
import type {ViewerManager} from '@wix/viewer-manager-adapter'

export * from './extensions/csave/serverProtocol'
export {EnvironmentExtensionAPI} from './extensions/environment'
export {DocumentServicesModelExtApi} from './extensions/documentServicesModel'
export {CSaveApi} from './extensions/csave/continuousSave'
export {ValidationWhitelistExtensionAPI} from './extensions/validationWhitelist'
export {ViewsExtensionAPI} from './extensions/views/views'
export type {ParsedSchemaValidationErrorMessage} from './extensions/schema/schema'
export type {BlocksAppDescriptor} from './extensions/blocks/types'
export type {SaveTransactionResponse} from './extensions/csave/csaveTypes'
export type {Channel as DuplexerChannel} from './extensions/channelUtils/duplexer'

export interface DalPointers {
    dal: DAL
    pointers: Pointers
}

export interface DalPointersWithExtensionAPI {
    dal: DAL
    pointers: Pointers
    extensionAPI: ExtensionAPI
}

export interface PageModel {
    pageId: string
    title: string
    pageUriSEO: string
    pageJsonFileName: string
    parentPageId?: string
    isPopup?: boolean
}

export interface CreateViewerExtensionArgument extends CreateExtensionArgument {
    viewerManager: ViewerManager
}

export interface ViewerExtensionFactory {
    createExtension(createViewerExtensionArgument: CreateViewerExtensionArgument): Extension
}

type Atom = null | string | number | boolean

export interface Serializable {
    [key: string]: Atom | Serializable[] | Serializable
}

export type Namespace = Record<string, Serializable>

export type Namespaces = Record<string, Namespace>
