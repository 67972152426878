import {ComponentAfterAddDataEvent, COMPONENTS_HOOKS, type RefComponentAfterAddDataEvent} from '../hooks'
import type {CreateExtArgs} from '@wix/document-manager-core'
import _ from 'lodash'
import type {DeserializationMappers, Pointer} from '@wix/document-services-types'
import type {RefOverridesExtensionAPI} from '../../refOverrides'
import type {HooksExtensionApi} from '../../hooks/hooks'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import type {NicknamesExtensionAPI} from '../../nicknames'
import {getComponentType} from '../../../utils/dalUtils'
import {VIEW_MODES} from '../../../constants/constants'
const copyOverriddenData = (
    createExtArgs: CreateExtArgs,
    compToAddPointer: Pointer,
    serializedOverriddenData: any,
    originalNicknameContext: any,
    mappers: DeserializationMappers
) => {
    const {pointers, extensionAPI} = createExtArgs
    const {refOverrides} = extensionAPI as RefOverridesExtensionAPI
    const pageId = pointers.structure.getPageOfComponent(compToAddPointer).id
    _.forEach(serializedOverriddenData, ({compId, itemType, dataItem, isMobile}) => {
        refOverrides.createOverrideDataItem(
            compToAddPointer,
            compId,
            itemType,
            pageId,
            dataItem,
            isMobile,
            originalNicknameContext,
            mappers
        )
    })
}

const generateConnectionOverrideForRefComponent = (
    createExtArgs: CreateExtArgs,
    data: ComponentAfterAddDataEvent,
    pagePointer: Pointer
) => {
    const {compToAddPointer} = data
    const {extensionAPI, dal, pointers} = createExtArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {nicknames} = extensionAPI as NicknamesExtensionAPI
    const {refOverrides} = extensionAPI as RefOverridesExtensionAPI
    const componentData = dataModel.components.getItem(compToAddPointer, 'data')
    let rootId
    let rootComponentType
    const nicknameGeneratorProvider = refOverrides.nickname.getNicknameContextGenerator()
    if (nicknameGeneratorProvider) {
        return nicknameGeneratorProvider(data)
    } else if (componentData.type === 'InternalRef') {
        rootId = componentData.rootCompId
        rootComponentType = getComponentType(dal, {id: rootId, type: VIEW_MODES.DESKTOP})
    } else {
        const {appDefinitionId, widgetId} = componentData
        const removeWidgetMetaData = dal.get(
            pointers.remoteStructureMetaData.getRemoteStructureWidgetMetaData(appDefinitionId, widgetId)
        )
        if (!removeWidgetMetaData) {
            throw new Error('Need to load remote widget meta data for nickname creation')
        }
        rootId = removeWidgetMetaData.rootId
        rootComponentType = removeWidgetMetaData.rootType
    }
    nicknames.generateConnectionOverrideForRefComponentRoot(compToAddPointer, pagePointer, rootId, rootComponentType)
}

export const handleDataOverridesAfterAdd = (createExtArgs: CreateExtArgs, data: ComponentAfterAddDataEvent) => {
    const {extensionAPI, pointers} = createExtArgs
    const {compToAddPointer, componentDefinition, mappers} = data
    // @ts-ignore
    const {overriddenData, originalNicknameContext} = componentDefinition.custom || {}
    if (overriddenData) {
        copyOverriddenData(createExtArgs, compToAddPointer, overriddenData, originalNicknameContext, mappers)
    }
    const pagePointer = pointers.structure.getPageOfComponent(compToAddPointer)
    generateConnectionOverrideForRefComponent(createExtArgs, data, pagePointer)
    const {hooks} = extensionAPI as HooksExtensionApi
    hooks.executeHook(
        COMPONENTS_HOOKS.ADD_COMPONENT.REF_COMPONENT_AFTER.createEvent(data as unknown as RefComponentAfterAddDataEvent)
    )
}
