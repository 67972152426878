import type {PublicMethodUtils} from '@wix/document-services-types'
import {component, utils, page, idGenerator, tpa, tpaCore} from '@wix/document-services-implementation'

const deprecateAppIdMessage =
    'this public api is deprecated, please use appDefinitionId version of this api under tpaV2'
export default function (publicMethodUtils: PublicMethodUtils) {
    const {defineGetter, defineAction, defineDataManipulationAction, deprecate} = publicMethodUtils

    return {
        methods: {
            tpa: {
                handlers: defineGetter(tpaCore.handlers),
                initEditor: defineAction(tpaCore.initEditor),
                /**
                 *
                 * @return if a component type is a tpa
                 */
                isTpaByCompType: defineGetter(tpa.isTpaByCompType),

                /**
                 * @return if a page hideFromMenu flag is true in the client spec map
                 */
                isPageMarkedAsHideFromMenu: deprecate(
                    defineGetter(tpa.isPageMarkedAsHideFromMenu.byAppId),
                    deprecateAppIdMessage
                ),

                /**
                 * return if the container contains at least one premium tpa app
                 *
                 * @param {Object} containerPointer - container reference
                 * @return return if the container contains at least on premium tpa app
                 * @example documentServices.tpa.isContainerContainsPremiumTpa(containerPointer)
                 */
                isContainerContainsPremiumTpa: defineGetter(tpa.isContainerContainsPremiumTpa),

                /**
                 *
                 * @param {string} deviceType - 'desktop' or 'mobile'
                 * @example documentServices.tpa.setTpaDeviceType('mobile')
                 */
                setDeviceType: defineGetter(tpa.setDeviceType),

                /**
                 * return all premium TPA child components of a given component ref.
                 *
                 * @param {Object} containerPointer - container reference
                 * @return all container's premium TPA child components
                 * @example documentServices.tpa.getPremiumTpaChildren(componentsPointers)
                 */
                getPremiumTpaChildren: defineGetter(tpa.getPremiumTpaChildren),

                /**
                 * recursively searches for tpa components among the given components and returns their pointers
                 *
                 * @param {Object} componentPointers - pointers of components
                 * @return pointers for all TPA components that were found
                 * @example documentServices.tpa.getTpaPointersRecursive(componentPointers)
                 */
                getTpaPointersRecursive: defineGetter(tpa.getTpaPointersRecursive),

                /**
                 * filter out from tpa app premiums comps that are sub components.
                 * for example: sub component of hotels section
                 *
                 * @param {Object} containerPointer - container reference
                 * @param tpaApplicationId {String} - tpaApplicationId on page
                 * @param filterSubComponents {Boolean} - indicate if to filter sub components
                 * @return all container's TPA premium child components filtering out the sub components if required
                 * @example documentServices.tpa.getPremiumTpaChildrenOnPage(pagePointer, tpaApplicationId, filterSubComponents)
                 */
                getPremiumTpaChildrenOnPage: deprecate(
                    defineGetter(tpa.getPremiumTpaChildrenOnPage.byAppId),
                    deprecateAppIdMessage
                ),

                /**
                 * close all opened popups and modal
                 *
                 * @example documentServices.tpa.closePopupsAndModal()
                 */
                closePopupsAndModal: defineAction(tpa.closePopupsAndModal),

                /**
                 * Add widget to the document if it was provisioned (e.g. from My Account) but not installed
                 *
                 * @param {string} appDefinitionId of the widget being added
                 * @param {Object} [options]
                 * {String} [widgetId] of the widget being added
                 *
                 * @example documentServices.tpa.addSystemApp('135aad86-9125-6074-7346-29dc6a3c9bcf',
                 * { widgetId: '135aae78-42c9-63b5-d09a-77233cebc1c4' })
                 */
                addSystemApp: defineDataManipulationAction(tpa.addSystemApp, {
                    isUpdatingAnchors: utils.YES,
                    isAsyncOperation: true,
                    asyncPreDataManipulation: tpa.provisionSystemApp,
                    getReturnValue: component.getComponentToAddRef
                }),

                /**
                 * widget
                 * @member documentServices.tpa.widget
                 * @namespace documentServices.tpa.widget
                 */
                widget: {
                    /**
                     * Add widget to the document
                     * For displaying the new widget refresh the site
                     *
                     * @param {string} appDefinitionId of the widget being added
                     * @param {Object} [options]
                     * {String} [widgetId] of the widget being added
                     * {Object} [layout] object describes the app width, height, x and y location - if no layout is given a default one will be taken.
                     * {String} [optionalCustomId] determine the added component comp id.
                     * {String} [pageId] Wix pageId that the widget should be added to
                     * @example documentServices.tpa.widget.add('135aad86-9125-6074-7346-29dc6a3c9bcf',
                     * { widgetId: '135aae78-42c9-63b5-d09a-77233cebc1c4', pageId: 'mainPage', layout: {'width': 300, 'height': 200, 'x': 200, 'y': 200 } })
                     */
                    add: defineDataManipulationAction(tpa.widget.add, {
                        isUpdatingAnchors: utils.YES,
                        isAsyncOperation: true,
                        asyncPreDataManipulation: tpa.widget.provisionWidget,
                        getReturnValue: tpa.getCompToAddWithOptionalCustomIdRef,
                        /**
                         * @param {ps} ps
                         * @param {object} data
                         * @param {string} appDefinitionId
                         * @return {{app_id: string}}
                         */
                        getInteractionParams: tpa.app.getAddTPAInteractionParams
                    }),

                    /**
                     * Removes a tpa widget from a given compId.
                     * @param {Object} compPointer of the widget
                     *
                     * @example
                     * documentServices.tpa.widget.delete(compPointer, onSuccess, onError)
                     */
                    delete: defineDataManipulationAction(tpa.widget.delete, {
                        isUpdatingAnchors: utils.YES,
                        isAsyncOperation: true,
                        asyncPreDataManipulation: tpa.widget.notifyBeforeDelete,
                        /**
                         * @param {ps} ps
                         * @param {object} compRef
                         * @return {{app_id: string, comp_pointer: object}}
                         */
                        getInteractionParams: tpa.widget.getTPAWidgetDeleteInteractionParams
                    }),

                    /**
                     * Returns an array with the pages that will be removed when the widget is removed
                     * @param {Object} compPointer of the widget

                     * @example
                     * documentServices.tpa.widget.getPagesToBeDeleted(compPointer)
                     */
                    getPagesToBeDeleted: defineGetter(tpa.widget.getPagesToBeDeleted),

                    /**
                     * Duplicates the given widget into the given page.
                     *
                     * @param {object} compPointer of the Widget id in query
                     * @param {string} pageId of the page to paste in
                     * @example
                     * documentServices.tpa.widget.duplicate(compPointer,'c1qpo')
                     */
                    duplicate: defineDataManipulationAction(tpa.widget.duplicate, {
                        isUpdatingAnchors: utils.YES
                    }),
                    /**
                     * get serialize structure containing tpa. clone the tpa data and add the serialized structure
                     *
                     * @param {object} containerPointer comp container
                     * @param {object} serializeStructure structure of the compToAdd
                     * @param {string} [customId] optional customId
                     * @param {Function} [callback] callback to be invoked on finish
                     * @example
                     * documentServices.tpa.widget.addAndCloneCompData(compPointer, compstructue, null, ()=> {})
                     */
                    addAndCloneCompData: publicMethodUtils.defineDataManipulationAction(
                        tpa.widget.addAndCloneTpaCompData,
                        {
                            getReturnValue: component.getComponentToAddRef,
                            isUpdatingAnchors: true,
                            isAsyncOperation: true,
                            asyncPreDataManipulation: tpa.widget.cloneTpaCompData
                        }
                    )
                },

                /**
                 * section
                 * @member documentServices.tpa.section
                 * @namespace documentServices.tpa.section
                 */
                section: {
                    /**
                     * Add section to the document and navigate to it
                     *
                     * @param {string} appDefinitionId of the widget being added
                     * @param {Object} [options]
                     * {String} [widgetId] of the multi multi section being added
                     * @return an object contains the new added page id that contains the new section and the section id
                     * @example
                     * documentServices.tpa.section.add('135aad86-9125-6074-7346-29dc6a3c9bcf')
                     */
                    add: defineDataManipulationAction(tpa.section.add, {
                        isUpdatingAnchors: utils.YES,
                        isAsyncOperation: true,
                        asyncPreDataManipulation: tpa.section.provisionSection,
                        getReturnValue: page.getPageIdToAdd,
                        /**
                         * @param {ps} ps
                         * @param {object} data
                         * @param {string} appDefinitionId
                         * @return {{app_id: string}}
                         */
                        getInteractionParams: tpa.app.getAddTPAInteractionParams
                    }),

                    /**
                     * Add section to the document and navigate to it
                     *
                     * @param {string} appDefinitionId of the widget being added
                     * @param {Object} options
                     * @param {String} [pageId] of the multi multi section being added
                     * @param {String} [title] of the new page
                     * @example
                     * const options = {
                     *   pageId: 'tpaPageId',
                     *   title: 'title'
                     * }
                     * documentServices.tpa.section.addMultiSection('appDef', options)
                     */
                    addMultiSection: defineDataManipulationAction(tpa.section.addMultiSection, {
                        isUpdatingAnchors: utils.YES,
                        isAsyncOperation: true,
                        asyncPreDataManipulation: tpa.section.provisionMultiSection,
                        getReturnValue: page.getPageIdToAdd
                    }),
                    /**
                     * Add sub section to the document
                     *
                     * @param {string} appDefinitionId of the section being added
                     * @param {Object} options
                     * @param {String} [pageId] of the sub section being added
                     * @param {String} [title] of the new page
                     * @example
                     * const options = {
                     *   pageId: 'tpaPageId',
                     *   title: 'title'
                     * }
                     * documentServices.tpa.section.addSubSection('appDef', options)
                     */
                    addSubSection: defineDataManipulationAction(tpa.section.addSubSection, {
                        isUpdatingAnchors: utils.YES,
                        getReturnValue: page.getPageIdToAdd
                    }),

                    /**
                     * Removes a section for a given page id.
                     *
                     * @param {string} pageId of the tpa section
                     * @param {Object} [options]
                     * @example
                     * documentServices.tpa.deleteSection('k66c')
                     */
                    delete: defineDataManipulationAction(tpa.section.delete, {
                        isAsyncOperation: true,
                        asyncPreDataManipulation: tpa.section.notifyBeforeDelete,
                        isUpdatingAnchors: utils.YES,
                        /**
                         * @param {ps} ps
                         * @param {string} pageId
                         * @return {{app_id: string, page_id: string}}
                         */
                        getInteractionParams: tpa.section.getTPAWidgetDeleteInteractionParams
                    }),
                    /**
                     * check if the section is already installed in the site
                     *
                     * @param {string} appDefinitionId of the app
                     *
                     * @example
                     * documentServices.tpa.section.alreadyInstalled('135aad86-9125-6074-7346-29dc6a3c9bcf')
                     */
                    alreadyInstalled: defineGetter(tpa.section.alreadyInstalled),
                    /**
                     * checks if a tpa section page is already installed in the site
                     *
                     * @param {Number} applicationId of the app
                     * @param {string} tpaPageId of the section
                     *
                     * @example
                     * documentServices.tpa.section.isSectionInstalledByTpaPageId('1234', 'product_page')
                     */
                    isSectionInstalledByTpaPageId: deprecate(
                        defineGetter(tpa.section.isSectionInstalledByTpaPageId.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * check if the a given page id has a section installed on it.
                     *
                     * @param {string} pageId
                     *
                     * @example
                     * documentServices.tpa.section.getPageData('pageId')
                     */
                    getPageData: defineGetter(tpa.section.getPageData),

                    /**
                     * Gets the section ref on a given page
                     * @param {string} pageId
                     * @return the section on the given page or undefined if none exists
                     */
                    getSectionRefByPageId: defineGetter(tpa.section.getSectionRefByPageId),
                    defaults: {
                        skin: {
                            set: defineDataManipulationAction(tpa.section.defaults.skin.set)
                        },
                        isContainable: {
                            set: defineDataManipulationAction(tpa.section.defaults.isContainable.set)
                        }
                    }
                },

                /**
                 * app
                 * @member documentServices.tpa.app
                 * @namespace documentServices.tpa.app
                 */
                app: {
                    /**
                     * installs an application if it doesn't exist yet
                     *
                     * @param {string} appDefinitionId of the application
                     * @param [options] object which can contain 'callback', 'widgetId', 'showPageAddedPanel', 'x', 'y', 'width', 'height', 'shouldNavigate', 'pageId', 'containerRef'
                     *
                     * @example
                     * documentServices.tpa.app.add('1363adbc-c783-b1e0-d8ef-4a661300ac8c', {})
                     */
                    add: defineDataManipulationAction(tpa.app.add, {
                        isUpdatingAnchors: utils.YES,
                        isAsyncOperation: true,
                        asyncPreDataManipulation: tpa.app.provisionApp,
                        /**
                         * @param {ps} ps
                         * @param {string} appDefinitionId
                         * @return {{app_id: string}}
                         */
                        getInteractionParams: tpa.app.getAddTPAAppInteractionParams
                    }),

                    /**
                     * Gets the app data by application id
                     *
                     * @param {string} applicationId of the application
                     * @return the application data from the client spec map
                     *
                     * @example
                     * documentServices.tpa.app.getData(16)
                     */
                    getData: deprecate(defineGetter(tpa.app.getData.byAppId), deprecateAppIdMessage),

                    /**
                     * Gets the application data by app definition id
                     *
                     * @param {string} appDefinitionId of the application in query
                     *
                     * @example
                     * documentServices.tpa.app.getDataByAppDefId('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     * */

                    getDataByAppDefId: defineGetter(tpa.app.getData.byAppDefId),

                    /**
                     * Checks if this application is hybrid
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.isHybrid(16)
                     */
                    isHybrid: deprecate(defineGetter(tpa.app.isHybrid.byAppId), deprecateAppIdMessage),

                    /**
                     * Checks if this application is dashboard only
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.isDashboardAppOnly(16)
                     */
                    isDashboardAppOnly: deprecate(
                        defineGetter(tpa.app.isDashboardAppOnly.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Checks if this application was upgraded to premium
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.isPremium(16)
                     */
                    isPremium: deprecate(defineGetter(tpa.app.isPremium.byAppId), deprecateAppIdMessage),

                    /**
                     * Checks if an application has premium offering
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.hasPremiumOffering(16)
                     */
                    hasPremiumOffering: deprecate(
                        defineGetter(tpa.app.hasPremiumOffering.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Checks if this appDefinitionId was upgraded to premium
                     *
                     * @param {string} appDefinitionId of the application in query
                     * @example
                     * documentServices.tpa.app.isPremium('1380b703-ce81-ff05-f115-39571d94dfcd')
                     */
                    isPremiumByAppDefinitionId: defineGetter(tpa.app.isPremium.byAppDefId),

                    /**
                     * Checks if this application has only one component
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.isLastAppComp(16)
                     */
                    isLastAppComp: deprecate(defineGetter(tpa.app.isLastAppComp.byAppId), deprecateAppIdMessage),

                    /**
                     * Returns true if and only if the application has been installed in the site
                     *
                     * @param {string} appDefinitionId of the application
                     * @params {boolean} [filterDemoMode] filter out demo mode app
                     * @return {boolean} application was installed
                     *
                     * @example
                     * documentServices.tpa.app.isInstalled('13016589-a9eb-424a-8a69-46cb05ce0b2c')
                     */
                    isInstalled: defineGetter(tpa.app.isInstalled),

                    /**
                     * Returns widget data from pageId
                     *
                     * @param {string} applicationId of the application in query
                     * @param {string} tpaPageId of the app widget
                     * @example
                     * documentServices.tpa.getWidgetDataFromTPAPageId('appDefId', 'page_id')
                     */
                    getWidgetDataFromTPAPageId: defineGetter(tpa.app.getWidgetDataFromTPAPageId),

                    /**
                     * Returns widget data from tpaWidgetId
                     *
                     * @param {string} applicationId of the application in query
                     * @param {string} tpaPageId of the app widget
                     * @example
                     * documentServices.tpa.getWidgetDataFromTPAWidgetId('appDefId', 'tpa_widget_id')
                     */
                    getWidgetDataFromTPAWidgetId: defineGetter(tpa.app.getWidgetDataFromTPAWidgetId),
                    /**
                     * Register a callback to be called when an app is installed
                     *
                     * @param {string} appDefinitionId of the application
                     * @return {function} callback when the app was installed
                     *
                     * @example
                     * documentServices.tpa.app.registerOnAppInstalled('13016589-a9eb-424a-8a69-46cb05ce0b2c', function () {])
                     */
                    registerOnInstalled: defineGetter(tpa.app.registerOnInstalled),

                    /**
                     * Register a callback to be called when an app is removed
                     *
                     * @param {string} appDefinitionId of the application
                     * @return {function} callback when the app was installed
                     *
                     * @example
                     * documentServices.tpa.app.registerOnAppDeleted('13016589-a9eb-424a-8a69-46cb05ce0b2c', function () {])
                     */
                    registerOnDeleted: defineGetter(tpa.app.registerOnDeleted),

                    /**
                     * Get all rendered react comp on site by Application Id
                     * appDefId version: tpaV2.app.getAllComps
                     *
                     * @param {string|string[]} applicationIds - Id/s of the application/s
                     *
                     * @example
                     * documentServices.tpa.app.getRenderedReactCompsByApplicationId('18')
                     * @example
                     * documentServices.tpa.app.getRenderedReactCompsByApplicationId(['23', '42'])
                     */
                    getRenderedReactCompsByApplicationId: deprecate(
                        defineGetter(tpa.app.getAllComps.byAppId),
                        deprecateAppIdMessage
                    ),
                    /**
                     * Get all comps on site by Application Id
                     *
                     * @param {string|string[]} applicationIds - Id/s of the application/s
                     *
                     * @example
                     * documentServices.tpa.app.getAllCompsByApplicationId('18')
                     * @example
                     * documentServices.tpa.app.getRenderedReactCompsByApplicationId(['23', '42'])
                     */
                    getAllCompsByApplicationId: deprecate(
                        defineGetter(tpa.app.getAllComps.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Returns true if and only if a given app has at least one section.
                     *
                     * @example
                     * documentServices.tpa.app.hasSections(appData)
                     */
                    hasSections: defineGetter(tpa.app.hasSections),

                    getDefaultLayout: deprecate(defineGetter(tpa.app.getDefaultLayout.byAppId), deprecateAppIdMessage),

                    /**
                     * url
                     * @member documentServices.tpa.app.url
                     * @namespace documentServices.tpa.app.url
                     */
                    url: {
                        /**
                         * Gets the settings url of the app
                         *
                         * @param {string} applicationId of the application
                         * @param {string} [widgetId] of the application
                         * @return {string} the settings url
                         *
                         * @example
                         * documentServices.tpa.app.url.getSettingsUrl(16, '135aae78-42c9-63b5-d09a-77233cebc1c4', 'comp-1234')
                         */
                        getSettingsUrl: deprecate(
                            defineGetter(tpa.app.url.getSettingsUrl.byAppId),
                            deprecateAppIdMessage
                        ),
                        /**
                         * Gets the settings modal url of the app
                         *
                         * @param {object} urlParams must contain url
                         * @param {string} [compId] compId
                         * @return {string} the settings modal url
                         *
                         * @example
                         * documentServices.tpa.app.url.getSettingsModalUrl({url: 'url'}, 'comp-1234')
                         */
                        getSettingsModalUrl: defineGetter(tpa.app.url.getSettingsModalUrl)
                    },

                    /**
                     * Returns this application extensions' widgets
                     *
                     * @param {Object} appData the application data
                     * @example
                     * documentServices.tpa.app.getExtensionsWidgets({})
                     */
                    getExtensionsWidgets: defineGetter(tpa.app.getExtensionsWidgets),

                    /**
                     * Returns this application installed dependent apps appData
                     *
                     * @param {Number} applicationId the main application id
                     * @example
                     * documentServices.tpa.app.getInstalledDependentAppsData(1234)
                     */
                    getInstalledDependentAppsData: deprecate(
                        defineGetter(tpa.app.getInstalledDependentAppsData.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Gets the first application component's page id and comp id. In case the application has a section and
                     * a widget - the section's page id anc comp id are returned.
                     *
                     * @param {string} applicationId of the application in query
                     * @param [boolean] useDefaultWidget if true will return the default widget data
                     * @example
                     * documentServices.tpa.getFirstAppCompPageId('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     */
                    getFirstAppCompPageId: defineGetter(tpa.app.getFirstAppCompPageId),

                    refreshApp: defineDataManipulationAction(tpa.app.refreshApp),

                    shouldDeleteWholeApp: defineGetter(tpa.app.shouldDeleteWholeApp),

                    /**
                     * Returns true iff multi section installed (has more than one main section).
                     *
                     * @param {string} applicationId The application ID of the app
                     * @example
                     * documentServices.tpa.section.isMultiSectionInstalled('20')
                     */
                    isMultiSectionInstalled: deprecate(
                        defineGetter(tpa.app.isMultiSectionInstalled.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Returns the app sections
                     *
                     * @param {string} applicationId The application ID of the app
                     * @example
                     * documentServices.tpa.app.getSections('20')
                     */
                    getSections: deprecate(defineGetter(tpa.app.getSections.byAppId), deprecateAppIdMessage),

                    /**
                     * Returns true if app is proviosned on server and false otherwise
                     *
                     * @param {string} applicationId The application ID of the app
                     * @example
                     * documentServices.tpa.app.isAppProvisionedOnServer('20')
                     */
                    isAppProvisionedOnServer: deprecate(
                        defineGetter(tpa.app.isAppProvisionedOnServer.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Returns true if app's permissions are revoked
                     *
                     * @param {string} applicationId The application ID of the app
                     * @example
                     * documentServices.tpa.app.isPermissionsRevoked('20')
                     */
                    isPermissionsRevoked: defineGetter(tpa.app.isPermissionsRevoked),

                    /**
                     * Returns the widget data of the main section
                     *
                     * @param {string} applicationId The application ID of the app
                     * @example
                     * documentServices.tpa.app.getMainSectionWidgetData('20')
                     */
                    getMainSectionWidgetDataFromApplicationId: deprecate(
                        defineGetter(tpa.app.getMainSectionWidgetData.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Returns the widget data of the main section
                     *
                     * @param {object} appData The data of the app
                     * @example
                     * documentServices.tpa.app.getMainSectionWidgetData(appData)
                     */
                    getMainSectionWidgetData: defineGetter(tpa.app.getMainSectionWidgetData.byData),

                    /**
                     * Returns an array of the sections that should be installed automatically in the correct order
                     *
                     * @param {Number} applicationId The application ID of the app
                     * @example
                     * documentServices.tpa.app.getSectionsToAutoInstallWidgetData(applicationId)
                     */
                    getSectionsToAutoInstallWidgetData: deprecate(
                        defineGetter(tpa.app.getSectionsToAutoInstallWidgetData.byAppId),
                        deprecateAppIdMessage
                    ),

                    /**
                     * Returns true if the app has a main section
                     *
                     * @param {object} appData The data of the app
                     * @example
                     * documentServices.tpa.app.hasMainSection(appData)
                     */
                    hasMainSection: defineGetter(tpa.app.hasMainSection),

                    /**
                     *  Returns true if the app is a super app (an app Developed internally by Wix)
                     *  @param {string} compId a component ID the app is from
                     */
                    isSuperAppByCompId: defineGetter(tpa.app.isSuperAppByCompId),

                    /**
                     *  Returns true if the app has platform part
                     *  @param {object} appData The data of the app
                     */
                    hasEditorPlatformPart: defineGetter(tpa.app.hasEditorPlatformPart),

                    /**
                     * Removes all app TPA components and notify the app platform script
                     *
                     * @param {string} applicationId
                     * @param {Function} [onSuccess]
                     * @param {Function} [onError]
                     *
                     * @example
                     * documentServices.tpa.app.delete('1234', ()=> {}, ()=> {})
                     */
                    delete: publicMethodUtils.deprecate(
                        defineDataManipulationAction(tpa.app.delete.byAppId, {
                            isAsyncOperation: true,
                            asyncPreDataManipulation: tpa.app.notifyBeforeApplicationDelete.byAppId,
                            isUpdatingAnchors: utils.YES,
                            /**
                             * @param {ps} ps
                             * @param {string} appDefinitionId
                             * @return {{app_id: string}}
                             */
                            getInteractionParams: tpa.app.getTPAAppDeleteInteractionParams.byAppId
                        }),
                        'DEPRECATED. please use platform.uninstall instead'
                    )
                },

                /**
                 * change
                 * @member documentServices.tpa.change
                 * @namespace documentServices.tpa.change
                 */
                change: {
                    /**
                     * Notify the ds the editor mode has changed
                     *
                     * @param {string} editorMode the editor new mode
                     *
                     * @example
                     * documentServices.tpa.change.editMode('preview')
                     */
                    editMode: defineAction(tpa.change.editMode),

                    /**
                     * Notify the ds the site was published
                     *
                     *
                     * @example
                     * documentServices.tpa.change.sitePublished()
                     */
                    siteSaved: defineAction(tpa.change.siteSaved),

                    /**
                     * Notify the ds the site was published
                     *
                     *
                     * @example
                     * documentServices.tpa.change.sitePublished()
                     */
                    sitePublished: defineAction(tpa.change.sitePublished),

                    /**
                     * Notify the ds the editor switch from mobile to desktop or vice versa
                     *
                     *
                     * @example
                     * documentServices.tpa.change.deviceType()
                     */
                    deviceType: defineAction(tpa.change.deviceType),

                    /**
                     * register
                     * @member documentServices.tpa.change.register
                     * @namespace documentServices.tpa.change.register
                     */
                    register: {
                        /**
                         * Register for component loaded handler.
                         * Callback will be called, with an object containing compPointer property, every time a TPA calls Wix.Performance.applicationLoaded() SDK func.
                         *
                         * @param {function} callback A callback function that will get called once a comp has loaded.
                         * @example
                         * documentServices.tpa.change.register.compLoadedHandler(function(data) {
                         *      console.log(data.compPointer)
                         * })
                         */
                        compLoadedHandler: defineAction(tpa.change.register.compLoadedHandler),

                        /**
                         * Register for component delete handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once a delete comp event happens.
                         */
                        deleteCompHandler: defineAction(tpa.change.register.deleteCompHandler),

                        /**
                         * Register for editor mode change handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once the editor mode has changed.
                         */
                        editModeChangeHandler: defineAction(tpa.change.register.editModeChangeHandler),

                        /**
                         * Register for device type change handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once the device type has changed.
                         */
                        deviceTypeChangeHandler: defineAction(tpa.change.register.deviceTypeChangeHandler),

                        /**
                         * Register for editor theme change handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once the editor theme has changed.
                         */
                        themeChangeHandler: defineAction(tpa.change.register.themeChangeHandler),

                        /**
                         * Register for window placement  change handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once the editor mode has changed.
                         */
                        windowPlacementChangedHandler: defineAction(tpa.change.register.windowPlacementChangedHandler),

                        /**
                         * Register for settings update change handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once the editor mode has changed.
                         */
                        settingsUpdatedHandler: defineAction(tpa.change.register.settingsUpdatedHandler),

                        /**
                         * Register for set public data change handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once the editor mode has changed.
                         */
                        registerPublicDataChangedHandler: deprecate(
                            defineAction(tpa.change.register.registerPublicDataChangedHandler.byAppId),
                            deprecateAppIdMessage
                        ),

                        /**
                         * Register for site published handler.
                         *
                         * @param {String} compId of the component
                         * @param {function} handler A callback function that will get called once the site is published
                         */
                        sitePublishedHandler: defineAction(tpa.change.register.sitePublishedHandler),

                        /**
                         * Register a handler for site saved event.
                         *
                         * @param {String} compId - id of the component
                         * @param {function} handler A callback function that will get called once the site is saved
                         */
                        siteSavedHandler: defineAction(tpa.change.register.siteSavedHandler)
                    },

                    /**
                     * trigger
                     * @member documentServices.tpa.change.trigger
                     * @namespace documentServices.tpa.change.trigger
                     */
                    trigger: {
                        /**
                         * Notify the ds when an editor event happened
                         *
                         * @param {type} the type of the editor event
                         *
                         * @example
                         * documentServices.tpa.change.trigger.editorEvent({type: "ADI_SETTINGS_CLOSED"})
                         */
                        editorEvent: defineAction(tpa.change.trigger.editorEvent),

                        /**
                         * Tell the ds that the window placement was changed
                         * @param  {String} placement of the glued widget
                         */
                        windowPlacement: defineAction(tpa.change.trigger.windowPlacement),

                        /**
                         * Tell the ds that the setting app triggered an update
                         *
                         * @param {String} applicationId the app application id
                         * @param {String} targetCompId the target comp id or * to broadcast the data to all the app components
                         * @param {Object} message the data to broadcast
                         *
                         * @example
                         * const applicationId = 16
                         * const targetCompIds = 'compId'
                         * const message = {
                         *      data: 'show-all'
                         * }
                         * documentServices.tpa.triggerSettingsUpdated(applicationId, targetCompId, message)
                         */
                        settingsUpdated: deprecate(
                            defineAction(tpa.change.trigger.settingsUpdated.byAppId),
                            deprecateAppIdMessage
                        )
                    }
                },

                getComponentDefinition: defineGetter(tpa.getComponentDefinition),

                getSettingsModalParams: defineGetter(tpa.getSettingsModalParams),

                pending: {
                    /**
                     * Add a pending app
                     * @param appDefId
                     * @example
                     * documentServices.tpa.pending.add('appDefId')
                     */
                    add: defineAction(tpa.pending.add),
                    /**
                     * Dismiss a pending app from the meta-site
                     * @param appDefId
                     * @example
                     * documentServices.tpa.pending.dismiss('appDefId')
                     */
                    dismiss: defineAction(tpa.pending.dismiss),
                    /**
                     * Returns premium pending applications
                     *
                     * @return {Array} list of pending applications
                     *
                     * @example
                     * documentServices.tpa.pending.getPendingApps()
                     */
                    getPendingApps: defineGetter(tpa.pending.getPendingApps)
                },

                preAdd: {
                    /**
                     * Sets a component definition modifier function that is triggered before adding a component to the stage (using `tpa.section.add`, `tpa.widget.add` and `tpa.app.add`).
                     * Component-Definition will be enhanced with `layout` (will overwrite unless `...layout` is not present) and with `layoutResponsive` if these are returned by the `modifierFn`.
                     * also style and container ref could be modified as well
                     *
                     * @param {function} modifierFn
                     * @example
                     * documentServices.tpa.preAdd.setComponentDefinitionModifier(({containerRef, layout, componentType}) => {
                     *  // Some layout logic here
                     *  return {
                     *      layout?: {
                     *          ...layout,
                     *          x: 0,
                     *          y: 0
                     *      },
                     *      layoutResponsive?: {
                     *          componentLayouts: ...,
                     *          containerLayouts: ...,
                     *          itemLayouts: ...,
                     *          metaData: ...,
                     *          type: 'LayoutData'
                     *      },
                     *      style?: {
                     *
                     *      },
                     *      containerRef?: {
                     *          id: ...,
                     *          type: ...
                     *      }
                     *  }
                     * })
                     */
                    setComponentDefinitionModifier: defineAction(tpa.preAdd.setComponentDefinitionModifier)
                },

                /**
                 * Returns hybrid pending applications
                 *
                 * @return {Array} list of pending applications
                 * @deprecated
                 *
                 * @example
                 * documentServices.tpa.getPendingApps()
                 */
                getPendingApps: defineGetter(tpa.getPendingApps),

                /**
                 * Returns premium pending applications
                 *
                 * @return {Array} list of pending applications
                 * @deprecated
                 * @example
                 * documentServices.tpa.getPendingApps()
                 */
                getPremiumPendingApps: defineGetter(tpa.getPremiumPendingApps),

                /**
                 * AppMarket
                 * @member documentServices.tpa.appMarket
                 * @namespace documentServices.tpa.appMarket
                 */
                appMarket: {
                    /**
                     * Returns app market data for the given appDefinitionId
                     *
                     * @param {String} appDefinitionId
                     * @returns {Object} app market data
                     */
                    getData: defineGetter(tpa.appMarket.getData), //TODO: appMarketAPIs - need to log usage

                    /**
                     * Returns app market data for the given pageId
                     *
                     * @param {String} pageId
                     * @returns {Object} app market data
                     */
                    getDataForPage: defineGetter(tpa.appMarket.getDataForPage), //TODO: appMarketAPIs - need to log usage

                    /**
                     * Returns app market data for the given appDefinitionId or retrieves it async
                     *
                     * @param {String} appDefinitionId
                     * @returns Promise
                     */
                    getDataAsync: defineGetter(tpa.appMarket.getDataAsync),

                    /**
                     * Returns app market URL which is its iframe src
                     *
                     * @param {Object} editorParams Set of parameters from editor
                     * @returns String
                     * @example
                     * documentServices.tpa.appMarket.getUrl({
                     *  openAppDefId: '1234',
                     *  experiments: 'exp1',
                     *  query: 'tag1',
                     *  origin: 'http://editor.wix.com',
                     *  appDefinitionId: '1234'
                     * })
                     */
                    getUrl: defineGetter(tpa.appMarket.getUrl),

                    /**
                     * Returns app market info URL which is its iframe src
                     *
                     * @param {Object} editorParams Set of parameters from editor
                     * @returns String
                     * @example
                     * documentServices.tpa.appMarket.getInfoUrl({
                     *  openAppDefId: '1234',
                     *  experiments: 'exp1',
                     *  query: 'tag1',
                     *  origin: 'http://editor.wix.com',
                     *  appDefinitionId: '1234'
                     * })
                     */
                    getInfoUrl: defineGetter(tpa.appMarket.getInfoUrl),

                    /**
                     * Returns the app info URL, on the reviews tab
                     *
                     * @param {Object} editorParams Set of parameters from editor:
                     * origin (mandatory) ,
                     * openMarketOrigin (mandatory),
                     * test (optional)
                     * @returns String
                     * @example
                     * documentServices.tpa.appMarket.getAppReviewsUrl({
                     *  origin: 'http://editor.wix.com',
                     *  openMarketOrigin: 'settings_panel,
                     *  tests: 'exp1'
                     * })
                     */
                    getAppReviewsUrl: defineGetter(tpa.appMarket.getAppReviewsUrl),

                    /**
                     * Returns app market permissions URL which is its iframe src
                     *
                     * @param {Object} editorParams Set of parameters from editor
                     * @returns String
                     * @example
                     * documentServices.tpa.appMarket.getPermissionsUrl({
                     *  openAppDefId: '1234',
                     *  experiments: 'exp1',
                     *  query: 'tag1',
                     *  origin: 'http://editor.wix.com',
                     *  appDefinitionId: '1234'
                     * })
                     */
                    getPermissionsUrl: defineGetter(tpa.appMarket.getPermissionsUrl),

                    /**
                     * Returns app market related apps
                     * @returns Array
                     * @example
                     * documentServices.tpa.appMarket.getRelatedApps(function(apps){console.log(apps)})
                     */
                    getRelatedApps: deprecate(
                        defineGetter(tpa.appMarket.getRelatedApps),
                        'getRelatedApps API is going to change the response soon due to a change in appMarket API'
                    ),

                    /**
                     * Returns app market packages with purchase url and currency info for the given appDefinitionId and instanceId
                     *
                     * @param {String} appDefinitionId
                     * @param {String} instanceId
                     * @returns Promise
                     * @example
                     * documentServices.tpa.appMarket.getPackages('appDefId','instanceId')
                     */
                    getPackages: defineGetter(tpa.appMarket.getPackages)
                },

                billing: {
                    /**
                     * Returns premium apps
                     * @returns Array containing appDefinitionIds of upgraded apps
                     * @example
                     * documentServices.tpa.billing.getPremiumApps(function(appDefIds){console.log(appDefIds)})
                     */
                    getPremiumApps: defineGetter(tpa.billing.getPremiumApps),

                    /**
                     * Returns the meta site upgrade url
                     * @param {Object} url params referralAdditionalInfo
                     * @returns String
                     * @example
                     * documentServices.tpa.billing.getSiteUpgradeUrl({referralAdditionalInfo : 'gfpp'})
                     */
                    getSiteUpgradeUrl: defineGetter(tpa.billing.getSiteUpgradeUrl),

                    /**
                     * Gets upgrade to premium url for an app.
                     *
                     * @function
                     * @memberof documentServices.tpa
                     * @param {string} applicationId
                     * @param {string} vendorProductId
                     * @param {string} paymentCycle could be 'YEARLY' or 'MONTHLY', default: 'MONTHLY'
                     * @parma {Object} options param like pp_type and referralAdditionalInfo
                     * @return {string} upgrade page payment url
                     *
                     * @example
                     * documentServices.tpa.billing.getAppUpgradeUrl(16, 'Premium1', 'MONTHLY', options)
                     */
                    getAppUpgradeUrl: deprecate(
                        defineGetter(tpa.billing.getAppUpgradeUrl.byAppId),
                        deprecateAppIdMessage
                    )
                },

                /**
                 * style
                 * @member documentServices.tpa.style
                 * @namespace documentServices.tpa.style
                 */
                style: {
                    /**
                     * Set style param handler for SDK 1.22.0-1.24.0.
                     *
                     * @param {String} compId the component id
                     * @param {Object} data the style param data.
                     */
                    setParamOldSDK: defineDataManipulationAction(tpa.style.setParamOldSDK, {
                        getReturnValue: idGenerator.getStyleIdToAdd
                    }),

                    setStyleParam: defineDataManipulationAction(tpa.style.setStyleParam),

                    setCompStyleParam: defineDataManipulationAction(tpa.style.setCompStyleParam),

                    mapStyleParamData: defineGetter(tpa.style.mapStyleParamData),

                    mapWixParamsToCssValues: defineGetter(tpa.style.mapWixParamsToCssValues),

                    /**
                     * Returns theme and style data to the given component
                     *
                     * @param {String} component id
                     * @returns Object style data
                     */
                    get: defineGetter(tpa.style.get),

                    /**
                     * Returns theme and style data to the given component
                     *
                     * @param componentPointer - component reference
                     * @returns Object style data
                     */
                    getByComp: defineGetter(tpa.style.getByComp),

                    postBackThemeData: defineAction(tpa.style.postBackThemeData),

                    /**
                     * Returns Name To FontsKey Map
                     *
                     * @returns Returns Name To FontsKey Map
                     */
                    getNameToFontsKeyMap: defineGetter(tpa.style.getNameToFontsKeyMap),

                    /**
                     * Returns a map of tpa colors to site colors
                     *
                     * @returns Returns Name To FontsKey Map
                     */
                    getTpaColorsToSiteColors: defineGetter(tpa.style.getTpaColorsToSiteColors)
                },

                provision: {
                    refreshAppSpecMap: deprecate(
                        defineAction(tpa.provision.refreshAppSpecMap.byAppId),
                        deprecateAppIdMessage
                    )
                },

                /**
                 * Tpa constants
                 *
                 * Constants Definitions for tpa components:
                 * TYPE: Describe the tpa type constants: TPASection, TPAMultiSection and etc
                 * @example documentServices.tpa.constants.TYPE.TPA_WIDGET
                 *
                 * COMP_TYPES: Describes tpa component types: 'wysiwyg.viewer.components.tpapps.TPASection', 'wysiwyg.viewer.components.tpapps.TPAMultiSection'
                 * @example documentServices.tpa.constants.COMP_TYPE.TPA_WIDGET
                 *
                 * TPA_COMP_TYPES: Deprecated - Describes tpa component types: 'tpa.viewer.components.tpapps.TPASection', 'tpa.viewer.components.tpapps.TPAMultiSection'
                 * @example documentServices.tpa.constants.TPA_COMP_TYPE.TPA_WIDGET
                 *
                 * SKINS: Describes tpa component skins: 'wysiwyg.viewer.skins.TPASectionSkin', 'wysiwyg.viewer.skins.TPAWidgetSkin'
                 * @example documentServices.tpa.constants.SKINS.TPA_WIDGET
                 */
                constants: tpa.constants,

                /**
                 * Tpa BI error and events constants
                 *
                 */
                bi: {
                    /*
                     * TPA Bi errors constants
                     *
                     * @example documentServices.tpa.bi.errors.EVENT_TYPE_NOT_SUPPORTED
                     */
                    errors: tpa.bi.errors,

                    /*
                     * Send a BI event for a message to the SDK endpoint from a specified origin
                     *
                     * @example documentServices.tpa.bi.sendBIEvent(msg, 'editor')
                     */
                    sendBIEvent: defineAction(tpa.bi.sendBIEvent, {isUpdatingData: false})
                },

                comp: {
                    /*
                     * resize a comp
                     *
                     * @example documentServices.tpa.comp.resize(compPointer, {width: 100, height: 100})
                     */
                    resize: defineAction(tpa.comp.resize),

                    /*
                     * set a comp externalId
                     *
                     * @example documentServices.tpa.comp.setExternalId(compPointer, '123-456-789')
                     */
                    setExternalId: defineAction(tpa.comp.setExternalId),

                    /*
                     * get a comp externalId if one is set
                     *
                     * @example documentServices.tpa.comp.getExternalId(compPointer)
                     */
                    getExternalId: defineGetter(tpa.comp.getExternalId),
                    /**
                     * Used for checking if a certain component from an app was added to the site
                     *
                     * @param {String} widgetId of the component
                     * @return {Boolean} true if the widget is installed, false otherwise
                     * @example documentServices.tpa.comp.isInstalled('135aad86-9125-6074-7346-29dc6a3c9bcf')
                     */
                    isInstalled: defineGetter(tpa.comp.isInstalled),

                    /**
                     * Adds a tpa widget or section to the site. if the comp application doesn't already exist, it will first install the application.
                     *
                     * @param [options] object which can contain:
                     * componentType,
                     *  copyStyle,
                     *  styleId,
                     *  widget - object containing widgetId, allPages, wixPageId
                     *  page - object containing title, pageId
                     *
                     * @example
                     * documentServices.tpa.comp.add({
                     *  appDefinitionId: '1380b703-ce81-ff05-f115-39571d94dfcd',
                     *  componentType: 'PAGE',
                     *  page: {pageId:'order_history'},
                     *  callback: console.log
                     *  }
                     * })
                     *
                     * documentServices.tpa.comp.add({
                     *  appDefinitionId: '1380b703-ce81-ff05-f115-39571d94dfcd',
                     *  componentType: 'WIDGET',
                     *  widget: {widgetId:'grid_gallery'},
                     *  callback: console.log
                     *  }
                     * })
                     */
                    add: defineDataManipulationAction(tpa.comp.add, {
                        isUpdatingAnchors: utils.YES,
                        isAsyncOperation: true,
                        asyncPreDataManipulation: tpa.comp.provisionComp
                    })
                },
                data: {
                    setMultiple: defineDataManipulationAction(tpa.data.setMultiple, {
                        isUpdatingAnchors: utils.DONT_CARE
                    }),
                    set: defineDataManipulationAction(tpa.data.set.byCompPointer, {
                        isUpdatingAnchors: utils.DONT_CARE
                    }),
                    app: {
                        get: deprecate(defineGetter(tpa.data.app.get.byAppId), deprecateAppIdMessage),
                        getMulti: deprecate(defineGetter(tpa.data.app.getMulti.byAppId), deprecateAppIdMessage)
                    },
                    comp: {
                        get: defineGetter(tpa.data.comp.get),
                        getMulti: defineGetter(tpa.data.comp.getMulti)
                    },
                    getPublicData: deprecate(defineGetter(tpa.data.getPublicData.byAppId), deprecateAppIdMessage),
                    remove: defineAction(tpa.data.remove),
                    SCOPE: tpa.data.SCOPE,

                    // TODO: remove once santa-editor version is deployed
                    get: defineGetter(tpa.data.get),
                    getMulti: defineGetter(tpa.data.getMulti)
                },
                /**
                 * page
                 * @member documentServices.tpa.page
                 * @namespace documentServices.tpa.page
                 */
                page: {
                    /**
                     * @param {String} pageId is a page ID.
                     * @returns {Array} An array of sub pages as defined in site structure.
                     * @example documentServices.tpa.page.getSubPages(pageId)
                     */
                    getSubPages: defineGetter(tpa.page.getSubPages)
                },
                getSiteMap: defineGetter(tpa.getSiteMap),
                __privates: {
                    fixEcomIfNeeded: defineDataManipulationAction(tpa.__privates.fixEcomIfNeeded, {
                        isUpdatingAnchors: utils.YES,
                        getReturnValue: page.getPageIdToAdd
                    }),
                    areDocumentServicesHandlersReady: defineGetter(tpa.__privates.areDocumentServicesHandlersReady),
                    fixProGallery: defineDataManipulationAction(tpa.__privates.fixProGallery)
                }
            },
            tpaV2: {
                /**
                 * @return if a page hideFromMenu flag is true in the client spec map
                 */
                isPageMarkedAsHideFromMenu: defineGetter(tpa.isPageMarkedAsHideFromMenu.byAppDefId),
                /**
                 * filter out from tpa app premiums comps that are sub components.
                 * for example: sub component of hotels section
                 *
                 * @param {Object} containerPointer - container reference
                 * @param tpaApplicationId {String} - tpaApplicationId on page
                 * @param filterSubComponents {Boolean} - indicate if to filter sub components
                 * @return all container's TPA premium child components filtering out the sub components if required
                 * @example documentServices.tpa.getPremiumTpaChildrenOnPage(pagePointer, tpaApplicationId, filterSubComponents)
                 */
                getPremiumTpaChildrenOnPage: defineGetter(tpa.getPremiumTpaChildrenOnPage.byAppDefId),
                /**
                 * section
                 * @member documentServices.tpa.section
                 * @namespace documentServices.tpa.section
                 */

                change: {
                    trigger: {
                        /**
                         * Tell the ds that the setting app triggered an update
                         *
                         * @param {string} applicationId The appDefId of the app
                         * @param {String} targetCompId the target comp id or * to broadcast the data to all the app components
                         * @param {Object} message the data to broadcast
                         *
                         * @example
                         * const appDefId = '1363adbc-c783-b1e0-d8ef-4a661300ac8c'
                         * const targetCompIds = 'compId'
                         * const message = {
                         *      data: 'show-all'
                         * }
                         * documentServices.tpaV2.trigger.SettingsUpdated(appDefId, targetCompId, message)
                         */
                        settingsUpdated: defineAction(tpa.change.trigger.settingsUpdated.byAppDefId)
                    }
                },
                section: {
                    /**
                     * checks if a tpa section page is already installed in the site
                     *
                     * @param {Number} applicationId of the app
                     * @param {string} tpaPageId of the section
                     *
                     * @example
                     * documentServices.tpa.section.isSectionInstalledByTpaPageId('1234', 'product_page')
                     */
                    isSectionInstalledByTpaPageId: defineGetter(tpa.section.isSectionInstalledByTpaPageId.byAppDefId),

                    /**
                     * Returns true iff multi section installed (has more than one main section).
                     *
                     * @param {string} applicationId The appDefId of the app
                     * @example
                     * documentServices.tpaV2.section.isMultiSectionInstalled('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     */
                    isMultiSectionInstalled: defineGetter(tpa.app.isMultiSectionInstalled.byAppDefId)
                },

                /**
                 * app
                 * @member documentServices.tpa.app
                 * @namespace documentServices.tpa.app
                 */
                app: {
                    /**
                     * Checks if this application is hybrid
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.isHybrid(16)
                     */
                    isHybrid: defineGetter(tpa.app.isHybrid.byAppDefId),

                    /**
                     * Checks if this application is dashboard only
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.isDashboardAppOnly(16)
                     */
                    isDashboardAppOnly: defineGetter(tpa.app.isDashboardAppOnly.byAppDefId),

                    /**
                     * Checks if this application was upgraded to premium
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.isPremium(16)
                     */
                    isPremium: defineGetter(tpa.app.isPremium.byAppDefId),

                    /**
                     * Checks if an application has premium offering
                     *
                     * @param {string} applicationId of the application in query
                     * @example
                     * documentServices.tpa.app.hasPremiumOffering(16)
                     */
                    hasPremiumOffering: defineGetter(tpa.app.hasPremiumOffering.byAppDefId),
                    /**
                     * Get all comps on site by Application Id
                     *
                     * @param {string|string[]} applicationIds - Id/s of the application/s
                     *
                     * @example
                     * documentServices.tpa.app.getAllCompsByApplicationId('18')
                     * @example
                     * documentServices.tpa.app.getRenderedReactCompsByApplicationId(['23', '42'])
                     */
                    getAllComps: defineGetter(tpa.app.getAllComps.byAppDefId),

                    /**
                     * Returns true if and only if a given app has at least one section.
                     *
                     * @example
                     * documentServices.tpa.app.hasSections(appData)
                     */
                    hasSections: defineGetter(tpa.app.hasSections),

                    /**
                     * Checks if this application has only one component
                     *
                     * @param {string} appDefinitionId of the application in query
                     * @example
                     * documentServices.tpa.app.isLastAppComp(1363adbc-c783-b1e0-d8ef-4a661300ac8c)
                     */
                    isLastAppComp: defineGetter(tpa.app.isLastAppComp.byAppDefId),

                    url: {
                        /**
                         * Gets the settings url of the app
                         *
                         * @param {string} appDefId app definition id of the application
                         * @param {string} [widgetId] of the application
                         * @return {string} the settings url
                         *
                         * @example
                         * documentServices.tpa.app.url.getSettingsUrl(16, '135aae78-42c9-63b5-d09a-77233cebc1c4', 'comp-1234')
                         */
                        getSettingsUrl: defineGetter(tpa.app.url.getSettingsUrl.byAppDefId)
                    },

                    getDefaultLayout: defineGetter(tpa.app.getDefaultLayout.byAppDefId),

                    /**
                     * Returns applications installed dependent apps appData
                     *
                     * @param {Number} applicationId the main app definition id
                     * @example
                     * documentServices.tpaV2.app.getInstalledDependentAppsData('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     */
                    getInstalledDependentAppsData: defineGetter(tpa.app.getInstalledDependentAppsData.byAppDefId),

                    /**
                     * Returns the app sections
                     *
                     * @param {string} applicationId The appDefId of app
                     * @example
                     * documentServices.tpaV2.app.getSections('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     */
                    getSections: defineGetter(tpa.app.getSections.byAppDefId),

                    /**
                     * Returns true if app is proviosned on server and false otherwise
                     *
                     * @param {string} applicationId The appDefId of app
                     * * @example
                     * documentServices.tpaV2.app.isAppProvisionedOnServer('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     */
                    isAppProvisionedOnServer: defineGetter(tpa.app.isAppProvisionedOnServer.byAppDefId),

                    /**
                     * Returns the widget data of the main section
                     *
                     * @param {string} applicationId The appDefId of app
                     * @example
                     * documentServices.tpaV2.app.getMainSectionWidgetDataByAppDefId('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     */
                    getMainSectionWidgetDataByAppDefId: defineGetter(tpa.app.getMainSectionWidgetData.byAppDefId),

                    /**
                     * Returns an array of the sections that should be installed automatically in the correct order
                     *
                     * @param {string} applicationId The appDefId of app
                     * @example
                     * documentServices.tpaV2.app.getSectionsToAutoInstallWidgetData('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
                     */
                    getSectionsToAutoInstallWidgetData: defineGetter(
                        tpa.app.getSectionsToAutoInstallWidgetData.byAppDefId
                    )
                },

                billing: {
                    /**
                     * Gets upgrade to premium url for an app.
                     *
                     * @function
                     * @memberof documentServices.tpa
                     * @param {string} applicationId
                     * @param {string} vendorProductId
                     * @param {string} paymentCycle could be 'YEARLY' or 'MONTHLY', default: 'MONTHLY'
                     * @parma {Object} options param like pp_type and referralAdditionalInfo
                     * @return {string} upgrade page payment url
                     *
                     * @example
                     * documentServices.tpa.billing.getAppUpgradeUrl(16, 'Premium1', 'MONTHLY', options)
                     */
                    getAppUpgradeUrl: defineGetter(tpa.billing.getAppUpgradeUrl.byAppDefId)
                },

                data: {
                    set: defineGetter(tpa.data.set.byAppDefId),
                    app: {
                        get: defineGetter(tpa.data.app.get.byAppDefId),
                        getMulti: defineGetter(tpa.data.app.getMulti.byAppDefId)
                    },
                    getPublicData: defineGetter(tpa.data.getPublicData.byAppDefId)
                }
            }
        },
        initMethod: tpa.initialize
    }
}
