import type {GridArea, GridTrackSize} from '@wix/document-services-types'

export type TrackType = 'rows' | 'columns'

export const getUpdatedSectionGridAreaAfterDeleteTrack = (
    gridArea: GridArea,
    trackType: TrackType,
    deletedTrackIndex: number
) => {
    const newGridArea = {...gridArea}
    if (trackType === 'columns') {
        // Update columnStart and columnEnd based on the deleted column index
        if (gridArea.columnStart > deletedTrackIndex + 1) {
            newGridArea.columnStart--
        }
        if (gridArea.columnEnd > deletedTrackIndex + 1) {
            newGridArea.columnEnd--
        }
    } else if (trackType === 'rows') {
        // Update rowStart and rowEnd based on the deleted row index
        if (gridArea.rowStart > deletedTrackIndex + 1) {
            newGridArea.rowStart--
        }
        if (gridArea.rowEnd > deletedTrackIndex + 1) {
            newGridArea.rowEnd--
        }
    }
    return newGridArea
}

export const getIndexForMandatoryPageColumn = (pageLayout: any): number => {
    const {columns} = pageLayout.containerLayout
    return columns.findIndex((column: GridTrackSize) => column.type === 'MinMaxSize') || 0
}
