import _ from 'lodash'
import {SERIALIZED_DATA_KEYS, SERIALIZED_SCOPED_DATA_KEYS} from './constants'
import {COMP_DATA_QUERY_KEYS, VARIANTS} from '../../constants/constants'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {EffectsExtensionAPI} from '../effects'

const removeBreakpointsIfCompHasVariants = (serializedComp: any) => {
    const hasBreakpoints = _.some(serializedComp?.variants, x => x?.type === VARIANTS.VARIANT_TYPES.BREAKPOINTS_DATA)
    if (hasBreakpoints) {
        delete serializedComp?.breakpoints
    }
}

const removeMetadataFromSerializedItem = (item: any) => {
    if (!_.isPlainObject(item)) {
        return
    }
    delete item.metaData
    _.forOwn(item, val => {
        if (_.isArray(val)) {
            _.forEach(val, removeMetadataFromSerializedItem)
        } else {
            removeMetadataFromSerializedItem(val)
        }
    })
}

const removeMetadata = (serializedComp: any) => {
    delete serializedComp.metaData
    _.forEach(SERIALIZED_DATA_KEYS, key => {
        if (serializedComp[key!]) {
            removeMetadataFromSerializedItem(serializedComp[key!])
        }
    })
    _.forEach(SERIALIZED_SCOPED_DATA_KEYS, key => {
        if (serializedComp[key]) {
            removeMetadataFromSerializedItem(serializedComp[key])
        }
    })
}

const sanitizeBehaviors = (serializedComp: any, extensionAPI: any, experimentInstance: any, logger: any) => {
    if (!serializedComp?.behaviors) {
        return
    }
    if (_.isString(serializedComp.behaviors)) {
        logger.interactionEnded('fixStringBehaviors', {
            extras: {
                behaviorsString: serializedComp.behaviors
            }
        })
        // support for old add panel data
        serializedComp.behaviors = extensionAPI.dataModel.createBehaviorsItem(serializedComp.behaviors)
    }
    if (!serializedComp.behaviors.items) {
        delete serializedComp.behaviors
        return
    }
    // For now this map is duplicated across those 3 files:
    // - document-services-implementation/src/component/sanitizeSerializedComponent.ts
    // - document-manager-extensions/src/extensions/components/serializedComponentSanitation.ts
    // - document-services-implementation/src/actionsAndBehaviors/actionsAndBehaviors.ts
    // and all must be maintained. When the list grows we can have a single source of truth for them
    const actionsToCleanMap = {
        screenIn: true,
        bgScrub: experimentInstance.isOpen('dm_bgScrubToMotionFixer')
    }
    const actionsToClean = Object.keys(actionsToCleanMap).filter(action => actionsToCleanMap[action])
    const itemsCleaned = JSON.parse(serializedComp.behaviors.items).filter(
        (item: any) => !actionsToClean.includes(item.action)
    )
    if (itemsCleaned.length === 0) {
        delete serializedComp.behaviors
        return
    }
    serializedComp.behaviors.items = JSON.stringify(itemsCleaned)
}

export const sanitizeSerializedComponent = (
    serializedComp: any,
    extensionAPI: ExtensionAPI,
    experimentInstance: any,
    logger: any
) => {
    delete serializedComp.activeVariants
    delete serializedComp.referredVariants
    delete serializedComp.siteWidth

    if ((extensionAPI as EffectsExtensionAPI).effects.usesNewAnimations()) {
        sanitizeBehaviors(serializedComp, extensionAPI, experimentInstance, logger)
    }

    _.forEach(COMP_DATA_QUERY_KEYS, compDataQueryKey => {
        delete serializedComp[compDataQueryKey]
    })

    removeMetadata(serializedComp)
    if (serializedComp.mobileStructure) {
        removeMetadata(serializedComp.mobileStructure)
    }
    removeBreakpointsIfCompHasVariants(serializedComp)
}
