import type {ComponentAction, ConversionDal, StructureIterators} from '../../types'
import {createImmutableProxy} from '@wix/wix-immutable-proxy'
import type {Pointer} from '@wix/document-services-types'
import type {StructureEntryItem} from '../types'
import {pointerUtils} from '@wix/document-manager-core'

export const createStructureIterators = (
    conversionDal: ConversionDal,
    rootPointer: Pointer
): StructureIterators<any> => {
    const getStructureById = (id: string) => conversionDal.get(pointerUtils.getPointerFromPointer(id, rootPointer))

    function* flatStructureIterator(comp: StructureEntryItem): Generator<StructureEntryItem> {
        yield comp

        for (const childId of comp.components || []) {
            yield* flatStructureIterator(getStructureById(childId))
        }
    }

    function* postOrderFlatStructureIterator(comp: StructureEntryItem): Generator<StructureEntryItem> {
        for (const childId of comp.components || []) {
            yield* postOrderFlatStructureIterator(getStructureById(childId))
        }

        yield comp
    }

    const forEachComponent = (action: ComponentAction<any>) => {
        for (const component of flatStructureIterator(conversionDal.get(rootPointer))) {
            action(createImmutableProxy(component))
        }
    }

    const forEachComponentAsync = async (action: ComponentAction<any>) => {
        for (const component of flatStructureIterator(conversionDal.get(rootPointer))) {
            await action(createImmutableProxy(component))
        }
    }

    const forEachComponentReverse = (action: ComponentAction<any>) => {
        for (const component of Array.from(flatStructureIterator(conversionDal.get(rootPointer))).reverse()) {
            action(createImmutableProxy(component))
        }
    }

    const forEachComponentPostOrder = (action: ComponentAction<any>) => {
        for (const component of postOrderFlatStructureIterator(conversionDal.get(rootPointer))) {
            action(createImmutableProxy(component))
        }
    }

    return {
        getRootPointer: () => rootPointer,
        forEachComponent,
        forEachComponentAsync,
        forEachComponentReverse,
        forEachComponentPostOrder
    }
}
