import type {PagesData, PS} from '@wix/document-services-types'
import _ from 'lodash'
import routersGetters from '../routers/routersGetters'
import type {PageExtensionAPI} from '@wix/document-manager-extensions'
import mlUtils from '../utils/multilingual'

export type PageDataChangedCallback = (
    ps: PS,
    pageId: string,
    changedData: any,
    useOriginalLanguage?: boolean,
    applyChangeToAllLanguages?: boolean
) => void

export type PageAddedCallback = (ps: PS, pageId: string, addMenuItem: boolean) => void

let pageDataChangedCallback: PageDataChangedCallback, pageAddedCallback: PageAddedCallback

// checking if environment supports unicode. IE11 does not
// eslint-disable-next-line prefer-regex-literals
const unicodeSupported = !_.isUndefined(RegExp('').unicode)
const getIllegalCharacterPattern = () => (unicodeSupported ? /[^\p{L}\p{M}0-9\-]/gu : /[^a-zA-Z0-9\-]/g)
const getStartingIllegalCharactersPattern = () => (unicodeSupported ? /^[^\p{L}\p{M}0-9]*/gu : /^[a-zA-Z0-9]*/g)
const getEndingIllegalCharactersPattern = () => (unicodeSupported ? /[^\p{L}\p{M}0-9]*$/gu : /[^a-zA-Z0-9]*$/g)

const uriHasIllegalCharacters = (pageUriSEO: string) => getIllegalCharacterPattern().test(pageUriSEO)

function registerPageDataChangedCallback(callback: PageDataChangedCallback) {
    pageDataChangedCallback = callback
}

function executePageDataChangedCallback(
    ps: PS,
    pageId: string,
    data: Partial<PagesData>,
    useOriginalLanguage = false,
    applyChangeToAllLanguages = false
) {
    if (_.isFunction(pageDataChangedCallback)) {
        pageDataChangedCallback(ps, pageId, data, useOriginalLanguage, applyChangeToAllLanguages)
    }
}

function registerPageAddedCallback(callback: PageAddedCallback) {
    pageAddedCallback = callback
}

function executePageAddedCallback(ps: PS, pageId: string, addMenuItem: boolean) {
    if (pageAddedCallback) {
        pageAddedCallback(ps, pageId, addMenuItem)
    }
}

function getHomepageId(ps: PS) {
    return ps.extensionAPI.page.getMainPageId()
}

function isHomepage(ps: PS, pageId: string) {
    return pageId === getHomepageId(ps)
}

function isMasterPage(ps: PS, pageId: string) {
    return pageId === 'masterPage'
}

function convertPageNameToUrl(ps: PS, title: string = '') {
    const fixedTitle = title ? title : '' // defaulting to '' for every falsy value
    const uri = fixedTitle
        .toLowerCase()
        .trim()
        .replace(getStartingIllegalCharactersPattern(), '')
        .replace(getEndingIllegalCharactersPattern(), '')
        .replace(/\s+/g, '-')
        .replace(getIllegalCharacterPattern(), '-')
        .replace(/-+/gu, '-')

    return uri || 'blank'
}

function getCurrentUrl(ps: PS, urlFormat: string, baseUrl: string) {
    return (ps.extensionAPI as PageExtensionAPI).siteAPI.getPageUrl(ps.siteAPI.getCurrentUrlPageId(), baseUrl)
}

function getMainPageUrl(ps: PS, urlFormat: string, baseUrl: string) {
    return (ps.extensionAPI as PageExtensionAPI).siteAPI.getPageUrl(getHomepageId(ps), baseUrl)
}

function isDynamicPage(ps: PS, pageId: string) {
    return !!routersGetters.get.byPage(ps, ps.pointers.data.getDataItemFromMaster(pageId))
}

function isLandingPage(ps: PS, pageId: string): boolean {
    const {page} = ps.extensionAPI as PageExtensionAPI
    const {getIsLandingPage, getIsMobileLandingPage} = page
    const isMobile = ps.siteAPI.isMobileView()
    const isLandingPageFunc = isMobile ? getIsMobileLandingPage : getIsLandingPage
    const languageCode = mlUtils.getLanguageByUseOriginal(ps, false)
    return !!isLandingPageFunc(pageId, {languageCode, useOriginalLanguageFallback: true})
}

export default {
    getHomepageId,
    isMasterPage,
    isHomepage,
    registerPageDataChangedCallback,
    executePageDataChangedCallback,
    registerPageAddedCallback,
    executePageAddedCallback,
    convertPageNameToUrl,
    getCurrentUrl,
    getMainPageUrl,
    isDynamicPage,
    uriHasIllegalCharacters,
    isLandingPage
}
