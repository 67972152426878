export const regularColorRegExp = /color_\d\d/g
export const regularColorPartRegExp = /color_(\d\d)/
export const stylableColorsRegExp = /site_\d_\d/g
export const stylableColorPartsRegExp = /site_(\d)_(\d)/

export const COLORS_START_IDX = 11
export const COLORS_END_IDX = 35
export const COLORS_PER_ROW = 5

export const toStylableColor = (id: string) => {
    const colorId = id.match(regularColorPartRegExp)?.[1]
    if (!colorId) {
        return null
    }
    if (+colorId > COLORS_END_IDX) {
        return id
    }
    const row = Math.floor((+colorId - COLORS_START_IDX) / COLORS_PER_ROW) + 1
    const col = ((+colorId - 1) % COLORS_PER_ROW) + 1
    return `site_${row}_${col}`
}

export const fromStylableColor = (id: string) => {
    if (regularColorRegExp.test(id)) {
        return id
    }
    const [, row, col] = id.match(stylableColorPartsRegExp) || []
    if (!row || !col) {
        return null
    }
    return `color_${COLORS_PER_ROW * (+row - 1) + (+col - 1) + COLORS_START_IDX}`
}
