const _ = require('lodash')
const exec = (pageJson, pageIdsArray, magicObject) => {
    if (!magicObject.isExperimentOpen('dm_fixAnchorUrlFragment')) {
        return
    }

    const data = _.get(pageJson, ['data', 'document_data'])
    if (!data) {
        return
    }

    Object.values(data)
        .filter(x => x && x.type === 'Anchor' && _.isUndefined(x.urlFragment) && _.isString(x.name))
        .forEach(x => {
            const cleanName = x.name.replace(/[^\w]/gi, '').toLowerCase().substr(0, 60)
            x.urlFragment = cleanName
        })
}

const fixer = {
    name: 'anchorUrlFragmentFixer',
    experimentalVersions: [{version: 1, experiment: 'dm_fixAnchorUrlFragment'}],
    version: 0,
    fixerRequiresReruns: true,
    exec
}

module.exports = fixer
