import type {CoreConfig, DalItem, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {ThemeExtAPI} from '../../../theme/theme'
import {ReportableError, ReportableWarning} from '@wix/document-manager-utils'
import {Errors} from '../../../../errors/errors'
import type {DataModelExtensionAPI} from '../../../dataModel/dataModel'
import type {SchemaExtensionAPI} from '../../../schema/schema'
import * as constants from '../../../../constants/constants'
import {DATA_TYPES, MASTER_PAGE_ID, STYLES} from '../../../../constants/constants'
import * as _ from 'lodash'
import type {DeserializeComponentParams} from '../types'
import {updateComponentScopedValues} from './scopedValues'

const addSystemStyle = (extensionAPI: ExtensionAPI, style: string, componentType?: string) => {
    if (!componentType) {
        throw new ReportableError({
            errorType: Errors.COMPONENT_TYPE_IS_MISSING,
            message: 'The component type is required to apply a system style',
            extras: {style}
        })
    }
    const {theme} = extensionAPI as ThemeExtAPI
    return theme.ensureDefaultStyleItemExists(componentType, style)
}

export const addCustomStyle = (
    extensionAPI: ExtensionAPI,
    style: DalItem,
    pageId: string,
    componentType?: string,
    customId?: string,
    stylesPerPage?: boolean
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const isComponentStyle = style.styleType !== STYLES.TYPES.SYSTEM && stylesPerPage
    const pageIdToAddTo = isComponentStyle ? pageId : MASTER_PAGE_ID

    _.set(style, ['type'], isComponentStyle ? constants.STYLES.COMPONENT_STYLE : constants.STYLES.TOP_LEVEL_STYLE)
    _.set(style, ['componentClassName'], componentType)
    _.set(style, ['id'], null)

    return dataModel.addItem(style, DATA_TYPES.theme, pageIdToAddTo, customId)
}
export const addStyleObjToStructure = (
    extensionAPI: ExtensionAPI,
    style: DalItem,
    pageId: string,
    componentType?: string,
    customId?: string,
    stylesPerPage?: boolean
) => {
    const {schemaAPI} = extensionAPI as SchemaExtensionAPI
    const styleId = style.id
    if (styleId && schemaAPI.isSystemStyle(styleId)) {
        return addSystemStyle(extensionAPI, styleId, componentType)
    }

    return addCustomStyle(extensionAPI, style, pageId, componentType, customId, stylesPerPage)
}

const addStyleStringToStructure = (
    extensionAPI: ExtensionAPI,
    coreConfig: CoreConfig,
    styleId: string,
    pageId: string,
    componentType?: string
) => {
    const {schemaAPI} = extensionAPI as SchemaExtensionAPI
    const {theme} = extensionAPI as ThemeExtAPI

    const isSystemStyle = schemaAPI.isSystemStyle(styleId)
    const existingStyle = theme.styles.getStyle(styleId, pageId)
    let styleIdToUse = existingStyle
    if (isSystemStyle) {
        if (!existingStyle) {
            styleIdToUse = addSystemStyle(extensionAPI, styleId, componentType)
        }
    } else {
        const {logger} = coreConfig
        logger.captureError(
            new ReportableWarning({
                errorType: Errors.ADDING_STRINGABLE_CUSTOM_STYLE,
                message: 'Unknown system style ID. Use a string for system style or an object for custom style.',
                extras: {
                    componentType,
                    styleId
                }
            })
        )
        if (existingStyle) {
            const customStyle = _.omit(existingStyle, ['id'])
            customStyle.styleType = 'custom'
            styleIdToUse = addCustomStyle(extensionAPI, customStyle, pageId, componentType, undefined, true)
        } else {
            if (!componentType) {
                throw new ReportableError({
                    errorType: Errors.COMPONENT_TYPE_IS_MISSING,
                    message: 'The component type is required to apply a default style',
                    extras: {styleId}
                })
            }
            const styleIds = theme.styles.getThemeStyleIds(componentType)
            if (styleIds.length) {
                styleIdToUse = {type: 'style', id: styleIds[0]}
            }
        }
    }

    return styleIdToUse
}

export const addStyleToStructure = (
    extensionAPI: ExtensionAPI,
    coreConfig: CoreConfig,
    style: DalItem | string,
    pageId: string,
    componentType?: string,
    customId?: string,
    stylesPerPage?: boolean // to support the implementation logic
) => {
    let styleQueryPointer: Pointer | null

    if (_.isObject(style)) {
        styleQueryPointer = addStyleObjToStructure(extensionAPI, style, pageId, componentType, customId, stylesPerPage)
    } else {
        styleQueryPointer = addStyleStringToStructure(extensionAPI, coreConfig, style, pageId, componentType)
    }

    return styleQueryPointer
}

export const updateComponentStylesStructure = (deserializationParams: DeserializeComponentParams) => {
    const {createExtArgs, compStructure, pageId, mappers} = deserializationParams
    updateComponentScopedValues(
        createExtArgs,
        compStructure,
        DATA_TYPES.theme,
        pageId,
        mappers,
        deserializationParams.stylesPerPage
    )
}
