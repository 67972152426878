import type {CreateExtArgs, CreateExtensionArgument, DmApis, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {STExtApi} from '../serviceTopology'
import type {SchemaAPI, SchemaExtensionAPI} from '../schema/schema'
import type {RMApi} from '../rendererModel'
import isEmpty from 'lodash/isEmpty'
import {type BuilderCompDetails, deriveFromManifest} from './manifest'
import type {FetchFn} from '../../utils/fetchUtils'
import type {Builder} from '@wix/document-services-types'
export type {BuilderCompDetails} from './manifest'

interface BuilderComponentsApi extends ExtensionAPI {
    registerBuilderComponentsForApp(appData: any): Promise<void>
    deriveFromManifest(identifier: string, manifest: Builder.Manifest): BuilderCompDetails
    registerBuilderComponent(details: BuilderCompDetails): void
    registerBuilderComponentWithManifest(identifier: string, manifest: Builder.Manifest): void
}

interface BuilderComponentsExtensionAPI extends ExtensionAPI {
    builderComponents: BuilderComponentsApi
}

const getAppComponents = (appData: any) => appData.components ?? []

const isNewBuilderComponent = (compData: any) =>
    compData.type === 'STUDIO_WIDGET' && !isEmpty(compData.data?.componentModel)

const extractBuilderCompsFromAppData = (
    serviceTopology: STExtApi['serviceTopology'],
    appData: any
): {componentType: string; manifestUrl: string}[] => {
    const comps = getAppComponents(appData).filter(isNewBuilderComponent)
    return comps.map((comp: any) => {
        const {componentType} = comp.data.componentModel
        const urlKey = `${comp.componentId}_manifestUrl`
        const url = appData.appFields.platform.baseUrls[urlKey]
        const dataFixerVersion = serviceTopology.getServiceTopology().scriptsVersionsMap?.['@wix/santa-data-fixer']
        const manifestGeneratorVersion =
            serviceTopology.getServiceTopology().scriptsVersionsMap?.['blocks-builder-manifest-generator']
        const manifestUrl = url
            .replace('$MANIFEST_MODULE_VERSION$', manifestGeneratorVersion)
            .replace('$DATA_FIXER_VERSION$', dataFixerVersion)
        return {componentType, manifestUrl}
    })
}

const registerBuilderComponent = (schemaAPI: SchemaAPI, details: BuilderCompDetails): void => {
    const {definition, schemas, identifier, innerTypes} = details
    const allowOverrides = true
    schemaAPI.registerComponentDefinitionAndSchemas(
        identifier,
        {
            componentDefinition: {
                [identifier]: {
                    ...definition,
                    innerTypes
                }
            },
            dataSchemas: {},
            propertiesSchemas: {},
            otherSchemas: {
                innerElements: schemas.innerElements,
                style: schemas.style
            }
        },
        allowOverrides
    )
}

async function fetchBuilderCompDetails(
    fetchFn: FetchFn,
    identifier: string,
    manifestUrl: string
): Promise<BuilderCompDetails> {
    const response = await fetchFn(manifestUrl)
    const manifest = await response.json()
    return deriveFromManifest(identifier, manifest)
}

const createExtension = ({environmentContext}: CreateExtensionArgument): Extension => {
    return {
        name: 'builderComponents',
        dependencies: new Set(['rendererModel', 'innerElements', 'serviceTopology']),
        async initialize(dmApis: DmApis): Promise<void> {
            const {rendererModel, builderComponents} = dmApis.extensionAPI as RMApi &
                BuilderComponentsExtensionAPI &
                STExtApi
            const csm = rendererModel.getClientSpecMap()
            await Promise.all(
                Object.values(csm ?? {}).map(appData => builderComponents.registerBuilderComponentsForApp(appData))
            )
        },
        createExtensionAPI(args: CreateExtArgs): BuilderComponentsExtensionAPI {
            const {schemaAPI, serviceTopology} = args.extensionAPI as SchemaExtensionAPI & STExtApi
            const {fetchFn} = environmentContext
            return {
                builderComponents: {
                    async registerBuilderComponentsForApp(appData: any): Promise<void> {
                        const comps = extractBuilderCompsFromAppData(serviceTopology, appData)
                        await Promise.all(
                            comps.map(async ({componentType, manifestUrl}) => {
                                const details = await fetchBuilderCompDetails(fetchFn, componentType, manifestUrl)
                                registerBuilderComponent(schemaAPI, details)
                            })
                        )
                    },
                    deriveFromManifest,
                    registerBuilderComponent(details: BuilderCompDetails) {
                        registerBuilderComponent(schemaAPI, details)
                    },
                    registerBuilderComponentWithManifest(identifier: string, manifest: Builder.Manifest) {
                        const compDetails = deriveFromManifest(identifier, manifest)

                        registerBuilderComponent(schemaAPI, compDetails)
                    }
                }
            }
        }
    }
}

export {createExtension, BuilderComponentsApi, BuilderComponentsExtensionAPI}
