import _ from 'lodash'
import * as conversionUtils from './conversionUtils'
import {conversionConfig} from './conversionConfig'
import * as mobileMerge from './mobileMerge/mobileMerge'
import {syncPage} from './mobileMerge/mobileSync'
import * as mergeUtils from './mobileMerge/mergeUtils'
import {rescaleComponent} from './structureConverter/structureConverter'
import {preProcessStructure} from './structurePreprocessor'
import {postProcessStructure, removeConversionDataRecursively} from './structurePostprocessor'
import {analyzeStructure} from './analyzer/structureAnalyzer'
import {convertMasterPage, fixMasterPageLayout, fixMasterPageLayoutAndOrder} from './masterPageConverter'
import type {ConversionSettings, DeepStructure} from '../types'
import {StructureManager} from './utils/structureManager'

export function synchronizePage(desktopPage: DeepStructure, mobilePage: DeepStructure): DeepStructure[] {
    syncPage(desktopPage, mobilePage)
    mergeUtils.synchronizeDataQueries(desktopPage, mobilePage)
    if (mobilePage.id === 'masterPage') {
        const mobileComps = conversionUtils.getChildren(mobilePage)
        const desktopComps = conversionUtils.getChildren(desktopPage)
        _.forEach(mobileComps, comp => _.set(comp, 'conversionData', _.get(_.find(desktopComps, {id: comp.id}), 'conversionData')))
        fixMasterPageLayout(mobileComps)
    }
    removeConversionDataRecursively(<DeepStructure>mobilePage)
    return <DeepStructure[]>conversionUtils.getChildren(mobilePage)
}

export function convertPage(page: DeepStructure, settings: ConversionSettings): DeepStructure[] {
    const components = conversionUtils.getChildren(page)
    preProcessStructure(page, components, settings)

    analyzeStructure(page, settings)

    if (conversionUtils.isMasterPage(page)) {
        convertMasterPage(<DeepStructure>page)
    } else {
        rescaleComponent(<DeepStructure>page, conversionConfig.MOBILE_WIDTH)
    }

    postProcessStructure(<DeepStructure>page, components, settings)
    removeConversionDataRecursively(page)
    return <DeepStructure[]>conversionUtils.getChildren(page)
}

export function mergePage(desktopStructure: DeepStructure, mobileStructure: DeepStructure, settings: ConversionSettings): DeepStructure[] {
    const structureManager = StructureManager.create(mobileStructure)
    mergeUtils.synchronizeDataQueries(desktopStructure, mobileStructure)
    mobileMerge.mergePage(desktopStructure, mobileStructure, settings)
    if (mobileStructure.id === 'masterPage') {
        const comps = conversionUtils.getChildren(mobileStructure)
        const desktopComps = conversionUtils.getChildren(desktopStructure)
        _.forEach(comps, comp => _.set(comp, 'conversionData', _.get(_.find(desktopComps, {id: comp.id}), 'conversionData')))
        if (settings.fixMasterPageStructureOrder) {
            fixMasterPageLayoutAndOrder(comps)
        } else {
            fixMasterPageLayout(comps)
        }
    }

    removeConversionDataRecursively(mobileStructure)
    const mobileChildren = structureManager.getRealChildren(mobileStructure)
    return mobileChildren
}

export function inspectStructure(structure: DeepStructure, settings: ConversionSettings): void {
    analyzeStructure(structure, settings)
}
