import type {PS} from '@wix/document-services-types'

/**
 * @param {ps} ps
 * @param {string} controllerId
 * @returns {any | string}
 */
function getState(ps: PS, controllerId: string) {
    return ps.extensionAPI.appController.getState(controllerId)
}

function setState(ps: PS, stateMap) {
    return ps.extensionAPI.appController.setState(stateMap)
}

export default {
    getState,
    setState
}
