import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import {getControllerNickname} from '../utils/nicknameUtils'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {getComponentType} from '../../../utils/dalUtils'
import {isHeaderOrFooterOrPageOrMasterPage, isPopupPageOrPopupContainer} from '../utils/pageUtils'

export const ALLOWED_CONTAINER_TYPES = [
    'core.components.Page',
    'mobile.core.components.Page',
    'wixapps.integration.components.AppPage',
    'responsive.components.Section',
    'responsive.components.HeaderSection',
    'responsive.components.FooterSection',
    'responsive.components.MembersAreaSection',
    'wysiwyg.viewer.components.PopupContainer',
    'wysiwyg.viewer.components.ClassicSection'
]

const CONTAINER_TYPES_ALLOWED_BY_EXPERIMENT = [
    'mobile.core.components.Container',
    'platform.components.AppWidget',
    'mobui.MobuiContainer'
]

const isAllowedContainerTypeByExperiment = ({coreConfig}: CreateExtArgs, containerType: string) => {
    if (!coreConfig.experimentInstance.isOpen('dm_enableControllerForBlocksContainers')) {
        return false
    }

    return _.includes(CONTAINER_TYPES_ALLOWED_BY_EXPERIMENT, containerType)
}

const isContainable = (
    createExtArgs: CreateExtArgs,
    containerPointer: Pointer,
    toggleShowOnAllPagesEnabled: boolean
) => {
    const potentialContainerCompType = getComponentType(createExtArgs.dal, containerPointer)
    const showOnAllPages = toggleShowOnAllPagesEnabled && isHeaderOrFooterOrPageOrMasterPage(potentialContainerCompType)

    return (
        showOnAllPages ||
        _.includes(ALLOWED_CONTAINER_TYPES, potentialContainerCompType) ||
        isPopupPageOrPopupContainer(createExtArgs, containerPointer) ||
        isAllowedContainerTypeByExperiment(createExtArgs, potentialContainerCompType)
    )
}

export const controllerMetaData = {
    nickname: getControllerNickname,
    container: false,
    canContain: false,
    fullWidthByStructure: false,
    fullWidth: false,
    canContainByStructure: false,
    containableByStructure: (
        createExtArgs: CreateExtArgs,
        controllerStructure: CompStructure,
        containerPointer: Pointer
    ) => isContainable(createExtArgs, containerPointer, true)
}
