import type {ApplicationId, AppDefinitionId, PagesData, PS, AppsInstallStateMap} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import page from '../../page/page'
import platformConstants from '../../platform/common/constants'
import platformAppDataGetter from '../../platform/services/platformAppDataGetter'
import * as visitableData from '../../utils/visitableData'
import tpaConstants from '../constants'
import tpaUtils from '../utils/tpaUtils'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnPageService from './installedTpaAppsOnPageService'
import pendingAppsService from './pendingAppsService'
import experiment from 'experiment-amd'
import type {AppsState} from './appStoreService'
import {constants, installedAppsFromStateMap} from '@wix/document-manager-extensions'
const {REMOVED, INSTALLED} = constants.APP_STATUS
import {contextAdapter} from '../../utils/contextAdapter'
import dsConstants from '../../constants/constants'

const shouldAppBeRevokedOld = (app, appIdsInstalledOnSite, appsState: AppsState, shouldAvoidRevoking: boolean) => {
    const appState = _.get(appsState, app?.appDefinitionId, {})
    const isAppRemoved = _.get(appState, 'pendingAction') === platformConstants.APP_ACTION_TYPES.REMOVE
    const shouldAppBeRemoved =
        clientSpecMapService.isAppAutoRevoked(app) &&
        isAppPermissionGranted(app, appIdsInstalledOnSite, appsState) &&
        !isAppPreInstalled(app) &&
        !pendingAppsService.isPending(undefined, app) &&
        !shouldAvoidRevoking
    const shouldAppBeRevokedRes = isAppRemoved || shouldAppBeRemoved
    if (shouldAppBeRevokedRes) {
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.APP_REVOKED_REASON, {
            tags: {shouldAppBeRevokedOld: true},
            extras: {
                isAppRemoved,
                shouldAppBeRemoved,
                isAppAutoRevoked: clientSpecMapService.isAppAutoRevoked(app),
                isAppPermissionGranted: isAppPermissionGranted(app, appIdsInstalledOnSite, appsState),
                isAppPreInstalled: isAppPreInstalled(app),
                isPending: pendingAppsService.isPending(undefined, app),
                shouldAvoidRevoking
            }
        })
    }
    return shouldAppBeRevokedRes
}

const shouldAppBeRevoked = (app, appsState: AppsState) => {
    const appState = _.get(appsState, app?.appDefinitionId, {})
    const isAppRemoved = _.get(appState, 'status') === REMOVED
    const editorOrHybridApp = clientSpecMapService.isEditorOrHybridApp(app)
    const hasGrantPermissions = clientSpecMapService.isAppPermissionsGrantedOnCSM(app, appsState)
    const notPlatformEditorOnly = !isAppPlatformEditorOnly(app)
    const isAppPending = pendingAppsService.isPending(undefined, app)
    const appNotPreInstalled = !isAppPreInstalled(app)

    const shouldAppUpdate = !isAppPending && editorOrHybridApp && notPlatformEditorOnly && appNotPreInstalled
    const shouldAppBeRevokedRes = shouldAppUpdate && isAppRemoved && hasGrantPermissions
    if (shouldAppBeRevokedRes) {
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.APP_REVOKED_REASON, {
            tags: {shouldAppBeRevoked: true},
            extras: {
                isAppRemoved,
                hasGrantPermissions,
                shouldAppUpdate,
                isAppPending,
                editorOrHybridApp,
                notPlatformEditorOnly,
                appNotPreInstalled
            }
        })
    }
    return shouldAppBeRevokedRes
}

const getAppsToRevokePermissions = (clientSpecMap, appIdsInstalledOnSite, options, appsState: AppsState) => {
    const {excludeHybrid, shouldAvoidRevoking} = options ?? {}
    if (shouldAvoidRevoking && experiment.isOpen('dm_settleFromInstallState')) {
        return []
    }
    const appsToRevokePermissions = _.filter(clientSpecMap, app =>
        experiment.isOpen('dm_settleFromInstallState')
            ? shouldAppBeRevoked(app, appsState)
            : shouldAppBeRevokedOld(app, appIdsInstalledOnSite, appsState, shouldAvoidRevoking)
    )
    contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.GET_APP_TO_REVOKE, {
        extras: {
            options,
            appsToRevokePermissions: _.map(
                appsToRevokePermissions,
                _.partialRight(_.pick, ['applicationId', 'appDefinitionId'])
            )
        }
    })
    if (options && _.isBoolean(excludeHybrid) && excludeHybrid) {
        const filteredApps = _.filter(
            appsToRevokePermissions,
            app =>
                clientSpecMapService.isEditorApp(app) || clientSpecMapService.isHybridAppAndEditorPartNotDismissed(app)
        )
        contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.GET_APP_TO_REVOKE, {
            extras: {
                options,
                appsToRevokePermissions: _.map(
                    appsToRevokePermissions,
                    _.partialRight(_.pick, ['applicationId', 'appDefinitionId'])
                )
            }
        })
        return filteredApps
    }
    contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.GET_APP_TO_REVOKE, {
        extras: {
            appsToRevokePermissions: _.map(
                appsToRevokePermissions,
                _.partialRight(_.pick, ['applicationId', 'appDefinitionId'])
            )
        }
    })
    return appsToRevokePermissions
}

const getDeletedAppDefIds = (ps: PS): AppDefinitionId[] => {
    const appDefIdsOnPages = getAppDefIdsOnPages(ps)
    const appsDataWithPredicate = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(
            csm,
            app => !appDefIdsOnPages.has(app.appDefinitionId) && clientSpecMapService.isEditorOrHybridApp(app)
        )
    )
    return _.map(appsDataWithPredicate, 'appDefinitionId')
}

const isAppPlatformEditorOnly = app =>
    clientSpecMapService.hasEditorPlatformPart(app) &&
    !clientSpecMapService.hasViewerPlatformPart(app) &&
    _.isEmpty(app.widgets)

const isAppIsPlatformEditorOnlyAndNotProvisioned = function (app) {
    return app.notProvisioned && isAppPlatformEditorOnly(app)
}

const shouldAppGrantPermissions = (app, appsState: AppsState) => {
    const editorOrHybridApp = clientSpecMapService.isEditorOrHybridApp(app)
    const hasRevokePermissions = clientSpecMapService.isAppPermissionsIsRevoked(app, appsState)
    const isAppInstalledOnSite = _.get(appsState, [app?.appDefinitionId, 'status']) === INSTALLED
    const isPlatformEditorOnly = isAppPlatformEditorOnly(app)
    return (
        (editorOrHybridApp && hasRevokePermissions && isAppInstalledOnSite) ||
        (app.notProvisioned && isPlatformEditorOnly)
    )
}

const getAppsToGrantPermissions = function (clientSpecMap, appIdsInstalledOnSite, appsState: AppsState = {}) {
    return _.filter(clientSpecMap, app =>
        experiment.isOpen('dm_settleFromInstallState')
            ? shouldAppGrantPermissions(app, appsState)
            : isAppPermissionRevoked(app, appIdsInstalledOnSite, appsState) ||
              isAppIsPlatformEditorOnlyAndNotProvisioned(app)
    )
}

const isPlatformOnlyAppUninstalled = function (_visitableData, clientSpecMap, routerConfigMap, appsState: AppsState) {
    const uninstalledPlatformOnly = _.filter(clientSpecMap, app => {
        if (!clientSpecMapService.hasEditorPlatformPart(app)) {
            return false
        }
        return (
            clientSpecMapService.isAppPermissionsGrantedOnCSM(app, appsState) &&
            (experiment.isOpen('dm_settleFromInstallState')
                ? _.get(appsState, [app.appDefinitionId, 'status']) === REMOVED
                : !installedTpaAppsOnPageService.isPlatformAppActive(app, _visitableData, routerConfigMap))
        )
    })
    return !_.isEmpty(uninstalledPlatformOnly)
}

/** This returns the apps that should have been revoked if branches weren't applied on this site */
const getUnusedApps = function (ps: PS) {
    const visitableDataAllPages = visitableData.createFromPrivateServices(ps)
    const clientSpecMap = clientSpecMapService.filterApps(ps.dal.get(ps.pointers.general.getClientSpecMap()))
    const routerConfigMap = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())
    const appIdsInstalledOnSite = experiment.isOpen('dm_settleFromInstallState')
        ? ps.extensionAPI.appsInstallState.getInstalledApps()
        : installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(
              visitableDataAllPages,
              clientSpecMap,
              routerConfigMap
          )

    return getAppsToRevokePermissions(
        clientSpecMap,
        appIdsInstalledOnSite,
        null,
        experiment.isOpen('dm_settleFromInstallState') ? ps.extensionAPI.appsInstallState.getAllAppsInstallStatus() : {}
    )
}

const getAppsToGrantAndRevoke = function (
    clientSpecMap,
    _visitableData,
    options?,
    routerConfigMap?,
    appsState?: AppsState
) {
    const appIdsInstalledOnSite = experiment.isOpen('dm_settleFromInstallState')
        ? installedAppsFromStateMap(appsState as AppsInstallStateMap)
        : installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(_visitableData, clientSpecMap, routerConfigMap)

    return getAppsToGrantAndRevokedFromInstalledApps(
        clientSpecMap,
        _visitableData,
        appIdsInstalledOnSite,
        options,
        appsState
    )
}

const getAppsToGrantAndRevokedFromInstalledApps = function (
    clientSpecMap,
    _visitableData,
    appIdsInstalledOnSite,
    options?,
    appsState?: AppsState
) {
    return {
        revoke: getAppsToRevokePermissions(clientSpecMap, appIdsInstalledOnSite, options, appsState),
        grant: getAppsToGrantPermissions(clientSpecMap, appIdsInstalledOnSite, appsState)
    }
}

const getAppsToProvision = clientSpecMap => _.filter(clientSpecMap, app => isAppInDemoMode(app))

const isAppInDemoMode = app => app.demoMode

const isAppPermissionGranted = function (app, appIdsInstalledOnSite, appsState) {
    return (
        clientSpecMapService.isEditorOrHybridApp(app) &&
        clientSpecMapService.isAppPermissionsGrantedOnCSM(app, appsState) &&
        !_.includes(appIdsInstalledOnSite, app.appDefinitionId) &&
        !isAppPlatformEditorOnly(app)
    )
}

const isAppPermissionRevoked = (app, appIdsInstalledOnSite, appsState: AppsState) =>
    clientSpecMapService.isEditorOrHybridApp(app) &&
    clientSpecMapService.isAppPermissionsIsRevoked(app, appsState) &&
    _.includes(_.invokeMap(appIdsInstalledOnSite, 'toString'), app.appDefinitionId)

const isAppPreInstalled = app => _.isBoolean(app.preInstalled) && app.preInstalled

const getInstalledAppsOnSite = function (ps: PS): AppDefinitionId[] {
    if (experiment.isOpen('dm_settleFromInstallState')) {
        return ps.extensionAPI.appsInstallState.getInstalledApps()
    }
    const pagesIds = page.getPageIdList(ps, true, true)
    const appDefIds = _.reduce(
        pagesIds,
        function (result, pageId) {
            const installedAppDefIdsOnPage = _.map(getInstalledAppsOnPage(ps, pageId), 'appDefinitionId')
            return _.union(result, installedAppDefIdsOnPage)
        },
        []
    )
    return _.uniq(appDefIds)
}

const getInstalledAppsOnPage = function (ps: PS, pageId: string) {
    const pointerToDataItems = ps.pointers.page.getPageData(pageId)
    const dataItems = ps.dal.get(pointerToDataItems)
    const appsData = _(dataItems)
        .filter(dataItem => tpaUtils.isTpaByDataType(dataItem.type))
        .map('appDefinitionId')
        .uniq()
        .map(appDefId => clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId))
        .compact()
        .value()
    return appsData
}

const getFirstMainSectionInstalledData = function (ps: PS, appDefinitionId: AppDefinitionId) {
    return getFirstCompData(ps, appDefinitionId, comp => comp.type === tpaConstants.DATA_TYPE.TPA_SECTION)
}

const getDefaultWidgetInstalledData = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    const defaultWidget = _.find(appData.widgets, {default: true})
    if (defaultWidget) {
        return getFirstCompData(ps, appData.appDefinitionId, comp => comp.widgetId === defaultWidget.widgetId)
    }
    return null
}

const getFirstCompData = function (ps: PS, appDefinitionId: AppDefinitionId, predicateFunc) {
    const pagesIds = page.getPageIdList(ps, true, true)
    let comp
    const pageIdWithComp = _.find(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        comp = _.find(tpaCompsOnPage, tpaComp => tpaComp.appDefinitionId === appDefinitionId && predicateFunc(tpaComp))
        return !_.isUndefined(comp)
    })

    return pageIdWithComp ? {pageId: pageIdWithComp, compId: comp.id} : null
}

const getFirstAppCompPageId = function (ps: PS, appDefinitionId: AppDefinitionId, useDefaultWidget?: boolean) {
    let data = appDefinitionId && getFirstMainSectionInstalledData(ps, appDefinitionId)
    if (!data && useDefaultWidget) {
        data = getDefaultWidgetInstalledData(ps, appDefinitionId)
    }
    data = appDefinitionId && (data || getFirstCompData(ps, appDefinitionId, () => true))

    return data
}

const isMainSectionInstalled = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const pagesIds = page.getPageIdList(ps, true)
    return _.some(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        return _.some(
            tpaCompsOnPage,
            tpaComp =>
                tpaComp.appDefinitionId === appDefinitionId && tpaComp.type === tpaConstants.DATA_TYPE.TPA_SECTION
        )
    })
}

const emptyToNull = v => (_.isEmpty(v) ? null : v)

const getHiddenSections = function (ps: PS, appDefinitionId: AppDefinitionId) {
    let hiddenSections = []
    const pagesIds = page.getPageIdList(ps, true)
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        hiddenSections = _.union(
            hiddenSections,
            _.filter(
                tpaCompsOnPage,
                tpaComp =>
                    tpaComp.appDefinitionId === appDefinitionId &&
                    tpaComp.type === tpaConstants.DATA_TYPE.TPA_MULTI_SECTION
            )
        )
    })

    return emptyToNull(hiddenSections)
}

const getWidgetsByAppDefId = function (ps: PS, appDefinitionId: AppDefinitionId) {
    let widgets = []
    const pagesIds = page.getPageIdList(ps, true, true)
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        widgets = _.union(
            widgets,
            _.filter(
                tpaCompsOnPage,
                tpaComp =>
                    tpaComp.appDefinitionId === appDefinitionId && tpaComp.type === tpaConstants.DATA_TYPE.TPA_WIDGET
            )
        )
    })

    return emptyToNull(widgets)
}

const getAllAppCompsByAppDefIds = function (ps: PS, appDefinitionIds?: AppDefinitionId[]) {
    const pagesIds = page.getPageIdList(ps, true, true)
    let tpaComps = []
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        tpaComps = _.union(
            tpaComps,
            _.filter(
                tpaCompsOnPage,
                tpaComp => tpaComp?.appDefinitionId && _.includes(appDefinitionIds, tpaComp.appDefinitionId)
            )
        )
    })

    return emptyToNull(tpaComps)
}

const isMultiSectionInstalled = function (ps: PS, appDefinitionId?: AppDefinitionId) {
    let mainSections = []
    const pagesIds = page.getPageIdList(ps, true)
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        mainSections = _.union(
            mainSections,
            _.filter(
                tpaCompsOnPage,
                tpaComp =>
                    tpaComp?.applicationId &&
                    tpaComp.appDefinitionId === appDefinitionId &&
                    tpaComp.type === tpaConstants.DATA_TYPE.TPA_SECTION
            )
        )
    })

    return _.size(mainSections) > 1
}

const isAppInstalledBy = function (ps: PS, appDefinitionId: AppDefinitionId, filterOutDemoMode?) {
    const tpaApp = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    if (tpaApp) {
        const appDefIdsInstalled =
            clientSpecMapService.isDashboardAppOnly(tpaApp) || isAppDefinitionIdInstalled(ps, appDefinitionId)
        if (filterOutDemoMode) {
            return appDefIdsInstalled && !tpaApp.demoMode
        }
        return appDefIdsInstalled
    }

    return false
}

const isAppInstalledOnPage = function (
    ps: PS,
    pageId: string,
    appDefinitionId: AppDefinitionId,
    filterOutDemoMode: boolean = false
) {
    const tpaApp = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    if (tpaApp) {
        const appDefIdsInstalled = isAppDefinitionIdExistsOnPage(ps, pageId, appDefinitionId)
        if (filterOutDemoMode) {
            return appDefIdsInstalled && !tpaApp.demoMode
        }
        return appDefIdsInstalled
    }

    return false
}

const getAppDefIdsOnPages = function (ps: PS) {
    const usedAppDefIds = new Set()
    const pagesIds = page.getPageIdList(ps, true, true)
    const predicate = dataItem => Boolean(dataItem.appDefinitionId)
    _.forEach(pagesIds, pageId => {
        const pointers = ps.pointers.data.getDataItemsWithPredicate(predicate, pageId)
        _.forEach(pointers, pointer => {
            usedAppDefIds.add(ps.dal.get(pointer).appDefinitionId)
        })
    })
    return usedAppDefIds
}

const isAppDefinitionIdExists = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const pagesIds = page.getPageIdList(ps, true, true)
    const predicate = dataItem => _.get(dataItem, 'appDefinitionId', '') === appDefinitionId
    return _.some(pagesIds, pageId => !!ps.pointers.data.getDataItemWithPredicate(predicate, pageId))
}

const isAppDefinitionIdInstalled = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const pagesIds = page.getPageIdList(ps, true, true)
    const predicate = dataItem => dataItem.appDefinitionId === appDefinitionId

    return _.some(pagesIds, pageId => !!ps.pointers.data.getDataItemWithPredicate(predicate, pageId))
}

const isAppDefinitionIdExistsOnPage = function (ps: PS, pageId: string, appDefinitionId: AppDefinitionId) {
    const predicate = dataItem => dataItem.appDefinitionId === appDefinitionId
    return !!ps.pointers.data.getDataItemWithPredicate(predicate, pageId)
}

const isSectionInstalledByTpaPageId = function (ps: PS, appDefinitionId: AppDefinitionId, tpaPageId: string) {
    const pagesData = page.getPagesDataItems(ps)
    return _.some(pagesData, {appDefinitionId, tpaPageId})
}

const isAppComponentInstalled = function (ps: PS, widgetId: string) {
    const pagesIds = page.getPageIdList(ps, true, true)
    return _.some(pagesIds, function (pageId) {
        const pointerToDataItems = ps.pointers.page.getPageData(pageId)
        const dataItems = ps.dal.get(pointerToDataItems)
        return _.some(dataItems, {widgetId})
    })
}

const getAllTpaCompsOnPage = function (ps: PS, pageId: string) {
    const pagePointer = page.getPage(ps, pageId)
    const tpaCompPointers = component.getTpaChildren(ps, pagePointer)

    return _.map(tpaCompPointers, tpaCompPointer =>
        _.assign(component.data.get(ps, tpaCompPointer), {id: tpaCompPointer.id, pageId})
    )
}

const isMultiSectionPage = function (ps: PS, pageData: PagesData): boolean {
    const appDefId = _.get(pageData, 'appDefinitionId')
    const tpaPageId = _.get(pageData, 'tpaPageId')
    const appExists = !!clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
    if (appExists) {
        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appDefId, tpaPageId)
        return _.get(widgetData, 'appPage.hidden')
    }
    return false
}

const isTpaCompsInstalledOnPages = function (_visitableData) {
    if (!_visitableData) {
        return false
    }

    let result = false
    _visitableData.accept((get, data) => {
        result = result || tpaUtils.isTpaByDataType(get(data, 'type'))
    })
    return result
}

const getAppsDefIdToProvisionOnSiteLoad = function (ps: PS) {
    const appsDataWithPredicate = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(
            csm,
            appData =>
                appData.demoMode &&
                (isAppDefinitionIdInstalled(ps, appData.appDefinitionId) ||
                    platformAppDataGetter.isPlatformAppInstalled(ps, appData.appDefinitionId))
        )
    )
    return _.map(appsDataWithPredicate, 'appDefinitionId')
}

const isDataFixerAddedChatRemoved = (
    isMasterPageUpdated: boolean,
    tpaCompsOnLastPages,
    tpaCompsOnUpdatedPages,
    clientSpecMap,
    appsState
) => {
    if (isMasterPageUpdated) {
        const chatApp = _.find(clientSpecMap, {appDefinitionId: tpaConstants.APP_DEF_ID.CHAT})

        return (
            chatApp &&
            clientSpecMapService.isAppPermissionsGrantedOnCSM(chatApp, appsState) &&
            !_.includes(tpaCompsOnLastPages, chatApp.appDefinitionId) &&
            !_.includes(tpaCompsOnUpdatedPages, chatApp.appDefinitionId)
        )
    }

    return false
}

const areTpaCompsWereUnInstalled = function (
    lastPagesVisitable,
    updatedPagesVisitable,
    deletedPagesVisitable?,
    clientSpecMap?,
    isMasterPageUpdated?: boolean,
    appsState?
) {
    if (isTpaCompsInstalledOnPages(deletedPagesVisitable)) {
        return true
    }

    const tpaCompsOnLastPages = installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(lastPagesVisitable) || []
    const tpaCompsOnUpdatedPages =
        installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(updatedPagesVisitable) || []

    const someAppsAddedSomeRemoved =
        tpaCompsOnLastPages.length === tpaCompsOnUpdatedPages.length &&
        !_.isEqual(tpaCompsOnLastPages, tpaCompsOnUpdatedPages)

    return (
        tpaCompsOnLastPages.length > tpaCompsOnUpdatedPages.length ||
        someAppsAddedSomeRemoved ||
        isDataFixerAddedChatRemoved(
            isMasterPageUpdated,
            tpaCompsOnLastPages,
            tpaCompsOnUpdatedPages,
            clientSpecMap,
            appsState
        )
    )
}

const getAppPages = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const pagesIds = page.getPageIdList(ps, false, false)
    const pagesMatchingAppDefId = _(pagesIds)
        .map(pageId => _.merge(page.data.get(ps, pageId), {pageId}))
        .filter({appDefinitionId})
        .value()
    return pagesMatchingAppDefId
}

const getPagesByAppDefinitionIds = function (ps: PS, appDefinitionIds: AppDefinitionId[]) {
    const pagesData = page.getPagesDataItems(ps)
    return _.filter(pagesData, pageData => _.includes(appDefinitionIds, pageData.appDefinitionId))
}

const isAppInstalled = function (ps: PS, installedAppDefIds: AppDefinitionId[], appData) {
    if (clientSpecMapService.isAppAutoRevoked(appData)) {
        return _.includes(installedAppDefIds, appData?.appDefinitionId)
    }
    return clientSpecMapService.isAppActive(ps, appData)
}

const getInstalledDependentAppsData = function (ps: PS, appDefinitionId: AppDefinitionId) {
    // @ts-expect-error
    const dependentAppDefIds = clientSpecMapService.getDependentApps(ps, appDefinitionId)
    if (!_.isEmpty(dependentAppDefIds)) {
        const installedAppDefIds = getInstalledAppsOnSite(ps)
        return _.reduce(
            dependentAppDefIds,
            function (result, appDefId) {
                const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
                if (isAppInstalled(ps, installedAppDefIds, appData)) {
                    result.push(appData)
                }
                return result
            },
            []
        )
    }
    return []
}

//DM-8066: appDefinitionId migration public api
const getInstalledDependentAppsDataByAppId = function (ps: PS, applicationId: ApplicationId) {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'installedTpaAppsOnSiteService.getInstalledDependentAppsDataByAppId'
        })
        return getInstalledDependentAppsData(ps, appDefId)
    }
}

const getAllAppCompsByAppId = function (ps: PS, applicationIds?: ApplicationId | ApplicationId[]) {
    if (!_.isArray(applicationIds)) {
        applicationIds = [applicationIds]
    }
    const appDefinitionIds = applicationIds.map(appId =>
        clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, appId, {
            source: 'getAllAppCompsByAppId'
        })
    )
    return getAllAppCompsByAppDefIds(ps, _.compact(appDefinitionIds))
}

const isMultiSectionInstalledByAppId = function (ps: PS, applicationId: ApplicationId) {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'isMultiSectionInstalledByAppId'
    })
    return isMultiSectionInstalled(ps, appDefId)
}

const isSectionInstalledByTpaPageIdByAppId = function (ps: PS, applicationId: ApplicationId, tpaPageId: string) {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'isSectionInstalledByTpaPageIdByAppId',
        tpaPageId
    })
    return isSectionInstalledByTpaPageId(ps, appDefId, tpaPageId)
}

export default {
    isMultiSectionPage,
    getInstalledAppsOnPage,
    getAllTpaCompsOnPage,
    isMainSectionInstalled,
    getWidgetsByAppDefId,
    getAllAppCompsByAppDefIds,
    getAllAppCompsByAppId,
    isMultiSectionInstalled,
    isMultiSectionInstalledByAppId,
    getHiddenSections,
    getFirstAppCompPageId,
    isAppDefinitionIdInstalled,
    isAppDefinitionIdExists,
    isAppInstalledBy,
    isAppInstalledOnPage,
    getDeletedAppDefIds,
    getAppsDefIdToProvisionOnSiteLoad,
    getAppsToGrantAndRevoke,
    getAppsToGrantAndRevokedFromInstalledApps,
    getUnusedApps,
    getAllAppIdsInstalledOnPages: installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages,
    areTpaCompsWereUnInstalled,
    getAppsToGrantPermissions,
    getAppsToProvision,
    getAppPages,
    getPagesByAppDefinitionIds,
    isAppComponentInstalled,
    isSectionInstalledByTpaPageId,
    isSectionInstalledByTpaPageIdByAppId,
    isPlatformOnlyAppUninstalled,
    getInstalledDependentAppsData,
    getInstalledDependentAppsDataByAppId,
    isAppPlatformEditorOnly
}
