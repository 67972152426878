import type {DmApis, CreateExtensionArgument, Extension, ExtensionAPI} from '@wix/document-manager-core'
import {Notifier, ReportableError} from '@wix/document-manager-utils'
import type {DistributorExtensionAPI} from '../distributor/distributor'
import type {PublishPipelineMessage} from '../distributor/messages'
import {publishPipelineFailedError} from '../csave/csaveErrors'
import type {HooksExtensionApi} from '../hooks/hooks'
import {PUBLISH_HOOKS} from './hooks'

enum PublishPipelineStatus {
    PIPELINE_SUCCEEDED = 'PIPELINE_SUCCEEDED',
    PIPELINE_FAILED = 'PIPELINE_FAILED',
    PUBLISH_FAILED = 'PUBLISH_FAILED'
}

interface PublishPipelineAPI extends ExtensionAPI {
    subscribeToDeployment(deploymentId: string): Promise<void>
}

interface PublishPipelineExtensionAPI extends ExtensionAPI {
    publishPipeline: PublishPipelineAPI
}

/**
 * @returns {Extension}
 */
const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const notifier = new Notifier<string, void, any>(logger, {}, 1000 * 60 * 2)

    const createExtensionAPI = (): PublishPipelineExtensionAPI => {
        const subscribeToDeployment = (deploymentId: string) => {
            return notifier.register(deploymentId)
        }

        return {
            publishPipeline: {
                subscribeToDeployment
            }
        }
    }

    const initializeChannelSubscriptions = async ({extensionAPI}: DmApis) => {
        const {distributor} = extensionAPI as DistributorExtensionAPI

        distributor.subscribeToMessage<PublishPipelineMessage>(
            PublishPipelineStatus.PIPELINE_SUCCEEDED,
            onResolveDeployment
        )
        distributor.subscribeToMessage<PublishPipelineMessage>(
            PublishPipelineStatus.PIPELINE_FAILED,
            onRejectDeployment
        )
        distributor.subscribeToMessage<PublishPipelineMessage>(PublishPipelineStatus.PUBLISH_FAILED, onPublishFailed)

        function notifyOnPublishStatus(payload: PublishPipelineMessage) {
            const {hooks} = extensionAPI as HooksExtensionApi
            hooks.executeHook(PUBLISH_HOOKS.PUBLISH.STATUS.createEvent(payload))
        }

        function onResolveDeployment(payload: PublishPipelineMessage) {
            const {deploymentId} = payload.data
            notifier.resolve(deploymentId)
            notifyOnPublishStatus(payload)
        }

        function onRejectDeployment(payload: PublishPipelineMessage) {
            const {data} = payload
            const {deploymentId} = data
            logger.captureError(
                new ReportableError({
                    errorType: 'publishPipelineFailed',
                    message: `Publish pipeline failed for deployment ${deploymentId}`,
                    extras: data
                })
            )
            notifier.reject(deploymentId, publishPipelineFailedError(data))
            notifyOnPublishStatus(payload)
        }

        function onPublishFailed(payload: PublishPipelineMessage) {
            const {data} = payload
            const {deploymentId} = data
            const error = new ReportableError({
                errorType: 'unknownPublishPipelineError',
                message: `Publish pipeline failed for deployment ${deploymentId}`,
                extras: data
            })
            logger.captureError(error)
            notifier.reject(deploymentId, error)
        }
    }
    return {
        name: 'publishPipeline',
        createExtensionAPI,
        initializeChannelSubscriptions
    }
}

export {createExtension, PublishPipelineExtensionAPI, PublishPipelineStatus}
