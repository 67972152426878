import type {CompRef, Pointer} from '@wix/document-services-types'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, VIEW_MODES} from '../constants/constants'
import {namespaceMapping} from '@wix/document-services-json-schemas'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {extractBaseComponentByNamespace} from './refStructureUtils'
import {pointerUtils, type DAL} from '@wix/document-manager-core'
import {stripHashIfExists} from './refArrayUtils'

const {getNamespaceConfig} = namespaceMapping

export const refCompDelimiter = '_r_'
const repeaterDelimiter = '__'
const mobileSplitSuffix = '-mobile'
const sharedPartsPrefix = `sharedParts${refCompDelimiter}`

export const MOBILE_SPLITTABLE_TYPE = {
    props: true
}
export const getRefHostTemplateId = (id: string): string => displayedOnlyStructureUtil.getRefHostCompId(id)

export const extractTargetCompId = (id: string, namespace: string): string => {
    const compId = VIEW_MODES[namespace] ? id : extractBaseComponentByNamespace(id, namespace)
    return compId?.split(refCompDelimiter).slice(-1)[0]
}

export const getRepeatersNestingOrder = (id: string): string[] => id.split(repeaterDelimiter).slice(1)

export const getRepeatersNestingSuffix = (id: string): string => getRepeatersNestingOrder(id).join(repeaterDelimiter)

const getRepeaterTemplateId = (id: string): string => id.split(repeaterDelimiter, 1)[0]

export const getUniqueRefId = (refHostId: string, compId: string) => `${refHostId}${refCompDelimiter}${compId}`

const isMobilePointer = (compPointer: Pointer) => compPointer.type === VIEW_MODES.MOBILE

const getDataItemSuffix = (itemType: string, isMobile: boolean) => {
    const maybeAddMobileSuffix = isMobile && MOBILE_SPLITTABLE_TYPE[itemType] ? mobileSplitSuffix : ''
    const itemTypeSuffix = `-${COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]}`
    return `${itemTypeSuffix}${maybeAddMobileSuffix}`
}

const isRepeatableDataItemType = (itemType: string) => getNamespaceConfig(itemType)?.supportsRepeaterItem

export const getCompIdWithRepeatersNesting = (compId: string, repeatersNestingOrder: string[]) =>
    [compId, ...repeatersNestingOrder].join(repeaterDelimiter)

export const getItemQueryId = (compPointer: Pointer, itemType: string) => {
    const compId = getRepeaterTemplateId(compPointer.id)
    const repeatersNestingOrder = isRepeatableDataItemType(itemType) ? getRepeatersNestingOrder(compPointer.id) : []
    const dataItemSuffix = getDataItemSuffix(itemType, isMobilePointer(compPointer))

    return getCompIdWithRepeatersNesting(`${compId}${dataItemSuffix}`, repeatersNestingOrder)
}

export const getRepeatedItemQuery = (dal: DAL, repeatedPointer: Pointer, itemType: string) => {
    const templatePointer = pointerUtils.getRepeatedItemPointerIfNeeded(repeatedPointer)
    const template = dal.get(templatePointer)
    if (!template) {
        return undefined
    }

    const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]

    const templateItemQuery = stripHashIfExists(template[queryKey])
    if (isRepeatableDataItemType(itemType)) {
        const repeatersNesting = getRepeatersNestingOrder(repeatedPointer.id)
        return `${templateItemQuery}${repeaterDelimiter}${repeatersNesting.join(repeaterDelimiter)}`
    }

    return templateItemQuery
}

export const createRefCompQuery = (
    refHostInflatedId: string,
    referredCompId: string,
    itemType: string = '',
    isMobile?: boolean
): string => {
    const repeatersNestingOrder = isRepeatableDataItemType(itemType) ? getRepeatersNestingOrder(referredCompId) : []
    const refHostId = getRepeaterTemplateId(refHostInflatedId)
    referredCompId = getRepeaterTemplateId(referredCompId)

    const suffix = itemType ? getDataItemSuffix(itemType, !!isMobile) : ''
    const newReferredCompId = `${getUniqueRefId(refHostId, referredCompId)}${suffix}`

    return getCompIdWithRepeatersNesting(newReferredCompId, repeatersNestingOrder)
}

export const createReferredId = (refCompId: string, referredStructureIds: string[]) =>
    [refCompId, ...referredStructureIds].join(refCompDelimiter)

export const splitReferredId = (compId: string) => compId.split(refCompDelimiter)

export const isReferredId = (id: string): boolean => id.includes(refCompDelimiter)

export const replaceRefComponentIdInOverride = (overrideId: string, refCompId: string): string => {
    const [, ...referredStructureIds] = splitReferredId(overrideId)
    return createReferredId(refCompId, referredStructureIds)
}

export const isSharedPartsId = (id: string): boolean => id?.startsWith(sharedPartsPrefix)

export const isSharedPartsPointer = (compPointer: CompRef): boolean => isSharedPartsId(compPointer?.id)

export const inflateWithSharedParts = (pointer: Pointer): Pointer => ({
    ...pointer,
    id: `${sharedPartsPrefix}${pointer.id}`
})

export const removeSharedPartsPrefix = (id: string): string => id.replace(sharedPartsPrefix, '')
