import type {
    ImportExportOptions,
    RawComponentFeaturesOptions,
    RawImportExportOptions
} from '@wix/document-services-types'
import {PREDEFINED_COMPONENT_FEATURES_GROUPS} from './constants'
import {ReportableError} from '@wix/document-manager-utils'

const getComponentFeatures = ({
    componentFeatures = 'PublicViewerData'
}: ImportExportOptions): RawComponentFeaturesOptions => {
    const features = PREDEFINED_COMPONENT_FEATURES_GROUPS[componentFeatures]

    if (!features) {
        throw new ReportableError({
            message: 'Got an unknown component features group option',
            errorType: 'UNKNOWN_COMPONENT_FEATURES_OPTION',
            extras: {componentFeatures}
        })
    }

    return {
        features,
        components: {}
    }
}

export const getRawOptions = (options: ImportExportOptions = {}): RawImportExportOptions => ({
    componentFeatures: getComponentFeatures(options)
})
