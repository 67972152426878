import {DocumentManager, pointerUtils} from '@wix/document-manager-core'
import * as constants from '../../../../constants/constants'
import type {PageMigrator} from '../dataMigrationRunner'
const {getPointer} = pointerUtils

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {dal} = documentManager
    const desktopPointer = getPointer(pageId, constants.VIEW_MODES.DESKTOP)
    const mobilePointer = getPointer(pageId, constants.VIEW_MODES.MOBILE)
    const desktopPage = dal.get(desktopPointer)
    const mobilePage = dal.get(mobilePointer)

    if (!desktopPage?.metaData?.sig) {
        dal.touch(desktopPointer)
    }
    if (!mobilePage?.metaData?.sig) {
        dal.touch(mobilePointer)
    }
}

const name = 'addMissingSignatures'
const version = 1

export const addMissingSignatures: PageMigrator = {
    migratePage,
    name,
    version
}
