import type {CreateExtArgs} from '@wix/document-manager-core'

const BLOG_APP_PAGE_ID = {
    SINGLE_POST: '7326bfbb-4b10-4a8e-84c1-73f776051e10',
    FEED: '79f391eb-7dfc-4adf-be6e-64434c4838d9'
}

const isAppPageWithId = ({dal, pointers}: CreateExtArgs, pageId: string, appPageId: string) => {
    const pageDataItemPointer = pointers.data.getDataItem(pageId)
    const pageDataItem = dal.get(pageDataItemPointer)
    return (
        pageDataItem &&
        pageDataItem.type === 'AppPage' &&
        pageDataItem.appPageType === 'AppPage' &&
        pageDataItem.appPageId === appPageId
    )
}

export const isBlogSinglePostPage = (createExtArgs: CreateExtArgs, pageId: string) => {
    return isAppPageWithId(createExtArgs, pageId, BLOG_APP_PAGE_ID.SINGLE_POST)
}
