import type {DAL} from '@wix/document-manager-core'

export const getSections = (dal: DAL, pageId: string) => {
    const indexKey = dal.queryFilterGetters.getPageCompFilter(pageId)
    return dal.queryKeys(
        'DESKTOP',
        indexKey,
        ({componentType}) => componentType === 'wysiwyg.viewer.components.ClassicSection'
    )
}
