import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {isPopup, isPopupFullWidth} from '../../page/popupUtils'
import {getComponentType} from '../../../utils/dalUtils'

const masterPage = 'wysiwyg.viewer.components.WSiteStructure'
const HEADER_FOOTER_PAGE_AND_MASTERPAGE = [
    'wysiwyg.viewer.components.FooterContainer',
    'wysiwyg.viewer.components.HeaderContainer',
    'mobile.core.components.Page',
    'core.components.Page',
    'wixapps.integration.components.AppPage',
    masterPage
]

const POPUP_CONTAINER = 'wysiwyg.viewer.components.PopupContainer'

export const notContainableByPopup = (createExtArgs: CreateExtArgs, containerPointer: Pointer) => {
    const pagePointer = createExtArgs.pointers.structure.getPageOfComponent(containerPointer)
    return !isPopup(createExtArgs, pagePointer.id)
}

export const isHeaderOrFooterOrPageOrMasterPage = (componentType: string) => {
    return _.includes(HEADER_FOOTER_PAGE_AND_MASTERPAGE, componentType)
}

export const isPopupPageOrPopupContainer = (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
    return (
        isPopup(createExtArgs, compPointer.id) || getComponentType(createExtArgs.dal, compPointer) === POPUP_CONTAINER
    )
}

export const isSiteStructure = (componentType: string) => {
    return componentType === masterPage
}

export const containableByFullWidthPopup = (createExtArgs: CreateExtArgs, containerPointer: Pointer) => {
    const pagePointer = createExtArgs.pointers.structure.getPageOfComponent(containerPointer)
    return !(isPopup(createExtArgs, pagePointer.id) && !isPopupFullWidth(createExtArgs, pagePointer))
}

export const isPopupContainerByType = (componentType: string) => {
    return componentType === POPUP_CONTAINER
}

export const isPopupContainer = ({dal}: CreateExtArgs, containerPointer: Pointer) => {
    const componentType = getComponentType(dal, containerPointer)
    return isPopupContainerByType(componentType)
}
