import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {
    isComponentSuitableForNonRenderingState,
    isComponentSuitableForNonRenderingStateByPointer
} from '../utils/componentUtils'

const BOX_SLIDE_SHOW = 'wysiwyg.viewer.components.BoxSlideShow'

export const boxSlideShowSlideMetaData = {
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: false,
    canContain: ({dal}: CreateExtArgs, componentPointer: Pointer, potentialContainee: Pointer) =>
        isComponentSuitableForNonRenderingStateByPointer(dal, potentialContainee),
    allowedParentTypes: [BOX_SLIDE_SHOW],
    canContainByStructure: ({}: CreateExtArgs, componentPointer: Pointer, potentialContaineeStructure: CompStructure) =>
        isComponentSuitableForNonRenderingState(potentialContaineeStructure.componentType)
}
