import {
    siteWidth,
    structure,
    structureUtils,
    arrangement,
    utils,
    meshMigrationUtil,
    responsiveLayout
} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {deprecate, extensionPublicAPI} = publicMethodUtils
    return {
        initMethod: structure.initialize,
        methods: {
            components: {
                setContainer: publicMethodUtils.defineDataManipulationAction(structure.setContainer, {
                    isUpdatingAnchors: utils.YES,
                    getReturnValue: structure.getNewComponentPointerAfterReparent,
                    getInteractionParams: structure.getSetContainerInteractionParams
                }),
                updateComponentRelations: publicMethodUtils.defineDataManipulationAction(
                    structure.updateComponentRelations
                ),
                reparentComponentToPage: deprecate(
                    publicMethodUtils.defineDataManipulationAction(structure.reparentComponentToPage, {
                        isUpdatingAnchors: utils.YES
                    }),
                    'reparentComponentToPage is deprecated, use setContainer instead'
                ),
                isShowOnAllPages: publicMethodUtils.defineGetter(structure.isShowOnAllPages),
                isShowOnSomePages: publicMethodUtils.defineGetter(structure.isShowOnSomePages),
                layout: {
                    /**
                     * Update the layout of a component - resize, move or rotate it.
                     * Angle is validated to the range of 0-359 degrees.
                     *
                     * @member documentServices.components.layout
                     * @param {compPointer} compReference
                     * @param {Object} layoutObject
                     * @param {number} layoutObject.x
                     * @param {number} layoutObject.y
                     * @param {number} layoutObject.width
                     * @param {number} layoutObject.height
                     * @param {number} layoutObject.rotationInDegrees
                     * @param {boolean} layoutObject.fixedPosition
                     * @param {number} layoutObject.scale
                     *      @example
                     *      documentServices.components.layout.update(comp, {x:100, width: 50, angleInDegrees: 255}, false)
                     */
                    update: publicMethodUtils.defineDataManipulationAction(structure.updateCompLayout, {
                        isUpdatingAnchors: utils.YES,
                        noRefresh: true,
                        disableLogInteraction: true
                    }),
                    syncTemplateLayout: publicMethodUtils.defineDataManipulationAction(structure.syncTemplateLayout, {
                        isUpdatingAnchors: utils.NO,
                        noRefresh: true
                    }),
                    updateAndAdjustLayout: publicMethodUtils.defineDataManipulationAction(
                        structure.updateCompLayoutAndAdjustLayout,
                        {
                            isUpdatingAnchors: utils.NO,
                            shouldLockComp: true
                        }
                    ),
                    validateAndForceUpdateLayout: publicMethodUtils.defineDataManipulationAction(
                        structure.validateAndForceUpdateLayout,
                        {
                            isUpdatingAnchors: utils.YES
                        }
                    ),
                    /**
                     * Changes the component to/from fixed/absolute positioning
                     *
                     * @member documentServices.components.layout
                     * @param {compPointer} compPointer
                     * @param {boolean} shouldBeFixed
                     */
                    updateFixedPosition: publicMethodUtils.defineDataManipulationAction(structure.updateFixedPosition, {
                        isUpdatingAnchors: utils.YES
                    }),
                    updateAndPreserveProportions: publicMethodUtils.defineDataManipulationAction(
                        structure.updateAndPreserveProportions,
                        {
                            isUpdatingAnchors: utils.YES
                        }
                    ),

                    /**
                     * Gets the component's layout relative to the structure - meaning the X and the Y will be relative to the whole document and not just the parent
                     *
                     * @member documentServices.components.layout
                     * @param {compPointer} compPointer
                     * @return {layoutObject}
                     */
                    getRelativeToStructure: publicMethodUtils.deprecate(
                        publicMethodUtils.defineGetter(structure.getCompLayoutRelativeToStructure),
                        'Should be replaced with ds.components.measure apis'
                    ),
                    /**
                     * Gets the component's layout relative to the screen.
                     * The X will be relative to the screen, and the Y will be relative to the structure (legacy issues).
                     *
                     * For a 'real' relative to screen position (viewport), see getRelativeToScreenConsideringScroll
                     *
                     * @member documentServices.components.layout
                     * @param {compPointer} compPointer
                     * @return {layoutObject}
                     */
                    getRelativeToScreen: publicMethodUtils.deprecate(
                        publicMethodUtils.defineGetter(structure.getCompLayoutRelativeToScreen),
                        'Should be replaced with ds.components.measure apis'
                    ),
                    /**
                     * Gets the component's layout relative to the screen.
                     * The X and Y will be relative to the screen (viewport)
                     *
                     * @member documentServices.components.layout
                     * @param {compPointer} compPointer
                     * @return {layoutObject}
                     */
                    getRelativeToScreenConsideringScroll: publicMethodUtils.deprecate(
                        publicMethodUtils.defineGetter(structure.getCompLayoutRelativeToScreenConsideringScroll),
                        'Should be replaced with ds.components.measure apis'
                    ),

                    getProportionStructure: publicMethodUtils.defineGetter(structure.getProportionStructure),

                    getEffectiveTextDimensions: publicMethodUtils.defineGetter(structure.getEffectiveTextDimensions),

                    /**
                     * @deprecated
                     */
                    getMinDimensions: publicMethodUtils.defineGetter(structure.getDeprecatedMinDimensions),
                    isFixedPosition: publicMethodUtils.defineGetter(structure.isFixedPosition),
                    isHorizontallyStretchedToScreen: publicMethodUtils.defineGetter(
                        structure.isHorizontallyStretchedToScreen
                    ),
                    isHorizontallyStretched: publicMethodUtils.defineGetter(structure.isHorizontallyStretched),
                    isVerticallyStretched: publicMethodUtils.defineGetter(structure.isVerticallyStretched),
                    isHorizontallyStretchedToScreenByStructure: publicMethodUtils.defineGetter(
                        structure.isHorizontallyStretchedToScreenByStructure
                    ),
                    isRenderedInFixedPosition: publicMethodUtils.defineGetter(structure.isRenderedInFixedPosition),
                    isAncestorRenderedInFixedPosition: publicMethodUtils.defineGetter(
                        structure.isAncestorRenderedInFixedPosition
                    ),
                    getClosestAncestorRenderedInFixedPosition: publicMethodUtils.defineGetter(
                        structure.getClosestAncestorRenderedInFixedPosition
                    ),
                    isShowOnFixedPosition: publicMethodUtils.defineGetter(structure.isShowOnFixedPosition),
                    isDocked: publicMethodUtils.defineGetter(structure.isDocked),
                    isDockedToScreen: publicMethodUtils.defineGetter(structure.isDockedToScreen),
                    isRotated: publicMethodUtils.defineGetter(structure.isRotated),
                    getDockedStyle: publicMethodUtils.defineGetter(structure.getDockedStyle),
                    getDock: publicMethodUtils.defineGetter(structure.getDock),
                    setDock: publicMethodUtils.defineDataManipulationAction(structure.setDock, {
                        isUpdatingAnchors: utils.YES
                    }),
                    updateDock: publicMethodUtils.defineDataManipulationAction(structure.updateDock, {
                        isUpdatingAnchors: utils.YES
                    }),
                    unDock: publicMethodUtils.defineDataManipulationAction(structure.unDock, {
                        isUpdatingAnchors: utils.YES
                    }),
                    updateAspectRatio: publicMethodUtils.defineDataManipulationAction(structure.updateAspectRatio),
                    removeAspectRatio: publicMethodUtils.defineDataManipulationAction(structure.removeAspectRatio),
                    isAspectRatioOn: publicMethodUtils.defineGetter(structure.isAspectRatioOn),
                    migrateToCurrentSiteWidth: publicMethodUtils.defineDataManipulationAction(
                        siteWidth.migrateToCurrentSiteWidth
                    )
                },
                responsiveLayout: {
                    get: publicMethodUtils.defineGetter(responsiveLayout.get)
                },
                arrangement: {
                    canMoveForward: publicMethodUtils.defineGetter(arrangement.canMoveForward),
                    canMoveBackward: publicMethodUtils.defineGetter(arrangement.canMoveBackward),
                    moveBackward: publicMethodUtils.defineDataManipulationAction(arrangement.moveBackward),
                    moveForward: publicMethodUtils.defineDataManipulationAction(arrangement.moveForward),
                    moveToFront: publicMethodUtils.defineDataManipulationAction(arrangement.moveToFront),
                    moveToBack: publicMethodUtils.defineDataManipulationAction(arrangement.moveToBack),
                    moveToIndex: publicMethodUtils.defineDataManipulationAction(arrangement.moveToIndex),
                    reorderComponents: extensionPublicAPI.dataManipulation('arrangement.reorderComponents'),
                    getCompIndex: publicMethodUtils.defineGetter(arrangement.getCompIndex)
                }
            },
            siteSegments: {
                layout: {
                    update: publicMethodUtils.defineDataManipulationAction(structure.updateCompLayout, {
                        isUpdatingAnchors: utils.YES,
                        noRefresh: true
                    })
                }
            },
            utils: {
                getBoundingLayout: publicMethodUtils.defineGetter(structureUtils.getBoundingLayout),
                getLayoutFromBoundingLayout: publicMethodUtils.defineGetter(structureUtils.getLayoutFromBoundingLayout)
            },
            pages: {
                usesMeshLayout: publicMethodUtils.defineGetter(structure.pageUsesMeshLayout)
            },
            site: {
                qualifiesForMeshLayout: publicMethodUtils.defineGetter(structure.siteQualifiesForMeshLayout),
                getHeight: publicMethodUtils.defineGetter(structure.getSiteHeight),
                getWidth: publicMethodUtils.defineGetter(siteWidth.getSiteWidth),
                setWidth: publicMethodUtils.defineDataManipulationAction(siteWidth.setSiteWidth, {noBatching: true}),
                setScrollAndScale: publicMethodUtils.defineDataManipulationAction(structure.setScrollAndScale, {
                    noBatching: true
                }),
                animateScroll: publicMethodUtils.defineDataManipulationAction(structure.animateScroll),
                setScroll: publicMethodUtils.defineAction(structure.setScroll),
                getScroll: publicMethodUtils.defineGetter(structure.getScroll),
                isScrollingEnabled: publicMethodUtils.defineGetter(structure.isScrollingEnabled),
                isSiteLayoutMesh: publicMethodUtils.defineGetter(structure.isSiteLayoutMesh),
                migrateToMeshLayout: publicMethodUtils.defineDataManipulationAction(structure.migrateToMeshLayout),
                cancelSiteToMeshMigration: publicMethodUtils.defineGetter(meshMigrationUtil.cancel),
                migrateSiteToMesh: publicMethodUtils.defineDataManipulationAction(meshMigrationUtil.migrateSiteToMesh),
                getSiteX: publicMethodUtils.defineGetter(structure.getSiteX),
                setIsFluid: publicMethodUtils.defineDataManipulationAction(structure.setSiteIsFluid),
                getIsFluid: publicMethodUtils.defineGetter(structure.getSiteIsFluid)
            }
        }
    }
}
