import _ from 'lodash'
import {getDefaultViewerMock, getDefaultViewerStore} from '@wix/viewer-mock'
import {deepClone} from '@wix/wix-immutable-proxy'
import {
    host as dmHost,
    Host,
    initialize,
    ConfigName,
    DefaultRendererModelBuilderForHost
} from '@wix/document-manager-host-common'
import miniSiteModels from './models.json'
import {createPrivateServices} from '../privateServices'
import {createViewerManagerAndExtendHost} from '../adapter'
import * as dsInterface from '@wix/document-services'
import {MockCEditTestServer, constants, PageAPI, RMApi, MiniSiteExtensionAPI} from '@wix/document-manager-extensions'
import {newSchemaService} from '@wix/document-services-json-schemas'
const {mainInitialization} = initialize
import {store, DocumentManager, CoreLogger, ModelsInitializationType} from '@wix/document-manager-core'
import type {
    Experiment,
    Pointer,
    MiniSiteManager,
    DocumentServicesObject,
    ClientSpecMap,
    PreviewElementOptions
} from '@wix/document-services-types'
import type {AdapterLogger} from '../adapterLogger'
import type {ViewerSiteAPI} from '@wix/viewer-manager-adapter'
import type {ViewerFragmentsProps} from '@wix/viewer-manager-interface'

const {VIEWER_PAGE_DATA_TYPES, VIEW_MODES} = constants
const {createStore} = store

const getMockedViewer = (overrides: any = {}, viewerParams: any = {}) => {
    const viewerStore = getDefaultViewerStore(overrides, viewerParams.withDefaults)
    return getDefaultViewerMock(viewerStore, overrides, viewerParams.withDefaults)
}

const getDataAndStructureFromPages = (dm: any, pagePointers: Pointer[]) => {
    const pageAPI = dm.extensionAPI.page as PageAPI
    const structure = pagePointers.reduce((acc, pagePointer) => {
        return Object.assign(acc, dm.dal.query(pagePointer.type, pageAPI.getPageIndexId(pagePointer.id)))
    }, {})

    const dataPageIds = _(pagePointers).map('id').concat(['masterPage']).uniq().value()
    const data = dataPageIds.reduce((acc, pageId) => {
        const pageData = _(VIEWER_PAGE_DATA_TYPES)
            .mapValues((v, nsName) => dm.dal.query(nsName, pageAPI.getPageIndexId(pageId)))
            .pickBy(_.identity)
            .mapKeys((v, k) => VIEWER_PAGE_DATA_TYPES[k])
            .value()

        return _.merge(acc, pageData)
    }, {})
    return {data, structure}
}
const getDefaultViewerOverrides = (dm: any) =>
    deepClone(getDataAndStructureFromPages(dm, [dm.pointers.structure.getPage('c1dmp', VIEW_MODES.DESKTOP)]))

const inheritModelsFromHostDM = (dm: DocumentManager, editedDocumentDM: DocumentManager) => {
    const themePointer = editedDocumentDM.pointers.data.getThemeItem('THEME_DATA', 'masterPage')
    dm.dal.set(themePointer, editedDocumentDM.dal.get(themePointer))
}

export interface MiniSiteManagerCreationArgs {
    logger: AdapterLogger
    experimentInstance: Experiment
    configName: ConfigName
    editedDocumentDM: DocumentManager
    getViewerFragments: ViewerSiteAPI['getViewerFragments']
}

const getEmptyLogger = (): CoreLogger => ({
    captureError: () => {},
    interactionEnded: () => {},
    interactionStarted: () => {},
    breadcrumb: () => {},
    flush: async () => {},
    reportBI: () => {}
})

export const createMiniSiteManagerAPI = async ({
    logger, //currently not used, but we might need it later on
    experimentInstance,
    configName,
    editedDocumentDM,
    getViewerFragments
}: MiniSiteManagerCreationArgs): Promise<MiniSiteManager> => {
    let hostReact: any

    const environmentContext = {
        fetchFn: fetch, //we have readonly config so no POST will happen. If an API needs to read for some reason, we will let it
        serverFacade: new MockCEditTestServer()
    }

    const rendererModelFromHostDM = _.get(window, ['rendererModel'])
    //set initial CSM to what's in the dal, since it is possible something updated while loading (like databinding fake app)
    rendererModelFromHostDM.clientSpecMap = editedDocumentDM.dal.get(
        editedDocumentDM.pointers.general.getClientSpecMap()
    )
    const models = {..._.pick(window, ['serviceTopology', 'documentServicesModel'])}

    const minisiteLogger = getEmptyLogger()

    const miniSiteHost: Host = dmHost.createHost({
        models,
        experimentInstance,
        logger: minisiteLogger,
        schemaService: newSchemaService.staticInstance,
        config: {
            configName,
            dsOrigin: 'miniSite',
            autosaveRestore: 'false',
            supportsPlatformInitialization: false,
            isReadOnly: true,
            features: ['miniSite']
        },
        environmentContext,
        initFunc: mainInitialization.initialize,
        pageList: rendererModelFromHostDM.pageList,
        rendererModelBuilder: new DefaultRendererModelBuilderForHost(rendererModelFromHostDM)
    })

    const dm = miniSiteHost.documentManager
    const initialStore = createStore()
    _.forOwn(miniSiteModels, (typeMap, type) => {
        _.forOwn(typeMap, (data, id) => {
            const pointer = dm.pointers.getPointer(id, type)
            initialStore.set(pointer, data)
        })
    })

    dm.dal.mergeToApprovedStore(initialStore)
    dm.initializeModels({
        ...models,
        rendererModel: rendererModelFromHostDM
    } as ModelsInitializationType)

    inheritModelsFromHostDM(dm, editedDocumentDM)

    const mockViewer = getMockedViewer(getDefaultViewerOverrides(dm), {
        experimentInstance: dm.experimentInstance,
        withDefaults: {
            defaultPageId: 'c1dmp'
        }
    })

    const {host, viewerManager} = await createViewerManagerAndExtendHost({hostWithDM: miniSiteHost, viewer: mockViewer})

    const ps = createPrivateServices({host, viewerManager})

    const adapter = {
        ps,
        host,
        config: host.config,
        documentServicesDataFixer: {
            fix: _.noop
        }
    }

    const {rendererModel: editedDocumentRendererModelExtension} = editedDocumentDM.extensionAPI as RMApi
    const {miniSite: miniSiteExtensionAPI} = dm.extensionAPI as MiniSiteExtensionAPI
    const {metaSiteId} = rendererModelFromHostDM
    miniSiteExtensionAPI.updateCSMForMSID(metaSiteId, editedDocumentRendererModelExtension.getClientSpecMap())
    editedDocumentRendererModelExtension.registerToClientSpecMapUpdate((updatedClientSpecMap: ClientSpecMap) => {
        miniSiteExtensionAPI.updateCSMForMSID(metaSiteId, updatedClientSpecMap)
    })
    const setHostReact = (reactFromHost: any) => {
        hostReact = reactFromHost
    }

    const createPreviewElements = async (compPointers: Pointer[], previewElementOptions?: PreviewElementOptions) => {
        const viewMode = compPointers[0].type
        if (!VIEW_MODES[viewMode]) {
            throw new Error(`You can only render a component pointer. You supplied a pointer of type ${viewMode}`)
        }
        if (_(compPointers).map('type').uniq().size() > 1) {
            throw new Error(
                'You cannot simultaneously render preview elments from different view modes. Please call createPreviewElements for each view mode'
            )
        }
        const pagePointers = _.uniqWith(
            compPointers.map(pointer => ps.pointers.components.getPageOfComponent(pointer)),
            _.isEqual
        )
        const rootCompIds = _.map(compPointers, 'id')
        const {data, structure} = getDataAndStructureFromPages(dm, pagePointers)
        const renderFlags = dm.dal.get(dm.pointers.general.getRenderFlags())
        const clientSpecMap = previewElementOptions?.applicationCSMTemplateID
            ? miniSiteExtensionAPI.getLoadedCSM(previewElementOptions.applicationCSMTemplateID)
            : deepClone(editedDocumentDM.dal.get(editedDocumentDM.pointers.general.getClientSpecMap()))
        //need to clone props, bc thunderbolt renderer mutates our metaData.pageId
        const viewerDirectProps = deepClone({
            structure,
            data: data as ViewerFragmentsProps['data'],
            renderFlags,
            onReady: _.noop,
            rootCompIds: rootCompIds ?? _(pagePointers).map('id').uniq().value(),
            viewMode,
            clientSpecMap,
            rootStyleIds: []
        })
        if (hostReact) {
            return getViewerFragments(hostReact, viewerDirectProps)
        }
    }

    let ds
    // eslint-disable-next-line prefer-const
    let readyPromise: Promise<DocumentServicesObject>

    const miniSiteManager: MiniSiteManager = {
        ds,

        setHostReact,

        createPreviewElements,
        // @ts-ignore
        waitForLoad: () => readyPromise
    }

    const modules = dsInterface.initPublicModules(miniSiteHost.config.modulesList)
    //@ts-ignore
    readyPromise = dsInterface.createDocumentServices(adapter, modules, logger).then(miniSiteDs => {
        ds = miniSiteManager.ds = miniSiteDs
        return ds
    })

    return miniSiteManager
}
