import type {PublicMethodUtils} from '@wix/document-services-types'
import {responsiveLayout} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                responsiveLayout: {
                    update: publicMethodUtils.defineDataManipulationAction(responsiveLayout.update),
                    get: publicMethodUtils.defineGetter(responsiveLayout.get),
                    pin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.pin),
                    unpin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.unpin),
                    moveChildrenToKeepThemInPlace: publicMethodUtils.defineDataManipulationAction(
                        responsiveLayout.moveChildrenToKeepThemInPlace
                    ),
                    runtime: {
                        measure: {
                            getRelativeToContainerBoundingBox: publicMethodUtils.defineGetter(
                                responsiveLayout.getRelativeToContainerBoundingBox
                            ),
                            getBoundingBox: publicMethodUtils.defineGetter(responsiveLayout.getBoundingBox),
                            getNonRotatedBoundingBox: publicMethodUtils.defineGetter(
                                responsiveLayout.getNonRotatedBoundingBox
                            ),
                            getRelativeToViewportBoundingBox: publicMethodUtils.defineGetter(
                                responsiveLayout.getRelativeToViewportBoundingBox
                            ),
                            getScrollHeight: publicMethodUtils.defineGetter(responsiveLayout.getScrollHeight),
                            getClientHeight: publicMethodUtils.defineGetter(responsiveLayout.getClientHeight),
                            getScrollWidth: publicMethodUtils.defineGetter(responsiveLayout.getScrollWidth),
                            getClientWidth: publicMethodUtils.defineGetter(responsiveLayout.getClientWidth),
                            getGridMeasures: publicMethodUtils.defineGetter(responsiveLayout.getGridMeasures),
                            getContentArea: publicMethodUtils.defineGetter(responsiveLayout.getContentArea)
                        },
                        detachComponents: publicMethodUtils.defineAction(responsiveLayout.detachComponents),
                        updateDetachedLayout: publicMethodUtils.defineAction(responsiveLayout.updateDetachedLayout),
                        reattachComponents: publicMethodUtils.defineAction(responsiveLayout.reattachComponents)
                    },
                    mesh: {
                        absoluteToMesh: publicMethodUtils.defineGetter(responsiveLayout.absoluteToMesh),
                        remeasureContainer: publicMethodUtils.defineDataManipulationAction(
                            responsiveLayout.remeasureContainer
                        ),
                        getMeasurements: publicMethodUtils.defineGetter(responsiveLayout.getMeasurements)
                    }
                }
            }
        }
    }
}
