'use strict'
const {
    migrateStylableComponentsByCompType
} = require('../helpers/stylableMigration/migrateStylableComponentsByCompType')

const COLLAPSIBLE_TEXT_COMPTYPE = 'wixui.CollapsibleText'
const COLLAPSIBLE_TEXT_NON_STYLABLE_SKIN = 'wixui.skins.CollapsibleText'

const CSS_PROP_TO_VAR_NAME = {
    'background-color': 'text-highlight',
    'text-decoration-line': 'text-decoration',
    'text-direction': 'direction'
}

const migratePage = (structure, data, uniqueIdGenerator, clientSpecMap) => {
    migrateStylableComponentsByCompType({
        structure,
        data,
        uniqueIdGenerator,
        clientSpecMap,
        newSkin: COLLAPSIBLE_TEXT_NON_STYLABLE_SKIN,
        compType: COLLAPSIBLE_TEXT_COMPTYPE,
        selectorsMappings: {
            '.root::expand-control': {
                name: 'button',
                properties: CSS_PROP_TO_VAR_NAME
            },
            '.root': {
                name: 'root',
                properties: CSS_PROP_TO_VAR_NAME,
                removePrefix: true
            },
            '.root::expand-control:hover': {
                name: 'button-hover',
                root: 'button',
                properties: CSS_PROP_TO_VAR_NAME
            },
            '.root::expand-control:disabled': {
                name: 'button-disabled',
                root: 'button',
                properties: CSS_PROP_TO_VAR_NAME
            },
            '.root::text': {
                name: 'text',
                properties: CSS_PROP_TO_VAR_NAME
            }
        }
    })
}

module.exports = {
    name: 'collapsibleTextStylesFixer',
    version: 0,
    experimentalVersions: [{experiment: 'dm_migrateCollapsibleTextToNonStylable', version: 1}],
    exec(pageJson, pageIdsArray, magicObject) {
        const {clientSpecMap} = magicObject
        const {uniqueIdGenerator} = magicObject.dataFixerUtils

        if (!magicObject.isExperimentOpen('dm_migrateCollapsibleTextToNonStylable')) {
            return
        }

        const {data, structure} = pageJson
        migratePage(structure, data, uniqueIdGenerator, clientSpecMap)
    },
    fixerRequiresReruns: true
}
