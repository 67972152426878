import _ from 'lodash'
import type {
    DocumentServicesObject,
    PS,
    PublicMethodDefinition,
    SetOperationsQueue,
    SoqErrorCallback,
    SoqErrorDetails,
    TransactionsObject,
    NestedRecord,
    DropCommitContext
} from '@wix/document-services-types'
import {TransactionRejectionError} from '@wix/document-manager-core'
import {createPromiseFromAsyncPartOfPublicMethod, getMethodReturnValue} from '../publicAPI/apiUtils/publicMethodsUtils'
import type {InnerViewerExtensionAPI} from '@wix/document-manager-extensions'

interface SetOperationParamsForTransaction {
    noBatching: false
    noBatchingAfter: false
    isAsyncOperation: true
    transaction: true
    methodName: string
    asyncPreDataManipulation(): void
}

interface AsyncPreDataManipulationResult<T> {
    hasError: boolean
    hasResult: boolean
    error: Error | null
    result: T | null
}

const goodResult = <T>(result: T): AsyncPreDataManipulationResult<T> => ({
    hasError: false,
    error: null,
    hasResult: true,
    result
})

const badResult = (error: Error): AsyncPreDataManipulationResult<any> => ({
    hasError: true,
    error,
    hasResult: false,
    result: null
})

const convertPublicMethodToAsyncFunction = (
    ps: PS,
    ds: DocumentServicesObject,
    method: PublicMethodDefinition,
    path: string[]
): Function => {
    if (!method.asyncPreDataManipulation || !method.isAsyncOperation) {
        throw new Error(
            'To convert a public ds method to an async function ' +
                'it needs to be defined as async (simple boolean, or functions' +
                'It also needs to provide an asyncPreDataManipulation function.'
        )
    }
    return async (...args: any[]) => {
        if (!ds.transactions.isRunning()) {
            const result = _.invoke(ds, path, ...args)
            await ds.waitForChangesAppliedAsync()
            return result
        }
        const {hasReturnValue, returnValue} = getMethodReturnValue(ps, method, args)
        const newArgs = hasReturnValue ? [returnValue, ...args] : args
        const asyncResult = await createPromiseFromAsyncPartOfPublicMethod(ps, method, newArgs)
        method.method(ps, asyncResult, ...newArgs)
        ps.setOperationsQueue.executeOperationCallbacksInTransaction()

        if (hasReturnValue) {
            return returnValue
        }
    }
}

const convertMethods = (
    ps: PS,
    ds: DocumentServicesObject,
    node: NestedRecord<PublicMethodDefinition>,
    path: string[]
): NestedRecord<Function> => {
    if (node.isPublicAPIDefinition) {
        // @ts-ignore
        return node.isAsyncOperation
            ? convertPublicMethodToAsyncFunction(ps, ds, node as unknown as PublicMethodDefinition, path)
            : null
    }
    if (_.isPlainObject(node)) {
        const converted = _.mapValues(node, (v, k) =>
            convertMethods(ps, ds, v as NestedRecord<PublicMethodDefinition>, [...path, k])
        )
        return _.pickBy(converted, v => _.isFunction(v) || !_.isEmpty(v))
    }
    // @ts-ignore
    return null
}

const createPromisesObject = (
    ps: PS,
    ds: DocumentServicesObject,
    methods: NestedRecord<PublicMethodDefinition>
): NestedRecord<Function> => convertMethods(ps, ds, methods, []) ?? {}

const createAsyncPreDataManipulationFunction = <T>(
    ps: PS,
    action: () => Promise<T>,
    onEnd: (result: AsyncPreDataManipulationResult<T>) => void
): SetOperationParamsForTransaction => ({
    noBatching: false,
    noBatchingAfter: false,
    isAsyncOperation: true,
    transaction: true,
    methodName: 'transaction',
    asyncPreDataManipulation() {
        ps.dal.disableCommits()
        ps.setOperationsQueue
            .withWaitForChangesDisabled(action)
            .finally(() => ps.dal.enableCommits())
            .then(x => {
                ps.setOperationsQueue.asyncPreDataManipulationComplete(x)
                onEnd(goodResult(x))
            })
            .catch(e => {
                const transactionToDrop = ps.dal.getCurrentOpenTransaction()
                ps.dal.dropUncommittedTransaction(e.message)
                const viewer = ps.extensionAPI.viewer as InnerViewerExtensionAPI | undefined
                viewer?.syncPointers(transactionToDrop.items.map(item => item.key))
                ps.setOperationsQueue.asyncPreDataManipulationComplete(null, e)
                onEnd(badResult(e))
            })
    }
})

const isWaitForApprovalAvailable = (ps: PS): boolean => {
    // The cast to any is needed since there was an issue with adding `extensionAPI` to the PS interface
    const extensions = (ps as any).extensionAPI
    return !!extensions.continuousSave?.shouldSave()
}

const throwAllErrors = async <T>(soq: SetOperationsQueue, op: () => Promise<T>): Promise<T> => {
    const marker = {} as const
    type Marker = typeof marker
    let error: Error | Marker = marker
    let value: T | Marker = marker
    const handler: SoqErrorCallback = (e: SoqErrorDetails) => {
        error = e.error
    }
    soq.registerToErrorThrown(handler)
    try {
        value = await op()
    } catch (e) {
        error = e as Error
    }
    soq.unRegisterFromErrorThrown(handler)
    if (error !== marker) {
        throw error
    }
    return value as T
}

const createTransactionsApi = (ds: DocumentServicesObject, ps: PS): TransactionsObject => {
    let isRunningNormalTransaction = false
    let isRunningWaitForApprovalTransaction = false
    const run = <T>(action: () => Promise<T>): Promise<T> =>
        new Promise((resolve, reject) => {
            if (isRunningNormalTransaction) {
                return resolve(action())
            }
            isRunningNormalTransaction = true
            const setOperationParams = createAsyncPreDataManipulationFunction(ps, action, result => {
                isRunningNormalTransaction = false
                if (result.hasError) {
                    reject(result.error)
                } else if (result.hasResult) {
                    resolve(result.result as T)
                } else {
                    reject(new Error('Transaction did not resolve or reject. Is it still pending?'))
                }
            })
            ps.setOperationsQueue.runSetOperation(() => {}, [], setOperationParams)
        })

    const runWithoutRendering = async <T>(action: () => Promise<T>): Promise<T> => {
        return await ps.setOperationsQueue.noRenders(() => run(action))
    }

    return {
        isWaitForApprovalAvailable: () => isWaitForApprovalAvailable(ps),
        runAndWaitForApproval: async <T>(action: () => Promise<T>, context?: DropCommitContext): Promise<T> => {
            if (!isWaitForApprovalAvailable(ps) || isRunningWaitForApprovalTransaction) {
                return await action()
            }
            isRunningWaitForApprovalTransaction = true
            const {continuousSave} = (ps as any).extensionAPI
            const soq = ps.setOperationsQueue
            const content = async () => {
                await ds.waitForChangesAppliedAsync()
                await continuousSave.saveAndWaitForResult()
                const res = await throwAllErrors(soq, () => ps.setOperationsQueue.withCommitsAndSavesDisabled(action))
                ps.dal.commitTransaction('runAndWaitForApproval')
                await continuousSave.saveAndWaitForResult()
                return res
            }
            try {
                return await throwAllErrors(soq, content)
            } catch (e: any) {
                ps.dal.dropUncommittedTransaction(e.message, e, context)
                throw e
            } finally {
                isRunningWaitForApprovalTransaction = false
            }
        },
        run,
        async render() {
            ps.extensionAPI.viewer.syncViewerFromOpenTransaction()
            await new Promise(requestAnimationFrame)
        },
        async renderAndWaitForViewer() {
            ps.extensionAPI.viewer.syncViewerFromOpenTransaction()
            await ps.siteAPI.waitForViewer()
        },
        runWithoutRendering,
        isRunning: () => isRunningNormalTransaction,
        errors: {
            TransactionRejectionError
        }
    }
}

export {createTransactionsApi, createPromisesObject}
