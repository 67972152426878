import type {DocumentManager} from '@wix/document-manager-core'
import {forIn} from 'lodash'
import * as constants from '../../../../constants/constants'
import type {MasterPageMigrator} from '../dataMigrationRunner'

const {NEW_COLOR_PALETTE_LENGTH} = constants

const NEW_TO_OLD_COLORS_INDEXES_MAP = {
    MAIN_1: [36, 11],
    MAIN_2: [37, 15],
    SHADE_1: [38, 12],
    SHADE_2: [39, 13],
    SHADE_3: [40, 14],
    SECONDARY_1: [41, 18],
    SECONDARY_2: [42, 15],
    SECONDARY_3: [43, 13],
    SECONDARY_4: [44, 11],
    BACKGROUND: [11, 11],
    SECONDARY_BACKGROUND: [12, 12],
    SECONDARY_TEXT: [14, 14],
    PRIMARY_TEXT: [15, 15],
    LINK: [18, 18],
    TITLE: [45, 15],
    SUBTITLE: [46, 15],
    LINE: [47, 14],
    PRIMARY_BUTTON_FILL: [48, 18],
    PRIMARY_BUTTON_BORDER: [49, 18],
    PRIMARY_BUTTON_TEXT: [50, 11],
    PRIMARY_BUTTON_FILL_HOVER: [51, 11],
    PRIMARY_BUTTON_BORDER_HOVER: [52, 18],
    PRIMARY_BUTTON_TEXT_HOVER: [53, 18],
    PRIMARY_BUTTON_FILL_DISABLED: [54, 13],
    PRIMARY_BUTTON_BORDER_DISABLED: [55, 13],
    PRIMARY_BUTTON_TEXT_DISABLED: [56, 11],
    SECONDARY_BUTTON_FILL: [57, 11],
    SECONDARY_BUTTON_BORDER: [58, 18],
    SECONDARY_BUTTON_TEXT: [59, 18],
    SECONDARY_BUTTON_FILL_HOVER: [60, 18],
    SECONDARY_BUTTON_BORDER_HOVER: [61, 18],
    SECONDARY_BUTTON_TEXT_HOVER: [62, 11],
    SECONDARY_BUTTON_FILL_DISABLED: [63, 11],
    SECONDARY_BUTTON_BORDER_DISABLED: [64, 13],
    SECONDARY_BUTTON_TEXT_DISABLED: [65, 13]
}

const THEME_DATA_ID = 'THEME_DATA'

const expandColorsToRedesignedColorPalette = (colors: string[]) => {
    const newPalette = [...colors]

    forIn(NEW_TO_OLD_COLORS_INDEXES_MAP, indexes => {
        const [newIndex, oldIndex] = indexes
        newPalette[newIndex] = newPalette[newIndex] ? newPalette[newIndex] : colors[oldIndex]
    })

    // Some current themes have fewer colors than anticipated by the code. Filling the gap
    for (let i = 0; i < NEW_COLOR_PALETTE_LENGTH; i++) {
        newPalette[i] = newPalette[i] ?? colors[0]
    }

    return newPalette
}

const migrateMasterPage = (dm: DocumentManager) => {
    const pointer = dm.pointers.data.getThemeItem(THEME_DATA_ID, 'masterPage')
    const colorPointer = dm.pointers.getInnerPointer(pointer, 'color')
    const colorPalette = dm.dal.get(colorPointer)

    if (colorPalette.length === NEW_COLOR_PALETTE_LENGTH) {
        return
    }

    const expandColorPalette = expandColorsToRedesignedColorPalette(colorPalette)

    dm.dal.set(colorPointer, expandColorPalette)
}

const name = 'colorPaletteMigrator'
const version = 2

export const colorPaletteMigrator: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version
}
export {NEW_TO_OLD_COLORS_INDEXES_MAP, NEW_COLOR_PALETTE_LENGTH}
