import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {
    isComponentSuitableForNonRenderingState,
    isComponentSuitableForNonRenderingStateByPointer
} from '../utils/componentUtils'

const STRIP_CONTAINER_SLIDE_SHOW = 'wysiwyg.viewer.components.StripContainerSlideShow'

export const stripSlideShowSlideMetaData = {
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: true,
    canContain: ({dal}: CreateExtArgs, componentPointer: Pointer, potentialContainee: Pointer) =>
        isComponentSuitableForNonRenderingStateByPointer(dal, potentialContainee),
    fullWidthByStructure: true,
    fullWidth: true,
    allowedParentTypes: [STRIP_CONTAINER_SLIDE_SHOW],
    canContainByStructure: ({}: CreateExtArgs, componentPointer: Pointer, potentialContaineeStructure: CompStructure) =>
        isComponentSuitableForNonRenderingState(potentialContaineeStructure.componentType)
}
