'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const createUniqueIdGenerator = require('./createUniqueIdGenerator')
const changeDetectorProxy = require('./changeDetectorProxy')
const {decodeFixerVersioning} = require('@wix/document-manager-utils')
const config = require('./config.json')

const fixerVersionsNamespace = 'fixerVersions'
const fixerCategory = 'viewer_fixer'

const experimentStatusNew = 'new'
const experimentStatusOld = 'old'
const experimentStatusSkipped = 'skipped'

const logFixers = typeof localStorage !== 'undefined' && localStorage.getItem('dm-log-fixers') === 'true'

const hasFixerRunOnCurrentVersion = (lastFixersVersionsThatRanForPage, fixerName, fixerVersion) => {
    const lastVersionRun = _.get(lastFixersVersionsThatRanForPage, fixerName)
    return lastVersionRun === fixerVersion
}

const createChangesAccumulator = (pageJson, fixerName, pageId) => {
    const modifiedPaths = []
    const changeCallback = (path, oldValue, newValue) => {
        if (!_.isEqual(oldValue, newValue)) {
            modifiedPaths.push(path.join('/'))
            if (logFixers) {
                console.log(`%c"${fixerName}" CHANGED page "${pageId}"`, 'color: yellow')
                console.log(`\t'${path.join('/')}'`, {oldValue, newValue})
            }
        }
    }
    return {
        pageJsonProxy: changeDetectorProxy.create(pageJson, changeCallback),
        hasModifications: () => modifiedPaths.length > 0,
        getModifiedPaths: () => modifiedPaths
    }
}

const createExperimentInstance = (captureError, isExperimentOpen, fixerName, baseVersion, experimentalVersions) => {
    if (experimentalVersions === undefined) {
        experimentalVersions = []
    }

    const runningExperiments = {}
    let versionMissed = false
    let linearVersioningIsBroken = false

    _(experimentalVersions)
        .sortBy(['version'])
        .forEach(experimentalVersion => {
            if (!versionMissed && isExperimentOpen(experimentalVersion.experiment)) {
                runningExperiments[experimentalVersion.experiment] = experimentStatusNew
                baseVersion = experimentalVersion.version
            } else if (versionMissed && isExperimentOpen(experimentalVersion.experiment)) {
                runningExperiments[experimentalVersion.experiment] = experimentStatusSkipped
                linearVersioningIsBroken = true
            } else {
                runningExperiments[experimentalVersion.experiment] = experimentStatusOld
                versionMissed = true
            }
        })

    const logContext = `Fixer:${fixerName}  RunningExperiments: ${JSON.stringify(runningExperiments)}`
    if (linearVersioningIsBroken) {
        captureError({
            message: `The experiments are opened non-linearly. ${logContext}`,
            errorType: 'NonLinearVersionError'
        })
    }
    const isOpen = name => {
        if (!runningExperiments[name]) {
            captureError({
                message: `Experiment used but not registered. ${logContext}`,
                errorType: 'NonRegisteredExperimentError'
            })
        }
        return runningExperiments[name] === 'new'
    }

    return {
        getRunningExperiments: () => runningExperiments,
        getValue: name => (isOpen(name) ? 'new' : ''),
        isMultiValueExperimentOpen: () => false,
        isOpen,
        version: baseVersion
    }
}

const getMagicObjectForFixer = (magicObject, experimentInstance) => ({
    ...magicObject,
    experiment: experimentInstance,
    isExperimentOpen: experimentInstance.isOpen
})

const createProxyRunner =
    (fixerChangesOnReruns, pageIdsArray) => (plugin, pageJson, magicObject, pageId, fixerVersion) => {
        if (!fixerChangesOnReruns) {
            plugin.exec(pageJson, pageIdsArray, magicObject)
            return
        }
        const {name: fixerName} = plugin
        const changesAccumulator = createChangesAccumulator(pageJson, fixerName, pageId)
        plugin.exec(changesAccumulator.pageJsonProxy, pageIdsArray, magicObject)
        if (changesAccumulator.hasModifications()) {
            if (fixerChangesOnReruns[fixerName]) {
                fixerChangesOnReruns[fixerName][pageId] = changesAccumulator.getModifiedPaths()
            } else {
                fixerChangesOnReruns[fixerName] = {
                    ver: fixerVersion,
                    [pageId]: changesAccumulator.getModifiedPaths()
                }
            }
        }
    }

function createMagicObject(
    urlFormatModel,
    clientSpecMap,
    quickActionsMenuEnabled,
    experiments,
    isViewerMode,
    pageJson,
    pageId,
    editorConfig,
    captureError,
    renderHintsFlags,
    isResponsive
) {
    const magicObject = {}
    magicObject.isSlash = _.matchesProperty('format', coreUtils.siteConstants.URL_FORMATS.SLASH)(urlFormatModel)
    magicObject.pageIdToResolvedUriSEO = _.get(urlFormatModel, 'pageIdToResolvedUriSEO', {})
    magicObject.clientSpecMap = clientSpecMap
    magicObject.quickActionsMenuEnabled = quickActionsMenuEnabled
    const experimentsSet = new Set(experiments)
    magicObject.isExperimentOpen = spec => experimentsSet.has(spec)
    magicObject.isViewerMode = isViewerMode
    magicObject.dataFixerUtils = {
        uniqueIdGenerator: createUniqueIdGenerator(pageJson, pageId)
    }
    magicObject.captureError = captureError
    magicObject.editorConfig = editorConfig
    magicObject.renderHintsFlags = renderHintsFlags
    magicObject.isResponsive = isResponsive

    return magicObject
}

function ensurePageJsonData(pageJson) {
    const data = pageJson.data || {}
    data.document_data = data.document_data || {}
    data.theme_data = data.theme_data || {}
    data.component_properties = data.component_properties || {}
    data.mobile_hints = data.mobile_hints || {}
    pageJson.data = data
}

function createFixerVersionsForPage(fixerVersions, pageId) {
    const fixerVersionsForPage = {}
    fixerVersions[pageId] = {
        [fixerCategory]: fixerVersionsForPage
    }
    return fixerVersionsForPage
}

function pluginIterator(
    plugin,
    captureError,
    magicObject,
    pageJson,
    fixerChangesOnReruns,
    execWithProxy,
    pageId,
    pageIdsArray,
    lastFixersVersionsThatRanForPage,
    fixerVersionsForPage
) {
    const {name: fixerName, version: baseVersion, experimentalVersions, fixerRequiresReruns} = plugin

    const experimentInstance = createExperimentInstance(
        captureError,
        magicObject.isExperimentOpen,
        plugin.name,
        baseVersion,
        experimentalVersions
    )

    const fixerVersion = experimentInstance.version
    const fixerSpecificMagicObject = getMagicObjectForFixer(magicObject, experimentInstance)

    if (hasFixerRunOnCurrentVersion(lastFixersVersionsThatRanForPage, fixerName, fixerVersion)) {
        if (!fixerRequiresReruns) {
            return
        }
        execWithProxy(plugin, pageJson, fixerSpecificMagicObject, pageId, fixerVersion)
    } else {
        plugin.exec(pageJson, pageIdsArray, fixerSpecificMagicObject)
    }

    fixerVersionsForPage[fixerName] = fixerVersion
}

function getLastFixersVersionsThatRanForPage(pageJson) {
    const {structure, data} = pageJson
    const {fixerVersionsQuery} = structure
    let allFixersVersions = _.get(data, [fixerVersionsNamespace, fixerVersionsQuery, fixerCategory])
    if (_.isString(allFixersVersions)) {
        allFixersVersions = decodeFixerVersioning(allFixersVersions, config.fixers)
    }
    return allFixersVersions
}

function fixPageData(
    plugins,
    {
        pageJson,
        urlFormatModel,
        isViewerMode,
        clientSpecMap,
        quickActionsMenuEnabled,
        experiments,
        pageIdsArray,
        pageId,
        editorConfig,
        fixerVersions = {},
        fixerChangesOnReruns,
        renderHintsFlags,
        captureError = _.noop,
        isResponsive
    }
) {
    const magicObject = createMagicObject(
        urlFormatModel,
        clientSpecMap,
        quickActionsMenuEnabled,
        experiments,
        isViewerMode,
        pageJson,
        pageId,
        editorConfig,
        captureError,
        renderHintsFlags,
        isResponsive
    )
    ensurePageJsonData(pageJson)
    const fixerVersionsForPage = createFixerVersionsForPage(fixerVersions, pageId)
    const execWithProxy = createProxyRunner(fixerChangesOnReruns, pageIdsArray)

    const lastFixersVersionsThatRanForPage = getLastFixersVersionsThatRanForPage(pageJson)

    _.forEach(plugins, plugin => {
        pluginIterator(
            plugin,
            captureError,
            magicObject,
            pageJson,
            fixerChangesOnReruns,
            execWithProxy,
            pageId,
            pageIdsArray,
            lastFixersVersionsThatRanForPage,
            fixerVersionsForPage
        )
    })

    return pageJson
}

module.exports = {
    factory: pluginsManager => fixPageData.bind(null, pluginsManager.getPlugins())
}
