import type {
    DSConfig,
    FixerActions,
    FixerCategoryToFixerVersion,
    FixerNameToVersion
} from '@wix/document-services-types'
import {FixerCategory, type DataFixerVersioningApi} from '../dataFixerVersioning/dataFixerVersioning'
import _ from 'lodash'
import * as santaDataFixers from '@wix/santa-data-fixer'
import getPageJson from './getPageJson'
import applyFixAndUpdate from './applyFixAndUpdate'
import type {PageExtensionAPI} from '../page'
import {defaultPageMigrations} from './dataMigration/migrators'
import {
    type BaseMigrator,
    type MasterPageMigrator,
    migratorsFixerVersioningConfig,
    type PageMigrator
} from './dataMigration/dataMigrationRunner'
import type {DocumentManager} from '@wix/document-manager-core'

export type FixPageApi = Pick<
    DocumentManager,
    'dal' | 'extensionAPI' | 'pointers' | 'config' | 'experimentInstance' | 'logger'
>

const shouldRerunMigrator = (migrator: BaseMigrator) => migrator.fixerRequiresReruns

const addFixerVersion = (
    fixPageApi: FixPageApi,
    fixerVersions: FixerNameToVersion,
    migrator: BaseMigrator,
    versionOverride?: number
) => {
    const {name, version, disableVersioning} = migrator
    if (_.isFunction(disableVersioning) && disableVersioning?.(fixPageApi as DocumentManager)) {
        return
    }
    fixerVersions[name] = versionOverride ?? version
}

const isExperimentOpen = (value: string) => !!(value && value !== 'old' && value !== 'false')

const getDataFixersParams = (fixPageApi: FixPageApi, dsConfig: DSConfig) => {
    const {extensionAPI, dal, pointers} = fixPageApi
    const {page} = extensionAPI as PageExtensionAPI

    const pageIdsArray = _(dal.getIndexed(page.getPageIndexId('masterPage')).data)
        .filter(data => (data?.type === 'Page' || data?.type === 'AppPage') && data?.id !== 'SITE_STRUCTURE')
        .map('id')
        .value()
    const runningExperiments = dal.get(pointers.getPointer('runningExperiments', 'rendererModel')) ?? {}
    const editorConfig = {isADI: runningExperiments.dm_isAdiDocument || dsConfig.origin === 'onboarding'}
    const experiments = _(runningExperiments)
        .pickBy(val => isExperimentOpen(val))
        .keys()
        .value()

    const quickActionsMenuEnabled =
        dal.get(
            pointers.getPointer('siteMetaData', 'rendererModel', {
                innerPath: ['quickActions', 'configuration', 'quickActionsMenuEnabled']
            })
        ) ?? false
    const previewMode = dal.get(pointers.getPointer('previewMode', 'rendererModel'))

    return {
        clientSpecMap: dal.get(pointers.rendererModel.getClientSpecMap()),
        isResponsive: dal.get(pointers.rendererModel.isResponsive()),
        urlFormatModel: dal.get(pointers.general.getUrlFormat()),
        quickActionsMenuEnabled,
        isViewerMode: !previewMode,
        experiments,
        pageIdsArray,
        editorConfig
    }
}

export const runPageMigrators = (fixPageApi: FixPageApi, pageId: string) => {
    const {extensionAPI, experimentInstance} = fixPageApi
    const fixerVersions: FixerNameToVersion = {}
    const {dataFixerVersioning} = extensionAPI as DataFixerVersioningApi
    const fixerChangesOnReruns: FixerActions = {}

    defaultPageMigrations.forEach(migrator => {
        const fixerExperiment = dataFixerVersioning.decorateExperimentWithVersions(
            experimentInstance,
            {fixerName: migrator.name},
            migrator.version,
            migrator.experimentalVersions
        )

        const migratePage = () => {
            if ('migratePage' in migrator) {
                ;(migrator as PageMigrator).migratePage(fixPageApi as DocumentManager, pageId, fixerExperiment)
            }
            if ('migrateMasterPage' in migrator && pageId === 'masterPage') {
                ;(migrator as MasterPageMigrator).migrateMasterPage(fixPageApi as DocumentManager, fixerExperiment)
            }
        }

        if (
            dataFixerVersioning.hasFixerRunOnCurrentVersion(
                pageId,
                FixerCategory.MIGRATOR,
                migrator.name,
                fixerExperiment.version,
                migratorsFixerVersioningConfig
            )
        ) {
            if (!shouldRerunMigrator(migrator)) {
                return
            }

            const changes = dataFixerVersioning.executeFixerAndSetModifications(
                () => migratePage(),
                pageId,
                migrator.name,
                fixerExperiment.version
            )

            _.merge(fixerChangesOnReruns, changes)
        } else {
            addFixerVersion(fixPageApi, fixerVersions, migrator, fixerExperiment.version)
            migratePage()
        }
    })

    dataFixerVersioning.updatePageVersionData(
        pageId,
        {[FixerCategory.MIGRATOR]: fixerVersions},
        {[FixerCategory.MIGRATOR]: migratorsFixerVersioningConfig}
    )

    dataFixerVersioning.reportFixerActions(FixerCategory.MIGRATOR, fixerChangesOnReruns)
}

export const runSantaDataFixers = async (
    fixPageApi: FixPageApi,
    pageId: string,
    dsConfig: DSConfig,
    customPlugins?: object[]
) => {
    const {dal, extensionAPI, logger} = fixPageApi
    const {dataFixerVersioning} = extensionAPI as DataFixerVersioningApi

    const fixerVersions: Record<string, FixerCategoryToFixerVersion> = {}
    const fixerChangesOnReruns = {}

    const fixerParams = getDataFixersParams(fixPageApi, dsConfig)

    const fixerVersioningConfig = santaDataFixers.getFixerVersioningConfig()

    const fixerFunc =
        customPlugins === undefined
            ? santaDataFixers.fix
            : santaDataFixers.fixFactory({getPlugins: () => customPlugins})
    const fixPage = async (pageJson: any) =>
        await Promise.resolve(
            fixerFunc({
                ...fixerParams,
                pageId,
                pageJson,
                fixerVersions,
                fixerChangesOnReruns,
                captureError: logger.captureError
            })
        )

    await applyFixAndUpdate(dal, pageId, getPageJson(pageId, fixPageApi), fixPage)

    Object.keys(fixerVersions).forEach(() => {
        dataFixerVersioning.updatePageVersionData(pageId, fixerVersions[pageId], {
            [FixerCategory.VIEWER]: fixerVersioningConfig
        })
    })

    dataFixerVersioning.reportFixerActions(FixerCategory.VIEWER, fixerChangesOnReruns)
}
