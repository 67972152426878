import type {DomAndDmContext} from './types'

export const getWindow = (): Window => {
    if (window.document.location.href.includes('editor/web/renderer/render')) {
        return window
    }
    return (window.document.getElementById('preview') as HTMLIFrameElement).contentWindow as Window
}

export const getDomAndDmContent = (): DomAndDmContext => {
    const _window = getWindow()
    return {
        window: _window,
        document: _window.document,
        documentServices: _window.documentServices
    }
}
