import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ComponentBeforeAddDataEvent} from '../hooks'
import type {CompStructure} from '@wix/document-services-types'

const childTypeByContainerType = {
    'wysiwyg.viewer.components.BoxSlideShow': 'wysiwyg.viewer.components.BoxSlideShowSlide',
    'wysiwyg.viewer.components.StripContainerSlideShow': 'wysiwyg.viewer.components.StripContainerSlideShowSlide',
    'wysiwyg.viewer.components.StateBox': [
        'wysiwyg.viewer.components.StateBoxState',
        'wysiwyg.viewer.components.StateBoxFormState',
        'wysiwyg.viewer.components.Repeater'
    ],
    'wysiwyg.viewer.components.StateStrip': 'wysiwyg.viewer.components.StateStripState'
}

const childTypes = _.flatten(_.values(childTypeByContainerType))

const isSlideComponent = (componentType: string) => _.includes(childTypes, componentType)

const getSlidesFromChildrenByStructure = (children: CompStructure[]) =>
    _.filter(children, ({componentType}) => isSlideComponent(componentType))

const getMatchingChildSlideTypes = (compType: string) => {
    const childType = childTypeByContainerType[compType as keyof typeof childTypeByContainerType]
    if (!childType) {
        throw new Error('invalid comp type, not a slideshow component')
    }

    return _.flatten([childType])
}

export const verifySlideShowStructureOnAdd = (
    createExtArgs: CreateExtArgs,
    {componentDefinition}: ComponentBeforeAddDataEvent
) => {
    const slides = getSlidesFromChildrenByStructure(componentDefinition.components as CompStructure[])
    const isAtLeastOneSlide = slides.length > 0
    const isSlideWithNonMatchingType = _.find(slides, function (slide) {
        return !_.includes(getMatchingChildSlideTypes(componentDefinition.componentType), slide.componentType)
    })

    if (!isAtLeastOneSlide || isSlideWithNonMatchingType) {
        throw new Error('Invalid slideShow structure definition')
    }
}
