import * as dmCore from '@wix/document-manager-core'
import type {CompRef, Pointer, PS, DeserializationMappers} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import refComponentUtils from '../../refComponent/refComponentUtils'
import connectionsHooks from './connectionsHooks'
import nickname from './nickname'
import relationsUtils from '../../variants/relationsUtils'
import {ReportableError} from '@wix/document-manager-utils'
import type {GetQueryIdEvent} from '@wix/document-manager-extensions/src/hooks'
import type {
    BeforeSetNonScopedValueEvent,
    BeforeSetScopedValueEvent
} from '@wix/document-manager-extensions/src/extensions/variants/hooks'
import livePreview from '../../platform/livePreview/livePreview'
import experiment from 'experiment-amd'
import type {RefComponentAfterAddDataEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

const {isRefPointer} = santaCoreUtils.displayedOnlyStructureUtil
const {DATA_TYPES, VIEWER_PAGE_DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} = constants
const CONNECTIONS_ITEM_TYPE = 'connections'

const REF_OVERRIDES_TO_REMOVE = {}
const IGNORED_REFRENCES_FOR_TYPES = {
    StyledText: ['linkList'],
    RefArray: ['values']
}

/**
 * @param ps
 * @param baseId
 * @param itemType
 * @param {*} serializedItem
 * @return {*}
 */
function generateUniqueIdsForInnerRefs(ps: PS, baseId: string, itemType: string, serializedItem) {
    if (!_.isObject(serializedItem)) {
        return serializedItem
    }

    // @ts-expect-error
    const dataPtr = ps.pointers.data.getItem(itemType, serializedItem.id)

    if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(dataPtr)) {
        // @ts-expect-error
        serializedItem.id = santaCoreUtils.guidUtils.getUniqueId(baseId, '-', {bucket: 'innerRefs'})
    }

    // @ts-expect-error
    const schemaName = serializedItem.type

    let serializedItemToIterate = serializedItem
    if (IGNORED_REFRENCES_FOR_TYPES[schemaName]) {
        serializedItemToIterate = _.omit(serializedItemToIterate, IGNORED_REFRENCES_FOR_TYPES[schemaName])
    }

    const refInfos = ps.extensionAPI.schemaAPI.extractOwnedReferenceFieldsInfo(itemType, schemaName, true)

    _.forOwn(serializedItemToIterate, function (value, key) {
        if (refInfos[key]?.isList) {
            serializedItem[key] = _.map(value, generateUniqueIdsForInnerRefs.bind(null, ps, baseId, itemType))
        } else if (_.isPlainObject(value) && refInfos[key]) {
            serializedItem[key] = generateUniqueIdsForInnerRefs(ps, baseId, itemType, value)
        } else if (key !== 'id') {
            serializedItem[key] = value
        }
    })

    return serializedItem
}

function filterConnectionItems(ps: PS, connectionList) {
    connectionList.items = _.reject(connectionList.items, ({controllerId, isPrimary}) => {
        if (!isPrimary) {
            return false
        }

        const controllerPtr = ps.pointers.data.getItem(DATA_TYPES.connections, controllerId)
        return santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(controllerPtr)
    })

    return connectionList
}

function beforeSetNonScopedRefArray(
    ps: PS,
    {compRef, itemType, pageId, newDefaultValueId, refArrayId}: BeforeSetNonScopedValueEvent,
    curResultValue: any
): string[] {
    if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compRef)) {
        return curResultValue
    }
    const overrideRefArrayPointer = ps.pointers.data.getItem(itemType, refArrayId, pageId)
    const arrayOfOverridesPointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(overrideRefArrayPointer)
    const overrideRefArray = ps.dal.get(arrayOfOverridesPointer)
    const overrideItemValues = overrideRefArray?.values ?? []
    const currentDefaultValueId = relationsUtils.nonScopedValuePointer(ps, itemType, overrideRefArray, pageId)?.id
    // this case should never happen in this hook, as it's handled before the hook is run.
    // however, if we do execute the hook from another location, it is handled here for completeness
    if (currentDefaultValueId) {
        return [`#${newDefaultValueId}`, ..._.without(overrideItemValues, `#${currentDefaultValueId}`)]
    }
    return [`#${newDefaultValueId}`, ...overrideItemValues]
}

function beforeSetRefArray(
    ps: PS,
    {compRef, itemType, pageId, relId, refArrayId}: BeforeSetScopedValueEvent,
    curResultValue
) {
    if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compRef)) {
        return curResultValue
    }

    const overrideRefArrayPointer = ps.pointers.data.getItem(itemType, refArrayId, pageId)
    const arrayOfOverridesPointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(overrideRefArrayPointer)
    const overrideItems = ps.dal.get(arrayOfOverridesPointer)

    const newValues = overrideItems?.values ? [...overrideItems.values, `#${relId}`] : [`#${relId}`]
    return newValues
}

function beforeSetRefArrayOnRelationRemoval(
    ps: PS,
    curResultValue,
    overrideRefArrayPointer: Pointer,
    relId: string
): string[] {
    if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(overrideRefArrayPointer)) {
        return curResultValue
    }

    const arrayOfOverridesPointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(overrideRefArrayPointer)
    const overrideItems = ps.dal.get(arrayOfOverridesPointer)

    return _.without(overrideItems?.values, `#${relId}`)
}

function beforeSetOverride(ps: PS, {}, overridePointer) {
    if (!overridePointer || !santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(overridePointer)) {
        return overridePointer
    }

    const pointerWithoutFallbacks = ps.pointers.referredStructure.getPointerWithoutFallbacks(overridePointer)
    const isRemotePointer = !ps.dal.get(pointerWithoutFallbacks)

    return isRemotePointer ? null : overridePointer
}

function beforeDeserialize(ps: PS, serializedItem, itemType: string, dataItemId: string) {
    if (dataItemId) {
        const dataPointer = ps.pointers.data.getItem(itemType, dataItemId)

        if (santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(dataPointer)) {
            const originalDataItem = dataModel.getDataByPointer(ps, itemType, dataPointer) || {}
            const baseId = refComponentUtils.extractBaseComponentId(dataPointer)
            serializedItem.type = serializedItem.type || originalDataItem.type

            if (serializedItem.type === originalDataItem.type) {
                serializedItem = {...originalDataItem, ...serializedItem}
            }

            const dataItemWithFixedIds = generateUniqueIdsForInnerRefs(ps, baseId, itemType, serializedItem)

            if (itemType === DATA_TYPES.connections) {
                return filterConnectionItems(ps, dataItemWithFixedIds)
            }

            return dataItemWithFixedIds
        }
    }

    return serializedItem
}

function copyOverridenData(
    ps: PS,
    compToAddPointer: Pointer,
    serializedOverriddenData,
    originalNicknameContext,
    mappers: DeserializationMappers
) {
    const pageId = ps.pointers.full.components.getPageOfComponent(compToAddPointer).id
    _.forEach(serializedOverriddenData, ({compId, itemType, dataItem, isMobile}) => {
        if (experiment.isOpen('dm_moveRefCompAddHook')) {
            ps.extensionAPI.refOverrides.createOverrideDataItem(
                compToAddPointer,
                compId,
                itemType,
                pageId,
                dataItem,
                isMobile,
                originalNicknameContext,
                mappers
            )
        } else {
            refComponentUtils.createOverrideDataItem(
                ps,
                itemType,
                compToAddPointer,
                compId,
                pageId,
                dataItem,
                isMobile,
                originalNicknameContext,
                mappers
            )
        }
    })
}

function handleDataOverridesAfterAdd(
    ps: PS,
    compToAddPointer: Pointer,
    clonedSerializedComp,
    mappers: DeserializationMappers
) {
    const {overriddenData, originalNicknameContext} = clonedSerializedComp.custom || {}
    if (overriddenData) {
        copyOverridenData(ps, compToAddPointer, overriddenData, originalNicknameContext, mappers)
    }
}

const filterConnectionOverrides = overriddenData => _.filter(overriddenData, {itemType: CONNECTIONS_ITEM_TYPE})

function updateConnectionOverridesControllerIds(ps: PS, overriddenData, containerRef, compId: string, oldToNewIdMap) {
    const connectionOverrideItems = filterConnectionOverrides(overriddenData)
    _.forEach(connectionOverrideItems, item => {
        const updatedConnectionItems = connectionsHooks.getUpdatedSerializedConnections(
            ps,
            containerRef,
            compId,
            item.dataItem.items,
            false,
            oldToNewIdMap
        )
        if (updatedConnectionItems) {
            item.dataItem.items = updatedConnectionItems
        }
    })
}

const getSlottedCompIdsFromOverridenData = (overriddenData: any[]): string[] => {
    const compIds = []
    for (const override of overriddenData ?? []) {
        if (override?.itemType === constants.DATA_TYPES.slots) {
            compIds.push(...Object.values(override?.dataItem?.slots ?? {}))
        }
    }

    return compIds
}

function beforeAddComponent(
    ps: PS,
    componentRef,
    containerRef,
    compDefinition,
    optionalCustomId: string,
    isPage: boolean,
    mappers: DeserializationMappers
) {
    const {overriddenData} = compDefinition.custom || {}

    if (overriddenData && experiment.isOpen('dm_addComponentWithSlotOverrides')) {
        // filter out children that are not slotted components, to prevent display only components from being deserialized
        const slottedCompIds = new Set(getSlottedCompIdsFromOverridenData(overriddenData))
        compDefinition.components = compDefinition.components
            ? compDefinition.components.filter(comp => slottedCompIds.has(comp.id))
            : []
    } else {
        compDefinition.components = []
    }

    if (overriddenData) {
        updateConnectionOverridesControllerIds(
            ps,
            overriddenData,
            containerRef,
            compDefinition.id,
            mappers?.oldToNewIdMap
        )
    }
}

function afterAddComponent(
    ps: PS,
    compToAddPointer: Pointer,
    clonedSerializedComp,
    customId,
    mappers: DeserializationMappers
) {
    const {scopes} = ps.extensionAPI
    if (experiment.isOpen('dm_moveAfterAddComponentHook')) {
        return
    }
    handleDataOverridesAfterAdd(ps, compToAddPointer, clonedSerializedComp, mappers)

    ps.setOperationsQueue.registerToNextSiteChanged(() => {
        const pagePointer = ps.pointers.components.getPageOfComponent(compToAddPointer)
        const templatePointer = dmCore.pointerUtils.getRepeatedItemPointerIfNeeded(compToAddPointer)
        const {getChildren} = ps.pointers.components
        const getChildrenFunc = scopes?.wrapMethodWithDisableScopes(getChildren) ?? getChildren
        const referredComponents = getChildrenFunc(templatePointer)
        nickname.generateNicknamesForComponents(ps, referredComponents, pagePointer)

        livePreview.debouncedRefreshComponent(ps, {
            componentsToReset: referredComponents,
            source: 'AFTER_ADD_REF_COMPONENT',
            shouldFetchData: false
        })
    })
}
const afterAddComponentFromExt = (ps: PS, data: RefComponentAfterAddDataEvent) => {
    const {compToAddPointer} = data
    const {scopes} = ps.extensionAPI
    ps.setOperationsQueue.registerToNextSiteChanged(() => {
        const templatePointer = dmCore.pointerUtils.getRepeatedItemPointerIfNeeded(compToAddPointer)
        const {getChildren} = ps.pointers.components
        const getChildrenFunc = scopes?.wrapMethodWithDisableScopes(getChildren) ?? getChildren
        const referredComponents = getChildrenFunc(templatePointer)
        livePreview.debouncedRefreshComponent(ps, {
            componentsToReset: referredComponents,
            source: 'AFTER_ADD_REF_COMPONENT',
            shouldFetchData: false
        })
    })
}

const removeInternalRefOverrides = (ps: PS, compPointer: Pointer) => {
    if (isRefPointer(compPointer)) {
        ps.logger.captureError(
            new ReportableError({
                errorType: 'AttemptToRemoveRemoteStructure',
                message: `attempt to remove ${compPointer}`
            })
        )
        return
    }
    const overrides = ps.pointers.referredStructure.getOverridesTargetingComp(compPointer)
    dataModel.removeItems(ps, overrides)
}

const removeAllOverrides = (ps: PS, compPointer: Pointer) => {
    const overrides = REF_OVERRIDES_TO_REMOVE[compPointer.id] || []
    dataModel.removeItems(ps, overrides)
    delete REF_OVERRIDES_TO_REMOVE[compPointer.id]
}

const saveOverrides = (ps: PS, compPointer: CompRef) => {
    const overrides = refComponentUtils.getAllOverridesToBeRemoved(ps, compPointer, false, true)
    REF_OVERRIDES_TO_REMOVE[compPointer.id] = overrides
}

const QUERY_TO_NS = _.invert(COMP_DATA_QUERY_KEYS_WITH_STYLE)

const getItemQueryId = ({compPointer, propName, compId}: GetQueryIdEvent, dataId: string) => {
    if (experiment.isOpen('specs.thunderbolt.doNotInflateSharedParts') && refComponentUtils.isSharedPartsId(compId)) {
        return dataId
    }

    if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compPointer)) {
        return dataId
    }

    const namespaceItemType = QUERY_TO_NS[propName]
    //ref component inflation happens in viewer, and only supports viewer types
    if (!VIEWER_PAGE_DATA_TYPES[namespaceItemType]) {
        return dataId
    }

    return refComponentUtils.getItemQueryId(compPointer, namespaceItemType)
}

export default {
    getItemQueryId,
    saveOverrides,
    beforeSetRefArray,
    beforeSetNonScopedRefArray,
    beforeSetOverride,
    beforeSetRefArrayOnRelationRemoval,
    beforeDeserialize,
    beforeAddComponent,
    afterAddComponentFromExt,
    afterAddComponent,
    setCustomSerializeData: refComponentUtils.setCustomSerializeData,
    removeAllOverrides,
    removeInternalRefOverrides
}
