import _ from 'lodash'
import constants from '../constants/constants'
const {COMP_DATA_QUERY_KEYS, SERIALIZED_DATA_KEYS, SERIALIZED_SCOPED_DATA_KEYS, VARIANTS} = constants

const removeBreakpointsIfCompHasVariants = comp => {
    const hasBreakpoints = _.some(comp?.variants, x => x?.type === VARIANTS.TYPES.BREAKPOINTS)
    if (hasBreakpoints) {
        delete comp?.breakpoints
    }
}

const removeMetadataFromSerializedItem = item => {
    if (!_.isPlainObject(item)) {
        return
    }
    delete item.metaData
    _.forOwn(item, val => {
        if (_.isArray(val)) {
            _.forEach(val, removeMetadataFromSerializedItem)
        } else {
            removeMetadataFromSerializedItem(val)
        }
    })
}

const removeMetadata = componentDefinition => {
    delete componentDefinition.metaData
    _.forEach(SERIALIZED_DATA_KEYS, key => {
        if (componentDefinition[key]) {
            removeMetadataFromSerializedItem(componentDefinition[key])
        }
    })
    _.forEach(SERIALIZED_SCOPED_DATA_KEYS, key => {
        if (componentDefinition[key]) {
            removeMetadataFromSerializedItem(componentDefinition[key])
        }
    })
}

const sanitizeBehaviors = (serializedComp, extensionAPI, experimentInstance, logger) => {
    if (!serializedComp?.behaviors) {
        return
    }
    if (_.isString(serializedComp.behaviors)) {
        logger.interactionEnded('fixStringBehaviors', {
            extras: {
                behaviorsString: serializedComp.behaviors
            }
        })
        // support for old add panel data
        serializedComp.behaviors = extensionAPI.dataModel.createBehaviorsItem(serializedComp.behaviors)
    }
    if (!serializedComp.behaviors.items) {
        delete serializedComp.behaviors
        return
    }
    // For now this map is duplicated across those 3 files:
    // - document-services-implementation/src/component/sanitizeSerializedComponent.ts
    // - document-manager-extensions/src/extensions/components/serializedComponentSanitation.ts
    // - document-services-implementation/src/actionsAndBehaviors/actionsAndBehaviors.ts
    // and all must be maintained. When the list grows we can have a single source of truth for them
    const actionsToCleanMap = {
        screenIn: true,
        bgScrub: experimentInstance.isOpen('dm_bgScrubToMotionFixer')
    }
    const actionsToClean = Object.keys(actionsToCleanMap).filter(action => actionsToCleanMap[action])
    const itemsCleaned = JSON.parse(serializedComp.behaviors.items).filter(
        (item: any) => !actionsToClean.includes(item.action)
    )
    if (itemsCleaned.length === 0) {
        delete serializedComp.behaviors
        return
    }
    serializedComp.behaviors.items = JSON.stringify(itemsCleaned)
}

function sanitizeSerializedComponent(componentDefinition, extensionAPI, experimentInstance, logger) {
    delete componentDefinition.activeVariants
    delete componentDefinition.referredVariants
    delete componentDefinition.siteWidth

    if (extensionAPI.effects.usesNewAnimations()) {
        sanitizeBehaviors(componentDefinition, extensionAPI, experimentInstance, logger)
    }

    _.forEach(COMP_DATA_QUERY_KEYS, compDataQueryKey => {
        delete componentDefinition[compDataQueryKey]
    })

    removeMetadata(componentDefinition)
    if (componentDefinition.mobileStructure) {
        removeMetadata(componentDefinition.mobileStructure)
    }
    removeBreakpointsIfCompHasVariants(componentDefinition)
}

export default sanitizeSerializedComponent
