import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import metaDataUtils from '../metaDataUtils'
import documentModeInfo from '../../documentMode/documentModeInfo'

const UNSUPPORTED_WIDGET_ROOT_COMPONENT_TYPES = [
    'wysiwyg.viewer.components.HoverBox',
    'wysiwyg.viewer.components.BoxSlideShow',
    'wysiwyg.viewer.components.StripContainerSlideShow',
    'wysiwyg.viewer.components.Repeater',
    'wysiwyg.viewer.components.StripContainer',
    'wysiwyg.viewer.components.StripColumnsContainer'
]

const EXCLUDED_COMPONENT_TYPES = [
    'wysiwyg.viewer.components.StateBoxState',
    'wysiwyg.viewer.components.StateBox',
    'wysiwyg.viewer.components.BoxSlideShow',
    'wysiwyg.viewer.components.BoxSlideShowSlide'
]

function getUnsupportedComponentInAppWidget() {
    const excludedComponentTypes = [...EXCLUDED_COMPONENT_TYPES, 'wysiwyg.viewer.components.tpapps.TPAWidget']
    return _.without(metaDataUtils.COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE, ...excludedComponentTypes)
}

function allChildrenAllowedInAppWidget(ps: PS, potentialChild: CompRef) {
    const recusiveChildrenPointers = ps.pointers.components.getChildrenRecursively(potentialChild)
    return _.every(recusiveChildrenPointers.concat(potentialChild), function (componentPointer) {
        const compType = metaDataUtils.getComponentType(ps, componentPointer)

        return !_.includes(getUnsupportedComponentInAppWidget(), compType)
    })
}

function allChildrenStructureAllowedInAppWidget(potentialChildStructure) {
    if (!potentialChildStructure.components) {
        return metaDataUtils.isComponentSuitableForNonRenderingState(potentialChildStructure.componentType)
    }

    const childrenTypes = metaDataUtils.getChildrenTypesDeep([potentialChildStructure])
    return _.every(childrenTypes, metaDataUtils.isComponentSuitableForNonRenderingState)
}

export default {
    groupable: false,
    layoutLimits: metaDataUtils.getControllerLayoutLimits,
    minimalChildrenNumber: 1,
    maximumChildrenNumber: 1,
    forceMaintainIDsOnSerialize: true,
    canContainByStructure(ps: PS, compRef: Pointer, potentialChildStructure, targetCompRef: Pointer) {
        return (
            !ps.pointers.components.isSameComponent(compRef, targetCompRef) ||
            !_.includes(UNSUPPORTED_WIDGET_ROOT_COMPONENT_TYPES, potentialChildStructure.componentType) ||
            !allChildrenStructureAllowedInAppWidget(ps)
        )
    },
    canContain(ps: PS, componentPointer: Pointer, potentialChild: CompRef) {
        return allChildrenAllowedInAppWidget(ps, potentialChild)
    },
    canBeFixedPosition(ps: PS, compRef: Pointer) {
        return metaDataUtils.getFromStageData(ps, 'pinnable', compRef) !== false
    },
    containable(ps: PS, compRef: Pointer, potentialContainerPointer: Pointer) {
        if (metaDataUtils.isTogglingShowOnAllPages(ps, compRef, potentialContainerPointer)) {
            return (
                !documentModeInfo.isMobileView(ps) &&
                metaDataUtils.getFromStageData(ps, 'toggleShowOnAllPagesEnabled', compRef) !== false
            )
        }
        return true
    },
    duplicatable(ps: PS, compRef: Pointer) {
        return metaDataUtils.getFromStageData(ps, 'duplicatable', compRef) !== false
    },
    removable(ps: PS, compRef: Pointer) {
        return metaDataUtils.getFromStageData(ps, 'removable', compRef) !== false
    },
    crossSiteDuplicatable: false,
    mobileConversionConfig: {
        category: 'appWidget',
        nestOverlayingSiblings: false,
        isTightContainer: true,
        marginX: 0,
        filterChildrenWhenHidden: true
    }
}
