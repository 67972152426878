import type {PageExtensionAPI} from '../page'
import {PAGE_DATA_TYPES} from '../../constants/constants'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {immutableStructureByPage} from '../../utils/siteDataImmutableFromSnapshot'
import {getTranslationInfoFromKey} from '../../utils/translationUtils'
import * as Constants from '../../constants/constants'
import type {FixPageApi} from './fixPage'

const sanitizedMetaData = (value: any) => {
    if (value?.metaData) {
        value.metaData = _.omit(value.metaData, ['pageId'])
    }
}

const cloneWithSanitizedMetaData = (value: any) => {
    const clonedValue = deepClone(value)
    sanitizedMetaData(clonedValue)
    return clonedValue
}

const addIfValue = (object: Record<string, any>, key: string, value: any) => {
    if (!_.isEmpty(value)) {
        object[key] = value
    }
}

const getTranslationsPath = (languageCode: string, dataItemId: string) => [
    languageCode,
    Constants.DATA_TYPES.data,
    Constants.PAGE_DATA_TYPES.data,
    dataItemId
]

export default function getPageJson(pageId: string, fixPageApi: FixPageApi) {
    const {dal, extensionAPI} = fixPageApi
    const {page} = extensionAPI as PageExtensionAPI
    const indexedValues = dal.getIndexed(page.getPageIndexId(pageId))

    const data: Record<string, any> = {}
    for (const [pageDataTypeKey, pageDataTypeValue] of Object.entries(PAGE_DATA_TYPES)) {
        const typeValues = indexedValues[pageDataTypeKey]
        if (!typeValues) {
            _.setWith(data, [pageDataTypeValue], {}, Object)
        } else {
            Object.values(typeValues).forEach((value: any) => {
                const valueWithoutPageId = cloneWithSanitizedMetaData(value)
                _.setWith(data, [pageDataTypeValue, value.id], valueWithoutPageId, Object)
            })
        }
    }

    const translations = {}
    Object.entries(indexedValues.translations ?? {}).forEach(([key, value]: any[]) => {
        const valueWithoutPageId = cloneWithSanitizedMetaData(value)
        const [languageCode, dataItemId] = getTranslationInfoFromKey(key)
        const translationPath = getTranslationsPath(languageCode, dataItemId)
        _.setWith(translations, translationPath, valueWithoutPageId)
    })

    const pageJson = {}

    addIfValue(pageJson, 'structure', immutableStructureByPage(indexedValues.DESKTOP, indexedValues.MOBILE, pageId))
    addIfValue(pageJson, 'data', data)
    addIfValue(pageJson, 'translations', translations)

    return pageJson
}
