import _ from 'lodash'

interface GenerateNameOptions {
    prefix?: string
    seperator?: string
    dontShowIndexForFirstName?: boolean
}

function isNamePrefixWithNumber(name: string, seperator: string, prefix: string) {
    const seperatorRegex = seperator ? `[${seperator}]?` : ''
    const r = new RegExp(`^${prefix}${seperatorRegex}[0-9]*$`)
    return r.test(name)
}

function generateName(
    existingNames: string[] = [],
    {prefix, dontShowIndexForFirstName, seperator = ''}: GenerateNameOptions = {}
) {
    const getNextIndex = () => {
        const highestIndexForName = _(existingNames)
            .filter(name => isNamePrefixWithNumber(name, seperator, prefix))
            .map(name => _.replace(name, prefix, ''))
            .map(Number)
            .sortBy()
            .last()

        if (dontShowIndexForFirstName && highestIndexForName === undefined) {
            return undefined
        }
        return (highestIndexForName || 0) + 1
    }

    const nextIndex = getNextIndex()

    const suffix = nextIndex === undefined ? '' : `${seperator}${nextIndex}`

    return `${prefix}${suffix}`
}

export default {
    generateName
}
