import type {Pointer, CompStructure} from '@wix/document-services-types'
import _ from 'lodash'
import {setControllerStateFromSerializedStructure, addPlatformAppToPageIfFirstController} from './utils/platformAppComp'
import type {CreateExtArgs, ExtensionAPI} from '@wix/document-manager-core'
import type {ConnectionsAPI} from '../../connections'
import type {StructureExtensionAPI} from '../../structure'

import {getControllerAppDefinitionId} from '../../appControllerData/appControllerUtils'
import type {ComponentAfterAddDataEvent} from '../hooks'
function getRelatedConnections(controllerDefinition: CompStructure, oldToNewIdMap: Record<string, string>) {
    return (
        _(controllerDefinition)
            .chain()
            .get(['custom', 'relatedConnections'])
            // @ts-expect-error
            .transform((acc: Record<string, any>, compConnections: any, compId: string) => {
                acc[_.get(oldToNewIdMap, compId, compId)] = compConnections
            }, {})
            .value()
    )
}

function reconnectComponentsToController(
    extensionAPI: ExtensionAPI,
    componentsToReconnect: Pointer[],
    relatedConnections: Record<string, any>,
    controllerRef: Pointer
) {
    const {connections} = extensionAPI as ConnectionsAPI
    _.forEach(componentsToReconnect, function (compRef: Pointer) {
        const compConnections = _.get(relatedConnections, compRef.id, [])
        _.forEach(compConnections, function (connectionItem) {
            connections.connect(
                compRef,
                controllerRef,
                connectionItem.role,
                connectionItem.config,
                connectionItem.isPrimary
            )
        })
    })
}

function reconnectComponents(
    {extensionAPI, pointers}: CreateExtArgs,
    controllerRef: Pointer,
    controllerDefinition: CompStructure,
    optionalCustomId: string | undefined,
    oldToNewIdMap: Record<string, string>
) {
    const relatedConnections = getRelatedConnections(controllerDefinition, oldToNewIdMap)
    const connectedComponentIds = _.keys(relatedConnections)
    const {components} = extensionAPI as StructureExtensionAPI
    const allComps = components.getAllDesktopComponents()
    const componentsToReconnect = _.reduce(
        allComps,
        (res: Pointer[], compStructure: CompStructure, componentId: string) => {
            if (_.includes(connectedComponentIds, componentId)) {
                res.push(pointers.getPointer(componentId, 'DESKTOP'))
            }
            return res
        },
        []
    )
    reconnectComponentsToController(extensionAPI, componentsToReconnect, relatedConnections, controllerRef)
}

export const handleControllerAddition = (createExtArgs: CreateExtArgs, data: ComponentAfterAddDataEvent) => {
    const {compToAddPointer, componentDefinition, optionalCustomId, mappers} = data
    reconnectComponents(createExtArgs, compToAddPointer, componentDefinition, optionalCustomId, mappers.oldToNewIdMap)
    setControllerStateFromSerializedStructure(createExtArgs, compToAddPointer, componentDefinition)
    addPlatformAppToPageIfFirstController(
        createExtArgs,
        compToAddPointer,
        getControllerAppDefinitionId(componentDefinition.data)!
    )
}
