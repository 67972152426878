// eslint-disable-next-line import/no-unresolved,wix-editor/no-internal-import
import {editorSkinsData as generatedEditorSkinsData} from '@wix/santa-ds-libs/basic'
import _ from 'lodash'
// eslint-disable-next-line wix-editor/no-internal-import
import {getComponentsEditorParams as getEditorElementsComponentsEditorParams} from '@wix/editor-elements-design-panel-params/componentsEditorParams'

let componentsEditorParams: Record<string, any>

export const getComponentsEditorParams = () => {
    if (!componentsEditorParams) {
        componentsEditorParams = _.merge(generatedEditorSkinsData, getEditorElementsComponentsEditorParams())
    }

    return componentsEditorParams
}
