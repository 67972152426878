import type {DocumentManager} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import * as constants from '../../../../constants/constants'
import type {DataModelExtensionAPI} from '../../../dataModel/dataModel'
import type {SchemaExtensionAPI} from '../../../schema/schema'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {PageMigrator} from '../dataMigrationRunner'

interface PointerAndStructure {
    pointer: Pointer
    structure: CompStructure
}

const enum Skins {
    RectangleArea = 'wysiwyg.viewer.skins.area.RectangleArea',
    TransparentPage = 'wysiwyg.viewer.skins.page.TransparentPageSkin'
}
const {VIEW_MODES, DATA_TYPES} = constants

const replaceSkinAndStyleForPage = (documentManager: DocumentManager, pageId: string) => {
    const pagePointersAndStructures: PointerAndStructure[] = Object.values(VIEW_MODES).map((viewMode: string) => {
        const pointer = documentManager.pointers.getPointer(pageId, viewMode)
        return {pointer, structure: deepClone(documentManager.dal.get(pointer))}
    })

    if (pagePointersAndStructures[0].structure.skin !== Skins.RectangleArea) {
        return
    }

    const {dataModel} = documentManager.extensionAPI as DataModelExtensionAPI
    const {schemaAPI} = documentManager.extensionAPI as SchemaExtensionAPI
    const {styleId} = pagePointersAndStructures[0].structure
    const updatedProperties: Partial<CompStructure> = {skin: Skins.TransparentPage}

    if (styleId) {
        const styleItemPointer = documentManager.pointers.data.getThemeItem(styleId, pageId)
        documentManager.dal.remove(styleItemPointer)
        updatedProperties.styleId = dataModel.addItem(
            schemaAPI.createItemAccordingToSchema('ComponentStyle', DATA_TYPES.theme, {
                skin: Skins.TransparentPage,
                styleType: 'custom',
                style: {
                    properties: {},
                    propertiesSource: {},
                    groups: {}
                },
                componentClassName: 'mobile.core.components.Page'
            }),
            DATA_TYPES.theme,
            pageId
        ).id
    }

    for (const {pointer, structure} of pagePointersAndStructures) {
        documentManager.dal.set(pointer, Object.assign(structure, updatedProperties))
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    replaceSkinAndStyleForPage(documentManager, pageId)
}

const name = 'migrateRectanglePageToTransparent'
const version = 1

export const migrateRectanglePageToTransparent: PageMigrator = {
    migratePage,
    name,
    version,
    fixerRequiresReruns: true
}
