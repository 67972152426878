import {
    componentLayoutToMeshLayout,
    ComponentStructuralInfo,
    ComponentStructureVariant,
    ConversionConfig
} from '@wix/layout-migrations'
import type {
    AbsoluteLayout,
    CompRef,
    ContainerLayouts,
    Pointer,
    PS,
    SingleLayoutData
} from '@wix/document-services-types'
import componentSerialization from '../component/componentSerialization'
import siteWidthAPI from '../structure/siteWidth/siteWidth'
import variantsUtils from '../variants/variantsUtils'
import type {VariantsExtensionAPI} from '@wix/document-manager-extensions/src/extensions/variants/variants'
import type {MeshLayoutApi, RemeasureExtensionAPI} from '@wix/document-manager-extensions'
import {
    AUTO_CONTENT_HEIGHT,
    COMP_IDS,
    MASTER_PAGE_ID,
    MESH_LAYOUT_TYPES
} from '@wix/document-manager-extensions/src/constants/constants'
import _ from 'lodash'
import * as experiment from 'experiment'
import {getResponsiveLayout, updateResponsiveLayout} from './getAndUpdate'

const responsiveLayout = {
    get: getResponsiveLayout,
    update: updateResponsiveLayout,
    updateWithoutRemeasuring: updateResponsiveLayout
}

interface LayoutUpdateFunc {
    (ps: PS, compRef: CompRef): void
}

export const absoluteToMesh = (ps: PS, compRef: CompRef, newLayout: AbsoluteLayout) => {
    const comp = componentSerialization.serializeComponent(ps, compRef)
    const parent = comp.parent ? ps.dal.get(ps.pointers.getPointer(comp.parent, compRef.type)) : undefined

    const compStructureVariant: ComponentStructureVariant = {
        ...comp,
        layout: newLayout
    }
    const compStructuralInfo: ComponentStructuralInfo = {
        ...comp,
        componentId: compRef.id
    }
    const parentStructuralInfo: ComponentStructuralInfo | undefined = parent
        ? {
              ...parent,
              componentId: comp.parent
          }
        : undefined

    const siteWidth = siteWidthAPI.getSiteWidth(ps)
    const config: ConversionConfig = {viewMode: compRef.type, fullWidthThreshold: siteWidth}

    return componentLayoutToMeshLayout(compStructureVariant, compStructuralInfo, parentStructuralInfo, config)
}

const isMeshContainer = (meshLayout: SingleLayoutData) => {
    return (meshLayout.containerLayout as ContainerLayouts)?.type === MESH_LAYOUT_TYPES.MESH_CONTAINER_LAYOUT
}

const isMasterPage = (compRef: CompRef) => {
    return compRef.id === MASTER_PAGE_ID
}

const isPagesContainer = (compRef: CompRef) => {
    return compRef.id === COMP_IDS.PAGES_CONTAINER
}

export const getMeasurements = (ps: PS, compRef: CompRef) => {
    const viewerMeasurements = ps.siteAPI.getBasicMeasureForComp(compRef)
    return !_.isEmpty(viewerMeasurements)
        ? viewerMeasurements
        : (ps.extensionAPI.meshLayout as MeshLayoutApi).getMeasurements(compRef)
}

const updateItemLayoutWithMeasurements: LayoutUpdateFunc = (ps: PS, compRef: CompRef) => {
    const oldLayout = responsiveLayout.get(ps, compRef)
    if (oldLayout?.itemLayout.type !== MESH_LAYOUT_TYPES.MESH_ITEM_LAYOUT) return

    const compMeasurements = getMeasurements(ps, compRef)
    const {x, y, height, width} = compMeasurements
    const newLayout = {...oldLayout, itemLayout: {...oldLayout.itemLayout, meshData: {x, y, height, width}}}

    responsiveLayout.updateWithoutRemeasuring(ps, compRef, newLayout)
}

const updateContainerHeightWithMeasurements: LayoutUpdateFunc = (ps: PS, containerRef: CompRef) => {
    const oldLayout = responsiveLayout.get(ps, containerRef)
    if (oldLayout.containerLayout.meshData.contentHeight === AUTO_CONTENT_HEIGHT) return

    const containerMeasurements = getMeasurements(ps, containerRef)
    const newLayout = {
        ...oldLayout,
        containerLayout: {
            ...oldLayout.containerLayout,
            meshData: {
                contentHeight: containerMeasurements.height
            }
        }
    }

    responsiveLayout.updateWithoutRemeasuring(ps, containerRef, newLayout)
}

const updatePagesContainerWithMeasurements: LayoutUpdateFunc = (ps: PS, pagesContainerRef: CompRef) => {
    const oldLayout = responsiveLayout.get(ps, pagesContainerRef)
    if (oldLayout?.itemLayout?.type !== MESH_LAYOUT_TYPES.MASTER_PAGE_ITEM_LAYOUT) return

    const compMeasurements = getMeasurements(ps, pagesContainerRef)
    const {y} = compMeasurements
    const newLayout = {...oldLayout, itemLayout: {...oldLayout.itemLayout, top: {type: 'px', value: y}}}

    responsiveLayout.updateWithoutRemeasuring(ps, pagesContainerRef, newLayout)
}

const executeOnMatchingVariants = (ps: PS, compRef: CompRef, compVariants: Pointer[], func: LayoutUpdateFunc) => {
    const compRefWithVariants = ps.pointers.components.getComponentVariantsPointer(compRef, compVariants) as CompRef

    const {remeasure} = ps.extensionAPI as RemeasureExtensionAPI
    if (remeasure.isUpdated(compRefWithVariants)) {
        return
    }

    const variantsIds = compRefWithVariants.variants?.map(variantPointer => variantPointer.id)
    if (!variantsIds?.length) {
        const compRefWithoutVariants = variantsUtils.getPointerWithoutVariants(compRef)
        func(ps, compRefWithoutVariants as CompRef)
        return
    }

    const layoutItem = (ps.extensionAPI as VariantsExtensionAPI).variants.getComponentItemConsideringVariants(
        compRefWithVariants,
        'layout'
    )

    if (layoutItem) {
        func(ps, compRefWithVariants)
    }
}

const remeasureMeshContainer: LayoutUpdateFunc = (ps: PS, containerRef: CompRef) => {
    const childrenRefs = ps.pointers.components.getChildren(containerRef)
    for (const childRef of childrenRefs) {
        executeOnMatchingVariants(ps, childRef, containerRef.variants, updateItemLayoutWithMeasurements)
    }
    executeOnMatchingVariants(ps, containerRef, containerRef.variants, updateContainerHeightWithMeasurements)
}

const remeasureMasterPage: LayoutUpdateFunc = (ps: PS, containerRef: CompRef) => {
    const childrenRefs = ps.pointers.components.getChildren(containerRef)
    for (const childRef of childrenRefs) {
        if (isPagesContainer(childRef)) {
            executeOnMatchingVariants(ps, childRef, containerRef.variants, updatePagesContainerWithMeasurements)
        } else {
            executeOnMatchingVariants(ps, childRef, containerRef.variants, updateItemLayoutWithMeasurements)
        }
    }
}

export const remeasureContainer = (ps: PS, containerRef: CompRef) => {
    const meshLayout = responsiveLayout.get(ps, containerRef)
    if (!meshLayout) return

    if (isMasterPage(containerRef)) {
        remeasureMasterPage(ps, containerRef)
    } else if (isMeshContainer(meshLayout)) {
        remeasureMeshContainer(ps, containerRef)
    }
}

export const remeasureContainerIfNeeded = (ps: PS, containerRef: CompRef) => {
    if (experiment.isOpen('dm_noRemeasure')) return
    remeasureContainer(ps, containerRef)
}
