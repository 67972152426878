const {styleItemSelectorType} = require('../parseStylableStyleItems')
const {
    markPropertyToRemove,
    isPropertyToRemove,
    removePropertyToRemovePrefix,
    rgbToHex,
    splitWithRgb
} = require('./utils')

const SIDES = ['left', 'right', 'top', 'bottom']
const BORDER_SIDES = ['border-left', 'border-right', 'border-top', 'border-bottom']
const borderPropertiesToRemove = [
    'border',
    'border-width',
    'border-style',
    'border-color',
    ...SIDES.flatMap(side => [`border-${side}-style`, `border-${side}-color`]),
    ...BORDER_SIDES.map(markPropertyToRemove)
]

function updateBorderWidth(borderValue = '1px solid', borderWidth) {
    const [, style, ...colorSplit] = borderValue.split(' ')
    const color = colorSplit.join(' ')
    return color ? `${borderWidth} ${style} ${color}` : `${borderWidth} ${style}`
}

function updateBorderStyle(borderValue = '1px solid', borderStyle) {
    // eslint-disable-next-line no-unused-vars
    const [width, _, ...colorSplit] = borderValue.split(' ')
    const color = colorSplit.join(' ')
    return color ? `${width} ${borderStyle} ${color}` : `${width} ${borderStyle}`
}

function updateBorderColor(borderValue = '1px solid', borderColor) {
    const [width, style] = borderValue.split(' ')
    const rgbRegex = /^(rgb|rgba)\(\d/
    const color = rgbRegex.test(borderColor) ? rgbToHex(borderColor) : borderColor
    return `${width} ${style} ${color}`
}

function updateBorderPartInBorderObject(borderObject, borderPartProp, updateBorderPartFunc) {
    const borderPart = splitWithRgb(borderPartProp.value)
    const bordersLength = borderPart.length

    if (bordersLength === 1) {
        const [borderPartValue] = borderPart
        return {
            left: updateBorderPartFunc(borderObject.left, borderPartValue),
            right: updateBorderPartFunc(borderObject.right, borderPartValue),
            top: updateBorderPartFunc(borderObject.top, borderPartValue),
            bottom: updateBorderPartFunc(borderObject.bottom, borderPartValue)
        }
    }

    if (bordersLength === 2) {
        const [borderTopAndBottom, borderLeftAndRight] = borderPart
        return {
            left: updateBorderPartFunc(borderObject.left, borderLeftAndRight),
            right: updateBorderPartFunc(borderObject.right, borderLeftAndRight),
            top: updateBorderPartFunc(borderObject.top, borderTopAndBottom),
            bottom: updateBorderPartFunc(borderObject.bottom, borderTopAndBottom)
        }
    }

    if (bordersLength === 3) {
        const [borderTop, borderLeftAndRight, borderBottom] = borderPart
        return {
            left: updateBorderPartFunc(borderObject.left, borderLeftAndRight),
            right: updateBorderPartFunc(borderObject.right, borderLeftAndRight),
            top: updateBorderPartFunc(borderObject.top, borderTop),
            bottom: updateBorderPartFunc(borderObject.bottom, borderBottom)
        }
    }

    const [borderTop, borderRight, borderBottom, borderLeft] = borderPart

    return {
        left: updateBorderPartFunc(borderObject.left, borderLeft),
        right: updateBorderPartFunc(borderObject.right, borderRight),
        top: updateBorderPartFunc(borderObject.top, borderTop),
        bottom: updateBorderPartFunc(borderObject.bottom, borderBottom)
    }
}

function borderToProperty(border) {
    const borderProperties = []
    Object.entries(border).forEach(([side, value]) => {
        borderProperties.push({
            prop: `border-${side}`,
            cssProp: `border-${side}`,
            value
        })
    })

    return borderProperties
}

function updateBorderSides(borderSides, updatedBorderSides) {
    borderSides.left = updatedBorderSides.left
    borderSides.right = updatedBorderSides.right
    borderSides.top = updatedBorderSides.top
    borderSides.bottom = updatedBorderSides.bottom
}

function setAsRootProperties(rootProperties, borderSides, specificSide) {
    const rootBorder = rootProperties?.find(prop => prop.cssProp === 'border')?.value
    ;(specificSide ? [specificSide] : SIDES).forEach(side => {
        borderSides[side] =
            borderSides[side] ?? (rootProperties?.find(prop => prop.cssProp === `border-${side}`)?.value || rootBorder)
    })
}

function handleBorderProperty(property, borderSides, rootProperties) {
    if (property.cssProp === 'border') {
        borderSides.left = property.value
        borderSides.right = property.value
        borderSides.top = property.value
        borderSides.bottom = property.value
    }

    if (property.cssProp === 'border-width') {
        setAsRootProperties(rootProperties, borderSides)
        const updatedBorder = updateBorderPartInBorderObject(borderSides, property, updateBorderWidth)
        updateBorderSides(borderSides, updatedBorder)
    }

    if (property.cssProp === 'border-style') {
        setAsRootProperties(rootProperties, borderSides)
        const updatedBorder = updateBorderPartInBorderObject(borderSides, property, updateBorderStyle)
        updateBorderSides(borderSides, updatedBorder)
    }

    if (property.cssProp === 'border-color') {
        setAsRootProperties(rootProperties, borderSides)
        const updatedBorder = updateBorderPartInBorderObject(borderSides, property, updateBorderColor)
        updateBorderSides(borderSides, updatedBorder)
    }

    SIDES.forEach(side => {
        if (property.cssProp === `border-${side}-style`) {
            setAsRootProperties(rootProperties, borderSides, side)
            const updatedBorder = updateBorderPartInBorderObject(borderSides, property, updateBorderStyle)
            borderSides[side] = updatedBorder[side]
        }

        if (property.cssProp === `border-${side}-color`) {
            setAsRootProperties(rootProperties, borderSides, side)
            const updatedBorder = updateBorderPartInBorderObject(borderSides, property, updateBorderColor)
            borderSides[side] = updatedBorder[side]
        }
    })

    if (BORDER_SIDES.includes(removePropertyToRemovePrefix(property.cssProp))) {
        const side = property.cssProp.split('-')[1]
        borderSides[side] = property.value

        if (!isPropertyToRemove(property.cssProp)) {
            property.cssProp = markPropertyToRemove(property.cssProp)
        }
    }
}

function getUpdatedProperties(properties, borderSides) {
    return [
        ...properties.filter(property => !borderPropertiesToRemove.includes(property.cssProp)),
        ...borderToProperty(borderSides)
    ]
}
/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
function migrateBorder(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem
        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (acc, [selector, {metadata, properties, mobileProperties}]) => {
                const borderSides = {}
                const mobileBorderSides = {}

                properties.forEach(property => {
                    const rootProperties =
                        metadata.type === styleItemSelectorType.STATE ? acc[metadata.root].properties : null
                    handleBorderProperty(property, borderSides, rootProperties)
                })

                mobileProperties.forEach(property => {
                    const rootProperties =
                        metadata.type === styleItemSelectorType.STATE ? acc[metadata.root].mobileProperties : null
                    handleBorderProperty(property, mobileBorderSides, rootProperties)
                })

                const updatedProperties = getUpdatedProperties(properties, borderSides)
                const updatedMobileProperties = getUpdatedProperties(mobileProperties, mobileBorderSides)

                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties
                    }
                }
            },
            parsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: updatedParsedStyle
        }
    })
}

module.exports = migrateBorder
