import type {
    AppDefinitionId,
    ComponentRemovedHookFunc,
    CompRef,
    DataItem,
    EditorClientSpecMapEntry,
    Pointer,
    PS
} from '@wix/document-services-types'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import _ from 'lodash'
import component from '../../component/component'
import componentStructureInfo from '../../component/componentStructureInfo'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import connections from '../../connections/connections'
import dataModel from '../../dataModel/dataModel'
import siteMetadata from '../../siteMetadata/siteMetadata'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import tpaUtils from '../../tpa/utils/tpaUtils'
import dsUtils from '../../utils/utils'
import constants from '../common/constants'
import notificationService, {NotifyApplication} from '../services/notificationService'
import platformAppDataGetter from '../services/platformAppDataGetter'
import platformEventsService from '../services/platformEventsService'
import appControllerUtils from '../../appControllerData/appControllerUtils'
import platform from '../platform'
import type {HookFunc} from '../../hooks/hooksHandlerFactory'
import type {ComponentAfterAddDataFromExtEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

function notifyOnFirstSaved(
    ps: PS,
    appsToNotify: EditorClientSpecMapEntry[],
    notifyApplicationFunc: NotifyApplication
) {
    const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
    _.forEach(appsToNotify, ({appDefinitionId, instance, instanceId}) => {
        notifyApplicationFunc(
            ps,
            appDefinitionId,
            platformEvents.factory.siteWasFirstSaved({
                metaSiteId,
                instance,
                instanceId
            })
        )
    })

    ps.siteAPI.updateBiData({metaSiteId})
}

function removeGhostStructureForApp(ps: PS, {appDefinitionId}: {appDefinitionId: AppDefinitionId}) {
    ps.siteDataAPI.siteData.removeGhostStructureData(appDefinitionId)
}

function notifyAddToAppWidget(
    ps: PS,
    componentPointer: Pointer,
    newContainerPointer: Pointer,
    oldParentPointer: Pointer
) {
    if (!newContainerPointer || ps.pointers.components.isPage(newContainerPointer)) {
        return
    }
    const closestAppWidget = componentStructureInfo.getAncestorByPredicate(
        ps,
        newContainerPointer,
        parentPointer => component.getType(ps, parentPointer) === constants.CONTROLLER_TYPES.APP_WIDGET
    )
    if (closestAppWidget) {
        if (oldParentPointer && componentDetectorAPI.isDescendantOfComp(ps, oldParentPointer, closestAppWidget)) {
            return
        }
        const fullData = dataModel.getDataItem(ps, closestAppWidget) || {}
        const widgetAppDefId = appControllerUtils.getControllerAppDefinitionId(fullData)
        if (widgetAppDefId) {
            const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, widgetAppDefId)
            if (appData) {
                const event = platformEvents.factory.componentAddedToApp({
                    widgetRef: closestAppWidget as CompRef,
                    compRef: componentPointer as CompRef
                })
                notificationService.notifyApplication(ps, widgetAppDefId, event)
            }
        }
    }
}

function addCompAppDefId(compData: DataItem, set: Set<AppDefinitionId>) {
    const appDefId = platform.getAppDefIdFromData(compData)
    if (appDefId) {
        set.add(appDefId)
    }
}

function notifyComponentAddedToStage(
    notifyApplicationCb: NotifyApplication,
    ps: PS,
    componentPointer: Pointer,
    newContainerPointer: Pointer,
    oldParentPointer: Pointer
) {
    if (!componentPointer || ps.pointers.components.isPage(componentPointer)) {
        return
    }

    const events = [platformEvents.factory.componentAddedToStage, platformEvents.factory.anyComponentAddedToStage].map(
        eventFromFactory => ({
            ...eventFromFactory({
                compRef: componentPointer as CompRef,
                componentType: component.getType(ps, componentPointer)
            }),
            eventOrigin: 'DM'
        })
    )

    const compData = component.data.get(ps, componentPointer)
    events.forEach(event => {
        const appsToNotify = new Set(_.compact(platformEventsService.getAppsRegisteredToEventType(event.eventType)))
        addCompAppDefId(compData, appsToNotify)
        appsToNotify.forEach(appDefinitionId => notifyApplicationCb(ps, appDefinitionId, event))
    })

    notifyConnectedComponentAddedToStage(notifyApplicationCb, ps, componentPointer, compData)
    notifyAddToAppWidget(ps, componentPointer, newContainerPointer, oldParentPointer)
}

function notifyConnectedComponentAddedToStage(
    notifyApplicationCb: NotifyApplication,
    ps: PS,
    componentPointer: Pointer,
    compData: DataItem
) {
    const event = Object.assign(
        {eventOrigin: 'DM'},
        platformEvents.factory.connectedComponentAddedToStage({
            compRef: componentPointer as CompRef,
            componentType: component.getType(ps, componentPointer)
        })
    )

    const appsConnectedToComponent = new Set(connections.getAppsConnectedToComponent(ps, componentPointer))
    addCompAppDefId(compData, appsConnectedToComponent)
    const appsToNotify = platformEventsService
        .getAppsRegisteredToEventType(event.eventType)
        .filter(appDefinitionId => appsConnectedToComponent.has(appDefinitionId))

    appsToNotify.forEach(appDefinitionId => notifyApplicationCb(ps, appDefinitionId, event))
}

function getComponentAddedToStageHook(notifyApplicationCb: NotifyApplication) {
    return _.partial(notifyComponentAddedToStage, notifyApplicationCb)
}

function notifyWidgetAddedToStage(ps: PS, compToAddPointer: Pointer, clonedSerializedComp) {
    const compData = clonedSerializedComp.data || {}
    const {originCompId, componentType} = clonedSerializedComp

    const appDefId =
        (connections.isOOIController(componentType) && compData.appDefinitionId) ||
        (connections.isAppWidgetType(componentType) && appControllerUtils.getControllerAppDefinitionId(compData))

    if (appDefId) {
        notificationService.notifyApplication(
            ps,
            appDefId,
            platformEvents.factory.widgetAdded({
                componentRef: compToAddPointer as CompRef,
                originalComponentId: originCompId,
                widgetId: compData.widgetId
            })
        )
    }
}
const notifyWidgetAddedToStageFromExt = (ps: PS, data: ComponentAfterAddDataFromExtEvent) => {
    const {compToAddPointer, componentDefinition} = data
    notifyWidgetAddedToStage(ps, compToAddPointer, componentDefinition)
}
const COMPS_TO_ADD_ORIGIN = [
    'wysiwyg.viewer.components.tpapps.TPAWidget',
    'wysiwyg.viewer.components.tpapps.TPASection',
    'platform.components.AppWidget'
]

function addOriginCompIdToWidget(ps: PS, componentPointer: Pointer, compStructure) {
    if (COMPS_TO_ADD_ORIGIN.includes(compStructure.componentType)) {
        compStructure.originCompId = componentPointer.id
    }
}

function getAppDefinitionId(ps: PS, componentPointer: Pointer): AppDefinitionId {
    const compData = dataModel.getDataItem(ps, componentPointer) || {}
    const componentType = dsUtils.getComponentType(ps, componentPointer)
    return tpaUtils.isTpaByCompType(componentType)
        ? compData.appDefinitionId
        : appControllerUtils.getControllerAppDefinitionId(compData)
}

function notifyComponentDisconnected(ps: PS, componentPointer: Pointer, controllerRef: Pointer) {
    const appDefinitionId = getAppDefinitionId(ps, componentPointer)
    if (appDefinitionId) {
        const event = platformEvents.factory.componentDisconnected({
            controllerRef: controllerRef as CompRef,
            compRef: componentPointer as CompRef
        })
        notificationService.notifyApplication(ps, appDefinitionId, event)
    }
}

function notifyComponentConnected(ps: PS, componentPointer: Pointer, controllerRef: Pointer) {
    const appDefinitionId = getAppDefinitionId(ps, componentPointer)
    if (appDefinitionId) {
        const event = platformEvents.factory.componentConnected({
            controllerRef: controllerRef as CompRef,
            compRef: componentPointer as CompRef
        })
        notificationService.notifyApplication(ps, appDefinitionId, event)
    }
}
function notifyAppOnPresetChanged(ps: PS, componentRef: Pointer, presetId: string) {
    const {appDefinitionId} = dataModel.getDataItem(ps, componentRef) || {}

    if (appDefinitionId) {
        const event = platformEvents.factory.presetChanged({
            componentRef: componentRef as CompRef,
            presetId
        })
        notificationService.notifyApplication(ps, appDefinitionId, event)
    }
}

function addAppsControllers(ps: PS, dataItem: DataItem): Record<AppDefinitionId, any> {
    const appsToNotify: Record<AppDefinitionId, any> = {}
    let appData: any
    switch (dataItem?.type) {
        case 'AppController':
            const appDefId = appControllerUtils.getControllerAppDefinitionId(dataItem)
            appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
            break
        case 'WidgetRef':
            appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, dataItem?.appDefinitionId)
            break
        default:
            appData = dataItem?.applicationId
                ? clientSpecMapService.getAppData(ps, dataItem?.applicationId)
                : clientSpecMapService.getAppDataByAppDefinitionId(ps, dataItem?.appDefinitionId)
    }
    if (clientSpecMapService.hasEditorPlatformPart(appData)) {
        appsToNotify[appData.appDefinitionId] = appsToNotify[appData.appDefinitionId] || []
    }
    return appsToNotify
}

function addConnectedComponentsApps(ps: PS, componentConnections: any[]): Record<AppDefinitionId, any[]> {
    return _.reduce(
        componentConnections,
        function (appsToNotifyAcc, connection) {
            const controllerData = dataModel.getDataItem(ps, connection.controllerRef)
            if (controllerData) {
                const appDefId = appControllerUtils.getControllerAppDefinitionId(controllerData)
                const appData = platformAppDataGetter.getAppDataByAppDefId(ps, appDefId)
                if (appData) {
                    appsToNotifyAcc[appDefId] = appsToNotifyAcc[appDefId] || []
                    appsToNotifyAcc[appDefId].push(connection)
                }
            }

            return appsToNotifyAcc
        },
        {} as Record<AppDefinitionId, any[]>
    )
}

function getOnDeleteHook(notifyApplicationCb: NotifyApplication) {
    return _.partial(notifyConnectedAppOnDelete, notifyApplicationCb)
}

function notifyConnectedAppOnDelete(
    notifyApplicationCb: NotifyApplication,
    ps: PS,
    compPointer: Pointer,
    deletingParent,
    removeArgs,
    deletedParentFromFull,
    dataItem,
    deletedCompParentPointer: Pointer,
    componentConnections,
    compType: string
) {
    const appsToNotify = {
        ...addAppsControllers(ps, dataItem),
        ...addConnectedComponentsApps(ps, componentConnections)
    }

    _.forEach(appsToNotify, function (appConnections, appDefinitionId) {
        notifyApplicationCb(
            ps,
            appDefinitionId,
            platformEvents.factory.componentDeleted({
                componentRef: compPointer as CompRef,
                connections: appConnections,
                componentType: compType
            }),
            true
        )
    })
}

const getOnComponentRemovedHook = (hook: ComponentRemovedHookFunc): HookFunc => {
    return (
        ps: PS,
        componentPointer: CompRef,
        deletingParent: boolean,
        removeArgs: boolean,
        deletedParentFromFull: boolean,
        copyDataItem: any
    ) => {
        hook({compRef: componentPointer, dataItem: copyDataItem})
    }
}

export default {
    notifyOnFirstSaved,
    removeGhostStructureForApp,
    notifyAddToAppWidget,
    getComponentAddedToStageHook,
    notifyWidgetAddedToStage,
    notifyWidgetAddedToStageFromExt,
    notifyAppOnPresetChanged,
    notifyComponentDisconnected,
    notifyComponentConnected,
    getOnDeleteHook,
    addOriginCompIdToWidget,
    getOnComponentRemovedHook
}
