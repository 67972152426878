import {MockCSChannel, MockCSDuplexer} from '../csave/mockCSChannel'
import type {Approved, Rejected} from '../csave/csaveTypes'

export type EventCB = (payload?: any) => Promise<void>

export class MockDistributorChannel extends MockCSChannel {
    constructor(private mockBatchEvents = false) {
        super()
    }
    approved(payload: Approved) {
        if (this.mockBatchEvents) {
            super.batchTransactions({transactionsResults: [{approved: payload}]})
        } else {
            super.approved(payload)
        }
    }

    rejected(payload: Rejected) {
        if (this.mockBatchEvents) {
            super.batchTransactions({transactionsResults: [{rejected: payload}]})
        } else {
            super.rejected(payload)
        }
    }
    distributeMessage(payloads?: any) {
        this.events.messageDistribute({payloads})
    }
}

export class MockDistributorDuplexer extends MockCSDuplexer {
    constructor(private mockBatchEvents = false) {
        super()
    }

    createChannel() {
        return new MockDistributorChannel(this.mockBatchEvents)
    }
    distributeMessage(payload?: any) {
        const channel = this._channel! as MockDistributorChannel
        channel.distributeMessage(payload)
    }
}
