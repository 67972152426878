import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'

export type ComponentMigrationFunction = (
    documentManager: DocumentManager,
    pageId: string,
    pointer: Pointer,
    viewMode: string
) => void

const runOnPageComponents = (
    documentManager: DocumentManager,
    pageId: string,
    viewMode: string,
    func: ComponentMigrationFunction
) => {
    const pageComponentPointers = documentManager.pointers.structure.getAllComponentPointers(viewMode, pageId)
    _.forEach(pageComponentPointers, pointer => func(documentManager, pageId, pointer, viewMode))
}

export {runOnPageComponents}
