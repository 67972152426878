import {DAL, DmApis, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {
    CompRef,
    Pointer,
    PossibleViewModes,
    PublicMethodDefinition,
    ScopePointer
} from '@wix/document-services-types'
import _ from 'lodash'
import * as constants from '../constants/constants'
import type {CreateViewerExtensionArgument} from '../types'
import {dataRefTypes, structureRefTypes, isRefPointer, getRefPointerType} from '../utils/refStructureUtils'
import {repeaterDelimiter} from '../utils/repeaterUtils'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import {
    buildScopePointerByOwner,
    getScopedPointer,
    getItemIdByInflatedId,
    buildOldInflatedId,
    removeMostOuterScope,
    getInflatedPointer
} from '../utils/scopesUtils'
import type {ComponentDefinitionExtensionAPI} from './componentDefinition'
import {inflateWithSharedParts} from '../utils/inflationUtils'

const {getInnerPointer, getRepeatedItemPointerIfNeeded, isPointer} = pointerUtils

const {DATA_TYPES, VIEW_MODES, VIEWER_PAGE_DATA_TYPES, VIEWER_DATA_TYPES} = constants
const DESKTOP = VIEW_MODES.DESKTOP as PossibleViewModes

const DISPLAYED_ONLY_TYPE_CANDIDATE = _.invert(VIEWER_DATA_TYPES)
const REFERRED_DATA_TYPES_TO_OVERRIDE_GETTERS = _.keyBy([...structureRefTypes, ...dataRefTypes])
const DATA_TYPES_TO_OVERRIDE_GETTERS = {
    ...VIEW_MODES,
    ...VIEWER_PAGE_DATA_TYPES
}

const createExtension = ({
    viewerManager,
    dsConfig,
    logger,
    experimentInstance
}: CreateViewerExtensionArgument): Extension => {
    const {extractScopeFromPointer} = viewerManager.viewerSiteAPI

    let disableScopes = false

    const getDisplayedFromViewer = (pointer: Pointer, shouldDisableScopes: boolean): any => {
        return viewerManager.dal.get(pointer, !shouldDisableScopes)
    }

    const getFromViewer = (pointer: Pointer, shouldDisableScopes: boolean): any =>
        getDisplayedFromViewer(getRefPointerType(pointer), shouldDisableScopes)

    const get = (dal: DAL, pointer: Pointer) => {
        if (pointer.noRefFallbacks) {
            return dal.get(pointer)
        }

        if (isRefPointer(pointer)) {
            return getFromViewer(pointer, disableScopes) ?? dal.get(pointer)
        }

        const value = dal.get(pointer)

        if (value !== undefined) {
            return value
        }

        if (DISPLAYED_ONLY_TYPE_CANDIDATE[pointer.type]) {
            return getFromViewer(pointer, disableScopes)
        }
    }

    const getByPointerWithRefType = (dal: DAL, pointer: Pointer) => {
        if (disableScopes) {
            return getFromViewer(pointer, true)
        }
    }

    const createGetters = () => {
        return {
            ..._.mapValues(DATA_TYPES_TO_OVERRIDE_GETTERS, () => get),
            ..._.mapValues(REFERRED_DATA_TYPES_TO_OVERRIDE_GETTERS, () => getByPointerWithRefType)
        }
    }

    const createExtensionAPI = ({dal, extensionAPI}: DmApis): ScopesExtensionAPI => {
        const getCompType = (compPointer: Pointer): string | undefined =>
            dal.get(getRepeatedItemPointerIfNeeded(compPointer))?.componentType

        const getDefinedScopes = (compPointer: CompRef): ScopePointer[] => {
            const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI
            const componentType = getCompType(compPointer)

            if (componentType) {
                if (componentDefinition.isRefComponent(componentType)) {
                    return [buildScopePointerByOwner(compPointer.id, compPointer.scope)]
                }

                if (dsConfig.enableRepeatersInScopes && componentDefinition.isRepeater(componentType)) {
                    const {dataModel} = extensionAPI as DataModelExtensionAPI
                    const {items} = dataModel.components.getItem(compPointer, DATA_TYPES.data)
                    return items.map((itm: string) =>
                        buildScopePointerByOwner(`${compPointer.id}${repeaterDelimiter}${itm}`, compPointer.scope)
                    )
                }
            }

            return []
        }

        const hasDefinedScopes = (compPointer: CompRef): boolean => {
            const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI
            const componentType = getCompType(compPointer)

            if (!componentType) {
                return false
            }

            if (dsConfig.enableRepeatersInScopes && componentDefinition.isRepeater(componentType)) {
                return true
            }

            return componentDefinition.isRefComponent(componentType)
        }

        const getScopeOwner = (scopePointer: ScopePointer, viewMode: PossibleViewModes = DESKTOP): CompRef => {
            let {id} = scopePointer

            if (dsConfig.enableRepeatersInScopes) {
                id = id.split(repeaterDelimiter, 1)[0]
            }

            const pointer = getScopedPointer(id, viewMode, _.cloneDeep(scopePointer.scope)) as CompRef
            pointer.id = buildOldInflatedId(pointer, dsConfig.enableRepeatersInScopes)
            return pointer
        }

        const getRefCompChildrenByScope = (scopePointer: ScopePointer, viewMode: PossibleViewModes) => {
            const ownerPointer = getScopeOwner(scopePointer, viewMode)
            const ownerChildrenPointer = getInnerPointer(ownerPointer, ['components'])

            if (isRefPointer(ownerChildrenPointer)) {
                return getFromViewer(ownerChildrenPointer, true) ?? getDisplayedFromViewer(ownerChildrenPointer, true)
            }

            return (
                getDisplayedFromViewer(ownerChildrenPointer, true) ??
                getFromViewer(inflateWithSharedParts(ownerChildrenPointer), true)
            )
        }

        const isLoaded = (scopePointer: ScopePointer, viewMode: PossibleViewModes = DESKTOP): boolean =>
            getRefCompChildrenByScope(scopePointer, viewMode)?.length > 0

        const getRootComponent = (scopePointer: ScopePointer, viewMode: PossibleViewModes = DESKTOP): CompRef => {
            let [rootId] = getRefCompChildrenByScope(scopePointer, viewMode) ?? []
            if (!rootId) {
                throw new ReportableError({
                    errorType: 'SCOPE_ROOT_COMP_NOT_FOUND',
                    message: 'Scope root component was not found',
                    extras: {
                        scopePointer
                    }
                })
            }

            if (dsConfig.enableRepeatersInScopes) {
                rootId = buildOldInflatedId(
                    getScopedPointer(getItemIdByInflatedId(rootId, true), viewMode, scopePointer),
                    true
                )
            }

            return getScopedPointer(rootId, viewMode, _.cloneDeep(scopePointer)) as CompRef
        }

        const getTemplateCompPointer = (compPointer: CompRef): CompRef => {
            const scope = extractScopeFromPointer(compPointer)

            if (!scope) {
                throw new ReportableError({
                    errorType: 'COMP_NOT_IN_SCOPE',
                    message: 'Component is not in a scope',
                    extras: {
                        compPointer
                    }
                })
            }

            const {id, type} = compPointer
            const pointer = getScopedPointer(
                getItemIdByInflatedId(id, dsConfig.enableRepeatersInScopes),
                type,
                scope && removeMostOuterScope(scope)
            ) as CompRef
            pointer.id = buildOldInflatedId(pointer, dsConfig.enableRepeatersInScopes)
            return pointer
        }

        const getTemplateRoot = (scopePointer: ScopePointer, viewMode: PossibleViewModes = DESKTOP): CompRef =>
            getTemplateCompPointer(getRootComponent(scopePointer, viewMode))

        const getScopesList = (compPointer: CompRef): ScopePointer[] => {
            const scopes = []
            let scope = extractScopeFromPointer(compPointer)

            while (scope) {
                scopes.push(_.cloneDeep(scope))
                scope = scope.scope
            }

            return scopes
        }

        const getComponentInScope = (compPointer: CompRef, scopes: ScopePointer | ScopePointer[]): CompRef => {
            const scopesList = Array.isArray(scopes) ? scopes : [scopes]
            const scope: ScopePointer | undefined = scopesList.reverse().reduce(
                (outerScopePointer: ScopePointer | undefined, scopePointer: ScopePointer) => ({
                    id: scopePointer.id,
                    type: 'scope',
                    ...(outerScopePointer && {scope: outerScopePointer})
                }),
                undefined
            )

            const scopedPointer = {
                ..._.omit(compPointer, 'scope'),
                ...(scope && {scope})
            }

            return getInflatedPointer(scopedPointer, dsConfig.enableRepeatersInScopes)
        }

        const getScopeWithPredicate = (
            compPointer: CompRef,
            predicate: Function = () => true
        ): ScopePointer | undefined => {
            let scope = extractScopeFromPointer(compPointer)

            while (scope && !predicate(scope)) {
                scope = scope.scope
            }

            return scope
        }

        const getRootScopeWithPredicate = (
            compPointer: CompRef,
            predicate: Function = () => true
        ): ScopePointer | undefined => {
            let scope = extractScopeFromPointer(compPointer)
            let foundScope = predicate(scope) ? scope : undefined

            while (scope) {
                foundScope = predicate(scope) ? scope : foundScope
                scope = scope.scope
            }

            return foundScope
        }

        const getScopesPointerConsideringHybrid = (id: string, type: string, scope?: ScopePointer) =>
            getScopedPointer(id, type, scope, disableScopes)

        const getInflatedId = (ptr: Pointer): string => {
            if (!ptr.scope) {
                return ptr.id
            }

            return getInflatedPointer(ptr as CompRef, dsConfig.enableRepeatersInScopes).id
        }

        const EXCLUDED_FROM_BI_APIS = new Set([
            'deprecatedOldBadPerformanceApis.components.getAllComponents',
            'deprecatedOldBadPerformanceApis.components.getAllComponentsFromFull',
            'deprecatedOldBadPerformanceApis.components.getChildren',
            'deprecatedOldBadPerformanceApis.components.getChildrenFromFull',
            'deprecatedOldBadPerformanceApis.components.getContainer',
            'deprecatedOldBadPerformanceApis.components.getAncestors',
            'deprecatedOldBadPerformanceApis.components.getRepeatedComponents',
            'deprecatedOldBadPerformanceApis.components.get.byXYRelativeToStructure',
            'deprecatedOldBadPerformanceApis.components.get.byXYRelativeToScreen',
            'deprecatedOldBadPerformanceApis.components.get.getComponentsAtXYConsideringFrame',
            'deprecatedOldBadPerformanceApis.components.get.byType',
            'deprecatedOldBadPerformanceApis.components.get.byAncestor',
            'deprecatedOldBadPerformanceApis.components.modes.getFirstAncestorWithActiveModes',
            'deprecatedOldBadPerformanceApis.components.isDescendantOfComp',
            'deprecatedOldBadPerformanceApis.layouters.getParentCompWithOverflowHidden',
            'deprecatedOldBadPerformanceApis.mobile.hiddenComponents.get',
            'tpa.handlers'
        ])

        const unhandledPublicApiAntiSpamSet = new Set()

        const APIS_IN_ROLLOUT = [
            {
                experiment: 'dm_useViewerIsShowOnFixedPosition',
                apis: new Set(['components.layout.isShowOnFixedPosition'])
            }
        ]

        const wrapMethodWithDisableScopes = (
            method: Function,
            apiDefinition?: PublicMethodDefinition,
            methodPath?: string
        ): Function => {
            if (experimentInstance.isOpen('dm_disableScopesHybridMode')) {
                return method
            }

            for (const {experiment, apis} of APIS_IN_ROLLOUT) {
                if (methodPath && experimentInstance.isOpen(experiment) && apis.has(methodPath)) {
                    return method
                }
            }

            return (...args: any[]) => {
                try {
                    disableScopes = true
                    const result = method(...args)

                    if (
                        experimentInstance.isOpen('dm_addScopesAffectedPublicApisBI') &&
                        apiDefinition &&
                        methodPath &&
                        !unhandledPublicApiAntiSpamSet.has(methodPath) &&
                        !EXCLUDED_FROM_BI_APIS.has(methodPath)
                    ) {
                        const {type} = apiDefinition
                        if (type !== 'DATA_MANIPULATION_ACTION' && type !== 'ACTION') {
                            disableScopes = false
                            const resultWithScopes = method(...args)

                            if (!_.isEqual(result, resultWithScopes)) {
                                unhandledPublicApiAntiSpamSet.add(methodPath)

                                logger.captureError(
                                    new ReportableError({
                                        errorType: 'SCOPES_AFFECTED_READ_PUBLIC_API',
                                        message: 'An affected public api was found (ignore)',
                                        extras: {
                                            publicApi: methodPath,
                                            args: args.filter(arg => isPointer(arg)),
                                            resultWithScopes,
                                            result
                                        }
                                    })
                                )
                            }
                        }
                    }

                    return result
                } finally {
                    disableScopes = false
                }
            }
        }

        return {
            scopes: {
                isLoaded,
                extractScopeFromPointer,
                hasDefinedScopes,
                getDefinedScopes,
                getRootComponent,
                getScopeOwner,
                getTemplateCompPointer,
                getTemplateRoot,
                getScopesList,
                getComponentInScope,
                getScopeWithPredicate,
                getRootScopeWithPredicate,
                getScopesPointerConsideringHybrid,
                getInflatedId,
                getDisplayedFromViewer: (pointer: Pointer) =>
                    getDisplayedFromViewer(pointer, !dsConfig.enableScopes || disableScopes),
                wrapMethodWithDisableScopes
            }
        }
    }

    const extension: Extension = {
        name: 'scopes',
        dependencies: new Set(['componentDefinition']),
        createExtensionAPI
    }

    if (dsConfig.enableScopes) {
        // @ts-expect-error
        extension.createGetters = createGetters
    }

    return extension
}

export interface ScopesExtensionAPI extends ExtensionAPI {
    scopes: {
        isLoaded(scopePointer: ScopePointer, viewMode?: PossibleViewModes): boolean
        extractScopeFromPointer(pointer: Pointer): ScopePointer | undefined
        hasDefinedScopes(compPointer: CompRef): boolean
        getDefinedScopes(compPointer: CompRef): ScopePointer[]
        getRootComponent(scopePointer: ScopePointer, viewMode?: PossibleViewModes): CompRef
        getScopeOwner(scopePointer: ScopePointer, viewMode?: PossibleViewModes): CompRef
        getTemplateCompPointer(compPointer: CompRef): CompRef
        getTemplateRoot(scopePointer: ScopePointer, viewMode?: PossibleViewModes): CompRef
        getScopesList(compRef: CompRef): ScopePointer[]
        getComponentInScope(compPointer: CompRef, scope: ScopePointer): CompRef
        getComponentInScope(compPointer: CompRef, scopes: ScopePointer[]): CompRef
        getScopeWithPredicate(compPointer: CompRef, predicate: Function): ScopePointer | undefined
        getRootScopeWithPredicate(compPointer: CompRef, predicate: Function): ScopePointer | undefined
        getScopesPointerConsideringHybrid(id: string, type: string, scope?: ScopePointer): Pointer
        getInflatedId(ptr: Pointer): string
        getDisplayedFromViewer(pointer: Pointer): any
        wrapMethodWithDisableScopes(
            method: Function,
            apiDefinition?: PublicMethodDefinition,
            methodPath?: string
        ): Function
    }
}

export {createExtension}
