import {
    listFiles as veloIdeListFiles,
    readFile as veloIdeReadFile,
    updateFiles as veloIdeUpdateFiles,
    copyFile as veloIdeCopyFile,
    moveFile as veloIdeMoveFile
} from '@wix/ambassador-velo-ide-v1-gridapp-filestructure/http'
import {
    type UpdateFilesRequest,
    type UpdateFilesResponse,
    type ReadFileRequest,
    type CopyFileRequest,
    type MoveFileRequest,
    type ListFilesRequest,
    GridAppLayout,
    type Directory,
    type File
} from '@wix/ambassador-velo-ide-v1-gridapp-filestructure/types'
import type {PS, WixCodeFileDescriptor} from '@wix/document-services-types'
import {HttpClient} from '@wix/http-client'
import type {CodeAppInfo} from '../utils/codeAppInfo'
import errors from '../utils/errors'
import fileDescriptorUtils from '../utils/fileDescriptorUtils'
import {
    ensureDescriptorWithoutTrailingSlash,
    getVirtualDescriptor,
    stripLastPathSeparatorIfExists,
    WixCodeFile
} from './IFileSystemService'

type GridAppUpdates = Pick<UpdateFilesRequest, 'filesToUpdate' | 'pathsToDelete' | 'emptyDirectoriesToCreate'>

const createService = (extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo) => {
    const getToken = () => codeAppInfo.signedInstance

    const wixHttpClientInstance = new HttpClient({
        baseURL: codeAppInfo.editorRootUrl,
        getAppToken: getToken
    })

    const gridAppId = codeAppInfo.appId

    const updateGridApp = async (updates: Partial<GridAppUpdates>): Promise<UpdateFilesResponse> => {
        const response = await wixHttpClientInstance
            .request(
                veloIdeUpdateFiles({
                    gridAppId,
                    layout: GridAppLayout.ONLINE,
                    ignoreForbiddenPaths: true,
                    sessionId: extensionAPI.wixCode.getWixCodeSessionId(),
                    ...updates
                })
            )
            .catch(e => {
                throw e
            })

        return response.data
    }

    const writeFile = async (file: WixCodeFileDescriptor, content?: string) => {
        await updateGridApp({filesToUpdate: [{path: file.location + file.name, content}]})
        return file
    }

    const deleteItem = async (itemToDelete: WixCodeFileDescriptor) => {
        await updateGridApp({pathsToDelete: [ensureDescriptorWithoutTrailingSlash(itemToDelete).location]})
        return itemToDelete
    }

    const createFolder = async (folderName: string, parentFolder: WixCodeFileDescriptor) => {
        const path = parentFolder.location + folderName
        await updateGridApp({emptyDirectoriesToCreate: [{path}]})
        return getVirtualDescriptor(path, true)
    }

    const readFile = async (descriptor: WixCodeFileDescriptor): Promise<string> => {
        const readFileRequest: ReadFileRequest = {
            gridAppId,
            layout: GridAppLayout.ONLINE,
            path: descriptor.location,
            branchId: extensionAPI.siteAPI.getBranchId()
        }
        const response = await wixHttpClientInstance.request(veloIdeReadFile(readFileRequest))

        return response.data.file.content
    }

    const copy = async (
        itemToCopy: WixCodeFileDescriptor,
        targetFolder: WixCodeFileDescriptor,
        newName?: string
    ): Promise<WixCodeFileDescriptor> => {
        const targetName = newName ?? itemToCopy.name
        const newPath = targetFolder.location + targetName
        const copyFileRequest: CopyFileRequest = {
            gridAppId,
            layout: GridAppLayout.ONLINE,
            sourcePath: itemToCopy.location,
            targetPath: newPath,
            sessionId: extensionAPI.wixCode.getWixCodeSessionId()
        }
        await wixHttpClientInstance.request(veloIdeCopyFile(copyFileRequest))

        return getVirtualDescriptor(newPath, itemToCopy.directory)
    }

    const move = async (
        itemToMove: WixCodeFileDescriptor,
        targetFolder: WixCodeFileDescriptor,
        newName?: string
    ): Promise<WixCodeFileDescriptor> => {
        const targetName = newName ?? itemToMove.name
        const newPath = targetFolder.location + targetName
        const moveFileRequest: MoveFileRequest = {
            gridAppId,
            layout: GridAppLayout.ONLINE,
            sourcePath: ensureDescriptorWithoutTrailingSlash(itemToMove).location,
            targetPath: newPath,
            sessionId: extensionAPI.wixCode.getWixCodeSessionId()
        }
        await wixHttpClientInstance.request(veloIdeMoveFile(moveFileRequest))

        return getVirtualDescriptor(newPath, itemToMove.directory)
    }

    const getChildren = async (fileSystemItem: WixCodeFileDescriptor) => {
        if (!fileDescriptorUtils.isFolder(fileSystemItem)) {
            throw new errors.ArgumentError(
                'parentFolder',
                'fileSystemService.getChildren',
                fileSystemItem,
                'folder object'
            )
        }
        const request: ListFilesRequest = {
            gridAppId,
            layout: GridAppLayout.ONLINE,
            baseDirectoryPath: stripLastPathSeparatorIfExists(fileSystemItem.location),
            branchId: extensionAPI.siteAPI.getBranchId()
        }
        const response = await wixHttpClientInstance.request(veloIdeListFiles(request)).then(res => res.data)

        const files = response.files.map(file => getFileSystemEntry(file, false))
        const directories = response.directories.map(dir => getFileSystemEntry(dir, true))

        return [...files, ...directories]
    }
    const getFileSystemEntry = (file: File | Directory, isDirectory: boolean) => {
        const descriptor = getVirtualDescriptor(file.path, isDirectory)
        return {
            name: descriptor.name,
            directory: descriptor.directory,
            location: descriptor.location,
            content: !isDirectory ? (file as File).content : undefined
        }
    }

    const readAllFiles = async () => {
        const listFilesRequest: ListFilesRequest = {
            gridAppId,
            layout: GridAppLayout.ONLINE,
            hideDeletedPages: true,
            includeNewPages: false,
            branchId: extensionAPI.siteAPI.getBranchId()
        }
        const response = await wixHttpClientInstance.request(veloIdeListFiles(listFilesRequest))

        return [
            ...response.data.files.map(file => ({path: file.path, content: file.content})),
            ...response.data.directories.map(dir => ({path: dir.path}))
        ]
    }

    const bulkWrite = async (files: WixCodeFile[]) => {
        await updateGridApp({filesToUpdate: files.map(file => ({path: file.fileId, content: file.content}))})
    }

    return {
        writeFile,
        deleteItem,
        createFolder,
        copy,
        move,
        getChildren,
        readAllFiles,
        readFile,
        bulkWrite
    }
}

export {createService}
