import experiment from 'experiment-amd'
import type {Pointer, PS} from '@wix/document-services-types'
import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'
import popupUtils from '../../page/popupUtils'
import dataModel from '../../dataModel/dataModel'

const COLUMN_COMPONENT_TYPE = 'wysiwyg.viewer.components.Column'
const CLASSIC_SECTION_COMPONENT_TYPE = 'wysiwyg.viewer.components.ClassicSection'
const ALLOWED_CONTAINER_TYPES = [COLUMN_COMPONENT_TYPE, CLASSIC_SECTION_COMPONENT_TYPE]

function isContainable(ps: PS, compPointer?: Pointer, containerPointer?: Pointer) {
    if (!containerPointer) {
        return false
    }

    const potentialContainerCompType = metaDataUtils.getComponentType(ps, containerPointer)
    const pagePointer = ps.siteAPI.getFocusedRootId()

    const isPopupContainer =
        popupUtils.isPopup(ps, pagePointer) && popupUtils.isPopupContainer(potentialContainerCompType)

    return (
        ALLOWED_CONTAINER_TYPES.includes(potentialContainerCompType) ||
        isPopupContainer ||
        metaDataUtils.isSiteSegmentContainer(potentialContainerCompType)
    )
}

export default {
    minimalChildrenNumber: 1,
    canContain(ps: PS, compPointer, potentialChildCompPtr) {
        const childType = metaDataUtils.getComponentType(ps, potentialChildCompPtr)
        return childType === COLUMN_COMPONENT_TYPE
    },
    canContainByStructure(ps: PS, compPointer, potentialChildCompStructure) {
        return potentialChildCompStructure.componentType === COLUMN_COMPONENT_TYPE
    },
    containable: isContainable,
    containableByStructure: (ps: PS, comp: unknown, containerPointer: Pointer) => {
        if (experiment.isOpen('dm_allowAddingStripForNonFullWidthPopup')) {
            return true
        }
        return metaDataUtils.containableByFullWidthPopup(ps, comp, containerPointer)
    },
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
    resizableSides(ps: PS, compPtr) {
        const isSingleColumnStrip = ps.pointers.components.getChildren(compPtr).length === 1
        if (ps.pointers.components.isMobile(compPtr) && !isSingleColumnStrip) {
            return []
        }

        return [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM]
    },
    fullWidth(ps: PS, compPointer) {
        const propertiesItem = dataModel.getPropertiesItem(ps, compPointer)
        return propertiesItem.fullWidth
    },
    fullWidthByStructure(ps, compStructure) {
        return compStructure.props.fullWidth
    },
    canBeFixedPosition: false,
    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        hideWhenEmptyBackgroundContainer: true,
        filterChildrenWhenHidden: true,
        isScreenWidth: true,
        marginX: 0,
        category: 'columns'
    }
}
