import type {Pointer, PS} from '@wix/document-services-types'
import socialComp from './utils/socialComp'
import type {ComponentAfterAddDataFromExtEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

export const upgradePinterestPinUrlOnAddition = (ps: PS, compPointer: Pointer) => {
    return socialComp.upgradeUrl(ps, compPointer, 'pinterest')
}
export const upgradePinterestPinUrlOnAdditionFromExt = (ps: PS, data: ComponentAfterAddDataFromExtEvent) => {
    const {compToAddPointer} = data
    upgradePinterestPinUrlOnAddition(ps, compToAddPointer)
}
