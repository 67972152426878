import {
    DAL,
    CoreLogger,
    DalItem,
    DalJsNamespace,
    DalJsStore,
    DalValue,
    isConflicting,
    NullUndefined
} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {Experiment, ResolvedReference, TagsAndExtras} from '@wix/document-services-types'
import _ from 'lodash'
import {MOBILE_ONLY_COMPS, BASED_ON_SIGNATURE_NS, VIEW_MODES} from '../../constants/constants'
import {isPage} from '../../utils/structureUtils'
import type {SchemaAPI} from '../schema/schema'

const DATA_REF_VALIDATION_WHITE_LIST = ['dataQuery', 'propertyQuery', 'connectionQuery']
const SLIDE_SHOW_TYPES = ['wysiwyg.viewer.components.StripContainerSlideShow', 'wysiwyg.viewer.components.BoxSlideShow']

const errors = {
    DUPLICATE_ID: 'DUPLICATE_ID',
    MISSING_DATA_REF: 'MISSING_DATA_REF',
    UNREFERENCED_PAGE_DATA: 'UNREFERENCED_PAGE_DATA',
    SLIDESHOW_WITHOUT_CHILDREN: 'SLIDESHOW_WITHOUT_CHILDREN',
    PAGE_WITH_BAD_STRUCTURE: 'PAGE_WITH_BAD_STRUCTURE',
    DUPLICATE_PAGE_URI: 'DUPLICATE_PAGE_URI',
    CONFLICTING_TRANSACTION: 'CONFLICTING_TRANSACTION'
}

export class ValidationError extends ReportableError {
    constructor(
        public readonly rule: string,
        message: string,
        public readonly namespace: string,
        public readonly id: string,
        public readonly value?: DalItem,
        public readonly extras?: Record<string, any>
    ) {
        super({message, errorType: 'csaveValidationError'})
        Object.setPrototypeOf(this, ValidationError.prototype)
    }
}

export function tagsFromError(e: Error): TagsAndExtras {
    let tags: Record<string, any> = {csave: true, csaveOp: 'validationFailed'}
    if (e instanceof ValidationError) {
        tags = _.merge(tags, _.pick(e, ['namespace', 'id', 'rule', 'extras']))
        return {tags, extras: {...(tags.extras || {}), value: e.value as any}}
    }
    return {tags, fingerprint: ['CSaveValidationError', 'GENERAL']}
}

const REF_NS = [VIEW_MODES.DESKTOP, VIEW_MODES.MOBILE] as string[]
const validateDataItemReferences = (
    store: DalJsStore,
    namespace: string,
    compId: string,
    componentOrDataItem: DalItem | undefined,
    schemaAPI: SchemaAPI
) => {
    if (!componentOrDataItem?.type) {
        return
    }
    const references = schemaAPI.getReferences(namespace, componentOrDataItem)

    const invalid = _(references)
        .filter(
            (ref: ResolvedReference) =>
                !REF_NS.includes(namespace) || DATA_REF_VALIDATION_WHITE_LIST.includes(ref.refInfo.path?.join('.'))
        )
        .filter('refInfo.shouldValidate')
        .find(({id, referencedMap}: ResolvedReference) => !!id && !_.get(store, [referencedMap, id]))

    if (invalid) {
        const extras: any = {}
        if (namespace === VIEW_MODES.DESKTOP || namespace === VIEW_MODES.MOBILE) {
            extras.fromComponent = componentOrDataItem.componentType
        }
        extras.fromType = componentOrDataItem.type
        extras.path = invalid.refInfo.jsonPointer
        throw new ValidationError(
            errors.MISSING_DATA_REF,
            `document has missing data refs ${invalid.id}`,
            namespace,
            compId,
            componentOrDataItem,
            extras
        )
    }
}

const validateMenuData = (store: DalJsStore, schemaAPI: SchemaAPI) => {
    const {data} = store
    if (data.CUSTOM_MENUS?.id) {
        validateDataItemReferences(store, 'data', data.CUSTOM_MENUS.id, data.CUSTOM_MENUS, schemaAPI)
    }
}

const collectDescendentsFromFlat = (
    structure: DalItem,
    id: string,
    ns: string,
    compsInTree: string[] = []
): string[] => {
    if (!(id in structure)) {
        throw new ValidationError(errors.MISSING_DATA_REF, `document has missing data refs ${id}`, ns, id)
    }
    compsInTree.push(id)
    const childrenIds = _.get(structure, [id, 'components'])
    _.forEach(childrenIds, (childId: string) => collectDescendentsFromFlat(structure, childId, ns, compsInTree))
    return compsInTree
}

const validateMobileOnlyComps = (store: DalJsStore, schemaAPI: SchemaAPI) => {
    _(store.MOBILE)
        .pick(_.keys(MOBILE_ONLY_COMPS))
        .flatMap((comp, id) => collectDescendentsFromFlat(store.MOBILE, id, VIEW_MODES.MOBILE))
        .uniq()
        .forEach((id: string) => {
            validateDataItemReferences(store, VIEW_MODES.MOBILE, id, store.MOBILE[id], schemaAPI)
        })
}

function validateDuplicateChildIdsInNamespace(allItems: DalJsNamespace, namespace: string) {
    const duplicateId = _(allItems)
        .flatMap((item: DalItem) => item?.components)
        .compact()
        .countBy()
        .pickBy(count => count > 1)
        .keys()
        .head()

    if (duplicateId) {
        const parents = _(allItems)
            .filter((item: DalItem) => item?.components?.includes(duplicateId))
            .map((item: DalItem) => ({id: item.id, componentType: item.componentType ?? ''}))
            .value()

        throw new ValidationError(
            errors.DUPLICATE_ID,
            `duplicate ids after transaction ${duplicateId}`,
            namespace,
            duplicateId,
            allItems[duplicateId],
            {
                compId: duplicateId,
                parents
            }
        )
    }
}

function validateUniqueChildrenId(store: DalJsStore): void {
    validateDuplicateChildIdsInNamespace(store.DESKTOP, VIEW_MODES.DESKTOP)
    validateDuplicateChildIdsInNamespace(store.MOBILE, VIEW_MODES.MOBILE)
}

/**
 * Applies each value in the transaction into the store. This mutates the store for the sake of performance.
 *
 * @param store
 * @param transactionStore
 */
const applyTransactionOnStore = (store: DalJsStore, transactionStore: DalJsStore): DalJsStore => {
    _.forEach(transactionStore, (namespaceStore: DalJsNamespace, namespace: string) => {
        _.forEach(namespaceStore, (value: DalValue, id: string) => {
            _.setWith(store, [namespace, id], value, Object)
        })
    })

    return store
}

interface Context {
    store: DalJsStore
    transactionStore: DalJsStore
    schemaAPI: SchemaAPI
}

type Check = (context: Context, namespace: string, id: string, value?: DalItem) => void

const ShouldHaveId: Check = (context: Context, namespace: string, id: string, value?: DalItem): void => {
    if (value && value.type === 'Component' && !value.id) {
        throw new ValidationError(
            errors.PAGE_WITH_BAD_STRUCTURE,
            'Transaction tried to insert a corrupted structure',
            namespace,
            id,
            value
        )
    }
}

const NoPhantomPages: Check = ({store}: Context, namespace: string, id: string, value?: DalItem): void => {
    if (namespace === 'data' && isPage(value) && !store.DESKTOP[id]) {
        throw new ValidationError(
            errors.UNREFERENCED_PAGE_DATA,
            `document with page data ${id} was missing pageId`,
            namespace,
            id,
            value
        )
    }
}

const SlideShowCompHasChildren: Check = (context: Context, namespace: string, id: string, value?: DalItem): void => {
    const componentType = value?.componentType
    if (
        value &&
        namespace === VIEW_MODES.DESKTOP &&
        SLIDE_SHOW_TYPES.includes(componentType) &&
        !_.get(value, ['components', 'length'])
    ) {
        throw new ValidationError(
            errors.SLIDESHOW_WITHOUT_CHILDREN,
            `document with slideshow id: ${value.id} has no children`,
            namespace,
            id,
            value
        )
    }
}

const ValidDataRef: Check = ({store, schemaAPI}: Context, namespace: string, id: string, value?: DalItem): void => {
    const isMobileWithCounterpart = namespace === VIEW_MODES.MOBILE && id in store.DESKTOP
    const REFS_NS = ['data', 'design', 'props', 'style', VIEW_MODES.DESKTOP]
    if (REFS_NS.includes(namespace) || isMobileWithCounterpart) {
        validateDataItemReferences(store, namespace, id, value, schemaAPI)
    }
}

const rules: Check[] = [ShouldHaveId, SlideShowCompHasChildren, ValidDataRef, NoPhantomPages]

const checkRules = (store: DalJsStore, transactionStore: DalJsStore, schemaAPI: SchemaAPI) => {
    _.forEach(transactionStore, (namespaceStore: DalJsNamespace, namespace: string) => {
        _.forEach(namespaceStore, (value: DalItem | undefined, id: string) => {
            rules.forEach(rule => {
                rule({store, transactionStore, schemaAPI}, namespace, id, value)
            })
        })
    })
}

const validateSignatures = (
    dal: DAL,
    store: DalJsStore,
    transactionStore: DalJsStore,
    logger: CoreLogger,
    experimentInstance: Experiment
) => {
    const shouldFail = experimentInstance.isOpen('dm_failCsaveInitConflict')
    const shouldReport = shouldFail || experimentInstance.isOpen('dm_reportCsaveInitConflict')
    _(transactionStore)
        .omit([BASED_ON_SIGNATURE_NS])
        .forEach((namespaceStore: DalJsNamespace, namespace: string) => {
            _.forEach(namespaceStore, (value: DalItem | undefined, id: string) => {
                const revisionValue = _.get(store, [namespace, id])
                const basedOnSignature = _.get(transactionStore, [
                    BASED_ON_SIGNATURE_NS,
                    dal.getBasedOnSignatureId(namespace, id)
                ]) as NullUndefined<string>
                if (isConflicting(value, revisionValue, basedOnSignature)) {
                    const extras = {namespace, id, revisionValue, value}
                    const error = new ValidationError(
                        errors.CONFLICTING_TRANSACTION,
                        'getDocument transaction is conflicting with revision',
                        namespace,
                        id,
                        value,
                        extras
                    )
                    if (shouldReport) {
                        logger.captureError(error)
                    }

                    if (shouldFail) {
                        throw error
                    }
                }
            })
        })
}

export const validateCSaveTransaction = (
    dal: DAL,
    store: DalJsStore,
    transactionStore: DalJsStore,
    schemaAPI: SchemaAPI,
    logger: CoreLogger,
    experimentInstance: Experiment
) => {
    validateSignatures(dal, store, transactionStore, logger, experimentInstance)
    const mergedStore = applyTransactionOnStore(store, transactionStore)
    validateUniqueChildrenId(mergedStore)
    validateMenuData(mergedStore, schemaAPI)
    validateMobileOnlyComps(mergedStore, schemaAPI)
    checkRules(mergedStore, transactionStore, schemaAPI)
}
