import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer, SerializedCompStructure, CompRef, CompStructure} from '@wix/document-services-types'
import _ from 'lodash'
import {DATA_TYPES, COMP_TYPES} from '../../../constants/constants'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import {updateConnectionsIfNeeded, updateNicknameContextByNewContainer} from './utils/nickname'
import {getComponentType} from '../../../utils/dalUtils'
import type {StructureExtensionAPI} from '../../structure'
import type {ComponentBeforeAddDataEvent} from '../hooks'

const getValidConnectTargets = (
    createExtArgs: CreateExtArgs,
    isPage: boolean,
    compId: string,
    containerRef: Pointer
) => {
    const {pointers, extensionAPI, dal} = createExtArgs
    const {components} = extensionAPI as StructureExtensionAPI
    const pageId = isPage ? compId : pointers.structure.getPageOfComponent(containerRef).id
    const controllers = pointers.structure.getComponentsByTypeForPage(COMP_TYPES.CONTROLLER_TYPE, pageId)
    const widgets = _.filter(
        _.compact([containerRef, ...components.getAncestors(containerRef, true)]),
        ancestorRef => getComponentType(dal, ancestorRef) === COMP_TYPES.APP_WIDGET_TYPE
    )
    return [...controllers, ...widgets]
}
const isValidConnection = (
    {extensionAPI}: CreateExtArgs,
    validConnectTargets: any,
    oldToNewIdMap: any,
    connection: any
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const controllerDataId = _.get(connection, ['controllerId'])
    return _(validConnectTargets)
        .map(controllerRef => _.get(dataModel.components.getItem(controllerRef, DATA_TYPES.data), ['id']))
        .some(dataItemId => controllerDataId === dataItemId || dataItemId === oldToNewIdMap[controllerDataId])
}
const updateControllerIds = (oldToNewIdMap: any, compConnections: any) => {
    return _.map(compConnections, connection => {
        const currentControllerId = _.get(connection, ['controllerId'])
        if (oldToNewIdMap[currentControllerId]) {
            return _.assign({}, connection, {controllerId: oldToNewIdMap[currentControllerId]})
        }
        return connection
    })
}
const getUpdatedConnections = (
    createExtArgs: CreateExtArgs,
    validConnectTargets: Pointer[],
    componentConnections: any[],
    oldToNewIdMap: any
) => {
    return _(componentConnections)
        .filter(connection => isValidConnection(createExtArgs, validConnectTargets, oldToNewIdMap, connection))
        .thru(_.partial(updateControllerIds, oldToNewIdMap))
        .value()
}
const getUpdatedSerializedConnections = (
    createExtArgs: CreateExtArgs,
    containerRef: CompRef,
    compId: string,
    componentConnections: any,
    isPage: boolean,
    oldToNewIdMap = {}
) => {
    const nonWixCodeConnections = _.reject(componentConnections, {type: 'WixCodeConnectionItem'})
    if (_.isEmpty(nonWixCodeConnections)) {
        return
    }
    const validConnectTargets = getValidConnectTargets(createExtArgs, isPage, compId, containerRef)
    const newConnections = getUpdatedConnections(
        createExtArgs,
        validConnectTargets,
        nonWixCodeConnections,
        oldToNewIdMap
    )
    const wixCodeConnections = _.difference(componentConnections, nonWixCodeConnections)
    return wixCodeConnections.concat(newConnections)
}
export const updateSerializedConnectionsData = (
    createExtArgs: CreateExtArgs,
    componentRef: CompRef,
    containerRef: CompRef,
    compDefinition: CompStructure,
    isPage: boolean,
    oldToNewIdMap = {}
) => {
    updateConnectionsIfNeeded(createExtArgs, componentRef, containerRef, compDefinition as SerializedCompStructure)
    updateNicknameContextByNewContainer(
        createExtArgs,
        componentRef,
        compDefinition as SerializedCompStructure,
        containerRef
    )

    const componentConnections = _.get(compDefinition, ['connections', 'items'])
    const updatedConnections = getUpdatedSerializedConnections(
        createExtArgs,
        containerRef,
        compDefinition.id!,
        componentConnections,
        isPage,
        oldToNewIdMap
    )
    if (!_.isNil(updatedConnections)) {
        _.set(compDefinition, ['connections', 'items'], updatedConnections)
    }
}

export const updateSerializedConnectionsDataForComponents = (
    createExtArgs: CreateExtArgs,
    data: ComponentBeforeAddDataEvent
) => {
    const {compToAddPointer, containerPointer, componentDefinition, mappers, isPage} = data
    if (isPage || !componentDefinition.connections) return

    updateSerializedConnectionsData(
        createExtArgs,
        compToAddPointer as CompRef,
        containerPointer as CompRef,
        componentDefinition,
        isPage,
        mappers?.oldToNewIdMap
    )
}
