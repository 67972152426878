import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import * as constants from '../../../../constants/constants'
import {runOnPageComponents} from './utils/migrationUtil'
import type {PageMigrator} from '../dataMigrationRunner'
import {stripHashIfExists} from '../../../../utils/refArrayUtils'

const cleanBrokenQuery = (
    documentManager: DocumentManager,
    pageId: string,
    pointer: Pointer,
    queryProp: string,
    targetNamespace: string
) => {
    const queryPointer = documentManager.pointers.getInnerPointer(pointer, queryProp)
    const query = documentManager.dal.get(queryPointer)
    if (query) {
        const itemId = stripHashIfExists(query)
        const itemPointer = documentManager.pointers.data.getItem(targetNamespace, itemId, pageId)
        if (!documentManager.dal.get(itemPointer)) {
            documentManager.dal.remove(queryPointer)
            documentManager.logger.interactionStarted('cleanBrokenQuery', {
                extras: {
                    itemPointer,
                    queryPointer,
                    pageId,
                    targetNamespace
                }
            })
        }
    }
}

const cleanComponent = (documentManager: DocumentManager, pageId: string, pointer: Pointer) => {
    cleanBrokenQuery(documentManager, pageId, pointer, 'mobileHintsQuery', constants.DATA_TYPES.mobileHints)
    cleanBrokenQuery(documentManager, pageId, pointer, 'propertyQuery', constants.DATA_TYPES.prop)
    cleanBrokenQuery(documentManager, pageId, pointer, 'behaviorQuery', constants.DATA_TYPES.behaviors)
    cleanBrokenQuery(documentManager, pageId, pointer, 'transformationQuery', constants.DATA_TYPES.transformations)
    cleanBrokenQuery(documentManager, pageId, pointer, 'transitionQuery', constants.DATA_TYPES.transitions)
    cleanBrokenQuery(documentManager, pageId, pointer, 'contentRoleQuery', constants.DATA_TYPES.contentRole)
    cleanBrokenQuery(documentManager, pageId, pointer, 'namingQuery', constants.DATA_TYPES.naming)
    cleanBrokenQuery(documentManager, pageId, pointer, 'designQuery', constants.DATA_TYPES.design)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    if (documentManager.experimentInstance.isOpen(`dm_brokenReferenceFixer`)) {
        // handled in brokenReferenceFixer
        return
    }

    _.forEach(constants.VIEW_MODES, viewMode => {
        runOnPageComponents(documentManager, pageId, viewMode, cleanComponent)
    })
}

const name = 'removeBrokenQueries'
const version = 3
const experimentalVersions = [{experiment: 'dm_brokenReferenceFixer', version: 4}]

export const removeBrokenQueries: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions,
    fixerRequiresReruns: true
}
