import type {CreateExtArgs} from '@wix/document-manager-core'
import {
    isComponentSuitableForNonRenderingState,
    isComponentSuitableForNonRenderingStateByPointer
} from '../utils/componentUtils'
import type {CompStructure, Pointer} from '@wix/document-services-types'

const STATE_STRIP = 'wysiwyg.viewer.components.StateStrip'

export const stateStripStateMetaData = {
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: false,
    canContain: ({dal}: CreateExtArgs, componentPointer: Pointer, potentialContainee: Pointer) =>
        isComponentSuitableForNonRenderingStateByPointer(dal, potentialContainee),
    fullWidthByStructure: true,
    fullWidth: true,
    allowedParentTypes: [STATE_STRIP],
    canContainByStructure: ({}: CreateExtArgs, componentPointer: Pointer, potentialContaineeStructure: CompStructure) =>
        isComponentSuitableForNonRenderingState(potentialContaineeStructure.componentType)
}
