import type {Builder} from '@wix/document-services-types'
export type Schema = any

const styleTypeSchemas: Record<string, Schema> = {
    borderRadius: {
        type: 'object',
        additionalProperties: false,
        properties: {
            value: {type: 'number'},
            unit: {
                enum: ['px', '%']
            }
        }
    }
}

export function getStyleTypeSchema(typeName?: Builder.StyleType): Schema {
    // We don't have the types yet, so we return a schema that accepts anything for now
    return (typeName && styleTypeSchemas[typeName]) ?? true
}
