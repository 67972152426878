import type {
    ClientSpecMap,
    PlatformAppsExperiments,
    SiteInfo,
    SitePropertiesInfo,
    WixCodeModel,
    PagesPlatformApplications,
    RoutersMap,
    SiteMetaData,
    RenderFlags,
    SiteMapLink,
    Size,
    Component,
    Rect
} from '@wix/document-services-types'
import _ from 'lodash'
import {
    PAGE_PROPS,
    PAGE_DATA,
    PAGE_STRUCTURE,
    RENDERER_MODEL,
    DOCUMENT_SERVICES_MODEL,
    DEFAULT_PLATFORM_DS_MODEL,
    ML_SEPARTOR,
    RENDER_FLAGS_DEFAULT,
    MODES_DEFAULT,
    PAGES_DEFAULT,
    MOBILE_PAGE_STRUCTURE,
    DISPLAYED_CONNECTION_OVERRIDE,
    GHOST_REF_COMPS,
    REMOTE_DATA_REF,
    DEFAULT_MOCK_HOST,
    REPEATER_DELIMITER,
    REF_DELIMITER
} from './constants'
import type {
    RuntimeDal,
    ViewerAPI,
    CompsWithOpacity,
    SiteMeasureMap,
    Callback,
    CurrentUrl,
    ComponentsWaitingForFonts,
    ModalCompData,
    PopupsCompData,
    RootNavigationInfo,
    ComponentBoundingBox,
    ComponentPadding,
    PagesViewerAPI,
    LayoutCallback,
    LayoutDoneCbArgs
} from '@wix/viewer-manager-interface'

export const MOCKED_VIEWER_NAME = 'mock'
export const MOCKED_VIEWER_VERSION = '0.0.1'

const getMainPageId = (store: any) => _.get(store, ['data', 'document_data', 'masterPage', 'mainPageId'])
const byRefStructure = {
    DESKTOP: {},
    MOBILE: {}
}

export const getDefaultViewerStore = (overrides: any = {}, {defaultPageId, isMobileView}: any = {}) => {
    const pageId = defaultPageId ?? 'c1dmp'
    const currentStoreOverrides = _.cloneDeep(overrides)
    _.defaultsDeep(byRefStructure, overrides?.byRef?.structure, {
        DESKTOP: {
            [pageId]: _.cloneDeep(PAGE_STRUCTURE)
        },
        MOBILE: {
            [pageId]: _.cloneDeep(MOBILE_PAGE_STRUCTURE)
        }
    })
    delete currentStoreOverrides?.byRef?.structure

    return _.defaultsDeep(currentStoreOverrides, {
        structure: {
            [pageId]: _.cloneDeep(PAGE_STRUCTURE)
        },
        data: {
            slots: {},
            anchors_data: {},
            behaviors_data: {},
            breakpoints_data: {},
            component_properties: {
                [pageId]: _.cloneDeep(PAGE_PROPS)
            },
            connections_data: {},
            design_data: {},
            document_data: {
                [pageId]: _.cloneDeep(PAGE_DATA)
            },
            layout_data: {},
            mobile_hints: {},
            theme_data: {},
            transformations_data: {},
            transitions_data: {},
            variants_data: {}
        },
        rendererModel: _.cloneDeep(RENDERER_MODEL),
        documentServicesModel: _.cloneDeep(DOCUMENT_SERVICES_MODEL),
        platform: {
            platform: _.cloneDeep(DEFAULT_PLATFORM_DS_MODEL)
        },
        translations: {},
        wixCode: {
            wixCode: {}
        },
        svgShapes: {},
        renderFlags: _.cloneDeep(RENDER_FLAGS_DEFAULT),
        activeVariants: {},
        realTimeConfig: {},
        runtime: {runtimeOverallBorders: {}, wantedIsMobileView: isMobileView!},
        currentLanguage: '',
        ghostStructure: {},
        ghostControllers: {},
        activeModes: MODES_DEFAULT,
        customElements: {},
        repeaterToTemplateItem: {},
        layoutAdjustmentComp: {},
        pages: PAGES_DEFAULT,
        byRef: {
            data: {
                document_data: {
                    [pageId]: _.cloneDeep(PAGE_DATA)
                },
                component_properties: {
                    [pageId]: _.cloneDeep(PAGE_PROPS)
                }
            },
            structure: byRefStructure.DESKTOP,
            displayedConnections: DISPLAYED_CONNECTION_OVERRIDE,
            hiddenCompsGroupedByRefComponent: GHOST_REF_COMPS,
            internallyReferredComps: GHOST_REF_COMPS,
            remoteOverrides: REMOTE_DATA_REF
        }
    })
}

const getTranslationInfoFromKey = (key: string) => _.split(key, ML_SEPARTOR)

interface ViewerState {
    defaultPageId?: string
    measureMap: Partial<SiteMeasureMap>
    isMobileView: boolean
    query: Record<string, any>
    siteWidth: number
    siteHeight: number
    rootNavigationInfo: RootNavigationInfo
    currentPopupId: string
}

const emptyMeasureMap: Partial<SiteMeasureMap> = {
    absoluteLeft: {},
    absoluteTop: {},
    height: {},
    innerHeight: {},
    innerWidth: {},
    left: {},
    minHeight: {},
    minWidth: {},
    pageBottomByComponents: {},
    top: {},
    width: {},
    contentHeight: {},
    custom: {}
}

export interface TestPagesViewerAPI extends PagesViewerAPI {
    setCurrentPage(pageId: string): void
}

const getBaseViewerMock = (store: any, defaults: Partial<ViewerState> = {}): ViewerAPI => {
    const state = {
        pageId: defaults.defaultPageId,
        url: {query: defaults.query ?? {}, host: DEFAULT_MOCK_HOST} as CurrentUrl,
        measureMap: _.defaults(defaults.measureMap ?? {}, emptyMeasureMap),
        siteWidth: defaults.siteWidth ?? 980,
        siteHeight: defaults.siteHeight ?? 800,
        registeredLayoutCbs: [] as LayoutCallback[],
        rootNavigationInfo: defaults.rootNavigationInfo ?? {},
        currentPopupId: defaults.currentPopupId ?? ''
    }
    const getCurrentPageId = () => state.pageId ?? getMainPageId(store)
    const noAnimations = () => {
        throw new Error('animations are not supported under mock')
    }
    const get = (pth: string[] | string) => _.get(store, pth)
    const set = (pth: string[] | string, val: any) => _.set(store, pth, val)
    const unset = (pth: string[] | string) => _.unset(store, pth)

    const setMode = (compId: string, modeId: string, getNewValue: Function) => {
        const comp = get(['structure', compId])
        const defnitions = _.get(comp, ['modes', 'definitions'], [])
        const modeDefinition = defnitions.find((def: any) => def!.modeId === modeId)
        if (modeDefinition) {
            const newValue = getNewValue(modeDefinition)
            if (_.isNil(newValue)) {
                unset(['activeModes', comp.metaData.pageId, modeId])
            } else {
                set(['activeModes', comp.metaData.pageId, modeId], newValue)
            }
        }
    }

    const deactivateMode = (compId: string, modeId: string) => setMode(compId, modeId, () => undefined)
    const activateMode = (compId: string, modeId: string) => {
        const comp = get(['structure', compId])
        const activateModes = get(['activeModes', comp.metaData.pageId]) ?? []
        const defnitionsIds = _.get(comp, ['modes', 'definitions'], []) ?? []
        _.forEach(defnitionsIds, ({modeId: defModeId}) => {
            if (activateModes[defModeId]) {
                deactivateMode(compId, defModeId)
            }
        })
        setMode(compId, modeId, ({type}: any) => ({modeType: type, modeId}))
    }

    const switchModes = (compId: string, modeIdToDeactivate: string, modeIdToActivate: string) => {
        deactivateMode(compId, modeIdToDeactivate)
        activateMode(compId, modeIdToActivate)
    }

    const query = {
        isQaMode: () => false,
        getQueryParam: (name: string) => state.url.query[name],
        setQueryParamResult: (name: string, value: any) => {
            state.url.query[name] = value
        }
    }

    const pages: TestPagesViewerAPI = {
        getViewerLoadedPagesIds: () => {
            const loadedPages = {
                [getMainPageId(store)]: true
            }
            if (state.pageId) {
                loadedPages[state.pageId] = true
            }
            return loadedPages
        },
        getFocusedRootId: () => getCurrentPageId(),
        getCurrentUrlPageId: () => getCurrentPageId(),
        getPrimaryPageId: () => getCurrentPageId(),
        getCurrentPopupId: () => state.currentPopupId,
        setCurrentPage(pageId: string): void {
            state.pageId = pageId
        },
        isPopupOpened: () => false,
        getAllRenderedRootIds: () => {
            const renderedRoots = [getCurrentPageId(), 'masterPage']
            if (state.currentPopupId) {
                renderedRoots.push(state.currentPopupId)
            }
            return renderedRoots
        }
    }

    const rendererModel = {
        updateClientSpecMap: (clientSpecMap: ClientSpecMap) => set(['rendererModel', 'clientSpecMap'], clientSpecMap),
        updatePlatformAppsExperiments: (platformAppsExperiments: PlatformAppsExperiments) =>
            set(['rendererModel', 'platformAppsExperiments'], platformAppsExperiments),
        updateBlocksExperiments: (blocksExperiments: Record<string, string>) =>
            set(['rendererModel', 'blocksExperiments'], blocksExperiments),
        getClientSpecMap: () => get(['rendererModel', 'clientSpecMap']),
        updateWixCodeModel: (wixCodeModel: WixCodeModel) => set(['rendererModel', 'wixCodeModel'], wixCodeModel),
        getWixCodeModel: () => get(['rendererModel', 'wixCodeModel']),
        updatePagesPlatformApplications: (pagesPlatformApplications: PagesPlatformApplications) =>
            set(['rendererModel', 'pagesPlatformApplications'], pagesPlatformApplications),
        getPagesPlatformApplications: () => get(['rendererModel', 'pagesPlatformApplications']),
        updatePasswordProtectedPages: (passwordProtectedPages: string[]) =>
            set(['rendererModel', 'passwordProtectedPages'], passwordProtectedPages),
        getPasswordProtectedPages: () => get(['rendererModel', 'passwordProtectedPages']),
        updateSitePropertiesInfo: (sitePropertiesInfo: SitePropertiesInfo) =>
            set(['rendererModel', 'sitePropertiesInfo'], sitePropertiesInfo),
        getSitePropertiesInfo: () => get(['rendererModel', 'sitePropertiesInfo']),
        updateMetaSiteId: (metaSiteId: string) => set(['rendererModel', 'metaSiteId'], metaSiteId),
        getMetaSiteId: () => get(['rendererModel', 'metaSiteId']),
        updatePremiumFeatures: (premiumFeatures: string[]) =>
            set(['rendererModel', 'premiumFeatures'], premiumFeatures),
        getPremiumFeatures: () => get(['rendererModel', 'premiumFeatures']),
        updateRouters: (routers: RoutersMap) => set(['rendererModel', 'routers'], routers),
        getRouters: () => get(['rendererModel', 'routers']),
        updateSiteInfo: (siteInfo: SiteInfo) => set(['rendererModel', 'siteInfo'], siteInfo),
        getSiteInfo: () => get(['rendererModel', 'siteInfo']),
        updateSiteMetaData: (siteMetaData: SiteMetaData) => set(['rendererModel', 'siteMetaData'], siteMetaData),
        updateUrlMappings: (urlMappings: string) => set(['rendererModel', 'setUrlMappings'], urlMappings),
        getUrlMappings: () => get(['rendererModel', 'urlMappings']),
        getSiteMetaData: () => get(['rendererModel', 'siteMetaData'])
    }

    const getStructure = (id: string) => get(['structure', id])

    const getOriginalId = (id: string) => id.split(REF_DELIMITER).at(-1)!.split(REPEATER_DELIMITER, 1)[0]

    const ROOT_TYPES = {Document: true, Page: true, AppPage: true}

    const scopes = {
        getScope: (id: string): string[] => {
            const scopesList: string[] = []
            const [, ...repeaterItems] = id.split(REPEATER_DELIMITER)
            let repeaterItemIndex = 0
            let current: Component = getStructure(id)

            if (!current) {
                return scopesList
            }

            while (!ROOT_TYPES[current.type]) {
                const parent = getStructure(current.parent!)

                if (parent.type === 'RepeaterContainer') {
                    scopesList.push(
                        `${getOriginalId(parent.id)}${REPEATER_DELIMITER}${repeaterItems[repeaterItemIndex]}`
                    )
                    repeaterItemIndex++
                }

                if (parent.type === 'RefComponent') {
                    scopesList.push(getOriginalId(parent.id))
                }

                current = parent
            }

            return scopesList.reverse()
        }
    }

    const api = {
        actions: {
            runInBatch: (callback: Callback) => callback()
        },
        miniSites: {
            getViewerFragment: async (hostReact: any) => hostReact.createElement('div')
        },
        responsive: {
            getScrollHeight: () => null,
            getClientHeight: () => null,
            getScrollWidth: () => null,
            getClientWidth: () => null,
            getGridMeasures: () => ({}),
            detach: _.noop,
            detachMulti: _.noop,
            detachContainer: _.noop,
            updateDetached: _.noop,
            updateDetachedStyles: _.noop,
            updateDetachedRotation: _.noop,
            updateDetachedTransformation: _.noop,
            clearDetached: _.noop
        },
        actionsAndBehaviors: {
            resetBehaviorsRegistration: _.noop,
            executeAction: _.noop,
            enableAction: _.noop,
            disableAction: _.noop,
            handleBehavior: _.noop
        },
        data: {
            getData: (type: string, id: string) => get(['data', type, id]),
            updateData: (type: string, id: string, value: any) => set(['data', type, id], value),
            updateTranslationDataItem: (id: string, value: any) => {
                const [lang, itemId] = getTranslationInfoFromKey(id)
                set(['translations', lang, 'data', 'document_data', itemId], value)
            },
            getTranslationDataItem: (id: string) => {
                const [lang, itemId] = getTranslationInfoFromKey(id)
                return get(['translations', lang, 'data', 'document_data', itemId])
            },
            getCompIdByDataQueryId: _.noop
        },
        scopes,
        structure: {
            getStructure,
            updateStructure: (id: string, value: any) => set(['structure', id], value)
        },
        byRef: {
            getData: (dataType: string, id: string) => get(['byRef', 'data', dataType, id]),
            getStructure: (id: string) => get(['byRef', 'structure', id]),
            getRemoteOverrides: (innerRefId: string) => get(['byRef', 'remoteOverrides', innerRefId]),
            getDisplayedConnectionOverrides: (innerRefId: string) => get(['byRef', 'displayedConnections', innerRefId]),
            getInternallyReferredComps: (compId: string) => get(['byRef', 'internallyReferredComps', compId]),
            getHiddenCompsGroupedByRefComponent: (compId: string) =>
                get(['byRef', 'hiddenCompsGroupedByRefComponent', compId]) || []
        },
        rendererModel,
        platformDsModel: {
            getPlatformDsModel: (id: string) => get(['platform', id]),
            updatePlatformDsModel: (path: string, value: any) => set(['platform', path], value)
        },
        documentServicesModel: {
            updateIsPublished: (isPublished: boolean) => set(['documentServicesModel', 'isPublished'], isPublished),
            getIsPublished: () => get(['documentServicesModel', 'isPublished']),
            updatePublicUrl: (publicUrl: string) => set(['documentServicesModel', 'publicUrl'], publicUrl),
            getPublicUrl: () => get(['documentServicesModel', 'publicUrl']),
            updateRevision: (revision: number) => set(['documentServicesModel', 'revision'], revision),
            getRevision: () => get(['documentServicesModel', 'revision']),
            updateSiteName: (siteName: string) => set(['documentServicesModel', 'siteName'], siteName),
            getSiteName: () => get(['documentServicesModel', 'siteName']),
            updateVersion: (version: number) => set(['documentServicesModel', 'version'], version),
            getVersion: () => get(['documentServicesModel', 'version']),
            updateCustomHeadTags: (customHeadTags: string) =>
                set(['documentServicesModel', 'customHeadTags'], customHeadTags),
            getCustomHeadTags: () => get(['documentServicesModel', 'customHeadTags'])
        },
        wixCode: {
            setWixCodeDsModel: (id: string, value: any) => set(['wixCode', id], value),
            getWixCodeDsModel: (id: string): any => get(['wixCode', id])
        },
        svg: {
            addSvgShape: (svgId: string, svgShape: any) => set(['svgShapes', svgId], svgShape),
            getSvgShape: (svgId: string): any => get(['svgShapes', svgId])
        },
        renderFlags: {
            setRenderFlag: (flagName: string, flagValue: string) => set(['renderFlags', flagName], flagValue),
            getRenderFlags: (): RenderFlags => get(['renderFlags'])
        },
        variants: {
            setVariantForComp: (compId: string, variantId: string) => set(['activeVariants', compId], variantId),
            getActiveVariant: (compId: string): string => get(['activeVariants', compId]),
            setCompsIgnoredTransformProps: _.noop,
            setEnableVariantsTransitionsInEditor: _.noop,
            setGlobalIgnoredTransformProps: _.noop,
            setTypeIgnoredTransformProps: _.noop
        },
        realTimeConfig: {
            setCompsToShowOnTop: (compIds: string[]) => set(['realTimeConfig', 'compsToShowOnTop'], compIds),
            setCompsToShowWithOpacity: (compsWithOpacity: CompsWithOpacity) =>
                set(['realTimeConfig', 'compsToShowWithOpacity'], compsWithOpacity),
            setHideTextComponent: (compId: string) => set(['realTimeConfig', 'hideTextComponent'], compId),
            setHiddenComp: (compId: string, viewMode: string, isHidden: boolean) =>
                set(['realTimeConfig', 'compsToHide', viewMode, compId].join('_'), isHidden),

            getCompsToShowOnTop: () => get(['realTimeConfig', 'compsToShowOnTop']),
            getCompsToShowWithOpacity: () => get(['realTimeConfig', 'compsToShowWithOpacity']),
            getHiddenTextComponent: () => get(['realTimeConfig', 'hideTextComponent']),
            getIsCompHidden: (compId: string, viewMode: string) =>
                get(['realTimeConfig', 'compsToHide', viewMode, compId].join('_'))
        },
        runtime: {
            refreshPage: () => {},
            getWantedNavInfo: () => get(['runtime', 'wantedNavInfo']),
            setWantedNavInfo: (navInfo: any) => {
                set(['runtime', 'wantedNavInfo'], navInfo)
                state.pageId = navInfo.pageId
            },
            getWantedIsMobileView: () => get(['runtime', 'wantedIsMobileView']),
            setWantedIsMobileView: (wantToMoveToMobile: boolean) => {
                set(['runtime', 'wantedIsMobileView'], wantToMoveToMobile)
                set(['byRef', 'structure'], wantToMoveToMobile ? byRefStructure.MOBILE : byRefStructure.DESKTOP)
            },
            setPagesModelsFetcher: () => {}
        },
        ghostables: {
            getGhostStructure: () => get(['ghostStructure']),
            setGhostStructure: (ghostStructure: any) => set(['ghostStructure'], ghostStructure),
            getGhostControllers: () => get(['ghostControllers']),
            setGhostControllers: (ghostControllers: any) => set(['ghostControllers'], ghostControllers)
        },
        multiLingual: {
            getCurrentLanguage: () => get(['currentLanguage']),
            setCurrentLanguage: (lang: string) => set(['currentLanguage'], lang)
        },
        modes: {
            getAllActiveModes: () => get(['activeModes']),
            getAllActiveModeIds: () =>
                _({})
                    .assign(..._.values(get(['activeModes'])))
                    .mapValues(value => value !== undefined)
                    .value(),
            updateActiveSOSPModes: _.noop,
            switchModes,
            activateMode,
            deactivateMode,
            resetAllActiveModes: _.noop
        },
        customElements: {
            getCacheKillerCounter: (fileId: string) => get(['customElements', 'cacheKillers', fileId]),
            getCacheKillerMap: () => get(['customElements', 'cacheKillers']),
            setCacheKillerCounter: (fileId: string, cacheKillerCounter: number) =>
                set(['customElements', 'cacheKillers', fileId], cacheKillerCounter),
            setCacheKillerMap: (value: Record<string, number>) => set(['customElements', 'cacheKillers'], value)
        },
        velo: {
            refreshFilesOrFolders: _.noop
        },
        displayedOnlyComponents: {
            setRepeaterToTemplateItem: (compId: string, templateItem: string) =>
                set(['repeaterToTemplateItem', compId], templateItem),
            getRepeaterToTemplateItem: (compId: string) => get(['repeaterToTemplateItem', compId])
        },
        layout: {
            registerToLayoutDone: (handler: LayoutCallback) => state.registeredLayoutCbs.push(handler),
            registerToNextLayoutDone: (handler: Callback) => _.defer(handler),
            registerToNextRenderDone: (handler: Callback) => _.defer(handler),
            registerToRenderDone: _.noop,
            registerToComponentsLayoutChange: (handler: LayoutCallback) => state.registeredLayoutCbs.push(handler),
            updateAndPushStart: _.noop,
            updateAndPushUpdate: _.noop,
            updateAndPushEnd: _.noop,
            isShowOnFixedPosition: _.noop,
            getSiteMeasureMap: () => state.measureMap ?? ({} as SiteMeasureMap),
            getBasicMeasureForComp: () => ({} as Rect),
            getBasicMeasureForCompWithoutTransform: () => ({} as Rect),
            getOriginalComponentBoundingBox: () => ({} as ComponentBoundingBox),
            getComponentBoundingBox: () => ({} as ComponentBoundingBox),
            getComponentInnerElementBoundingBoxes: () => [{}] as ComponentBoundingBox[],
            getContentArea: _.noop,
            getRelativeToViewportBoundingBox: () => ({} as ComponentBoundingBox),
            getPadding: () => ({} as ComponentPadding),
            getScreenSize: () => ({width: state.siteWidth, height: state.siteHeight} as Size),
            getSiteX: () => 60,
            getSiteWidth: () => state.siteWidth,
            getScreenHeight: () => 0,
            getScreenWidth: () => 0,
            isLayoutPending: () => false,
            getLayoutMechanism: () => 'mesh',
            simulateMockViewerLayout: (didLayoutArgs: LayoutDoneCbArgs) =>
                _.forEach(state.registeredLayoutCbs, cb => cb(didLayoutArgs))
        },
        runtimeLayout: {
            updateRuntimeLayout: _.noop,
            removeRuntimeLayout: _.noop,
            detach: _.noop,
            detachMulti: _.noop,
            updateDetached: _.noop,
            updateDetachedStyles: _.noop,
            clearDetached: _.noop,
            getRuntimeProperty: _.noop
        },
        pages,
        viewMode: {
            isMobileView: () => get(['runtime', 'wantedIsMobileView']) || false,
            setMobileView(v: boolean) {
                set(['runtime', 'wantedIsMobileView'], v)
            },
            isDuringViewModeSwitch: () => false,
            registerToSwitchViewModeDone: _.noop,
            registerToNextSwitchViewModeDone: _.noop
        },
        navigation: {
            getCurrentUrl: () => state.url,
            getRootNavigationInfo: () => state.rootNavigationInfo,
            setPreviewTooltipCallback: _.noop,
            registerToDynamicPagesNavigationError: _.noop,
            waitForNavigation: () => Promise.resolve()
        },
        animation: {
            getSiteAnimationsService: () => ({
                sequence: noAnimations,
                animate: noAnimations,
                updateViewMode: noAnimations
            }),
            reloadPageAnimations: _.noop,
            stopAndClearAllAnimations: _.noop,
            previewAnimation: () => '',
            previewTransition: () => '',
            stopPreviewAnimation: _.noop,
            updateScrubAnimationsPreview: _.noop
        },
        query,
        legacy: {
            reportBI: _.noop,
            updateBiData: _.noop,
            reportBIError: _.noop
        },
        viewerConfig: {
            viewerName: MOCKED_VIEWER_NAME,
            viewerVersion: MOCKED_VIEWER_VERSION
        },
        updateStatus: {
            waitForDataRequirements: async () => {},
            waitForViewer: async () => {}
        },
        component: {
            isComponentRenderedOnSite: () => true
        },
        componentDetector: {
            getElementsUnderXY: () => [] as Element[],
            getComponentsUnderXY: () => [] as string[]
        },
        fonts: {
            hasPendingFonts: () => false,
            getCompsWaitingForFonts: () => ({} as ComponentsWaitingForFonts)
        },
        livePreview: {
            refreshAppsInCurrentPage: _.noop,
            toggleLivePreviewEnabled: _.noop,
            getAllowedApps: () => [] as string[],
            isLivePreviewOpen: () => false
        },
        media: {
            registerStateChange: _.noop,
            unregisterStateChange: _.noop
        },
        members: {
            getMemberDetailsInPreview: _.noop,
            showLoginDialog: _.noop,
            showSignUpDialog: _.noop,
            hideAuthDialog: _.noop
        },
        platform: {
            getComponentsByPageIdForWixCode: () => ({} as Record<string, any>),
            getAppInstance: () => ({} as Record<string, string>),
            getRuntimeDal: () => ({} as RuntimeDal),
            reloadAppsContainer: _.noop,
            triggerAppStudioWidgetOnPropsChanged: _.noop,
            flushDsBeforeLoadMessagesQueue: _.noop,
            registerToAppInstanceUpdate: _.noop,
            registerToNotifyApplicationRequestFromViewerWorker: _.noop
        },
        scroll: {
            animateScroll: _.noop,
            isSiteScrollingBlocked: () => false,
            getTotalScroll: () => ({x: 0, y: 0}),
            getScroll: () => ({x: -10, y: 0}),
            scroll: _.noop,
            scrollBy: _.noop,
            scrollToComponent: _.noop,
            scrollToAnchor: _.noop,
            setOnComponentsScrollListener: _.noop,
            setScroll: _.noop,
            setScrollAndScale: _.noop
        },
        selectiveDownload: {
            downloadCompClasses: async () => {}
        },
        stylable: {
            getStylableEditorInstance: () => ({}),
            setQuickChanges: _.noop,
            revertQuickChanges: _.noop,
            forceState: _.noop,
            revertForceState: _.noop,
            mergeStylesheets: _.noop
        },
        tpa: {
            setCompState: _.noop,
            getTpaCompPreviewData: () => ({}),
            removeAllPopups: _.noop,
            removeModal: _.noop,
            setTpaCompPreviewData: _.noop,
            getModalCompData: () => ({} as ModalCompData),
            getPopupsCompData: () => ({} as PopupsCompData),
            getSiteMap: () => [] as SiteMapLink[],
            handleDsTpaHandler: _.noop
        },
        blocks: {
            setBlocksPreviewData: _.noop,
            getBlocksPreviewData: _.noop
        },
        customCSS: {
            refresh: _.noop
        },
        externalComponents: {
            getExternalComponentConsent: _.noop,
            setExternalComponentConsent: _.noop
        },
        pathBuilder: {
            buildPaths: _.noop
        },
        motion: _(['enable', 'disable', 'play', 'cancel', 'cancelAll', 'startScrub', 'updateScrub', 'cancelScrub'])
            .keyBy(name => name)
            .mapValues(() => _.noop)
            .value()
    }
    // @ts-ignore
    return api
}

export const getDefaultViewerMock = (store: any, overrides: any = {}, defaults = {}): ViewerAPI =>
    _.defaultsDeep(overrides, getBaseViewerMock(store, defaults))

export const buildViewerMockWithDefaultStore = (overrides: any = {}, defaults = {}): ViewerAPI =>
    getDefaultViewerMock(getDefaultViewerStore(), overrides, defaults)

export const createNavigationInfo = (pageId: string, overrides?: any) =>
    _.assign(
        {
            format: 'slash',
            pageId,
            title: 'home',
            queryParams: {}
        },
        overrides
    )
