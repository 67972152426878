import type {BehaviorsList, BehaviourItem} from '@wix/document-services-types'
import _ from 'lodash'
import {stripHashIfExists} from '../../../../utils/refArrayUtils'
import {DATA_TYPES, DOM_ID_PREFIX} from '../../../../constants/constants'
import type {DeserializeComponentParams} from '../types'
import {addNamespaceToStructure} from '../componentDeserializationStructure'

const MOBILE_COMP_DATA_PREFIX = DOM_ID_PREFIX.MOBILE

const fixBehaviorItemCompIdsAndTargetIds = (behaviorItem: BehaviourItem, oldToNewIdMap: any) => {
    const sourceIdPath = ['action', 'sourceId']
    if (_.get(behaviorItem, ['action', 'type']) === 'comp') {
        const oldSourceId = _.get(behaviorItem, sourceIdPath)
        _.set(behaviorItem, sourceIdPath, _.get(oldToNewIdMap, oldSourceId, oldSourceId))
    }
    const runCodeCompIdPath = ['behavior', 'params', 'compId']
    const runCodeTargetIdPath = ['behavior', 'targetId']
    if (_.get(behaviorItem, ['behavior', 'name']) === 'runCode' && _.has(behaviorItem, runCodeCompIdPath)) {
        const oldRunCodeCompId = _.get(behaviorItem, runCodeCompIdPath)
        const oldRunCodeTargetId = _.get(behaviorItem, runCodeTargetIdPath)
        _.set(behaviorItem, runCodeCompIdPath, _.get(oldToNewIdMap, oldRunCodeCompId, oldRunCodeCompId))
        _.set(behaviorItem, runCodeTargetIdPath, _.get(oldToNewIdMap, oldRunCodeTargetId, oldRunCodeTargetId))
    }
}

const fixBehaviorsIds = (
    behaviors: BehaviorsList,
    oldToNewIdMap: any,
    behaviorItemFixerHook?: (behaviorItem: BehaviourItem) => void
): BehaviorsList => {
    if (behaviors?.items) {
        let behaviorsItems: string | BehaviourItem[] = behaviors.items
        if (_.isString(behaviorsItems)) {
            behaviorsItems = JSON.parse(behaviorsItems) as BehaviourItem[]
        }
        const fixedBehaviorItems = _.map(behaviorsItems, behaviorItem => {
            if (behaviorItemFixerHook) {
                behaviorItemFixerHook(behaviorItem)
            }
            fixBehaviorItemCompIdsAndTargetIds(behaviorItem, oldToNewIdMap)

            return behaviorItem
        })
        const behaviorResult: Partial<BehaviorsList> = _.omit(behaviors, 'items')
        behaviorResult.items = JSON.stringify(fixedBehaviorItems)

        return behaviorResult as BehaviorsList
    }

    return behaviors
}

function getCustomId(
    oldToNewIdMap: Record<string, any> | undefined,
    dataObject: {id?: string},
    optionalCustomId?: string
) {
    let newId = optionalCustomId
    if (!oldToNewIdMap || !dataObject) {
        return newId
    }
    const serializedDataId = stripHashIfExists(dataObject.id!) ?? ''
    if (oldToNewIdMap[serializedDataId]) {
        newId = oldToNewIdMap[serializedDataId]
    } else if (_.startsWith(serializedDataId, MOBILE_COMP_DATA_PREFIX)) {
        const idSuffix = serializedDataId.substring(MOBILE_COMP_DATA_PREFIX.length)
        const mobilePropsMappedId = oldToNewIdMap[idSuffix]
        if (mobilePropsMappedId) {
            newId = MOBILE_COMP_DATA_PREFIX + mobilePropsMappedId
        }
    }
    return newId
}

const addIdToMap = (dataObject: {id?: string}, newId: string, oldToNewIdMap: any) => {
    if (!oldToNewIdMap) {
        return
    }
    const serializedDataId = stripHashIfExists(dataObject.id!) ?? ''
    newId = stripHashIfExists(newId)
    if (dataObject.id && !oldToNewIdMap[serializedDataId]) {
        oldToNewIdMap[serializedDataId] = newId
    }
}

export const updateComponentBehaviorsStructure = (
    deserializationParams: DeserializeComponentParams,
    behaviorItemFixerHook?: (behaviorItem: BehaviourItem) => void
) => {
    const {compStructure, mappers, customId} = deserializationParams
    if (!compStructure.behaviors) {
        return
    }

    const {oldToNewIdMap} = mappers ?? {}
    compStructure.behaviors = fixBehaviorsIds(compStructure.behaviors, oldToNewIdMap, behaviorItemFixerHook)
    const behaviorCustomId = getCustomId(oldToNewIdMap, compStructure.behaviors, customId)
    const behaviorQuery = addNamespaceToStructure(
        {...deserializationParams, customId: behaviorCustomId},
        compStructure.behaviors,
        DATA_TYPES.behaviors,
        oldToNewIdMap ?? {}
    )

    if (behaviorQuery) {
        compStructure.behaviorQuery = behaviorQuery
        addIdToMap(compStructure.behaviors, behaviorQuery, oldToNewIdMap)
    }
}
