const _ = require('lodash')
const {migrateColorsFactory} = require('./migrateColors')
const migrateFonts = require('./migrateFonts')
const migrateBorder = require('./migrateBorder')
const migratePadding = require('./migratePadding')
const migrateBorderRadiusFactory = require('./migrateBorderRadius')
const migrateIconRotation = require('./migrateIconRotation')

/**
 * @param {object} props
 * @param {boolean} props.useCssColor
 * @param {boolean} props.withBorderRadiusAsOneParam
 * @returns {function(Array<import('../index').ParsedStyleItem>): Array<import('../index').ParsedStyleItem>}
 */
function migrateProperties(props = {useCssColor: false, withBorderRadiusAsOneParam: false}) {
    const {useCssColor, withBorderRadiusAsOneParam} = props
    return _.flow([
        migrateColorsFactory(useCssColor),
        migrateFonts,
        migrateBorder,
        migratePadding,
        migrateBorderRadiusFactory(withBorderRadiusAsOneParam),
        migrateIconRotation
    ])
}

module.exports = migrateProperties
