const {
    newSchemaService: {staticInstance: schemaService},
    namespaceMapping
} = require('@wix/document-services-json-schemas')
const constants = require('../../helpers/constants')
const _ = require('lodash')
const PROPS_DATA_NAMESPACE = namespaceMapping.NAMESPACE_MAPPING.props
const BASE_PROPS_SCHEMA_TYPE = 'DefaultProperties'
const {DESKTOP, MOBILE} = constants

const addPropsData = pageJson => {
    pageJson.data[PROPS_DATA_NAMESPACE] = pageJson.data[PROPS_DATA_NAMESPACE] ?? {}
}

const generatePropsId = uniqueIdGenerator => uniqueIdGenerator.getUniqueId('propItem', '-', {bucket: 'props'})

const createPropsAndAddToPage =
    propsBuilder =>
    ({pageJson, uniqueIdGenerator}, propsData, shouldSplit) => {
        propsData.id = propsData.id || generatePropsId(uniqueIdGenerator)
        let propItemId

        if (shouldSplit && !pageJson.data[PROPS_DATA_NAMESPACE][propsData.id]) {
            propItemId = propsData.id
            pageJson.data[PROPS_DATA_NAMESPACE][propsData.id] = _.omit(propsData, ['fontScale'])
        }

        const mobilePropItemId = `${shouldSplit ? 'mobile_' : ''}${propsData.id}`
        pageJson.data[PROPS_DATA_NAMESPACE][mobilePropItemId] = propsBuilder(mobilePropItemId, propsData)

        return {propItemId, mobilePropItemId}
    }

const createPropsItem = createPropsAndAddToPage((id, propsData) => ({...propsData, id}))

const createPropsBuilder = compType => {
    const compDefinition = schemaService.getDefinition(compType) || {}
    const propsType = (compDefinition.propertyTypes || [])[0] || BASE_PROPS_SCHEMA_TYPE
    return schemaService.createItemAccordingToSchema(propsType, 'props')
}

const addPropsToComponent = ({allComponents}, componentId, propsId, mobilePropsId) => {
    const desktopComponent = allComponents[DESKTOP][componentId]

    if (propsId && desktopComponent) {
        desktopComponent.propertyQuery = propsId
    }

    const mobileComponent = allComponents[MOBILE][componentId]

    if (mobileComponent) {
        mobileComponent.propertyQuery = mobilePropsId
    }
}

const updateProps = (conversionResult, componentId, conversionContext) => {
    if (conversionResult.mobile.props) {
        const {allComponents} = conversionContext
        const mobileComponent = allComponents[MOBILE][componentId]
        const desktopComponent = allComponents[DESKTOP][componentId]
        const isSplit =
            !desktopComponent || (mobileComponent && mobileComponent.propertyQuery !== desktopComponent.propertyQuery)
        const {propItemId, mobilePropItemId} = createPropsItem(
            conversionContext,
            conversionResult.mobile.props,
            !isSplit
        )

        addPropsToComponent(conversionContext, componentId, propItemId, mobilePropItemId)
    }
}

module.exports = {
    addPropsData,
    createPropsBuilder,
    updateProps
}
