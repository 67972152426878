import type {
    ComponentStageData,
    MobileAlgoContext,
    Stage,
    StageHandler,
    ConversionDal,
    StructureIterators,
    MobileAlgoConfig
} from '../types'
import type {StructureEntryItem} from '../conversionDal/types'
import type {SnapshotDal} from '@wix/document-manager-core'
import {getStructurePointer} from '../conversionDal/pointerUtils'

export const createComponentStage = (): Stage<ComponentStageData> => {
    const handlers: StageHandler<ComponentStageData>[] = []
    const run = async (
        ctx: MobileAlgoContext,
        conversionDal: ConversionDal,
        initialSnapshot: SnapshotDal,
        structureIterators: StructureIterators,
        pageId: string,
        config: MobileAlgoConfig
    ) => {
        if (handlers.length === 0) {
            return
        }

        await structureIterators.forEachComponentAsync(async (component: StructureEntryItem) => {
            const componentPointer = getStructurePointer({id: component.id})

            if (component.type === 'Component' || component.type === 'Container') {
                for (const handler of handlers) {
                    await handler({
                        globalContext: ctx,
                        conversionDal,
                        initialSnapshot,
                        componentPointer,
                        pageId,
                        config
                    })
                }
            }
        })
    }

    return {
        register: (handler: StageHandler<ComponentStageData>) => {
            handlers.push(handler)
        },
        run
    }
}
