import _ from 'lodash'
import type {
    CreateExtArgs,
    CreateExtension,
    DAL,
    DeepFunctionMap,
    Extension,
    ExtensionAPI
} from '@wix/document-manager-core'
import {addComponent, addPage, replaceComponent} from './json/import'
import type {
    ComponentExport,
    CompRef,
    ImportExportOptions,
    RawComponentExport,
    WMLFiles
} from '@wix/document-services-types'
import {convertWmlToRawComponentExport, convertCoreToRawComponentExport} from './formatConverter'
import {wml} from '@wix/document-management-wml-converter'
import {json} from '@wix/document-management-json-converter'
import {exportComponent} from './json/export'

export interface ImportExportApi extends DeepFunctionMap {
    components: {
        replace(componentToReplace: CompRef, componentExport: RawComponentExport, options?: ImportExportOptions): void
        add(
            newComponentPointer: CompRef,
            containerPointer: CompRef,
            componentExport: RawComponentExport,
            options?: ImportExportOptions
        ): CompRef
    }
    pages: {
        replace(pageToReplace: CompRef, pageExport: RawComponentExport, options?: ImportExportOptions): void
        add(newComponentPointer: CompRef, pageExport: RawComponentExport, options?: ImportExportOptions): CompRef
    }
    core: {
        convertCoreToRawComponentExport(componentExport: ComponentExport): Promise<RawComponentExport>
        exportComponent(componentPointer: CompRef, options?: ImportExportOptions): ComponentExport
        exportPage(pagePointer: CompRef, options?: ImportExportOptions): ComponentExport
    }
    wml: {
        convertWmlToRawComponentExport(wmlFiles: WMLFiles): Promise<RawComponentExport>
        exportComponent(componentPointer: CompRef, options?: ImportExportOptions): WMLFiles
        exportPage(pagePointer: CompRef, options?: ImportExportOptions): WMLFiles
    }
}

export interface ImportExportExtensionApi extends ExtensionAPI {
    importExport: ImportExportApi
}

const wrapMethodsWithCustomGetterDisabled = (value: any, dal: DAL): any => {
    if (_.isPlainObject(value)) {
        return _.mapValues(value, prop => wrapMethodsWithCustomGetterDisabled(prop, dal))
    }

    return dal.withoutCustomGetters(value)
}

const createExtension: CreateExtension = (): Extension => {
    const createExtensionAPI = (extensionContext: CreateExtArgs): ImportExportExtensionApi => ({
        importExport: {
            components: {
                replace: (
                    componentToReplace: CompRef,
                    rawComponentExport: RawComponentExport,
                    options?: ImportExportOptions
                ): void => replaceComponent(extensionContext, rawComponentExport, componentToReplace, options),
                add: (
                    newComponentPointer,
                    containerPointer: CompRef,
                    rawComponentExport: RawComponentExport,
                    options?: ImportExportOptions
                ): CompRef =>
                    addComponent(extensionContext, newComponentPointer, rawComponentExport, containerPointer, options)
            },
            pages: {
                replace: (
                    pageToReplace: CompRef,
                    rawPageExport: RawComponentExport,
                    options?: ImportExportOptions
                ): void => replaceComponent(extensionContext, rawPageExport, pageToReplace, options),
                add: (newComponentPointer, rawPageExport: RawComponentExport, options?: ImportExportOptions): CompRef =>
                    addPage(extensionContext, newComponentPointer, rawPageExport, options)
            },
            core: {
                convertCoreToRawComponentExport,
                exportComponent: (componentPointer: CompRef, options?: ImportExportOptions): ComponentExport =>
                    json.pack(exportComponent(extensionContext, componentPointer, options)),
                exportPage: (pagePointer: CompRef, options?: ImportExportOptions): ComponentExport =>
                    json.pack(exportComponent(extensionContext, pagePointer, options))
            },
            wml: {
                convertWmlToRawComponentExport,
                exportComponent: (componentPointer: CompRef, options?: ImportExportOptions): WMLFiles =>
                    wml.pack(exportComponent(extensionContext, componentPointer, options)),
                exportPage: (pagePointer: CompRef, options?: ImportExportOptions): WMLFiles =>
                    wml.pack(exportComponent(extensionContext, pagePointer, options))
            }
        }
    })

    return {
        name: 'importExport',
        createExtensionAPI: (args: CreateExtArgs): ImportExportExtensionApi =>
            wrapMethodsWithCustomGetterDisabled(createExtensionAPI(args), args.dal)
    }
}

export {createExtension}
