import type {DebugToolsConfig} from '../../types'

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

const ColorPallets: Record<string, string[]> = {
    monochromaticBlue: [
        '#ADD8E6', // Light Blue
        '#87CEEB', // Sky Blue
        '#00BFFF', // Deep Sky Blue
        '#1E90FF', // Dodger Blue
        '#4169E1', // Royal Blue
        '#0000CD', // Medium Blue
        '#00008B', // Dark Blue
        '#000080' // Navy
    ],
    analogousGreen: [
        '#98FF98', // Mint Green
        '#98FB98', // Pale Green
        '#00FF7F', // Spring Green
        '#2E8B57', // Sea Green
        '#3CB371', // Medium Sea Green
        '#8FBC8F', // Dark Sea Green
        '#228B22', // Forest Green
        '#006400' // Dark Green
    ],
    complementary: [
        '#FF7F50', // Coral
        '#40E0D0', // Turquoise
        '#FFDAB9', // Peach Puff
        '#7B68EE', // Medium Slate Blue
        '#DDA0DD', // Plum
        '#FF6347', // Tomato
        '#7FFFD4', // Aquamarine
        '#E6E6FA' // Lavender
    ],
    triadic: [
        '#DAA520', // Goldenrod
        '#C71585', // Medium Violet Red
        '#00FA9A', // Medium Spring Green
        '#FF6347', // Tomato
        '#6A5ACD', // Slate Blue
        '#FFA07A', // Light Salmon
        '#BA55D3', // Medium Orchid
        '#00FF7F' // Spring Green
    ],
    tetradic: [
        '#708090', // Slate Gray
        '#B22222', // Firebrick
        '#F0E68C', // Khaki
        '#F08080', // Light Coral
        '#9932CC', // Dark Orchid
        '#CD853F', // Peru
        '#B0C4DE', // Light Steel Blue
        '#B8860B' // Dark Goldenrod
    ],
    neutral: [
        '#F5F5F5', // White Smoke
        '#D3D3D3', // Light Gray
        '#C0C0C0', // Silver
        '#808080', // Gray
        '#696969', // Dim Gray
        '#DCDCDC', // Gainsboro
        '#2F4F4F', // Dark Slate Gray
        '#000000' // Black
    ]
}

let colorIndex = 0
export const getColor = (config: DebugToolsConfig): string => {
    if (config.colors || config.colorPallet) {
        const colors = config.colors ?? ColorPallets[config.colorPallet!]
        return colors[colorIndex++ % colors.length]
    }
    return getRandomColor()
}
