import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import menuUtils from '../../menu/menuUtils'
import type {DsFixer} from '../dsDataFixers'

const getItem = (ps: PS, itemId: string) => ps.dal.full.get(ps.pointers.data.getDataItemFromMaster(itemId))
const updateItem = (ps: PS, itemId: string, item) =>
    ps.dal.full.set(ps.pointers.data.getDataItemFromMaster(itemId), item)

function fixCrappyReferences(ps: PS, menuPointer: Pointer) {
    const reverse_refs = {} //<itemId, parentId>
    const toFix = {} //<parent, itemQueriesToRemove>
    const missingReferences = {} //<parent, itemQueriesToRemove>
    let item
    let itemId: string
    const items = [menuPointer.id]
    while (items.length) {
        itemId = menuUtils.sanitizeHash(items.shift())
        item = getItem(ps, itemId)
        //eslint-disable-next-line no-loop-func
        item.items.forEach((query: string) => {
            if (!getItem(ps, menuUtils.sanitizeHash(query))) {
                missingReferences[itemId] = missingReferences[itemId] || []
                missingReferences[itemId].push(query)
            } else {
                if (reverse_refs[query]) {
                    toFix[itemId] = toFix[itemId] || []
                    toFix[itemId].push(query)
                }
                reverse_refs[query] = itemId
                items.push(query)
            }
        })
    }
    _.forEach([toFix, missingReferences], invalidStuffToFix => {
        _.forOwn(invalidStuffToFix, (itemQueriesToRemove, parentId) => {
            const parentItem = getItem(ps, parentId)
            _.pullAll(parentItem.items, itemQueriesToRemove)
            updateItem(ps, parentId, parentItem)
        })
    })
    if (!_.isEmpty(missingReferences)) {
        ps.extensionAPI.logger.captureError(new Error('Missing references to menu items'), {
            tags: {
                menuReferencesFixer: true
            },
            extras: {
                missingItems: {
                    [menuPointer.id]: missingReferences
                }
            }
        })
    }
}

const fixer: DsFixer = {
    exec(ps: PS) {
        const allMenuPointers = menuUtils.getAllMenuPointers(ps)
        _.forEach(allMenuPointers, menuPointer => {
            fixCrappyReferences(ps, menuPointer) //crappy = double or missing
        })
    },
    name: 'menuReferencesFixer',
    version: 1
}
export default fixer
