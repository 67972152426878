import type {DalValue, ExtensionAPI, DAL, CreateExtArgs} from '@wix/document-manager-core'
import type {
    CompRef,
    Pointer,
    Pointers,
    ExternalsBuilderMap,
    ExternalsMap,
    NamespacesDataMap
} from '@wix/document-services-types'
import _ from 'lodash'
import type {DataModelExtensionAPI} from '../../../../dataModel/dataModel'
import {getNewItemId} from '../../utils'
import {
    DetailedValue,
    forEachReferredDataItem,
    getDetailedValueByPath,
    type ExtendedRefInfo,
    type ReferredDataItemsIterateeContext
} from '@wix/import-export-utils'
import {stripHashIfExists} from '../../../../../utils/refArrayUtils'
import type {RelationshipsAPI} from '../../../../relationships'
import type {SchemaExtensionAPI} from '../../../../..'
import {REFERABLE_BY_EXTERNAL_NODES, SPECIAL_DATA_NODE_IDS, type ExternalsHandler} from '../../constants'
import {getExternalsHandler} from '../externals'

interface DataItemsUpdate {
    pointer: Pointer
    origDataItem?: DalValue
    dataItem?: DalValue
}

type DataItemsUpdates = DataItemsUpdate[]

const sanitizeDataItem = (dataItem: DalValue, namespace: string) => {
    const {id, type} = dataItem
    if (!REFERABLE_BY_EXTERNAL_NODES[namespace]?.[type] && !SPECIAL_DATA_NODE_IDS[namespace]?.has(id)) {
        delete dataItem.id
    }
}

const collectNonOwnedReferences = (
    {pointers, extensionAPI}: CreateExtArgs,
    item: DalValue,
    namespace: string,
    refFieldsInfo: ExtendedRefInfo[],
    externals: ExternalsBuilderMap
) => {
    for (const {isRefOwner, path, constantValues, referencedMap} of refFieldsInfo) {
        if (isRefOwner) {
            continue
        }

        const valueByPath: DetailedValue[] = getDetailedValueByPath(item, path)
        const handleExternals: ExternalsHandler = getExternalsHandler(namespace, item.type, path)

        for (const detailedValue of valueByPath) {
            if (!constantValues?.has(detailedValue.value)) {
                const refId: string = stripHashIfExists(detailedValue.value)
                const refPointer: Pointer = pointers.getPointer(refId, referencedMap)

                handleExternals.pack(detailedValue, externals, refPointer, item, extensionAPI)
            }
        }
    }
}

export const resolveDataItem = (
    extensionContext: CreateExtArgs,
    itemPointer: Pointer,
    pagePointer: CompRef,
    externals?: ExternalsBuilderMap
): DalValue => {
    const {extensionAPI, coreConfig} = extensionContext
    const {schemaService} = coreConfig
    const {dataModel} = extensionAPI as DataModelExtensionAPI

    const dataItem = dataModel.getItem(itemPointer.id, itemPointer.type, pagePointer.id)

    forEachReferredDataItem(
        schemaService,
        dataItem,
        itemPointer.type,
        ({item, namespace, refFieldsInfo}: ReferredDataItemsIterateeContext) => {
            externals?.internalIds.add(item.id)
            sanitizeDataItem(item, namespace)
            if (externals) {
                collectNonOwnedReferences(extensionContext, item, namespace, refFieldsInfo, externals)
            }
        }
    )

    return dataItem
}

export const getResolvedDataItemsByNamespaceMap = (
    extensionContext: CreateExtArgs,
    dataPointers: Pointer[],
    pagePointer: CompRef,
    externals?: ExternalsBuilderMap
): NamespacesDataMap | undefined => {
    if (dataPointers.length === 0) {
        return
    }

    const dataItemsMap: NamespacesDataMap = {}
    for (const pointer of dataPointers) {
        const dataItem = resolveDataItem(extensionContext, pointer, pagePointer, externals)
        dataItemsMap[pointer.type] ??= {}
        dataItemsMap[pointer.type][pointer.id] = dataItem
    }

    return dataItemsMap
}

const shouldRemoveOriginalItem = (item: DalValue, originalItem?: DalValue) =>
    originalItem && originalItem.id !== item.id

const removeReferencesToItem = (
    itemPointer: Pointer,
    namespace: string,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
) => {
    const {relationships, schemaAPI} = extensionAPI as RelationshipsAPI & SchemaExtensionAPI
    const referringPointers = relationships.getReferencesToPointer(itemPointer)
    for (const referringPointer of referringPointers) {
        const referringItem = dal.get(referringPointer)
        const refFieldsInfo = schemaAPI.extractReferenceFieldsInfo(referringPointer.type, referringItem.type)
        for (const refFieldInfo of refFieldsInfo) {
            if (refFieldInfo.referencedMap !== namespace) {
                continue
            }

            const referredPointers = getDetailedValueByPath(referringItem, refFieldInfo.path)
            for (const {value, path} of referredPointers) {
                if (stripHashIfExists(value) === itemPointer.id) {
                    const referencePointer = pointers.getInnerPointer(referringPointer, path)
                    dal.remove(referencePointer)
                }
            }
        }
    }
}

const removeOriginalItem = (
    originalItem: DalValue,
    namespace: string,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
) => {
    const originalItemPointer = pointers.getPointer(originalItem.id, namespace)
    removeReferencesToItem(originalItemPointer, namespace, dal, pointers, extensionAPI)
    dal.remove(originalItemPointer)
}

const replaceSingleItemReferences =
    ({dal, pointers, extensionAPI}: CreateExtArgs, externals: ExternalsMap) =>
    ({item, namespace, refFieldsInfo, originalItem}: ReferredDataItemsIterateeContext) => {
        item.id = getNewItemId(item.id, namespace, externals.externalRefs, originalItem?.id)

        if (shouldRemoveOriginalItem(item, originalItem)) {
            removeOriginalItem(originalItem, namespace, dal, pointers, extensionAPI)
        }

        for (const refFieldInfo of refFieldsInfo) {
            const {isRefOwner, path} = refFieldInfo

            if (isRefOwner) {
                continue
            }

            const valueByPath: DetailedValue[] = getDetailedValueByPath(item, path)
            const handleExternals: ExternalsHandler = getExternalsHandler(namespace, item.type, path)

            for (const detailedValue of valueByPath) {
                handleExternals.unpack(detailedValue, externals, refFieldInfo, item)
            }
        }
    }

const replaceDataItemReferences = (
    extensionContext: CreateExtArgs,
    dataItem: DalValue,
    origDataItem: DalValue,
    namespace: string,
    externals: ExternalsMap
) => {
    const {coreConfig} = extensionContext
    const {schemaService} = coreConfig

    forEachReferredDataItem(
        schemaService,
        dataItem,
        namespace,
        replaceSingleItemReferences(extensionContext, externals),
        {
            originalItem: origDataItem
        }
    )
}

export const updateComponentDataItem = (
    extensionContext: CreateExtArgs,
    componentPointer: CompRef,
    dataItem: DalValue,
    namespace: string,
    externals: ExternalsMap
) => {
    const {extensionAPI} = extensionContext
    const {dataModel} = extensionAPI as DataModelExtensionAPI

    const origDataItem = dataModel.components.getItem(componentPointer, namespace)

    if (dataItem) {
        replaceDataItemReferences(extensionContext, dataItem, origDataItem, namespace, externals)
        dataModel.components.addItem(componentPointer, namespace, dataItem, undefined, {skipOriginalNodeMerge: true})
    } else if (origDataItem) {
        dataModel.components.removeItem(componentPointer, namespace)
    }
}

export const updateDataItem = (
    extensionContext: CreateExtArgs,
    dataItem: DalValue,
    origDataItem: DalValue,
    namespace: string,
    pagePointer: CompRef,
    externals: ExternalsMap
) => {
    const {pointers, extensionAPI} = extensionContext
    const {dataModel} = extensionAPI as DataModelExtensionAPI

    if (dataItem) {
        replaceDataItemReferences(extensionContext, dataItem, origDataItem, namespace, externals)
        dataModel.addItemWithRefReuse(dataItem, namespace, pagePointer.id)
    } else if (origDataItem) {
        dataModel.removeItemRecursivelyIncludingPermanentNodes(pointers.getPointer(origDataItem.id, namespace))
    }
}

export const updateDataItemsByNamespacesDataMap = (
    extensionContext: CreateExtArgs,
    origPointers: Pointer[],
    namespacesDataMap: NamespacesDataMap | undefined,
    pagePointer: CompRef,
    externals: ExternalsMap
) => {
    const {pointers, extensionAPI} = extensionContext
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const updates: DataItemsUpdates = origPointers.map((pointer: Pointer) => {
        const {id, type} = pointer
        const origDataItem = dataModel.getItem(id, type, pagePointer.id)
        const dataItem = namespacesDataMap?.[type]?.[id]
        delete namespacesDataMap?.[type]?.[id]

        return {
            pointer,
            origDataItem,
            dataItem
        }
    })

    _.forOwn(namespacesDataMap, (items: Record<string, DalValue>, namespace: string) => {
        _.forOwn(items, (dataItem: DalValue, id: string) => {
            updates.push({
                pointer: pointers.getPointer(id, namespace),
                dataItem
            })
        })
    })

    for (const {pointer, dataItem, origDataItem} of updates) {
        if (dataItem) {
            dataItem.id ??= pointer.id
            externals.externalRefs[dataItem.id] ??= dataItem.id
        }

        updateDataItem(extensionContext, dataItem, origDataItem, pointer.type, pagePointer, externals)
    }
}
