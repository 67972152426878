import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import routers from '../../routers/routers'
import page from '../../page/page'
import type {DsFixer} from '../dsDataFixers'

function removeNonExistingPagesFromRouter(ps: PS) {
    const pageIds = page.getPageIdList(ps, false, true)
    const appRouters = routers.get.all(ps)

    _.forEach(appRouters, router => {
        const routerRef = routers.getRouterRef.byPrefix(ps, router.prefix)
        _.forEach(router.pages, pageId => {
            if (!_.includes(pageIds, pageId)) {
                routers.pages.removePageFromRouter(ps, routerRef, pageId)
            }
        })
    })
}

const fixer: DsFixer = {
    exec(ps) {
        removeNonExistingPagesFromRouter(ps)
    },
    name: 'routersDataFixer',
    version: 1,
    fixerRequiresReruns: true
}
export default fixer
