import _ from 'lodash'
import type {PageExtensionAPI} from '../../../page'
import * as constants from '../../../../constants/constants'
import type {DocumentManager} from '@wix/document-manager-core'
import type {MasterPageMigrator} from '../dataMigrationRunner'

const {DATA_TYPES, MASTER_PAGE_ID} = constants
const masterPageDataPointer = {id: MASTER_PAGE_ID, type: DATA_TYPES.data}

const migrateMasterPage = (documentManager: DocumentManager) => {
    const {dal, extensionAPI} = documentManager
    const {page} = extensionAPI as PageExtensionAPI

    const masterPageData = dal.get(masterPageDataPointer)
    if (masterPageData.usesNewAnimations) {
        return
    }

    const indexKey = page.getAllPagesIndexId()
    const hasEffects = !_.isEmpty(dal.query(DATA_TYPES.effects, indexKey))
    if (!hasEffects) {
        return
    }

    dal.set(masterPageDataPointer, {
        ...masterPageData,
        usesNewAnimations: true
    })
}

const name = 'addUsesNewAnimationsFlagToMasterPageData'
const version = 1

export const addUsesNewAnimationsFlagToMasterPageData: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version,
    fixerRequiresReruns: true
}
