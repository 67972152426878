import type {Tags} from '@wix/document-services-types'
import {utils} from '@wix/santa-ds-libs'
import {contextAdapter} from '../utils/contextAdapter'
const {cookieUtils} = utils

export default {
    SAVE: 'ds_save',
    SERVER_SAVE: 'ds_serverSave',
    FULL_PARTIAL_SAVE: 'ds_fullPartial_save',
    BUILD_PARTIAL_PAYLOAD: 'ds_partialSave_buildPayload',
    PUBLISH: 'ds_publish',
    PUBLISH_PIPELINE: 'ds_publishPipeline',
    start: (interaction: string, tags?: Tags) => {
        contextAdapter.utils.fedopsLogger.interactionStarted(interaction, {
            tags,
            paramsOverrides: {
                sourceOfStart: tags?.sourceOfStart
            }
        })
    },
    end: (interaction: string, tags?: Tags) =>
        contextAdapter.utils.fedopsLogger.interactionEnded(interaction, {
            tags,
            paramsOverrides: {
                sourceOfStart: tags?.sourceOfStart
            }
        }),
    error: (e: Error, extras: Tags = {}, tags: Tags = {}) => {
        if (e.message === 'invalid CSRF Token') {
            extras.xsrf = cookieUtils.getCookie('XSRF-TOKEN')
        }
        contextAdapter.utils.fedopsLogger.captureError(e, {
            tags: {
                saveError: true,
                ...tags
            },
            extras
        })
    }
}
