import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'
import type {CreateExtArgs} from '@wix/document-manager-core'
import {addIdToMap, getCustomId} from '../utils'
import type {DataModelExtensionAPI, SchemaExtensionAPI, VariantsExtensionAPI} from '../../../..'
import type {DeserializeComponentParams} from '../types'
import {DATA_TYPES, VARIANTS} from '../../../../constants/constants'

/**
 * collect all variants of component and his ancestor and save it to map
 * it will help us later on to link scoped values to the variant that triggers the scoped value
 * when deserializing scoped values like (transform, transition, ...)
 */
const mapAncestorsVariantIds = (
    {extensionAPI}: CreateExtArgs,
    componentPointer: Pointer,
    oldToNewIdMap: Record<string, string>,
    isAncestorsChecked?: boolean
) => {
    const {variants} = extensionAPI as VariantsExtensionAPI

    if (!isAncestorsChecked) {
        const variantsIds = variants.collectAncestorsVariants(componentPointer)
        _.forEach(variantsIds, id => addIdToMap({id}, id, oldToNewIdMap))
    }
}

const mapMasterPageVariantIds = (
    {extensionAPI}: CreateExtArgs,
    componentPointer: Pointer,
    oldToNewIdMap: Record<string, string>
) => {
    const {variants} = extensionAPI as VariantsExtensionAPI

    const variantsIds = variants.getMasterPageVariants(componentPointer)
    _.forEach(variantsIds, id => addIdToMap({id}, id, oldToNewIdMap))
}

/**
 * deserialize variants of component to the page, and replacing the ref from old comp id to new comp id
 */

export const updateVariantsInStructure = (
    deserializationParams: DeserializeComponentParams,
    isAncestorsChecked?: boolean
) => {
    const {createExtArgs, mappers, containerPointer, compStructure, pageId} = deserializationParams
    const {extensionAPI} = createExtArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {schemaAPI} = extensionAPI as SchemaExtensionAPI
    let {oldToNewIdMap} = mappers ?? {}
    if (!oldToNewIdMap) {
        oldToNewIdMap = {}
    }

    mapAncestorsVariantIds(createExtArgs, containerPointer!, oldToNewIdMap, isAncestorsChecked)
    mapMasterPageVariantIds(createExtArgs, containerPointer!, oldToNewIdMap)

    _.forEach(compStructure.variants, (variant, oldId) => {
        const variantCustomId = getCustomId(oldToNewIdMap, {id: oldId}, dataModel.generateNewId(DATA_TYPES.variants))
        const variantWithNewCompId = _.defaults({componentId: compStructure.id}, variant)

        const {values} = variant
        _.forEach(values, val => {
            if (val.id) {
                const newId = dataModel.generateNewId(DATA_TYPES.variants)
                addIdToMap({id: val.id}, newId, oldToNewIdMap)
                val.id = newId
            }

            if (
                [VARIANTS.VARIANT_TYPES.REPEATER_PATTERN_LIST, VARIANTS.VARIANT_TYPES.VARIANTS_LIST].includes(
                    variant.type
                )
            ) {
                val.componentId = compStructure.id
            }
        })

        const possibleNonOwnedRefs = schemaAPI
            .extractReferenceFieldsInfo(DATA_TYPES.variants, variant.type, variant)
            .filter(({referencedMap}) => referencedMap === DATA_TYPES.effects)
        for (const {path, referencedMap} of possibleNonOwnedRefs) {
            const id = _.get(variant, path)
            const newId = getCustomId(oldToNewIdMap, {id}, dataModel.generateNewId(referencedMap))!
            addIdToMap({id}, newId, oldToNewIdMap)
            _.set(variantWithNewCompId, path, `#${newId}`)
        }

        const newVariantId = dataModel.addItem(variantWithNewCompId, DATA_TYPES.variants, pageId, variantCustomId).id
        if (newVariantId) {
            addIdToMap({id: oldId}, newVariantId, oldToNewIdMap)
            if (variantWithNewCompId.type === VARIANTS.VARIANT_TYPES.BREAKPOINTS_DATA) {
                compStructure.breakpointVariantsQuery = newVariantId
            }
            if (
                [VARIANTS.VARIANT_TYPES.REPEATER_PATTERN_LIST, VARIANTS.VARIANT_TYPES.VARIANTS_LIST].includes(
                    variant.type
                )
            ) {
                compStructure.variantsQuery = newVariantId
            }
        }
    })
}
