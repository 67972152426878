import type {DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {PageExtensionAPI, SchemaExtensionAPI} from '../../..'
import type {
    CompRef,
    Component,
    ComponentFeature,
    ComponentFeatures,
    ExternalRefsMap,
    Pointers,
    RawComponentExportStructure,
    RawImportExportOptions
} from '@wix/document-services-types'
import {DATA_TYPES, VIEW_MODES} from '../../../constants/constants'
import {generateItemIdWithPrefix} from '../../../utils/dataUtils'
import {stripHashIfExists} from '../../../utils/refArrayUtils'
import {DEFAULT_COMP_LAYOUT} from '../../components/components'
import _ from 'lodash'
import type {ExtendedRefInfo} from '@wix/import-export-utils'

const {DESKTOP} = VIEW_MODES

export const withHash = (id: string) => `#${id}`

const getIdPrefixByNamespace = (namespace: string): string => {
    if (VIEW_MODES[namespace]) {
        return 'comp'
    }

    return namespace
}

export const generateItemIdByNamespace = (namespace: string) => {
    const prefix = getIdPrefixByNamespace(namespace)
    return generateItemIdWithPrefix(prefix)
}

export const getNewItemId = (
    itemId: string | undefined,
    namespace: string,
    externalRefs: ExternalRefsMap,
    originalItemId?: string
): string => {
    if (!itemId) {
        return originalItemId ?? generateItemIdByNamespace(namespace)
    }

    let id = externalRefs[itemId]
    if (!id) {
        id = originalItemId ?? generateItemIdByNamespace(namespace)
        externalRefs[itemId] = id
    }

    return id
}

export const getNewItemRef = (
    originalId: string | undefined,
    refFieldInfo: ExtendedRefInfo,
    externalRefs: ExternalRefsMap
): string => {
    const {referencedMap, noHash, constantValues} = refFieldInfo

    if (originalId) {
        originalId = stripHashIfExists(originalId)

        if (constantValues?.has(originalId)) {
            return originalId
        }
    }

    const id = getNewItemId(originalId, referencedMap, externalRefs)
    if (!noHash) {
        return withHash(id)
    }

    return id
}

export const buildPlaceholderStructure = (
    componentPointer: CompRef,
    pagePointer: CompRef,
    overrides: Partial<Component> = {}
): Partial<Component> => ({
    id: componentPointer.id,
    components: [],
    layout: DEFAULT_COMP_LAYOUT,
    metaData: {pageId: pagePointer.id},
    ...overrides
})

export const addPlaceholderComponent = (
    componentId: string | undefined,
    containerPointer: CompRef,
    dal: DAL,
    pointers: Pointers
): CompRef => {
    const containerChildrenPointer = pointers.structure.getChildrenContainer(containerPointer)
    const pagePointer = pointers.structure.getPageOfComponent(containerPointer) as CompRef
    componentId ??= generateItemIdByNamespace(DESKTOP)
    const componentPointer = pointers.getPointer(componentId, DESKTOP) as CompRef

    dal.set(componentPointer, buildPlaceholderStructure(componentPointer, pagePointer, {parent: containerPointer.id}))

    const containerChildren = dal.get(containerChildrenPointer) ?? []
    dal.set(containerChildrenPointer, [...containerChildren, componentId])

    return componentPointer
}

export const addPlaceholderPage = (
    pageId: string | undefined,
    pageExport: RawComponentExportStructure,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
): CompRef => {
    const {page} = extensionAPI as PageExtensionAPI
    const pagePointer = pointers.getPointer(pageId ?? generateItemIdByNamespace(DESKTOP), DESKTOP) as CompRef
    return page.addPage(pageExport.data.data, undefined, pagePointer) as CompRef
}

export const isSystemDataItem = (
    componentQuery: string | undefined,
    namespace: string,
    extensionAPI: ExtensionAPI
): boolean => {
    const {schemaAPI} = extensionAPI as SchemaExtensionAPI
    return componentQuery !== undefined && namespace === DATA_TYPES.theme && schemaAPI.isSystemStyle(componentQuery)
}

export const getComponentFeaturesByType = (
    componentType: string,
    {features, components}: RawImportExportOptions['componentFeatures']
): ComponentFeatures => components[componentType] ?? features

interface FeaturesInfo {
    aliases: Set<ComponentFeature['alias']>
    namespaces: Set<ComponentFeature['namespace']>
    queries: Set<ComponentFeature['query']>
}

export const getFeaturesInfoByComponentFeatures = (componentFeatures: ComponentFeatures): FeaturesInfo => {
    const aliases = new Set<ComponentFeature['alias']>()
    const namespaces = new Set<ComponentFeature['namespace']>()
    const queries = new Set<ComponentFeature['query']>()

    _.forOwn(componentFeatures, ({alias, namespace, query}: ComponentFeature) => {
        aliases.add(alias)
        namespaces.add(namespace)
        queries.add(query)
    })

    return {
        aliases,
        namespaces,
        queries
    }
}
