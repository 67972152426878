import * as dmUtils from '@wix/document-manager-utils'
import buildErrors from './constants/buildErrors'
import siteMetaData from '../siteMetadata/siteMetadata'
import {onRefreshRequiredErrorTypes} from '@wix/document-services-types'

export default {
    save: {...dmUtils.saveErrors, ...onRefreshRequiredErrorTypes},
    build: buildErrors,
    seo: siteMetaData.seo.ERRORS,
    social: {
        facebook: siteMetaData.social.facebook.ERRORS
    },
    favicon: siteMetaData.favicon.ERRORS,
    siteName: siteMetaData.siteName.ERRORS
}
