import type {Pointer, PS} from '@wix/document-services-types'
import dataModel from '../../dataModel/dataModel'
import structure from '../../structure/structure'
import type {ComponentAfterAddDataFromExtEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

function replaceFullScreenPropertyWithDocking(ps: PS, compPointer: Pointer) {
    const propertiesItem = dataModel.getPropertiesItem(ps, compPointer)
    if (propertiesItem?.fullScreenModeOn) {
        structure.setDock(ps, compPointer, {left: {vw: 0}, right: {vw: 0}})
        dataModel.updatePropertiesItem(ps, compPointer, {fullScreenModeOn: false})
    }
}
const replaceFullScreenPropertyWithDockingFromExt = (ps: PS, data: ComponentAfterAddDataFromExtEvent) => {
    replaceFullScreenPropertyWithDocking(ps, data.compToAddPointer)
}
export default {
    replaceFullScreenPropertyWithDocking,
    replaceFullScreenPropertyWithDockingFromExt
}
