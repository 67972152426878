import type {PS} from '@wix/document-services-types'
import textThemes from '../textThemes/textThemes'
import _ from 'lodash'
import {coreUtils} from '@wix/santa-ds-libs'

const fontUtils = _.once((ps: PS) => {
    const {createFontUtils, FONT_EXPERIMENT_NAME} = coreUtils.fontUtils
    return createFontUtils(ps.siteAPI.isExperimentOpen(FONT_EXPERIMENT_NAME))
})

function setToTextTheme(ps: PS, index: number, val: string) {
    const textTheme = fontUtils(ps).fontStringToTextTheme(val)
    textThemes.set(ps, index, textTheme)
}

function getFromTextTheme(ps: PS, index: number) {
    const textTheme = textThemes.get(ps, index)
    if (!textTheme) {
        return
    }
    return fontUtils(ps).textThemeToFontString(textTheme)
}

function getAllFromTextTheme(ps: PS) {
    const allTextThemes = textThemes.getAll(ps)
    return _.map(allTextThemes, fontUtils(ps).textThemeToFontString)
}

export default {
    set: setToTextTheme,
    get: getFromTextTheme,
    getAll: getAllFromTextTheme
}
