import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {PageMigrator} from '../dataMigrationRunner'
import {runOnPageComponents} from './utils/migrationUtil'
import * as constants from '../../../../constants/constants'
import type {CompRef, Pointer} from '@wix/document-services-types'
import type {ComponentDefinitionExtensionAPI} from '../../../componentDefinition'
import {getRefComponentChildrenWithoutCorrespondingSlots} from '../../../../utils/slotsUtils'
import type {ComponentsExtensionAPI} from '../../../components/components'
import type {StructureExtensionAPI} from '../../../structure'

const name = 'removeRefComponentChildrenWithoutCorrespondingSlots'
const version = 0

const removeInvalidChildren = (
    {extensionAPI, logger}: DocumentManager,
    compPointer: Pointer,
    invalidChildren: CompRef[]
): void => {
    const {components} = extensionAPI as ComponentsExtensionAPI

    logger.interactionStarted(name, {compPointer, invalidChildren})

    for (const child of invalidChildren) {
        components.removeComponent(child)
    }

    logger.interactionEnded(name)
}

const fixRefComponentChildrenWithoutSlots = (
    documentManager: DocumentManager,
    pageId: string,
    compPointer: Pointer
) => {
    const {dal, pointers, extensionAPI} = documentManager
    const {componentDefinition, components} = extensionAPI as ComponentDefinitionExtensionAPI & StructureExtensionAPI
    const componentType: string = components.getComponentType(compPointer)

    if (!componentDefinition.isRefComponent(componentType)) return

    const invalidChildren: CompRef[] = getRefComponentChildrenWithoutCorrespondingSlots(
        dal,
        pointers,
        compPointer as CompRef
    )

    if (invalidChildren.length === 0) return

    removeInvalidChildren(documentManager, compPointer, invalidChildren)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    if (documentManager.experimentInstance.isOpen('dm_shouldFailInvalidSlotQuery')) {
        _.forEach(constants.VIEW_MODES, viewMode => {
            runOnPageComponents(documentManager, pageId, viewMode, fixRefComponentChildrenWithoutSlots)
        })
    }
}

export const removeRefComponentChildrenWithoutCorrespondingSlots: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions: [{version: 1, experiment: 'dm_shouldFailInvalidSlotQuery'}]
}
