import {
    type CreateExtArgs,
    type DalValue,
    type Extension,
    type ExtensionAPI,
    pointerUtils
} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {VariantsExtensionAPI} from '../variants/variants'
import * as constants from '../../constants/constants'

export type FeatureData = any

export interface FeaturesAPI {
    component: {
        get(compPointer: Pointer, featureName: string): FeatureData
    }
    site: {
        get(featureName: string): FeatureData
        update(featureName: string, value: DalValue): Pointer
    }
}

export type FeaturesExtensionAPI = ExtensionAPI & {
    features: FeaturesAPI
}

export const FEATURE_TYPES = {
    COMPONENT: 'Component',
    PAGE: 'Page',
    SITE: 'Site',
    REPEATER: 'Repeater'
}

const validPageLevelTypes = ['Page', 'Document']

const {
    namespaceMapping: {featuresData, getNamespaceConfig}
} = jsonSchemas

const masterPagePointer = pointerUtils.getPagePointer(constants.MASTER_PAGE_ID, constants.VIEW_MODES.DESKTOP)

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): FeaturesExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const {variants} = extensionAPI as VariantsExtensionAPI

        const validateFeatureType = (compPointer: Pointer, namespace: string, expectedFeatureType: string) => {
            if (!featuresData[namespace]) {
                throw new Error(`feature ${namespace} doesn't exist`)
            }

            const {featureType} = featuresData[namespace]

            if (featureType !== expectedFeatureType) {
                throw new Error(`feature ${namespace} cannot be used through ${expectedFeatureType} feature APIs`)
            }
            if (featureType === FEATURE_TYPES.COMPONENT) {
                // all components can use a Component feature
                return
            }
            const type = dal.get(pointers.getInnerPointer(compPointer, ['type']))

            if (featureType === FEATURE_TYPES.PAGE && !validPageLevelTypes.includes(type)) {
                throw new Error(`feature ${namespace} cannot be used for a non-page component`)
            }

            if (featureType === FEATURE_TYPES.SITE && type !== 'Document') {
                throw new Error(`feature ${namespace} is a site feature, and cannot be used for a page or component`)
            }

            if (featureType === FEATURE_TYPES.REPEATER && type !== 'RepeaterContainer') {
                throw new Error(
                    `feature ${namespace} is a repeater feature, and cannot be used for any other type of component`
                )
            }
        }

        const getNamespaceData = (componentPointer: Pointer, namespace: string) => {
            const config = getNamespaceConfig(namespace)
            if (config.supportsVariants) {
                return variants.getComponentItemConsideringVariants(componentPointer, namespace)
            }
            return dataModel.components.getItem(componentPointer, namespace)
        }

        const updateNamespaceData = (componentPointer: Pointer, namespace: string, value: DalValue) => {
            const config = getNamespaceConfig(namespace)
            if (config.supportsVariants) {
                return variants.updateDataConsideringVariants(componentPointer, value, namespace)
            }
            return dataModel.components.addItem(componentPointer, namespace, value)
        }

        const getFeatureData = (featureType: string, compPointer: Pointer, featureName: string): FeatureData => {
            validateFeatureType(compPointer, featureName, featureType)
            return getNamespaceData(compPointer, featureName)
        }

        const updateFeatureData = (
            featureType: string,
            compPointer: Pointer,
            featureName: string,
            value: DalValue
        ): Pointer => {
            validateFeatureType(compPointer, featureName, featureType)
            return updateNamespaceData(compPointer, featureName, value)
        }

        return {
            features: {
                component: {
                    get: (compPointer: Pointer, featureName: string) =>
                        getFeatureData(FEATURE_TYPES.COMPONENT, compPointer, featureName)
                },
                site: {
                    get: (featureName: string) => getFeatureData(FEATURE_TYPES.SITE, masterPagePointer, featureName),
                    update: (featureName: string, value: DalValue) =>
                        updateFeatureData(FEATURE_TYPES.SITE, masterPagePointer, featureName, value)
                }
            }
        }
    }

    return {
        name: 'features',
        createExtensionAPI,
        dependencies: new Set(['dataModel'])
    }
}

export {createExtension}
