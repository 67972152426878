import {ReportableError} from '@wix/document-manager-utils'
import type {
    CompRef,
    CompStructure,
    ControllerConnectionItem,
    IConnectionItem,
    Pointer,
    PossibleViewModes,
    PS,
    SerializedCompStructure,
    WixCodeConnectionItem
} from '@wix/document-services-types'
import _ from 'lodash'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import connections from '../connections/connections'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import hooks from '../hooks/hooks'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import pageData from '../page/pageData'
import componentStructureInfo from './componentStructureInfo'
import nicknameContextRegistrar from './nicknameContextRegistrar'
import {constants as extConsts} from '@wix/document-manager-extensions'
const {NICKNAMES} = extConsts
import * as experiment from 'experiment'
import refComponentUtils from '../refComponent/refComponentUtils'
import {COMP_TYPES} from '@wix/document-manager-extensions/dist/src/constants/constants'

const {VALIDATIONS} = NICKNAMES
const {MOBILE, DESKTOP} = constants.VIEW_MODES
const {MASTER_PAGE_ID} = constants
type NicknamesMap = Record<string, string>

const ORIGINAL_CONTEXT_FIELD = 'originalNicknameContext'

function getNextSuffixIndex(ps: PS, compNickname: string, usedNicknames: NicknamesMap): number {
    const regex = new RegExp(`${compNickname}(\\d+)`) //will match the number in the end of the nickname
    const maxSuffixOfDefaultNickname = _(usedNicknames)
        .map(nickname => {
            const match = regex.exec(nickname)
            return match ? _.parseInt(match[1]) : null
        })
        .concat(0)
        .max()

    return maxSuffixOfDefaultNickname + 1
}

function setNicknamesForComponentsWithoutNickname(
    ps: PS,
    comps: CompRef[],
    defaultCompNickname: string,
    maxSuffixOfDefaultNickname: number,
    context: CompRef | null,
    pagePointer: CompRef
): CompRef[] {
    return _(comps)
        .filter(compPointer => shouldSetNickname(ps, compPointer, context))
        .map(function (comp) {
            while (
                hasComponentWithThatNickname(
                    ps,
                    pagePointer,
                    `${defaultCompNickname}${maxSuffixOfDefaultNickname}`,
                    comp
                )
            ) {
                maxSuffixOfDefaultNickname++
            }
            const newNickname = `${defaultCompNickname}${maxSuffixOfDefaultNickname}`

            setNickname(ps, comp, newNickname, context)
            return comp
        })
        .value()
}

function getNicknames(ps: PS, componentPointers: CompRef[], context: CompRef | null): NicknamesMap {
    return _(componentPointers)
        .map(compPointer => getComponentNickname(ps, compPointer, context))
        .reduce(_.assign)
}

function getComponentsInContainer(ps: PS, containerPointer: CompRef) {
    return ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(containerPointer)
}

function getComponentsInContext(ps: PS, pagePointer: CompRef, context: CompRef | null) {
    return context
        ? _.reject(getComponentsInContainer(ps, context), context)
        : getComponentsInContainer(ps, pagePointer)
}

function generateNicknamesForPage(ps: PS, usedNicknames: NicknamesMap, pagePointer: CompRef): NicknamesMap {
    const context = getNicknameContext(ps, pagePointer)
    const allCompsInPageContext = getComponentsInContext(ps, pagePointer, context)
    const usedNicknamesInPage = getNicknames(ps, allCompsInPageContext, context)
    const allUsedNickNames = _.assign({}, usedNicknamesInPage, usedNicknames)

    const componentsWithNewNicknamesInPage = generateNicknamesForComponentsImpl(
        ps,
        allCompsInPageContext,
        allUsedNickNames,
        context,
        pagePointer
    )

    return _.assign(usedNicknamesInPage, getNicknames(ps, componentsWithNewNicknamesInPage, context))
}

function generateNicknamesForComponentsImpl(
    ps: PS,
    compsPointers: CompRef[],
    usedNickNames: NicknamesMap,
    context: CompRef | null,
    pagePointer: CompRef
) {
    const compGroupsByBaseNickname = _.groupBy(compsPointers, compPointer =>
        componentsMetaData.getDefaultNickname(ps, compPointer)
    )

    return _.flatMap(compGroupsByBaseNickname, function (comps, defaultCompNickname) {
        const maxSuffixOfDefaultNickname = getNextSuffixIndex(ps, defaultCompNickname, usedNickNames)
        return setNicknamesForComponentsWithoutNickname(
            ps,
            comps,
            defaultCompNickname,
            maxSuffixOfDefaultNickname,
            context,
            pagePointer
        )
    })
}

function generateNicknamesForComponents(
    ps: PS,
    compsPointers: CompRef[],
    pagePointer: CompRef,
    viewMode: PossibleViewModes
) {
    const context = getNicknameContext(ps, pagePointer)
    const masterPagePointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, viewMode)
    const allCompsInContext = getComponentsInContext(ps, pagePointer, context).concat(
        getComponentsInContext(ps, masterPagePointer, context)
    )
    const usedNicknames = getNicknames(ps, allCompsInContext, context)

    generateNicknamesForComponentsImpl(ps, compsPointers, usedNicknames, context, pagePointer)
}

function generateNicknamesForPageInViewMode(
    ps: PS,
    usedNicknames: NicknamesMap,
    pageId: string,
    viewMode: string
): NicknamesMap {
    const desktopPage = ps.pointers.components.getPage(pageId, DESKTOP)
    const pageNicknames = generateNicknamesForPage(ps, usedNicknames, desktopPage)
    if (viewMode === MOBILE) {
        copyNicknamesFromDesktopToViewMode(ps, pageId)
        const mobilePage = ps.pointers.components.getPage(pageId, MOBILE)
        _.assign(pageNicknames, generateNicknamesForPage(ps, usedNicknames, mobilePage))
    }

    return pageNicknames
}

const getUsedNicknamesFromPageInViewMode = (ps: PS, pageId: string, viewMode: string): NicknamesMap => {
    const desktopPage = ps.pointers.components.getPage(pageId, DESKTOP)
    const nickNames = getNicknames(ps, getComponentsInContainer(ps, desktopPage), null)
    if (viewMode === MOBILE) {
        const mobilePage = ps.pointers.components.getPage(pageId, MOBILE)
        _.assign(nickNames, getNicknames(ps, getComponentsInContainer(ps, mobilePage), null))
    }
    return nickNames
}

/**
 * @param ps
 * @param requestedPages list if page ids
 * @param viewModeIfSupported default is current view mode
 * @param shouldCommitPerPage if true, commit after each page
 */
function generateNicknamesForPages(
    ps: PS,
    requestedPages: string[],
    viewModeIfSupported?: PossibleViewModes,
    shouldCommitPerPage: boolean = false
) {
    const viewMode = mobileUtil.getViewMode(ps, viewModeIfSupported, documentModeInfo)
    const masterPageNicknames = getUsedNicknamesFromPageInViewMode(ps, MASTER_PAGE_ID, viewMode)
    if (!requestedPages.includes(MASTER_PAGE_ID)) {
        for (const pageId of requestedPages) {
            generateNicknamesForPageInViewMode(ps, masterPageNicknames, pageId, viewMode)
            if (shouldCommitPerPage) {
                ps.dal.commitTransaction('componentCode.generateNicknamesForSite')
            }
        }
        return
    }

    const generatedNicknames: NicknamesMap = {}
    for (const pageId of _.without(requestedPages, 'masterPage')) {
        _.assign(generatedNicknames, generateNicknamesForPageInViewMode(ps, masterPageNicknames, pageId, viewMode))
        if (shouldCommitPerPage) {
            ps.dal.commitTransaction('componentCode.generateNicknamesForSite')
        }
    }

    const otherPages = _.difference(pageData.getPagesList(ps, false, true), requestedPages)
    const otherNicknames: NicknamesMap = {}
    for (const pageId of otherPages) {
        _.assign(otherNicknames, getUsedNicknamesFromPageInViewMode(ps, pageId, viewMode))
    }

    const nicknamesInAllSite = _.assign({}, masterPageNicknames, generatedNicknames, otherNicknames)
    generateNicknamesForPageInViewMode(ps, nicknamesInAllSite, MASTER_PAGE_ID, viewMode)
}

function copyNicknamesFromDesktopToViewMode(ps: PS, pageId: string) {
    const mobilePage = ps.pointers.components.getPage(pageId, MOBILE)
    const context = getNicknameContext(ps, mobilePage)
    const mobileComponents = getComponentsInContainer(ps, mobilePage)
    const desktopPage = ps.pointers.components.getPage(pageId, DESKTOP)
    _(mobileComponents)
        .filter(compPointer => shouldSetNickname(ps, compPointer, context))
        .forEach(function (viewModeCompPointer) {
            const desktopCompPointer = ps.pointers.components.getComponent(viewModeCompPointer.id, desktopPage)
            if (desktopCompPointer) {
                const desktopCompNickname = getNickname(ps, desktopCompPointer, context)
                if (desktopCompNickname) {
                    setNickname(ps, viewModeCompPointer, desktopCompNickname, context)
                }
            }
        })
}

//TODO: split this to private and public for pages and site
/**
 * @param ps
 * @param [viewModeIfSupported] default is current view mode
 * @param shouldCommitPerPage if true, commit after each page
 */
function generateNicknamesForSite(
    ps: PS,
    viewModeIfSupported?: PossibleViewModes,
    shouldCommitPerPage: boolean = false
) {
    const pagesPopupsAndMasterPage = pageData.getPagesList(ps, true, true)
    generateNicknamesForPages(ps, pagesPopupsAndMasterPage, viewModeIfSupported, shouldCommitPerPage)
}

function getNicknameContext(ps: PS, compPointer: CompRef) {
    if (experiment.isOpen('dm_moveNicknameContextProviderExt')) {
        return ps.extensionAPI.nicknameContext.getNicknameContext(compPointer)
    }
    return nicknameContextRegistrar.getNicknameContext(ps, compPointer)
}

function findConnectionByContext(context: CompRef | null, compConnections: IConnectionItem[]): IConnectionItem {
    return context
        ? (_.find(compConnections, {controllerRef: {id: context.id}}) as IConnectionItem)
        : getWixCodeConnectionItem(compConnections)
}

function findSerializedConnectionByContext(
    ps: PS,
    context: CompRef | null,
    compConnections: IConnectionItem[]
): IConnectionItem {
    if (context) {
        const {id: controllerId} = dataModel.getDataItem(ps, context) || {}
        if (controllerId) {
            return _.find(compConnections, {controllerId}) as IConnectionItem
        }
    }
    return getWixCodeConnectionItem(compConnections)
}

function getNicknameFromConnectionList(connectionList: IConnectionItem[], context: CompRef | null) {
    const connectionItem = findConnectionByContext(context, connectionList)
    if (connectionItem) {
        return connectionItem.role
    }
}

function getNickname(ps: PS, compPointer: CompRef, context: null | CompRef = getNicknameContext(ps, compPointer)) {
    const compConnections = connections.get(ps, compPointer)
    return getNicknameFromConnectionList(compConnections, context)
}

function getNicknameByConnectionPointer(ps: PS, connectionPtr: Pointer, pagePointer: CompRef, context: null | CompRef) {
    const connectionItem = connections.getByConnectionPointer(ps, connectionPtr, pagePointer)
    return getNicknameFromConnectionList(connectionItem, context)
}

function getNicknameForRefComp(ps: PS, compPointer: CompRef, compNickname: string, context) {
    const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointer)

    return _(compPointer)
        .thru(ps.pointers.referredStructure.getConnectionOverrides)
        .mapKeys(connectionPtr => refComponentUtils.extractBaseComponentId(connectionPtr))
        .mapValues(connectionPtr => getNicknameByConnectionPointer(ps, connectionPtr, pagePointer, context))
        .assign({[compPointer.id]: compNickname})
        .pickBy()
        .value()
}

function getComponentNickname(
    ps: PS,
    compPointer: CompRef,
    context: null | CompRef = getNicknameContext(ps, compPointer)
) {
    if (experiment.isOpen('dm_getNicknameFromExt')) {
        return ps.extensionAPI.nicknames.getComponentNickname(compPointer, context)
    }

    const compConnections = connections.get(ps, compPointer)
    const nickname = getNicknameFromConnectionList(compConnections, context)
    const componentType = componentStructureInfo.getType(ps, compPointer)

    if (componentType === COMP_TYPES.REF_TYPE) {
        return getNicknameForRefComp(ps, compPointer, nickname, context)
    }

    return nickname ? {[compPointer.id]: nickname} : {}
}

function getWixCodeConnectionItem(currentConnections: IConnectionItem[]): IConnectionItem {
    return _.find(currentConnections, {type: 'WixCodeConnectionItem'})
}

function createConnectionItem(context: null | CompRef, nickname: string): IConnectionItem {
    return context
        ? {
              type: 'ConnectionItem',
              role: nickname,
              // @ts-expect-error
              controllerRef: context,
              isPrimary: true
          }
        : {
              type: 'WixCodeConnectionItem',
              role: nickname
          }
}

function setNickname(ps: PS, compPointer: CompRef, nickname: string, context = getNicknameContext(ps, compPointer)) {
    if (validateNickname(ps, compPointer, nickname) !== VALIDATIONS.VALID) {
        throw new ReportableError({errorType: 'invalidNickname', message: 'The new nickname you provided is invalid'})
    }
    hooks.executeHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_BEFORE, 'updateConnectionsItem', [ps, compPointer, nickname])

    if (_.get(compPointer, 'id') === _.get(context, 'id')) {
        throw new Error('Cannot set nickname of context component to itself')
    }

    let currentConnections: IConnectionItem[] = connections.get(ps, compPointer)
    const connection = findConnectionByContext(context, currentConnections)
    if (connection) {
        connection.role = nickname
    } else {
        const newConnectionItem = createConnectionItem(context, nickname)
        currentConnections = [newConnectionItem].concat(currentConnections)
    }

    dataModel.updateConnectionsItem(ps, compPointer, currentConnections)
    hooks.executeHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_AFTER, 'updateConnectionsItem', [ps, compPointer, nickname])
}

function removeNickname(ps: PS, compPointer: CompRef, context = getNicknameContext(ps, compPointer)) {
    const currentConnections = connections.get(ps, compPointer)
    const connection = findConnectionByContext(context, currentConnections)
    if (connection) {
        _.remove(currentConnections, connection)
        if (currentConnections.length > 0) {
            dataModel.updateConnectionsItem(ps, compPointer, currentConnections)
        } else {
            dataModel.removeConnectionsItem(ps, compPointer)
        }
    }
}

function hasComponentWithThatNickname(
    ps: PS,
    containingPagePointer: Pointer,
    searchedNickname: string,
    compPointerToExclude?: Pointer
): boolean {
    if (!searchedNickname) {
        return false
    }

    return ps.extensionAPI.nicknames.hasComponentWithThatNickname(
        containingPagePointer,
        searchedNickname,
        compPointerToExclude
    )
}

function validateNickname(ps: PS, compPointer: CompRef, nickname: string) {
    return ps.extensionAPI.nicknames.validateNickname(
        compPointer,
        nickname,
        hasComponentWithThatNickname.bind(this, ps)
    )
}

function shouldSetNickname(ps: PS, compPointer: CompRef, context: null | CompRef): boolean {
    return (
        !ps.pointers.components.isMasterPage(compPointer) &&
        !getNickname(ps, compPointer, context) &&
        componentsMetaData.shouldAutoSetNickname(ps, compPointer)
    )
}

function removeNicknameFromComponentIfInvalid(ps: PS, compPointer: CompRef, containerPointer: CompRef) {
    const pagePointer = componentStructureInfo.isPageComponent(ps, containerPointer)
        ? containerPointer
        : componentStructureInfo.getPage(ps, containerPointer)
    const context = getNicknameContext(ps, pagePointer)
    _.forEach(ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer), function (comp) {
        const nickname = getNickname(ps, comp, context)
        if (hasComponentWithThatNickname(ps, pagePointer, nickname, comp)) {
            removeNickname(ps, comp, context)
        }
    })
}

function fixWixCodeConnections(ps: PS, context: null | CompRef, compDefinition: CompStructure) {
    const items = _.get(compDefinition, ['connections', 'items'], [])
    const wixCodeConnectionItem: WixCodeConnectionItem = _.find(items, {type: 'WixCodeConnectionItem'})
    if (context && wixCodeConnectionItem) {
        const {role} = wixCodeConnectionItem
        const controllerId = dataModel.getDataItem(ps, context).id
        const connectionItem = connections.createConnectionItem(role, controllerId)

        compDefinition.connections.items = _(items).without(wixCodeConnectionItem).concat([connectionItem]).value()
    }
}

function removeConnectionFromSerializedComponentIfInvalidNickname(
    ps: PS,
    compPointer: CompRef,
    context: null | CompRef,
    connectionItems: IConnectionItem[],
    pagePointer: CompRef
) {
    const nicknameConnectionItem = findSerializedConnectionByContext(ps, context, connectionItems)
    if (
        nicknameConnectionItem &&
        hasComponentWithThatNickname(
            ps,
            pagePointer,
            (nicknameConnectionItem as ControllerConnectionItem).role,
            compPointer
        )
    ) {
        _.remove(connectionItems, nicknameConnectionItem)
    }
}

function fixOrRemoveConnections(
    ps: PS,
    compPointer: CompRef,
    compDefinition: CompStructure,
    nicknameContext: CompRef,
    pagePointer: CompRef
) {
    fixWixCodeConnections(ps, nicknameContext, compDefinition)
    removeConnectionFromSerializedComponentIfInvalidNickname(
        ps,
        compPointer,
        nicknameContext,
        _.get(compDefinition, ['connections', 'items']),
        pagePointer
    )
}

function updateConnectionsIfNeeded(
    ps: PS,
    compPointer: CompRef,
    containerPointer: Pointer,
    compDefinition: SerializedCompStructure
) {
    const pagePointer = ps.pointers.full.components.getPageOfComponent(containerPointer)
    const nicknameContext = getNicknameContext(ps, pagePointer)

    fixOrRemoveConnections(ps, compPointer, compDefinition, nicknameContext, pagePointer)
}

function updateConnectionsRecursively(
    ps: PS,
    compPointer: CompRef,
    containerPointer: Pointer,
    rootCompDefinition: CompStructure
) {
    const pagePointer = ps.pointers.full.components.getPageOfComponent(containerPointer)
    const nicknameContext = getNicknameContext(ps, pagePointer)
    const compsQueue = [rootCompDefinition]

    while (compsQueue.length) {
        const compDefinition = compsQueue.shift()

        fixOrRemoveConnections(ps, compPointer, compDefinition, nicknameContext, pagePointer)

        compsQueue.push(...((compDefinition.components as CompStructure[]) || []))
    }
}

function getContextControllerId(ps: PS, context: CompRef | null) {
    return _.get(dataModel.getDataItem(ps, context), 'id')
}

function updateNicknameContextByNewContainer(
    ps: PS,
    compPointer: CompRef,
    componentDefinition: CompStructure,
    newContainerPointer: Pointer
) {
    const connectionItems = _.get(componentDefinition, ['connections', 'items'])
    if (_.isEmpty(connectionItems)) {
        return
    }

    const contextInCurrentContainer = _.get(componentDefinition, ['custom', ORIGINAL_CONTEXT_FIELD])
    if (contextInCurrentContainer) {
        const pagePointer = ps.pointers.full.components.getPageOfComponent(newContainerPointer)
        updateConnectionItemsNickname(ps, connectionItems, compPointer, pagePointer, contextInCurrentContainer)
    }
}

function updateConnectionItemsNickname(
    ps: PS,
    connectionItems: IConnectionItem[],
    compPointer: CompRef,
    pagePointer: CompRef,
    contextInCurrentContainer: CompRef
) {
    const connectionItem = findSerializedConnectionByContext(ps, contextInCurrentContainer, connectionItems)
    if (connectionItem) {
        const context = getNicknameContext(ps, pagePointer)
        ;(connectionItem as ControllerConnectionItem).controllerId = getContextControllerId(ps, context)
        removeConnectionFromSerializedComponentIfInvalidNickname(ps, compPointer, context, connectionItems, pagePointer)
    }
}

function setOriginalContextToSerializedComponent(ps: PS, compPointer: CompRef, customStructureData) {
    const context = getNicknameContext(ps, compPointer)

    if (context) {
        customStructureData[ORIGINAL_CONTEXT_FIELD] = context
    }
}

export default {
    getNicknameByConnectionPointer,
    generateNicknamesForComponents,
    generateNicknamesForSite,
    getNickname,
    setNickname,
    removeNickname,
    removeNicknameFromComponentIfInvalid,
    updateConnectionsIfNeeded,
    updateConnectionsRecursively,
    updateNicknameContextByNewContainer,
    setOriginalContextToSerializedComponent,
    updateConnectionItemsNickname,
    validateNickname,
    generateNicknamesForPages,
    hasComponentWithThatNickname,
    getComponentsInPage: getComponentsInContainer,
    findSerializedConnectionByContext,
    VALIDATIONS
}
