import {addIdToMap, getCustomId} from '../utils'
import {DATA_TYPES} from '../../../../constants/constants'
import {addNamespaceToStructure} from '../componentDeserializationStructure'
import type {DeserializeComponentParams} from '../types'

export const updateComponentConnectionsStructure = (deserializationParams: DeserializeComponentParams) => {
    const {compStructure, customId, mappers} = deserializationParams
    const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}
    deserializationParams.customId = getCustomId(oldToNewIdMap, compStructure.connections, customId)
    const connectionQuery = addNamespaceToStructure(
        deserializationParams,
        compStructure.connections,
        DATA_TYPES.connections,
        oldToNewIdMap
    )

    if (connectionQuery) {
        compStructure.connectionQuery = connectionQuery
        addIdToMap(compStructure.connections, connectionQuery, oldToNewIdMap)
    }
}
