import {DAL, pointerUtils} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {Component, Pointer} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import _ from 'lodash'

const {getRepeaterTemplateId, isRepeatedComponent} = displayedOnlyStructureUtil

export const getComponentIncludingDisplayOnly = (dal: DAL, componentPointer: Pointer): Component => {
    let component = dal.get(componentPointer)
    if (!component && isRepeatedComponent(componentPointer.id)) {
        const repeaterTemplateId = getRepeaterTemplateId(componentPointer.id)
        component = dal.get({id: repeaterTemplateId, type: componentPointer.type})
    }

    return component
}

const pointerToString = (componentPointer: Pointer) => {
    if (!componentPointer) {
        return '(null)'
    }
    let s = `type: ${componentPointer.type} id: ${componentPointer.id}`
    if ('innerPath' in componentPointer) {
        s += ` inner: ${componentPointer.innerPath}`
    }
    return s
}

const masterPage = 'wysiwyg.viewer.components.WSiteStructure'
const SPECIAL_COMP_TYPES = {
    masterPage
}

export const getComponentType = (dal: DAL, componentPointer: Pointer) => {
    if (componentPointer) {
        const specialCompType = SPECIAL_COMP_TYPES[componentPointer.id]
        if (specialCompType) {
            return specialCompType
        }

        componentPointer = pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer)
        const compData = dal.get(componentPointer)
        return _.get(compData, ['componentType']) as string
    }
    throw new ReportableError({
        errorType: 'nonExistingCompPointer',
        message: `non existing component pointer: ${pointerToString(componentPointer)}`
    })
}
