import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import dsUtils from '../utils/utils'
import relationsUtils from '../variants/relationsUtils'
import * as experiment from 'experiment'
import type {VariablesExtensionAPI} from '@wix/document-manager-extensions/src/extensions/variables/variables'

const {DATA_TYPES} = constants
const VARIABLES_NAMESPACE = DATA_TYPES.variables

const getComponentVariablesIds = (ps: PS, componentPointer: Pointer) => {
    const variablesListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, VARIABLES_NAMESPACE)
    if (!ps.dal.isExist(variablesListPointer)) {
        return []
    }
    return ps.dal.get(variablesListPointer).variables.map(dsUtils.stripHashIfExists)
}

const collectAncestorsVariablesIds = (ps: PS, componentPointer: Pointer) => {
    if (experiment.isOpen('dm_moveCollectAncestorsVariablesIdsToExt')) {
        return (ps.extensionAPI as VariablesExtensionAPI).variables.collectAncestorsVariablesIds(componentPointer)
    }

    const ancestors = [componentPointer, ...ps.pointers.full.components.getAncestors(componentPointer)]
    const variablesIds = _.reduce(
        ancestors,
        (vars, ancestor) => {
            const compVariables = getComponentVariablesIds(ps, ancestor)
            vars.push(...compVariables)
            return vars
        },
        []
    )
    return _.uniq(variablesIds)
}

const removeOutOfScopeVariablesFromRefArray = (
    ps: PS,
    componentPointer: Pointer,
    dataType: string,
    scopedVariables
) => {
    const componentPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
    const relationIds = relationsUtils.extractDataItemsIds(ps, componentPointer, dataType)
    _.forEach(relationIds, relationId => {
        const relationPointer = ps.pointers.data.getItem(dataType, relationId, componentPage.id)
        const variableConnectionsPointer = ps.pointers.getInnerPointer(relationPointer, 'variableConnections')
        if (ps.dal.isExist(variableConnectionsPointer)) {
            const variableConnections = ps.dal.get(variableConnectionsPointer)
            const newVariableConnections = _.filter(variableConnections, ({variableId}) =>
                scopedVariables.includes(variableId)
            )
            ps.dal.set(variableConnectionsPointer, newVariableConnections)
        }
    })
}

export default {
    getComponentVariablesIds,
    collectAncestorsVariablesIds,
    removeOutOfScopeVariablesFromRefArray
}
