import {DalValue, DmApis, Extension, ExtensionAPI, pointerUtils, ValidateValue} from '@wix/document-manager-core'
import type {Pointer, Variable} from '@wix/document-services-types'
import _ from 'lodash'
import type {DataModelExtensionAPI} from '../..'
import type {RelationshipsAPI} from '../relationships'
import {getIdFromRef} from '../../utils/dataUtils'
import * as constants from '../../constants/constants'
import * as refArrayUtils from '../../utils/refArrayUtils'

const {getPointer} = pointerUtils

const VARIABLES_NAMESPACE = constants.DATA_TYPES.variables
const LAYOUT_NAMESPACE = constants.DATA_TYPES.layout
const VARIABLES_LIST_TYPE = 'VariablesList'
const REF_ARRAY_TYPE = constants.REF_ARRAY_DATA_TYPE
const VARIANT_RELATION_TYPE = constants.RELATION_DATA_TYPES.VARIANTS
const {DESKTOP, MOBILE} = constants.VIEW_MODES

const NAMESPACES_SUPPORTING_VARIABLES = new Set([LAYOUT_NAMESPACE])

const createExtension = (): Extension => {
    const getCompPointersByDataItem = (
        relationships: RelationshipsAPI['relationships'],
        pointer: Pointer
    ): Pointer[] => {
        if (pointer.type === DESKTOP || pointer.type === MOBILE) {
            return [pointer]
        }

        const references = relationships.getOwningReferencesToPointer(pointer)
        return references.flatMap(p => getCompPointersByDataItem(relationships, p))
    }

    const comparePointers = (p1: Pointer, p2: Pointer) => p1.type === p2.type && p1.id === p2.id

    const createExtensionAPI = ({extensionAPI, pointers}: DmApis): VariablesExtensionAPI => {
        const {relationships} = extensionAPI as RelationshipsAPI
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const getComponentsUsingVariable = (variablePointer: Pointer, viewMode: string = DESKTOP): Pointer[] =>
            _(relationships.getReferencesToPointer(variablePointer))
                .filter(({type}) => type !== VARIABLES_NAMESPACE)
                .flatMap(pointer => getCompPointersByDataItem(relationships, pointer))
                .filter(({type}) => type === viewMode)
                .uniqWith(comparePointers)
                .value()

        const getAllVariablesPointers = (componentPointer: Pointer): Pointer[] => {
            const variablesListItem = dataModel.components.getItem(componentPointer, VARIABLES_NAMESPACE)
            return _.map(variablesListItem?.variables, ({id}) => getPointer(getIdFromRef(id), VARIABLES_NAMESPACE))
        }

        const getComponentVariablesIds = (componentPointer: Pointer): string[] => {
            const variablesItem = dataModel.components.getItem(componentPointer, VARIABLES_NAMESPACE)
            if (!variablesItem) {
                return []
            }
            return variablesItem.variables.map((variant: DalValue) => variant.id)
        }

        const collectAncestorsVariablesIds = (componentPointer: Pointer) => {
            const ancestors = [componentPointer, ...pointers.structure.getAncestors(componentPointer)].filter(
                pointer => !_.isNil(pointer)
            )
            const variablesIds = _.reduce(
                ancestors,
                (vars: string[], ancestor) => {
                    const compVariables = getComponentVariablesIds(ancestor)
                    vars.push(...compVariables)
                    return vars
                },
                []
            )
            return _.uniq(variablesIds)
        }

        return {
            variables: {
                getComponentsUsingVariable,
                list: getAllVariablesPointers,
                collectAncestorsVariablesIds
            }
        }
    }

    const createValidator = ({dal, pointers, extensionAPI}: DmApis): Record<string, ValidateValue> => {
        const {relationships} = extensionAPI as RelationshipsAPI

        const variablesListPath = ['variables']
        const variableTypes = dal.schema
            .extractReferenceFieldsInfo(VARIABLES_NAMESPACE, VARIABLES_LIST_TYPE)
            .find(({path}) => _.isEqual(path, variablesListPath))!.refTypes
        const variableTypesSet = new Set(variableTypes)

        const isEligibleForTypeValidator = (pointer: Pointer, value?: DalValue) =>
            value?.type && pointer.type === VARIABLES_NAMESPACE && value.type !== VARIANT_RELATION_TYPE

        const createValidatorResult = (type: string, message: string) => [
            {
                shouldFail: true,
                type,
                message,
                extras: {}
            }
        ]

        const createTypeValidatorResult = (type: string) =>
            createValidatorResult(type, 'Variable type is not matching the value type')

        const getIdsByRefArrayValues = (refArrayValues: string[]) => {
            return refArrayValues.map((id: string) => {
                const value = dal.get(getPointer(id, VARIABLES_NAMESPACE))
                if (value.type !== VARIANT_RELATION_TYPE) return id

                return refArrayUtils.variantRelation.extractTo(value)
            })
        }

        const getRefArrayByVariable = (variable: Variable) => {
            const references = dal.schema.getReferences(VARIABLES_NAMESPACE, variable)[0]
            if (!references) return
            const {id, refInfo} = references
            const refArrayPointer = getPointer(id, VARIABLES_NAMESPACE)
            const refArray = dal.get(refArrayPointer)
            const refArrayValues = refArrayUtils.refArray.extractValuesWithoutHash(refArray)
            const varValIds = getIdsByRefArrayValues(refArrayValues)

            return {
                refArray,
                varValIds,
                refInfo
            }
        }

        const getRelationalSplitNodePointer = (pointer: Pointer) => {
            const ownerPointer = relationships.getOwningReferencesToPointer(pointer, pointer.type)[0]

            if (!ownerPointer) return

            const ownerObj = dal.get(ownerPointer)

            if (ownerObj.type === VARIANT_RELATION_TYPE) {
                const splittingNodeId = refArrayUtils.variantRelation.extractFrom(ownerObj)

                return getPointer(splittingNodeId, pointer.type)
            }

            if (ownerObj.type === REF_ARRAY_TYPE) {
                return relationships.getOwningReferencesToPointer(ownerPointer, pointer.type)[0]
            }
        }

        const extractRelationalSplitSchemaInfo = (pointer: Pointer, value: DalValue) =>
            dal.schema
                .extractReferenceFieldsInfo(pointer.type, value.type)
                ?.find(({isRelationalSplit}) => isRelationalSplit)

        const isEqualOrDescendant = (compPointer: Pointer, possibleAncestorPointer: Pointer) =>
            comparePointers(compPointer, possibleAncestorPointer) ||
            pointers.structure.isDescendant(compPointer, possibleAncestorPointer)

        const checkCompDescendentOfOwnersByViewMode = (
            compPointer: Pointer,
            variableOwnerCompsPointers: Pointer[],
            viewMode: string
        ) =>
            _(variableOwnerCompsPointers)
                .filter(({type}) => type === viewMode)
                .every(ownerCompPointer => isEqualOrDescendant(compPointer, ownerCompPointer))

        const getReferredVariablePointers = (pointer: Pointer) => {
            const referredPointers = relationships.getReferredPointers(pointer)
            return referredPointers.filter(
                referredPointer =>
                    referredPointer.type === VARIABLES_NAMESPACE && variableTypesSet.has(dal.get(referredPointer)?.type)
            )
        }

        const getVariableOwnerComps = (variablePointers: Pointer[]) => {
            return _(variablePointers)
                .flatMap(variablePointer =>
                    relationships.getOwningReferencesToPointer(variablePointer, VARIABLES_NAMESPACE)
                )
                .flatMap(variablesListPointers => relationships.getOwningReferencesToPointer(variablesListPointers))
                .uniqWith(comparePointers)
                .value()
        }

        const validateVariableType = (pointer: Pointer, value?: DalValue) => {
            if (!isEligibleForTypeValidator(pointer, value) || !variableTypesSet.has(value.type)) return

            const {varValIds, refInfo} = getRefArrayByVariable(value) ?? {}
            const varValTypes =
                refInfo &&
                varValIds?.map(varValId => dal.get(getPointer(varValId, VARIABLES_NAMESPACE, {innerPath: ['type']})))
            if (!varValTypes || varValTypes.some(varValType => !refInfo.refTypes.includes(varValType))) {
                return createTypeValidatorResult('validateVariableType')
            }
        }

        const validateVariableValueType = (pointer: Pointer, value?: DalValue) => {
            if (!isEligibleForTypeValidator(pointer, value)) return

            const relationalSplitNodePointer = getRelationalSplitNodePointer(pointer)
            const relationalSplitNode = relationalSplitNodePointer && dal.get(relationalSplitNodePointer)
            if (!relationalSplitNode) return

            const {refTypes} = extractRelationalSplitSchemaInfo(pointer, relationalSplitNode) ?? {}

            if (refTypes && !refTypes.includes(value.type)) {
                return createTypeValidatorResult('validateVariableValueType')
            }
        }

        const validateVariableHierarchicalUsage = (pointer: Pointer, value?: DalValue) => {
            // Check if the given value is eligible for the validator.
            if (!value || !NAMESPACES_SUPPORTING_VARIABLES.has(pointer.type)) return

            // Find the variables that are in use by this data item.
            const referredVariablePointers = getReferredVariablePointers(pointer)
            if (referredVariablePointers.length === 0) return

            // Find the components that own the used variables.
            const variableOwnerCompsPointers = getVariableOwnerComps(referredVariablePointers)

            // Get the component pointer of the data item (could be DESKTOP and MOBILE components).
            const variableUserCompsPointers = getCompPointersByDataItem(relationships, pointer)

            if (_.every(variableOwnerCompsPointers, (owner: Pointer) => pointers.structure.isMasterPage(owner))) {
                return
            }

            // Make sure that the component that uses the variables is a descendent of the owners (in both DESKTOP and MOBILE).
            for (const compPointer of variableUserCompsPointers) {
                if (!checkCompDescendentOfOwnersByViewMode(compPointer, variableOwnerCompsPointers, compPointer.type)) {
                    return createValidatorResult('validateVariableHierarchicalUsage', 'Variable is used out of scope')
                }
            }
        }

        return {
            validateVariableType,
            validateVariableValueType,
            validateVariableHierarchicalUsage
        }
    }

    return {
        name: 'variables',
        dependencies: new Set(['structure', 'relationships', 'dataModel']),
        createExtensionAPI,
        createValidator
    }
}

export interface VariablesExtensionAPI extends ExtensionAPI {
    variables: {
        getComponentsUsingVariable(variablePointer: Pointer, viewMode?: string): Pointer[]
        list(componentPointer: Pointer): Pointer[]
        collectAncestorsVariablesIds(componentPointer: Pointer): string[]
    }
}

export {createExtension}
