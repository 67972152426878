/* eslint-disable promise/prefer-await-to-then */
import type {AppDefinitionId, ApplicationId, PS} from '@wix/document-services-types'
import _ from 'lodash'
import {platformInit} from '@wix/santa-ds-libs'
import hooks from '../hooks/hooks'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import workerService from './services/workerService'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import appStoreService from '../tpa/services/appStoreService'
import originService from './services/originService'
import constants from './common/constants'
import appComponents from './appComponents'
import wixCode from '../wixCode/wixCode'
import dsConstants from '../constants/constants'
import platformStateService from './services/platformStateService'
import copyDataFromTemplate from './services/copyDataFromTemplate'
import generalInfo from '../siteMetadata/generalInfo'
import platformAppDataGetter from './services/platformAppDataGetter'
import permissionsUtils from '../tpa/utils/permissionsUtils'
import {ReportableError} from '@wix/document-manager-utils'
import callbackUtils from '../utils/callbackUtils'
import {loadRemoteDataForApp} from './services/loadRemoteWidgetMetaData'

const {getOnSuccessWithFallback} = callbackUtils

const getOriginInfo = (appDefinitionId: AppDefinitionId, installOptions) => {
    if (!appDefinitionId) {
        return null
    }
    if (installOptions?.platformOrigin) {
        const info = installOptions?.platformOrigin?.info
        return info?.type || info?.appDefinitionId
    }
    if (installOptions?.origin) {
        const info = installOptions?.origin?.info
        return info?.type || info?.appDefinitionId
    }
    return null
}

const {hasCodePackage} = appComponents

const isTestVersion = (version: string) => version === 'latest'

const getProvisionInteractionParams = (appDefinitionId: AppDefinitionId, options, eventGuid) => {
    const installationOriginInfo = getOriginInfo(appDefinitionId, options)
    return {
        eventGuid,
        tags: {
            origin_info: installationOriginInfo,
            appDefinitionId
        },
        extras: {app_id: appDefinitionId, firstInstall: options?.firstInstall}
    }
}

const extendOptionWithInternalOrigin = (options, internalOrigin: string) =>
    _.setWith(options, ['internalOrigin'], internalOrigin, Object)

function provision(ps: PS, appDefinitionId: AppDefinitionId, options?) {
    const eventGuid = _.random(10000, true)
    ps.extensionAPI.logger.interactionStarted(
        dsConstants.PLATFORM_INTERACTIONS.PROVISION,
        getProvisionInteractionParams(appDefinitionId, options, eventGuid)
    )

    return new Promise<any>(function (resolve, reject) {
        if (generalInfo.isTemplate(ps)) {
            reject(new Error('cannot add apps on template'))
            return
        }
        if (!appDefinitionId) {
            reject(new Error('options must contain appDefinitionId'))
            return
        }

        const onSuccess = args => {
            ps.extensionAPI.logger.interactionEnded(
                dsConstants.PLATFORM_INTERACTIONS.PROVISION,
                getProvisionInteractionParams(appDefinitionId, options, eventGuid)
            )
            resolve(args)
        }

        if (appDefinitionId === constants.APPS.WIX_CODE.appDefId) {
            if (!workerService.isInitiated()) {
                reject(workerService.getNotInitError(ps, 'failed provisioning wix code'))
                return
            }
            wixCode.provision(ps, {
                onSuccess,
                onError: reject
            })
            return
        }

        const resolveWithEnd = getOnSuccessWithFallback('provision', reject, onSuccess)
        addApp(ps, appDefinitionId, options, resolveWithEnd, reject)
    })
}

async function update(ps: PS, appDefinitionId: AppDefinitionId, applicationVersion: string, options?) {
    if (workerService.isInitiated()) {
        return await updateApp(ps, appDefinitionId, applicationVersion, options)
    }
    return Promise.reject('workerService is not initiated')
}

async function updateApp(ps: PS, appDefinitionId: AppDefinitionId, applicationVersion: string, options) {
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (!existingAppData) {
        return Promise.reject(
            'Application with the given appDefinitionId is not installed. Please provision before updating'
        )
    }
    if (applicationVersion === existingAppData.appFields.installedVersion && !isTestVersion(applicationVersion)) {
        return onUpdateSuccess(ps, options, existingAppData)
    }

    const updatedClientSpecMap = await appStoreService.update(
        ps,
        [
            {
                appDefinitionId,
                appVersion: applicationVersion
            }
        ],
        options
    )

    return onUpdateSuccess(ps, options, _.find(updatedClientSpecMap, {appDefinitionId}))
}

async function onUpdateSuccess(ps: PS, options, appData) {
    const reportProgress = _.get(options, 'reportProgress')
    if (reportProgress) {
        reportProgress({step: 'clientSpecMap'})
    }

    clientSpecMap.registerAppData(ps, appData)
    clientSpecMap.setAppInstallStateByAppData(ps, appData)

    if (hasCodePackage(appData)) {
        await wixCode.codePackages.installCodeReusePkg(
            ps,
            appData.appDefinitionId,
            appData.appFields.installedVersion ?? options[appData.appDefinitionId]?.appVersion
        )
    }

    try {
        await workerService.notifyAppUpdated(ps, appData.appDefinitionId, options)

        return appData
    } finally {
        hooks.executeHook(hooks.HOOKS.PLATFORM.APP_UPDATED, '', [ps, appData])
    }
}

function addApp(ps: PS, appDefinitionId: AppDefinitionId, options, resolve, reject) {
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
        //when coming from presets (sourceTemplateId)
        appStoreService.provision(
            ps,
            [
                {
                    appDefinitionId,
                    version: options?.appVersion,
                    sourceTemplateId: options?.sourceTemplateId
                }
            ],
            getOnSuccessWithFallback('addApp', reject, provisionResponse => {
                const csm = provisionResponse.clientSpecMap
                const appData = _.find(csm, {appDefinitionId})
                if (appData) {
                    const origin_instance_id = _.get(options, 'origin_instance_id')
                    if (origin_instance_id) {
                        const {copyDataFromOriginTemplateByApp} = copyDataFromTemplate
                        copyDataFromOriginTemplateByApp(ps, appDefinitionId, appData, {origin_instance_id})
                            .then(() => {
                                extendOptionWithInternalOrigin(
                                    options,
                                    constants.ADD_APP_ORIGINS.ADD_APP_WITH_COPY_DATA_SUCC
                                )
                                loadRemoteDataForApp(ps, appData)
                                    .then(() => {
                                        onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                                    })
                                    .catch(() => {
                                        reject()
                                    })
                            })
                            .catch(() => {
                                extendOptionWithInternalOrigin(
                                    options,
                                    constants.ADD_APP_ORIGINS.ADD_APP_WITH_COPY_DATA_ERROR
                                )
                                onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                            })
                    } else {
                        extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP)
                        loadRemoteDataForApp(ps, appData)
                            .then(() => {
                                onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                            })
                            .catch(() => {
                                reject()
                            })
                    }
                } else {
                    reject('Application wasnt installed')
                }
            }),
            reject
        )
    } else {
        extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_EXISTING)
        onPreSaveProvisionSuccess(ps, resolve, reject, options, existingAppData)
    }
}

function onPreSaveProvisionSuccess(
    ps: PS,
    resolve,
    reject,
    options: any = {},
    appData?,
    internalInstallInfo: {flowId?: string; origin_info?: any; sourceApi?: string} = {}
) {
    const appDefinitionId = _.get(appData, 'appDefinitionId')
    const installationOriginInfo = getOriginInfo(appDefinitionId, options)
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    const eventGuid = _.random(10000, true)
    ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
        eventGuid,
        tags: {
            origin_info: installationOriginInfo,
            appDefinitionId,
            sourceApi: internalInstallInfo?.sourceApi
        },
        extras: {app_id: appDefinitionId, firstInstall: options?.firstInstall}
    })

    if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
        clientSpecMap.registerAppData(ps, appData)
        ps.extensionAPI.appsInstallState.setAppInstalled(
            appData.appDefinitionId,
            {
                version: clientSpecMapService.getAppVersion(appData)
            },
            'onPreSaveProvisionSuccess'
        )
    }

    if (!clientSpecMapService.hasEditorPlatformPart(appData)) {
        const addAppResponse = _.assign(
            {
                success: true,
                type: 'addAppCompleted'
            },
            appData
        )

        ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
            eventGuid,
            tags: {
                origin_info: installationOriginInfo,
                appDefinitionId,
                sourceApi: internalInstallInfo?.sourceApi
            },
            extras: {
                app_id: appDefinitionId,
                addAppResponse: addAppResponse?.success,
                firstInstall: options?.firstInstall
            }
        })
        resolve(addAppResponse)
    } else {
        if (!workerService.isInitiated()) {
            const err = workerService.getNotInitError(ps, 'onPreSaveProvisionSuccess')
            reject(err)
            return
        }
        appData.firstInstall = true
        if (options.isSilent) {
            appData.silentInstallation = true
        }

        if (options.headlessInstallation) {
            appData.headlessInstallation = true
        }

        appData.origin = _.get(options, 'origin')
        if (!appData.origin || typeof appData.origin !== 'object') {
            appData.origin = {}
        }
        appData.origin.type = appData.origin.type || originService.getEditorType()
        appData.biData = _.get(options, 'biData')

        appData.settings = _.get(options, 'settings')

        workerServiceAddAppWrapper(ps, appData, options, internalInstallInfo)
            .then((addAppResponse: any) => {
                ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
                    tags: {
                        origin_info: installationOriginInfo,
                        appDefinitionId,
                        sourceApi: internalInstallInfo.sourceApi
                    },
                    extras: {
                        app_id: appDefinitionId,
                        addAppResponse: addAppResponse?.success,
                        firstInstall: appData?.firstInstall
                    }
                })
                resolve(addAppResponse)
            })
            .catch(addAppError => {
                ps.extensionAPI.logger.captureError(
                    new ReportableError({
                        errorType: dsConstants.PLATFORM_ERRORS.ON_PRE_SAVE_PROVISION,
                        message: 'worker service add app failed',
                        tags: {
                            appDefinitionId,
                            origin_info: installationOriginInfo,
                            sourceApi: internalInstallInfo.sourceApi
                        },
                        extras: {
                            errName: dsConstants.PLATFORM_ERRORS.ON_PRE_SAVE_PROVISION,
                            app_id: appDefinitionId,
                            originalError: addAppError,
                            firstInstall: appData?.firstInstall
                        }
                    })
                )
                reject(addAppError)
            })
    }
}

async function onRemoteProvision(ps: PS, appData) {
    if (platformAppDataGetter.hasAppManifest(ps, appData.appDefinitionId)) {
        return
    }

    if (!clientSpecMapService.hasEditorPlatformPart(appData)) {
        return
    }

    if (!workerService.isInitiated()) {
        return Promise.reject(workerService.getNotInitError(ps, 'onRemoteProvision'))
    }

    appData.firstInstall = false
    appData.silentInstallation = true

    if (!appData.origin) {
        appData.origin = {type: originService.getEditorType()}
    }

    await workerServiceAddAppWrapper(ps, appData, {origin: 'onRemoveProvision'})
}

async function workerServiceAddAppWrapper(
    ps: PS,
    appData,
    options,
    internalInstallInfo: {flowId?: string; origin_info?: any; sourceApi?: string} = {}
) {
    const installationOriginInfo = getOriginInfo(appData.appDefinitionId, options)
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    const appDataWithResolvedUrl = platformInit.specMapUtils.resolveEditorScriptUrl(appData, {
        clientSpec: appData,
        serviceTopology
    })

    return await new Promise((resolve, reject) => {
        const eventGuid = _.random(10000, true)
        ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.WORKER_ADD_APP, {
            eventGuid,
            tags: {
                origin_info: installationOriginInfo,
                appDefinitionId: appData.appDefinitionId,
                sourceApi: internalInstallInfo.sourceApi
            },
            extras: {
                appDefinitionId: appData.appDefinitionId,
                origin: _.get(options, 'origin'),
                internalOrigin: _.get(options, 'internalOrigin'),
                firstInstall: appData?.firstInstall
            }
        })
        workerService.addApp(
            ps,
            appDataWithResolvedUrl,
            function (data) {
                const resultData = _.assign(appDataWithResolvedUrl, data || {})
                ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.WORKER_ADD_APP, {
                    eventGuid,
                    tags: {
                        origin_info: installationOriginInfo,
                        appDefinitionId: appData.appDefinitionId,
                        sourceApi: internalInstallInfo.sourceApi
                    },
                    extras: {
                        appDefinitionId: appData.appDefinitionId,
                        resultData: data,
                        firstInstall: appData?.firstInstall
                    }
                })
                ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.AFTER_APP_ADDED_ACTIONS, {
                    tags: {
                        origin_info: installationOriginInfo,
                        appDefinitionId: appData.appDefinitionId,
                        sourceApi: internalInstallInfo.sourceApi
                    },
                    extras: {
                        appDefinitionId: appData.appDefinitionId,
                        firstInstall: appData?.firstInstall
                    }
                })
                if (_.get(resultData, 'success')) {
                    hooks.executeHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, '', [ps])
                    ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.AFTER_APP_ADDED_ACTIONS, {
                        tags: {
                            origin_info: installationOriginInfo,
                            appDefinitionId: appData.appDefinitionId,
                            sourceApi: internalInstallInfo.sourceApi
                        },
                        extras: {
                            appDefinitionId: appData.appDefinitionId,
                            firstInstall: appData?.firstInstall
                        }
                    })
                    resolve(resultData)
                } else {
                    permissionsUtils.shouldAvoidRevoking({ps}).then(shouldAvoidRevoking => {
                        if (shouldAvoidRevoking) {
                            platformStateService.setUnusedApps(ps, [appData])
                        } else {
                            platformStateService.setAppPendingAction(
                                ps,
                                appData.appDefinitionId,
                                constants.APP_ACTION_TYPES.REMOVE
                            )
                        }

                        clientSpecMap.registerAppData(ps, appData)
                        ps.extensionAPI.appsInstallState.setAppUninstalled(
                            appData.appDefinitionId,
                            'workerServiceAddAppWrapper'
                        )

                        hooks.executeHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, '', [ps])
                        ps.extensionAPI.logger.captureError(
                            new ReportableError({
                                errorType: 'workerServiceAddAppError',
                                message: 'workerService.addApp error'
                            }),
                            {
                                tags: {
                                    sourceApi: internalInstallInfo.sourceApi,
                                    origin_info: installationOriginInfo,
                                    appDefinitionId: appData.appDefinitionId,
                                    handleAppProvisionedOrUpdated: true
                                },
                                extras: {
                                    errName: dsConstants.PLATFORM_ERRORS.AFTER_APP_ADDED_ACTIONS,
                                    appDefinitionId: appData.appDefinitionId,
                                    resultData: data,
                                    firstInstall: appData?.firstInstall
                                }
                            }
                        )
                        const {instanceId, appDefinitionName} = resultData
                        const extendedFailResponse = {
                            ...data,
                            instanceId,
                            appDefinitionName
                        }
                        reject(extendedFailResponse)
                    })
                }
            },
            options
        )
    })
}

export default {
    provision,
    update,
    update_publicApi: (ps: PS, applicationId: ApplicationId, applicationVersion: string, options?: any) => {
        if (applicationId) {
            const appDefinitionId = clientSpecMap.getAppDefinitionIdFromApplicationId(ps, applicationId, {
                source: 'provision.update'
            })
            return update(ps, appDefinitionId, applicationVersion, options)
        }
    },
    onPreSaveProvisionSuccess,
    onRemoteProvision,
    extendOptionWithInternalOrigin
}
