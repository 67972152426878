import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'
import {MULTILINGUAL_TYPES, PAGE_DATA_TYPE_TO_POINTER_TYPE} from '../constants/constants'
import {pointerUtils} from '@wix/document-manager-core'
import {getTranslationItemKey} from './translationUtils'

const MOBILE_COMPONENTS = 'mobileComponents'
const MASTER_PAGE_CHILDREN = 'children'
const COMPONENTS = 'components'

function getChildrenKey(data: any, isMobile: boolean) {
    if (isMobile && data.mobileComponents) {
        return MOBILE_COMPONENTS
    }
    return data.children ? MASTER_PAGE_CHILDREN : COMPONENTS
}

function getChildrenComponents(structure: any, isMobile: boolean) {
    const children = isMobile ? structure.mobileComponents : structure.children
    return children || structure.components || []
}

const omitPaths = [MASTER_PAGE_CHILDREN, MOBILE_COMPONENTS]

const convertChildrenToIds = (comp: any, parentId: string | undefined, isMobile: boolean, pageId: string) => {
    const compChildren = comp[getChildrenKey(comp, isMobile)]
    const newComp: any = _.omit(comp, omitPaths)
    newComp.metaData = {...comp.metaData, pageId}
    if (parentId) {
        newComp.parent = parentId
    }
    if (compChildren) {
        newComp.components = compChildren.map((item: any) => item.id)
    }
    return newComp
}

const flattenRecursive = (
    structure: any,
    parentId: string | undefined,
    pageId: string,
    isMobile: boolean,
    result: any
) => {
    const {id} = structure
    result[id] = convertChildrenToIds(structure, parentId, isMobile, pageId)
    getChildrenComponents(structure, isMobile).forEach((childStructure: any) => {
        flattenRecursive(childStructure, id, pageId, isMobile, result)
    })
}

function convertNestedPageStructureToFlat(structure: any, pageId: string, isMobile: boolean) {
    const result = {}
    flattenRecursive(structure, undefined, pageId, isMobile, result)
    return result
}

type AddFunc = (pointer: Pointer, value: any) => void

const addPageData = (pageId: string, dataMap: any, addFunc: AddFunc) => {
    _.forEach(dataMap, (typeMap, type) => {
        _.forEach(typeMap, (data, id) => {
            const pointerType = PAGE_DATA_TYPE_TO_POINTER_TYPE[type]
            const pointer = pointerUtils.getPointer(id, pointerType)
            const dataWithPageAndId = _.merge(data, {metaData: {pageId}, id})
            addFunc(pointer, dataWithPageAndId)
        })
    })
}

const addPageStructure = (nestedPage: any, isMobile: boolean, addFunc: AddFunc) => {
    const pageId = nestedPage.id
    const flatPage = convertNestedPageStructureToFlat(nestedPage, pageId, isMobile)
    if (isMobile && nestedPage.mobileMetaData) {
        flatPage[pageId].metaData = {...nestedPage.mobileMetaData, pageId}
    }

    const pointerType = isMobile ? 'MOBILE' : 'DESKTOP'
    _.forEach(flatPage, (value, compId) => {
        const pointer = pointerUtils.getPointer(compId, pointerType)
        addFunc(pointer, value)
    })
}

const getTranslationPointer = (pageId: string, lang: string, itemId: string) => ({
    ...pointerUtils.getPointer(getTranslationItemKey(lang, itemId), MULTILINGUAL_TYPES.multilingualTranslations),
    pageId
})

const addPageTranslations = (pageId: string, translations: any, addFunc: AddFunc) => {
    if (!_.isEmpty(translations)) {
        _.forEach(translations, (translationData, lang) => {
            const dataMap = _.get(translationData, ['data', 'document_data'], {})
            _.forEach(dataMap, (dataItem, dataId) => {
                if (dataId !== 'masterPage') {
                    const pointer = getTranslationPointer(pageId, lang, dataId)
                    const valueWithPageId = _.merge(dataItem, {metaData: {pageId}})
                    addFunc(pointer, valueWithPageId)
                }
            })
        })
    }
}

export const addPageJson = (pageId: string, pageJson: any, addFunc: (pointer: Pointer, value: any) => void) => {
    addPageData(pageId, pageJson.data, addFunc)
    addPageStructure(pageJson.structure, false, addFunc)
    addPageStructure(pageJson.structure, true, addFunc)
    addPageTranslations(pageId, pageJson.translations, addFunc)
}
