import type {CompRef, Pointer} from '@wix/document-services-types'
import {DATA_TYPES} from '../constants/constants'
import type {CreateExtArgs, DmApis, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {CreateViewerExtensionArgument} from '../types'
import type {HooksExtensionApi} from './hooks/hooks'
import type {VariantsExtensionAPI} from './variants/variants'
import {RENDER_HOOKS} from './viewer/hooks'

interface RemeasureAPI {
    isUpdated(compRef: CompRef): boolean
}
export type RemeasureExtensionAPI = ExtensionAPI & {
    remeasure: RemeasureAPI
}

const createExtension = ({}: CreateViewerExtensionArgument): Extension => {
    const updatedLayouts = new Set<string>()

    const createExtensionAPI = ({extensionAPI}: CreateExtArgs): RemeasureExtensionAPI => {
        const {variants} = extensionAPI as VariantsExtensionAPI

        const isUpdated = (compRef: CompRef) => {
            const compLayoutPointer = variants.getDataPointerConsideringVariants(compRef, DATA_TYPES.layout)
            if (!compLayoutPointer) return false

            return updatedLayouts.has(compLayoutPointer.id)
        }

        return {
            remeasure: {
                isUpdated
            }
        }
    }

    const createPostSetOperation =
        () =>
        ({type: namespace, id}: Pointer) => {
            if (namespace === DATA_TYPES.layout) {
                updatedLayouts.add(id)
            }
        }

    const initialize = async ({extensionAPI}: DmApis) => {
        const {hooks} = extensionAPI as HooksExtensionApi
        hooks.registerHookWithExtensionArgs(RENDER_HOOKS.AFTER_RENDER.id, () => updatedLayouts.clear())
    }

    return {
        name: 'remeasure',
        createExtensionAPI,
        createPostSetOperation,
        initialize
    }
}

export {createExtension}
