import type {CreateExtArgs, CreateExtensionArgument, DAL, Extension, ExtensionAPI} from '@wix/document-manager-core'
// eslint-disable-next-line no-duplicate-imports
import {DalValue, DmApis, pointerUtils, ValidateValue} from '@wix/document-manager-core'

import type {
    FontDisplayType,
    PartialTextThemeMap,
    Pointer,
    Pointers,
    SPXReferenceDefinition,
    StyleRef,
    TextTheme,
    TextThemeMap
} from '@wix/document-services-types'
import _ from 'lodash'
import {DATA_TYPES, MASTER_PAGE_ID, STYLES} from '../../constants/constants'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import type {DefaultDefinitionsAPI} from '../defaultDefinitions/defaultDefinitions'
import {getSkinDefinition, systemStyles} from './defaultValues'
import {deepClone} from '@wix/wix-immutable-proxy'
import Color from 'color'
// eslint-disable-next-line import/no-unresolved,wix-editor/no-internal-import
import {coreUtils} from '@wix/santa-ds-libs/basic'
import type {SchemaExtensionAPI} from '../schema/schema'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {RelationshipsAPI} from '../relationships'
import {getComponentType} from '../../utils/dalUtils'
import {ReportableError} from '@wix/document-manager-utils'
import {
    createEmptyStylableStylesheet,
    getComponentStylableName,
    shouldApplySkinTheme
} from '../style/stylable/stylableUtils'
// eslint-disable-next-line ,wix-editor/no-internal-import
import editorElementsThemePresets from '@wix/editor-elements-schemas/themePresets'
import {STYLABLE_SKIN_NAME} from '../../utils/stylableUtils'

export interface ThemeAPI extends ExtensionAPI {
    spx: {
        get(): SPXReferenceDefinition
        set(newSpx: SPXReferenceDefinition): void
    }
    fontDisplay: {
        get(): FontDisplayType
        set(newFontDisplay: FontDisplayType): void
    }
    onChange: {
        onThemeChangeAddListener(callback: (changedData: any) => void): void
        removeChangeThemeListener(listenerId: number): void
        onThemeChangeRunCallbacks(changedData: any): void
    }
    styles: {
        getStyle(styleId: string, pageId: string): any
        getThemeStyleIds(componentType: string): string[]
    }
    createComponentStyle(
        compType: string,
        skin: string,
        styleProperties: Record<string, any>,
        propertiesSource: Record<string, any>,
        propertiesOverride: Record<string, any>
    ): StyleRef
    ensureDefaultStyleItemExists(compType: string, styleId: string): Pointer
    cloneStyle(styleId: string): Pointer
    getColors(): Colors
    updateColors(colors: Colors): Colors
    getTextTheme(): TextThemeMap | {}
    updateTextTheme(textThemesMap: PartialTextThemeMap): void
    getStylableConfigs(value: DalValue): Record<string, boolean>
}

export interface ThemeExtAPI extends ExtensionAPI {
    theme: ThemeAPI
}
const TEXT_THEMES_MAP_KEY = 'font'
export const PROPERTY_TYPE = {
    TEXT_THEME: 'textTheme',
    COLOR: 'color',
    FONT: 'font'
}
export const enum StyleTypes {
    topLevelStyle = 'TopLevelStyle',
    componentStyle = 'ComponentStyle'
}

const getThemePointer = (pointers: any): Pointer => {
    return pointers.data.getThemeItem('THEME_DATA', MASTER_PAGE_ID)
}

const getCollectionItemPointer = (
    pointers: any,
    collectionType: string,
    index: string | number | undefined
): Pointer => {
    return pointerUtils.getInnerPointer(
        getThemePointer(pointers),
        !_.isNil(index) ? [collectionType, index.toString()] : collectionType
    )
}

const getCollectionPointer = (pointers: Pointers, collectionType: string, index?: number): Pointer => {
    return getCollectionItemPointer(pointers, collectionType, index)
}

function normalizeColorValue(colorValue: string) {
    if (colorValue) {
        const regexRes = /^(rgb|rgba)\(([0-9,\\.]*)\)$/.exec(colorValue)
        colorValue = regexRes?.at(2) ?? colorValue
    }
    return colorValue
}

function getPropIndex(name: string): number {
    const index = name.split('font_')[1] || name.split('color_')[1]
    return parseInt(index, 10)
}

function validateTypeAndKeys(dal: DAL, pointers: Pointers, valuesToMerge: Colors, type: string) {
    if (!PROPERTY_TYPE[type.toUpperCase()]) {
        throw new Error(`Type ${type} is not supported in this extension`)
    }

    if (typeof valuesToMerge !== 'object') {
        throw new Error(`Value "${valuesToMerge}" is not valid.Param should be an object`)
    }

    const allValues = dal.get(getCollectionPointer(pointers, type))

    _.forEach(valuesToMerge, function (val, key) {
        const index = getPropIndex(key)
        if (index === undefined || !(allValues && (allValues[index] || allValues[key]))) {
            throw new Error(`Invalid Key ${key}`)
        }
    })
}

function validateHexColor(hexColor: string): boolean {
    return !!hexColor && /^#(([0-9|a-f|A-F]){3}){1,2}$/.test(hexColor)
}

function rgbaColor(value: string): boolean {
    if (value === null) {
        return true
    }
    const split = value.split(',')

    if (split.length !== 3 && split.length !== 4) {
        return false
    }

    const alpha = parseFloat(split[3])
    const validRgb = _.every(split.slice(0, 3), number => parseInt(number, 10) >= 0 && parseInt(number, 10) <= 255)

    if (!validRgb) {
        return false
    }

    if (alpha) {
        return alpha >= 0 && alpha <= 1
    }

    return true
}

function isColorValid(colorToValidate: string | undefined): boolean {
    if (colorToValidate) {
        const isHexColor = validateHexColor(colorToValidate)
        const isRGBAColor = rgbaColor(colorToValidate)
        return isHexColor || isRGBAColor
    }
    return false
}

function validateColor(colors: Colors) {
    _.forEach(colors, function (val, key) {
        const normalizedColorVal = normalizeColorValue(val)
        if (!isColorValid(normalizedColorVal)) {
            throw new Error(`color value isn't valid ${val} .Please supply or hex/rgb string`)
        }
        colors[key] = normalizedColorVal
    })
}

interface Colors {
    [key: string]: string
}

const mergeOldAndNewColors = (colorsToModify: string[], colors: Colors) => {
    _.mapKeys(colors, (colorValue, colorName) => {
        colorsToModify[getPropIndex(colorName)] = colorValue
    })

    return colorsToModify
}

const setCollection = (pointers: any, dal: DAL, propertyType: string, colors: Colors): void => {
    const pointer = getCollectionPointer(pointers, propertyType)
    const oldColors = _.cloneDeep(dal.get(pointer))
    const newColors = mergeOldAndNewColors(oldColors, colors)
    dal.set(pointer, newColors)
}

const createExtension = ({experimentInstance}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({dal, extensionAPI, pointers}: CreateExtArgs): ThemeExtAPI => {
        const defaultDefinitions = () => extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
        const schemaAPI = () => (extensionAPI as SchemaExtensionAPI).schemaAPI
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const themeChangeListeners: {listenerId: number; callback: Function}[] = []
        const fontUtils = coreUtils.fontUtils.createFontUtils(
            experimentInstance.isOpen(coreUtils.fontUtils.FONT_EXPERIMENT_NAME)
        )

        function removeChangeThemeListener(listenerId: number) {
            if (_.isNil(listenerId)) {
                throw new Error(`missing argument - listenerId: ${listenerId}`)
            }
            const indexToRemove = _.findIndex(themeChangeListeners, {listenerId})
            if (indexToRemove === -1) {
                throw new Error(`Value "${listenerId}" is not valid.No listener with this id exist`)
            }
            themeChangeListeners.splice(indexToRemove, 1)
        }

        function onThemeChangeAddListener(callback: (changedData: any) => void) {
            if (typeof callback !== 'function') {
                throw new Error(`Value "${callback}" is not valid.Param should be function`)
            }
            const listenerId = themeChangeListeners.length
            themeChangeListeners.push({listenerId, callback})
            return listenerId
        }

        function onThemeChangeRunCallbacks(changedData: any) {
            _(themeChangeListeners).filter('callback').invokeMap('callback', changedData).value()
        }

        function verifySystemStyleHasDefaultSkin(styleId: string, componentType: string) {
            const componentDefaults = schemaAPI().getDefinition(componentType)
            if (!componentDefaults) {
                throw new ReportableError({
                    errorType: 'ComponentNotFoundInDefinitionsMap',
                    message: 'Component of this type is not listed in componentsDefinitionsMap',
                    extras: {componentType, styleId}
                })
            }

            const defaultSkin = componentDefaults.styles[styleId]
            if (!defaultSkin) {
                throw new ReportableError({
                    errorType: 'UnknownSystemStyleIdError',
                    message: 'Style id is not a known system style id',
                    extras: {styleId}
                })
            }
        }

        const getStyleDataByThemeType = (compType: string, styleId: string, definition: any, themeType: string) => {
            let skinName
            let props
            let propsSource
            if (themeType === 'stylable') {
                const {styles} = definition
                const compName = getComponentStylableName(compType)
                const stylablePresets = editorElementsThemePresets[compName!]
                const defaultPreset = createEmptyStylableStylesheet(compType)
                const preset = stylablePresets ? stylablePresets[styleId] || defaultPreset : defaultPreset
                if (!styles[styleId] || !preset) {
                    throw new ReportableError({
                        errorType: 'NoPresetForCompTypeAndStyleIdError',
                        message: 'there is no preset for this compType and styleId',
                        extras: {compType, styleId}
                    })
                }
                props = {'$st-css': preset}
                skinName = STYLABLE_SKIN_NAME
            } else {
                skinName = definition.styles[styleId]
                const skin = systemStyles[skinName]
                props = skin ? skin.properties : _.mapValues(getSkinDefinition(skinName), 'defaultValue')
                propsSource = skin ? skin.propertiesSource : {}
            }
            return {skinName, props, propsSource}
        }

        const createStyle = (compType: string, styleId: string, definition: any, themeType: string) => {
            const {skinName, props, propsSource} = getStyleDataByThemeType(compType, styleId, definition, themeType)
            return defaultDefinitions().createSystemStyle(styleId, compType, skinName, props, propsSource)
        }
        const addDefaultStyle = (compType: string, stylePointer: Pointer) => {
            const styleId = stylePointer.id
            verifySystemStyleHasDefaultSkin(styleId, compType)
            const definition = schemaAPI().getDefinition(compType)
            const themeType = shouldApplySkinTheme(compType, definition, experimentInstance) ? 'skin' : 'stylable'
            const styleData = createStyle(compType, styleId, definition, themeType)
            dataModel.addItem(styleData, DATA_TYPES.theme, MASTER_PAGE_ID, styleId)
        }

        const cloneStyle = (styleId: string): Pointer => {
            const clonedTheme = _.cloneDeep(dal.get(pointerUtils.getPointer(styleId, 'style')))
            const newPointer = pointerUtils.getPointer(generateItemIdWithPrefix('style'), 'style')
            dal.set(newPointer, {...clonedTheme, type: 'ComponentStyle', styleType: 'custom', id: newPointer.id})
            return newPointer
        }

        const createComponentStyle = (
            compType: string,
            skin: string,
            styleProperties: Record<string, any>,
            propertiesSource: Record<string, any>,
            propertiesOverride: Record<string, any>
        ): StyleRef => {
            return {
                compId: '',
                componentClassName: compType,
                pageId: '',
                styleType: 'custom',
                type: StyleTypes.componentStyle,
                skin,
                style: {
                    groups: {},
                    properties: styleProperties,
                    propertiesSource,
                    propertiesOverride
                }
            }
        }

        const ensureDefaultStyleItemExists = (compType: string, styleId: string): Pointer => {
            const stylePointer = {type: 'style', id: styleId}
            if (!dal.has(stylePointer)) {
                addDefaultStyle(compType, stylePointer)
            }
            return stylePointer
        }

        const convertToHex = (colors: Colors) => {
            const valueToHex = (colorString: string) => {
                let colorObj

                if (colorString.indexOf('#') !== 0) {
                    const rgbString = colorString.indexOf('r') === 0 ? colorString : `rgba(${colorString})`
                    colorObj = new Color(rgbString)
                } else {
                    colorObj = new Color(colorString)
                }

                // @ts-ignore
                return colorObj.hexString()
            }

            if (_.isArray(colors)) {
                return _.map(colors, valueToHex)
            } else if (_.isObject(colors)) {
                return _.mapValues(colors, valueToHex)
            }
            return valueToHex(colors)
        }

        const getColors = (): Colors => {
            const colors = getPropertiesAccordingToType(PROPERTY_TYPE.COLOR)
            return convertToHex(colors)
        }

        const getTextThemesMap = (textThemes: TextTheme) => {
            const textThemesMap: TextThemeMap | {} = {}

            _.forEach(textThemes, (textTheme, index) => {
                const key = `${TEXT_THEMES_MAP_KEY}_${index}`
                textThemesMap[key] = textTheme
            })

            return textThemesMap
        }

        const getTextTheme = (): TextThemeMap | {} => {
            const allTextTheme = dal.get(getCollectionPointer(pointers, PROPERTY_TYPE.TEXT_THEME))
            return getTextThemesMap(allTextTheme)
        }

        function getPropertiesAccordingToType(type: string): Record<string, any> {
            const result = {}
            const pointer = getCollectionPointer(pointers, type)
            const getter = deepClone(dal.get(pointer))
            const values = getter ? getter : []
            _.forEach(values, (value, index) => {
                result[`${type}_${index}`] = value
            })
            return result
        }

        const updateColors = (colors: Colors): Colors => {
            validateTypeAndKeys(dal, pointers, colors, PROPERTY_TYPE.COLOR)
            validateColor(colors)
            setCollection(pointers, dal, PROPERTY_TYPE.COLOR, colors)

            onThemeChangeRunCallbacks({
                type: PROPERTY_TYPE.COLOR,
                values: colors
            })
            return colors
        }

        const validateGetterAndKey = (valuesToMerge: PartialTextThemeMap) => {
            if (typeof valuesToMerge !== 'object') {
                throw new Error(`Value "${valuesToMerge}" is not valid.Param should be an object`)
            }
            const allValues = getTextTheme()
            _.forEach(valuesToMerge, function (val, key) {
                const index = getPropIndex(key)
                if (index === undefined || !(allValues[index] || allValues[key])) {
                    throw new Error(`Invalid Key ${key}`)
                }
            })
        }

        const setCollectionItem = (collectionType: string, index: number, value: any) => {
            const itemPointer = getCollectionItemPointer(pointers, collectionType, index)
            dal.set(itemPointer, value)
        }

        const setTextThemeToData = (valuesMap: PartialTextThemeMap) => {
            _.forEach(valuesMap, (value, name) => {
                const index = getPropIndex(name)
                const fontString = fontUtils.textThemeToFontString(value as TextTheme)
                setCollectionItem(PROPERTY_TYPE.TEXT_THEME, index, value)
                setCollectionItem(PROPERTY_TYPE.FONT, index, fontString)
            })
        }

        const updateTextTheme = (updates: PartialTextThemeMap) => {
            validateGetterAndKey(updates)
            _.forEach(updates, (textTheme: Partial<TextTheme> | undefined) => {
                dal.schema.validate('TextTheme', textTheme, 'style')
            })
            setTextThemeToData(updates)
            onThemeChangeRunCallbacks({
                type: PROPERTY_TYPE.TEXT_THEME,
                values: updates
            })
        }

        const spxPointer = () => pointerUtils.getInnerPointer(getThemePointer(pointers), ['theme', 'spx'])

        const getSpx = () => dal.get(spxPointer())

        const setSpx = (newSpx: SPXReferenceDefinition) => dal.set(spxPointer(), newSpx)

        const fontDisplayPointer = () => pointerUtils.getInnerPointer(getThemePointer(pointers), ['fontDisplay'])

        const getFontDisplay = () => dal.get(fontDisplayPointer())

        const setFontDisplay = (newFontDisplay: FontDisplayType) => dal.set(fontDisplayPointer(), newFontDisplay)

        const getStylableConfigs = (value: DalValue) => {
            const {componentClassName, skin} = value ?? {}
            const isStylableComp = schemaAPI().getDefinition(componentClassName)?.isStylableComp
            const isStylableSkin = isStylableComp && skin === 'wixui.skins.Skinless'
            const isMigratedStylableSkin = isStylableComp && skin !== 'wixui.skins.Skinless'
            return {isStylableComp, isStylableSkin, isMigratedStylableSkin}
        }

        const getStyle = (styleId: string, pageId: string) => {
            const stylePointer = pointers.getPointer(styleId, 'style', {pageId})
            return dal.get(stylePointer)
        }

        const getThemeStyleIds = (componentType: string) => {
            const themeStyleIds = _.keys(schemaAPI().getDefinition(componentType).styles).sort()
            return themeStyleIds || []
        }

        return {
            theme: {
                spx: {
                    get: getSpx,
                    set: setSpx
                },
                fontDisplay: {
                    get: getFontDisplay,
                    set: setFontDisplay
                },
                onChange: {
                    onThemeChangeAddListener,
                    removeChangeThemeListener,
                    onThemeChangeRunCallbacks
                },
                createComponentStyle,
                styles: {
                    getStyle,
                    getThemeStyleIds
                },
                ensureDefaultStyleItemExists,
                cloneStyle,
                getColors,
                getTextTheme,
                updateTextTheme,
                updateColors,
                getStylableConfigs
            }
        }
    }

    const createValidator = ({extensionAPI, coreConfig, dal}: DmApis): Record<string, ValidateValue> => {
        const {theme, relationships, schemaAPI} = extensionAPI as ThemeExtAPI & RelationshipsAPI & SchemaExtensionAPI

        return {
            missingSkin: (pointer: Pointer, value: DalValue) => {
                const isActualStyle = (v: DalValue) => [STYLES.COMPONENT_STYLE, STYLES.TOP_LEVEL_STYLE].includes(v.type)
                if (
                    value === undefined ||
                    pointer.type !== DATA_TYPES.theme ||
                    !isActualStyle(value) ||
                    !coreConfig.experimentInstance.isOpen('dm_missingSkinValidator')
                ) {
                    return
                }

                if (!value.skin) {
                    const extras: Record<string, any> = {
                        styleId: value.id
                    }

                    const isSystemStyle = (v: DalValue) =>
                        v.type === STYLES.TOP_LEVEL_STYLE && v.styleType === STYLES.TYPES.SYSTEM

                    if (!isSystemStyle(value)) {
                        const componentPointer = relationships.getOwningComponentToPointer(pointer)
                        if (componentPointer) {
                            extras.componentType = getComponentType(dal, componentPointer)
                        }
                    }

                    return [
                        {
                            shouldFail: false,
                            type: 'missingSkinInStyle',
                            message: `Attempt to set style with missing skin property`,
                            extras
                        }
                    ]
                }
            },
            invalidSystemStyle: (pointer: Pointer, value: DalValue) => {
                if (
                    value === undefined ||
                    pointer.type !== DATA_TYPES.theme ||
                    !coreConfig.experimentInstance.isOpen('dm_fixSystemStyleAsRefArray') ||
                    !schemaAPI.isSystemStyle(value.id)
                ) {
                    return
                }

                if (value.type !== 'TopLevelStyle' || value.styleType !== 'system') {
                    return [
                        {
                            shouldFail: true,
                            type: 'invalidSystemStyle',
                            message: `Attempt to set invalid system style`,
                            extras: {
                                styleId: value.id,
                                style: value
                            }
                        }
                    ]
                }
            },
            invalidStyleForStylableComponent: (pointer: Pointer, value: DalValue) => {
                if (pointer.type !== DATA_TYPES.theme) {
                    return
                }

                const {style} = value ?? {}
                const {isStylableSkin, isMigratedStylableSkin} = theme.getStylableConfigs(value)

                // Stylable skins should always contain $st-css property
                if (isStylableSkin && !style?.properties?.['$st-css']) {
                    return [
                        {
                            shouldFail: coreConfig.experimentInstance.isOpen('dm_validateStylableStyles'),
                            type: 'missingStylableProperties',
                            message: 'Stylable style must contain a $st-css property',
                            extras: {
                                style: value
                            }
                        }
                    ]
                    // Migrated stylable skins should never contain $st-css property
                } else if (isMigratedStylableSkin && style?.properties?.['$st-css']) {
                    return [
                        {
                            shouldFail: coreConfig.experimentInstance.isOpen('dm_validateMigratedStylableStyles'),
                            type: 'invalidStyleForMigratedStylableComp',
                            message: 'Migrated stylable style cannot contain a $st-css property',
                            extras: {
                                style: value
                            }
                        }
                    ]
                }
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {theme} = extensionAPI as ThemeExtAPI
        return {
            theme: {
                spx: {
                    get: theme.spx.get,
                    set: theme.spx.set
                },
                fontDisplay: {
                    get: theme.fontDisplay.get,
                    set: theme.fontDisplay.set
                }
            }
        }
    }

    return {
        name: 'theme',
        dependencies: new Set(['data', 'schema', 'dataModel', 'dataAccess']),
        createExtensionAPI,
        createPublicAPI,
        createValidator
    }
}

export {createExtension}
