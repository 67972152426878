module.exports = {
    name: 'meshEditorSafeguard',
    version: 0,
    experimentalVersions: [{version: 1, experiment: 'dm_meshEditorSafeguard'}],
    exec(pageJson, pageIdsArray, magicObject) {
        if (magicObject.isExperimentOpen('dm_meshEditorSafeguard') && magicObject.isViewerMode) {
            throw new Error("magicObject.isViewerMode should be 'false' in the editor")
        }
    }
}
