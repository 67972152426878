import type {DalValue} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'
import _ from 'lodash'

const EDITOR_STAGE_WIDTH = 980

const validateComponentLayoutLimit = (component: DalValue, {x, width}: {x: number; width: number}) => {
    if (x < 0 || x + width > EDITOR_STAGE_WIDTH) {
        throw new ReportableError({
            message: `component layout outside ${EDITOR_STAGE_WIDTH}px`,
            errorType: 'illegalComponentLayout',
            extras: {
                componentId: component.id,
                componentType: component.componentType,
                xValue: x
            }
        })
    }
}

const validateLayoutBoundaries = (component: DalValue, parentComponent: DalValue) => {
    const {x, y} = _.pick(component.layout, ['x', 'y'])
    const parentXLimit = parentComponent?.layout.x + parentComponent?.layout.width
    const parentYLimit = parentComponent?.layout.y + parentComponent?.layout.height

    if (x < 0 || parentXLimit < x) {
        throw new ReportableError({
            message: 'component overstepping its container boundaries',
            errorType: 'illegalComponentLayout',
            extras: {
                componentId: component.id,
                componentType: component.componentType,
                xValue: x,
                parentXLimit
            }
        })
    }
    if (y < 0 || parentYLimit < y) {
        throw new ReportableError({
            message: 'component overstepping its container boundaries',
            errorType: 'illegalComponentLayout',
            extras: {
                componentId: component.id,
                componentType: component.componentType,
                yValue: y,
                parentYLimit
            }
        })
    }
}

export const mobileConversionValidation = (component: DalValue, parentComponent: DalValue) => {
    validateComponentLayoutLimit(component, _.pick(component.layout, ['x', 'width']))
    validateLayoutBoundaries(component, parentComponent)
}
