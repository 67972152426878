import type {DalItem, DocumentManager} from '@wix/document-manager-core'
import * as constants from '../../../../constants/constants'
import type {PageAPI} from '../../../page'
import type {RelationshipsAPI} from '../../../relationships'
import type {VariantsExtensionAPI} from '../../../variants/variants'
import type {ResolvedReference} from '@wix/document-services-types'
import type {PageMigrator} from '../dataMigrationRunner'
import type {ExperimentalVersion} from '../../../dataFixerVersioning/dataFixerVersioning'

const {DATA_TYPES, TRIGGER_TYPES} = constants
const TRIGGERS_WITH_EFFECT_PARAM = new Set([TRIGGER_TYPES.ANIMATION_END, TRIGGER_TYPES.ANIMATION_START])

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {dal, extensionAPI, pointers} = documentManager
    const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
    const {relationships} = extensionAPI as RelationshipsAPI
    const {variants} = extensionAPI as VariantsExtensionAPI
    const dataItemsToCheck: Record<string, DalItem> = dal.query(DATA_TYPES.variants, pageCompFilter)

    for (const [dataItemId, dataItem] of Object.entries(dataItemsToCheck)) {
        if (TRIGGERS_WITH_EFFECT_PARAM.has(dataItem.trigger)) {
            const brokenEffectReferences = dal.schema
                .getReferences(DATA_TYPES.variants, dataItem)
                .filter(
                    ({id, referencedMap}: ResolvedReference) =>
                        referencedMap === DATA_TYPES.effects &&
                        (!dal.has(pointers.getPointer(id, referencedMap)) ||
                            !relationships.getOwningReferencesToPointer(pointers.getPointer(id, referencedMap)).length)
                )

            if (!brokenEffectReferences.length) {
                continue
            }

            const triggerVariantPointer = pointers.getPointer(dataItemId, DATA_TYPES.variants)
            const triggersListPointer = relationships.getOwningReferencesToPointer(
                triggerVariantPointer,
                DATA_TYPES.triggers
            )[0]
            const triggersList = dal.get(triggersListPointer)
            const newTriggersListValues = triggersList.values.filter(
                (triggerRef: string) => triggerRef !== `#${dataItemId}`
            )

            variants.removeScopedValuesByVariants([triggerVariantPointer])
            dal.set(triggersListPointer, {
                ...triggersList,
                values: newTriggersListValues
            })
            dal.remove(triggerVariantPointer)
        }
    }
}

const name = 'brokenEffectsReferenceFixer'
const version = 1
const experimentalVersions: ExperimentalVersion[] = []

export const brokenEffectsReferenceFixer: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions
}
