import type {ComponentStyle, CompRef} from '@wix/document-services-types'
import type {ConversionStyleDataTransformer, DeepPartial} from './types'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {VariantsExtensionAPI} from '../../variants/variants'
import {DATA_TYPES} from '../../../constants/constants'
import {StyleTypes, type ThemeExtAPI} from '../../theme/theme'
import _ from 'lodash'
import type {StructureEntryItem, StyleEntryItem} from '../conversionDal/types'

const styleDataTransformersByCompType: Record<string, ConversionStyleDataTransformer> = {
    'wysiwyg.viewer.components.WRichText': (style: StyleEntryItem): DeepPartial<ComponentStyle> => ({
        style: {
            properties: {
                fontSize: `${style.fontSize}px`
            }
        }
    }),
    'wysiwyg.viewer.components.SiteButton': (style: StyleEntryItem): DeepPartial<ComponentStyle> => ({
        style: {
            propertiesOverride: {
                fnt: {
                    fontSize: `${style.fontSize}px`
                }
            }
        }
    }),
    'wixui.CollapsibleText': (style: StyleEntryItem): DeepPartial<ComponentStyle> => ({
        style: {
            propertiesOverride: {
                'text-font': {
                    fontSize: `${style.fontSize}px`
                },
                'button-font': {
                    fontSize: `${style.collapsibleTextButtonFontSize}px`
                }
            }
        }
    })
}

export const getStyleTransformer = (
    extensionAPI: ExtensionAPI,
    compPointer: CompRef,
    component: StructureEntryItem,
    styleItem: StyleEntryItem
): CallableFunction | undefined => {
    if (!styleDataTransformersByCompType[component.componentType]) {
        return
    }

    const {variants} = extensionAPI as VariantsExtensionAPI
    const {theme} = extensionAPI as ThemeExtAPI
    let baseStyleItem = variants.getComponentItemConsideringVariants(compPointer, DATA_TYPES.theme)

    if (!baseStyleItem || baseStyleItem.type === StyleTypes.topLevelStyle) {
        baseStyleItem = theme.createComponentStyle(component.componentType, component.skin, {}, {}, {})
    }

    return () => _.merge(baseStyleItem, styleDataTransformersByCompType[component.componentType](styleItem))
}
