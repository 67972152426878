import type {ExternalsMap, ExternalsBuilderMap, DalValue} from '@wix/document-services-types'
import type {ExternalsHandler} from '../../constants'
import type {DetailedValue, ExtendedRefInfo} from '@wix/import-export-utils'
import {getNewItemRef} from '../../utils'
import {stripHashIfExists} from '../../../../../utils/refArrayUtils'
import _ from 'lodash'

const DATAQUERY_REGEX = /dataquery="(.*?)"/g

export const richTextRefsHandler: ExternalsHandler<string> = {
    pack: (ref: DetailedValue<string>, externals: ExternalsBuilderMap) => {
        const refs = Array.from(ref.value.matchAll(DATAQUERY_REGEX)).map(([, id]) => stripHashIfExists(id))

        for (const refId of refs) {
            externals.nonOwnedRefs.add(refId)
        }
    },
    unpack: (ref: DetailedValue<string>, externals: ExternalsMap, refFieldInfo: ExtendedRefInfo, item: DalValue) => {
        const newText: string = ref.value.replace(DATAQUERY_REGEX, (match, oldId) => {
            const newId: string = getNewItemRef(oldId, refFieldInfo, externals.externalRefs)
            return match.replace(oldId, newId)
        })

        _.set(item, ref.path, newText)
    }
}
