import type {CompRef, PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import responsiveLayout from '../../responsiveLayout/responsiveLayout'

export const convertToMeshAfterAddHook = (ps: PS, compToAddPointer: CompRef, containerRef: CompRef) => {
    if (!experiment.isOpen('dm_meshLayout')) {
        return
    }

    const alreadyMigrated = !!ps.dal.get(ps.pointers.getInnerPointer(compToAddPointer, ['layoutQuery']))

    if (alreadyMigrated) {
        return
    }

    const isPage = ps.pointers.structure.isPage(compToAddPointer)

    if (isPage) {
        return ps.extensionAPI.meshLayout.convertToMeshLayout(compToAddPointer.id)
    }

    ps.extensionAPI.meshLayout.convertToMeshLayout(containerRef.id, {ignoreRoot: true})
}

export const remeasureContainerHook = (ps: PS, compToAddPointer: CompRef, containerRef: CompRef) => {
    if (!experiment.isOpen('dm_meshLayout')) return

    responsiveLayout.remeasureContainerIfNeeded(ps, containerRef)
}

export const remeasureOldContainerHook = (
    ps: PS,
    compToAddPointer: CompRef,
    containerRef: CompRef,
    oldContainerPointer: CompRef
) => {
    if (!experiment.isOpen('dm_meshLayout')) return

    responsiveLayout.remeasureContainerIfNeeded(ps, oldContainerPointer)
}
