import type {DAL} from '@wix/document-manager-core'
import type {CompRef, Pointer, Pointers} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {DATA_TYPES} from '../constants/constants'
import _ from 'lodash'

const getReferringSlots = (dal: DAL, refCompChildId: string): Pointer[] => {
    return dal.getIndexPointers(dal.queryFilterGetters.getSlotsFilter(refCompChildId), DATA_TYPES.slots)
}

const belongsToOverride = (dal: DAL, refCompChildId: string, refHostId: string): boolean => {
    const slotsOverrides: Pointer[] = getReferringSlots(dal, refCompChildId)

    if (slotsOverrides.length !== 1) {
        return false
    }

    return displayedOnlyStructureUtil.getRootRefHostCompId(slotsOverrides[0].id) === refHostId
}

const getRefComponentChildrenWithoutCorrespondingSlots = (
    dal: DAL,
    pointers: Pointers,
    refComponent: CompRef
): CompRef[] => {
    const refComponentChildren = pointers.structure
        .getChildren(refComponent)
        .filter((compRef: Pointer) => !compRef.id.startsWith('dead-')) as CompRef[]

    return _.reject(refComponentChildren, child => belongsToOverride(dal, child.id, refComponent.id))
}

export {getRefComponentChildrenWithoutCorrespondingSlots}
