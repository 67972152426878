import {
    CreateExtArgs,
    Extension,
    ExtensionAPI,
    DmApis,
    CreateExtensionArgument,
    pointerUtils
} from '@wix/document-manager-core'
import type {StructureExtensionAPI} from '../structure'
import {VIEW_MODES} from '../../constants/constants'
import type {ComponentDefinitionExtensionAPI} from '../componentDefinition'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {SerializedCompStructure, Pointer} from '@wix/document-services-types'
import {getUniqueDisplayedId} from '../../utils/repeaterUtils'
import {
    aiContentBadJsonErrorMessage,
    aiContentBadJsonErrorType,
    aiContentMainPageFailedErrorMessage,
    aiContentMainPageFailedErrorType,
    aiContentOutlineResultConsecutiveErrorMessage,
    aiContentOutlineResultConsecutiveErrorType,
    aiContentOutlineResultErrorMessage,
    aiContentOutlineResultErrorType,
    aiContentTooLongErrorMessage,
    aiContentTooLongErrorType,
    aiContentUnexpectedError,
    aiContentUnexpectedErrorMessage,
    blacklistedPageTitleKeywords,
    aiContentAllPagesFailedErrorType,
    aiContentAllPagesFailedErrorMessage,
    aiPageSuggestionInteraction,
    nonApplicableParamsVersion
} from './constants'
import {
    parsePaddedJSON,
    getNewOutlineFromJSON,
    shortenBusinessNameFieldInCompletionIfNecessary,
    getPromptHubPromptIdForSiteInjection,
    fetchOutlineWithPromptParamsFromPromptHub,
    getPromptHubInjectionParams
} from './aiExtensionUtils'
import {ReportableError, getReportableFromError} from '@wix/document-manager-utils'
import type {PageExtensionAPI} from '../..'
import * as constants from '../../constants/constants'
import {
    applyOutlineToStructure,
    getSuggestedOutlineByStructure,
    VersionOverrides,
    getSuggestedPageOutlineByStructure,
    applyOutlineToPageStructure
} from './aiExtensionStructure'
import {
    getNextIdForPrefix,
    ignoredContainerTypes,
    unsectionedOutlineSectionName,
    outlineCompIdSectionNameOverrides,
    getContentForComponent,
    OutlineValidationCounters,
    invalidOutlineRetryAmount,
    CompletionCallMetadata,
    reportOutlineBIError,
    validateOutlineSuggestion,
    getSectionName,
    updateDataItemValue,
    validateContentLengthInternal
} from './aiExtensionContent'
import {getIdFromRef} from '../../utils/dataUtils'

export interface ContentExtractionOptions {
    excludeMasterPage?: boolean
}

export interface ContentSuggestionOptions extends ContentExtractionOptions {}

export interface AiApi {
    content: {
        getPageOutline(pageId: string, options?: ContentExtractionOptions): OutlineWithIdMap
        getSuggestedOutline(
            pageId: string,
            businessType: string,
            businessName: string,
            additionalInformation: string,
            options?: ContentSuggestionOptions,
            toneOfVoice?: string
        ): Promise<OutlineSuggestionResult>
        applyOutline(outlineWithIds: OutlineWithIdMap, pageId: string): void
        getSuggestedSiteOutlines(
            businessType: string,
            businessName: string,
            additionalInformation: string,
            options?: ContentSuggestionOptions,
            toneOfVoice?: string
        ): Promise<PageSuggestionResults[]>
        getSuggestedOutlineByStructure(
            businessType: string,
            businessName: string,
            additionalInformation: string,
            componentStructure: SerializedCompStructure,
            sectionCategory?: string,
            versionOverrides?: VersionOverrides,
            language?: string
        ): Promise<EntitySuggestionResult>
        applyOutlineToStructure(
            outlineWithIds: OutlineWithIdMapForStructure,
            compStructure: SerializedCompStructure
        ): SerializedCompStructure
        getSuggestedOutlineByPageStructure(
            businessType: string,
            businessName: string,
            additionalInformation: string,
            componentStructure: SerializedCompStructure,
            versionOverrides?: VersionOverrides,
            language?: string
        ): Promise<EntitySuggestionResult>
        applyOutlineToPageStructure(
            outlineWithIds: OutlineWithIdMapForStructure,
            compStructure: SerializedCompStructure
        ): SerializedCompStructure
    }
}

export type AiExtApi = ExtensionAPI & {ai: AiApi}

export interface Outline {
    [key: string]: {[key: string]: string}
}
export interface FlatOutline {
    [key: string]: string
}

export interface OutlineFieldMetadata {
    originalCharacterCount?: number
    isMultiChoice?: boolean
}

export interface OutlineWithIdMap {
    outline: Outline
    idMap: Record<string, string>
    metadataMap?: Record<string, OutlineFieldMetadata>
}

export interface OutlineWithIdMapForStructure {
    outline: Outline | FlatOutline
    idMap: Record<string, string>
    idsToKeep?: Record<string, string>
    metadataMap?: Record<string, OutlineFieldMetadata>
}

export interface OutlineValidationResults {
    isValid: boolean
    outlineFieldsMismatch: boolean
    blackListThresholdExceeded: boolean
    counters: OutlineValidationCounters
}

export interface OutlineSuggestionResult extends OutlineWithIdMap {
    validationResults: OutlineValidationResults
    originalOutline: Outline
    retries: number
    completionMetadata: {
        promptsVersion: string
        gptParamsVersion: string
        tokenUsage: any[]
    }
}

export interface OutlineSuggestionResultForStructure extends OutlineWithIdMapForStructure {
    validationResults: OutlineValidationResults
    originalOutline: Outline | FlatOutline
    retries: number
    completionMetadata: {
        promptsVersion: string
        gptParamsVersion: string
        tokenUsage: any[]
    }
}

export interface PageSuggestionResults {
    pageId: string
    pageSuggestion?: OutlineSuggestionResult
    error?: Error
}

export interface EntitySuggestionResult {
    entityId: string
    contentSuggestion?: OutlineSuggestionResultForStructure
    error?: Error
    compStructure: SerializedCompStructure
}
const createExtension = ({environmentContext, experimentInstance}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({extensionAPI, pointers, coreConfig, dal}: CreateExtArgs): AiExtApi => {
        const {components} = extensionAPI as StructureExtensionAPI
        const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const {page: pageExt} = extensionAPI as PageExtensionAPI

        const {logger} = coreConfig
        const {serverFacade} = environmentContext

        const updateContentForComponent = (compPointer: Pointer, content: string) => {
            const dataItem = dataModel.components.getItem(compPointer, 'data')
            const type = components.getComponentType(compPointer)
            updateDataItemValue(dataItem, type, content)
            dataModel.components.addItem(compPointer, 'data', dataItem)
        }

        const getSectionNameByPointer = (sectionPointer: Pointer): string => {
            const anchorItem = dataModel.components.getItem(sectionPointer, constants.DATA_TYPES.anchors)
            return getSectionName(anchorItem)
        }

        const getOutineContainerName = (containerPointer: Pointer, containerType: string): string | undefined => {
            if (componentDefinition.isSection(containerType)) {
                return getSectionNameByPointer(containerPointer)
            }
            return outlineCompIdSectionNameOverrides[containerPointer.id]
        }

        const getContentRecursively = (
            compPointer: Pointer,
            outline: Outline,
            typeCounter: Record<string, number>,
            idMap: Record<string, string>,
            unsectionedSectionName: string = unsectionedOutlineSectionName,
            outlineSectionName?: string | undefined
        ): void => {
            const {id} = compPointer
            const type = components.getComponentType(compPointer)

            if (ignoredContainerTypes.has(type)) {
                return
            }

            if (componentDefinition.isContainer(type)) {
                const containerName = getOutineContainerName(compPointer, type)
                const containerOutlineSectionName = containerName
                    ? getNextIdForPrefix(containerName, typeCounter)
                    : outlineSectionName

                if (containerOutlineSectionName) {
                    idMap[containerOutlineSectionName] = id
                }

                const children = pointers.structure.getChildren(compPointer)
                for (const childPointer of children) {
                    getContentRecursively(
                        childPointer,
                        outline,
                        typeCounter,
                        idMap,
                        unsectionedSectionName,
                        containerOutlineSectionName
                    )
                }
            }

            const dataItem = dataModel.components.getItem(compPointer, 'data')
            const content = getContentForComponent(type, dataItem)
            if (content) {
                const mappedId = getNextIdForPrefix(content.type, typeCounter)
                idMap[mappedId] = id

                const outlineSectionNameOrDefault = outlineSectionName ?? unsectionedSectionName
                outline[outlineSectionNameOrDefault] ??= {}
                outline[outlineSectionNameOrDefault][mappedId] = content.value
            }
        }

        const getPageOutline = (pageId: string, options?: ContentExtractionOptions): OutlineWithIdMap => {
            const pagePointer = pointers.structure.getComponentById(pageId, VIEW_MODES.DESKTOP)

            const outline: Outline = {}
            const typeCounter: Record<string, number> = {}
            const idMap: Record<string, string> = {}

            getContentRecursively(pagePointer, outline, typeCounter, idMap)

            if (pageExt.getMainPageId() === pageId && !options?.excludeMasterPage) {
                getContentRecursively(
                    pointerUtils.getPagePointer(constants.MASTER_PAGE_ID, constants.VIEW_MODES.DESKTOP),
                    outline,
                    typeCounter,
                    idMap,
                    'header'
                )
            }

            return {outline, idMap}
        }

        const validateContentLength = (compPointer: Pointer, newContent: string): boolean => {
            const dataItem = dataModel.components.getItem(compPointer, 'data')
            const componentType = components.getComponentType(compPointer)
            const currentContent = getContentForComponent(componentType, dataItem)?.value
            const res = validateContentLengthInternal(currentContent, newContent)
            if (!res) {
                logger.captureError(
                    new ReportableError({
                        errorType: aiContentTooLongErrorType,
                        message: aiContentTooLongErrorMessage,
                        extras: {
                            compPointer: JSON.stringify(compPointer),
                            currentContent,
                            newContent
                        }
                    })
                )
            }
            return res
        }

        const updateRepeatedDataItem = (compId: string, pageId: string, content: string) => {
            const splitCompId = compId.split('_')
            const compTemplateId = splitCompId[0]
            const itemId = splitCompId[1]
            const compPointer = pointers.structure.getComponentById(compTemplateId, VIEW_MODES.DESKTOP)
            const comp = dal.get(compPointer)
            if (comp) {
                const dataQuery = getIdFromRef(comp.dataQuery)
                const dataId = getUniqueDisplayedId(dataQuery, itemId)
                const dataItem = dataModel.getItem(dataId, 'data', pageId)
                if (dataItem) {
                    updateDataItemValue(dataItem, components.getComponentType(compPointer), content)
                    dataModel.addItem(dataItem, 'data', pageId, dataItem.id)
                }
            }
        }

        const applyContentUpdate = (result: Outline, idMap: Record<string, string>, pageId: string): void => {
            for (const sectionName of Object.keys(result)) {
                const section = result[sectionName]
                for (const fieldName of Object.keys(section)) {
                    const content = section[fieldName]
                    const compId = idMap[fieldName]

                    // the ai might suggest fields that were not originally present in the request and therefore don't have a corresponding component
                    if (!compId) {
                        continue
                    }
                    const compPointer = pointers.structure.getComponentById(compId, VIEW_MODES.DESKTOP)
                    if (compPointer && dal.get(compPointer)) {
                        if (validateContentLength(compPointer, content)) {
                            updateContentForComponent(compPointer, content)
                        }
                    } else if (experimentInstance.isOpen('dm_updateRepeaterByPageStructure') && compId.includes('_')) {
                        updateRepeatedDataItem(compId, pageId, content)
                    }
                }
            }
        }

        const getSuggestedOutline = async (
            pageId: string,
            businessType: string,
            businessName: string,
            additionalInformation: string,
            options?: ContentSuggestionOptions,
            toneOfVoice?: string,
            pageOutlineOverride?: OutlineWithIdMap
        ): Promise<OutlineSuggestionResult> => {
            const content = pageOutlineOverride ?? getPageOutline(pageId, options)
            const {outline, idMap} = content
            let resultOutline: Outline | null = null

            let apiCallCount = 0
            let validationResults: OutlineValidationResults
            let lastError: Error | null = null
            const tokenUsage: any[] = []
            const callsMetadata: CompletionCallMetadata[] = []

            const promptId = getPromptHubPromptIdForSiteInjection(experimentInstance, toneOfVoice)

            try {
                while (apiCallCount <= invalidOutlineRetryAmount) {
                    const completionResponse = await fetchOutlineWithPromptParamsFromPromptHub(
                        serverFacade,
                        pageId,
                        businessType,
                        businessName,
                        additionalInformation,
                        outline,
                        promptId,
                        getPromptHubInjectionParams(
                            businessName,
                            businessType,
                            additionalInformation,
                            outline,
                            toneOfVoice
                        ),
                        ['templateInjection']
                    )

                    tokenUsage.push(completionResponse.response.openAiChatCompletionResponse.usage)
                    const completionText = completionResponse.response.generatedTexts[0]
                    apiCallCount += 1

                    let parsedJSON
                    try {
                        parsedJSON = parsePaddedJSON(completionText!)
                        const parsedOutline = getNewOutlineFromJSON(parsedJSON)
                        resultOutline = parsedOutline
                    } catch (e) {
                        callsMetadata.push({
                            isBadJson: true
                        })
                        lastError = new ReportableError({
                            errorType: aiContentBadJsonErrorType,
                            message: aiContentBadJsonErrorMessage,
                            extras: {
                                completionText,
                                pageId,
                                businessType,
                                businessName,
                                additionalInformation
                            }
                        })
                        continue
                    }
                    if (pageExt.getMainPageId() === pageId && !options?.excludeMasterPage) {
                        resultOutline = await shortenBusinessNameFieldInCompletionIfNecessary(
                            serverFacade,
                            logger,
                            experimentInstance,
                            parsedJSON,
                            resultOutline,
                            outline
                        )
                    }
                    validationResults = validateOutlineSuggestion(resultOutline!, outline)
                    callsMetadata.push({
                        isBadJson: false,
                        textCounters: validationResults.counters
                    })

                    if (validationResults.isValid) {
                        break
                    } else if (apiCallCount > 1) {
                        reportOutlineBIError(
                            logger,
                            aiContentOutlineResultConsecutiveErrorType,
                            aiContentOutlineResultConsecutiveErrorMessage,
                            resultOutline!,
                            pageId,
                            businessType,
                            businessName,
                            additionalInformation,
                            validationResults
                        )
                    } else {
                        reportOutlineBIError(
                            logger,
                            aiContentOutlineResultErrorType,
                            aiContentOutlineResultErrorMessage,
                            resultOutline!,
                            pageId,
                            businessType,
                            businessName,
                            additionalInformation,
                            validationResults
                        )
                    }
                }

                if (!resultOutline) {
                    throw (
                        lastError ??
                        new ReportableError({
                            errorType: aiContentUnexpectedError,
                            message: aiContentUnexpectedErrorMessage
                        })
                    )
                }

                return {
                    outline: resultOutline!,
                    idMap,
                    validationResults: validationResults!,
                    originalOutline: outline,
                    retries: apiCallCount - 1,
                    completionMetadata: {
                        gptParamsVersion: nonApplicableParamsVersion,
                        promptsVersion: promptId,
                        tokenUsage
                    }
                }
            } catch (e: any) {
                // ensure that tokenUsage is always in the error
                const reportedError = getReportableFromError(e, {
                    errorType: e.errorType ?? aiContentUnexpectedError,
                    tags: {
                        aiContentInjection: true
                    },
                    extras: {tokenUsage}
                })
                logger.captureError(reportedError)

                throw reportedError
            } finally {
                logger.interactionEnded(aiPageSuggestionInteraction, {
                    extras: {
                        suggestionPurpose: 'pageInjection',
                        pageId,
                        outline,
                        tokenUsage,
                        resultOutline,
                        completionCalls: callsMetadata
                    }
                })
            }
        }

        const applyOutline = (outlineWithIds: OutlineWithIdMap, pageId: string): void => {
            applyContentUpdate(outlineWithIds.outline, outlineWithIds.idMap, pageId)
        }

        const shouldPageBeIgnored = (pageId: string): boolean => {
            const pagePointer = pointerUtils.getPointer(pageId, constants.VIEW_MODES.DESKTOP)
            const pageDataItem = dataModel.components.getItem(pagePointer, constants.DATA_TYPES.data)

            const lowerCaseTitle = pageDataItem.title.toLowerCase()
            for (const blacklistedPageTitleKeyword of blacklistedPageTitleKeywords) {
                if (lowerCaseTitle.includes(blacklistedPageTitleKeyword)) {
                    return true
                }
            }

            if (pageExt.isPlatformPage(pageId)) {
                return true
            }

            return false
        }
        const getSuggestedSiteOutlines = async (
            businessType: string,
            businessName: string,
            additionalInformation: string,
            options?: ContentSuggestionOptions,
            toneOfVoice?: string
        ): Promise<PageSuggestionResults[]> => {
            const pageIds = pageExt.getAllPagesIds(false)
            const pageOutlinesWithContent: {pageId: string; outline: OutlineWithIdMap}[] = []
            for (const pageId of pageIds) {
                const content = getPageOutline(pageId, options)
                if (Object.keys(content.outline).length > 0 && !shouldPageBeIgnored(pageId)) {
                    pageOutlinesWithContent.push({pageId, outline: content})
                }
            }

            const safeGetPageSuggestion = async (pageId: string, outline: OutlineWithIdMap): Promise<any> => {
                try {
                    const pageSuggestion = await getSuggestedOutline(
                        pageId,
                        businessType,
                        businessName,
                        additionalInformation,
                        options,
                        toneOfVoice,
                        outline
                    )
                    return {pageId, pageSuggestion}
                } catch (e) {
                    return {pageId, error: e}
                }
            }

            const pageSuggestions = await Promise.all(
                pageOutlinesWithContent.map(({pageId, outline}) => safeGetPageSuggestion(pageId, outline))
            )

            const mainPageId = pageExt.getMainPageId()
            const mainPageResult = pageSuggestions.find(({pageId}) => pageId === mainPageId)
            if (mainPageResult?.error) {
                throw new ReportableError({
                    errorType: aiContentMainPageFailedErrorType,
                    message: aiContentMainPageFailedErrorMessage,
                    extras: {
                        pageSuggestionResults: pageSuggestions
                    }
                })
            }
            if (pageSuggestions.every(({error}) => !!error)) {
                throw new ReportableError({
                    errorType: aiContentAllPagesFailedErrorType,
                    message: aiContentAllPagesFailedErrorMessage,
                    extras: {
                        pageSuggestionResults: pageSuggestions
                    }
                })
            }

            return pageSuggestions
        }

        return {
            ai: {
                content: {
                    getPageOutline,
                    getSuggestedOutline,
                    applyOutline,
                    getSuggestedSiteOutlines,
                    getSuggestedOutlineByStructure: (
                        businessType: string,
                        businessName: string,
                        additionalInformation: string,
                        compStructure: SerializedCompStructure,
                        sectionCategory?: string,
                        versionOverrides?: VersionOverrides,
                        language?: string
                    ) => {
                        return getSuggestedOutlineByStructure(
                            {extensionAPI, pointers, environmentContext, coreConfig},
                            businessType,
                            businessName,
                            additionalInformation,
                            compStructure,
                            sectionCategory,
                            versionOverrides,
                            language
                        )
                    },
                    applyOutlineToStructure: (
                        outlineWithIds: OutlineWithIdMapForStructure,
                        compStructure: SerializedCompStructure
                    ) => {
                        return applyOutlineToStructure(
                            {extensionAPI, pointers, environmentContext, coreConfig},
                            outlineWithIds,
                            compStructure
                        )
                    },
                    getSuggestedOutlineByPageStructure: (
                        businessType: string,
                        businessName: string,
                        additionalInformation: string,
                        compStructure: SerializedCompStructure,
                        versionOverrides?: VersionOverrides,
                        language = 'English'
                    ) => {
                        return getSuggestedPageOutlineByStructure(
                            {extensionAPI, pointers, environmentContext, coreConfig},
                            businessType,
                            businessName,
                            additionalInformation,
                            compStructure,
                            versionOverrides,
                            language
                        )
                    },
                    applyOutlineToPageStructure: (
                        outlineWithIds: OutlineWithIdMapForStructure,
                        compStructure: SerializedCompStructure
                    ) => {
                        return applyOutlineToPageStructure(
                            {extensionAPI, pointers, environmentContext, coreConfig},
                            outlineWithIds,
                            compStructure
                        )
                    }
                }
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {ai} = extensionAPI as AiExtApi
        return {
            ai: {
                content: {
                    getPageOutline: ai.content.getPageOutline,
                    applyOutline: ai.content.applyOutline,
                    getSuggestedOutline: ai.content.getSuggestedOutline,
                    getSuggestedSiteOutlines: ai.content.getSuggestedSiteOutlines
                }
            }
        }
    }

    return {
        name: 'aiContent',
        dependencies: new Set(),
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
