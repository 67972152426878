import type {
    ContentSuggestionOptions,
    EntitySuggestionResult,
    OutlineSuggestionResult,
    OutlineSuggestionResultForStructure,
    PageSuggestionResults,
    AiToolRequest
} from '@wix/document-manager-extensions'
import {getReportableFromError} from '@wix/document-manager-utils'
import type {Callback1, Callback2, PS, SerializedCompStructure} from '@wix/document-services-types'
import {initAITools} from '../aiTools/aiTools'

interface OutlineSuggestionOnSuccessParams {
    suggestionResults: OutlineSuggestionResult
}

interface OutlineSuggestionOnErrorParams {
    contentServerError?: string | object
    originalError: Error
    tokenUsage: any[]
}
interface SectionSuggestionOnErrorParams {
    originalError: Error
}

interface EntireSiteSuggestionOnErrorParams {
    originalError: Error
    pageSuggestions: PageSuggestionResults[]
}

async function getSuggestedOutline(
    ps: PS,
    pageId: string,
    category: string,
    businessName: string,
    description: string,
    onSuccess?: Callback1<OutlineSuggestionOnSuccessParams>,
    onError?: Callback1<OutlineSuggestionOnErrorParams>,
    options?: ContentSuggestionOptions,
    toneOfVoice?: string
) {
    try {
        const suggestion = await ps.extensionAPI.ai.content.getSuggestedOutline(
            pageId,
            category,
            businessName,
            description,
            options,
            toneOfVoice
        )
        ps.setOperationsQueue.asyncPreDataManipulationComplete(suggestion)
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'AIContentGetSuggestedOutlineError'
        })
        ps.setOperationsQueue.asyncPreDataManipulationComplete(null, newError as Error)

        ps.extensionAPI.logger.captureError(newError)

        onError?.({
            contentServerError: e?.extras?.responseBody,
            originalError: e,
            tokenUsage: e?.extras?.tokenUsage
        })
    }
}

function applyOutline(
    ps: PS,
    suggestionResults: OutlineSuggestionResult,
    pageId: string,
    category: string,
    businessName: string,
    description: string,
    onSuccess?: Callback1<OutlineSuggestionOnSuccessParams>,
    onError?: Callback1<OutlineSuggestionOnErrorParams>
) {
    try {
        ps.extensionAPI.ai.content.applyOutline(suggestionResults, pageId)
        onSuccess?.({
            suggestionResults
        })
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'AIContentApplyOutlineError'
        })
        onError?.({
            originalError: e,
            tokenUsage: suggestionResults?.completionMetadata?.tokenUsage
        })
        ps.extensionAPI.logger.captureError(newError)
    }
}

async function getSuggestedSiteOutlines(
    ps: PS,
    category: string,
    businessName: string,
    description: string,
    onSuccess?: Callback1<PageSuggestionResults[]>,
    onError?: Callback1<EntireSiteSuggestionOnErrorParams>,
    options?: ContentSuggestionOptions,
    toneOfVoice?: string
) {
    try {
        const suggestions = await ps.extensionAPI.ai.content.getSuggestedSiteOutlines(
            category,
            businessName,
            description,
            options,
            toneOfVoice
        )
        ps.setOperationsQueue.asyncPreDataManipulationComplete(suggestions)
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'AIContentgetSuggestedSiteOutlinesError'
        })
        ps.setOperationsQueue.asyncPreDataManipulationComplete(null, newError as Error)

        onError?.({
            originalError: e,
            pageSuggestions: e?.extras?.pageSuggestionResults
        })
        ps.extensionAPI.logger.captureError(newError)
    }
}

async function getSuggestedOutlineByStructure(
    ps: PS,
    category: string,
    businessName: string,
    description: string,
    compStructure: SerializedCompStructure,
    sectionCategory?: string,
    onSuccess?: Callback2<SerializedCompStructure, OutlineSuggestionResultForStructure>,
    onError?: Callback1<SectionSuggestionOnErrorParams>,
    language?: string //should be string of the language for example English
) {
    try {
        const sectionPromptVersionOverride = ps.siteAPI.getQueryParam('sectionPromptVersionOverride')
        const sectionParamsVersionOverride = ps.siteAPI.getQueryParam('sectionParamsVersionOverride')
        const suggestionResults = await ps.extensionAPI.ai.content.getSuggestedOutlineByStructure(
            category,
            businessName,
            description,
            compStructure,
            sectionCategory,
            {promptVersionOverride: sectionPromptVersionOverride, paramsVersionOverride: sectionParamsVersionOverride},
            language
        )

        ps.extensionAPI.ai.content.applyOutlineToStructure(
            suggestionResults.contentSuggestion,
            suggestionResults.compStructure
        )
        onSuccess?.(suggestionResults.compStructure, suggestionResults.contentSuggestion)
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'getSuggestedOutlinesByStructure'
        })

        onError?.({
            originalError: e
        })
        ps.extensionAPI.logger.captureError(newError)
    }
}

async function getSuggestedOutlineByPageStructure(
    ps: PS,
    category: string,
    businessName: string,
    description: string,
    compStructure: SerializedCompStructure,
    onSuccess?: Callback1<EntitySuggestionResult>,
    onError?: Callback1<SectionSuggestionOnErrorParams>,
    language?: string //should be string of the language for example English
) {
    try {
        const pagePromptVersionOverride = ps.siteAPI.getQueryParam('pagePromptVersionOverride')
        const pageParamsVersionOverride = ps.siteAPI.getQueryParam('pageParamsVersionOverride')
        const suggestionResults = await ps.extensionAPI.ai.content.getSuggestedOutlineByPageStructure(
            category,
            businessName,
            description,
            compStructure,
            {promptVersionOverride: pagePromptVersionOverride, paramsVersionOverride: pageParamsVersionOverride},
            language
        )
        ps.setOperationsQueue.asyncPreDataManipulationComplete({suggestionResults, compStructure})
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'getSuggestedOutlinesByStructure'
        })
        ps.setOperationsQueue.asyncPreDataManipulationComplete(null, newError as Error)

        onError?.({
            originalError: e
        })
        ps.extensionAPI.logger.captureError(newError)
    }
}

async function applyOutlines(
    ps: PS,
    suggestionResults: PageSuggestionResults[],
    category: string,
    businessName: string,
    description: string,
    onSuccess?: Callback1<PageSuggestionResults[]>,
    onError?: Callback1<EntireSiteSuggestionOnErrorParams>
) {
    try {
        for (const pageResult of suggestionResults.filter(pageSuggestion => !pageSuggestion?.error)) {
            ps.extensionAPI.ai.content.applyOutline(pageResult.pageSuggestion, pageResult.pageId)
        }
        onSuccess?.(suggestionResults)
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'AIContentApplyOutlinesForEntireSiteError'
        })
        onError?.({
            originalError: e,
            pageSuggestions: e?.extras?.pageSuggestionResults
        })
        ps.extensionAPI.logger.captureError(newError)
    }
}

async function applyOutlineToPage(
    ps: PS,
    suggestionData: {suggestionResults: EntitySuggestionResult},
    category: string,
    businessName: string,
    description: string,
    _compStructure: SerializedCompStructure,
    sectionCategory?: string,
    onSuccess?: Callback1<OutlineSuggestionResultForStructure>,
    onError?: Callback1<OutlineSuggestionOnErrorParams>
) {
    try {
        ps.extensionAPI.ai.content.applyOutline(
            suggestionData.suggestionResults.contentSuggestion,
            suggestionData.suggestionResults.entityId
        )
        onSuccess?.(suggestionData.suggestionResults.contentSuggestion)
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'AIContentApplyOutlineError'
        })
        onError?.({
            originalError: e,
            tokenUsage: suggestionData.suggestionResults.contentSuggestion?.completionMetadata?.tokenUsage
        })
        ps.extensionAPI.logger.captureError(newError)
    }
}

async function applyOutlineToPageStructure(
    ps: PS,
    suggestionData: {suggestionResults: EntitySuggestionResult; compStructure: SerializedCompStructure},
    category: string,
    businessName: string,
    description: string,
    _compStructure: SerializedCompStructure,
    onSuccess?: Callback2<SerializedCompStructure, OutlineSuggestionResultForStructure>,
    onError?: Callback1<SectionSuggestionOnErrorParams>
) {
    try {
        const {suggestionResults, compStructure} = suggestionData
        ps.extensionAPI.ai.content.applyOutlineToPageStructure(suggestionResults.contentSuggestion, compStructure)
        onSuccess?.(compStructure, suggestionResults.contentSuggestion)
    } catch (e: any) {
        const newError = getReportableFromError(e, {
            errorType: 'applyOutlinesToStructureError'
        })
        onError?.({
            originalError: e
        })
        ps.extensionAPI.logger.captureError(newError)
    }
}

const sendMessageInConversation = async (ps: PS, message: string) =>
    ps.extensionAPI.ai.conversation.sendMessage(message)

export const registerTool = (ps: PS, aiTool: AiToolRequest) => ps.extensionAPI.ai.conversation.registerTool(aiTool)

const api = {
    getSuggestedOutline,
    applyOutline,
    getSuggestedSiteOutlines,
    applyOutlines,
    getSuggestedOutlineByStructure,
    getSuggestedOutlineByPageStructure,
    applyOutlineToPageStructure,
    applyOutlineToPage,
    conversation: {
        sendMessage: sendMessageInConversation,
        registerTool
    },
    initAITools
}

export default api
