import type {OverlayHandle} from '../types'
import {getDomAndDmContent} from '../domAndDmContenxt'
import {createOverlayElement, removeOverlayElement} from './domUtils/overlayElementBuilder'
import {createIndicationElement} from './domUtils/indicationElementBuilder'
import _ from 'lodash'

export interface OverlayManager {
    cleanup(): void
    markComponent(compIds: string[], tag: string, indication: string, color: string, bottom: string, top: string): void
}

export const createOverlayManager = () => {
    const allHandles: Record<string, OverlayHandle> = {}

    const cleanup = () => {
        for (const [key, handle] of Object.entries(allHandles)) {
            removeOverlayElement(getDomAndDmContent().document, handle.baseElement)
            delete allHandles[key]
        }
    }

    const addOverlay = (componentIds: string[], id: string, color: string) => {
        const overlayElement = createOverlayElement(getDomAndDmContent().window, componentIds, id, color)
        const overlayHandle = {
            baseElement: overlayElement,
            additionalElements: {}
        }
        allHandles[id] = overlayHandle
        return overlayHandle
    }

    const markComponent = (
        compIds: string[],
        tag: string,
        indication: string,
        color: string,
        bottom: string,
        top: string
    ) => {
        const overlayId = `algoDebugOverlay-${_(compIds).uniq().sortBy().join('-')}`
        const componentManager = allHandles[overlayId] ?? addOverlay(compIds, overlayId, color)
        const newIndication = createIndicationElement(
            getDomAndDmContent().document,
            componentManager.baseElement,
            indication,
            color,
            bottom,
            top
        )
        componentManager.additionalElements[tag] = newIndication
    }

    return {
        cleanup,
        markComponent
    }
}
