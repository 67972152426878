import {componentStylesAndSkinsAPI} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                style: {
                    getObjectBasedStyle: publicMethodUtils.defineGetter(
                        componentStylesAndSkinsAPI.style.getObjectBasedStyle
                    ),
                    setObjectBasedStyle: publicMethodUtils.defineDataManipulationAction(
                        componentStylesAndSkinsAPI.style.setObjectBasedStyle
                    ),
                    getId: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.style.getId),
                    get: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.style.get),
                    update: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.update),
                    connectToTheme: publicMethodUtils.defineDataManipulationAction(
                        componentStylesAndSkinsAPI.style.connectToThemeStyle
                    ),
                    scoped: {
                        remove: publicMethodUtils.defineDataManipulationAction(
                            componentStylesAndSkinsAPI.style.removeScoped
                        )
                    },
                    fork: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.fork)
                },
                getColorsListInAllComponents: publicMethodUtils.extensionPublicAPI.getter(
                    'style.getColorsListInAllComponents'
                ),
                replaceColorInAllComponents: publicMethodUtils.extensionPublicAPI.dataManipulation(
                    'style.replaceColorInAllComponents'
                )
            }
        }
    }
}
