import _ from 'lodash'
import responsiveBlocksConfig from './responsiveBlocksConfig'
import responsiveConfig from './responsiveConfig'
import type {DSConfig} from '@wix/document-services-types'

const modulesList = ['localEditorPublicAPI']
const extensionsList = ['localEditor']

const createLocalEditorConfig = (origin: string, base: DSConfig): DSConfig => ({
    ...base,
    modulesList: _.union(base.modulesList, modulesList),
    extensionsList: _.union(base.extensionsList, extensionsList),
    cedit: false,
    disableSave: true,
    createRevision: false,
    autosaveRestore: 'false',
    disableAutoSave: true,
    continuousSave: false,
    concurrentWorkEnabled: false,
    acceptTransactionsFromDuplexer: false,
    isReadOnly: true,
    noUndo: true,
    localEditor: true,
    origin
})

const responsive = createLocalEditorConfig('responsiveLocalEditor', responsiveConfig)
const blocks = createLocalEditorConfig('blocksLocalEditor', responsiveBlocksConfig)

export {responsive, blocks}
