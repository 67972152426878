import type {
    VirtualStructureEntryItem,
    MobileAlgoConfig,
    MobileAlgoPluginInitializationArgs,
    ConversionDal,
    PluginContext,
    StructureIterators
} from '../../types'
import {createServerRequest} from './request'
import {CTX_SERVER, VIRTUAL_TAG} from './constants'
import type {Rect} from '@wix/document-services-types'
import type {GroupingResult} from './types'
import type {StructureEntryItem} from '../../conversionDal/types'
import {getMeasurementsPointer, getStructurePointer} from '../../conversionDal/pointerUtils'

export const groupingAIResult = async (
    structureIterators: StructureIterators,
    pageId: string,
    pluginInitArgs: MobileAlgoPluginInitializationArgs,
    contextHelper: PluginContext,
    config: MobileAlgoConfig
): Promise<GroupingResult> => {
    const {readOnlyExtensionAPI, environmentContext} = pluginInitArgs
    const requestData = createServerRequest(structureIterators, pageId, readOnlyExtensionAPI, config, contextHelper)
    const sendRequest = contextHelper.get(CTX_SERVER)

    return await sendRequest(readOnlyExtensionAPI, environmentContext, requestData)
}

const isContainer = (comp: StructureEntryItem) => {
    return comp?.components && comp?.components.length > 0
}

const calculateRealChildren = (children: VirtualStructureEntryItem[]): string[] =>
    children.flatMap(child => (child.componentType === VIRTUAL_TAG ? child.realChildren : [child.id]))

const calculateMeasurements = (children: VirtualStructureEntryItem[]): Rect => {
    const groupMeasurements = {left: Infinity, top: Infinity, right: 0, bottom: 0}

    children.forEach(({measurements}) => {
        groupMeasurements.left = Math.min(groupMeasurements.left, measurements.x)
        groupMeasurements.top = Math.min(groupMeasurements.top, measurements.y)
        groupMeasurements.right = Math.max(groupMeasurements.right, measurements.x + measurements.width)
        groupMeasurements.bottom = Math.max(groupMeasurements.bottom, measurements.y + measurements.height)
    })

    return {
        x: groupMeasurements.left,
        y: groupMeasurements.top,
        width: groupMeasurements.right - groupMeasurements.left,
        height: groupMeasurements.bottom - groupMeasurements.top
    }
}

export const createVirtualGroupMap = (
    serverResponse: GroupingResult,
    conversionDal: ConversionDal
): Record<string, VirtualStructureEntryItem> => {
    const componentToChildrenGroupMap: Record<string, VirtualStructureEntryItem> = {}

    const scanGroupingResultAndBuildGroupsTree = (group: GroupingResult, parent: GroupingResult) => {
        if (group.tag !== 'Group') {
            const compStructure = conversionDal.get(getStructurePointer({id: group.id}))
            const compMeasurements = conversionDal.get(getMeasurementsPointer({id: group.id}))

            componentToChildrenGroupMap[group.id] = {
                id: group.id,
                components: [],
                componentType: compStructure.componentType,
                measurements: compMeasurements,
                parentId: parent.id,
                realChildren: []
            }

            return
        }

        const childContainer = group.children!.find(child => {
            const compStructure = conversionDal.get(getStructurePointer({id: child.id}))

            return isContainer(compStructure)
        })?.id

        const getSoleSiblingContainerId = () => {
            const groupHasOnlyOneSibling = parent?.children?.length === 2

            if (groupHasOnlyOneSibling) {
                const sibling = parent?.children?.find(child => child.id !== group.id)
                const compStructure = conversionDal.get(getStructurePointer({id: sibling!.id}))
                const isSiblingContainer = isContainer(compStructure)

                if (isSiblingContainer) {
                    return isSiblingContainer
                }
            }
        }

        const soleSiblingContainer = getSoleSiblingContainerId()

        if (childContainer) {
            componentToChildrenGroupMap[group.id] = {
                id: group.id,
                components: group.children?.map(child => child.id) ?? [],
                componentType: 'CONTAINER_GROUP',
                parentId: parent.id
            } as VirtualStructureEntryItem
        }

        if (soleSiblingContainer && !childContainer) {
            componentToChildrenGroupMap[group.id] = {
                id: group.id,
                components: group.children?.map(child => child.id) ?? [],
                componentType: 'CHILDREN_GROUP',
                parentId: parent.id
            } as VirtualStructureEntryItem
        }

        if (!soleSiblingContainer && !childContainer) {
            componentToChildrenGroupMap[group.id] = {
                id: group.id,
                components: group.children?.map(child => child.id) ?? [],
                componentType: VIRTUAL_TAG,
                parentId: parent.id
            } as VirtualStructureEntryItem
        }

        for (const child of group.children!) {
            scanGroupingResultAndBuildGroupsTree(child, group)
        }

        if (childContainer) {
            // Remove the container-Group and replace it with the actual container (keep children)
            if (componentToChildrenGroupMap[parent.id]) {
                const index = componentToChildrenGroupMap[parent.id]!.components!.findIndex(child => child === group.id)
                componentToChildrenGroupMap[parent.id].components!.splice(index, 1, childContainer)
            }

            componentToChildrenGroupMap[childContainer].parentId = parent.id
            componentToChildrenGroupMap[childContainer].components = componentToChildrenGroupMap[
                group.id
            ].components!.filter(childId => childId !== childContainer)

            componentToChildrenGroupMap[childContainer].components!.forEach(comp => {
                componentToChildrenGroupMap[comp].parentId = childContainer
            })

            const children = componentToChildrenGroupMap[childContainer].components!.map(
                childId => componentToChildrenGroupMap[childId]
            ) as VirtualStructureEntryItem[]
            componentToChildrenGroupMap[childContainer].realChildren = calculateRealChildren(children)

            delete componentToChildrenGroupMap[group.id]
            return
        }

        if (soleSiblingContainer) {
            // Remove the child-group and insert the children where the child-group used to be
            const index = componentToChildrenGroupMap[parent.id].components!.findIndex(child => child === group.id)
            componentToChildrenGroupMap[parent.id].components!.splice(
                index,
                1,
                ...componentToChildrenGroupMap[group.id].components!
            )

            componentToChildrenGroupMap[group.id].components?.forEach(comp => {
                componentToChildrenGroupMap[comp].parentId = parent.id
            })

            delete componentToChildrenGroupMap[group.id]

            return
        }

        if (componentToChildrenGroupMap[group.id].componentType === VIRTUAL_TAG) {
            const children = componentToChildrenGroupMap[group.id].components!.map(
                childId => componentToChildrenGroupMap[childId]
            ) as VirtualStructureEntryItem[]
            componentToChildrenGroupMap[group.id].measurements = calculateMeasurements(children)
            componentToChildrenGroupMap[group.id].realChildren = calculateRealChildren(children)

            return
        }
    }

    scanGroupingResultAndBuildGroupsTree(serverResponse.children![0], serverResponse)

    return componentToChildrenGroupMap
}
