import type {DalItem} from '@wix/document-manager-core'
import {addIdToMap, getCustomId} from '../utils'
import {DATA_TYPES} from '../../../../constants/constants'
import {addNamespaceToStructure} from '../componentDeserializationStructure'
import type {DeserializeComponentParams} from '../types'

export const updateComponentMobileHintsStructure = (deserializeComponentParams: DeserializeComponentParams) => {
    const {compStructure, customId, mappers} = deserializeComponentParams
    const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}
    deserializeComponentParams.customId = getCustomId(oldToNewIdMap, compStructure.mobileHints, customId)
    const mobileHintsQuery = addNamespaceToStructure(
        deserializeComponentParams,
        compStructure.mobileHints as DalItem,
        DATA_TYPES.mobileHints,
        oldToNewIdMap
    )

    if (mobileHintsQuery) {
        compStructure.mobileHintsQuery = mobileHintsQuery
        addIdToMap(compStructure.mobileHints, mobileHintsQuery, oldToNewIdMap)
    }
}
