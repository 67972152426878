import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, DalValue, Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../../../dataModel/dataModel'
import {DATA_TYPES} from '../../../../constants/constants'
import {setCompQueryValue, updateComponentNonRootScopedValues} from './scopedValues'
import {addIdToMap} from '../utils'
import type {VariablesExtensionAPI} from '../../../variables/variables'
import type {DeserializeComponentParams} from '../types'
import _ from 'lodash'

const addAncestorsVariablesToMap = (
    createExtArgs: CreateExtArgs,
    componentPointer: Pointer,
    oldToNewIdMap: Record<string, string>
) => {
    const {variables} = createExtArgs.extensionAPI as VariablesExtensionAPI
    const variablesIds = variables.collectAncestorsVariablesIds(componentPointer)
    _.forEach(variablesIds, id => addIdToMap({id}, id, oldToNewIdMap))
}

export const updateComponentVariablesDefinition = (
    deserializationParams: DeserializeComponentParams,
    isAncestorsChecked?: boolean
) => {
    const {createExtArgs, compStructure, pageId, mappers, containerPointer} = deserializationParams
    const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI

    const {oldToNewIdMap} = mappers ?? {}
    if (!isAncestorsChecked) {
        addAncestorsVariablesToMap(createExtArgs, containerPointer!, mappers?.oldToNewIdMap ?? {})
    }
    const itemType = DATA_TYPES.variables
    const variablesList = compStructure[itemType as keyof CompStructure] as {variables: DalValue[]}
    if (!_.isObject(variablesList)) return
    updateComponentNonRootScopedValues(createExtArgs, compStructure, itemType, pageId, mappers)

    const newVariablesListId = dataModel.addItem(
        variablesList,
        itemType,
        pageId,
        undefined,
        undefined,
        oldToNewIdMap
    ).id

    setCompQueryValue(compStructure, newVariablesListId, itemType)
}
