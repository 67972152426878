import {type DAL, type DalItem, type DocumentManager, type IndexKey, pointerUtils} from '@wix/document-manager-core'
import type {PageAPI} from '../../../page'
import * as constants from '../../../../constants/constants'
import type {PageMigrator} from '../dataMigrationRunner'
import _ from 'lodash'
import type {ViewModes} from '@wix/document-services-types'
import type {ExperimentalVersion} from '../../../dataFixerVersioning/dataFixerVersioning'

const {VIEW_MODES} = constants
const {DESKTOP, MOBILE} = VIEW_MODES

const fixDuplicatedChildrenIdsForNamespace = (dal: DAL, pageCompFilter: IndexKey, namespace: keyof ViewModes) => {
    const components: Record<string, DalItem> = dal.query(namespace, pageCompFilter)

    for (const [componentId, component] of Object.entries(components)) {
        const childIds = component.components
        const uniqueChildIds = _.uniq(childIds)

        if (!childIds || childIds.length === uniqueChildIds.length) {
            continue
        }

        dal.set(pointerUtils.getPointer(componentId, namespace, {innerPath: ['components']}), uniqueChildIds)
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {dal, extensionAPI} = documentManager
    const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)

    fixDuplicatedChildrenIdsForNamespace(dal, pageCompFilter, DESKTOP)
    fixDuplicatedChildrenIdsForNamespace(dal, pageCompFilter, MOBILE)
}

const name = 'fixDuplicatedChildrenIds'
const version = 1
const experimentalVersions: ExperimentalVersion[] = []

export const fixDuplicatedChildrenIds: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions
}
