import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ComponentAfterAddDataEvent} from '../hooks'
import {addPlatformAppToPageIfFirstController, setControllerStateFromSerializedStructure} from './utils/platformAppComp'
import {getControllerAppDefinitionId} from '../../appControllerData/appControllerUtils'
import type {MetadataHookEventData} from '../../componentsMetadata/metadataHooks'
import type {StructureExtensionAPI} from '../../structure'
import type {ComponentsMetadataAPI} from '../../componentsMetadata/componentsMetadata'
import {COMP_TYPES} from '../../../constants/constants'
import _ from 'lodash'

export const handleAppWidgetAddition = (createExtArgs: CreateExtArgs, data: ComponentAfterAddDataEvent) => {
    const {compToAddPointer, componentDefinition} = data
    setControllerStateFromSerializedStructure(createExtArgs, compToAddPointer, componentDefinition)
    addPlatformAppToPageIfFirstController(
        createExtArgs,
        compToAddPointer,
        getControllerAppDefinitionId(componentDefinition.data)!
    )
}

export const isContainableByStructureInAppWidget = (
    {extensionAPI}: CreateExtArgs,
    {additionalArguments}: MetadataHookEventData,
    originalMetadataValue?: boolean
): boolean | undefined => {
    if (originalMetadataValue === false) {
        return false
    }

    const {components, componentsMetadata} = extensionAPI as StructureExtensionAPI & ComponentsMetadataAPI
    const [compStructure, potentialContainerPointer] = additionalArguments

    if (components.getComponentType(potentialContainerPointer) === COMP_TYPES.APP_WIDGET_TYPE) {
        const rootChild = _.head(components.getChildren(potentialContainerPointer))
        if (!rootChild) {
            return originalMetadataValue
        }
        return componentsMetadata.isContainableByStructurePublic(compStructure, rootChild)
    }

    return true
}
