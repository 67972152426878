import type {
    MobileAlgoContext,
    Stage,
    StageHandler,
    StructureStageData,
    ConversionDal,
    StructureIterators,
    MobileAlgoConfig
} from '../types'
import type {SnapshotDal} from '@wix/document-manager-core'

export const createStructureStage = (): Stage<StructureStageData> => {
    const handlers: StageHandler<StructureStageData>[] = []

    return {
        register: (handler: StageHandler<StructureStageData>) => {
            handlers.push(handler)
        },
        run: async (
            ctx: MobileAlgoContext,
            conversionDal: ConversionDal,
            initialSnapshot: SnapshotDal,
            structureIterators: StructureIterators,
            pageId: string,
            config: MobileAlgoConfig
        ) => {
            for (const handler of handlers) {
                await handler({
                    globalContext: ctx,
                    conversionDal,
                    initialSnapshot,
                    structureIterators,
                    pageId,
                    config
                })
            }
        }
    }
}
