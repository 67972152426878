import _ from 'lodash'
import type {Experiment, RunningExperiments} from '@wix/document-services-types'

const isTb = process.env.VIEWER_NAME === 'tb'
const runningExperiments: RunningExperiments = {
    dm_stretchRichText: 'true',
    dm_failSplitQueries: 'new',
    dm_throwOnSoqFailure: 'new',
    dm_fixCrossPagePropsAndDuplicateOwnedBehaviors: 'new',
    dm_failCsaveInitConflict: 'new',
    dm_useWhitelistRemovalForCsave: 'new',
    dm_repeatersFromViewer: 'old', //should turn on when we are ready to open in prod
    dm_disableValidationWhitelist: 'new',
    dm_enableMLSlug: 'true',
    dm_nsTypesFailInvalidDesign: 'old', //will turn on by default once we resolve all currently failing tests
    dm_validateSamePageMobileFail: 'new',
    dm_repeaterDontAddRepeatedRefCompData: 'new',
    dm_mobileAlgoValidationNoConduct: 'true',
    dm_fixTextLinksAfterRefAddToContainer: 'new',
    'specs.UseTBAsMainRScript': isTb ? 'new' : 'old',
    'specs.UseTbInPreview': isTb ? 'new' : 'old',
    dm_fixerVersioningDataCollection: 'new',
    dm_runDalStrictSchemaValidation: 'true',
    dm_failDalStrictSchemaValidation: 'true',
    dm_noWhitelistDalStrictSchemaValidation: 'false', // will be turned on only after cleanup of whitelist
    dm_missingSkinValidator: 'true',
    dm_forceConfigName: 'true',
    dm_blocksNewDevCenterApiComponents: 'true',
    dm_reportRemoteStructureSet: 'true',
    dm_deleteRemoveByParent: 'true',
    dm_validateStylableStyles: 'true',
    dm_validateMigratedStylableStyles: 'true',
    dm_useViewerIsShowOnFixedPosition: 'true',
    dm_internalRefGarbageCollector: 'true',
    dm_sendPresetInfoInBlocksBuild: 'true',
    dm_failOnExceedsMaxActionsPerTransaction: 'true',
    dm_enableControllerForBlocksContainers: 'true',
    dm_recalculateBlocksOnGroupingAndUngrouping: 'true',
    dm_fixTextScaleInMobileAlgo: 'true',
    dm_noRecovery2: 'new',
    dm_platformWorkerFedopsLoggerDefaultsOverride: 'true',
    dm_returnAllAppDataInAddApps: 'true',
    dm_returnErrorToEditorOnCsaveNetworkError: 'true',
    dm_oldHoverBoxDeprecationFixer: 'true',
    dm_menuStructureValidation: 'true',
    dm_useUserCodeDependencyManagerV2: 'true',
    dm_wixCodeNewProvision: 'true',
    dm_performAutoSaveOnInitMethod: 'true',
    dm_rejectDalOnBuildingPayloadForCsaveError: 'true',
    dm_fixSystemStyleAsRefArray: 'true',
    dm_publishPipelineRcSite: 'true',
    dm_passNestedWidgetsPageIdsMapForBuild: 'true',
    dm_encodeFixerVersioning: 'true',
    dm_allowAddingStripForNonFullWidthPopup: 'true',
    dm_moveMetadataIsContainableByStructureToExt: 'true',
    dm_runHooksInGetMetadataByStructure: 'true',
    dm_handlePendingSnapshotsAfterFinishedAllSaves: 'true',
    dm_moveMaxChildrenNumberMetadataToExt: 'true',
    dm_moveCompSanitationToExt: 'true',
    dm_moveCompValidationToExt: 'true',
    dm_meshLayoutSafeguard: 'new',
    dm_settleFromInstallState: 'true',
    dm_moveAfterAddComponentHook: 'true',
    dm_useExtensionValidateComponentToAdd: 'new',
    dm_builderDataInAppDescriptor: 'false', //will turn on only for builder
    dm_moveNicknameContextProviderExt: 'new',
    dm_moveFixersToExtension: 'new',
    dm_splitFixersToMultipleSaves: 'true',
    dm_fixTpaw0SystemStyleMissingSkin: 'true',
    dm_moveRefCompAddHook: 'new',
    dm_shouldFailInvalidSlotQuery: 'new',
    dm_shouldValidateComponentLayoutMobileAlgo: 'true',
    dm_useExtStatesAndTriggersDeserializers: 'new',
    dm_moveComponentScopedValuesToExt: 'new',
    dm_moveComponentLayoutToExt: 'new',
    dm_refactorPlatformUninstall: 'new',
    dm_waitForPendingCsaveIfNothingToSaveOnSaveAndWait: 'new',
    dm_moveDeserializeComponentDataToExt: 'new',
    dm_multilingualTextLinkSupport: 'new',
    dm_moveComponentDesignToExt: 'new',
    dm_moveComponentReactionsToExt: 'new',
    dm_moveComponentTransitionsToExt: 'new',
    dm_moveComponentTransformationsToExt: 'new',
    dm_moveComponentFeaturesToExt: 'new',
    dm_moveComponentEffectsToExt: 'new',
    dm_moveBehaviorsDeserializerToExt: 'new',
    dm_moveBeforeAddComponentHook: 'new',
    dm_livePreviewRefreshAllConnectedControllersRecursively: 'new',
    dm_useExtensionUpdateDisplayedOnlyComponentsDefs: 'new',
    dm_moveCollectAncestorsVariablesIdsToExt: 'new',
    dm_useExtensionUpdateComponentVariablesDefinition: 'new',
    dm_moveVariantUtilsToExt: 'new',
    dm_validatorNoEmptyValuesInRefArray: 'new',
    dm_useExtensionUpdateVariantsInStructure: 'new',
    dm_fixCsaveAfterFinishedSaving: 'new',
    dm_ignoreOutOfSyncOnFirstDuplexerSubscription: 'new',
    dm_updateAutoSaveInfoAfterSave: 'new'
}

export const getDefaultExperiments = () => _.cloneDeep(runningExperiments)

export const isExperimentOn = (value: string) => !!(value && value !== 'old' && value !== 'false')

export const createTestExperimentInstance = (experimentOverrides?: RunningExperiments): Experiment => {
    const experiments = _.merge({}, runningExperiments, experimentOverrides)
    return {
        getRunningExperiments: () => experiments,
        getValue: (name: string) => experiments[name],
        isOpen: (name: string) => {
            const value = experiments[name]
            return isExperimentOn(value)
            // return !!(value && value !== 'old' && value !== 'false')
        },
        isMultiValueExperimentOpen: (name: string) => !!experiments[name]
    }
}
