import type {CompStructure, Pointer} from '@wix/document-services-types'
import {createHookDefinition} from '../../utils/hooksUtils'
import type {HookEventData} from '../hooks/hooks'

export interface ComponentAfterAddData {
    componentType: string
    compToAddPointer: Pointer
    componentDefinition: CompStructure
    optionalCustomId?: string
    mappers: any
    containerPointer: Pointer
}

export interface ComponentAfterRootAddData {
    componentType: string
    compToAddPointer: Pointer
    componentDefinition: CompStructure
    containerPointer: Pointer
}

export interface ComponentAfterAddDataEvent extends ComponentAfterAddData, HookEventData<'component_after_add'> {}
export interface RefComponentAfterAddDataEvent
    extends ComponentAfterAddData,
        HookEventData<'ref_component_after_add'> {}
export interface ComponentAfterAddDataFromExtEvent
    extends ComponentAfterAddData,
        HookEventData<'component_after_add_from_ext'> {}
export interface AdjustComponentLayoutFromExtEvent
    extends ComponentAfterAddData,
        HookEventData<'adjust_component_layout_from_ext'> {}

export interface ComponentBeforeAddData {
    componentType: string
    compToAddPointer: Pointer
    componentDefinition: CompStructure
    optionalCustomId?: string
    mappers: any
    containerPointer: Pointer
    isPage: boolean
    isAncestorsChecked: boolean
}
export interface ComponentBeforeAddDataEvent extends ComponentBeforeAddData, HookEventData<'component_before_add'> {}
export interface ComponentAfterRootAddDataEvent
    extends ComponentAfterRootAddData,
        HookEventData<'root_component_after_add'> {}

export const COMPONENTS_HOOKS = {
    ADD_COMPONENT: {
        BEFORE: createHookDefinition<'component_before_add', ComponentBeforeAddData>('component_before_add'),
        AFTER: createHookDefinition<'component_after_add', ComponentAfterAddData>('component_after_add'),
        REF_COMPONENT_AFTER: createHookDefinition<'ref_component_after_add', RefComponentAfterAddDataEvent>(
            'ref_component_after_add'
        ),
        AFTER_FROM_EXT: createHookDefinition<'component_after_add_from_ext', ComponentAfterAddDataFromExtEvent>(
            'component_after_add_from_ext'
        ),
        ADJUST_COMP_LAYOUT: createHookDefinition<'adjust_component_layout_from_ext', AdjustComponentLayoutFromExtEvent>(
            'adjust_component_layout_from_ext'
        )
    },
    ADD_ROOT: {
        AFTER: createHookDefinition<'root_component_after_add', ComponentAfterRootAddData>('root_component_after_add')
    }
}
