import socialComp from './utils/socialComp'
import type {Pointer, PS} from '@wix/document-services-types'
import type {ComponentAfterAddDataFromExtEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

export const upgradeFacebookCommentsOnAddition = (ps: PS, compPointer: Pointer) => {
    socialComp.upgradeUrl(ps, compPointer, 'facebook.comments')
}
export const upgradeFacebookCommentsOnAdditionFromExt = (ps: PS, data: ComponentAfterAddDataFromExtEvent) => {
    const {compToAddPointer} = data
    upgradeFacebookCommentsOnAddition(ps, compToAddPointer)
}
