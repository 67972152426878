// @ts-expect-error problem with ts declaration exports
import {createDevEditorClient, type WmlDescriptor} from '@wix/editor-velo-cli-comm-api'
import {ReportableError} from '@wix/document-manager-utils'
import type {WMLSite} from '@wix/document-services-types'
import _ from 'lodash'
import {pointerUtils} from '@wix/document-manager-core'
import {VIEW_MODES} from '../../../constants/constants'

const DEFAULT_CONNECTION_TIMEOUT = 5000

type SyncWmlChangesCallback = (wml: WMLSite) => Promise<void>

export interface VeloCliService {
    connect(): Promise<{wml: WMLSite}>
    syncChanges(callback: SyncWmlChangesCallback): void
    save(wml: WMLSite): Promise<void>
}

export const veloCliService = (port: number): VeloCliService => {
    const devEditorClient = createDevEditorClient()

    const convertDescriptorsToWmlSite = (wmlDescriptors: WmlDescriptor[]): WMLSite => {
        return {
            pages: _(wmlDescriptors)
                .keyBy(({pagePointer}: WmlDescriptor) => pagePointer.id)
                .mapValues(({data}) => data)
                .value()
        }
    }

    // currently, WmlDescriptor['data'] is not nullable, but it should be (null means the page is deleted)
    // this is a temporary workaround until @wix/editor-velo-cli-comm-api is updated
    interface TemporaryWmlDescriptor {
        pagePointer: WmlDescriptor['pagePointer']
        data: WmlDescriptor['data'] | null
    }
    const convertWmlSiteToDescriptors = (wmlSite: WMLSite): TemporaryWmlDescriptor[] => {
        return Object.entries(wmlSite.pages).map(([pageId, data]) => ({
            pagePointer: pointerUtils.getCompPointer(pageId, VIEW_MODES.DESKTOP),
            data
        }))
    }

    const connect = async () => {
        let wml: WmlDescriptor[] | undefined
        try {
            const {currentWmlDescriptors} = await devEditorClient.connect(port, {
                timeoutMs: DEFAULT_CONNECTION_TIMEOUT
            })

            wml = currentWmlDescriptors
        } catch (e) {
            throw new ReportableError({
                errorType: 'failedConnectToVeloCliWS',
                message: 'Failed to establish connection with velo-cli-comm-api'
            })
        }

        if (!wml) {
            throw new ReportableError({
                errorType: 'failedConnectToVeloCliWS',
                message: 'Connection to velo-cli-comm-api established, but no data received'
            })
        }

        return {wml: convertDescriptorsToWmlSite(wml)}
    }

    const syncChanges = (callback: SyncWmlChangesCallback) =>
        devEditorClient.on(
            'wmlChange',
            async changedWmlDescriptors => await callback(convertDescriptorsToWmlSite(changedWmlDescriptors))
        )

    const save = async (wml: WMLSite) =>
        devEditorClient.syncWmlChange(convertWmlSiteToDescriptors(wml) as WmlDescriptor[])

    return {
        connect,
        syncChanges,
        save
    }
}
