import type {PublicMethodUtils} from '@wix/document-services-types'
import {component, importExport, page} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            importExport: {
                components: {
                    wml: {
                        export: publicMethodUtils.defineGetter(importExport.wml.exportComponent),
                        replace: publicMethodUtils.defineDataManipulationAction(importExport.components.replace, {
                            asyncPreDataManipulation: importExport.wml.convertWmlToRawComponentExport,
                            isAsyncOperation: true
                        }),
                        add: publicMethodUtils.defineDataManipulationAction(importExport.components.add, {
                            asyncPreDataManipulation:
                                importExport.wml.convertWmlToRawComponentExportWithContainerArgument,
                            getReturnValue: component.getComponentToAddRef,
                            isAsyncOperation: true
                        })
                    },
                    core: {
                        export: publicMethodUtils.defineGetter(importExport.core.exportComponent),
                        replace: publicMethodUtils.defineDataManipulationAction(importExport.components.replace, {
                            asyncPreDataManipulation: importExport.core.convertCoreToRawComponentExport,
                            isAsyncOperation: true
                        }),
                        add: publicMethodUtils.defineDataManipulationAction(importExport.components.add, {
                            asyncPreDataManipulation:
                                importExport.core.convertCoreToRawComponentExportWithContainerArgument,
                            getReturnValue: component.getComponentToAddRef,
                            isAsyncOperation: true
                        })
                    }
                },
                pages: {
                    wml: {
                        export: publicMethodUtils.defineGetter(importExport.wml.exportPage),
                        replace: publicMethodUtils.defineDataManipulationAction(importExport.pages.replace, {
                            asyncPreDataManipulation: importExport.wml.convertWmlToRawComponentExport,
                            isAsyncOperation: true
                        }),
                        add: publicMethodUtils.defineDataManipulationAction(importExport.pages.add, {
                            asyncPreDataManipulation: importExport.wml.convertWmlToRawComponentExport,
                            getReturnValue: page.getPageIdToAdd,
                            isAsyncOperation: true
                        })
                    },
                    core: {
                        export: publicMethodUtils.defineGetter(importExport.core.exportPage),
                        replace: publicMethodUtils.defineDataManipulationAction(importExport.pages.replace, {
                            asyncPreDataManipulation: importExport.core.convertCoreToRawComponentExport,
                            isAsyncOperation: true
                        }),
                        add: publicMethodUtils.defineDataManipulationAction(importExport.pages.add, {
                            asyncPreDataManipulation: importExport.core.convertCoreToRawComponentExport,
                            getReturnValue: page.getPageIdToAdd,
                            isAsyncOperation: true
                        })
                    }
                }
            }
        }
    }
}
