import {CoreConfig, CoreLogger, createDMCore, DocumentManager} from '@wix/document-manager-core'
import type {DataFixer, Experiment, PageListWithMasterAndMainPage, DSConfig} from '@wix/document-services-types'
import * as santaDataFixer from '@wix/santa-data-fixer'
import _ from 'lodash'
import {createConfig, isCEditOpen} from './createConfig'
import {loadExtensions} from './extensionLoader'
import {DefaultFetchPagesFacade} from './initialize/fetchPages'
import {InitResult, initialize, InitParams} from './initialize/mainInitialization'
import type {
    DocumentServicesModel,
    DocumentServicesModelForServer,
    RendererModel,
    RendererModelForServer
} from './initialize/modelTypes'
import type {BootstrapConfig} from './types'
import {ReportableError} from '@wix/document-manager-utils'
import {createDataMigrationRunner, DataMigrationRunner} from '@wix/document-manager-extensions'
export type {FetchPagesToDalFunction} from './initialize/mainInitialization'

export {isCEditOpen}
export type EnvironmentContext = Record<string, any>

const buildCoreConfig = (
    config: DSConfig,
    experimentInstance: Experiment,
    logger: CoreLogger,
    schemaService: any,
    tracking?: Function
): CoreConfig => {
    return {
        experimentInstance,
        logger,
        tracking,
        schemaService,
        disableCommonConfig: config.disableCommonConfig,
        transactionActionsLimit: config.transactionActionsLimit,
        signatureSeed: config.signatureSeed,
        undoRedoConfig: config.undoRedoConfig,
        supportsUsingPresetVariants: config.supportsUsingPresetVariants,
        dontCollectFixerVersionData: config.dontCollectFixerVersionData,
        minNumOfPagesToCalculateRenderHints: config.minNumOfPagesToCalculateRenderHints
    }
}

/**
 * Create a working document Manager which includes the core, registered extensions and initialized state
 * the Document Manager is ready to accept set operations. Public APIs are not initialized yet
 */
const createDocumentManager = (
    config: DSConfig,
    experimentInstance: Experiment,
    logger: CoreLogger,
    schemaService: any,
    environmentContext: EnvironmentContext
): DocumentManager => {
    const coreConfig = buildCoreConfig(
        config,
        experimentInstance,
        logger,
        schemaService,
        _.get(environmentContext, ['trackingFn'])
    )
    const core = createDMCore(coreConfig)
    loadExtensions(core, config, environmentContext)
    return core
}

export type AnyRendererModel = RendererModelForServer | RendererModel

export interface RendererModelBuilderForHost {
    getRendererModel(): Promise<AnyRendererModel>
}

interface Models {
    serviceTopology: Record<string, any>
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
}

type InitFunc = (initObj: InitParams) => Promise<InitResult>

export interface CreateHostArgs {
    models: Models
    partialPages?: string[]
    config: BootstrapConfig
    experimentInstance: Experiment
    logger: CoreLogger
    schemaService: any
    pageList: PageListWithMasterAndMainPage
    rendererModelBuilder: RendererModelBuilderForHost
    environmentContext?: EnvironmentContext
    dataFixer?: DataFixer
    dataMigrationRunner?: DataMigrationRunner
    initFunc?: InitFunc
}

export interface Host {
    readonly documentManager: DocumentManager
    readonly config: DSConfig
    _dsInitTimeoutHandler?: any //should be number
    runInitializers(): Promise<InitResult>
    environmentContext: EnvironmentContext
}

export const createHost = ({
    models,
    partialPages = [],
    config: bootstrapConfig,
    experimentInstance,
    logger,
    schemaService,
    environmentContext: envContext = {},
    dataFixer = santaDataFixer,
    dataMigrationRunner = createDataMigrationRunner(),
    initFunc,
    pageList,
    rendererModelBuilder
}: CreateHostArgs): Host => {
    if (!experimentInstance.isOpen('dm_forceConfigName') && !bootstrapConfig.configName) {
        // Remove this whole block when dm_forceConfigName is merged
        logger.captureError(
            new ReportableError({
                message: 'Missing a configName parameter',
                errorType: 'missingConfigNameError'
            })
        )
        bootstrapConfig.configName = 'fullFunctionality'
    }

    const environmentContext: EnvironmentContext = {
        fetchPagesFacade: _.get(envContext, ['fetchPagesFacade']) ?? new DefaultFetchPagesFacade(),
        ...envContext
    }
    const config: DSConfig = createConfig(bootstrapConfig, experimentInstance)
    const documentManager = createDocumentManager(config, experimentInstance, logger, schemaService, environmentContext)

    const runInitializers = async (): Promise<InitResult> => {
        const {serviceTopology, documentServicesModel} = models
        const init = initFunc ?? initialize
        const initResult = await init({
            documentManager,
            partialPages: pagesToLoad(config, partialPages, pageList),
            dataFixer,
            dataMigrationRunner,
            serviceTopology,
            documentServicesModel,
            rendererModelBuilder,
            config,
            logger,
            fetchFn: environmentContext.fetchFn,
            trackingFn: environmentContext.trackingFn,
            experimentInstance,
            fetchPagesFacade: environmentContext.fetchPagesFacade,
            pageList
        })
        await documentManager.initialize()
        return initResult
    }

    return {
        documentManager,
        runInitializers,
        config,
        environmentContext
    }
}

function pagesToLoad(config: DSConfig, partialPages: string[], pageList: PageListWithMasterAndMainPage) {
    if (!_.isEmpty(partialPages)) {
        return partialPages
    }
    if (config.lazyLoadPages) {
        return [pageList.mainPageId, 'masterPage']
    }
    return [] // This means all pages in the partial pages API
}
