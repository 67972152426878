import {type DocumentManager, pointerUtils} from '@wix/document-manager-core'
import _ from 'lodash'
import type {DataModelExtensionAPI, DataModelAPI} from '../../../dataModel/dataModel'
import type {DataExtensionAPI} from '../../../data'
import type {SchemaExtensionAPI} from '../../../schema/schema'
import * as constants from '../../../../constants/constants'
import {type ComponentMigrationFunction, runOnPageComponents} from './utils/migrationUtil'
import type {PageMigrator} from '../dataMigrationRunner'
import {ReportableWarning} from '@wix/document-manager-utils'

const {DATA_TYPES, MASTER_PAGE_ID, REF_ARRAY_DATA_TYPE} = constants
const {getPointer} = pointerUtils

const fixSystemStyleRefArrayFn: ComponentMigrationFunction = (documentManager, pageId, pointer) => {
    const {dal, extensionAPI} = documentManager
    const component = dal.get(pointer)
    const styleItem = (extensionAPI as DataModelExtensionAPI).dataModel.components.getItem(pointer, DATA_TYPES.theme)

    if (
        styleItem &&
        (extensionAPI as SchemaExtensionAPI).schemaAPI.isSystemStyle(styleItem.id) &&
        styleItem.type === REF_ARRAY_DATA_TYPE
    ) {
        const customStylesInRefArray = (extensionAPI as DataExtensionAPI).data.refArray.extractValues(styleItem)
        if (customStylesInRefArray.length === 0) {
            documentManager.logger.captureError(
                new ReportableWarning({
                    errorType: 'systemStyleAsRefArray',
                    message: `System style is used as ref array in component without any custom styles`,
                    extras: {
                        page: pageId,
                        componentId: pointer.id,
                        componentType: component.componentType,
                        styleId: styleItem.id
                    }
                })
            )
            return
        }
        if (customStylesInRefArray.length > 1) {
            documentManager.logger.captureError(
                new ReportableWarning({
                    errorType: 'systemStyleAsRefArray',
                    message: `System style is used as ref array in component with multiple custom styles`,
                    extras: {
                        page: pageId,
                        componentId: pointer.id,
                        componentType: component.componentType,
                        styleId: styleItem.id
                    }
                })
            )
            return
        }
        const styleDefaultValue = customStylesInRefArray[0]

        const refArrayPointer = getPointer(styleItem.id, DATA_TYPES.theme)

        ;(extensionAPI.dataModel as DataModelAPI).removeItemRecursively(refArrayPointer)

        styleDefaultValue.id = styleItem.id
        styleDefaultValue.styleType = 'system'
        styleDefaultValue.type = 'TopLevelStyle'
        ;(extensionAPI.dataModel as DataModelAPI).addItem(styleDefaultValue, DATA_TYPES.theme, MASTER_PAGE_ID)
    }
}
const migratePage = (documentManager: DocumentManager, pageId: string) => {
    if (documentManager.experimentInstance.isOpen('dm_fixSystemStyleAsRefArray')) {
        _.forEach(constants.VIEW_MODES, viewMode => {
            runOnPageComponents(documentManager, pageId, viewMode, fixSystemStyleRefArrayFn)
        })
    }
}

const name = 'fixSystemStyleRefArray'
const version = 0

export const fixSystemStyleRefArray: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions: [{version: 1, experiment: 'dm_fixSystemStyleAsRefArray'}]
}
