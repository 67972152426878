import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'

export interface DeprecationAPI extends ExtensionAPI {
    setShouldThrowOnDeprecation(newValue: boolean): void
    getShouldThrowOnDeprecation(): boolean
}

export type DeprecationExtensionAPI = ExtensionAPI & {
    deprecation: DeprecationAPI
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({}: CreateExtArgs): DeprecationExtensionAPI => {
        let shouldThrowOnDeprecation: boolean = false
        return {
            deprecation: {
                setShouldThrowOnDeprecation: (newValue: boolean) => {
                    shouldThrowOnDeprecation = newValue
                },
                getShouldThrowOnDeprecation: () => {
                    return shouldThrowOnDeprecation
                }
            }
        }
    }

    return {
        name: 'deprecation',
        createExtensionAPI
    }
}

export {createExtension}
