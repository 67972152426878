import type {CreateExtArgs, CreateExtension, DeepFunctionMap, ExtensionAPI} from '@wix/document-manager-core'
import {runSiteMigratorsFactory} from './runSiteMigrators'
import type {PageAPI, PageExtensionAPI} from '../page'
import {runPageMigrators, runSantaDataFixers} from './fixPage'

const pushMasterPageToEnd = (pageIds: string[]) => {
    const index = pageIds.indexOf('masterPage')
    if (index !== -1) {
        pageIds.splice(index, 1)
    }
    pageIds.push('masterPage')
}

const filterLoadedPages = (pageIds: string[], pageAPI: PageAPI) =>
    pageIds.filter((pageId: string) => pageAPI.hasPageBeenLoaded(pageId))

const createExtension: CreateExtension = ({experimentInstance, logger, dsConfig}) => {
    const createExtensionAPI = ({dal, extensionAPI, pointers, coreConfig}: CreateExtArgs): FixersExtensionAPI => {
        const fixersApi = {dal, extensionAPI, pointers, config: coreConfig, experimentInstance, logger}

        const fixPage = async (pageId: string) => {
            if (!dsConfig.skipFixers) {
                await runSantaDataFixers(fixersApi, pageId, dsConfig)
            }

            if (!dsConfig.skipDataMigrators) {
                runPageMigrators(fixersApi, pageId)
            }

            if (experimentInstance.isOpen('dm_splitFixersToMultipleSaves')) {
                dal.commitTransaction(`dataFixer-per-page-${pageId}`, true)
            }
        }

        const runSiteMigrators = runSiteMigratorsFactory(fixersApi)

        const fixAllPagesAndMigrateSite = async (pageIds: string[]) => {
            const {page} = extensionAPI as PageExtensionAPI
            const allSitePages = page.getAllPagesIds(true)

            if (pageIds.length === 0) {
                pageIds = allSitePages
            }

            const loadedPageIds = filterLoadedPages(pageIds, page)
            pushMasterPageToEnd(loadedPageIds) // masterPage should be last

            for (const pageId of loadedPageIds) {
                await fixPage(pageId)
            }

            if (dsConfig.skipDataMigrators) return

            if (loadedPageIds.sort().toString() === allSitePages.sort().toString()) {
                runSiteMigrators()
            }
        }

        return {
            fixers: {
                fixPage,
                runSiteMigrators,
                fixAllPagesAndMigrateSite
            }
        }
    }

    return {
        name: 'fixers',
        createExtensionAPI
    }
}

export {createExtension}

export interface FixersAPI extends DeepFunctionMap {
    fixPage(pageId: string): Promise<void>
    runSiteMigrators(): void
    fixAllPagesAndMigrateSite(pageIds: string[]): Promise<void>
}

export interface FixersExtensionAPI extends ExtensionAPI {
    fixers: FixersAPI
}
