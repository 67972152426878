import {
    CreateExtensionArgument,
    DmApis,
    pointerUtils,
    type CreateExtArgs,
    type Extension,
    type ExtensionAPI
} from '@wix/document-manager-core'
import {veloCliService, type VeloCliService} from './utils/veloCliService'
import {exportPage, importPage} from './utils/importExport'
import type {CompRef, WMLSite} from '@wix/document-services-types'
import {getUpdatedPageIds, SnapshotSyncManager} from './utils/snapshots'
import {VIEW_MODES} from '../../constants/constants'
import {EVENTS} from './constants'
import {ReportableError} from '@wix/document-manager-utils'
import type {SnapshotExtApi} from '../snapshots'

const {getPointer} = pointerUtils

const createExtension = ({dsConfig}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({
        dal,
        pointers,
        extensionAPI,
        eventEmitter,
        coreConfig
    }: CreateExtArgs): LocalEditorExtensionAPI => {
        const snapshotSyncManager = new SnapshotSyncManager()

        let veloCliServiceInstance: VeloCliService

        const importSite = async ({pages}: WMLSite) => {
            for (const [pageId, data] of Object.entries(pages)) {
                const pagePointer = getPointer(pageId, VIEW_MODES.DESKTOP)
                await importPage(pagePointer as CompRef, data, extensionAPI, dal, pointers, coreConfig)
            }
        }

        const handleSyncFromFileSystem = async (wmlSite: WMLSite) => {
            await importSite(wmlSite)

            const snapshot = dal.commitTransaction()
            snapshotSyncManager.markSnapshotSyncedFromFS(snapshot)

            eventEmitter.emit(EVENTS.LOCAL_EDITOR.AFTER_SYNC_FROM_FS)
        }

        const getLocalWSPort = (): number => {
            const port: number | undefined = dsConfig.localEditorWSPort

            if (!port) {
                throw new ReportableError({
                    message: 'localPort was not provided for local editor',
                    errorType: 'missingLocalEditorPort'
                })
            }

            return port
        }

        const initialize = async (): Promise<void> => {
            if (!veloCliServiceInstance) {
                veloCliServiceInstance = veloCliService(getLocalWSPort())
            }

            const {wml} = await veloCliServiceInstance.connect()

            veloCliServiceInstance.syncChanges(handleSyncFromFileSystem)

            await importSite(wml)

            const snapshot = dal.commitTransaction()
            // after init state is synced
            snapshotSyncManager.markSnapshotSyncedFromFS(snapshot)
            snapshotSyncManager.markSnapshotSyncedFromEditor(snapshot)
        }

        const exportToCli = async () => {
            const pageIds = Array.from(getUpdatedPageIds(extensionAPI, snapshotSyncManager))

            if (!pageIds.length) {
                return
            }

            await veloCliServiceInstance.save({
                pages: Object.fromEntries(
                    pageIds.map(pageId => [
                        pageId,
                        exportPage(pointerUtils.getCompPointer(pageId, VIEW_MODES.DESKTOP), extensionAPI, dal)
                    ])
                )
            })

            const {snapshots} = extensionAPI as SnapshotExtApi
            snapshotSyncManager.markSnapshotSyncedFromEditor(snapshots.getCurrentSnapshot())
        }

        return {
            localEditor: {
                initialize,
                exportToCli
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {localEditor} = extensionAPI as LocalEditorExtensionAPI

        return {
            localEditor: {
                exportToCli: localEditor.exportToCli
            }
        }
    }

    return {
        name: 'localEditor',
        dependencies: new Set(['importExport']),
        createExtensionAPI,
        EVENTS,
        createPublicAPI
    }
}

export interface LocalEditorExtensionAPI extends ExtensionAPI {
    localEditor: {
        initialize(): Promise<void>
        exportToCli(): Promise<void>
    }
}

export {createExtension}
