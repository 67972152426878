import type {Pointer, PS} from '@wix/document-services-types'

import _ from 'lodash'
import constants from '../../constants/constants'
import {convertCompToNewSiteWidth} from './siteWidthConversion'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'

const {VIEW_MODES} = constants

function getSiteWidthForViewMode(ps: PS, viewMode: string) {
    const siteSettings = ps.dal.get(ps.pointers.data.getDataItemFromMaster('masterPage'))
    return viewMode === VIEW_MODES.MOBILE ? 320 : siteSettings.renderModifiers?.siteWidth ?? ps.siteAPI.getSiteWidth()
}

function getSiteWidth(ps: PS) {
    const viewMode = ps.siteAPI.isMobileView() ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP
    return getSiteWidthForViewMode(ps, viewMode)
}

function setSiteWidth(ps: PS, siteWidth: number) {
    const siteSettingsPointer = ps.pointers.data.getDataItemFromMaster('masterPage')
    const siteSettings = ps.dal.get(siteSettingsPointer)
    const currentWidth = getSiteWidth(ps)
    _.setWith(siteSettings, ['renderModifiers', 'siteWidth'], siteWidth, Object)
    ps.dal.set(siteSettingsPointer, siteSettings)

    const rootSegments = [
        ps.pointers.components.getHeader(VIEW_MODES.DESKTOP),
        ps.pointers.components.getFooter(VIEW_MODES.DESKTOP)
    ].filter(ptr => ps.dal.full.isExist(ptr))

    _.forEach(rootSegments, segmentPointer => {
        const compStructure = ps.dal.get(segmentPointer)
        compStructure.layout.width = siteWidth
        ps.dal.set(segmentPointer, compStructure)
    })

    const allPages = ps.pointers.page.getNonDeletedPagesPointers(true)
    const allDesktopPages = _.map(allPages, ps.pointers.components.getDesktopPointer)

    allDesktopPages.forEach(ref => {
        convertCompToNewSiteWidth(ps, ref, null, currentWidth, siteWidth)
    })

    const SITE_REGION_CONTAINER_TYPE = 'wysiwyg.viewer.components.SiteRegionContainer'
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.DESKTOP)
    const allSiteRegionContainers = componentDetectorAPI.getComponentByType(
        ps,
        SITE_REGION_CONTAINER_TYPE,
        masterPagePointer
    )

    allSiteRegionContainers.forEach(comp => convertCompToNewSiteWidth(ps, comp, null, currentWidth, siteWidth))
}

function migrateToCurrentSiteWidth(ps: PS, compPointer: Pointer, prevSiteWidth: number) {
    const currentSiteWidth = getSiteWidth(ps)
    convertCompToNewSiteWidth(ps, compPointer, null, prevSiteWidth, currentSiteWidth)
}

export default {
    convertCompToNewSiteWidth,
    getSiteWidth,
    getSiteWidthForViewMode,
    setSiteWidth,
    migrateToCurrentSiteWidth
}
