import responsiveWidgetConfig from './responsiveWidgetConfig'
import classicWidgetConfig from './classicWidgetConfig'
import type {ResponsiveConfig} from '@wix/document-services-types'

const createNewWidgetConfig = () => classicWidgetConfig
const createNewResponsiveWidgetConfig = (configOverrides?: Partial<ResponsiveConfig>) =>
    responsiveWidgetConfig(configOverrides)

export default {
    createNewWidgetConfig,
    createNewResponsiveWidgetConfig
}
