import type {CreateExtArgs, DmApis, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {DesignObject, Pointer, StyleRef} from '@wix/document-services-types'
import _ from 'lodash'
import type {PageExtensionAPI} from '../page'
import {fromStylableColor, regularColorRegExp, stylableColorsRegExp, toStylableColor} from '../../utils/colors'
import {deepFind} from '../../utils/object'

interface StylesApi extends ExtensionAPI {
    replaceColorInAllItems(
        oldColor: string,
        newColor: string,
        pageId: string,
        predicate?: (pointer: Pointer) => boolean
    ): void
    getColorsListInAllComponents(): string[]
}

export interface StylesExtensionApi extends ExtensionAPI {
    style: StylesApi
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({extensionAPI, pointers, dal}: CreateExtArgs): StylesExtensionApi => {
        const {page} = extensionAPI as PageExtensionAPI

        const getColorsListInAllComponents = () => {
            const usedColors = new Set<string>()

            const checkAndCollectColors = (item: StyleRef | DesignObject): boolean => {
                const colors: string[] = []
                _.cloneDeepWith(item, value => {
                    if (typeof value !== 'string') {
                        return
                    }
                    const matched = value.match(regularColorRegExp)
                    if (matched) colors.push(...matched)
                })

                colors?.forEach(colorId => usedColors.add(colorId))
                return colors.length > 0
            }

            const checkAndCollectStylableColors = (item: StyleRef) => {
                const stcss: string | undefined = item.style?.properties?.['$st-css']
                const matched = stcss?.match(stylableColorsRegExp)
                matched
                    ?.map(colorId => fromStylableColor(colorId))
                    .filter((colorId): colorId is string => !!colorId)
                    .forEach(colorId => usedColors.add(colorId))
                return matched
            }

            const pageIds = page.getAllPagesIds(true)

            pageIds.forEach(pageId => {
                pointers.data.getStyleItemsWithPredicate(
                    (styleObj: StyleRef) => styleObj.type === 'ComponentStyle' && checkAndCollectColors(styleObj),
                    pageId
                )
                pointers.data.getStyleItemsWithPredicate(checkAndCollectStylableColors, pageId)
                pointers.data.getDesignItemsWithPredicate(
                    (designObj: DesignObject) => checkAndCollectColors(designObj),
                    pageId
                )
            })

            return Array.from(usedColors)
        }

        const replaceColorInAllItems = (
            oldColor: string,
            newColor: string,
            pageId: string,
            predicate?: (pointer: Pointer) => boolean
        ) => {
            const regularRegExp = new RegExp(`${oldColor}(?!\\d)`, 'g')
            const oldStylableColor = toStylableColor(oldColor)
            const newStylableColor = toStylableColor(newColor)
            const stylableRegExp = oldStylableColor && new RegExp(`${oldStylableColor}`, 'g')

            const replaceRegularColors = (pointer: Pointer) => {
                const styleData = dal.get(pointer)
                const updatedStyleData = _.cloneDeepWith(styleData, value =>
                    typeof value === 'string' ? value.replace(regularRegExp, newColor) : undefined
                )
                dal.set(pointer, updatedStyleData)
            }

            const replacerStylableColors = (pointer: Pointer) => {
                const stcssPointer = pointers.getInnerPointer(pointer, ['style', 'properties', '$st-css'])
                const stcss: string = dal.get(stcssPointer)
                if (!stcss || !stylableRegExp || !newStylableColor) {
                    return
                }
                const updatedStCss = stcss.replace(stylableRegExp, newStylableColor)
                dal.set(stcssPointer, updatedStCss)
            }

            if (stylableRegExp) {
                const stylableItemWithPointers = pointers.data.getStyleItemsWithPredicate(
                    itemData => itemData.style?.properties?.['$st-css']?.match(stylableRegExp),
                    pageId
                )
                // replace in stylables
                stylableItemWithPointers
                    ?.filter(pointer => !predicate || predicate(pointer))
                    .forEach(pointer => replacerStylableColors(pointer))
            }

            const styleItemWithPointers = pointers.data.getStyleItemsWithPredicate(
                itemData =>
                    deepFind(itemData, value => (typeof value === 'string' ? value.match(regularRegExp) : undefined)),
                pageId
            )
            // replace in styleItems
            styleItemWithPointers
                .filter(pointer => !predicate || predicate(pointer))
                .forEach(pointer => replaceRegularColors(pointer))

            const designItemsPointers = pointers.data.getDesignItemsWithPredicate(
                itemData =>
                    deepFind(itemData, value => (typeof value === 'string' ? value.match(regularRegExp) : undefined)),
                pageId
            )
            // replace in desingItems
            designItemsPointers
                .filter(pointer => !predicate || predicate(pointer))
                .forEach(pointer => replaceRegularColors(pointer))
        }

        return {
            style: {
                replaceColorInAllItems,
                getColorsListInAllComponents
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {style} = extensionAPI as StylesExtensionApi

        return {
            style: {
                /**
                 * The function replaces all the occurrences of colorId in style objects and design items
                 * @param {oldColor} color that needs to be replaced
                 * @param {newColor} color that replaces an old color
                 */
                replaceColorInAllComponents: style.replaceColorInAllItems,
                /**
                 * The function returns all colors that are used in site components
                 */
                getColorsListInAllComponents: style.getColorsListInAllComponents
            }
        }
    }
    return {
        name: 'style',
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
