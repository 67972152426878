import {
    pointerUtils,
    type Extension,
    type InitializeExtArgs,
    type CreateExtArgs,
    type ExtensionAPI,
    DmApis,
    ValidateValue,
    DalValue
} from '@wix/document-manager-core'
import _ from 'lodash'
import {isPopup} from '../page/popupUtils'
import type {PageExtensionAPI} from '../page'
import {getTranslationLanguageCodes} from '../page/language'
import {
    getAllSyncableMenuPointers,
    updatePageItemFromTranslatedMenu,
    getAllBasicMenuItemsForMenu,
    menuHookChangePageData,
    addMenuItemWithPageLink,
    sanitizeHash,
    getParentItemId,
    validateParent,
    getIndexOfItemInParent,
    getMenuDataItemPointer
} from './menuUtils'
import * as menuUtilsForSyncItemsId from './menuUtilsForSyncItemsId'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {CUSTOM_MAIN_MENU, CUSTOM_MENUS, DATA_TYPES, MASTER_PAGE_ID} from '../../constants/constants'
import type {MenuData, MenuItem, Pointer} from '@wix/document-services-types'
import type {StructureExtensionAPI} from '../structure'
import type {SchemaExtensionAPI} from '../schema/schema'
import type {MultilingualTranslationsAPI} from '../multilingual'
import type {RelationshipsAPI} from '../relationships'
import {ReportableError} from '@wix/document-manager-utils'

export interface MenuAPI {
    removeMenu(menuId: string): void
    createMenu(newMenuId: string | undefined, menuData: Record<string, any>, languageCode?: string): string
    syncMenusOnAddPage(pageId: string, shouldCreatePageMenuItem?: boolean): void
    getAll(languageCode?: string, useOriginalLanguageFallback?: boolean): MenuData[]
    getById(menuId: string, languageCode?: string, useOriginalLanguageFallback?: boolean): any
    updateMenu(item: Record<string, any>, menuId: string, languageCode?: string): void
    moveItem(menuId: string, itemId: string, newParentId: string | null, newIndex: number): void
    fixItemsWithDifferentIdAndSamePageId(menuId: string): void
}
export type MenuExtensionAPI = ExtensionAPI & {
    menus: MenuAPI
}

const createExtension = (): Extension => {
    function mutateItems(mutators: Function[], item: MenuItem) {
        _.forEach(mutators, mutator => {
            mutator(item)
        })
        if (item.items) {
            _.forEach(item.items, subItem => {
                mutateItems(mutators, subItem)
            })
        }
    }

    const withoutId = _.partialRight(_.unset, 'id')
    const withoutLinkId = _.partialRight(_.unset, ['link', 'id'])
    const asBasicMenuItem = _.partialRight(_.set, 'type', 'BasicMenuItem')

    function normalizeMenuItems(menuData: any) {
        _.forEach(menuData.items, item => {
            mutateItems([withoutId, withoutLinkId, asBasicMenuItem], item)
        })
    }

    const updateMenuDataOnPageChanged = (
        extArgs: InitializeExtArgs,
        pageId: string,
        changedData: Record<string, any>,
        useLanguage: string,
        applyChangeToAllLanguages = false
    ) => {
        const {pointers, extensionAPI} = extArgs
        const {page} = extensionAPI as PageExtensionAPI
        if (!isPopup(extArgs, pageId)) {
            const mobileHidePage = page.getMobileHidePage(pageId, {languageCode: useLanguage})
            const translationLanguages = getTranslationLanguageCodes(extArgs)
            const allSyncableMenus = getAllSyncableMenuPointers(extArgs)
            if (applyChangeToAllLanguages) {
                _.flatMap(translationLanguages, lang =>
                    updatePageItemFromTranslatedMenu(
                        extArgs,
                        allSyncableMenus,
                        lang,
                        pageId,
                        useLanguage,
                        changedData,
                        mobileHidePage
                    )
                )
            }
            const allBasicMenuItems = _.flatMap(allSyncableMenus, menuPointer =>
                getAllBasicMenuItemsForMenu(extArgs, menuPointer, useLanguage)
            )
            _.forEach(allBasicMenuItems, basicMenuItemData => {
                const basicMenuItemPointer = pointers.data.getDataItemFromMaster(basicMenuItemData.id)
                if (basicMenuItemData.link) {
                    const linkData = basicMenuItemData.link
                    if (linkData.type === 'PageLink' && linkData.pageId === `#${pageId}`) {
                        menuHookChangePageData(extArgs, basicMenuItemPointer, changedData, useLanguage, mobileHidePage)
                    }
                }
            })
        }
    }

    const addPageMenuItem = (
        extArgs: InitializeExtArgs,
        pageId: string,
        label: string,
        hideItem: boolean,
        hideItemMobile: boolean
    ) => {
        const {pointers, dal} = extArgs
        const translationLanguages = getTranslationLanguageCodes(extArgs)
        _.forEach(getAllSyncableMenuPointers(extArgs), function (menuPointer) {
            const menuId = menuPointer.id
            const menuItemId = addMenuItemWithPageLink(extArgs, menuId, pageId, label, hideItem, hideItemMobile)
            const menuItem = translationLanguages.length
                ? dal.get(pointers.data.getDataItemFromMaster(menuItemId))
                : null
            _.forEach(translationLanguages, lang => {
                const translatedMenuPointer = pointers.multilingualTranslations.translationDataItem(
                    MASTER_PAGE_ID,
                    lang,
                    menuId
                )
                if (dal.has(translatedMenuPointer)) {
                    const existingDataItem = deepClone(dal.get(translatedMenuPointer))
                    const existingItems = _.get(existingDataItem, ['items'])
                    existingItems.push(`#${menuItemId}`)
                    dal.set(translatedMenuPointer, existingDataItem)
                    dal.set(
                        pointers.multilingualTranslations.translationDataItem(MASTER_PAGE_ID, lang, menuItemId),
                        menuItem
                    )
                }
            })
        })
    }

    const menuHookAddPage = (extArgs: InitializeExtArgs, pageId: string) => {
        const {pointers, dal} = extArgs
        const pageDataPointer = pointers.data.getDataItemFromMaster(pageId)
        const pageData = dal.get(pageDataPointer)

        addPageMenuItem(extArgs, pageData.id, pageData.title, pageData.hidePage, pageData.mobileHidePage)
        return {success: true}
    }

    const syncMenusOnAddPage = (extArgs: InitializeExtArgs, pageId: string, shouldCreatePageMenuItem = true) => {
        if (!isPopup(extArgs, pageId) && shouldCreatePageMenuItem) {
            menuHookAddPage(extArgs, pageId)
        }
    }
    const getAllMenus = ({extensionAPI}: CreateExtArgs) => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const customMenusData = dataModel.getItem(CUSTOM_MENUS, DATA_TYPES.data, MASTER_PAGE_ID)
        return customMenusData.menus
    }

    const getMenuById = (
        {extensionAPI}: CreateExtArgs,
        menuId: string,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ) => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        return dataModel.getItem(menuId, DATA_TYPES.data, MASTER_PAGE_ID, languageCode, useOriginalLanguageFallback)
    }
    const validateMenuExists = ({pointers, dal}: CreateExtArgs, menuId: string) => {
        const menuDataPointer = pointers.data.getDataItemFromMaster(menuId)

        if (!menuId || !dal.has(menuDataPointer)) {
            throw new Error(`Menu with id ${menuId} does not exist.`)
        }
    }

    const isConnectedToMenuData = (extArgs: CreateExtArgs, menuId: string) => {
        const {dataModel} = extArgs.extensionAPI as DataModelExtensionAPI
        return (comp: Pointer) => {
            const compData = dataModel.components.getItem(comp, DATA_TYPES.data)
            return compData?.menuRef === `#${menuId}`
        }
    }

    const hasCompsConnectedToMenu = (extArgs: CreateExtArgs, menuId: string) => {
        const {components} = extArgs.extensionAPI as StructureExtensionAPI
        const allComponentPointers = components.getAllComponentPointers()
        return _.some(allComponentPointers, isConnectedToMenuData(extArgs, menuId))
    }

    const validateNoCompsConnectedToMenu = (extArgs: CreateExtArgs, menuId: string) => {
        if (hasCompsConnectedToMenu(extArgs, menuId)) {
            throw new Error(
                `Cannot remove menu with id ${menuId}, there are components connected to this menu. Please connect the other components to a differnt menu first.`
            )
        }
    }

    const initialize = async (extArgs: InitializeExtArgs) => {
        const {eventEmitter, EVENTS} = extArgs
        eventEmitter.on(
            EVENTS.PAGE.DATA_UPDATE,
            (
                pageId: string,
                data: Record<string, any>,
                languageCodeToUse: string,
                applyChangeToAllLanguages: boolean
            ) => {
                updateMenuDataOnPageChanged(extArgs, pageId, data, languageCodeToUse, applyChangeToAllLanguages)
            }
        )
        eventEmitter.on(EVENTS.PAGE.PAGE_DATA_ADDED, (pagePointer: Pointer, shouldCreatePageMenuItem) => {
            syncMenusOnAddPage(extArgs, pagePointer.id, shouldCreatePageMenuItem)
        })
    }

    const moveItem = (
        extArgs: CreateExtArgs,
        menuId: string,
        itemId: string,
        newParentId: string | null,
        newIndex: number
    ) => {
        const {dal} = extArgs
        itemId = sanitizeHash(itemId)
        const menu = getMenuById(extArgs, menuId)
        const oldParentId = getParentItemId(menu, itemId) || menuId
        newParentId = newParentId || menuId
        validateParent(extArgs, newParentId, itemId, menuId)

        const oldIndex = getIndexOfItemInParent(extArgs, oldParentId, itemId)
        const oldParentPointer = getMenuDataItemPointer(extArgs, oldParentId)
        const oldParent = deepClone(dal.get(oldParentPointer))
        oldParent.items.splice(oldIndex, 1)
        dal.set(oldParentPointer, oldParent)

        const newParentPointer = getMenuDataItemPointer(extArgs, newParentId)
        const newParent = deepClone(dal.get(newParentPointer))
        newParent.items = newParent.items || []
        newParent.items.splice(newIndex, 0, `#${itemId}`)
        dal.set(newParentPointer, newParent)
    }
    const createExtensionAPI = (extArgs: CreateExtArgs): MenuExtensionAPI => {
        const fixItemsWithDifferentIdAndSamePageId = (menuId: string) =>
            menuUtilsForSyncItemsId.fixItemsWithDifferentIdAndSamePageId(
                extArgs.pointers,
                extArgs.dal,
                extArgs.extensionAPI,
                menuId,
                extArgs.coreConfig.logger
            )

        const updateItem = (item: Record<string, any>, menuId: string, languageCode?: string) => {
            validateMenuExists(extArgs, menuId)
            const {dataModel} = extArgs.extensionAPI as DataModelExtensionAPI
            dataModel.addItem(item, DATA_TYPES.data, MASTER_PAGE_ID, menuId, languageCode)
            if (menuId === CUSTOM_MAIN_MENU) {
                fixItemsWithDifferentIdAndSamePageId(menuId)
            }
        }

        return {
            menus: {
                removeMenu: (menuId: string) => {
                    const {dataModel} = extArgs.extensionAPI as DataModelExtensionAPI
                    if (menuId === CUSTOM_MAIN_MENU) {
                        throw new Error(`You cannot delete the ${CUSTOM_MAIN_MENU}.`)
                    }
                    validateMenuExists(extArgs, menuId)
                    validateNoCompsConnectedToMenu(extArgs, menuId)

                    const customMenusArrayPointer = pointerUtils.getInnerPointer(
                        extArgs.pointers.data.getDataItem('CUSTOM_MENUS', 'masterPage'),
                        'menus'
                    )
                    const customMenus = _.reject(
                        _.cloneDeep(extArgs.dal.get(customMenusArrayPointer)),
                        v => v === `#${menuId}`
                    )
                    extArgs.dal.set(customMenusArrayPointer, customMenus)
                    dataModel.removeItemRecursively(extArgs.pointers.data.getDataItemFromMaster(menuId))
                },
                createMenu(menuItemId: string | undefined, menuData: Record<string, any>, languageCode?: string) {
                    const {dataModel} = extArgs.extensionAPI as DataModelExtensionAPI
                    const {schemaAPI} = extArgs.extensionAPI as SchemaExtensionAPI
                    const newMenuId = menuItemId ?? dataModel.generateItemIdWithPrefix('dataItem')

                    if (getMenuById(extArgs, newMenuId, languageCode)) {
                        throw new ReportableError({
                            errorType: 'MENU_ALREADY_EXIST',
                            message: `Menu with id ${newMenuId} already exist.`,
                            extras: {
                                menuId: newMenuId
                            }
                        })
                    }

                    extArgs.eventEmitter.emit(extArgs.EVENTS.CLIENT_SPEC_MAP.MIGRATE_APPID_TO_APPDEF, menuData)

                    const dataItem = schemaAPI.createItemAccordingToSchema('CustomMenu', DATA_TYPES.data)
                    menuData = _.assign(dataItem, menuData, {id: newMenuId})
                    normalizeMenuItems(menuData)

                    dataModel.addItem(menuData, DATA_TYPES.data, MASTER_PAGE_ID, newMenuId, languageCode)
                    const customMenusArrayPointer = pointerUtils.getInnerPointer(
                        extArgs.pointers.data.getDataItem('CUSTOM_MENUS', 'masterPage'),
                        'menus'
                    )
                    const customMenusArray = extArgs.dal.get(customMenusArrayPointer)
                    extArgs.dal.set(customMenusArrayPointer, _.uniq([...customMenusArray, `#${newMenuId}`]))
                    return newMenuId
                },
                syncMenusOnAddPage: (pageId, shouldCreatePageMenuItem) =>
                    syncMenusOnAddPage(extArgs, pageId, shouldCreatePageMenuItem),
                getAll: () => getAllMenus(extArgs),
                getById: (menuId: string, languageCode?: string, useOriginalLanguageFallback?: boolean) =>
                    getMenuById(extArgs, menuId, languageCode, useOriginalLanguageFallback),
                moveItem: (menuId: string, itemId: string, newParentId: string, newIndex: number) =>
                    moveItem(extArgs, menuId, itemId, newParentId, newIndex),
                updateMenu: updateItem,
                fixItemsWithDifferentIdAndSamePageId
            }
        }
    }

    const createValidator = (apis: DmApis): Record<string, ValidateValue> => {
        return {
            outOfSyncMenusStructure: (pointer: Pointer, value: DalValue) => {
                if (value && pointer.type === DATA_TYPES.data) {
                    const multilingualAPI = apis.extensionAPI.multilingualTranslations as MultilingualTranslationsAPI
                    const relationshipAPI = apis.extensionAPI as RelationshipsAPI
                    const reference = relationshipAPI.relationships.getOwningReferencesToPointer(pointer)

                    const isMenuStructureValidation =
                        apis.coreConfig.experimentInstance.isOpen('dm_menuStructureValidation')
                    const isReferOfCustomMainMenu = reference.find(({id}) => id === 'CUSTOM_MAIN_MENU')
                    if (value.id === 'CUSTOM_MAIN_MENU' || isReferOfCustomMainMenu) {
                        const getTranslationsPointers = multilingualAPI.getTranslationsById(value?.id)
                        for (const pl of getTranslationsPointers) {
                            const translatedValue = apis.dal.get(pl)
                            if (!_.isEqual(value.items, translatedValue.items)) {
                                return [
                                    {
                                        shouldFail: isMenuStructureValidation,
                                        type: 'menuStructureNotSyncWithTheTranslate',
                                        message: `menu structure not sync with the translate - ${pl.id}`,
                                        extras: {
                                            translateValue: translatedValue
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    }

    return {
        name: 'menu',
        initialize,
        createExtensionAPI,
        createValidator,
        dependencies: new Set(['structure', 'relationships', 'data', 'page'])
    }
}

export {createExtension}
