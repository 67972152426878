export const DEAD_COMP_TYPE = 'to.be.removed.by.MobileMerge'

export const SPLITTABLE_QUERIES = ['propertyQuery', 'designQuery']

export const NON_SPLITTABLE_QUERIES_WITHOUT_NAMESPACES = ['nickname']

export const INTERACTIONS = {
    RUN: 'mobileAlgo-run',
    ANALYZE: 'mobileAlgo-analyze'
}
