import type {
    ComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry
} from '../../types'
import type {StyleEntryItem} from '../../conversionDal/types'
import {getStylePointer} from '../../conversionDal/pointerUtils'

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const calculateNewFontSize = (fontSize: number) => {
        if (fontSize < 14) {
            return fontSize
        }

        if (fontSize < 17) {
            return 14
        }

        if (fontSize < 20) {
            return 16
        }

        if (fontSize < 23) {
            return 18
        }

        if (fontSize < 31) {
            return 20
        }

        if (fontSize < 39) {
            return 22
        }

        if (fontSize < 47) {
            return 24
        }

        return 26
    }

    const textScale = ({componentPointer, conversionDal}: ComponentStageData) => {
        const compStylePointer = getStylePointer(componentPointer)
        const baseStyleSettings = conversionDal.get(compStylePointer)
        const styleOverrides: StyleEntryItem = {}

        if (baseStyleSettings?.fontSize) {
            styleOverrides.fontSize = calculateNewFontSize(baseStyleSettings?.fontSize)
        }

        if (baseStyleSettings?.collapsibleTextButtonFontSize) {
            styleOverrides.collapsibleTextButtonFontSize = calculateNewFontSize(
                baseStyleSettings?.collapsibleTextButtonFontSize
            )
        }

        if (Object.keys(styleOverrides).length) {
            conversionDal.set(compStylePointer, styleOverrides)
        }
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.SCALE, textScale)
    }

    const name = 'textScale'
    return {register, name, dependencies: []}
}
