import {tpaWidgetDefaultResponsiveLayout} from './components/tpaWidgetDefaultResponsiveLayout'
import {tpaSectionDefaultResponsiveLayout} from './components/tpaSectionDefaultResponsiveLayout'

import {responsiveSectionDefaultResponsiveLayout} from './components/responsiveSectionDefaultResponsiveLayout'
import {refComponentDefaultResponsiveLayout} from './components/refComponentDefaultResponsiveLayout'
import {pageDefaultResponsiveLayout} from './components/pageDefaultResponsiveLayout'
import {customElementDefaultResponsiveLayout} from './components/customElementDefaultResponsiveLayout'

export const COMPONENT_TYPE_TO_RESPONSIVE_LAYOUT_MAP = {
    'wysiwyg.viewer.components.tpapps.TPAWidget': tpaWidgetDefaultResponsiveLayout,
    'responsive.components.Section': responsiveSectionDefaultResponsiveLayout,
    'wysiwyg.viewer.components.RefComponent': refComponentDefaultResponsiveLayout,
    'mobile.core.components.Page': pageDefaultResponsiveLayout,
    'wysiwyg.viewer.components.tpapps.TPASection': tpaSectionDefaultResponsiveLayout,
    'wysiwyg.viewer.components.tpapps.TPAMultiSection': tpaSectionDefaultResponsiveLayout,
    'wixui.CustomElementComponent': customElementDefaultResponsiveLayout
}
