import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {MobileHints, SerializedCompStructure} from '@wix/document-services-types'
import {createValidationError} from './validation'
import type {SchemaExtensionAPI} from '../../schema/schema'
import * as santaCoreUtils from '@wix/santa-core-utils'
import {DATA_TYPES} from '../../../constants/constants'

const FORBIDDEN_COMPONENT_TYPE = ['wysiwyg.viewer.components.HoverBox']

const validateType = (createExtArgs: CreateExtArgs, componentStructure: SerializedCompStructure) => {
    if (!_.isObject(componentStructure)) {
        throw createValidationError('component structure is not an object')
    }

    const {componentType} = componentStructure
    if (!componentType) {
        throw createValidationError('componentType is missing')
    }

    const {schemaAPI} = createExtArgs.extensionAPI as SchemaExtensionAPI

    if (!schemaAPI.getDefinition(componentType)) {
        throw createValidationError(`componentType ${componentType} has no schema`)
    }

    if (
        createExtArgs.coreConfig.experimentInstance.isOpen('dm_oldHoverBoxDeprecationFixer') &&
        FORBIDDEN_COMPONENT_TYPE.includes(componentType)
    ) {
        throw createValidationError(`componentType ${componentType} cannot be added`)
    }
}

const validateStyle = (createExtArgs: CreateExtArgs, componentStructure: SerializedCompStructure) => {
    const {schemaAPI} = createExtArgs.extensionAPI as SchemaExtensionAPI

    const {componentType, skin, style} = componentStructure
    const compDefinition = schemaAPI.getDefinition(componentType)
    const compNeedsStyling = !_.isEmpty(compDefinition.styles) || !_.isEmpty(compDefinition.skins)
    if (compNeedsStyling && !skin && !style) {
        throw createValidationError('missing style/skin in component structure', {componentType})
    }
}

const validateRepeatedData = (createExtArgs: CreateExtArgs, compType: string, compData: any) => {
    validateDataType(createExtArgs, compType, compData.original)
    _.forEach(compData.overrides, _.partial(validateDataType, createExtArgs, compType))
}

function validateDataType(createExtArgs: CreateExtArgs, compType: string, compData: any) {
    const {schemaAPI} = createExtArgs.extensionAPI as SchemaExtensionAPI

    const compDefinitions = schemaAPI.getDefinition(compType)
    // @ts-expect-error
    if (_.isObject(compData) && !compData.type) {
        throw createValidationError('component data is missing a type', {compData})
    }

    const dataType = _.get(compData, ['type'], compData || '')
    const dataTypes = compDefinitions.dataTypes || [''] //support no data by default

    if (dataType === 'RepeatedData') {
        validateRepeatedData(createExtArgs, compType, compData)
    } else if (!_.includes(dataTypes, dataType)) {
        throw createValidationError(`data type ${dataType} is not allowed for componentType ${compType}`, {compData})
    }
}

const setIfUndefined = (obj: any, path: string | string[], value: any): boolean => {
    if (_.has(obj, path)) {
        return false
    }
    _.setWith(obj, path, value, Object)
    return true
}

const validateWithFakeId = (obj: any, validate: Function) => {
    const ID_PATH = 'id'
    const FAKE_ID = '<FAKE_ID_FOR_NEW_COMP_VALIDATIONS>'
    const idWasFaked = setIfUndefined(obj, ID_PATH, FAKE_ID)
    validate(obj)

    if (idWasFaked) {
        _.unset(obj, ID_PATH)
    }
}

const validateProperties = (createExtArgs: CreateExtArgs, componentType: string, compProps?: any) => {
    const {schemaAPI} = createExtArgs.extensionAPI as SchemaExtensionAPI

    const compDefinition = schemaAPI.getDefinition(componentType)
    if (!compDefinition) {
        throw new Error(`${componentType} has no component definition.`)
    }
    if (!compDefinition.propertyTypes && !compDefinition.propertyType && !compProps) {
        return
    }

    const types = compDefinition.propertyTypes
        ? compDefinition.propertyTypes
        : [compDefinition.propertyType || santaCoreUtils.constants.BASE_PROPS_SCHEMA_TYPE]
    if (_.includes(types, '') || (_.isString(compProps) && _.includes(types, compProps))) {
        return
    }

    const msg = `${componentType} must get a compProps of one of the types [${types}]`
    if (!compProps) {
        throw new Error(msg)
    }

    if (!_.includes(types, compProps.type)) {
        throw new Error(`${msg} but got ${compProps.type}`)
    }
    schemaAPI.addDefaultsAndValidate(compProps.type, compProps, DATA_TYPES.prop)
}

const validatePropsType = (createExtArgs: CreateExtArgs, componentStructure: SerializedCompStructure) => {
    validateWithFakeId(componentStructure.props, (props: any) =>
        validateProperties(createExtArgs, componentStructure.componentType, props)
    )
}

const validateSlotsType = (createExtArgs: CreateExtArgs, componentStructure: SerializedCompStructure) => {
    if (componentStructure.slots) {
        const {schemaAPI} = createExtArgs.extensionAPI as SchemaExtensionAPI

        const compType = componentStructure.componentType
        const compDefinition = schemaAPI.getDefinition(compType)
        const targetSlotsType = componentStructure.slots.type
        if (compDefinition.slotsDataType !== targetSlotsType) {
            throw createValidationError(`slots type ${targetSlotsType} is not allowed for componentType ${compType}`, {
                compType,
                targetSlotsType
            })
        }
    }
}

const validateMobileHintsBySchema = (createExtArgs: CreateExtArgs, mobileHints: MobileHints) => {
    const {schemaAPI} = createExtArgs.extensionAPI as SchemaExtensionAPI

    if (mobileHints) {
        schemaAPI.addDefaultsAndValidate('MobileHints', mobileHints, DATA_TYPES.mobileHints)
    }
}

const validMobileHints = (createExtArgs: CreateExtArgs, componentStructure: SerializedCompStructure) => {
    validateWithFakeId(componentStructure.mobileHints, (mobileHints: MobileHints) => {
        validateMobileHintsBySchema(createExtArgs, mobileHints)
    })
}

export const validateStructure = (createExtArgs: CreateExtArgs, componentStructure: SerializedCompStructure) => {
    validateType(createExtArgs, componentStructure)
    validateStyle(createExtArgs, componentStructure)
    validateDataType(createExtArgs, componentStructure.componentType, componentStructure.data)
    validatePropsType(createExtArgs, componentStructure)
    validateSlotsType(createExtArgs, componentStructure)
    validMobileHints(createExtArgs, componentStructure)
}
