import type {
    WixComponentManifest,
    Style as ManifestStyle,
    EditingBehaviors as ManifestEditingBehaviors,
    Preset as ManifestPreset
} from '@wix/component-protocol/schema'

export namespace Builder {
    export type Manifest = WixComponentManifest
    export type Style = ManifestStyle
    export type StyleType = NonNullable<ManifestStyle>[string]['shapeOf']
    export type EditingBehaviors = ManifestEditingBehaviors
    export type Preset = ManifestPreset
}

interface BuilderComponentsObject {
    register(identifier: string, manifest: Builder.Manifest): void
}

export interface BuilderObject {
    components: BuilderComponentsObject
}
