import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef} from '@wix/document-services-types'
import {COMP_TYPES} from '../../../constants/constants'

export const refComponentMetaData = {
    shouldAutoSetNickname: false,
    isRepeatable: (
        {coreConfig, dal, pointers}: CreateExtArgs,
        componentPointer: CompRef | undefined,
        repeaterContainer?: CompRef
    ) => {
        if (coreConfig.experimentInstance.isOpen('dm_createWidgetInRepeater')) {
            return true
        }

        if (coreConfig.experimentInstance.isOpen('dm_dynamicRepeater') && repeaterContainer) {
            const repeaterType = dal.get(pointers.getInnerPointer(repeaterContainer, 'componentType'))

            return repeaterType === COMP_TYPES.DYNAMIC_REPEATER
        }

        return false
    },
    isPublicContainer: false,
    canContainByStructure: true
}
