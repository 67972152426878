const {createDataItem, createMobileVariantRelation, createRefArray} = require('./utils')
const constants = require('../../helpers/constants')
const _ = require('lodash')
const {NAMESPACE_MAPPING} = require('@wix/document-services-json-schemas').namespaceMapping
const {DESKTOP, MOBILE} = constants

const LAYOUT_DATA_NAMESPACE = NAMESPACE_MAPPING.layout
const LAYOUT_NAMESPACE = 'layout'

const createLayoutRefArray = createRefArray(LAYOUT_NAMESPACE, LAYOUT_DATA_NAMESPACE)
const createLayoutDataItem = createDataItem(LAYOUT_NAMESPACE, LAYOUT_DATA_NAMESPACE)
const createLayoutMobileRelation = createMobileVariantRelation(LAYOUT_NAMESPACE, LAYOUT_DATA_NAMESPACE)

const addLayoutData = pageJson => {
    pageJson.data[LAYOUT_DATA_NAMESPACE] = pageJson.data[LAYOUT_DATA_NAMESPACE] ?? {}
}

const addLayoutToComponent = ({allComponents}, componentId, layoutId) => {
    const desktopComponent = allComponents[DESKTOP][componentId]
    if (desktopComponent) {
        desktopComponent.layoutQuery = `#${layoutId}`
    }
    const mobileComponent = allComponents[MOBILE][componentId]
    if (mobileComponent) {
        mobileComponent.layoutQuery = `#${layoutId}`
    }
}

const writeMeshLayout = (conversionResult, componentId, conversionContext) => {
    if (!conversionResult.default.layout && !conversionResult.mobile.layout) {
        return
    }

    const refArray = createLayoutRefArray(conversionContext)
    if (conversionResult.default.layout) {
        const defaultLayout = createLayoutDataItem(conversionContext, conversionResult.default.layout)
        refArray.values.push(`#${defaultLayout.id}`)
    }

    if (conversionResult.mobile.layout) {
        const mobileLayout = createLayoutDataItem(conversionContext, conversionResult.mobile.layout)
        const mobileRelation = createLayoutMobileRelation(conversionContext, {
            to: `#${mobileLayout.id}`,
            from: `#${componentId}`
        })
        refArray.values.push(`#${mobileRelation.id}`)
    }

    addLayoutToComponent(conversionContext, componentId, refArray.id)
}

const createZeroLayout = () => ({x: 0, y: 0, height: 0, width: 0})

const convertPageCompsToZeroLayout = ({allComponents}) => {
    _.forEach(allComponents, componentsInViewMode => {
        _.forEach(componentsInViewMode, component => {
            component.layout = createZeroLayout()
        })
    })
}

module.exports = {
    addLayoutData,
    writeMeshLayout,
    convertPageCompsToZeroLayout
}
