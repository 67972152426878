import type {CompRef, SerializedCompStructure} from '@wix/document-services-types'
import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {WixCodeExtensionAPI} from '../../../wixCode'
import type {ComponentCodeExtensionAPI} from '../../../componentCode'
import type {NicknamesExtensionAPI} from '../../../nicknames'

export const updateConnectionsIfNeeded = (
    createExtArgs: CreateExtArgs,
    compPointer: CompRef,
    containerPointer: CompRef,
    compDefinition: SerializedCompStructure
) => {
    const {pointers, extensionAPI} = createExtArgs
    const {wixCode} = extensionAPI as WixCodeExtensionAPI
    const {componentCode} = extensionAPI as ComponentCodeExtensionAPI
    const {nicknames} = extensionAPI as NicknamesExtensionAPI
    if (compDefinition.type === 'Page' || !compDefinition.connections) return

    const connectionsItems = compDefinition.connections.items
    const pagePointer = pointers.structure.getPageOfComponent(containerPointer)

    if (wixCode.isProvisioned()) {
        componentCode.updateConnectionsIfNeeded(compPointer, containerPointer, compDefinition)
    } else {
        const wixCodeConnectionItem = _.find(connectionsItems, {type: 'WixCodeConnectionItem'})
        if (!wixCodeConnectionItem) return

        if (nicknames.hasComponentWithThatNickname(pagePointer, wixCodeConnectionItem.role, compPointer)) {
            _.pull(connectionsItems, wixCodeConnectionItem)
            if (_.isEmpty(connectionsItems)) {
                delete compDefinition.connections
            }
        }
    }
}
export const updateNicknameContextByNewContainer = (
    createExtArgs: CreateExtArgs,
    compPointer: CompRef,
    componentDefinition: SerializedCompStructure,
    newContainerPointer: CompRef
) => {
    const {extensionAPI} = createExtArgs
    const {wixCode} = extensionAPI as WixCodeExtensionAPI
    const {componentCode} = extensionAPI as ComponentCodeExtensionAPI

    if (wixCode.isProvisioned() && componentDefinition.type !== 'Page') {
        componentCode.updateNicknameContextByNewContainer(
            compPointer as CompRef,
            componentDefinition,
            newContainerPointer
        )
    }
}
