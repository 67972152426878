import _ from 'lodash'

const WARNING = 'warning'
const ERROR = 'error'

const getDeprecatedComponents = () => {
    return {
        'wysiwyg.viewer.components.StripContainer': {
            deprecated: WARNING,
            message: 'This component will be deprecated'
        },
        'wysiwyg.viewer.components.ClipArt': {
            deprecated: WARNING,
            message: 'This component will be deprecated'
        }
    }
}

export const isComponentDeprecated = (compType: string) => {
    return _.get(getDeprecatedComponents(), [compType, 'deprecated']) === ERROR
}

export const shouldWarnForDeprecation = (compType: string) => {
    return _.get(getDeprecatedComponents(), [compType, 'deprecated']) === WARNING
}

export const getDeprecationMessage = (compType: string) => {
    return `${compType}|${_.get(getDeprecatedComponents(), [compType, 'message'])}`
}
