import type {PublicMethodUtils} from '@wix/document-services-types'
import {builderComponents} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            builder: {
                components: {
                    register: publicMethodUtils.defineAction(builderComponents.registerBuilderComponent)
                }
            }
        }
    }
}
