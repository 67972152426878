const _ = require('lodash')
const {markPropertyToRemove, isPropertyToRemove, removePropertyToRemovePrefix} = require('./utils')

const sides = [
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-left-radius',
    'border-bottom-right-radius'
]

const borderRadiusPropertiesToRemove = ['border-radius', ...sides.map(markPropertyToRemove)]

const borderSideCssMapping = {
    topLeft: 'top-left',
    topRight: 'top-right',
    bottomLeft: 'bottom-left',
    bottomRight: 'bottom-right'
}

function borderRadiusToProperty(borderRadius, withBorderRadiusAsOneParam) {
    const entries = Object.entries(borderRadius)
    if (entries.length && withBorderRadiusAsOneParam) {
        return [
            {
                prop: 'border-radius',
                cssProp: 'border-radius',
                value: `${borderRadius.topLeft} ${borderRadius.topRight} ${borderRadius.bottomRight} ${borderRadius.bottomLeft}`
            }
        ]
    }

    const borderRadiusProperties = []
    entries.forEach(([side, value]) => {
        const sideCss = borderSideCssMapping[side]
        borderRadiusProperties.push({
            prop: `border-${sideCss}-radius`,
            cssProp: `border-${sideCss}-radius`,
            value
        })
    })

    return borderRadiusProperties
}

function getBorderRadiusValues(borderRadius) {
    const borderRadiusValues = borderRadius.value.split(' ')
    const borderRadiusLength = borderRadiusValues.length

    if (borderRadiusLength === 1) {
        const [borderRadiusValue] = borderRadiusValues
        return {
            topLeft: borderRadiusValue,
            topRight: borderRadiusValue,
            bottomLeft: borderRadiusValue,
            bottomRight: borderRadiusValue
        }
    }

    if (borderRadiusLength === 2) {
        const [borderRadiusTopLeftAndBottomRight, borderTopRightAndBottomLeft] = borderRadiusValues
        return {
            topLeft: borderRadiusTopLeftAndBottomRight,
            topRight: borderTopRightAndBottomLeft,
            bottomRight: borderRadiusTopLeftAndBottomRight,
            bottomLeft: borderTopRightAndBottomLeft
        }
    }

    if (borderRadiusLength === 3) {
        const [borderRadiusTopLeft, borderRadiusLeftAndRight, borderRadiusBottomRight] = borderRadiusValues
        return {
            topLeft: borderRadiusTopLeft,
            topRight: borderRadiusLeftAndRight,
            bottomRight: borderRadiusBottomRight,
            bottomLeft: borderRadiusLeftAndRight
        }
    }

    const [borderRadiusTopLeft, borderRadiusTopRight, borderRadiusBottomLeft, borderRadiusBottomRight] =
        borderRadiusValues

    return {
        topLeft: borderRadiusTopLeft,
        topRight: borderRadiusTopRight,
        bottomLeft: borderRadiusBottomLeft,
        bottomRight: borderRadiusBottomRight
    }
}

function handleBorderRadiusProperty(property, borderRadiusSides) {
    if (property.cssProp === 'border-radius') {
        const {topLeft, topRight, bottomLeft, bottomRight} = getBorderRadiusValues(property)
        borderRadiusSides.topLeft = topLeft
        borderRadiusSides.topRight = topRight
        borderRadiusSides.bottomLeft = bottomLeft
        borderRadiusSides.bottomRight = bottomRight
    }

    if (sides.includes(removePropertyToRemovePrefix(property.cssProp))) {
        const propParts = property.cssProp.split('-')
        const side = _.camelCase(`${propParts[1]}-${propParts[2]}`)
        borderRadiusSides[side] = property.value

        if (!isPropertyToRemove(property.cssProp)) {
            property.cssProp = markPropertyToRemove(property.cssProp)
        }
    }
}

function getUpdatedProperties(properties, borderRadiusSides, withBorderRadiusAsOneParam) {
    return [
        ...properties.filter(property => !borderRadiusPropertiesToRemove.includes(property.cssProp)),
        ...borderRadiusToProperty(borderRadiusSides, withBorderRadiusAsOneParam)
    ]
}

/**
 * @param {boolean} withBorderRadiusAsOneParam
 * @returns {function(Array<import('../index').ParsedStyleItem>): Array<import('../index').ParsedStyleItem>}
 */
function migrateBorderRadiusFactory(withBorderRadiusAsOneParam) {
    return function (styleItems) {
        return styleItems.map(styleItem => {
            const {parsedStyle} = styleItem
            const updatedParsedStyle = Object.entries(parsedStyle).reduce(
                (acc, [selector, {properties, mobileProperties}]) => {
                    const borderRadiusSides = {}
                    const mobileBorderRadiusSides = {}

                    properties.forEach(property => {
                        handleBorderRadiusProperty(property, borderRadiusSides)
                    })

                    mobileProperties.forEach(property => {
                        handleBorderRadiusProperty(property, mobileBorderRadiusSides)
                    })

                    const updatedProperties = getUpdatedProperties(
                        properties,
                        borderRadiusSides,
                        withBorderRadiusAsOneParam
                    )
                    const updatedMobileProperties = getUpdatedProperties(
                        mobileProperties,
                        mobileBorderRadiusSides,
                        withBorderRadiusAsOneParam
                    )

                    return {
                        ...acc,
                        [selector]: {
                            ...acc[selector],
                            properties: updatedProperties,
                            mobileProperties: updatedMobileProperties
                        }
                    }
                },
                parsedStyle
            )

            return {
                ...styleItem,
                parsedStyle: updatedParsedStyle
            }
        })
    }
}

module.exports = migrateBorderRadiusFactory
