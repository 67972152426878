import {CONTROLLER_DATA} from '../../constants/constants'
import {CreateExtArgs, pointerUtils} from '@wix/document-manager-core'
import {getReferredCompId} from '../../utils/refStructureUtils'
import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'

const getStatePointer = ({pointers}: CreateExtArgs, controllerId: string) => {
    const appStatePointer = pointers.platform.getAppStatePointer()
    return pointerUtils.getInnerPointer(appStatePointer, [controllerId])
}
export const getState = (createExtArgs: CreateExtArgs, controllerId: string) => {
    const {dal} = createExtArgs
    const statePointer = getStatePointer(createExtArgs, controllerId)

    const controllerState = dal.get(statePointer)

    if (controllerState) {
        return controllerState
    }

    const templateControllerId = getReferredCompId(controllerId)

    const templateControllerStatePointer = getStatePointer(createExtArgs, templateControllerId)

    return templateControllerId
        ? dal.get(templateControllerStatePointer) || CONTROLLER_DATA.DEFAULT_STATE
        : CONTROLLER_DATA.DEFAULT_STATE
}
export const setState = ({pointers, dal, coreConfig}: CreateExtArgs, stateMap: any) => {
    const {logger} = coreConfig
    const appStatePointer = pointers.platform.getAppStatePointer()
    _.forEach(stateMap, (controllerRefs, state) => {
        _.forEach(controllerRefs, (controllerRef: Pointer) => {
            if (controllerRef?.id) {
                dal.set(pointers.getInnerPointer(appStatePointer, controllerRef.id), state)
            } else {
                logger.captureError(
                    new ReportableError({
                        errorType: 'SetStateError',
                        message: 'missing controllerRef id',
                        extras: {controllerRef, state}
                    })
                )
            }
        })
    })
}
