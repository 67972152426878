import {CONTAINER_LAYOUT_TYPES, ITEM_LAYOUT_TYPES, COMPONENT_LAYOUT_TYPES} from '../defaultResponsiveLayoutDefinitions'
export const customElementDefaultResponsiveLayout = {
    getLayout: () => {
        return {
            COMPONENT_LAYOUT_TYPES: COMPONENT_LAYOUT_TYPES.HEIGHT_WIDTH_AUTO,
            CONTAINER_LAYOUT_TYPES: CONTAINER_LAYOUT_TYPES.FRACTION,
            ITEM_LAYOUT_TYPES: ITEM_LAYOUT_TYPES.STRETCH_ITEM_LAYOUT
        }
    }
}
