import {type DAL, type DalItem, pointerUtils} from '@wix/document-manager-core'
import constants from './constants'
import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'

const {NAMESPACES_SUPPORTING_VARIABLES} = constants
const {getPointer} = pointerUtils

const findAndRemoveInnerVariableConnections = (
    prop: DalItem,
    rootVariableConnections: any[],
    oldToNewIdMap: any,
    path: (number | string)[] = []
) => {
    if (_.isArray(prop))
        prop.forEach((p, i) =>
            findAndRemoveInnerVariableConnections(p, rootVariableConnections, oldToNewIdMap, [...path, i])
        )
    if (!_.isPlainObject(prop)) return

    const {variableConnections} = prop
    if (_.isPlainObject(variableConnections)) {
        delete prop.variableConnections
        _.forOwn(variableConnections, (variableId, property) => {
            const newVariableId = oldToNewIdMap ? oldToNewIdMap[variableId] : variableId
            if (newVariableId) {
                rootVariableConnections.push({path: [...path, property].join('/'), variableId: newVariableId})
            }
        })
    }

    _.forOwn(prop, (p, key) =>
        findAndRemoveInnerVariableConnections(p, rootVariableConnections, oldToNewIdMap, [...path, key])
    )
}

export const removeVariablesConnections = (
    dal: DAL,
    item: DalItem,
    itemType: string,
    itemId: string,
    oldToNewIdMap?: {[key: string]: string}
) => {
    if (!NAMESPACES_SUPPORTING_VARIABLES.has(itemType)) return item

    const rootVariableConnections: string[] = []

    findAndRemoveInnerVariableConnections(item, rootVariableConnections, oldToNewIdMap)

    const variableConnectionsPointer = itemId && getPointer(itemId, itemType, {innerPath: ['variableConnections']})
    if (rootVariableConnections.length > 0 || dal.has(variableConnectionsPointer as Pointer)) {
        item.variableConnections = rootVariableConnections
    }

    return item
}
