import _ from 'lodash'
import type {DocumentServicesObject} from '@wix/document-services-types'
import type {OverlayManager} from '../overlayManager/overlayManager'
import type {ComponentsOrderFlatMapOld} from '../types'
import {DESKTOP_VIEWMODE, OLD_ALGO} from '../constants'

const displayOrderIndicationsOld = (
    overlayManager: OverlayManager,
    orderMap: ComponentsOrderFlatMapOld,
    rootId: string
) => {
    const dfsStack = [rootId]
    let order = 0
    while (dfsStack.length > 0) {
        const compId = dfsStack.pop()!
        if (orderMap[compId]) {
            dfsStack.push(...orderMap[compId].slice().reverse())
        } else {
            overlayManager.markComponent([compId], OLD_ALGO, `old-algo:${++order}`, 'red', '', '')
        }
    }
}

export const displayComponentsOrderSingleSection = (
    documentServices: DocumentServicesObject,
    overlayManager: OverlayManager,
    sectionId: string
) => {
    const orderMap: ComponentsOrderFlatMapOld = documentServices.mobileConversion.debugApi.getComponentsOrder(
        documentServices.pages.getCurrentPageId(),
        DESKTOP_VIEWMODE
    )
    displayOrderIndicationsOld(overlayManager, orderMap, sectionId)
}

export const displayComponentsOrderAllSections = (
    documentServices: DocumentServicesObject,
    overlayManager: OverlayManager
) => {
    const pageId = documentServices.pages.getCurrentPageId()
    const orderMap: ComponentsOrderFlatMapOld = documentServices.mobileConversion.debugApi.getComponentsOrder(
        pageId,
        DESKTOP_VIEWMODE
    )
    _(orderMap)
        .keys()
        .without(pageId)
        .forEach(sectionId => displayOrderIndicationsOld(overlayManager, orderMap, sectionId))
}
