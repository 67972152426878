import {
    CreateExtArgs,
    DmApis,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    PointerMethods,
    pointerUtils,
    DalValue,
    CreateExtensionArgument,
    ValidateValue,
    InitializeExtArgs
} from '@wix/document-manager-core'
import type {Pointer, FlatStructureMap, DeepStructureOptions, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import {
    COMP_IDS,
    LANDING_PAGES_COMP_IDS,
    MASTER_PAGE_ID,
    VIEW_MODES,
    COMP_DATA_QUERY_KEYS_WITH_STYLE
} from '../constants/constants'
import {findCompBFS} from '../utils/findCompBFS'
import {isRepeater, getRepeaterTemplateId, getUniqueDisplayedId} from '../utils/repeaterUtils'
import {getChildrenRecursivelyRightLeftRoot, getDeepStructureForComp} from '../utils/structureUtils'
import type {SlotsExtensionAPI} from './slots'
import type {PageAPI} from './page'
import type {RelationshipsAPI} from './relationships'
import {getPointerGetterConsideringScopes} from '../utils/scopesUtils'
import {getComponentType as getUtilCompType} from '../utils/dalUtils'
import type {MeshLayoutExtApi} from './meshLayout/meshLayout'
import {deepClone} from '@wix/wix-immutable-proxy'
import {isSharedPartsPointer} from '../utils/inflationUtils'

const {getPagePointer, getPointer, getRepeatedItemPointerIfNeeded, getInnerPointer} = pointerUtils

const masterPageId = MASTER_PAGE_ID
const pagesTypes = ['wixapps.integration.components.AppPage', 'mobile.core.components.Page']

type Predicate = (a: any) => boolean

export interface ComponentsAPI extends ExtensionAPI {
    getAllDesktopComponents(): Record<string, DalValue>
    getAllMobileComponents(): Record<string, DalValue>
    getChildren(componentPointer: Pointer, isRecursive?: boolean): Pointer[]
    getAncestors(componentPointer: Pointer, isRecursive?: boolean): Pointer[]
    getAllComponentPointers(): Pointer[]
    getComponentType(componentPointer: Pointer): string
    createMobilePageFromNewDesktopPage(pointer: Pointer, value: any): void
    getDeepStructure(compPointer: Pointer, options?: DeepStructureOptions): any
    getDescendants(compPointer: Pointer): FlatStructureMap
}

export interface StructureExtensionAPI extends ExtensionAPI {
    components: ComponentsAPI
    mobile: {
        disableMobileNamespaceSupport(): void
        isMobileNamespaceSupported(configValue?: Boolean): boolean
    }
    siteAPI: {
        getAllDesktopComponents(): Record<string, DalValue>
        getAllMobileComponents(): Record<string, DalValue>
        getAllDesktopComponentPointers(): Pointer[]
        getAllMobileComponentPointers(): Pointer[]
        getAllComponentPointers(): Pointer[]
    }
    site: {
        isWithStaticSharedPartsInflation(): boolean
    }
}

const createExtension = ({experimentInstance, dsConfig}: CreateExtensionArgument): Extension => {
    const isWithStaticSharedPartsInflation = () =>
        experimentInstance.isOpen('specs.thunderbolt.doNotInflateSharedParts')

    const createPointersMethods = ({dal, pointers, extensionAPI}: DmApis): PointerMethods => {
        const getPointerFunc = getPointerGetterConsideringScopes(extensionAPI)

        const getComponent = (id: string, pagePointer: Pointer) => {
            const pointer = getPointer(id, pagePointer.type)
            return getRepeatedItemPointerIfNeeded(pointer)
        }

        const getComponentById = (id: string, viewMode: string) => {
            const pointer = getPointer(id, viewMode)
            return getRepeatedItemPointerIfNeeded(pointer)
        }

        /**
         * @param {Pointer} compPointer
         * @returns {Pointer|null}
         */
        const getPageOfComponent = (compPointer: Pointer): Pointer | null => {
            if (!compPointer) {
                return null
            }

            if (isWithStaticSharedPartsInflation() && isSharedPartsPointer(compPointer as CompRef)) {
                const {pageId} = dal.get(pointers.runtime.getWantedNavInfo())
                return getPointer(pageId, compPointer.type)
            }

            const pointer = getRepeatedItemPointerIfNeeded(compPointer)

            const component = dal.get(pointer)
            if (!component) {
                return null
            }
            return getPointer(component.metaData.pageId, compPointer.type)
        }

        const isMasterPage = (pointer: Pointer) => pointer.id === 'masterPage'
        const getChildren = (pointer: CompRef) => {
            const pointerToGetFrom = getRepeatedItemPointerIfNeeded(pointer)
            const childrenIds = dal.getWithPath(pointerToGetFrom, ['components'])

            const childrenPointers = _.map(childrenIds, id => getPointerFunc(pointer, id))

            return childrenPointers as CompRef[]
        }

        const getParent = (pointer: CompRef) => {
            if (!pointer) {
                return null
            }
            const pointerToGetFrom = getRepeatedItemPointerIfNeeded(pointer)
            const childPointer = getPointerFunc(pointerToGetFrom, pointerToGetFrom.id)
            const parentId = dal.get(getInnerPointer(childPointer, ['parent']))

            if (parentId === undefined) {
                return null
            }

            const parentPointer = getPointerFunc(pointerToGetFrom, parentId)

            return parentPointer as CompRef
        }

        const getChildrenContainer = ({id, type}: Pointer) => ({id, type, innerPath: ['components']})
        const getChildrenRecursively = (pointer: CompRef): CompRef[] => {
            const children = getChildren(pointer)
            return children.concat(_.flatMap(children, getChildrenRecursively))
        }

        const getChildrenRecursivelyRightLeftRootIncludingRoot = (pointer: CompRef) =>
            getChildrenRecursivelyRightLeftRoot(getChildren, pointer)

        const findDescendant = (componentPointer: CompRef, predicate: Predicate) => {
            const comp = findCompBFS(componentPointer, getChildren, compPointer => predicate(dal.get(compPointer)))
            return comp ? getComponent(comp.id, componentPointer) : null
        }

        const findComponentInPage = (pagePointer: CompRef, isMobileView: boolean, predicate: Predicate) =>
            findDescendant(pagePointer, predicate)

        const getDesktopPointer = (pointer: Pointer) => _.assign(_.clone(pointer), {type: VIEW_MODES.DESKTOP})
        const getMobilePointer = (pointer: Pointer) => _.defaults({type: VIEW_MODES.MOBILE}, pointer)

        const getMasterPage = (viewMode: string) => getPointer(masterPageId, viewMode)
        const getPage = (id: string, viewMode: string) => (_.isString(id) ? getPointer(id, viewMode) : null)
        const getNewPage = (id: string, viewMode = VIEW_MODES.DESKTOP) => {
            const pointer = getPagePointer(id, viewMode)
            if (dal.has(pointer)) {
                throw new Error(`there is already a page with id ${id}`)
            }
            return pointer
        }

        const getPagesContainer = (viewMode: string) => getPointer(COMP_IDS.PAGES_CONTAINER, viewMode)
        const getFooter = (viewMode: string) => getPointer(COMP_IDS.FOOTER, viewMode)
        const getHeader = (viewMode: string) => getPointer(COMP_IDS.HEADER, viewMode)
        const isMobile = (p: Pointer) => p?.type === VIEW_MODES.MOBILE
        const isWithVariants = (pointer: Pointer) => !!_.get(pointer, ['variants'])
        const getViewMode = (pointer: Pointer) => (isMobile(pointer) ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP)
        const isPage = (pointer: Pointer): boolean => {
            const compType = dal.getWithPath(pointer, 'componentType')
            return pointer.id === masterPageId || _.includes(pagesTypes, compType)
        }
        const isPagesContainer = ({id}: Pointer) => id === COMP_IDS.PAGES_CONTAINER
        const getLandingPageComponents = (viewMode: string) =>
            _(LANDING_PAGES_COMP_IDS)
                .mapValues(id => getPointer(id, viewMode))
                .filter(pointer => dal.has(pointer))
                .value()

        const getAncestorByPredicate = (compPointer: CompRef, predicate: Predicate) => {
            let ancestorPointer = getParent(compPointer)
            while (ancestorPointer && !predicate(ancestorPointer)) {
                ancestorPointer = getParent(ancestorPointer)
            }

            return ancestorPointer
        }

        const isDescendant = (compPointer: CompRef, possibleAncestorPointer: CompRef): boolean => {
            return !!getAncestorByPredicate(compPointer, (ancestorPointer: CompRef) =>
                pointers.components.isSameComponent(ancestorPointer, possibleAncestorPointer)
            )
        }

        const isInMasterPage = (pointer: Pointer): boolean =>
            dal.getWithPath(getRepeatedItemPointerIfNeeded(pointer), ['metaData', 'pageId']) === masterPageId

        const getParentIncludingSlot = (pointer: CompRef) => {
            const {slots} = extensionAPI as SlotsExtensionAPI
            return slots.getPluginParent(pointer) || getParent(pointer)
        }

        const getAncestors = (pointer: CompRef) => {
            const result: CompRef[] = []
            let ancestor = getParentIncludingSlot(pointer)

            while (ancestor) {
                result.push(ancestor)
                ancestor = getParentIncludingSlot(ancestor)
            }

            return result
        }

        const getSiblings = (pointer: CompRef): CompRef[] => {
            const parent = getParent(pointer)

            if (parent) {
                return getChildren(parent).filter(sibling => !pointers.components.isSameComponent(pointer, sibling))
            }

            return []
        }

        const getAllDisplayedOnlyComponents = (pointer: CompRef) => {
            const repeaterPointer = getAncestorByPredicate(pointer, (ancestorPointer: Pointer) =>
                isRepeater(dal, ancestorPointer)
            )

            if (repeaterPointer) {
                const templateId = getRepeaterTemplateId(pointer.id)
                const {relationships} = extensionAPI as RelationshipsAPI
                const repeaterDataQuery = relationships.getIdFromRef(dal.getWithPath(repeaterPointer, 'dataQuery'))
                const repeaterDataPointer = getPointer(repeaterDataQuery, 'data')
                const repeaterData = dal.get(repeaterDataPointer)

                return _.map(repeaterData.items, itemId =>
                    getPointer(getUniqueDisplayedId(templateId, itemId), pointer.type)
                )
            }

            return [pointer]
        }

        const getAllComponentPointers = (viewMode: string, pageId: string | null = null): Pointer[] => {
            const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
            const result = dal.query(viewMode, pageCompFilter)
            return _(result)
                .keys()
                .map(id => getPointer(id, viewMode))
                .value()
        }

        return {
            structure: {
                // @ts-expect-error
                getAncestorByPredicate,
                getAncestors,
                getChildren,
                getChildrenContainer,
                getChildrenRecursively,
                getChildrenRecursivelyRightLeftRootIncludingRoot,
                getComponent,
                getComponentById,
                getDesktopPointer,
                getFooter,
                getHeader,
                getLandingPageComponents,
                getMasterPage,
                getMobilePointer,
                getNewPage,
                // @ts-expect-error
                getPage,
                // @ts-expect-error
                getPageOfComponent,
                getPagesContainer,
                // @ts-expect-error
                getParent,
                // @ts-expect-error
                getParentIncludingSlot,
                getSiblings,
                getUnattached: getPointer,
                // @ts-expect-error
                getViewMode,
                isDescendant,
                isInMasterPage,
                isMasterPage,
                isMobile,
                isPage,
                isPagesContainer,
                getAllDisplayedOnlyComponents,
                // @ts-expect-error
                findComponentInPage,
                // @ts-expect-error
                findDescendant,
                isWithVariants,
                getAllComponentPointers
            }
        }
    }

    const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): StructureExtensionAPI => {
        const getDescendants = (compPointer: Pointer, descendantsMap: FlatStructureMap = {}): FlatStructureMap => {
            descendantsMap[compPointer.id] = dal.get(compPointer)
            pointers.structure
                .getChildren(compPointer)
                .map(childPointer => getDescendants(childPointer, descendantsMap))

            return descendantsMap
        }

        const getDeepStructure = (
            compPointer: Pointer,
            {convertToAbsolute, resolver}: DeepStructureOptions = {}
        ): any => {
            const pointerToGetFrom = getRepeatedItemPointerIfNeeded(compPointer)
            const descendantsMap = deepClone(getDescendants(pointerToGetFrom))
            if (convertToAbsolute) {
                const {meshLayout} = extensionAPI as MeshLayoutExtApi
                meshLayout.convertToStructureLayout(descendantsMap, {isMobile: compPointer.type === 'MOBILE'})
            }
            if (resolver) {
                for (const [componentId, component] of Object.entries(descendantsMap)) {
                    const flatCompPointer = pointers.getPointer(componentId, compPointer.type) as CompRef
                    resolver(flatCompPointer, component)
                }
            }

            return getDeepStructureForComp(pointerToGetFrom.id, descendantsMap)
        }

        const getAllDesktopComponents = () => dal.query('DESKTOP', (extensionAPI.page as PageAPI).getAllPagesIndexId())

        const getAllMobileComponents = () => dal.query('MOBILE', (extensionAPI.page as PageAPI).getAllPagesIndexId())

        const getAllDesktopComponentPointers = () => {
            const desktopComponents = getAllDesktopComponents()

            return _.map(desktopComponents, componentDalValue => getPointer(componentDalValue.id, VIEW_MODES.DESKTOP))
        }

        const getAllMobileComponentPointers = () => {
            const mobileComponents = getAllMobileComponents()

            return _.map(mobileComponents, componentDalValue => getPointer(componentDalValue.id, VIEW_MODES.MOBILE))
        }

        const getAllComponentPointers = () => {
            const desktopComponentsPointers = getAllDesktopComponentPointers()
            const mobileComponentsPointers = getAllMobileComponentPointers()

            return _.unionBy(desktopComponentsPointers, mobileComponentsPointers, 'id')
        }

        const getChildren = (componentPointer: Pointer, isRecursive = false) => {
            if (_.isNil(componentPointer)) {
                return []
            }
            return isRecursive
                ? pointers.structure.getChildrenRecursively(componentPointer)
                : pointers.structure.getChildren(componentPointer)
        }

        const getAncestors = (componentPointer: Pointer, isRecursive = false) => {
            if (isRecursive) {
                return pointers.structure.getAncestors(componentPointer)
            }
            const getParentResult = pointers.structure.getParentIncludingSlot(componentPointer)

            return getParentResult ? [getParentResult] : []
        }

        const getComponentType = (componentPointer: CompRef) => {
            return getUtilCompType(dal, componentPointer)
        }
        const createMobilePageFromNewDesktopPage = (pointer: Pointer, value: any) => {
            const isPage = (extensionAPI.page as PageAPI).isPage(value)
            if (pointer.type === VIEW_MODES.DESKTOP && isPage && !pointer.innerPath) {
                const mobilePagePointer = getPointer(pointer.id, VIEW_MODES.MOBILE)
                if (!dal.get(mobilePagePointer)) {
                    const valueCopy = {...value, components: []} //there is a deep clone on set anyway, we just want to override components
                    dal.set(mobilePagePointer, valueCopy)
                }
            }
        }
        const isMobileNamespaceSupported = (defaultValue?: boolean) => {
            const inDalValue = dal.get(pointers.data.getDataItemFromMaster(MASTER_PAGE_ID))?.supportMobile
            if (inDalValue !== undefined) {
                return !!inDalValue
            }
            if (defaultValue !== undefined) {
                return defaultValue
            }
            return dsConfig.supportMobile
        }

        const disableMobileNamespaceSupport = () => {
            const inDalValue = dal.set(
                pointerUtils.getInnerPointer(pointers.data.getDataItemFromMaster(MASTER_PAGE_ID), ['supportMobile']),
                false
            )
            if (inDalValue !== undefined) {
                return inDalValue
            }
        }

        return {
            components: {
                getAllDesktopComponents,
                getAllMobileComponents,
                getAllDesktopComponentPointers,
                getAllMobileComponentPointers,
                getAllComponentPointers,
                getChildren,
                getAncestors,
                getComponentType,
                createMobilePageFromNewDesktopPage,
                getDeepStructure,
                getDescendants: (compPointer: CompRef) => getDescendants(compPointer)
            },
            mobile: {
                disableMobileNamespaceSupport,
                isMobileNamespaceSupported
            },
            siteAPI: {
                getAllDesktopComponents,
                getAllMobileComponents,
                getAllDesktopComponentPointers,
                getAllMobileComponentPointers,
                getAllComponentPointers
            },
            site: {
                isWithStaticSharedPartsInflation
            }
        }
    }

    const getDocumentDataTypes = (): DocumentDataTypes => _.mapValues(VIEW_MODES, _.constant({hasSignature: true}))
    const isStructure = (namespace: string) => !!VIEW_MODES[namespace]

    const NON_SPLITTABLE_QUERIES = _(COMP_DATA_QUERY_KEYS_WITH_STYLE).omit(['props', 'design']).values().value()

    const validateSplitQueriesImpl = (desktopValue: DalValue, mobileValue: DalValue) => {
        if (!desktopValue || !mobileValue) {
            return undefined
        }

        for (const query of NON_SPLITTABLE_QUERIES) {
            if (!!desktopValue[query] && !!mobileValue[query] && desktopValue[query] !== mobileValue[query]) {
                return [
                    {
                        shouldFail: experimentInstance.isOpen('dm_failSplitQueries'),
                        type: 'splitQueriesError',
                        message: `split ${query} not allowed`,
                        extras: {
                            desktopValue,
                            mobileValue
                        }
                    }
                ]
            }
        }
    }

    const createValidator = ({dal, pointers}: DmApis): Record<string, ValidateValue> => ({
        validateMobileOnSamePage: (pointer: Pointer, value: DalValue) => {
            if (!value || !isStructure(pointer.type)) {
                return undefined
            }

            const otherModePointer = pointers.structure.isMobile(pointer)
                ? pointers.structure.getDesktopPointer(pointer)
                : pointers.structure.getMobilePointer(pointer)

            const changedStructurePage = value.metaData.pageId
            const otherModeStructurePage = pointers.structure.getPageOfComponent(otherModePointer)

            if (otherModeStructurePage && otherModeStructurePage.id !== changedStructurePage) {
                return [
                    {
                        shouldFail: experimentInstance.isOpen('dm_validateSamePageMobileFail'),
                        type: 'differentMobilePageError',
                        message: `{${pointer.id},${pointer.type}} was changed to a different page than {${otherModePointer.id},${otherModePointer.type}}`,
                        extras: {
                            otherPointer: otherModePointer
                        }
                    }
                ]
            }

            return undefined
        },
        validateSplitQueries: (pointer: Pointer, value: DalValue) => {
            if (!value) {
                return undefined
            }
            if (pointer.type === VIEW_MODES.DESKTOP) {
                const mobileValue = dal.get(pointers.structure.getMobilePointer(pointer))
                return validateSplitQueriesImpl(value, mobileValue)
            }
            if (pointer.type === VIEW_MODES.MOBILE) {
                const desktopValue = dal.get(pointers.structure.getDesktopPointer(pointer))
                return validateSplitQueriesImpl(desktopValue, value)
            }
        }
    })

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {site} = extensionAPI as StructureExtensionAPI

        return {
            site: {
                isWithStaticSharedPartsInflation: site.isWithStaticSharedPartsInflation
            }
        }
    }

    const initialize = async (extArgs: InitializeExtArgs) => {
        const {eventEmitter, EVENTS, dal, extensionAPI} = extArgs
        eventEmitter.on(EVENTS.PAGE.PAGE_COMPONENT_ADDED, (pagePointer: Pointer) => {
            const {components} = extensionAPI as StructureExtensionAPI
            components.createMobilePageFromNewDesktopPage(pagePointer, dal.get(pagePointer))
        })
    }
    return {
        name: 'structure',
        initialize,
        createPointersMethods,
        createExtensionAPI,
        getDocumentDataTypes,
        createPublicAPI,
        createValidator
    }
}

const isStructurePointer = (pointer: Pointer): boolean => !!VIEW_MODES[pointer.type]

export {createExtension, isStructurePointer}
