import {METADATA_TYPES} from '../../../constants/constants'
import {isContainableByStructureInAppWidget} from '../../components/componentHooks/appWidgetHooks'
import type {HooksExtensionApi} from '../../hooks/hooks'
import type {CreateExtArgs} from '@wix/document-manager-core'
import {METADATA_HOOKS} from '../metadataHooks'

export const registerComponentMetadataHooks = (createExtArgs: CreateExtArgs) => {
    const {extensionAPI} = createExtArgs
    const {hooks} = extensionAPI as HooksExtensionApi

    hooks.registerHookWithExtensionArgs(
        METADATA_HOOKS[METADATA_TYPES.CONTAINABLE_BY_STRUCTURE].id,
        isContainableByStructureInAppWidget
    )
}
