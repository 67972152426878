import type {CreateExtArgs, CreateExtensionArgument, Extension, ExtensionAPI} from '@wix/document-manager-core'
import _ from 'lodash'

export interface EventOptions {
    ignoreUndefined?: boolean
}
export interface HookEventData<E extends string> {
    event: E
    eventOptions?: EventOptions
}

interface HookEvent<E extends string, R = undefined> {
    data: HookEventData<E>
    valueSetter?(value: R | undefined): void
    valueGetter?(): R | undefined
    compType?: string
}

export type HookEventHandler<E extends string, D extends HookEventData<E>, R = undefined> = (
    event: D,
    value?: R
) => R | undefined

export type HookEventHandlerWithExtensionArgs<E extends string, D extends HookEventData<E>, R = undefined> = (
    extensionArgs: CreateExtArgs,
    event: D,
    value?: R
) => R | undefined

export interface HooksExtensionApi extends ExtensionAPI {
    hooks: HooksApi
}

export interface HooksApi extends ExtensionAPI {
    registerHook<E extends string, D extends HookEventData<E>, R = undefined>(
        event: E,
        handler: HookEventHandler<E, D, R>,
        compType?: string
    ): void
    registerHookWithExtensionArgs<E extends string, D extends HookEventData<E>, R = undefined>(
        event: E,
        handler: HookEventHandlerWithExtensionArgs<E, D, R>,
        compType?: string
    ): void
    executeHook<E extends string, EE extends E>(data: HookEventData<EE>, compType?: string): void
    executeHookAndUpdateValue<E extends string, EE extends E, R = undefined>(
        data: HookEventData<EE>,
        initialValue: R,
        compType?: string
    ): R
}

const createExtension = ({}: CreateExtensionArgument): Extension => {
    return {
        createExtensionAPI: (createExtensionArgs: CreateExtArgs) => {
            const {eventEmitter} = createExtensionArgs
            const decorateHandler = <E extends string, R = undefined>(
                handler: HookEventHandler<E, HookEventData<E>, R>
            ) => {
                return (eventData: HookEvent<E, R>) => {
                    const returnValue = handler(eventData.data, eventData.valueGetter?.())
                    eventData.valueSetter?.(returnValue)
                }
            }

            const decorateHandlerWithExtensionApi = <E extends string, R = undefined>(
                handler: HookEventHandlerWithExtensionArgs<E, HookEventData<E>, R>
            ) => {
                return (eventData: HookEvent<E, R>) => {
                    const returnValue = handler(createExtensionArgs, eventData.data, eventData.valueGetter?.())
                    eventData.valueSetter?.(returnValue)
                }
            }

            const decorateEvent = <E extends string, R = undefined>(
                data: HookEventData<E>,
                setter: (data: R) => void,
                getter: () => R | undefined,
                compType?: string
            ): HookEvent<E, R> => {
                return {
                    data,
                    valueSetter: setter,
                    valueGetter: getter,
                    compType
                }
            }

            const createReturnValueGetterAndSetter = <R>(initialValue: R | undefined, eventOptions?: EventOptions) => {
                let returnValue: R | undefined = initialValue
                const setter = (value: R | undefined) => {
                    if (value !== undefined || (value === undefined && !eventOptions?.ignoreUndefined)) {
                        returnValue = value
                    }
                }
                const getter = (): R | undefined => {
                    return returnValue
                }
                return {setter, getter}
            }
            const getEventName = (event: string, compType?: string) => `hook__${compType}__${event}`

            const registerHook = <E extends string, R = undefined>(
                event: E,
                handler: HookEventHandler<E, HookEventData<E>, R>,
                compType?: string
            ) => {
                const decoratedHandler = decorateHandler(handler)
                eventEmitter.on(getEventName(event, compType), decoratedHandler)
            }

            const registerHookWithExtensionArgs = <E extends string, R = undefined>(
                event: E,
                handler: HookEventHandlerWithExtensionArgs<E, HookEventData<E>, R>,
                compType?: string
            ) => {
                const decoratedHandler = decorateHandlerWithExtensionApi(handler)
                eventEmitter.on(getEventName(event, compType), decoratedHandler)
            }

            const emitEvent = (
                compType: string | undefined,
                event: string,
                decoratedEventData: HookEvent<string, any>
            ) => {
                if (compType) {
                    eventEmitter.emit(getEventName(event, compType), decoratedEventData)
                }
                eventEmitter.emit(getEventName(event), decoratedEventData)
            }

            const executeHook = <E extends string, EE extends E>(data: HookEventData<EE>, compType?: string) => {
                const decoratedEventData = decorateEvent(data, _.noop, _.noop, compType)
                emitEvent(compType, data.event, decoratedEventData)
            }

            const executeHookAndUpdateValue = <E extends string, EE extends E, R = undefined>(
                data: HookEventData<EE>,
                initialValue: R,
                compType?: string
            ): R => {
                const {setter, getter} = createReturnValueGetterAndSetter(initialValue, data.eventOptions)
                const decoratedEventData = decorateEvent(data, setter, getter, compType)
                emitEvent(compType, data.event, decoratedEventData)
                return getter()!
            }

            return <HooksExtensionApi>{
                hooks: <HooksApi>{
                    registerHook,
                    registerHookWithExtensionArgs,
                    executeHook,
                    executeHookAndUpdateValue
                }
            }
        },
        name: 'hooks'
    }
}

export {createExtension}
