import type {CompStructure, PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import type {DeepStructure} from '@wix/mobile-conversion/src/types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import metaDataUtils from '../../componentsMetaData/metaDataUtils'
import theme from '../../theme/theme'
import dsUtils from '../../utils/utils'
import design from '../../variants/design'
import componentSpecificConversionData from './componentSpecificConversionData/componentSpecificConversionData'
import mobileHints from './mobileHints'
import experiment from 'experiment-amd'
import siteWidth from '../../structure/siteWidth/siteWidth'
import {VIEW_MODES} from '@wix/santa-core-utils/dist/ts/coreUtils/core/constants'

function getStyle(ps: PS, comp: CompStructure) {
    //this should consider refArray JIRA DM-4440
    const {styleId} = comp
    if (styleId) {
        return theme.styles.get(ps, styleId)
    }
    return null
}

function getBackground(ps: PS, comp: CompStructure, pageId: string) {
    const designItem = design.getComponentDefaultDesign(ps, comp, pageId)
    return _.get(designItem, 'background')
}

function getBackgroundColor(ps: PS, comp: CompStructure, pageId: string) {
    const backgroundColor = comp.designQuery
        ? _.get(getBackground(ps, comp, pageId), 'color', null)
        : _.get(getStyle(ps, comp), ['style', 'properties', 'bg'], null)
    return backgroundColor ? backgroundColor.replace(/[{}]/g, '') : null
}

function hasMediaBackground(ps: PS, component: CompStructure, pageId: string) {
    return _.has(getBackground(ps, component, pageId), 'mediaRef')
}

function isSolidColorBackgroundComponent(ps: PS, component: CompStructure, pageId: string) {
    if (_.get(component, ['conversionData', 'category']) === 'columns') {
        return (
            _.size(component.components) === 1 &&
            !hasMediaBackground(ps, component.components[0] as CompStructure, pageId)
        )
    }
    if (
        _.get(component, ['conversionData', 'category']) === 'column' ||
        component.componentType === 'wysiwyg.viewer.components.StripContainer'
    ) {
        return !hasMediaBackground(ps, component, pageId)
    }
    return false
}

function getComponentBorderFromStyle(ps: PS, component: CompStructure) {
    const border = '' + _.get(getStyle(ps, component), ['style', 'properties', 'brw'], '0') // eslint-disable-line
    return +border.match(/\d/g).join('')
}

function getContainerOpacity(ps: PS, component: CompStructure, pageId: string, isSolidColorBackground: boolean) {
    if (isSolidColorBackground || _.get(component, ['conversionData', 'category']) === 'column') {
        return +_.get(getBackground(ps, component, pageId), 'colorOpacity', 1)
    }
    if (component.componentType !== 'mobile.core.components.Container') {
        return 1
    }
    const style = getStyle(ps, component)
    const styleSkin = _.get(style, 'skin')
    if (
        styleSkin !== 'wysiwyg.viewer.skins.area.RectangleArea' &&
        styleSkin !== 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
    ) {
        return 1
    }
    return +_.get(style, ['style', 'properties', 'alpha-bg'], 1)
}

function setConversionData(comp: CompStructure, overriddenValues) {
    const oldConversionData = _.get(comp, 'conversionData', {})
    const newConversionData = _.assign(oldConversionData, overriddenValues)
    _.assign(comp, {conversionData: _.omitBy(newConversionData, _.isNull)})
}

function shouldApplyPreset(ps: PS, component: CompStructure, pageId: string) {
    const modeDefinitions = _.get(component, ['modes', 'definitions'])
    if (_.isEmpty(modeDefinitions)) {
        return true
    }
    const {propertyQuery} = component
    const propertyPointer = propertyQuery
        ? ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(propertyQuery), pageId)
        : null
    if (!propertyPointer) {
        return true
    }
    const compProps = ps.dal.get(propertyPointer) || {}
    const modeFromProps =
        compProps.mobileDisplayedModeId && _.find(modeDefinitions, 'modeId', compProps.mobileDisplayedModeId)
    return _.get(modeFromProps, 'type') !== santaCoreUtils.siteConstants.COMP_MODES_TYPES.DEFAULT
}

function validateComponentLayout(ps: PS, component: DeepStructure) {
    _.defaults(component, {layout: {}})
    delete component.layout.anchors
    if (
        mobileCore.conversionUtils.isSiteSegmentOrPage(component) ||
        mobileCore.conversionUtils.isMasterPage(component)
    ) {
        _.assign(component.layout, {fixedPosition: false})
        return
    }
    _.defaults(
        component.layout,
        {
            ...(experiment.isOpen('dm_stretchedCompsConversionDataFixWidthFallback') &&
                component.layout.docked?.left &&
                component.layout.docked?.right && {
                    width: siteWidth.getSiteWidthForViewMode(ps, VIEW_MODES.DESKTOP)
                })
        },
        {
            height: _.get(component, ['conversionData', 'layoutLimits', 'minHeight']),
            width: _.get(component, ['conversionData', 'layoutLimits', 'minWidth'])
        },
        {
            height: santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT,
            width: santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH,
            x: mobileCore.conversionConfig.COMPONENT_MOBILE_MARGIN_X,
            y: mobileCore.conversionConfig.COMPONENT_MOBILE_MARGIN_Y
        }
    )
}

function createConversionData(ps: PS, component: CompStructure, pageId: string) {
    const mobileConversionConfig = componentsMetaData.public.getMobileConversionConfig(ps, component, pageId)
    const compWithConversionConfig = _.assign({}, _.clone(component), {conversionData: mobileConversionConfig})
    const conversionData: any = {}
    const borderWidth = getComponentBorderFromStyle(ps, component)
    conversionData.borderWidth = borderWidth || null
    conversionData.isSolidColorBackground =
        isSolidColorBackgroundComponent(ps, compWithConversionConfig, pageId) || null
    const containerOpacity = getContainerOpacity(
        ps,
        compWithConversionConfig,
        pageId,
        conversionData.isSolidColorBackground
    )
    conversionData.isSemiTransparentContainer = containerOpacity < 1
    conversionData.isTransparentContainer = borderWidth ? false : containerOpacity === 0
    conversionData.backgroundColor = getBackgroundColor(ps, component, pageId)
    conversionData.shouldApplyPreset = shouldApplyPreset(ps, component, pageId)
    conversionData.noEmptyGroupsOnMobile = true

    const componentSpecificData = componentSpecificConversionData.getComponentSpecificConversionData(
        ps,
        component,
        pageId
    )
    _.merge(conversionData, componentSpecificData)

    conversionData.mobileHints = mobileHints.getMobileHintsItem(ps, component, pageId)

    const dockedRuntimeLayouts = ps.dal.get(ps.pointers.general.getDockedRuntimeLayout())

    if (_.has(component, ['layout', 'docked']) && dockedRuntimeLayouts[component.id]) {
        _.assign(component.layout.docked, dockedRuntimeLayouts[component.id])
    }

    conversionData.isScreenWidth =
        conversionData.isScreenWidth ||
        santaCoreUtils.dockUtils.isHorizontalDockToScreen(component.layout) ||
        santaCoreUtils.dockUtils.isFullPageComponent(component.layout) ||
        null

    conversionData.isNonContainableFullWidth = metaDataUtils.isNonContainableFullWidth(component.componentType)
    _.assign(conversionData, mobileConversionConfig)
    setConversionData(component, conversionData)
    validateComponentLayout(ps, component as any)
}

function createConversionDataForMobileOnlyComp(ps: PS, component: CompStructure, pageId: string) {
    const mobileConversionConfig = componentsMetaData.public.getMobileConversionConfig(ps, component, pageId)
    setConversionData(component, mobileConversionConfig || {})
}

export default {
    createConversionData,
    createConversionDataForMobileOnlyComp
}
