import _ from 'lodash'

import * as conversionUtils from '../conversionUtils'
import {shouldKeepOnMobile} from '../conversionUtils'
import * as semanticGroupsConverter from './semanticGroupsConverter'
import {conversionConfig} from '../conversionConfig'
import {
    getCustomWidth,
    getHorizontalMargin,
    getMarginForNextRow,
    getOffsetX,
    getOrderedChildren,
    getRecommendedHeight,
    getScaleFactor,
    getTopPadding,
    setHeight
} from './structureConverterUtils'
import {PROPORTIONAL_GROUP_FONT_SCALEDOWN_FACTOR} from './structureConverterConstants'
import type {ConversionSettings, ObjMap, DeepStructure} from '../../types'

export function applyPreset(
    component: DeepStructure,
    presetComponent: DeepStructure,
    settings: ConversionSettings,
    allDesktopComponents: ObjMap<DeepStructure> | undefined,
    desktopStructure: DeepStructure | undefined
): DeepStructure {
    _.assign(component, _.omit(presetComponent, ['conversionData', 'components']))

    const presetChildren = conversionUtils.getChildren(presetComponent)

    if (!_.isEmpty(presetChildren)) {
        const components = presetChildren.map(childPreset => {
            const child: DeepStructure = conversionUtils.getComponentByIdFromStructure(childPreset.id, component) as DeepStructure
            return child ? applyPreset(child, <DeepStructure>childPreset, settings, allDesktopComponents, desktopStructure) : null
        })
        component.components = _.compact(components)
        _.forEach(component.components, childComp => {
            // @ts-ignore
            if (component?.conversionData?.structureManager) {
                // @ts-ignore
                component.conversionData.structureManager.addToStructure(component, childComp)
            }
        })
    }

    return component
}
function rescaleLayout(comp: DeepStructure, scaleFactor: number, dims: string[] = ['x', 'y', 'width', 'height']): void {
    if (scaleFactor === 1) {
        return
    }
    _.assign(
        comp.layout,
        _.zipObject(
            dims,
            _.map(dims, dim => Math.round(comp.layout[dim] * scaleFactor))
        )
    )
}
/**
 * @public
 * @param component
 * @param scaleFactor
 */
const rescaleProportionally = (component: DeepStructure, scaleFactor: number) => {
    rescaleLayout(component, scaleFactor)
    _.forEach(component.components, comp => rescaleProportionally(comp, scaleFactor))
    rescaleText(component, scaleFactor)
}

/**
 * @public
 * @param DeepStructure component
 * @param number scaleFactor
 */
const rescaleText = (component: DeepStructure, scaleFactor: number) => {
    if (!conversionUtils.isTextComponent(component)) {
        return
    }

    if (component.conversionData.mobileAdjustedAverageFontSize) {
        const averageFontSize = component.conversionData.averageFontSize || 0
        component.layout.scale =
            (averageFontSize * scaleFactor * PROPORTIONAL_GROUP_FONT_SCALEDOWN_FACTOR) / component.conversionData.mobileAdjustedAverageFontSize
    }
}
/**
 * @private
 * @param DeepStructure component
 * @param number allowedWidth
 * @param boolean hasCustomWidth
 * @param settings
 * @param modifiedComponents
 * @returns void
 */
const rescaleChildToFitParent = (
    component: DeepStructure,
    allowedWidth: number,
    hasCustomWidth: boolean,
    settings: ConversionSettings,
    allDesktopComponents: ObjMap<DeepStructure>,
    desktopStructure: DeepStructure | undefined
) => {
    if (_.has(component, ['conversionData', 'preset']) && _.get(component, ['conversionData', 'shouldApplyPreset'])) {
        applyPreset(component, component.conversionData.preset, settings, allDesktopComponents, desktopStructure)
        return
    }

    if (
        component.layout.width > allowedWidth ||
        component.conversionData.shouldEnlargeToFitWidth ||
        component.layout.width > conversionConfig.MIN_WIDTH_FOR_ENLARGE ||
        hasCustomWidth ||
        component.conversionData.rescaleMethod === 'row'
    ) {
        rescaleComponent(component, allowedWidth, settings, allDesktopComponents, desktopStructure)
    } else {
        rescaleComponentHeight(component, {scaleFactor: 1})
    }
}

/**
 * Function is called recursivly and calculate new layout
 * for mobile components it used by merge and optimize
 * @public
 * @param componentToUpdate
 * @param parent
 * @param allowedWidth
 * @param settings
 */
const reorganizeChildren = (
    componentToUpdate: DeepStructure,
    parent: DeepStructure,
    allowedWidth: number,
    settings: ConversionSettings = {
        imageScaleFactor: 1,
        applyFontScaling: true,
        enableImprovedMergeFlow: false
    },
    allDesktopComponents?: ObjMap<DeepStructure> | undefined,
    desktopStructure?: DeepStructure | undefined
) => {
    const orderedComponents = getOrderedChildren(componentToUpdate)

    const topRightMargin = parent.conversionData.topRightMargin || [0, 0]
    const firstComponent = _.head(orderedComponents)
    const topPadding = getTopPadding(componentToUpdate, parent, firstComponent)

    let y = topPadding

    _.forEach(orderedComponents, (curComp, i) => {
        const horizontalMargin = getHorizontalMargin(curComp, parent)

        let shouldKeepMobileSizes = false

        if (settings.enableImprovedMergeFlow) {
            shouldKeepMobileSizes = shouldKeepOnMobile(curComp)
        }

        if ((settings.applyFontScaling && conversionUtils.isTextComponent(curComp)) || curComp.conversionData.mobileScale) {
            if (!shouldKeepMobileSizes) {
                curComp.layout.scale = curComp.conversionData.mobileScale
            }
        }

        const isScreenWidth = conversionUtils.shouldStretchToScreenWidth(curComp)

        let marginLeft = horizontalMargin
        let marginRight = horizontalMargin

        const alignment = curComp.conversionData.isInvisible || isScreenWidth ? 'screen' : componentToUpdate.conversionData.naturalAlignment || 'center'

        if (y < topRightMargin[1] - topPadding) {
            marginRight = Math.max(marginRight, topRightMargin[0])
            if (alignment === 'center') {
                marginLeft = marginRight
            }
        }

        const allowedWidthWithoutMargins = Math.max(allowedWidth - marginLeft - marginRight, 0)

        const customWidth = getCustomWidth(curComp, allowedWidth, allowedWidthWithoutMargins, isScreenWidth, settings)
        const hasCustomWidth = _.isNumber(customWidth)
        const widthToFit = hasCustomWidth ? customWidth : allowedWidthWithoutMargins

        rescaleChildToFitParent(curComp, widthToFit, hasCustomWidth, settings, allDesktopComponents, desktopStructure)

        if (y < topRightMargin[1] && customWidth > allowedWidthWithoutMargins && !curComp.conversionData.isTransparentContainer) {
            y = topRightMargin[1]
            marginRight = marginLeft = horizontalMargin
        }

        if (_.isNumber(curComp.conversionData.yOffset)) {
            y -= curComp.conversionData.yOffset
        } else if (curComp.conversionData.yOffsetRatio) {
            y -= curComp.layout.height * curComp.conversionData.yOffsetRatio
        }
        if (!shouldKeepMobileSizes) {
            curComp.layout.x = getOffsetX(curComp, alignment, allowedWidth, marginRight, marginLeft, orderedComponents[i - 1])
            if (curComp.layout.x < 0 || curComp.layout.x > allowedWidth) {
                curComp.layout.x = Math.round((allowedWidth - curComp.layout.width) / 2)
            }

            const isFirstInvisibleChild = curComp.conversionData.isInvisible && !i
            curComp.layout.y = y - (isFirstInvisibleChild ? topPadding + 1 : 0)
            if (curComp.layout.y < 0) {
                curComp.layout.y = 0
            }

            if (!curComp.conversionData.stackLayout) {
                const nextRowMargin = getMarginForNextRow(curComp, orderedComponents[i + 1], settings)
                const invisibleComponentsSizePlceholder = 1

                y += curComp.conversionData.isInvisible ? invisibleComponentsSizePlceholder : curComp.layout.height + nextRowMargin
            }
        }
    })
    componentToUpdate.components = orderedComponents
}

/**
 * @public
 * @param DeepStructure component
 * @param Object options
 * @param string[] allModifiedCompIds
 * @returns
 */
const rescaleComponentHeight = (component: DeepStructure, options: {scaleFactor: number}) => {
    const presetHeight = getRecommendedHeight(component)

    if (presetHeight) {
        return setHeight(component, presetHeight)
    }

    const fixedHeight = _.get(component.conversionData.fixedSize, 'height')

    if (conversionUtils.isGraphicComponent(component) && fixedHeight) {
        return setHeight(component, fixedHeight)
    }

    if (conversionUtils.isTextComponent(component) && !conversionUtils.isVerticalText(component)) {
        return setHeight(component, conversionConfig.DEFAULT_TEXT_HEIGHT)
    }

    const desktopHeight = component.layout.height
    const minHeight = component.conversionData.minHeight || 0

    if (_.isNumber(fixedHeight)) {
        setHeight(component, fixedHeight)
    } else if (_.get(component.conversionData, 'preserveAspectRatio', true)) {
        setHeight(component, Math.round(component.layout.height * options.scaleFactor))
    } else if (minHeight) {
        setHeight(component, 0)
    }
    setHeight(component, minHeight < desktopHeight ? Math.max(component.layout.height, minHeight) : desktopHeight)

    const heightAccordingToChildren = conversionUtils.getHeightAccordingToChildren(component, conversionUtils.getChildren(component), false)

    if (
        heightAccordingToChildren &&
        (!fixedHeight || fixedHeight <= heightAccordingToChildren || conversionUtils.containsComponent(component, conversionUtils.isTextComponent))
    ) {
        setHeight(component, heightAccordingToChildren)
    }
}
/**
 * @public
 * @param component
 * @param allowedWidth
 * @param settings
 * @param modifiedComponents
 */
const rescaleComponent = (
    component: DeepStructure,
    allowedWidth: number,
    settings: ConversionSettings = {
        imageScaleFactor: 1,
        applyFontScaling: true
    },
    allDesktopComponent?: ObjMap<DeepStructure> | undefined,
    desktopStructure?: DeepStructure | undefined
) => {
    const scaleFactor = getScaleFactor(component, allowedWidth)

    const method = component.conversionData.rescaleMethod
    if (method !== 'none' && !shouldKeepOnMobile(component)) {
        component.layout.width = allowedWidth
    }

    const nextSettings = <any>_.assign(settings, {
        imageScaleFactor: component.conversionData.descendantImageScaleFactor || settings.imageScaleFactor
    })

    switch (method) {
        case 'proportional':
            _.forEach(component.components, child => rescaleProportionally(child, scaleFactor))
            break
        case 'logo':
            semanticGroupsConverter.convertLogo(component, scaleFactor)
            break
        case 'row':
            semanticGroupsConverter.convertRow(component, scaleFactor)
            break
        case 'none':
            break
        default:
            reorganizeChildren(component, component, allowedWidth, nextSettings, allDesktopComponent, desktopStructure)
            break
    }

    rescaleComponentHeight(component, {scaleFactor})
}

export {reorganizeChildren, rescaleComponent, rescaleComponentHeight, rescaleText, rescaleLayout, rescaleProportionally}
