import _ from 'lodash'
import {updateComponentScopedValues} from './scopedValues'
import type {DeserializeComponentParams} from '../types'
import {getCustomId} from '../utils'
import {DATA_TYPES} from '../../../../constants/constants'
import {SERIALIZED_SCOPED_DATA_KEYS} from '../../constants'
import {stripHashIfExists} from '../../../../utils/refArrayUtils'

export const updateComponentReactionsStructure = (deserializationParams: DeserializeComponentParams) => {
    const {createExtArgs, compStructure, pageId, mappers} = deserializationParams
    const key = SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.reactions]
    const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}

    _.forEach(compStructure[key], reaction => {
        reaction.values.map((reactionData: any) => {
            if (reactionData.state) {
                const oldId = stripHashIfExists(reactionData.state)
                reactionData.state = `#${getCustomId(oldToNewIdMap, {id: oldId})}`
            }

            if (reactionData.effect) {
                const oldId = stripHashIfExists(reactionData.effect)
                reactionData.effect = `#${getCustomId(oldToNewIdMap, {id: oldId})}`
            }

            return reactionData
        })
    })

    updateComponentScopedValues(createExtArgs, compStructure, DATA_TYPES.reactions, pageId, mappers)
}
