'use strict'
const coreUtils = require('@wix/santa-core-utils')
const {isReferredId} = coreUtils.displayedOnlyStructureUtil

const exec = pageJson => {
    const {layout_data} = pageJson.data
    if (!layout_data) return

    const layoutDataIds = Object.keys(layout_data)
    layoutDataIds.forEach(id => {
        if (isReferredId(id)) {
            delete layout_data[id]
        }
    })
}

module.exports = {
    name: 'deleteLayoutOverridesForRefComponents',
    version: 1,
    exec
}
