import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {ComponentBeforeAddDataEvent} from '../hooks'

const isPageOrMasterPage = ({pointers}: CreateExtArgs, compPointer: Pointer) =>
    pointers.structure.isPage(compPointer) || pointers.structure.isMasterPage(compPointer)

export const unDockNonDirectPageChildren = (createExtArgs: CreateExtArgs, data: ComponentBeforeAddDataEvent) => {
    const {compToAddPointer, containerPointer, componentDefinition} = data
    if (compToAddPointer.id === 'MENU_AS_CONTAINER') {
        // This seems to be an exception for pinned components, since menuContainerDataFixer specifically moved it to the header
        // I don't want to get into changing that now
        return
    }

    if (componentDefinition.layout?.fixedPosition && !isPageOrMasterPage(createExtArgs, containerPointer)) {
        delete componentDefinition.layout.docked
        delete componentDefinition.layout.fixedPosition
        _.defaults(componentDefinition.layout, {x: 0, y: 0, width: 100, height: 100})
    }
}
