import type {CreateExtArgs, CreateExtensionArgument, Extension, ExtensionAPI} from '@wix/document-manager-core'
import _ from 'lodash'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {
    CompRef,
    CompStructure,
    ControllerConnectionItem,
    IConnectionItem,
    SerializedCompStructure,
    WixCodeConnectionItem
} from '@wix/document-services-types'
import {DATA_TYPES} from '../constants/constants'
import {getWixCodeConnectionItem} from '../utils/nicknamesUtils'
import type {NicknameContextExtensionAPI} from './nicknameContext'
import type {NicknamesExtensionAPI} from './nicknames'
import type {ConnectionsAPI} from './connections'

export interface ComponentCodeAPI {
    updateConnectionItemsNickname(
        connectionItems: IConnectionItem[],
        compPointer: CompRef,
        pagePointer: CompRef,
        contextInCurrentContainer: CompRef
    ): void
    updateConnectionsIfNeeded(
        compPointer: CompRef,
        containerPointer: CompRef,
        compDefinition: SerializedCompStructure
    ): void
    updateNicknameContextByNewContainer(
        compPointer: CompRef,
        componentDefinition: SerializedCompStructure,
        newContainerPointer: CompRef
    ): void
}

export type ComponentCodeExtensionAPI = ExtensionAPI & {
    componentCode: ComponentCodeAPI
}

const ORIGINAL_CONTEXT_FIELD = 'originalNicknameContext'

const createExtension = ({}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): ComponentCodeExtensionAPI => {
        const findSerializedConnectionByContext = (
            context: CompRef | null,
            compConnections: IConnectionItem[]
        ): IConnectionItem => {
            const {extensionAPI} = createExtArgs
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            if (context) {
                const {id: controllerId} = dataModel.components.getItem(context, DATA_TYPES.data) || {}
                if (controllerId) {
                    return _.find(compConnections, {controllerId}) as IConnectionItem
                }
            }
            return getWixCodeConnectionItem(compConnections) as IConnectionItem
        }

        const getContextControllerId = (context: CompRef | null) => {
            const {extensionAPI} = createExtArgs
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            if (!context) {
                return
            }
            const dataItem = dataModel.components.getItem(context, DATA_TYPES.data)
            return _.get(dataItem, ['id'])
        }

        const removeConnectionFromSerializedComponentIfInvalidNickname = (
            compPointer: CompRef,
            context: null | CompRef,
            connectionItems: IConnectionItem[],
            pagePointer: CompRef
        ) => {
            const nicknameConnectionItem = findSerializedConnectionByContext(context, connectionItems)
            const {extensionAPI} = createExtArgs
            const {nicknames} = extensionAPI as NicknamesExtensionAPI
            if (
                nicknameConnectionItem &&
                nicknames.hasComponentWithThatNickname(
                    pagePointer,
                    (nicknameConnectionItem as ControllerConnectionItem).role,
                    compPointer
                )
            ) {
                _.remove(connectionItems, nicknameConnectionItem)
            }
        }

        const updateConnectionItemsNickname = (
            connectionItems: IConnectionItem[],
            compPointer: CompRef,
            pagePointer: CompRef,
            contextInCurrentContainer: CompRef
        ) => {
            const {extensionAPI} = createExtArgs
            const {nicknameContext} = extensionAPI as NicknameContextExtensionAPI
            const connectionItem = findSerializedConnectionByContext(contextInCurrentContainer, connectionItems)
            if (connectionItem) {
                const context = nicknameContext.getNicknameContext(pagePointer)
                ;(connectionItem as ControllerConnectionItem).controllerId = getContextControllerId(context)
                removeConnectionFromSerializedComponentIfInvalidNickname(
                    compPointer,
                    context,
                    connectionItems,
                    pagePointer
                )
            }
        }

        const fixWixCodeConnections = (context: null | CompRef, compDefinition: CompStructure) => {
            const items = _.get(compDefinition, ['connections', 'items'], [])
            const wixCodeConnectionItem: WixCodeConnectionItem = _.find(items, {type: 'WixCodeConnectionItem'})
            if (context && wixCodeConnectionItem) {
                const {role} = wixCodeConnectionItem
                const {extensionAPI} = createExtArgs
                const {dataModel} = extensionAPI as DataModelExtensionAPI
                const {connections} = extensionAPI as ConnectionsAPI

                const controllerId = dataModel.components.getItem(context, DATA_TYPES.data).id
                const connectionItem = connections.createConnectionItem(role, controllerId)

                compDefinition.connections.items = _(items)
                    .without(wixCodeConnectionItem)
                    .concat([connectionItem])
                    .value()
            }
        }
        const fixOrRemoveConnections = (
            compPointer: CompRef,
            compDefinition: CompStructure,
            nicknameContext: CompRef | null,
            pagePointer: CompRef
        ) => {
            fixWixCodeConnections(nicknameContext, compDefinition)
            removeConnectionFromSerializedComponentIfInvalidNickname(
                compPointer,
                nicknameContext,
                _.get(compDefinition, ['connections', 'items']),
                pagePointer
            )
        }
        const updateConnectionsIfNeeded = (
            compPointer: CompRef,
            containerPointer: CompRef,
            compDefinition: SerializedCompStructure
        ) => {
            const {pointers, extensionAPI} = createExtArgs
            const {nicknameContext} = extensionAPI as NicknameContextExtensionAPI

            const pagePointer = pointers.structure.getPageOfComponent(containerPointer)
            const nicknameCtxVal = nicknameContext.getNicknameContext(pagePointer as CompRef)

            fixOrRemoveConnections(compPointer, compDefinition, nicknameCtxVal, pagePointer as CompRef)
        }
        const updateNicknameContextByNewContainer = (
            compPointer: CompRef,
            componentDefinition: SerializedCompStructure,
            newContainerPointer: CompRef
        ) => {
            const {pointers} = createExtArgs
            const connectionItems = _.get(componentDefinition, ['connections', 'items'])
            if (_.isEmpty(connectionItems)) {
                return
            }

            const contextInCurrentContainer = _.get(componentDefinition, ['custom', ORIGINAL_CONTEXT_FIELD])
            if (contextInCurrentContainer) {
                const pagePointer = pointers.structure.getPageOfComponent(newContainerPointer)
                updateConnectionItemsNickname(
                    connectionItems,
                    compPointer,
                    pagePointer as CompRef,
                    contextInCurrentContainer
                )
            }
        }
        return {
            componentCode: {
                updateConnectionItemsNickname,
                updateConnectionsIfNeeded,
                updateNicknameContextByNewContainer
            }
        }
    }
    return {
        name: 'componentCode',
        dependencies: new Set(['dataModel', 'components']),
        createExtensionAPI
    }
}

export {createExtension}
