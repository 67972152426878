import type {DAL} from '@wix/document-manager-core'
import {removePrefix} from '@wix/document-manager-utils'
import type {MetaData, Pointers} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'
import _ from 'lodash'
import {DATA_TYPES, DATA_TYPES_VALUES_WITH_HASH} from '../constants/constants'
import * as jsonSchemas from '@wix/document-services-json-schemas'

const {getUniqueId} = guidUtils
const {
    namespaceMapping: {NAMESPACE_MAPPING}
} = jsonSchemas

const ITEM_TO_PREFIX_MAP: Record<string, string> = {
    data: 'dataItem',
    props: 'propItem',
    connections: 'connection',
    behaviors: 'behavior'
}
const DEFAULT_DATA_PREFIX = new Set(Object.keys(NAMESPACE_MAPPING))

const generateItemIdWithPrefix = (prefix: string, addPrefixDelimiter = true) =>
    getUniqueId(prefix, addPrefixDelimiter ? '-' : '', {bucket: `generateItemIdWithPrefix_${prefix}`})

const generateUniqueIdByType = (type: string, pageId: string, dal: DAL, pointers: Pointers) => {
    const prefix = ITEM_TO_PREFIX_MAP[type] ?? type
    let newId = generateItemIdWithPrefix(prefix)
    const retries = 3
    for (let i = 0; i < retries && dal.has(pointers.data.getItem(type, newId, pageId)); i++) {
        newId = generateItemIdWithPrefix(prefix)
    }

    if (dal.has(pointers.data.getDataItem(newId, pageId))) {
        throw new Error('Failed to generate unique Id')
    }

    return newId
}

/**
 * Generates a new data Item ID.
 * usually the path to the page to add the data to.
 */
const generateNewDataItemId = () => {
    return generateItemIdWithPrefix('dataItem')
}

/**
 * Generates a new property Item ID.
 * usually the path to the page to add the data to.
 */
const generateNewPropertiesItemId = () => {
    return generateItemIdWithPrefix('propItem')
}

/**
 * return the function to generate a new id
 *
 * @param {string} idType (data || props || style)
 */
const generateNewId = (idType: string) => {
    switch (idType) {
        case 'data':
            return generateNewDataItemId()
        case 'props':
        case 'properties':
            return generateNewPropertiesItemId()
        case 'design':
            return generateNewDataItemId()
        case 'behaviors':
            return generateItemIdWithPrefix('behavior')
        case 'connections':
            return generateItemIdWithPrefix('connection')
    }

    //general/default case:
    if (DEFAULT_DATA_PREFIX.has(idType)) {
        return generateItemIdWithPrefix(idType)
    }

    throw new Error(`Unknown idType: ${idType}`)
}

const BASE_METADATA = {schemaVersion: '1.0'}
const DEFAULT_METADATA = {...BASE_METADATA, isPreset: false, isHidden: false}
const createMetaData = (template: Record<string, string | boolean>, pageId?: string) =>
    pageId ? {...template, pageId} : {...template}

const GET_DEFAULT_META_DATA = {
    [DATA_TYPES.prop]: createMetaData.bind(null, BASE_METADATA),
    DEFAULT: createMetaData.bind(null, DEFAULT_METADATA)
}

const createDefaultMetaData = (metaDataOverrides?: MetaData, pageId?: string, itemType: string = 'DEFAULT') => {
    const defaultMetaDataCreator = GET_DEFAULT_META_DATA[itemType] || GET_DEFAULT_META_DATA.DEFAULT

    return _.assign(defaultMetaDataCreator(pageId), metaDataOverrides)
}

const addDefaultMetaData = (newDataItem: {metaData?: MetaData}, pageId?: string, itemType: string = 'DEFAULT') => {
    newDataItem.metaData = createDefaultMetaData(newDataItem.metaData, pageId, itemType)
}

const shouldMergeDataItems = (existingDataItem: any, newDataItem: any) =>
    existingDataItem &&
    (!existingDataItem.type || !newDataItem.type || _.isEqual(existingDataItem.type, newDataItem.type))

const REF_PREFIX = '#'
const getIdFromRef = (ref: string) => removePrefix(ref, REF_PREFIX)

//this should be used only for root structure queries
const createRef = (id: string, namespace: string) =>
    DATA_TYPES_VALUES_WITH_HASH[namespace] && !id.startsWith(REF_PREFIX) ? `${REF_PREFIX}${id}` : id

const createInnerRef = (id: string) => (!id.startsWith(REF_PREFIX) ? `${REF_PREFIX}${id}` : id)

export {
    shouldMergeDataItems,
    createDefaultMetaData,
    addDefaultMetaData,
    generateUniqueIdByType,
    getIdFromRef,
    generateItemIdWithPrefix,
    generateNewId,
    createRef,
    createInnerRef
}
