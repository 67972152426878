import type {
    AppDefinitionId,
    ApplicationId,
    Callback1,
    ClientSpecMap,
    EditorClientSpecMapEntry,
    PagesData,
    PS,
    TPAWidget
} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import platformStateService from '../../platform/services/platformStateService'
import clientSpecMapDS from '../../siteMetadata/clientSpecMap'
import metaData from '../../siteMetadata/siteMetadata'
import tpaUtils from '../utils/tpaUtils'
import {installedTpaAppsUtils} from '@wix/document-manager-extensions'
import experiment from 'experiment-amd'
import type {AppsState} from './appStoreService'

const isAppRevoked = (appData: EditorClientSpecMapEntry) => _.get(appData, ['permissions', 'revoked']) === true

const {computeHidePage: computeHidePageInternal} = installedTpaAppsUtils
let isDevMode = false
let clientSpecMapReadyOnLoad = false
const callbacksForClientSpecMapReadyOnLoad: Callback1<PS>[] = []

const isPremiumApp = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)
    return !!appData && !!appData.vendorProductId
}

const hasPremiumOffering = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (appData?.vendorProducts?.length === 1 && appData?.vendorProducts[0] === 'basic') {
        return false
    }
    return !!appData && _.size(appData.vendorProducts) > 0
}

const setAppsData = function (ps: PS, newClientSpecMap: ClientSpecMap) {
    metaData.setProperty(ps, metaData.PROPERTY_NAMES.CLIENT_SPEC_MAP, newClientSpecMap)
    _.forEach(newClientSpecMap, (appData: any) => {
        if (isAppRevoked(appData)) {
            ps.extensionAPI.appsInstallState.setAppUninstalled(appData.appDefinitionId, 'setAppsData')
        } else {
            const version = clientSpecMapDS.getAppVersion(appData)
            ps.extensionAPI.appsInstallState.setAppInstalled(appData.appDefinitionId, {version}, 'setAppsData')
        }
    })
}

const getLargestApplicationId = function (clientSpecMap: ClientSpecMap) {
    const toInt = (x: string) => parseInt(x, 10)
    return _(clientSpecMap).keys().map(toInt).compact().concat(0).max()
}

const filterApps = function (csm) {
    return _.pickBy(
        csm,
        appData =>
            tpaUtils.isTpaByAppType(appData.type) && (_.isUndefined(appData.appType) || isEditorOrHybridApp(appData))
    )
}

const getWidgetData = function (ps: PS, appDefinitionId: AppDefinitionId, widgetId: number | string) {
    const widgets = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)?.widgets || {}
    return widgets[widgetId] || null
}

const isWidgetPublished = function (widget: TPAWidget) {
    const isPublishedInNewAndOldEditor = widget.published
    const isPublishedOnlyInNewEditor = widget.santaEditorPublished
    return isPublishedInNewAndOldEditor || isPublishedOnlyInNewEditor
}

const getExtensionsWidgets = function (ps: PS, appData) {
    let widgets = appData?.widgets
    widgets = _.filter(widgets, widget => _.isNil(widget.appPage))

    if (!isDevMode) {
        widgets = _.filter(widgets, isWidgetPublished)
    }
    return widgets
}

const getAppSections = function (ps: PS, appData) {
    let widgets = appData?.widgets
    widgets = _.filter(widgets, widget => !_.isNil(widget.appPage))

    if (!isDevMode) {
        widgets = _.filter(widgets, isWidgetPublished)
    }
    return widgets
}

const getAppSectionsToInstall = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)
    let sections = getAppSections(ps, appData)
    sections = _.filter(sections, {autoAddToSite: true})
    return _.sortBy(sections, 'appPage.order')
}

const getAppWorkerUrl = (ps: PS, appDefinitionId: AppDefinitionId) => {
    const appData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)
    return _.get(appData, 'appWorkerUrl')
}

const isPageMarkedAsAutoAddToSite = function (ps: PS, pageData: PagesData) {
    const appData = clientSpecMapDS.getAppData(ps, _.get(pageData, 'tpaApplicationId'))
    const sections = getAppSections(ps, appData)
    const widgetData = _.find(sections, {appPage: {id: _.get(pageData, 'tpaPageId')}})
    return _.get(widgetData, 'autoAddToSite')
}

const isPageMarkedAsShouldDeleteAppWhenDeleted = function (ps: PS, pageData: PagesData) {
    const appData = clientSpecMapDS.getAppData(ps, _.get(pageData, 'tpaApplicationId'))
    const sections = getAppSections(ps, appData)
    const widgetData = _.find(sections, {appPage: {id: _.get(pageData, 'tpaPageId')}})
    return _.get(widgetData, 'componentFields.shouldDeleteAppWhenDeleted')
}

const hasSections = function (ps: PS, appData) {
    const appSections = getAppSections(ps, appData)
    return !_.isEmpty(appSections)
}

const isHybridApp = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = appDefinitionId && clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)
    return isHybridAppFromAppData(appData)
}

const isHybridAppFromAppData = function (appData) {
    if (_.isUndefined(appData) || _.isUndefined(appData.appType)) {
        return false
    }
    return appData.appType === 'Hybrid'
}

const isHybridAppAndEditorPartNotDismissed = function (appData) {
    const isHybrid = isHybridAppFromAppData(appData)
    return isHybrid && (_.isUndefined(appData.editorPartDismissed) || appData.editorPartDismissed === false)
}

const isDashboardAppOnly = function (appData) {
    if (_.isUndefined(appData) || _.isUndefined(appData.appType)) {
        return false
    }
    return appData.appType === 'Dashboard'
}

const isEditorOrHybridApp = ({appType}: {appType?: string} = {}) => appType === 'Hybrid' || appType === 'Editor'

const isEditorApp = ({appType}: {appType?: string}) => appType === 'Editor'

const isAppPermissionsIsRevoked = function (appData?, appsState?: AppsState) {
    const isRevokedByCSM = appData?.permissions?.revoked === true
    const appState = _.get(appsState, appData?.appDefinitionId, {})
    const isUnusedApp = !experiment.isOpen('dm_settleFromInstallState') && _.get(appState, ['unused']) === true

    return isRevokedByCSM || isUnusedApp
}

const isAppActiveByState = function (ps: PS, appData: Record<string, any> = {}) {
    if (isDashboardAppOnly(appData)) {
        // @ts-ignore
        return !isAppRevoked(appData)
    }

    const appDefId = _.get(appData, 'appDefinitionId', false)

    return appDefId && ps.extensionAPI.appsInstallState.isAppInstalled(appDefId)
}

const isAppProvisioned = (ps: PS, appDefinitionId: AppDefinitionId) => {
    const existingAppData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)

    return (
        !_.isUndefined(existingAppData) &&
        !isAppPermissionsIsRevoked(existingAppData) &&
        !existingAppData.requiresEditorComponent
    )
}

const isAppAutoRevoked = appData =>
    !(_.has(appData, 'appFields.platform.studio') || _.get(appData, 'appFields.excludeFromAutoRevoke', false))

const isAppUnused = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appsState = platformStateService.getAppsState(ps)
    return !experiment.isOpen('dm_settleFromInstallState') && appsState?.[appDefinitionId]?.unused === true
}

const isAppPermissionsGrantedOnCSM = function (appData, appsState: AppsState) {
    const appState = _.get(appsState, appData?.appDefinitionId, {})
    const isGrantedByCSM = appData?.permissions?.revoked === false
    const isAppUsed = experiment.isOpen('dm_settleFromInstallState') || _.get(appState, ['unused']) !== true

    return isGrantedByCSM && isAppUsed
}

const hasHiddenPages = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)
    const sections = getAppSections(ps, appData)
    const hiddenSection = _.filter(sections, 'appPage.hidden')
    return _.size(hiddenSection) > 0
}

const hasMainSection = function (ps: PS, appData) {
    const sections = getAppSections(ps, appData)
    return _.some(sections, app => !app.appPage.hidden)
}

const getMainSectionWidgetData = function (ps: PS, appData) {
    const sections = getAppSections(ps, appData)
    return _.find(sections, app => !app.appPage.hidden)
}

const getMainSectionWidgetDataFromApplicationId = function (ps: PS, applicationId: ApplicationId) {
    return getMainSectionWidgetData(ps, clientSpecMapDS.getAppData(ps, applicationId))
}

const getMainSectionWidgetDataFromAppDefinitionId = function (ps: PS, appDefinitionId: AppDefinitionId) {
    return getMainSectionWidgetData(ps, clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId))
}

const widgetsToAutoAddToSite = function (ps: PS, appData) {
    const widgets = getExtensionsWidgets(ps, appData)
    return _.filter(widgets, 'autoAddToSite')
}

const getWidgetDataFromTPAPageId = function (ps: PS, appDefinitionId: AppDefinitionId, pageId: string) {
    const sections = getAppSections(ps, clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId))
    return _.find(sections, {appPage: {id: pageId}})
}

const getWidgetDataFromTPAWidgetId = function (ps: PS, appDefinitionId: AppDefinitionId, tpaWidgetId: string) {
    const widgets = getExtensionsWidgets(ps, clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId))
    return _.find(widgets, {tpaWidgetId})
}

const setIsInDevMode = function (isInDevMode: boolean) {
    isDevMode = isInDevMode
}

const isWidgetHasMobileUrl = function (ps: PS, appDefinitionId: AppDefinitionId, widgetId: string | number) {
    if (!appDefinitionId || !widgetId) {
        return false
    }
    const widgetData = getWidgetData(ps, appDefinitionId, widgetId)
    if (widgetData?.mobileUrl) {
        const {mobileUrl} = widgetData
        const isPublished =
            widgetData.mobilePublished && _.isBoolean(widgetData.mobilePublished) && widgetData.mobilePublished
        return !_.isEmpty(mobileUrl) && (isDevMode || isPublished)
    }

    return false
}

const isAppProvisionedOnServer = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)
    return !appData.notProvisioned
}

const getSectionsWidgetIdsToPreFetch = function (appData) {
    return _(appData.widgets).filter('preFetch').filter('appPage').filter(isWidgetPublished).map('widgetId').value()
}

const isDemoAppAfterProvision = function (appData) {
    const instance = _.get(appData, 'instance')
    if (!_.isEmpty(instance)) {
        const instanceParts = appData.instance.split('.')
        const instanceValues = JSON.parse(atob(instanceParts[1]))
        return !_.isEmpty(instanceValues.originInstanceId)
    }
}

const isSuperAppByCompId = function (ps: PS, compId: string) {
    const compPointer = componentDetectorAPI.getComponentById(ps, compId)
    const compData = component.data.get(ps, compPointer)
    const applicationId = _.get(compData, 'applicationId')
    const appData = clientSpecMapDS.getAppData(ps, applicationId)
    return appData.isWixTPA
}

const getDependentApps = function (): string[] {
    //temporarily hard coded until a proper mechanism will exist in devCenter
    return []
}

const hasEditorPlatformPart = function (appData) {
    const editorPlatformScript = _.get(appData, 'appFields.platform.editorScriptUrl')
    return !!editorPlatformScript
}

const hasViewerPlatformPart = function (appData) {
    const viewerScriptUrl = _.get(appData, 'appFields.platform.viewerScriptUrl')
    return !!viewerScriptUrl
}

const computeHidePage = (widgetData, isHidden: boolean, defaultValue) =>
    computeHidePageInternal(widgetData, isHidden, defaultValue)
const isMultiSectionInstanceEnabled = function (appData, widgetId: string) {
    if (!widgetId) {
        return false
    }
    const widgetData = _.get(appData, ['widgets', widgetId])
    return _.get(widgetData, 'appPage.multiInstanceEnabled')
}

const setClientSpecMapReadyOnLoad = (ps: PS) => {
    clientSpecMapReadyOnLoad = true
    _.forEach(callbacksForClientSpecMapReadyOnLoad, cb => cb(ps))
}

const registerToClientSpecMapOnLoad = (ps: PS, callback: Callback1<PS>) => {
    if (clientSpecMapReadyOnLoad) {
        callback(ps)
    }
    callbacksForClientSpecMapReadyOnLoad.push(callback)
}

const getAppDataListByAppDefinitionIds = (ps: PS, appDefinitionIds: AppDefinitionId[]) => {
    return appDefinitionIds.map(appDefinitionId => clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId))
}

const registerAndSetInstallState = (ps: PS, newAppData) => {
    const appDefId = _.get(newAppData, 'appDefinitionId')
    if (!appDefId) {
        return
    }

    clientSpecMapDS.registerAppData(ps, newAppData)

    if (isAppPermissionsIsRevoked(newAppData)) {
        ps.extensionAPI.appsInstallState.setAppUninstalled(appDefId, 'registerAndSetInstallState')
    } else {
        ps.extensionAPI.appsInstallState.setAppInstalled(appDefId, 'registerAndSetInstallState')
    }
}

export default {
    // TODO: remove duplicates of clientSpecMapDS methods
    registerAppData: clientSpecMapDS.registerAppData,
    registerAndSetInstallState,
    getAppData: clientSpecMapDS.getAppData,
    getAppDataByAppDefinitionId: clientSpecMapDS.getAppDataByAppDefinitionId,
    getAppDataListByAppDefinitionIds,
    getAppsData: clientSpecMapDS.getAppsData,
    getAppsDataWithPredicate: clientSpecMapDS.getAppsDataWithPredicate,
    filterAppsDataByType: clientSpecMapDS.filterAppsDataByType,
    getApplicationIdFromAppDefinitionId: clientSpecMapDS.getApplicationIdFromAppDefinitionId,
    getAppDefinitionIdFromApplicationId: clientSpecMapDS.getAppDefinitionIdFromApplicationId,
    getAppVersion: clientSpecMapDS.getAppVersion,
    filterApps,
    getLargestApplicationId,
    getWidgetData,
    getAppWorkerUrl,
    getExtensionsWidgets,
    isHybridAppAndEditorPartNotDismissed,
    isHybridApp,
    isHybridAppFromAppData,
    isDashboardAppOnly,
    isEditorOrHybridApp,
    isEditorApp,
    setAppsData,
    isAppPermissionsIsRevoked,
    isAppActive: isAppActiveByState,
    isAppProvisioned,
    isAppUnused,
    isAppAutoRevoked,
    isPremiumApp,
    hasPremiumOffering,
    hasHiddenPages,
    getAppSections,
    getAppSectionsToInstall,
    widgetsToAutoAddToSite,
    getWidgetDataFromTPAPageId,
    getWidgetDataFromTPAWidgetId,
    setIsInDevMode,
    isWidgetHasMobileUrl,
    isAppPermissionsGrantedOnCSM,
    hasSections,
    isAppProvisionedOnServer,
    hasMainSection,
    getMainSectionWidgetData,
    getMainSectionWidgetDataFromApplicationId,
    getMainSectionWidgetDataFromAppDefinitionId,
    getSectionsWidgetIdsToPreFetch,
    isDemoAppAfterProvision,
    isSuperAppByCompId,
    getDependentApps,
    hasEditorPlatformPart,
    hasViewerPlatformPart,
    isPageMarkedAsAutoAddToSite,
    isPageMarkedAsShouldDeleteAppWhenDeleted,
    computeHidePage,
    isMultiSectionInstanceEnabled,
    setClientSpecMapReadyOnLoad,
    registerToClientSpecMapOnLoad
}
