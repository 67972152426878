import type {
    VirtualStructureEntryItem,
    ConversionDal,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginContext,
    PluginHeuristicsRegistry,
    StructureIterators,
    StructureStageData
} from '../../types'
import {createVirtualGroupMap, groupingAIResult} from './groupingMap'
import type {GroupingApiPlugin, GroupingResult, SectionData, SendRequest} from './types'
import {CTX_AI_GROUPING_RESULT, CTX_SERVER, CTX_SECTION_DATA} from './constants'
import {ReportableError} from '@wix/document-manager-utils'
import type {CoreLogger} from '@wix/document-manager-core'
import _ from 'lodash'
import type {StructureEntryItem} from '../../conversionDal/types'
import {getStructurePointer, getVirtualStructurePointer} from '../../conversionDal/pointerUtils'

export const createPlugin = (pluginInitValues: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const {stages} = pluginInitValues

    const createApi = (context: PluginContext): GroupingApiPlugin => {
        return {
            registerServer: (server: SendRequest) => {
                context.set(CTX_SERVER, server)
            },
            getAiGroupTree: (): GroupingResult => {
                return context.get(CTX_AI_GROUPING_RESULT)
            },
            getSectionDataInput: (): SectionData => {
                return context.get(CTX_SECTION_DATA)
            }
        }
    }

    const validateChildParent = (
        mobileConversionDal: ConversionDal,
        logger: CoreLogger,
        parentId: string,
        realChildrenOrdered: string[]
    ) => {
        const errors: ReportableError[] = []
        realChildrenOrdered.forEach(child => {
            const childParent = mobileConversionDal.get(getStructurePointer({id: child})).parent
            if (!_.isEqual(childParent, parentId)) {
                const error = new ReportableError({
                    message: `child is assigned to intruder`,
                    errorType: 'groupingIntruder',
                    extras: {
                        componentId: child,
                        intruder: parentId
                    }
                })
                logger.captureError(error)
                errors.push(error)
            }
        })

        return errors
    }

    const validateGroupingResult = (
        mobileConversionDal: ConversionDal,
        structureIterators: StructureIterators,
        componentToChildrenGroupMap: Record<string, Partial<VirtualStructureEntryItem>>
    ) => {
        let errors: ReportableError[] = []

        structureIterators.forEachComponent((comp: StructureEntryItem) => {
            errors = errors.concat(
                validateChildParent(
                    mobileConversionDal,
                    pluginInitValues.logger,
                    comp.id,
                    componentToChildrenGroupMap[comp.id]?.realChildren ?? []
                )
            )
        })

        if (errors.length > 0) {
            throw new ReportableError({
                message: 'child is assigned to different parent',
                errorType: 'illegalComponentStructure',
                extras: {errors}
            })
        }
    }

    const createGroupingMap = async (
        {conversionDal, structureIterators, pageId, config}: StructureStageData,
        contextHelper: PluginContext
    ) => {
        const serverResponse = await groupingAIResult(
            structureIterators,
            pageId,
            pluginInitValues,
            contextHelper,
            config
        )
        contextHelper.set(CTX_AI_GROUPING_RESULT, serverResponse)
        const componentToChildrenGroupMap = createVirtualGroupMap(serverResponse, conversionDal)
        validateGroupingResult(conversionDal, structureIterators, componentToChildrenGroupMap)

        for (const [id, data] of Object.entries(componentToChildrenGroupMap)) {
            if (conversionDal.get(getStructurePointer({id}))) {
                conversionDal.modify(getStructurePointer({id}), value => ({
                    ...value,
                    virtualParentId: data.parentId
                }))
            }

            conversionDal.set(getVirtualStructurePointer({id}), data)
        }
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.ANALYZE, createGroupingMap)
    }

    return {register, createApi, name: 'grouping', dependencies: []}
}
