import type {Rect} from '@wix/document-services-types'
export const createAxisAlignedEnvelope = (rect: Rect, degrees: number): Rect => {
    const rad = degrees * (Math.PI / 180)
    const halfWidth = rect.width / 2
    const halfHeight = rect.height / 2
    const centerX = rect.x + halfWidth
    const centerY = rect.y + halfHeight
    const corners = [
        {x: -halfWidth, y: -halfHeight},
        {x: halfWidth, y: -halfHeight},
        {x: halfWidth, y: halfHeight},
        {x: -halfWidth, y: halfHeight}
    ].map(corner => ({
        x: centerX + (corner.x * Math.cos(rad) - corner.y * Math.sin(rad)),
        y: centerY + (corner.x * Math.sin(rad) + corner.y * Math.cos(rad))
    }))
    const minX = Math.min(...corners.map(corner => corner.x))
    const maxX = Math.max(...corners.map(corner => corner.x))
    const minY = Math.min(...corners.map(corner => corner.y))
    const maxY = Math.max(...corners.map(corner => corner.y))

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    }
}
