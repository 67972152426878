const {createDataItem, createMobileVariantRelation, createRefArray} = require('./utils')
const constants = require('../../helpers/constants')
const _ = require('lodash')
const {stripHashIfExists} = require('../../helpers/dataUtils')
const {NAMESPACE_MAPPING} = require('@wix/document-services-json-schemas').namespaceMapping

const {DESKTOP, MOBILE, VARIANT_RELATION_TYPE, TRANSFORM_DATA_TYPE} = constants
const TRANSFORMATIONS_DATA_NAMESPACE = NAMESPACE_MAPPING.transformations
const TRANSFORMATIONS_NAMESPACE = 'transformations'

const addTransformationsData = pageJson => {
    if (!pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE]) {
        pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE] = {}
    }
}

const createTransformationsRefArray = createRefArray(TRANSFORMATIONS_NAMESPACE, TRANSFORMATIONS_DATA_NAMESPACE)
const createTransformationsDataItem = createDataItem(TRANSFORMATIONS_NAMESPACE, TRANSFORMATIONS_DATA_NAMESPACE)
const createTransformationsMobileRelation = createMobileVariantRelation(
    TRANSFORMATIONS_NAMESPACE,
    TRANSFORMATIONS_DATA_NAMESPACE
)

const collectTransformations = (transformationsData, transformationQuery) => {
    const transformationsRefArray = _.cloneDeep(transformationsData?.[stripHashIfExists(transformationQuery)])
    const {values} = transformationsRefArray
    const transformationsItems = []

    for (let i = 0; i < values.length; i++) {
        const transformationsId = stripHashIfExists(values[i])
        const transformationsItem = _.cloneDeep(transformationsData[transformationsId])

        if (transformationsItem.type === TRANSFORM_DATA_TYPE) {
            transformationsItems.push({item: transformationsItem})
        } else if (transformationsItem.type === VARIANT_RELATION_TYPE) {
            const variantRelationTransformationId = stripHashIfExists(transformationsItem.to)
            transformationsItem.to = _.cloneDeep(transformationsData[variantRelationTransformationId])
            transformationsItems.push({item: transformationsItem.to, variants: transformationsItem.variants})
        }
    }
    return transformationsItems
}

const createTransformations = (conversionResult, componentId, conversionContext) => {
    const refArray = createTransformationsRefArray(conversionContext)

    if (conversionResult.default?.transformations?.length) {
        const defaultTransformations = createTransformationsDataItem(
            conversionContext,
            conversionResult.default.transformations[0].item
        )
        refArray.values.push(`#${defaultTransformations.id}`)
    }

    if (conversionResult.mobile?.transformations?.length) {
        const mobileTransformations = createTransformationsDataItem(
            conversionContext,
            conversionResult.mobile.transformations[0].item
        )
        const mobileRelation = createTransformationsMobileRelation(conversionContext, {
            to: `#${mobileTransformations.id}`,
            from: `#${componentId}`
        })
        refArray.values.push(`#${mobileRelation.id}`)
    }

    return refArray.id
}

const createTransformationsMobileVariant = (
    conversionContext,
    transformationsData,
    componentId,
    refArray,
    transformationsVariants
) => {
    const mobileTransformationsItem = createTransformationsDataItem(conversionContext, transformationsData)
    const mobileRelation = createTransformationsMobileRelation(conversionContext, {
        to: `#${mobileTransformationsItem.id}`,
        from: `#${componentId}`
    })
    if (transformationsVariants) {
        mobileRelation.variants = [...mobileRelation.variants, ...transformationsVariants]
    }
    refArray.values.push(`#${mobileRelation.id}`)

    return mobileRelation
}

const overrideTransformations = (conversionResult, componentId, conversionContext) => {
    const {pageJson, allComponents} = conversionContext

    const desktopComponent = allComponents[DESKTOP][componentId]
    const mobileComponent = allComponents[MOBILE][componentId]

    const refArray =
        pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE][
            desktopComponent?.transformationQuery ?? mobileComponent?.transformationQuery
        ]

    if (conversionResult.default?.transformations?.length) {
        const {transformations} = conversionResult.default
        for (let i = 0; i < transformations.length; i++) {
            pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE][transformations[i].item.id] = transformations[i].item
        }
    }

    if (conversionResult.mobile?.transformations?.length) {
        const {transformations} = conversionResult.mobile
        for (let i = 0; i < transformations.length; i++) {
            delete transformations[i].item.id
            createTransformationsMobileVariant(
                conversionContext,
                transformations[i].item,
                componentId,
                refArray,
                transformations[i].variants
            )
        }
    }

    return refArray.id
}

const addTransformationsToComponent = (desktopComponent, mobileComponent, refArrayId) => {
    if (desktopComponent) {
        desktopComponent.transformationQuery = `#${refArrayId}`
    }
    if (mobileComponent) {
        mobileComponent.transformationQuery = `#${refArrayId}`
    }
}

const updateTransformations = (conversionResult, componentId, conversionContext) => {
    if (!conversionResult.default.transformations?.length && !conversionResult.mobile.transformations?.length) return

    const {allComponents} = conversionContext

    const desktopComponent = allComponents[DESKTOP][componentId]
    const mobileComponent = allComponents[MOBILE][componentId]

    let refArrayId
    if (!desktopComponent?.transformationQuery && !mobileComponent?.transformationQuery) {
        refArrayId = createTransformations(conversionResult, componentId, conversionContext)
    } else {
        refArrayId = overrideTransformations(conversionResult, componentId, conversionContext)
    }

    addTransformationsToComponent(desktopComponent, mobileComponent, refArrayId)
}

module.exports = {
    addTransformationsData,
    collectTransformations,
    updateTransformations
}
