import * as jsonSchemas from '@wix/document-services-json-schemas'
import constants from '../constants/constants'
import namespaces from '../namespaces/namespaces'
import experiment from 'experiment-amd'
import type {Pointer, PS, ValueOf} from '@wix/document-services-types'
import {eventHooks} from '../hooks/eventHooks/eventHooks'
import {DATA_MODEL_HOOKS} from '@wix/document-manager-extensions/src/hooks'

const {
    namespaceMapping: {featuresData}
} = jsonSchemas

const FEATURE_TYPES = {
    COMPONENT: 'Component',
    PAGE: 'Page',
    SITE: 'Site',
    REPEATER: 'Repeater'
} as const

export type FeatureType = ValueOf<typeof FEATURE_TYPES>

const validPageLevelTypes = ['Page', 'Document']

export type FeatureData = Record<string, any> & {
    type?: string
}

const validateFeatureType = (ps: PS, compPointer: Pointer, namespace: string, expectedFeatureType: string) => {
    if (!featuresData[namespace]) {
        throw new Error(`feature ${namespace} doesn't exist`)
    }

    const {featureType} = featuresData[namespace]

    if (featureType !== expectedFeatureType) {
        throw new Error(`feature ${namespace} cannot be used through ${expectedFeatureType} feature APIs`)
    }
    if (featureType === FEATURE_TYPES.COMPONENT) {
        // all components can use a Component feature
        return
    }
    const type = ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['type']))

    if (featureType === FEATURE_TYPES.PAGE && !validPageLevelTypes.includes(type)) {
        throw new Error(`feature ${namespace} cannot be used for a non-page component`)
    }

    if (featureType === FEATURE_TYPES.SITE && type !== 'Document') {
        throw new Error(`feature ${namespace} is a site feature, and cannot be used for a page or component`)
    }

    if (featureType === FEATURE_TYPES.REPEATER && type !== 'RepeaterContainer') {
        throw new Error(
            `feature ${namespace} is a repeater feature, and cannot be used for any other type of component`
        )
    }
}

const featureDataUpdater = (featureType: FeatureType) => {
    /**
     * @param ps
     * @param compPointer
     * @param featureName the data namespace
     * @param featureData the data itself, the default type if unspecified will be the namespace
     */
    function updateFeatureData(ps: PS, compPointer: Pointer, featureName: string, featureData: FeatureData) {
        validateFeatureType(ps, compPointer, featureName, featureType)
        const namespaceData = namespaces.updateNamespaceData(ps, compPointer, featureName, featureData)
        eventHooks(ps).executeHook(
            DATA_MODEL_HOOKS.FEATURE_DATA_UPDATE.AFTER.createEvent({compPointer, featureName, featureData})
        )
        return namespaceData
    }

    return updateFeatureData
}

const featureDataGetter = (featureType: FeatureType) => {
    function getFeatureData(ps: PS, compPointer: Pointer, featureName: string) {
        if (featureName === FEATURE_TYPES.COMPONENT && experiment.isOpen('dm_useFeaturesExt')) {
            return ps.extensionAPI.features.component.get(compPointer)
        }
        if (featureType === FEATURE_TYPES.SITE && experiment.isOpen('dm_useFeaturesExt')) {
            return ps.extensionAPI.features.site.get(featureName) || null
        }
        validateFeatureType(ps, compPointer, featureName, featureType)
        return namespaces.getNamespaceData(ps, compPointer, featureName)
    }

    return getFeatureData
}

const featureDataRemover = (featureType: FeatureType) => {
    function removeFeatureData(ps: PS, compPointer: Pointer, featureName: string) {
        validateFeatureType(ps, compPointer, featureName, featureType)
        namespaces.removeComponentNamespaceData(ps, compPointer, featureName)
    }

    return removeFeatureData
}

const bindToMasterPageComponent =
    func =>
    (ps: PS, ...args) =>
        func(ps, ps.pointers.components.getMasterPage(constants.VIEW_MODES.DESKTOP), ...args)

function getAllCompsInPageBasedOnFeatureType(ps: PS, featureName: string, pageId: string, viewMode: string) {
    return ps.extensionAPI.page.getOwnersOfItemsBasedOnQueryName(featureName, pageId, viewMode)
}

export default {
    getFeatureData: featureDataGetter(FEATURE_TYPES.COMPONENT),
    updateFeatureData: featureDataUpdater(FEATURE_TYPES.COMPONENT),
    removeComponentFeatureData: featureDataRemover(FEATURE_TYPES.COMPONENT),
    repeater: {
        get: featureDataGetter(FEATURE_TYPES.REPEATER),
        update: featureDataUpdater(FEATURE_TYPES.REPEATER),
        remove: featureDataRemover(FEATURE_TYPES.REPEATER)
    },
    page: {
        get: featureDataGetter(FEATURE_TYPES.PAGE),
        update: featureDataUpdater(FEATURE_TYPES.PAGE),
        remove: featureDataRemover(FEATURE_TYPES.PAGE)
    },
    site: {
        //these methods do not receive a pointer in the argument
        get: bindToMasterPageComponent(featureDataGetter(FEATURE_TYPES.SITE)),
        update: bindToMasterPageComponent(featureDataUpdater(FEATURE_TYPES.SITE)),
        remove: bindToMasterPageComponent(featureDataRemover(FEATURE_TYPES.SITE))
    },
    getAllCompsInPageBasedOnFeatureType
}
