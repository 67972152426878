import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {containableByFullWidthPopup} from '../utils/pageUtils'

export const stripGallerySlideShowMetaData = {
    fullWidthByStructure: true,
    fullWidth: true,
    containableByStructure: (createExtArgs: CreateExtArgs, compStructure: CompStructure, containerPointer: Pointer) =>
        containableByFullWidthPopup(createExtArgs, containerPointer)
}
