import _ from 'lodash'
import {
    CreateExtArgs,
    CreateExtensionArgument,
    DalValue,
    DmApis,
    DmStore,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    ModelsInitializationType,
    PointerMethods,
    pointerUtils,
    ValidatorMap
} from '@wix/document-manager-core'
import type {AppDefinitionId, Pointer} from '@wix/document-services-types'
import {ReportableWarning, TPA_CONSTANTS} from '@wix/document-manager-utils'
import type {PageExtensionAPI} from './page'
import {DATA_TYPES} from '../constants/constants'
import {
    hasEditorPlatformPart,
    isAppControllerData,
    isTpaByDataType,
    isUnifiedComponentData
} from './tpa/installedTpaAppsUtils'
import type {RMApi} from './rendererModel'
import {deepClone} from '@wix/wix-immutable-proxy'

const {getPointer} = pointerUtils
const pointerType = 'platform'
const pagesPlatformPointerType = 'pagesPlatformApplications'
const semanticAppVersionsPointerType = 'semanticAppVersions'
const appsStatePointerType = 'appsState'

export interface PlatformExtensionAPI extends ExtensionAPI {
    platform: {
        getAllAppsInstalledOnPages(): Set<AppDefinitionId>
        pagesPlatformApplication: {
            updatePagePlatformApp(pageRef: Pointer, appDefinitionId: AppDefinitionId, value: any): void
        }
        getAppDefinitionIdFromApplicationId(applicationId: string, info: Record<string, any>): AppDefinitionId
    }
}

const createPointersMethods = (): PointerMethods => {
    const getPlatformPointer = () => getPointer('platform', pointerType)
    const getAppManifestPointer = (appDefinitionId: string) =>
        getPointer('platform', pointerType, {innerPath: ['appManifest', appDefinitionId]})
    const appPublicApiNamePointer = (appDefinitionId: string) =>
        getPointer('platform', pointerType, {innerPath: ['appPublicApiName', appDefinitionId]})
    const appPrivateApiNamePointer = (appDefinitionId: string) =>
        getPointer('platform', pointerType, {innerPath: ['appPrivateApiName', appDefinitionId]})
    const appEditorApiNamePointer = (appDefinitionId: string) =>
        getPointer('platform', pointerType, {innerPath: ['appEditorApiName', appDefinitionId]})
    const getAppStatePointer = () => getPointer('appState', pointerType)
    const getControllerStatePointer = (controllerId: string) =>
        getPointer('appState', pointerType, {innerPath: [controllerId]})
    const getControllerStageDataPointer = (appDefinitionId: string, controllerType: string, controllerState: string) =>
        getPointer('platform', pointerType, {
            innerPath: ['appManifest', appDefinitionId, 'controllersStageData', controllerType, controllerState]
        })
    const getControllerRolePointer = (
        appDefinitionId: string,
        controllerType: string,
        controllerState: string,
        role: string
    ) =>
        getPointer('platform', pointerType, {
            innerPath: [
                'appManifest',
                appDefinitionId,
                'controllersStageData',
                controllerType,
                controllerState,
                'connections',
                role
            ]
        })

    const getPagesPlatformApplicationsPointer = () => getPointer('pagesPlatformApplications', pagesPlatformPointerType)
    const getPagesPlatformApplicationPointer = (appDefinitionId: string) =>
        getPointer('pagesPlatformApplications', pagesPlatformPointerType, {innerPath: [appDefinitionId]})

    const getSemanticAppVersionPointer = (appDefinitionId: string) =>
        getPointer('semanticAppVersions', semanticAppVersionsPointerType, {innerPath: [appDefinitionId]})
    const getSemanticAppVersionsPointer = () => getPointer('semanticAppVersions', semanticAppVersionsPointerType)

    const getAppInstallationStatePointer = (appDefinitionId: string) =>
        getPointer('appsState', appsStatePointerType, {innerPath: [appDefinitionId]})
    const getAppsInstallationStatePointer = () => getPointer('appsState', appsStatePointerType)

    return {
        platform: {
            getControllerRolePointer,
            getPlatformPointer,
            getAppManifestPointer,
            appPublicApiNamePointer,
            appPrivateApiNamePointer,
            appEditorApiNamePointer,
            getPagesPlatformApplicationPointer,
            getPagesPlatformApplicationsPointer,
            getControllerStageDataPointer,
            getControllerStatePointer,
            getAppStatePointer,
            getSemanticAppVersionPointer,
            getSemanticAppVersionsPointer,
            getAppInstallationStatePointer,
            getAppsInstallationStatePointer
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [pointerType]: {},
    [pagesPlatformPointerType]: {},
    [appsStatePointerType]: {}
})

const initialState = {
    [appsStatePointerType]: {
        [appsStatePointerType]: {}
    }
}

const initializeModels = (initialStore: DmStore, initialModels: ModelsInitializationType, {pointers}: DmApis) => {
    initialStore.set(
        pointers.platform.getPagesPlatformApplicationsPointer(),
        initialModels.rendererModel.pagesPlatformApplications
    )
}

const createExtension = ({experimentInstance}: CreateExtensionArgument): Extension => {
    const createValidator = ({dal, pointers}: DmApis): ValidatorMap => {
        const appsRelatedDataTypes = [
            ..._.values(TPA_CONSTANTS.DATA_TYPE),
            'Page',
            'CustomMenu',
            'PagesGroupData',
            'AppController',
            'CustomElement'
        ]

        const validateAppsOnComps = (pointer: Pointer, value: DalValue) => {
            const {type, appDefinitionId, appDefId, applicationId, appId, tpaApplicationId} = value ?? {}
            const applicationIdOnData = applicationId ?? appId ?? tpaApplicationId
            const appDefIdOnData = appDefId ?? appDefinitionId

            if (pointer.type !== 'data' || !_.includes(appsRelatedDataTypes, type) || !applicationIdOnData) {
                return
            }

            const missingAppDefinitionId = isAppControllerData(value)
                ? _.isNil(applicationIdOnData)
                : _.isNil(appDefIdOnData)

            const appData =
                dal.get(pointers.general.getClientSpecMapEntry(applicationIdOnData)) ??
                dal.get(pointers.general.getClientSpecMapEntryByAppDefId(appDefIdOnData))

            if (appData && missingAppDefinitionId) {
                return [
                    {
                        shouldFail: experimentInstance.isOpen('dm_missingAppDefIdOnDataError'),
                        type: 'missingAppDefIdOnDataError',
                        message: 'missing appDefinitionId on data item',
                        extras: {
                            type,
                            pointer,
                            applicationId: applicationIdOnData,
                            appDefinitionId: appDefIdOnData
                        }
                    }
                ]
            }
        }

        return {
            validateAppsOnComps
        }
    }

    const createExtensionAPI = ({pointers, dal, extensionAPI, coreConfig}: CreateExtArgs): PlatformExtensionAPI => {
        const updatePagePlatformApp = (pageRef: Pointer, appDefinitionId: AppDefinitionId, value: any) => {
            const pageId = pageRef.id
            const pagesPlatformApplicationPointer =
                pointers.platform.getPagesPlatformApplicationPointer(appDefinitionId)
            const applicationPages = deepClone(dal.get(pagesPlatformApplicationPointer)) || {}

            delete applicationPages[pageId]
            if (value) {
                _.set(applicationPages, pageId, true)
            }
            if (_.isEmpty(applicationPages)) {
                if (dal.has(pagesPlatformApplicationPointer)) {
                    dal.remove(pagesPlatformApplicationPointer)
                }
                return
            }
            dal.set(pagesPlatformApplicationPointer, applicationPages)
        }

        const handleMigrationError = (extras: Record<string, any>, tags: Record<string, any>) => {
            coreConfig.logger.captureError(
                new ReportableWarning({
                    errorType: 'appIdMigrationToAppDefIdError',
                    message: 'error on migration process from appId to appDefId',
                    extras,
                    tags
                })
            )
        }

        const getAppDefinitionIdFromApplicationId = (applicationId: string, info: Record<string, any>) => {
            try {
                const csmEntry = dal.get(pointers.general.getClientSpecMapEntry(applicationId.toString()))
                return csmEntry.appDefinitionId
            } catch (e) {
                handleMigrationError({applicationId, info}, {appDefIdToAppId: true})
            }
        }
        return {
            platform: {
                getAllAppsInstalledOnPages: () => {
                    const {page} = extensionAPI as PageExtensionAPI
                    const {rendererModel} = extensionAPI as RMApi
                    const clientSpecMap = rendererModel.getClientSpecMap()
                    const dataItems = _.values(dal.query(DATA_TYPES.data, page.getAllPagesIndexId()))
                    return dataItems.reduce((allAppDefIds, dataItem) => {
                        const appDefIdOnData = isAppControllerData(dataItem)
                            ? dataItem?.applicationId
                            : dataItem?.appDefinitionId ?? dataItem?.appDefId

                        if (!appDefIdOnData) {
                            return allAppDefIds
                        }

                        const isTpaApp = isTpaByDataType(dataItem.type)
                        const isUnifiedComponent = isUnifiedComponentData(dataItem.type)

                        if (!isTpaApp && !isUnifiedComponent) {
                            const appData = _.find(
                                clientSpecMap,
                                app => _.get(app, ['appDefinitionId']) === appDefIdOnData
                            )
                            const isActive = !_.isNil(appData) && hasEditorPlatformPart(appData)
                            if (!isActive) {
                                return allAppDefIds
                            }
                        }
                        allAppDefIds.add(appDefIdOnData)
                        return allAppDefIds
                    }, new Set<string>())
                },
                pagesPlatformApplication: {
                    updatePagePlatformApp
                },
                getAppDefinitionIdFromApplicationId
            }
        }
    }
    return {
        name: 'platform',
        createExtensionAPI,
        createPointersMethods,
        getDocumentDataTypes,
        initializeModels,
        createValidator,
        initialState
    }
}
export {createExtension}
