import _ from 'lodash'
import type {FetchPagesToDalFunction, Host} from '@wix/document-manager-host-common'
import {experimentInst, rendererModel, manipulatedRendererModel, queryUtil} from './early-deps'

const {documentServicesModel, wixBiSession, serviceTopology, viewerBase, dmBase} = window
window.queryUtil = queryUtil

import {startTb} from './loadTb'
import {
    registerAjaxMethod,
    fetchFunction,
    createDocumentServicesWithHost,
    initDocumentServicesDocument,
    createViewerManagerAndExtendHost
} from '@wix/bolt-ds-adapter'
import {createThunderboltLogger} from './logger'
import {getBILoggerFactoryForFedops} from './biLoggerFactory'
import {createBILogger} from './bi'
import {coreUtils as dsCoreUtils, warmupUtils} from '@wix/santa-ds-libs'
import {guidUtils} from '@wix/santa-core-utils'

const {urlUtils} = dsCoreUtils
import type {GetViewerApiParams, ViewerAPI} from '@wix/viewer-manager-interface'
import type {DocumentManager} from '@wix/document-manager-core'
import type {ViewerExtensionAPI, PageExtensionAPI} from '@wix/document-manager-extensions'
import type {
    RendererModelFromServer,
    DocumentServicesModel,
    WixBiSession,
    PossibleViewModes,
    QueryUtils,
    DSConfig
} from '@wix/document-services-types'
import type {Client as SentryClient} from '@sentry/types'
import type {Factory} from '@wix/web-bi-logger/dist/src/logger'
import {buildViewerMockWithDefaultStore} from '@wix/viewer-mock'
import {createPanoramaFactory, PanoramaFactory} from './panoramaLoggerFactory'

interface LoadDocumentServicesAndThunderBoltResult {
    hostWithDM: Host
    documentServices: any
    viewerManager: ViewerAPI
}

declare global {
    interface Window {
        rendererModel: RendererModelFromServer
        documentServicesModel: DocumentServicesModel
        wixBiSession: WixBiSession
        serviceTopology: any

        getViewerApi(params: GetViewerApiParams): ViewerAPI

        viewerSource: string
        viewerBase: string
        manifest: {[entry: string]: string}
        queryUtil: QueryUtils
        thunderboltBase: string
        // @ts-ignore
        Sentry: SentryClient
        mainLoaded?: number
    }
}

const isSiteHistoryEndpoint = (_rawUrl: string): boolean =>
    _.includes(_rawUrl, '/html/editor/web/renderer/revisions/view')

const getExternalBaseUrl = (): string => {
    const _rawUrl = `${location.origin}${location.pathname}${(location.search || '').replace(/\+/g, '%20')}`
    const relevantPathParts = isSiteHistoryEndpoint(_rawUrl) ? 8 : 7
    return urlUtils.getBaseUrlWithPath(urlUtils.parseUrl(_rawUrl), relevantPathParts)
}

export const getViewerManagerParamsForInit = (
    fedopsFactory: Factory,
    panoramaFactory: PanoramaFactory,
    config: DSConfig
): GetViewerApiParams => {
    const {
        geo,
        locale,
        siteMetaData,
        runningExperiments,
        dataFixerExperiments,
        platformAppsExperiments,
        siteMediaToken,
        mediaAuthToken,
        siteOwnerCoBranding,
        urlFormatModel,
        sitePropertiesInfo,
        useSandboxInHTMLComp,
        userId,
        metaSiteId,
        urlMappings,
        blocksExperiments
    } = manipulatedRendererModel
    const {isResponsive} = siteMetaData
    const {multilingualInfo} = sitePropertiesInfo
    const {editorSessionId, isHttpsEnabled, mediaManagerInfo, originalTemplateId, userInfo} = documentServicesModel
    const isMobileView = config?.showMobileView && !isResponsive

    return {
        dmBase,
        isResponsive,
        fedopsFactory,
        panoramaFactory,
        isPreview: true,
        wixBiSession,
        serviceTopology,
        runningExperiments,
        dataFixerExperiments,
        platformAppsExperiments,
        geo,
        languageCode: multilingualInfo?.languageCode ?? manipulatedRendererModel.languageCode,
        locale,
        siteMediaToken,
        mediaAuthToken,
        siteOwnerCoBranding,
        urlFormatModel,
        useSandboxInHTMLComp,
        userId,
        editorSessionId,
        isHttpsEnabled,
        mediaManagerInfo,
        originalTemplateId,
        userInfo,
        viewerBase,
        externalBaseUrl: getExternalBaseUrl(),
        fetchFunction,
        metaSiteId,
        origin: config?.origin,
        viewMode: isMobileView ? 'mobile' : 'desktop',
        urlMappings,
        blocksExperiments
    }
}

const initThunderBoltModels = (
    viewerManager: ViewerAPI,
    {extensionAPI, dal}: DocumentManager,
    mainPageId: string,
    dsConfig: DSConfig
) => {
    const shouldUseViewMode = dal.get({type: 'rendererModel', id: 'siteMetaData', innerPath: ['adaptiveMobileOn']})

    const isMobileView = shouldUseViewMode && dsConfig.showMobileView

    if (isMobileView) {
        viewerManager.runtime.setWantedIsMobileView(true)
    }

    ;(extensionAPI as ViewerExtensionAPI).viewer.syncViewer({}, true)
    viewerManager.runtime.setWantedNavInfo({pageId: mainPageId})
}

const getViewerAPI = async (
    fedopsFactory: ReturnType<typeof getBILoggerFactoryForFedops>,
    panoramaFactory: PanoramaFactory,
    config: DSConfig
) => {
    const viewerSourceQuery = queryUtil.getParameterByName('viewerSource')

    if (viewerSourceQuery === 'mock') {
        return buildViewerMockWithDefaultStore()
    }

    await startTb()
    return window.getViewerApi(getViewerManagerParamsForInit(fedopsFactory, panoramaFactory, config))
}
const initDs = async (thunderboltLogger: ReturnType<typeof createThunderboltLogger>) => {
    const {hostWithDM, fetchPagesToDal} = await initDocumentServicesDocument(
        null as any,
        thunderboltLogger,
        experimentInst,
        {
            rendererModel: manipulatedRendererModel,
            documentServicesModel,
            serviceTopology
        }
    )

    const isReadOnly = !!queryUtil.getParameterByName('isReadOnly')
    registerAjaxMethod(warmupUtils.ajaxLibrary, hostWithDM.environmentContext.serverFacade, experimentInst, isReadOnly)

    return {
        fetchPagesToDal,
        hostWithDM
    }
}

const syncModelsToViewerAndInitNavigation = (
    viewerManager: ViewerAPI,
    hostWithDM: Host,
    fetchPagesToDal: FetchPagesToDalFunction
) => {
    const {extensionAPI} = hostWithDM.documentManager
    const loadPages = (extensionAPI as ViewerExtensionAPI).viewer.syncPagesToViewer

    const pageModelsFetcher = async (pageIds: string[], viewMode?: PossibleViewModes) => {
        if (hostWithDM.config.lazyLoadPages) {
            await fetchPagesToDal(pageIds)
        }

        return () => loadPages(pageIds, viewMode)
    }

    viewerManager.runtime.setPagesModelsFetcher(pageModelsFetcher)
    const mainPageIdOverride = queryUtil.getParameterByName('mainPageIdOverride')
    const pageIdsList = (extensionAPI as PageExtensionAPI).page.getAllPagesIds(false)
    const mainPageId =
        mainPageIdOverride && _.includes(pageIdsList, mainPageIdOverride)
            ? mainPageIdOverride
            : (extensionAPI as PageExtensionAPI).page.getMainPageId()
    initThunderBoltModels(viewerManager, hostWithDM.documentManager, mainPageId, hostWithDM.config)
}

const rawUrl = `${location.origin}${location.pathname}${location.search || ''}${encodeURIComponent('%20')}`
const createLoggers = () => {
    window.biLogger = createBILogger(rendererModel, documentServicesModel, dmBase, rawUrl, wixBiSession, queryUtil)
    const fedopsFactory = getBILoggerFactoryForFedops(
        rendererModel,
        documentServicesModel,
        dmBase,
        queryUtil,
        wixBiSession
    )

    const panoramaFactory = createPanoramaFactory(rendererModel)

    const thunderboltLogger = createThunderboltLogger(dmBase, wixBiSession, fedopsFactory, panoramaFactory, queryUtil)

    return {
        thunderboltLogger,
        fedopsFactory,
        panoramaFactory
    }
}

export const loadDocumentServicesAndThunderBolt = async (): Promise<LoadDocumentServicesAndThunderBoltResult> => {
    const {fedopsFactory, thunderboltLogger, panoramaFactory} = createLoggers()
    const {hostWithDM, fetchPagesToDal} = await initDs(thunderboltLogger)
    const viewerManager = await getViewerAPI(fedopsFactory, panoramaFactory, hostWithDM.config)

    const viewerManagerInstanceAndHost = await createViewerManagerAndExtendHost({hostWithDM, viewer: viewerManager})
    syncModelsToViewerAndInitNavigation(viewerManager, hostWithDM, fetchPagesToDal)

    if (hostWithDM.config.lazyLoadPages) {
        const documentServices = undefined
        viewerManager.renderFlags.setRenderFlag('isPlayingAllowed', true)
        await viewerManagerInstanceAndHost.viewerManager.viewerSiteAPI.waitForViewer()
        window.parent.postMessage('viewerLoaded', '*')
        window.didLoadDocumentServices = true
        return {hostWithDM, documentServices, viewerManager}
    }
    const documentServices = await createDocumentServicesWithHost({
        hostWithDM,
        viewerManager: viewerManager as unknown as ViewerAPI,
        coreUtils: {...dsCoreUtils, guidUtils},
        logger: thunderboltLogger,
        experimentInst,
        viewerManagerInstanceAndHost
    })
    return {hostWithDM, documentServices, viewerManager}
}
