import type {FixerNameToVersion, FixerActions} from '@wix/document-services-types'
import {type DataFixerVersioningApi, FixerCategory} from '../dataFixerVersioning/dataFixerVersioning'
import _ from 'lodash'
import {defaultRunOncePerSite} from './dataMigration/migrators'
import {type BaseMigrator, migratorsFixerVersioningConfig} from './dataMigration/dataMigrationRunner'
import type {DocumentManager} from '@wix/document-manager-core'

type SiteMigratorApi = Pick<
    DocumentManager,
    'dal' | 'extensionAPI' | 'pointers' | 'config' | 'experimentInstance' | 'logger'
>

export const runSiteMigratorsFactory = (siteMigratorApi: SiteMigratorApi) => () => {
    const {extensionAPI, experimentInstance, dal} = siteMigratorApi
    const {dataFixerVersioning} = extensionAPI as DataFixerVersioningApi
    const masterPageId = 'masterPage'

    const shouldRerunMigrator = (migrator: BaseMigrator) => migrator.fixerRequiresReruns
    const addFixerVersion = (fixerVersions: FixerNameToVersion, migrator: BaseMigrator, versionOverride?: number) => {
        const {name, version, disableVersioning} = migrator
        if (_.isFunction(disableVersioning) && disableVersioning?.(siteMigratorApi as DocumentManager)) {
            return
        }
        fixerVersions[name] = versionOverride ?? version
    }

    const fixerVersions: FixerNameToVersion = {}
    const fixerChangesOnReruns: FixerActions = {}

    defaultRunOncePerSite.forEach(migrator => {
        const {name: fixerName, version: baseVersion, experimentalVersions} = migrator
        const fixerExperiment = dataFixerVersioning.decorateExperimentWithVersions(
            experimentInstance,
            {fixerName: migrator.name},
            baseVersion,
            experimentalVersions
        )
        const {version: fixerVersion} = fixerExperiment

        if (
            dataFixerVersioning.hasFixerRunOnCurrentVersion(
                masterPageId,
                FixerCategory.MIGRATOR,
                fixerName,
                fixerVersion,
                migratorsFixerVersioningConfig
            )
        ) {
            if (!shouldRerunMigrator(migrator)) {
                return
            }
            const changes = dataFixerVersioning.executeFixerAndSetModifications(
                () => migrator.migrateSite(siteMigratorApi as DocumentManager, fixerExperiment),
                '_site_',
                fixerName,
                fixerVersion
            )
            _.merge(fixerChangesOnReruns, changes)
        } else {
            addFixerVersion(fixerVersions, migrator)
            migrator.migrateSite(siteMigratorApi as DocumentManager, fixerExperiment)
        }

        if (experimentInstance.isOpen('dm_splitFixersToMultipleSaves')) {
            dal.commitTransaction('siteMigrator', true)
        }
    })
    dataFixerVersioning.updatePageVersionData(
        masterPageId,
        {[FixerCategory.MIGRATOR]: fixerVersions},
        {[FixerCategory.MIGRATOR]: migratorsFixerVersioningConfig}
    )
    dataFixerVersioning.reportFixerActions(FixerCategory.MIGRATOR, fixerChangesOnReruns)
}
