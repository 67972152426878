import type {Pointer, PS} from '@wix/document-services-types'
import dataModel from '../../dataModel/dataModel'
import socialComp from './utils/socialComp'
import type {ComponentAfterAddDataFromExtEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

export const upgradeFacebookShareUrlOnAddition = (ps: PS, compPointer: Pointer) => {
    const compData = dataModel.getDataItem(ps, compPointer)
    const forceMainPage = compData.urlChoice && compData.urlChoice.toLowerCase() === 'site'
    socialComp.upgradeUrl(ps, compPointer, 'facebook.shares', {forceMainPage})
}
export const upgradeFacebookShareUrlOnAdditionFromExt = (ps: PS, data: ComponentAfterAddDataFromExtEvent) => {
    const {compToAddPointer} = data
    upgradeFacebookShareUrlOnAddition(ps, compToAddPointer)
}
