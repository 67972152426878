import _ from 'lodash'
import type {TextTheme} from '@wix/document-services-types'
import type {MobileAlgoConfig, PluginContext, ReadOnlyExtensionAPI, StructureIterators} from '../../types'
import {MASTER_PAGE_ID} from '../../../../constants/constants'
import {composeProtoInput, parseProtoOutput} from '../../../../utils/aiPredictionProtoUtil'
import {type EnvironmentContext, pointerUtils} from '@wix/document-manager-core'
import type {GroupingResult} from './types'
import {fetchWithAuth} from '../../../../utils/fetchUtils'
import type {StructureEntryItem} from '../../conversionDal/types'
import {CTX_SECTION_DATA} from './constants'

const {getPointer} = pointerUtils

interface Offset {
    x: number
    y: number
}

interface Layout {
    x: number
    y: number
    width: number
    height: number
    top: number
    left: number
    bottom: number
    right: number
}

export interface ComponentInput {
    id: string
    type: string
    componentType: string
    components: string[]
    data: any
    fixed_position: boolean
    in_footer: boolean
    in_header: boolean
    in_master: boolean
    is_responsive: boolean
    is_visible: boolean
    pageId: string
    parent: string
    url?: string
}

export const sendRequest = async (
    readOnlyExtensionAPI: ReadOnlyExtensionAPI,
    environmentContext: EnvironmentContext,
    serverInput: any
): Promise<GroupingResult> => {
    const endpoint = '/_api/predictor-service//v1/invoke/model/layout-sectiongrouping-2-8582'
    const instance = readOnlyExtensionAPI.getInstance()
    const {fetchFn} = environmentContext
    const result = await fetchWithAuth(endpoint, instance, fetchFn, 'POST', serverInput)
    return parseProtoOutput(result.output.values.GroupTree)
}

const transformLayouts = (
    readOnlyExtensionAPI: ReadOnlyExtensionAPI,
    layoutMap: Record<string, Layout>,
    config: MobileAlgoConfig
) => {
    _.forEach(layoutMap, (layout: Layout, id: string) => {
        const isFullWidth = readOnlyExtensionAPI.isFullWidth(getPointer(id, 'DESKTOP'))
        if (isFullWidth) {
            layout.width = config.stageWidth
            layout.right = config.stageWidth
        }
    })
}

const createComponentMaps = (structureIterators: StructureIterators, pageId: string) => {
    let zIndex = 0
    const compMap: Record<string, ComponentInput> = {}
    const layoutMap: Record<string, Layout> = {}
    const zIndexMap: Record<string, number> = {}
    structureIterators.forEachComponent((comp: StructureEntryItem) => {
        const parentLayout = layoutMap[comp.parent]
        const parentOffset: Offset = {
            x: parentLayout?.x ?? 0,
            y: parentLayout?.y ?? 0
        }

        compMap[comp.id] = {
            id: comp.id,
            type: comp.type,
            componentType: comp.componentType,
            components: comp.components ?? [],
            data: comp.data,
            url: comp.media?.uri,
            fixed_position: false,
            is_visible: true,
            in_header: false,
            in_footer: false,
            is_responsive: false,
            in_master: pageId === MASTER_PAGE_ID,
            parent: comp.parent,
            pageId
        }

        const envelope = {...comp.axisAlignedEnvelope}

        layoutMap[comp.id] = {
            x: envelope.x + parentOffset.x,
            y: envelope.y + parentOffset.y,
            width: envelope.width,
            height: envelope.height,
            top: envelope.y + parentOffset.y,
            left: envelope.x + parentOffset.x,
            bottom: envelope.y + envelope.height + parentOffset.y,
            right: envelope.x + parentOffset.x + envelope.width
        }

        zIndexMap[comp.id] = zIndex++
    })

    return {compMap, layoutMap, zIndexMap}
}

const createSectionDataInput = (
    structureIterators: StructureIterators,
    pageId: string,
    readOnlyExtensionAPI: ReadOnlyExtensionAPI,
    config: MobileAlgoConfig
) => {
    const {compMap, layoutMap, zIndexMap} = createComponentMaps(structureIterators, pageId)
    transformLayouts(readOnlyExtensionAPI, layoutMap, config)

    const homePageId = readOnlyExtensionAPI.getMainPageId()
    const fontMap = Object.values(readOnlyExtensionAPI.getTextTheme()).map(
        (font: TextTheme) =>
            `${font.fontStyle} ${font.fontVariant} ${font.fontWeight} ${font.fontSize}/${font.lineHeight} ${font.fontFamily} {${font.color}}`
    )
    const siteDataInput = {
        components: compMap,
        globalData: {
            pageId,
            homePageId,
            isHomePage: pageId === homePageId,
            isTemplate: readOnlyExtensionAPI.isTemplate(),
            fontMap
        }
    }
    const domDataInput = {
        domLayout: layoutMap,
        isVisibleList: {},
        textLayout: {},
        zIndexMap,
        contentWidth: config.stageWidth
    }

    return {
        siteData: siteDataInput,
        domData: domDataInput
    }
}

const createComposeProtoInput = (sectionData: {siteData: any; domData: any}) => composeProtoInput(sectionData)

export const createServerRequest = (
    structureIterators: StructureIterators,
    pageId: string,
    readOnlyExtensionAPI: ReadOnlyExtensionAPI,
    config: MobileAlgoConfig,
    contextHelper: PluginContext
) => {
    const metaSiteId = readOnlyExtensionAPI.getMetaSiteId()
    const siteId = readOnlyExtensionAPI.getSiteId()
    const sectionData = createSectionDataInput(structureIterators, pageId, readOnlyExtensionAPI, config)
    contextHelper.set(CTX_SECTION_DATA, sectionData)
    const composeSectionData = createComposeProtoInput(sectionData)
    return {
        features: {
            values: {
                SectionData: composeSectionData,
                meta_site_id: {strVal: metaSiteId},
                page_id: {strVal: siteId},
                section_name: {strVal: ''},
                revision: {strVal: 'NA'}
            }
        }
    }
}
