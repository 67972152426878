import type {Experiment} from '@wix/document-services-types'

const shouldApplySkinTheme = (compType: string, definition: {isStylableComp?: boolean}, experiment: Experiment) =>
    !definition?.isStylableComp ||
    (compType === 'wixui.StylableButton' && experiment.isOpen('dm_migrateStylableButtonToNonStylable')) ||
    (compType === 'wixui.CollapsibleText' && experiment.isOpen('dm_migrateCollapsibleTextToNonStylable'))

const getComponentStylableName = (compType: string) => compType.split('.').pop()

const createEmptyStylableStylesheet = (compType: string) => {
    const compName = getComponentStylableName(compType)
    return `:import {
    -st-from: 'wix-ui-santa/index.st.css';
    -st-named: ${compName};
}
.root{
    -st-extends: ${compName};
}`
}

export {shouldApplySkinTheme, getComponentStylableName, createEmptyStylableStylesheet}
