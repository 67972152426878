import type {CompRef, Pointer, PS, DeserializationMappers, RemoveOverridesOptions} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import * as dmCore from '@wix/document-manager-core'
import dataModel from '../dataModel/dataModel'
import component from '../component/component'
import componentCode from '../component/componentCode'
import documentModeInfo from '../documentMode/documentModeInfo'
import constants from '../constants/constants'
import design from '../variants/design'
import repeaterUtils from '../utils/repeater'
import {inflationUtils, refStructureUtils} from '@wix/document-manager-extensions'
const {createRefCompQuery, getItemQueryId, refCompDelimiter, createReferredId, isSharedPartsId} = inflationUtils
import {replaceVariantsOnSerializedOverrides} from '../variants/variantsReplacementUtils'
import dsUtils from '../utils/utils'
const {extractBaseComponentId} = refStructureUtils
const {displayedOnlyStructureUtil} = santaCoreUtils
const {addRepeatedItemsDataOverridesIfNeeded} = repeaterUtils
const {DATA_TYPES} = constants
const CONNECTIONS_ITEM_TYPE = 'connections'
const PROPERTIES_ITEM_TYPE = 'props'
const INTERNAL_REF_TYPES = new Set(['InternalRef', 'InternalBlocksRef'])

const isMobilePointer = (compPointer: Pointer) => compPointer.type === constants.VIEW_MODES.MOBILE

const cleanAndAddOverridenConnections = (
    ps: PS,
    pageId: string,
    dataItem,
    newDataItemId: string,
    compToAddPointer: CompRef,
    originalNicknameContext
) => {
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    if (originalNicknameContext) {
        componentCode.updateConnectionItemsNickname(
            ps,
            dataItem.items,
            compToAddPointer,
            pagePointer,
            originalNicknameContext
        )
    }

    dataItem.items = _.reject(
        dataItem.items,
        ({type, role}) =>
            type === 'WixCodeConnectionItem' && componentCode.hasComponentWithThatNickname(ps, pagePointer, role)
    )

    if (!_.isEmpty(dataItem.items)) {
        dataModel.addSerializedConnectionsItemToPage(ps, pageId, dataItem, newDataItemId)
    }
}

const DATA_SETTERS = {
    data: dataModel.addSerializedDataItemToPage,
    props: dataModel.addSerializedPropertyItemToPage,
    design: dataModel.addSerializedDesignItemToPage,
    behaviors: dataModel.addSerializedBehaviorsItemToPage,
    variables: dataModel.addSerializedVariablesItemToPage,
    presets: dataModel.addSerializedPresetsItemToPage,
    connections: cleanAndAddOverridenConnections,
    mobileHints: dataModel.addSerializedMobileHintsItemToPage,
    style: dataModel.addSerializedStyleItemToPage
}

const {
    getDataItemPointer,
    getPropertyItemPointer,
    getBehaviorsItemPointer,
    getConnectionsItemPointer,
    getMobileHintsItemPointer,
    getStyleItemPointer
} = dataModel

const {getDesignItemPointer} = design

const DATA_POINTER_GETTERS = {
    data: getDataItemPointer,
    props: getPropertyItemPointer,
    design: getDesignItemPointer,
    behaviors: getBehaviorsItemPointer,
    connections: getConnectionsItemPointer,
    mobileHints: getMobileHintsItemPointer,
    style: getStyleItemPointer
}

const MOBILE_SPLITTABLE_TYPE = {
    props: true
}

const MOBILE_SPLIT_SUFFIX = '-mobile'
const isMobileOverride = ({id, type}: Pointer) => !!MOBILE_SPLITTABLE_TYPE[type] && _.endsWith(id, MOBILE_SPLIT_SUFFIX)

const serializeCustomOverriddenData = (ps: PS, overridePointers: Pointer[]) =>
    _.map(overridePointers, overridePointer => {
        const compId = extractBaseComponentId(overridePointer)
        const referredCompId = displayedOnlyStructureUtil.getReferredCompId(compId)
        const itemType = overridePointer.type
        const dataItem = dataModel.getDataByPointer(ps, itemType, overridePointer, true)
        const isMobile = isMobileOverride(overridePointer)

        return {
            itemType,
            dataItem,
            isMobile,
            compId: referredCompId
        }
    })

function getOverriddenData(ps: PS, refComponentPointer: Pointer) {
    const overrides = ps.pointers.referredStructure.getAllOverrides(refComponentPointer)
    const overridePointersWithoutFallbacks = _.map(overrides, overridePointer =>
        ps.pointers.referredStructure.getPointerWithoutFallbacks(overridePointer)
    )

    return serializeCustomOverriddenData(ps, overridePointersWithoutFallbacks)
}

function getRemoteOverriddenData(ps: PS, contextId: string, refComponentId: string) {
    const overrides = ps.pointers.referredStructure.getRemoteOverrides(contextId, refComponentId)
    return serializeCustomOverriddenData(ps, overrides)
}

function setCustomSerializeData(ps: PS, refComponentPointer: Pointer, customStructureData) {
    customStructureData.overriddenData = getOverriddenData(ps, refComponentPointer)
}

function unwrapOverriddenCompIds(overriddenData) {
    if (overriddenData) {
        return _.map(overriddenData, item =>
            _.assign({}, item, {compId: displayedOnlyStructureUtil.getReferredCompId(item.compId)})
        )
    }
}

function getOverridenDataItemPointer(ps: PS, compPointer: Pointer, itemType: string) {
    const pointerGetter = DATA_POINTER_GETTERS[itemType]
    if (pointerGetter) {
        const dataItemPointer = pointerGetter(ps, compPointer)
        if (dataItemPointer) {
            return ps.pointers.referredStructure.getPointerWithoutFallbacks(dataItemPointer)
        }
    }
}

function removeOverrides(ps: PS, compRef: CompRef, options?: RemoveOverridesOptions) {
    ps.extensionAPI.refOverrides.removeOverrides(compRef, options)
}

function removeConnectionOverride(ps: PS, compRef: CompRef) {
    const overrides = getAllOverridesToBeRemoved(ps, compRef, false)
    const connectionOverride = _.filter(overrides, override => override.type === CONNECTIONS_ITEM_TYPE)
    dataModel.removeItems(ps, connectionOverride)
}

function getAllOverridesToBeRemoved(
    ps: PS,
    compRef: CompRef,
    excludeConnectionItems = true,
    excludeNonPropertiesItemsForMobile = false
) {
    const compType = component.getType(ps, compRef)
    if (compType !== 'wysiwyg.viewer.components.RefComponent') {
        return []
    }

    const overrides = ps.pointers.referredStructure.getAllOverrides(compRef)
    return _(overrides)
        .filter(override =>
            isMobilePointer(compRef) && excludeNonPropertiesItemsForMobile
                ? override.type === PROPERTIES_ITEM_TYPE
                : true
        )
        .reject(override => (excludeConnectionItems ? override.type === CONNECTIONS_ITEM_TYPE : false))
        .map(pointer => ps.pointers.referredStructure.getPointerWithoutFallbacks(pointer))
        .value()
}

function hasOverridesToBeRemoved(...args: any[]) {
    // @ts-expect-error
    return Boolean(getAllOverridesToBeRemoved(...args).length)
}

/**
 * Create a new component ref by existing component parent
 *
 * @param ps privateServices
 * @param compRef component ref that will be opened/closed
 * @returns new pointer for added component
 */
const getComponentToCreateRef = (ps: PS, compRef: CompRef): CompRef => {
    const parentRef = ps.pointers.components.getParent(compRef)
    return component.getComponentToDuplicateRef(ps, compRef, parentRef)
}

const removePropertyOverride = (ps: PS, compPointer: Pointer) => {
    const propertyOverridePointer = getOverridenDataItemPointer(ps, compPointer, constants.DATA_TYPES.prop)
    if (ps.dal.isExist(propertyOverridePointer)) {
        dataModel.removeItems(ps, [propertyOverridePointer])
    }
}

const updateVariantRelations = (ps: PS, compToAddPointer: Pointer, dataItem, mappers: DeserializationMappers) => {
    const {variantMapper, oldToNewIdMap} = mappers ?? {}
    const refArrayValuesReducer = (cleanRefArray, refArrayValue) => {
        if (refArrayValue.type === constants.RELATION_DATA_TYPES.VARIANTS) {
            const [, ...referredStructureIds] = refArrayValue.from.split(refCompDelimiter)

            const updatedVariants = refArrayValue.variants.map(
                oldVariant => oldToNewIdMap[dsUtils.stripHashIfExists(oldVariant)]
            )
            const newVariants = updatedVariants.filter(Boolean)

            if (newVariants.length !== refArrayValue.variants.length) {
                return cleanRefArray
            }

            refArrayValue = ps.extensionAPI.data.variantRelation.create(
                newVariants,
                createReferredId(compToAddPointer.id, referredStructureIds),
                refArrayValue.to
            )
        }

        cleanRefArray.push(refArrayValue)

        return cleanRefArray
    }
    dataItem.values = replaceVariantsOnSerializedOverrides(dataItem.values, variantMapper)
    dataItem.values = oldToNewIdMap ? dataItem.values.reduce(refArrayValuesReducer, []) : dataItem.values
}

const createOverrideDataItem = (
    ps: PS,
    itemType: string,
    compToAddPointer: Pointer,
    compId: string,
    pageId: string,
    dataItem,
    isMobile = false,
    originalNicknameContext?,
    mappers: DeserializationMappers = {oldToNewIdMap: {}, variantMapper: []}
) => {
    const dataSetter = DATA_SETTERS[itemType]

    if (dataItem?.type === constants.REF_ARRAY_DATA_TYPE) {
        updateVariantRelations(ps, compToAddPointer, dataItem, mappers)
    } else if (dataItem?.type === constants.RELATION_DATA_TYPES.VARIANTS) {
        return
    }

    if (dataSetter) {
        const repeatedTemplatePointer = dmCore.pointerUtils.getRepeatedItemPointerIfNeeded(compToAddPointer)
        const newDataItemId = createRefCompQuery(repeatedTemplatePointer.id, compId, itemType, isMobile)
        if (itemType === DATA_TYPES.theme) {
            dataSetter(ps, pageId, dataItem, newDataItemId)
        } else if (itemType === DATA_TYPES.data || itemType === DATA_TYPES.design) {
            addRepeatedItemsDataOverridesIfNeeded(ps, compToAddPointer, newDataItemId, dataItem, pageId, dataSetter)
        } else if (itemType === DATA_TYPES.connections) {
            dataSetter(ps, pageId, dataItem, newDataItemId, repeatedTemplatePointer, originalNicknameContext)
        } else if (itemType === DATA_TYPES.variables) {
            dataSetter(ps, pageId, dataItem, createRefCompQuery(repeatedTemplatePointer.id, compId))
        } else {
            dataSetter(ps, pageId, dataItem, newDataItemId)
        }
    }
}

const isRefHost = (ps: PS, compPointer: Pointer) =>
    ps.dal.isExist(compPointer) && component.getType(ps, compPointer) === constants.REF_COMPONENT.REF_COMPONENT_TYPE

const isInternalRef = (ps: PS, componentRef: CompRef) => {
    const compData = component.data.get(ps, componentRef)
    const refType = _.get(compData, 'type')
    return INTERNAL_REF_TYPES.has(refType)
}

const updateVariation = (ps: PS, refComponent: CompRef, variationId: string) => {
    if (isInternalRef(ps, refComponent)) {
        const variationPage = ps.pointers.components.getPage(variationId, documentModeInfo.getViewMode(ps))
        const [newVariationWidgetRoot] = ps.pointers.full.components.getChildren(variationPage)
        component.data.update(ps, refComponent, {pageId: variationId, rootCompId: newVariationWidgetRoot.id})
    } else {
        component.data.update(ps, refComponent, {variationId})
    }
}

const getSerializedConnectionOverrideData = connectionsItem => {
    const compId = extractBaseComponentId({id: connectionsItem.id, type: CONNECTIONS_ITEM_TYPE})
    const referredCompId = displayedOnlyStructureUtil.getReferredCompId(compId)
    return {
        itemType: CONNECTIONS_ITEM_TYPE,
        dataItem: connectionsItem,
        compId: referredCompId
    }
}

const createOverrideKey = ({compId, itemType}) => `${itemType}_${compId}`

const isRefComponentInflated = (ps: PS, refComp: CompRef) => !_.isEmpty(ps.pointers.components.getChildren(refComp))

export default {
    DATA_SETTERS,
    removeConnectionOverride,
    isRefComponentInflated,
    getOverridenDataItemPointer,
    getAllOverridesToBeRemoved,
    extractBaseComponentId,
    getOverriddenData,
    getRemoteOverriddenData,
    getSerializedConnectionOverrideData,
    setCustomSerializeData,
    removeOverrides,
    hasOverridesToBeRemoved,
    removePropertyOverride,
    createOverrideDataItem,
    getComponentToCreateRef,
    isRefHost,
    getReferredCompId: displayedOnlyStructureUtil.getReferredCompId,
    updateVariation,
    isInternalRef,
    unwrapOverriddenCompIds,
    createOverrideKey,
    getItemQueryId,
    isSharedPartsId
}
