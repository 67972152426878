'use strict'
const _ = require('lodash')
const {convertToMesh, shouldBeConvertedToMesh} = require('@wix/layout-migrations')
const {stripHashIfExists} = require('../../helpers/dataUtils')
const {namespaceMapping} = require('@wix/document-services-json-schemas')
const constants = require('../../helpers/constants')
const {addPropsData, createPropsBuilder, updateProps} = require('./props')
const {collectTransformations, updateTransformations, addTransformationsData} = require('./transformations')
const {addLayoutData, convertPageCompsToZeroLayout, writeMeshLayout} = require('./layout')
const {shouldRunConversion} = require('./shouldRunConversion')

const {DESKTOP, MOBILE} = constants
const VARIANTS_DATA_NAMESPACE = namespaceMapping.NAMESPACE_MAPPING.variants
const TRANSFORMATIONS_DATA_NAMESPACE = namespaceMapping.NAMESPACE_MAPPING.transformations
const PROPS_DATA_NAMESPACE = 'component_properties'
const PAGE_TYPES = ['Document', 'Page']

const addVariantsData = pageJson => {
    if (!pageJson.data[VARIANTS_DATA_NAMESPACE]) {
        pageJson.data[VARIANTS_DATA_NAMESPACE] = {}
    }
}

const getChildren = (structure, viewMode) => {
    if (structure.type === 'Document' && viewMode !== MOBILE) {
        return structure.children
    }
    if (PAGE_TYPES.includes(structure.type) && viewMode === MOBILE) {
        return structure.mobileComponents
    }
    return structure.components
}

const getAllComponents = (structure, viewMode) => {
    const stack = [structure]
    const componentsMap = {}

    while (stack.length > 0) {
        const currentStructure = stack.pop()
        componentsMap[currentStructure.id] = currentStructure
        const children = getChildren(currentStructure, viewMode)

        _.forEach(children, child => {
            child.parent = currentStructure.id
            stack.push(child)
        })
    }

    return componentsMap
}

const prepareContext = (pageJson, magicObject) => {
    const conversionContext = {
        allComponents: {
            [DESKTOP]: getAllComponents(pageJson.structure, DESKTOP),
            [MOBILE]: getAllComponents(pageJson.structure, MOBILE)
        },
        pageJson,
        uniqueIdGenerator: magicObject.dataFixerUtils.uniqueIdGenerator,
        options: {
            usesFlexLayout: magicObject.isExperimentOpen('dm_flexLayoutInMesh')
        }
    }
    return conversionContext
}

const getStructureVariant = (comp, pageJson, viewMode = DESKTOP) => {
    return {
        props: {
            ...pageJson.data[PROPS_DATA_NAMESPACE][stripHashIfExists(comp.propertyQuery)]
        },
        layout: {...comp.layout},
        parent: comp.parent,
        components: _.map(getChildren(comp, viewMode), 'id'),
        transformations: comp.transformationQuery
            ? collectTransformations(pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE], comp.transformationQuery)
            : []
    }
}

const getStructure = (componentId, {allComponents, pageJson}) => {
    const {structure} = pageJson
    const desktop = allComponents[DESKTOP][componentId]
    const mobile = allComponents[MOBILE][componentId]
    const structureToCheck = desktop ? desktop : mobile
    const structureLayout = {
        componentType: structureToCheck.componentType,
        pageId: structure.id,
        componentId,
        type: structureToCheck.type,
        isResponsive: !!structureToCheck.layoutQuery
    }
    if (desktop && desktop.layout) {
        structureLayout.desktop = getStructureVariant(desktop, pageJson)
    }
    if (mobile && mobile.layout) {
        structureLayout.mobile = getStructureVariant(mobile, pageJson, MOBILE)
    }
    return structureLayout
}

const write = (convertedLayouts, conversionContext) => {
    _.forEach(convertedLayouts, (conversionResult, componentId) => {
        updateProps(conversionResult, componentId, conversionContext)
        updateTransformations(conversionResult, componentId, conversionContext)
        writeMeshLayout(conversionResult, componentId, conversionContext)
    })
}

const convertToMeshLayout = conversionContext => {
    const {allComponents} = conversionContext
    const uniqueComponentsIds = _.union(Object.keys(allComponents[DESKTOP]), Object.keys(allComponents[MOBILE]))
    const structureToConvertToMesh = _(uniqueComponentsIds)
        .keyBy()
        .mapValues(componentId => getStructure(componentId, conversionContext))
        .pickBy(structure => shouldBeConvertedToMesh(structure) && !structure.isResponsive)
        .value()

    const convertedToMeshStructure = {}
    // @ts-ignore
    convertToMesh(structureToConvertToMesh, convertedToMeshStructure, {
        createPropertiesItemByType: createPropsBuilder,
        options: conversionContext.options
    })
    write(convertedToMeshStructure, conversionContext)
}

module.exports = {
    name: 'meshDataFixer',
    version: 0,
    experimentalVersions: [
        {version: 1, experiment: 'dm_meshLayout'},
        {version: 2, experiment: 'dm_flexLayoutInMesh'},
        {version: 3, experiment: 'dm_avoidZeroLayout'}
    ],
    exec(pageJson, pageIdsArray, magicObject) {
        if (!shouldRunConversion(magicObject, pageJson)) {
            return
        }

        addVariantsData(pageJson)
        addLayoutData(pageJson)
        addPropsData(pageJson)
        addTransformationsData(pageJson)
        const conversionContext = prepareContext(pageJson, magicObject)
        convertToMeshLayout(conversionContext)
        if (!magicObject.isExperimentOpen('dm_avoidZeroLayout')) {
            convertPageCompsToZeroLayout(conversionContext)
        }
    }
}
