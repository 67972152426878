import {stripHashIfExists} from '../../../utils/refArrayUtils'
import * as _ from 'lodash'
import * as constants from '../../../constants/constants'
import type {CompStructure} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {LoggerExtAPI} from '../../logger'
import {Errors} from '../../../errors/errors'

const {DOM_ID_PREFIX} = constants
const MOBILE_COMP_DATA_PREFIX = DOM_ID_PREFIX.MOBILE

export const getCustomId = (oldToNewIdMap: any, dataObject: any, customId?: string) => {
    let newId = customId
    if (!oldToNewIdMap || !dataObject) {
        return newId
    }
    const serializedDataId = stripHashIfExists(dataObject.id) ?? ''
    if (oldToNewIdMap[serializedDataId]) {
        newId = oldToNewIdMap[serializedDataId]
    } else if (_.startsWith(serializedDataId, MOBILE_COMP_DATA_PREFIX)) {
        const idSuffix = serializedDataId.substring(MOBILE_COMP_DATA_PREFIX.length)
        const mobilePropsMappedId = oldToNewIdMap[idSuffix]
        if (mobilePropsMappedId) {
            newId = MOBILE_COMP_DATA_PREFIX + mobilePropsMappedId
        }
    }
    return newId
}

export const addIdToMap = (dataObject: any, newId: string, oldToNewIdMap: any) => {
    if (!oldToNewIdMap) {
        return
    }
    const serializedDataId = stripHashIfExists(dataObject.id) ?? ''
    newId = stripHashIfExists(newId)
    if (dataObject.id && !oldToNewIdMap[serializedDataId]) {
        oldToNewIdMap[serializedDataId] = newId
    }
}

export const getIdentifierForQuery = (extensionAPI: ExtensionAPI, compStructure: CompStructure, itemType: string) => {
    if (compStructure.id) {
        return `${compStructure.id},${itemType}`
    } else if (compStructure.overrideOwner) {
        return `${compStructure.overrideOwner},${itemType},override`
    }
    const message = `undefined,${itemType}`
    const err = new ReportableError({
        message: `compStructure is missing id or overrideOwner- ${message}`,
        errorType: Errors.VARIANTS_QUERY_IDENTIFIER,
        extras: compStructure
    })
    const {logger} = extensionAPI as LoggerExtAPI
    logger.captureError(err)
    return message
}
