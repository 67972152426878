import {
    CreateExtArgs,
    DAL,
    DmApis,
    Extension,
    ExtensionAPI,
    PointerMethods,
    pointerUtils
} from '@wix/document-manager-core'
import type {CreateViewerExtensionArgument} from '../types'
import type {CompRef, Pointer, PossibleViewModes, Rect} from '@wix/document-services-types'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {CHILDREN_PROPERTY_NAMES, DM_POINTER_TYPES, VIEW_MODES} from '../constants/constants'
import {findCompBFS} from '../utils/findCompBFS'
import {pageGetterFromDisplayed} from '../utils/pageUtils'
import {getRefPointerType, isRefHost, isRefPointer} from '../utils/refStructureUtils'
import {
    getUniqueDisplayedId,
    getUniqueStructure,
    isRepeatedComponent,
    isRepeater,
    isRepeaterMasterItem,
    syncTemplateLayout,
    updateRepeaterMasterItem
} from '../utils/repeaterUtils'
import {getChildrenRecursivelyRightLeftRoot} from '../utils/structureUtils'
import {getIdFromRef} from '../utils/dataUtils'
import {getPointerGetterConsideringScopes} from '../utils/scopesUtils'
import {getRepeatersNestingSuffix} from '../utils/inflationUtils'
import type {ScopesExtensionAPI} from './scopes'
import {ReportableError} from '@wix/document-manager-utils'

const {getInnerPointer, getInnerValue, getPointer, getRepeatedItemPointerIfNeeded, normalizeInnerPath, stripInnerPath} =
    pointerUtils

const structureTypes = _.values(VIEW_MODES)
const isModeActive = (modeId: string, allActiveModeIds: Record<string, any>) => !!allActiveModeIds[modeId]
const applyOverride = (value: any, override: any) => {
    const cleanOverride = _.omit(override, ['modeIds', 'overrides', 'definitions'])
    return _.defaults(cleanOverride, value)
}

/**
 * @param {ViewerManager} viewerManager
 * @param experimentInstance
 * @param dsConfig
 * @returns {Extension}
 */
const createExtension = ({viewerManager, dsConfig}: CreateViewerExtensionArgument): Extension => {
    const applyModes = (current: any) => {
        if (!_.has(current, ['modes'])) {
            return current
        }

        let valueWithOverrides = applyOverride(current, current.modes)

        if (_.has(current, ['modes', 'overrides'])) {
            const allActiveModeIds = viewerManager.viewerSiteAPI.getAllActiveModeIds()
            const matchingOverrides = _.filter(
                current.modes.overrides,
                override => !_.some(override.modeIds, modeId => !isModeActive(modeId, allActiveModeIds))
            )
            _.forEach(matchingOverrides, override => {
                valueWithOverrides = applyOverride(valueWithOverrides, override)
            })
        }

        if (valueWithOverrides.isHiddenByModes) {
            return undefined
        }

        return _.omit(valueWithOverrides, ['isHiddenByModes'])
    }

    const getRepeaterStructure = (dal: DAL, current: any) => {
        const templateId: string = _.head(current.components) as string

        const repeaterDataQuery = getIdFromRef(current.dataQuery)
        const repeaterDataPointer = getPointer(repeaterDataQuery, 'data')
        const repeaterData = dal.get(repeaterDataPointer)

        const newChildren = repeaterData
            ? _.map(repeaterData.items, itemId => getUniqueDisplayedId(templateId, itemId))
            : []
        return _.defaults({components: newChildren}, current)
    }

    const getRepeaterStructureFromViewerManager = (current: any, pointer: Pointer) => {
        const repeaterStructure = viewerManager.dal.get(pointer)
        if (repeaterStructure) {
            return _.defaults({components: repeaterStructure.components}, current)
        }
        return current
    }

    const getBasicMeasureForRepeatedComp = (pointer: Pointer): Rect =>
        viewerManager.viewerSiteAPI.getBasicMeasureForCompWithoutTransform(pointer)

    const getRepeatedItemStructure = (dal: DAL, pointer: Pointer) => {
        const itemId = getRepeatersNestingSuffix(pointer.id)
        const templatePointer = getRepeatedItemPointerIfNeeded(pointer)
        const templateStructure = dal.get(templatePointer)
        if (!templateStructure) {
            return undefined
        }
        const inflatedRepeatedStructure = _.assign({}, templateStructure, getUniqueStructure(templateStructure, itemId))
        const parentPointer = getPointer(templateStructure.parent, pointer.type)
        const shouldPreserveParentId = parentPointer && isRepeater(dal, parentPointer)
        const compMeasures = getBasicMeasureForRepeatedComp(pointer)
        return _.defaultsDeep(
            {
                layout: compMeasures,
                parent: shouldPreserveParentId ? templateStructure.parent : inflatedRepeatedStructure.parent
            },
            inflatedRepeatedStructure
        )
    }

    const applyRepeatedStructureIfNeeded = (dal: DAL, current: any, pointer: Pointer, extensionAPI: ExtensionAPI) => {
        const isRepeaterComp = isRepeater(dal, pointer)
        const isRepeatedComp = isRepeatedComponent(pointer.id)

        if (!isRepeaterComp && !isRepeatedComp) {
            return current
        }

        const templatePointer = getRepeatedItemPointerIfNeeded(pointer)
        const isByRef = isRefPointer(pointer) || isRefHost(templatePointer, dal)

        if (isByRef) {
            if (viewerManager.dal.isExist(pointer)) {
                const {scopes} = extensionAPI as ScopesExtensionAPI
                const compFromViewer = scopes.getDisplayedFromViewer(pointer)
                const compMeasures = getBasicMeasureForRepeatedComp(pointer)
                return _.defaultsDeep({layout: compMeasures}, compFromViewer)
            }
            return current
        }

        if (isRepeaterComp) {
            if (viewerManager.dal.isExist(pointer)) {
                return getRepeaterStructureFromViewerManager(current, pointer)
            }
            if (current) {
                return getRepeaterStructure(dal, current)
            }
        }
        if (isRepeatedComp) {
            return getRepeatedItemStructure(dal, pointer)
        }
        return current
    }

    const getDisplayedPage = (dmApis: DmApis, pointer: Pointer) => {
        const page = pageGetterFromDisplayed(dmApis, pointer)
        return getInnerValue(pointer, page)
    }

    const getFromDisplayedStructure = (dal: DAL, pointer: Pointer, extensionAPI: ExtensionAPI) => {
        const strippedPointer = stripInnerPath(pointer)
        const value = dal.get(strippedPointer)
        const valueAfterModes = applyModes(value)
        const valueAfterRepeatedStructure = applyRepeatedStructureIfNeeded(
            dal,
            valueAfterModes,
            strippedPointer,
            extensionAPI
        )
        return getInnerValue(pointer, valueAfterRepeatedStructure)
    }

    const getFromDisplayed = (dmApis: DmApis, pointer: Pointer) => {
        const {dal, extensionAPI} = dmApis
        if (pointer.type === DM_POINTER_TYPES.pageDM) {
            return getDisplayedPage(dmApis, pointer)
        }

        if (structureTypes.includes(pointer.type as PossibleViewModes)) {
            return getFromDisplayedStructure(dal, pointer, extensionAPI)
        }

        return dal.get(pointer)
    }

    /**
     * Returns an index to an override that matches current active modes, -1 otherwise
     *
     * I know the code is old school, doing something similar to the below line. The reason is that this is a very high usage function,
     * so performance is the top priority
     * ALTERNATIVE _.filter(current.modes.overrides, override => !_.some(override.modeIds, modeId => !isModeActive(modeId, allActiveModeIds)))
     *
     * @param current
     * @param allActiveModeIds
     * @returns {number}
     */
    const getMatchingOverrideIndex = (current: any, allActiveModeIds: Record<string, any>) => {
        const {overrides} = current.modes
        for (let i = 0; i < overrides.length; i++) {
            let allMatch = true
            for (const modeId of overrides[i].modeIds) {
                if (!isModeActive(modeId, allActiveModeIds)) {
                    allMatch = false
                    break
                }
            }

            if (allMatch) {
                return i
            }
        }

        return -1
    }

    const getOverriddenPath = (current: any, path: any) => {
        if (!_.has(current, ['modes', 'overrides'])) {
            return path
        }

        const allActiveModeIds = viewerManager.viewerSiteAPI.getAllActiveModeIds()
        const matchingOverrideIndex = getMatchingOverrideIndex(current, allActiveModeIds)

        if (matchingOverrideIndex === -1) {
            return path
        }

        const overridePath = ['modes', 'overrides', matchingOverrideIndex].concat(path)
        if (!_.get(current, overridePath)) {
            // There is no override for this value, not adding a new one
            return path
        }
        return overridePath
    }

    /**
     * @param {{dal:DAL, pointers}} o
     * @returns {{displayed: {setDisplayedValue: (function(*, *): void), getDisplayedValue: (function(*): *)}}}
     */
    const createExtensionAPI = (extArgs: CreateExtArgs): FTDExtApi => {
        const {
            dal,
            pointers,
            coreConfig: {logger}
        } = extArgs
        function updateRepeaterMasterIfNeeded(pointer: Pointer, innerPath: string[]) {
            const shouldUpdateMasterItem = !dsConfig?.doNotSyncRepeatedLayout
            if (shouldUpdateMasterItem) {
                const isRepeatedLayoutChange =
                    isRepeatedComponent(pointer.id) &&
                    innerPath.includes('layout') &&
                    !isRepeaterMasterItem({dal, pointers}, pointer)
                const isRepeatedChildrenChange =
                    isRepeatedComponent(pointer.id) && _.intersection(innerPath, CHILDREN_PROPERTY_NAMES).length > 0

                if (isRepeatedLayoutChange || isRepeatedChildrenChange) {
                    updateRepeaterMasterItem({pointers, dal, viewerManager, logger}, pointer)
                }
            }
        }

        const setToDisplayedStructure = (pointer: Pointer, value: any) => {
            const innerPath = normalizeInnerPath(pointer.innerPath)
            const pointerToUpdate = getRepeatedItemPointerIfNeeded(pointer)

            if (!innerPath.length) {
                dal.set(pointerToUpdate, value)
            }
            updateRepeaterMasterIfNeeded(pointer, innerPath)

            const basePointer = stripInnerPath(pointerToUpdate)
            const current = dal.get(basePointer)
            const overriddenPath = getOverriddenPath(current, innerPath)

            let updatedValue = current
            if (typeof updatedValue !== 'undefined') {
                updatedValue = _.setWith(deepClone(current), overriddenPath, value, Object)
            }
            dal.set(basePointer, updatedValue)
        }

        const setToDisplayed = (pointer: Pointer, value: any) => {
            if (structureTypes.includes(pointer.type as PossibleViewModes)) {
                return setToDisplayedStructure(pointer, value)
            }
            return dal.set(pointer, value)
        }

        return {
            displayed: {
                getDisplayedValue: pointer => getFromDisplayed(extArgs, pointer),
                setDisplayedValue: setToDisplayed
            },
            repeaters: {
                updateMasterIfNeeded: updateRepeaterMasterIfNeeded,
                syncTemplateLayout: pointer =>
                    syncTemplateLayout({dal, viewerManager, logger}, pointer, pointers.components.getSiblings(pointer))
            }
        }
    }

    /**
     * @param structurePointers
     * @param {DAL} dal
     * @param pointers
     * @param extensionAPI
     * @returns {*}
     */
    const getComponentPointersFromDisplay = (dmApis: DmApis) => {
        const {dal, pointers, extensionAPI, coreConfig} = dmApis
        const getPointerFunc = getPointerGetterConsideringScopes(extensionAPI)

        const getSlottedChildren = (pointer: Pointer, type: string): Pointer[] => {
            const slotsQuery = getFromDisplayed(dmApis, getInnerPointer(pointer, ['slotsQuery']))

            if (slotsQuery) {
                const itemValue = getFromDisplayed(dmApis, getPointer(slotsQuery, 'slots'))
                if (!itemValue) {
                    coreConfig.logger.captureError(
                        new ReportableError({
                            message: `${slotsQuery} is missing from dal but is referenced by ${pointer}`,
                            errorType: 'slotsObjectMissingFromDal',
                            extras: {
                                component: getFromDisplayed(dmApis, pointer)
                            }
                        })
                    )
                }
                const {slots}: Record<string, string> = itemValue
                return Object.values(slots).map(id => getPointer(id, type))
            }

            return []
        }

        const getChildren = (originalPointer: CompRef): CompRef[] => {
            if (!originalPointer) {
                return []
            }

            const pointer = isRefHost(originalPointer, dal) ? getRefPointerType(originalPointer) : originalPointer
            const childrenIds: string[] = getFromDisplayed(dmApis, getInnerPointer(pointer, ['components']))

            const childPointers = _.map(childrenIds, id => getPointerFunc(originalPointer, id))
            const slottedChildren = getSlottedChildren(pointer, originalPointer.type)

            const childrenPointers = _.filter(
                _.unionBy([...childPointers, ...slottedChildren], 'id'),
                childPointer => !!getFromDisplayed(dmApis, childPointer)
            )

            return childrenPointers as CompRef[]
        }

        const getChildrenRecursively = (pointer: CompRef): CompRef[] => {
            const children = getChildren(pointer)
            return children.concat(_.flatMap(children, getChildrenRecursively))
        }

        const getComponent = (id: string, pagePointer: Pointer) => getPointer(id, pagePointer.type)

        const getChildrenRecursivelyRightLeftRootIncludingRoot = (pointer: CompRef) =>
            getChildrenRecursivelyRightLeftRoot(getChildren, pointer)

        const getParent = (pointer: Pointer) => {
            if (!pointer) {
                return null
            }

            const parentId = getFromDisplayed(dmApis, getInnerPointer(pointer, ['parent']))

            if (parentId === undefined) {
                return null
            }

            const parentPointer = getPointerFunc(pointer, parentId)

            return parentPointer
        }

        const findDescendant = (componentPointer: CompRef, predicate: any) => {
            const comp = findCompBFS(componentPointer, getChildren, compPointer =>
                predicate(getFromDisplayed(dmApis, compPointer))
            )
            return comp ? getComponent(comp.id, componentPointer) : null
        }

        const findComponentInPage = (pagePointer: CompRef, isMobileView: boolean, predicate: any) =>
            findDescendant(pagePointer, predicate)

        const getAncestorByPredicate = (compPointer: Pointer, predicate: (a: Pointer) => boolean = _.identity) => {
            let ancestorPointer = getParent(compPointer)
            while (ancestorPointer && !predicate(ancestorPointer)) {
                ancestorPointer = getParent(ancestorPointer)
            }

            return ancestorPointer
        }

        const isDescendant = (compPointer: Pointer, possibleAncestorPointer: Pointer): boolean => {
            return !!getAncestorByPredicate(compPointer, (ancestorPointer: Pointer) =>
                pointers.components.isSameComponent(ancestorPointer, possibleAncestorPointer)
            )
        }

        const displayPointers = {
            getParent,
            getChildren,
            getComponent,
            isDescendant,
            getChildrenRecursively,
            getChildrenRecursivelyRightLeftRootIncludingRoot,
            findComponentInPage,
            findDescendant
        }

        return _.assign({}, pointers.structure as any, displayPointers)
    }

    const createPointersMethods = (dmApis: DmApis): PointerMethods => {
        return {
            components: getComponentPointersFromDisplay(dmApis),
            full: {
                // @ts-expect-error
                components: dmApis.pointers.structure
            }
        }
    }

    return {
        name: 'fullToDisplay',
        dependencies: new Set(['scopes']),
        createExtensionAPI,
        createPointersMethods
    }
}

export interface FTDExtApi extends ExtensionAPI {
    displayed: {
        getDisplayedValue(pointer: Pointer): any
        setDisplayedValue(pointer: Pointer, value: any): void
    }
    repeaters: {
        updateMasterIfNeeded(pointer: Pointer, innerPath: string[]): void
        syncTemplateLayout(masterItemPointer: Pointer): void
    }
}

export {createExtension}
