import type {PS} from '@wix/document-services-types'
import variants from '../../variants/variants'
import type {DsFixer} from '../dsDataFixers'

function removeUnknownDataNodeVariantsAndData(ps: PS) {
    if (!ps.pointers.variants) {
        return
    }

    const allPages = ps.siteAPI.getAllPagesIds(true)

    allPages.forEach(pageId => {
        const corruptedVariants = ps.pointers.data.getVariantItemsWithPredicate(
            x => x.type === 'UnknownDataNode',
            pageId
        )

        if (corruptedVariants.length > 0) {
            variants.remove(ps, corruptedVariants)
        }
    })
}

const fixer: DsFixer = {
    exec(ps: PS) {
        removeUnknownDataNodeVariantsAndData(ps)
    },
    name: 'unknownTypeVariantsFixer',
    version: 1
}
export default fixer
