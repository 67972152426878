import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef, CompStructure, Pointer, Pointers} from '@wix/document-services-types'
import _ from 'lodash'

const hasChildren = (pointers: Pointers, repeaterPointer: Pointer) => {
    const children = pointers.structure.getChildren(repeaterPointer)
    return _.size(children) > 0
}

const canContain = (
    {pointers}: CreateExtArgs,
    popoverPointer: Pointer,
    potentialChild: CompRef | CompStructure,
    targetedContainerPointer: Pointer
) => {
    const isTargetedContainerAPopover = targetedContainerPointer.id === popoverPointer.id
    const containsChildren = hasChildren(pointers, popoverPointer)
    return !(isTargetedContainerAPopover && containsChildren)
}

export const popoverMetaData = {
    isRepeatable: false,
    canContain,
    canContainByStructure: canContain
}
