import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {isPopup} from '../../page/popupUtils'
import {getComponentType} from '../../../utils/dalUtils'
import type {ComponentDefinitionExtensionAPI} from '../../componentDefinition'

const containableByStructure = (
    createExtArgs: CreateExtArgs,
    compStructure: CompStructure,
    containerPointer: Pointer
) => {
    const {componentDefinition} = createExtArgs.extensionAPI as ComponentDefinitionExtensionAPI

    const containerCompType = getComponentType(createExtArgs.dal, containerPointer)
    const isContainerPage = componentDefinition.isPage(containerCompType)
    return isContainerPage && !isPopup(createExtArgs, containerPointer.id)
}

export const classicSectionMetadata = {
    isRepeatable: false,
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: true,
    container: true,
    alwaysContainRecursively: true,
    containableByStructure,
    fullWidthByStructure: true,
    fullWidth: true
}
