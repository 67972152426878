import {type CreateExtArgs, type DAL, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {
    CompStructure,
    DeserializationMappers,
    Pointer,
    SerializedCompStructure,
    SerializedSlotStructure
} from '@wix/document-services-types'
import _ from 'lodash'
import type {AddComponentOptionals, Result} from './types'
import {OOI_HOST_COMPONENT_TYPES, SERIALIZED_PROPERTIES_TO_OMIT} from './constants'
import {validateComponentToAdd} from './validation/validation'
import {ReportableError} from '@wix/document-manager-utils'
import {DATA_TYPES, VIEW_MODES} from '../../constants/constants'
import type {ComponentsExtensionAPI} from './components'
import {deepClone} from '@wix/wix-immutable-proxy'
import {getRepeaterTemplateId, mapOldIdToNewId} from './utils'
import type {ComponentDefinitionExtensionAPI} from '../componentDefinition'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import {getRepeaterItemId, getUniqueDisplayedId, isRepeatedComponent} from '../../utils/repeaterUtils'
import {stripHashIfExists} from '../../utils/refArrayUtils'
import {updateComponentDataStructure} from './componentDeserialization/namespaces/data'
import {updateComponentDesignStructure} from './componentDeserialization/namespaces/design'
import {type AdjustComponentLayoutFromExtEvent, type ComponentAfterAddDataFromExtEvent, COMPONENTS_HOOKS} from './hooks'
import type {HooksExtensionApi} from '../hooks/hooks'
import {updateComponentVariablesDefinition} from './componentDeserialization/namespaces/variables'
import {addIdToMap} from './componentDeserialization/utils'
import {updateVariantsInStructure} from './componentDeserialization/namespaces/variants'
import {isAppControllerData} from '../tpa/installedTpaAppsUtils'
import type {PlatformExtensionAPI} from '../platform'
import type {StructureExtensionAPI} from '../structure'
import {getTranslationLanguageCodes} from '../page/language'
import {getContainerToAddTo} from '../page/popupUtils'

export const addComponentToParent = (dal: DAL, parentPointer: Pointer, componentPointer: Pointer) => {
    const componentsPtr = pointerUtils.getInnerPointer(parentPointer, 'components')
    const components = _.cloneDeep(dal.get(componentsPtr))
    components.push(componentPointer.id)
    dal.set(componentsPtr, components)
    dal.set({...componentPointer, innerPath: ['parent']}, parentPointer.id)
}

export const updateContainerChildren = (
    createExtArgs: CreateExtArgs,
    containerPointer: Pointer,
    compStructure: Partial<SerializedCompStructure>,
    componentPointer: Pointer
) => {
    const {dal, pointers} = createExtArgs
    const containerChildrenPointer = pointers.structure.getChildrenContainer(containerPointer)

    // todo getNewIndexForNewComp
    const containerChildren = _.cloneDeep(dal.get(containerChildrenPointer))
    containerChildren.push(compStructure.id)

    dal.set(containerChildrenPointer, containerChildren)
    dal.set({...componentPointer, innerPath: ['parent']}, containerPointer.id)
}

const buildSerializeComp = (componentsAPI: any, compStructure: SerializedCompStructure) => {
    const namespacesWithPossiblyRequiredData = [DATA_TYPES.data, DATA_TYPES.prop, DATA_TYPES.design, DATA_TYPES.theme]
    const requiredDefaults = _.pick(
        componentsAPI.buildDefaultComponentStructure(compStructure.componentType),
        namespacesWithPossiblyRequiredData
    )
    _(namespacesWithPossiblyRequiredData)
        .filter(ns => _.isNull(compStructure[ns]))
        .forEach(ns => delete compStructure[ns])
    // add empty object to defaults so that we make a shallow clone
    return _.defaults({}, compStructure, requiredDefaults) //add empty object to defaults so that we make a shallow clone
}

const convertToContainer = (compToConvert: SerializedCompStructure) => {
    compToConvert.type = 'Container'
    compToConvert.components = compToConvert.components ?? []
}

const omitPropertiesFromSerializedComp = (compStructure: SerializedCompStructure) => {
    return _.omit(compStructure, SERIALIZED_PROPERTIES_TO_OMIT)
}

const updateDefinitionTypeByComponentType = (extensionApi: ExtensionAPI, compStructure: SerializedCompStructure) => {
    const {componentDefinition} = extensionApi as ComponentDefinitionExtensionAPI
    const {componentType} = compStructure

    if (OOI_HOST_COMPONENT_TYPES.includes(componentType)) {
        return convertToContainer(compStructure)
    }

    if (componentDefinition.isRepeater(componentType)) {
        compStructure.type = 'RepeaterContainer'
    } else if (componentDefinition.isPage(componentType)) {
        compStructure.type = 'Page'
    } else if (componentDefinition.isRefComponent(componentType)) {
        compStructure.type = 'RefComponent'
    } else if (componentDefinition.isContainer(componentType)) {
        return convertToContainer(compStructure)
    } else {
        compStructure.type = 'Component'
    }
}

export const updateDisplayedOnlyComponentsDefs = (
    createExtArgs: CreateExtArgs,
    pageId: string,
    compToAddPointer: Pointer,
    structureToAdd: CompStructure,
    serializedComp: SerializedCompStructure,
    isPage: boolean,
    mappers: DeserializationMappers
) => {
    const {pointers, dal} = createExtArgs

    const displayOnlyComponents = pointers.structure.getAllDisplayedOnlyComponents(compToAddPointer)
    const shouldUpdateDisplayedOnlyComps = !_.isEmpty(displayOnlyComponents) && isRepeatedComponent(compToAddPointer.id)
    if (shouldUpdateDisplayedOnlyComps && (structureToAdd.dataQuery || structureToAdd.designQuery)) {
        _.forEach(displayOnlyComponents, function (displayedComponent) {
            const itemId = getRepeaterItemId(displayedComponent.id)
            if (structureToAdd.dataQuery) {
                const uniqueDataQueryForItem = getUniqueDisplayedId(stripHashIfExists(structureToAdd.dataQuery), itemId)
                if (!dal.has(pointers.data.getDataItem(uniqueDataQueryForItem, pageId))) {
                    updateComponentDataStructure({
                        createExtArgs,
                        pageId,
                        isPage,
                        mappers,
                        compStructure: _.clone(serializedComp),
                        customId: uniqueDataQueryForItem
                    })
                }
            }
            if (structureToAdd.designQuery) {
                const uniqueDesignQueryForItem = getUniqueDisplayedId(
                    stripHashIfExists(structureToAdd.designQuery),
                    itemId
                )
                if (!dal.has(pointers.data.getDesignItem(uniqueDesignQueryForItem, pageId))) {
                    updateComponentDesignStructure({
                        createExtArgs,
                        pageId,
                        isPage,
                        mappers,
                        compStructure: _.clone(serializedComp),
                        customId: uniqueDesignQueryForItem
                    })
                }
            }
        })
    }
}

const fixAppDataMissingAppDefId = ({extensionAPI}: CreateExtArgs, compStructure: SerializedCompStructure) => {
    const compData = compStructure?.data
    if (isAppControllerData(compData) || !compData?.applicationId || !!compData.appDefinitionId) {
        return
    }

    const {platform} = extensionAPI as PlatformExtensionAPI
    const appDefinitionId = platform.getAppDefinitionIdFromApplicationId(compData.applicationId, {
        source: 'checkApplicationDataValid'
    })

    _.set(compData, ['appDefinitionId'], appDefinitionId)
}

const createTranslationsForComp = (
    createExtArgs: CreateExtArgs,
    compPointer: Pointer,
    serializedComp: SerializedCompStructure
) => {
    const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI
    if (serializedComp.translations) {
        const siteTranslations = getTranslationLanguageCodes(createExtArgs)
        const relevantLanguages = _.intersection(Object.keys(serializedComp.translations), siteTranslations)

        for (const languageCode of relevantLanguages) {
            const translatedItem = serializedComp!.translations![languageCode]
            dataModel.components.addItem(compPointer, DATA_TYPES.data, translatedItem, languageCode)
        }
    }
}

const resetMobileComponentsForClonedComp = (
    serializedComp: SerializedCompStructure,
    clonedSerializedComp: SerializedCompStructure
) => {
    if (serializedComp.mobileComponents) {
        delete clonedSerializedComp.mobileComponents
    }
}

const setMobileDefinitionsForPage = (
    createExtArgs: CreateExtArgs,
    serializedComp: SerializedCompStructure,
    pageId: string,
    mappers: DeserializationMappers,
    isMobileSupportedDefaultValue: boolean | undefined,
    pageStructureToAdd: Partial<SerializedCompStructure>,
    optionals: AddComponentOptionals
) => {
    const {mobile} = createExtArgs.extensionAPI as StructureExtensionAPI
    if (!mobile.isMobileNamespaceSupported(isMobileSupportedDefaultValue)) {
        return
    }

    const mobilePageComponentPointer = createExtArgs.pointers.structure.getPage(pageId, VIEW_MODES.MOBILE)
    createExtArgs.dal.set(mobilePageComponentPointer, pageStructureToAdd)

    const mobileChildren = serializedComp.mobileComponents
    if (mobileChildren) {
        setComponentChildrenAndSlotsStructure(
            createExtArgs,
            mobilePageComponentPointer,
            mobileChildren,
            undefined,
            pageId,
            optionals,
            mappers
        )
    }
}

export function setComponentV2(
    createExtArgs: CreateExtArgs,
    compPointer: Pointer,
    containerPointer: Pointer,
    compStructure: SerializedCompStructure,
    optionals: AddComponentOptionals = {isPage: false}
) {
    const {extensionAPI, coreConfig, pointers} = createExtArgs
    const {components: componentsAPI} = extensionAPI as ComponentsExtensionAPI
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {isPage, customId, isAncestorsChecked} = optionals
    let {mappers} = optionals
    if (!mappers) {
        mappers = {
            oldToNewIdMap: {}
        }
    }
    const {hooks} = extensionAPI as HooksExtensionApi

    const serializedComp = buildSerializeComp(componentsAPI, compStructure)
    fixAppDataMissingAppDefId(createExtArgs, serializedComp)
    componentsAPI.sanitation.sanitizeSerializedComponent(
        serializedComp,
        extensionAPI as ExtensionAPI,
        coreConfig.experimentInstance,
        coreConfig.logger
    )
    //todo execute HOOKS.ADD.BEFORE
    const validationResult = componentsAPI.validation.validateComponentToSet(
        serializedComp,
        customId,
        containerPointer,
        isPage
    )

    if (!validationResult.success) {
        throw new ReportableError({
            errorType: 'addComponentValidation',
            message: validationResult.error,
            extras: {
                componentPointer: compPointer,
                containerPointer
            }
        })
    }

    const clonedSerializedComp = deepClone(serializedComp)
    mapOldIdToNewId(clonedSerializedComp, compPointer, mappers)
    clonedSerializedComp.id = getRepeaterTemplateId(compPointer.id)

    updateDefinitionTypeByComponentType(extensionAPI, clonedSerializedComp)
    componentsAPI.sanitation.sanitizeCompLayout(clonedSerializedComp)
    componentsAPI.validation.validateCompConnections(clonedSerializedComp)

    const pageId = isPage ? compPointer.id : pointers.structure.getPageOfComponent(containerPointer).id
    updateVariantsInStructure(
        {
            createExtArgs,
            compStructure: clonedSerializedComp,
            containerPointer,
            mappers,
            pageId
        },
        isAncestorsChecked
    )
    updateComponentVariablesDefinition(
        {
            createExtArgs,
            pageId,
            containerPointer,
            isPage,
            mappers,
            compStructure: clonedSerializedComp
        },
        isAncestorsChecked
    )
    componentsAPI.deserialization.deserializeComponentData(
        clonedSerializedComp,
        pageId,
        mappers,
        isPage,
        customId,
        optionals.stylesPerPage
    )

    const children = serializedComp.components
    if (children) {
        clonedSerializedComp.components = []
    }

    resetMobileComponentsForClonedComp(serializedComp, clonedSerializedComp)
    const structureToAdd = omitPropertiesFromSerializedComp(clonedSerializedComp)
    structureToAdd.id = compPointer.id

    dataModel.addItem(structureToAdd, compPointer.type, pageId)

    if (!isPage) {
        updateContainerChildren(createExtArgs, containerPointer, structureToAdd, compPointer)
        updateDisplayedOnlyComponentsDefs(
            createExtArgs,
            pageId,
            compPointer,
            compStructure,
            serializedComp,
            isPage ?? false,
            mappers
        )
    }

    createTranslationsForComp(createExtArgs, compPointer, clonedSerializedComp)

    setComponentChildrenAndSlotsStructure(
        createExtArgs,
        compPointer,
        children,
        clonedSerializedComp.slots,
        pageId,
        optionals,
        mappers
    )
    if (isPage) {
        setMobileDefinitionsForPage(
            createExtArgs,
            serializedComp,
            pageId,
            mappers,
            optionals.isMobileSupportedDefaultValue,
            structureToAdd,
            optionals
        )
    }

    // todo     const afterArgs = [ps, compToAddPointer, clonedSerializedComp, customId, mappers, containerPointer]
    hooks.executeHook(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.createEvent({
            componentType: compStructure.componentType,
            compToAddPointer: compPointer,
            componentDefinition: compStructure,
            optionalCustomId: customId,
            mappers,
            containerPointer
        }),
        compStructure.componentType
    )
    hooks.executeHook(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER_FROM_EXT.createEvent({
            componentType: compStructure.componentType,
            compToAddPointer: compPointer,
            componentDefinition: compStructure,
            optionalCustomId: customId,
            mappers,
            containerPointer
        } as ComponentAfterAddDataFromExtEvent),
        compStructure.componentType
    )
    return compPointer
}

const getComponentToAddRef = (containerPointer: Pointer, customId?: string) => {
    const id = customId ?? generateItemIdWithPrefix('comp')
    const {getPointer} = pointerUtils
    return getPointer(id, containerPointer.type)
}

const updateComponentSlotsStructure = (
    createExtArgs: CreateExtArgs,
    pageId: string,
    slots: SerializedSlotStructure,
    containerPointer: Pointer,
    mappers?: DeserializationMappers
) => {
    const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI
    const {type, slots: slotComponents} = slots
    const addedSlotComponents = _.mapValues(slotComponents, childSlotDefinition => {
        const compPointer = getComponentToAddRef(containerPointer)
        setComponentV2(createExtArgs, compPointer, containerPointer, childSlotDefinition, {mappers, isPage: false})
        return compPointer.id
    })

    const slotsData: any = {slots: {...addedSlotComponents}, type}
    const slotsDataId = dataModel.addItem(slotsData, DATA_TYPES.slots, pageId).id
    slotsData.id = slotsDataId
    addIdToMap(slotsData, slotsDataId, mappers?.oldToNewIdMap)

    createExtArgs.dal.set(createExtArgs.pointers.getInnerPointer(containerPointer, ['slotsQuery']), slotsDataId)
}

function setComponentChildrenAndSlotsStructure(
    createExtArgs: CreateExtArgs,
    containerPointer: Pointer,
    children: SerializedCompStructure[] | undefined,
    slots: SerializedSlotStructure | undefined,
    pageId: string,
    optionals: AddComponentOptionals,
    mappers?: DeserializationMappers
) {
    if (slots) {
        updateComponentSlotsStructure(createExtArgs, pageId, slots, containerPointer, mappers)
    }

    if (children) {
        _.forEach(children, child => {
            const newId = child.id ? mappers?.oldToNewIdMap?.[child.id] : undefined
            const childPointer = getComponentToAddRef(containerPointer, newId)
            setComponentV2(createExtArgs, childPointer, containerPointer, child as SerializedCompStructure, {
                ...optionals,
                isPage: false,
                mappers,
                customId: newId
            })
        })
    }
}

export const addComponentV2Internal = (
    createExtArgs: CreateExtArgs,
    componentPointer: Pointer,
    containerPointer: Pointer,
    compStructure: SerializedCompStructure,
    optionals?: AddComponentOptionals
) => {
    const containerToAddTo = getContainerToAddTo(createExtArgs, containerPointer)
    const validationResult: Result = validateComponentToAdd(
        createExtArgs,
        componentPointer,
        compStructure,
        containerPointer,
        optionals?.index
    )
    if (!validationResult.success) {
        throw new ReportableError({
            errorType: 'addComponentValidation',
            message: validationResult.error,
            extras: {
                componentPointer,
                containerPointer
            }
        })
    }

    // todo execute HOOKS.ADD_ROOT.BEFORE

    setComponentV2(createExtArgs, componentPointer, containerPointer, compStructure, optionals)

    if (containerToAddTo && !_.isEqual(containerToAddTo, containerPointer)) {
        const {hooks} = createExtArgs.extensionAPI as HooksExtensionApi
        hooks.executeHook(
            COMPONENTS_HOOKS.ADD_COMPONENT.ADJUST_COMP_LAYOUT.createEvent({
                componentType: compStructure.componentType,
                compToAddPointer: componentPointer,
                componentDefinition: compStructure,
                optionalCustomId: optionals?.customId,
                mappers: optionals?.mappers,
                containerPointer: containerToAddTo
            } as AdjustComponentLayoutFromExtEvent),
            'mobile.core.components.Page'
        )
        //todo addCompToContainer
    }
    // todo more code here
    // todo execute HOOKS.ADD_ROOT.AFTER
    return componentPointer
}
