import type {PS} from '@wix/document-services-types'
import {getGlobalOverrides} from '@wix/fedops-logger'
import {coreUtils} from '@wix/santa-ds-libs'
import experiment from 'experiment-amd'
import _ from 'lodash'
import siteMetadata from '../../siteMetadata/siteMetadata'
import originService from '../services/originService'
import getEditorSdkUrl from './getEditorSdkUrl'

let _currentHandler

const {urlUtils} = coreUtils
const {joinURL} = urlUtils

const isWebWorker = (worker: Worker) => {
    return _.isFunction(worker.addEventListener)
}

const addWorkerHandler = function (handler, _appsContainer: Worker) {
    if (_currentHandler) {
        if (isWebWorker(_appsContainer)) {
            _appsContainer.removeEventListener('message', handler)
        } else {
            ;(_appsContainer as any).off('message', _currentHandler)
        }
        _currentHandler = null
    }

    _currentHandler = handler
    if (isWebWorker(_appsContainer)) {
        _appsContainer.addEventListener('message', handler)
    } else {
        _currentHandler = (message: any) => handler({data: message, target: _appsContainer, ...message})
        ;(_appsContainer as any).on('message', _currentHandler)
    }
}

const semverInUrlRegex = /\d+\.\d+\.\d+/

const replaceVersion = function (base: string, version: string) {
    return base.replace(semverInUrlRegex, version)
}

const isSemver = function (str: string) {
    return /^\d+\.\d+\.\d+$/.test(str)
}

const isLocalVersion = function (platformSource: string) {
    return /^https?\:\/\/localhost/.test(platformSource)
}

const getBaseUrl = function (
    appsContainerBase: string,
    scriptsDomainUrl: string,
    baseDomain: string,
    platformSource: string,
    servePlatformSourceAsHttp: string
) {
    if (isLocalVersion(platformSource)) {
        return platformSource
    }

    const urlToReplace = _.trimEnd(joinURL(scriptsDomainUrl, 'services'), '/')

    let baseUrl = appsContainerBase.replace(urlToReplace, _.trimEnd(baseDomain, '/'))

    if (isSemver(platformSource)) {
        baseUrl = replaceVersion(baseUrl, platformSource)
    }

    if (servePlatformSourceAsHttp === 'true') {
        baseUrl = baseUrl.replace(/^https:\/\//, 'http://')
    }

    return baseUrl
}

const getSentryEnv = (ps: PS): 'production' | 'sled' | 'development' => {
    // https://github.com/wix-private/sled/blob/c07286aac964a5acc4a1cb1e8c213c3b1fc0bf5d/packages/sled-test-runner/src/jest-runtime/lib/sled-global.ts#L138
    if (/yes_this_is_sled/.test(window.document.cookie)) {
        return 'sled'
    }

    if (ps.siteAPI.getQueryParam('debug')) {
        return 'development'
    }

    return 'production'
}

const getAppsWorkerInitializeParams = (
    ps: PS,
    config: {extraNamespaces?: string[]} = {},
    cookiesOverride = null,
    requestId = null
) => {
    const sdkVersion = getEditorSdkUrl(ps)
    const extraNamespaces = _.isEmpty(config.extraNamespaces) ? undefined : _.join(config.extraNamespaces, ',')
    const metaSiteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID)
    const userId = _.get(siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.USER_INFO), 'userId')
    const editorType = originService.getEditorType()
    const commonConfig = ps.dal.get(ps.pointers.general.getCommonConfig())
    const sentryEnv = ps.siteAPI.getQueryParam('sentryEnv')
    const initParams: any = {sdkVersion, extraNamespaces, metaSiteId, userId, editorType, commonConfig, sentryEnv}
    if (experiment.isOpen('dm_platformWorkerWixExpConfig')) {
        initParams.wixExpConfig = {
            expUseNewApi: true,
            expConductionMethod: 'owner-account',
            ownerId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.USER_ID)
        }
    }
    if (experiment.isOpen('dm_platformWorkerFedopsLoggerDefaultsOverride')) {
        initParams.fedopsLoggerDefaultOverrides = getGlobalOverrides()
    }

    if (ps.siteAPI.getQueryParam('editorScriptHot') === 'true') {
        initParams.editorScriptHot = true
    }
    initParams.concurrentEditing = false

    if (cookiesOverride) {
        initParams.cookiesOverride = cookiesOverride
    }

    if (requestId) {
        initParams.requestId = requestId
    }

    if (!sentryEnv) {
        initParams.sentryEnv = getSentryEnv(ps)
    }

    return initParams
}

const getAppsContainerUrl = function (ps: PS) {
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    const appsContainerBase = serviceTopology.scriptsLocationMap['santa-platform-apps-container']
    const baseDomain = serviceTopology.editorWorkerBaseUrl
    const currentUrl = ps.siteAPI.getCurrentUrl()
    const query = _.get(currentUrl, 'query')
    const {scriptsDomainUrl} = serviceTopology
    const baseUrl = getBaseUrl(
        appsContainerBase,
        scriptsDomainUrl,
        baseDomain,
        query.PlatformContainerSource,
        query.PlatformContainerHttp
    )

    return joinURL(baseUrl, 'dist/platform-worker.js')
}

function isLocalWorker(urlBase: string) {
    const localhostRegex = /^https?:\/\/localhost($|:|\/)/
    return localhostRegex.test(urlBase) ? urlBase : null
}

function createWorkerBlobUrl(workerUrlOverride: string) {
    const blobUrl = new Blob([`importScripts('${workerUrlOverride}');`], {type: 'application/javascript'})
    return URL.createObjectURL(blobUrl)
}

export default {
    getEditorSdkUrl,
    addWorkerHandler,
    getAppsContainerUrl,
    getAppsWorkerInitializeParams,
    isLocalWorker,
    createWorkerBlobUrl
}
