import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import type {BehaviorDef, Pointer, SavedBehavior, BehaviorObject} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import {DATA_TYPES, VIEW_MODES} from '../constants/constants'

export interface ActionsAndBehaviorsAPI {
    getBehaviors(actionSourceRef: Pointer): any
    isCodeBehavior(behavior: BehaviorDef): boolean
    removeComponentsBehaviorsWithFilter(componentPointer: Pointer, filterObject: any): void
}

export type ActionsAndBehaviorsExtensionAPI = ExtensionAPI & {
    actionsAndBehaviors: ActionsAndBehaviorsAPI
}

const CODE_BEHAVIOR_NAMES = {runCode: true, runAppCode: true}
const CODE_BEHAVIOR_TYPE = 'widget'

const createExtension = (): Extension => {
    const createExtensionAPI = ({pointers, extensionAPI, dal}: CreateExtArgs): ActionsAndBehaviorsExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const getOriginalCompRef = (actionSourceRef: Pointer) => {
            if (displayedOnlyStructureUtil.isRepeatedComponent(actionSourceRef.id)) {
                const originalId = displayedOnlyStructureUtil.getRepeaterTemplateId(actionSourceRef.id)
                const pagePointer = pointers.structure.getPageOfComponent(actionSourceRef)
                const originalCompPointer = pointers.structure.getComponent(originalId, pagePointer)
                return originalCompPointer
            }
            return actionSourceRef
        }

        const getBehaviors = (actionSourceRef: Pointer) => {
            const compRef = getOriginalCompRef(actionSourceRef)
            const behaviors = dataModel.components.getItem(compRef, DATA_TYPES.behaviors)

            return behaviors?.items ? JSON.parse(behaviors.items) : []
        }

        const isCodeBehavior = (behavior: BehaviorDef) => {
            const {type, name} = behavior || {}
            return type === CODE_BEHAVIOR_TYPE && _.has(CODE_BEHAVIOR_NAMES, name)
        }
        const toDesktopComponent = (componentPointer: Pointer): Pointer => {
            return _.defaults({type: VIEW_MODES.DESKTOP}, componentPointer)
        }
        const isMobileAndHasDesktopComponent = (componentPointer: Pointer) => {
            return (
                _.get(componentPointer, ['type']) === VIEW_MODES.MOBILE && dal.has(toDesktopComponent(componentPointer))
            )
        }
        const getComponentBehaviors = (componentPointer: Pointer): BehaviorObject[] | null => {
            const compBehaviors = getBehaviors(componentPointer)
            return _.isEmpty(compBehaviors) ? null : compBehaviors
        }

        const updateBehaviorsItem = (componentPointer: Pointer, behaviorsItem: string) => {
            let behaviorsId = dataModel.components.getComponentDataItemId(componentPointer, DATA_TYPES.behaviors)
            const pageId = pointers.structure.getPageOfComponent(componentPointer).id

            const doesComponentHaveBehaviorsData = Boolean(behaviorsId)
            const itemToUpdate = dataModel.createBehaviorsItem(behaviorsItem)
            const addItemPtr = dataModel.addItem(itemToUpdate, DATA_TYPES.behaviors, pageId, behaviorsId)
            behaviorsId = addItemPtr.id
            if (!doesComponentHaveBehaviorsData) {
                dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
                    componentPointer,
                    behaviorsId,
                    DATA_TYPES.behaviors
                )
            }
            return behaviorsId
        }

        const updateBehaviorsInDAL = (componentPointer: Pointer, behaviors: SavedBehavior[]) => {
            const behaviorsToSet = JSON.stringify(behaviors)
            if (_.isEmpty(behaviors)) {
                dataModel.components.removeItemForDesktopAndMobile(componentPointer.id, DATA_TYPES.behaviors)
            } else {
                updateBehaviorsItem(componentPointer, behaviorsToSet)
            }
        }
        const removeComponentsBehaviorsWithFilter = (componentPointer: Pointer, filterObject: any) => {
            if (isMobileAndHasDesktopComponent(componentPointer)) {
                // if removing a mobile component's behaviors explicitly, remove the desktop behaviors first
                removeComponentsBehaviorsWithFilter(toDesktopComponent(componentPointer), filterObject)
            }
            let behaviors: SavedBehavior[] = getComponentBehaviors(componentPointer) as any
            behaviors = behaviors ? (_.reject(behaviors, filterObject) as SavedBehavior[]) : []
            updateBehaviorsInDAL(componentPointer, behaviors)
        }

        return {
            actionsAndBehaviors: {
                getBehaviors,
                isCodeBehavior,
                removeComponentsBehaviorsWithFilter
            }
        }
    }

    return {
        name: 'actionsAndBehaviors',
        createExtensionAPI,
        dependencies: new Set(['structure', 'dataModel'])
    }
}

export {createExtension}
