import {setElementStyle} from './elementStyleBuilder'
export const createIndicationElement = (
    document: Document,
    boxElement: HTMLElement,
    indication: string,
    color: string,
    bottom: string,
    top: string
) => {
    const indicationElement = document.createElement('div')
    setElementStyle(indicationElement, {
        position: 'absolute',
        'background-color': color,
        'font-size': '16px',
        bottom,
        top
    })
    indicationElement.textContent = indication
    boxElement.appendChild(indicationElement)
    return indicationElement
}

export const removeIndicationElement = (boxElement: HTMLElement, indicationElement: HTMLElement) => {
    boxElement.removeChild(indicationElement)
}
