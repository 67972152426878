import type {PS, WixCodeFileDescriptor} from '@wix/document-services-types'
import {createService as createFileSystemNGService, createService} from './fileSystemServiceNG'
import type {IFileSystemService, WixCodeFile} from './IFileSystemService'
import codeAppInfoUtils, {CodeAppInfo} from '../utils/codeAppInfo'

let memoizedServiceArgs:
    | {
          extensionAPI: PS['extensionAPI']
          codeAppInfo: CodeAppInfo
          fileSystemNGService: ReturnType<typeof createService>
      }
    | undefined

export const getServiceArgsFromPS = (ps: PS) => {
    const {extensionAPI} = ps
    const codeAppInfo = codeAppInfoUtils.getCodeAppInfoFromPS(ps)
    return getServiceArgs(extensionAPI, codeAppInfo)
}

const getServiceArgs = (extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo) => {
    if (memoizedServiceArgs && memoizedServiceArgs.codeAppInfo.appId === codeAppInfo.appId) {
        return memoizedServiceArgs
    }

    memoizedServiceArgs = {
        extensionAPI,
        codeAppInfo,
        fileSystemNGService: createFileSystemNGService(extensionAPI, codeAppInfo)
    }
    return memoizedServiceArgs
}

export const fileSystemServiceFacade: IFileSystemService = {
    createFolder(ps: PS, folderName: string, parentFolder: WixCodeFileDescriptor) {
        const {fileSystemNGService} = getServiceArgsFromPS(ps)

        return fileSystemNGService.createFolder(folderName, parentFolder)
    },
    deleteItem(extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo, itemToDelete: WixCodeFileDescriptor) {
        const {fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return fileSystemNGService.deleteItem(itemToDelete)
    },
    writeFile(
        extensionAPI: PS['extensionAPI'],
        codeAppInfo: CodeAppInfo,
        file: WixCodeFileDescriptor,
        content?: string
    ) {
        const {fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return fileSystemNGService.writeFile(file, content)
    },
    move(ps: PS, itemToMove: WixCodeFileDescriptor, newParent: WixCodeFileDescriptor, newName?: string) {
        const {fileSystemNGService} = getServiceArgsFromPS(ps)
        return fileSystemNGService.move(itemToMove, newParent, newName)
    },
    copy(
        extensionAPI: PS['extensionAPI'],
        codeAppInfo: CodeAppInfo,
        itemToCopy: WixCodeFileDescriptor,
        newParent: WixCodeFileDescriptor,
        newName?: string
    ) {
        const {fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return fileSystemNGService.copy(itemToCopy, newParent, newName)
    },
    getChildren(ps: PS, parentFolder: WixCodeFileDescriptor) {
        const {fileSystemNGService} = getServiceArgsFromPS(ps)
        return fileSystemNGService.getChildren(parentFolder)
    },
    readAllFiles(ps: PS) {
        const {fileSystemNGService} = getServiceArgsFromPS(ps)
        return fileSystemNGService.readAllFiles()
    },
    readFile(ps: PS, file: WixCodeFileDescriptor) {
        const {fileSystemNGService} = getServiceArgsFromPS(ps)
        return fileSystemNGService.readFile(file)
    },
    bulkWrite(extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo, files: WixCodeFile[]) {
        const {fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return fileSystemNGService.bulkWrite(files)
    }
}
