import _ from 'lodash'
import hooks from './hooks'
import wphoto from './layoutHooks/wphoto'
import mediaPlayer from './layoutHooks/mediaPlayer'
import wFacebookComment from './layoutHooks/wFacebookComment'
import documentMedia from './layoutHooks/documentMedia'
import verticalMenu from './layoutHooks/verticalMenu'
import expandableMenu from './layoutHooks/expandableMenu'
import slideshow from './layoutHooks/slideShow'
import stateBox from './layoutHooks/stateBox'
import tinyMenu from './layoutHooks/tinyMenu'
import columns from './layoutHooks/columns'
import column from './layoutHooks/column'
import verticalAnchorsMenu from './layoutHooks/verticalAnchorsMenu'
import repeaterHooks from './componentHooks/repeaterHooks'
import popoverHooks from './layoutHooks/popoverLayoutHooks'
import wRichText from './layoutHooks/wRichText'
import fixedInProps from './layoutHooks/fixedInProps'
import appWidget from './layoutHooks/appWidget'
import matrixGallery from './layoutHooks/matrixGallery'
import refComponent from './layoutHooks/refComponent'
import tabs from './layoutHooks/tabs'
import accordionContainer from './layoutHooks/accordionContainer'

init()

function init() {
    //before
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, wphoto, 'wysiwyg.viewer.components.WPhoto')
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        documentMedia,
        'wysiwyg.viewer.components.documentmedia.DocumentMedia'
    )

    _.forEach(['wysiwyg.viewer.components.BoxSlideShow', 'wysiwyg.viewer.components.StripContainerSlideShow'], type =>
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, slideshow, type)
    )

    _.forEach(['wysiwyg.viewer.components.StateBox', 'wysiwyg.viewer.components.StateStrip'], type =>
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, stateBox.applyLayoutToStateBoxChildrenBeforeUpdate, type)
    )
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, tabs.applyLayoutToTabsChildrenBeforeUpdate, 'wixui.Tabs')
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        accordionContainer.applyLayoutBeforeUpdate,
        'wixui.AccordionContainer'
    )

    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, columns, 'wysiwyg.viewer.components.StripColumnsContainer')
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, tinyMenu, 'wysiwyg.viewer.components.mobile.TinyMenu')
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, wRichText, 'wysiwyg.viewer.components.WRichText')
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        verticalMenu.before,
        'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu'
    )
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, fixedInProps, 'wysiwyg.viewer.components.PopupContainer')
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        mediaPlayer.positionBigPlay,
        'wysiwyg.viewer.components.MediaOverlayControls'
    )
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        mediaPlayer.positionControlsBar,
        'wysiwyg.viewer.components.MediaControls'
    )
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        popoverHooks.updateChildLayout,
        'wysiwyg.viewer.components.Popover'
    )
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        appWidget.applyLayoutToWidgetRootOnLayoutUpdate,
        'platform.components.AppWidget'
    )
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        matrixGallery.before,
        'wysiwyg.viewer.components.MatrixGallery'
    )
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, refComponent, 'wysiwyg.viewer.components.RefComponent')

    //after
    _.forEach(['wysiwyg.viewer.components.StateBox', 'wysiwyg.viewer.components.StateStrip'], type =>
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, stateBox.applyLayoutToStateBoxChildrenAfterUpdate, type)
    )

    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_AFTER,
        appWidget.applyLayoutToWidgetRootAfterLayoutUpdate,
        'platform.components.AppWidget'
    )
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_AFTER,
        repeaterHooks.afterLayoutChanged,
        'wysiwyg.viewer.components.Repeater'
    )
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, wFacebookComment, 'wysiwyg.viewer.components.WFacebookComment')
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_AFTER,
        expandableMenu.after,
        'wysiwyg.viewer.components.ExpandableMenu'
    )
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_AFTER,
        verticalAnchorsMenu,
        'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu'
    )
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, column, 'wysiwyg.viewer.components.Column')
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_AFTER,
        mediaPlayer.layoutPlayerChildren,
        'wysiwyg.viewer.components.MediaPlayer'
    )
}
