import type {CreateExtArgs, DAL, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {
    Pointer,
    Pointers,
    WixCodeConnectionItem,
    IConnectionItem,
    ConnectionItem,
    Connection,
    ControllerConnectionItem
} from '@wix/document-services-types'
import _ from 'lodash'
import type {TPAExtensionAPI} from './tpa'
import type {ComponentsMetadataAPI} from './componentsMetadata/componentsMetadata'
import {getComponentIncludingDisplayOnly, getComponentType} from '../utils/dalUtils'
import {COMP_TYPES, DATA_TYPES, MASTER_PAGE_ID} from '../constants/constants'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {RelationshipsAPI} from './relationships'
const INVALID_ROLE = '*'
import {isRefPointer} from '../utils/refStructureUtils'

export type ValidateConnection = (
    connectionItem: ConnectionItemForValidation,
    otherConnectionItems: ConnectionItemForValidation[],
    componentRef: Pointer,
    controllerRef: Pointer
) => void

export type GetConnections = (compRef: Pointer) => IConnectionItem[] | null
export type GetResolvedConnections = (compRef: Pointer) => Record<string, any>[] | null

export type GetConnectionsByDataItem = (dataItemId: string, pageId: string) => IConnectionItem[] | null
export type GetResolvedConnectionsByDataItem = (
    dataItemId: string,
    pagePointer: Pointer,
    viewMode: string
) => Record<string, any>[] | null
export type UpdateConnectionsItem = (componentPointer: Pointer, connectionsItem: IConnectionItem[]) => string
export type UpdateConnectionsItemByDataItem = (
    connectionItemId: string,
    pageId: string,
    connectionsItem: IConnectionItem[]
) => string
export type CreateWixCodeConnectionItem = (nickname: string) => WixCodeConnectionItem
export type CreateConnectionItem = (
    role: string,
    controllerId: string,
    isPrimary?: boolean,
    subRole?: string
) => ControllerConnectionItem
export interface ConnectionItemForValidation {
    isPrimary: boolean
    role: string
}

export interface ConnectionsAPI extends ExtensionAPI {
    connections: {
        connect(
            compRef: Pointer,
            controllerRef: Pointer,
            role: string,
            connectionConfig?: any,
            isPrimary?: boolean,
            subRole?: string
        ): void
        validateConnection: ValidateConnection
        get: GetConnections
        getResolved: GetResolvedConnections
        getResolvedConnectionsByDataItem: GetResolvedConnectionsByDataItem
        getConnectionsByDataItem: GetConnectionsByDataItem
        updateConnectionsItem: UpdateConnectionsItem
        updateConnectionsItemByDataItem: UpdateConnectionsItemByDataItem
        createWixCodeConnectionItem: CreateWixCodeConnectionItem
        createConnectionItem: CreateConnectionItem
    }
}

export const EVENTS = {
    CONNECTIONS: {
        UPDATE_AFTER: 'connection_update_after'
    }
}

const createExtension = (): Extension => {
    const validateConnection = (
        dal: DAL,
        pointers: Pointers,
        tpa: TPAExtensionAPI,
        componentsMetaData: ComponentsMetadataAPI,
        connectionItem: ConnectionItemForValidation,
        otherConnectionItems: ConnectionItemForValidation[],
        componentRef: Pointer,
        controllerRef: Pointer
    ): void => {
        const isOOIController = (compType: string) => {
            return tpa.tpa.isTpaByCompType(compType)
        }

        const controllerCompType = getComponentIncludingDisplayOnly(dal, controllerRef).componentType
        const connectedComponentType = getComponentIncludingDisplayOnly(dal, componentRef).componentType

        if (controllerCompType === COMP_TYPES.APP_WIDGET_TYPE) {
            if (!pointers.structure.isDescendant(componentRef, controllerRef)) {
                throw new Error('cannot connect to app widget from the outside')
            }
        } else if (controllerCompType !== COMP_TYPES.CONTROLLER_TYPE && !isOOIController(controllerCompType)) {
            throw new Error('controllerRef component type is invalid - should be a controller or current context')
        }

        if (isRefPointer(controllerRef)) {
            throw new Error('controllerRef component cannot be inflated component')
        }
        if (connectionItem.isPrimary && connectedComponentType === COMP_TYPES.CONTROLLER_TYPE) {
            throw new Error('cannot connect to another app controller with a primary connection')
        }
        if (connectionItem.role === INVALID_ROLE) {
            throw new Error('invalid connection role - cannot be *')
        }

        if (!componentsMetaData.componentsMetadata.canConnectToCode(componentRef)) {
            throw new Error('cannot connect to this component type')
        }

        if (connectionItem.isPrimary && _.some(otherConnectionItems, {isPrimary: true})) {
            throw new Error('Primary connection is already connected to the component')
        }
    }

    const createExtensionAPI = ({dal, pointers, extensionAPI, eventEmitter}: CreateExtArgs): ConnectionsAPI => {
        const tpa = extensionAPI as TPAExtensionAPI
        const componentsMetaData = extensionAPI as ComponentsMetadataAPI
        const getControllerRefFromId = (controllerDataId: string, pagePointer: Pointer, viewMode: string) => {
            const {relationships} = extensionAPI as RelationshipsAPI
            const dataPointer = pointers.data.getDataItem(controllerDataId, pagePointer.id)
            const owningReferences = relationships.getOwningReferencesToPointer(dataPointer, viewMode)
            if (owningReferences.length) {
                const component = owningReferences[0]
                const pageOfComponent = pointers.structure.getPageOfComponent(component)

                if (pointers.components.isSameComponent(pageOfComponent, pagePointer)) {
                    return component
                }
                if (pointers.components.isMasterPage(pagePointer)) {
                    return null
                }
                if (pageOfComponent.id === MASTER_PAGE_ID) {
                    return component
                }
            }
            return null
        }

        const resolveConnectionItems = (connectionsItems: ConnectionItem[], pagePointer: Pointer, viewMode: string) =>
            _.map(connectionsItems, connectionItem => {
                if (connectionItem.type === 'WixCodeConnectionItem') {
                    return connectionItem
                }

                const controllerRef = getControllerRefFromId(connectionItem.controllerId, pagePointer, viewMode)
                const newConnectionItem: ControllerConnectionItem = _.assign(
                    {},
                    _.omit(connectionItem, 'controllerId'),
                    {controllerRef}
                ) as unknown as ControllerConnectionItem
                if (!_.has(newConnectionItem, ['config'])) {
                    return newConnectionItem
                }
                newConnectionItem.config = JSON.parse(newConnectionItem.config!)
                return newConnectionItem
            })

        const getConnections = (componentPointer: Pointer): ConnectionItem[] => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const connectionsData = dataModel.components.getItem(componentPointer, DATA_TYPES.connections)
            return deepClone(_.get(connectionsData, ['items']) ?? [])
        }
        const getResolvedConnections = (componentPointer: Pointer): IConnectionItem[] => {
            const connectionsItems = getConnections(componentPointer)

            const pagePointer = pointers.structure.getPageOfComponent(componentPointer)

            return resolveConnectionItems(connectionsItems, pagePointer, componentPointer.type)
        }

        const getConnectionsByDataItem = (connectionDataItemId: string, pageId: string) => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const connectionsData = dataModel.getItem(connectionDataItemId, DATA_TYPES.connections, pageId)
            const connectionsItems = connectionsData?.items
            if (_.isEmpty(connectionsItems)) {
                return []
            }
            return connectionsItems
        }

        const getResolvedConnectionsByDataItem = (
            connectionDataItemId: string,
            pagePointer: Pointer,
            viewMode: string
        ) => {
            const connections = getConnectionsByDataItem(connectionDataItemId, pagePointer.id)

            return resolveConnectionItems(connections, pagePointer, viewMode)
        }

        const createConnectionsItem = (connections: IConnectionItem[]) =>
            dal.schema.createItemAccordingToSchema('ConnectionList', DATA_TYPES.connections, {items: connections})

        const updateConnectionsItem = (componentPointer: Pointer, connectionsItem: IConnectionItem[]): string => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            let connectionsId = dataModel.components.getComponentDataItemId(componentPointer, 'connections')
            const pageId = pointers.structure.getPageOfComponent(componentPointer).id

            const doesComponentHaveConnectionsData = Boolean(connectionsId)
            const itemToUpdate = createConnectionsItem(connectionsItem)
            const connectionsPointer = dataModel.addItem(itemToUpdate, DATA_TYPES.connections, pageId, connectionsId)
            connectionsId = connectionsPointer.id
            if (!doesComponentHaveConnectionsData) {
                dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
                    componentPointer,
                    connectionsId,
                    DATA_TYPES.connections
                )
            }
            eventEmitter.emit(EVENTS.CONNECTIONS.UPDATE_AFTER, itemToUpdate, componentPointer)
            return connectionsId
        }

        const updateConnectionsItemByDataItem = (
            connectionDataItemId: string,
            pageId: string,
            connectionsItem: IConnectionItem[]
        ): string => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const itemToUpdate = createConnectionsItem(connectionsItem)
            const connectionsPointer = dataModel.addItem(
                itemToUpdate,
                DATA_TYPES.connections,
                pageId,
                connectionDataItemId
            )
            return connectionsPointer.id
        }

        const createWixCodeConnectionItem = (nickname: string): WixCodeConnectionItem => {
            return {
                type: 'WixCodeConnectionItem',
                role: nickname
            }
        }
        const createConnectionItem = (
            role: string,
            controllerId: string,
            isPrimary: boolean = true,
            subRole?: string
        ): ControllerConnectionItem => {
            return {
                type: 'ConnectionItem',
                role,
                controllerId,
                isPrimary,
                subRole
            }
        }
        const isAppWidgetType = (compType: string) => compType === COMP_TYPES.APP_WIDGET_TYPE

        const serializeConnectionsItem = (connectionsItems: IConnectionItem[]): IConnectionItem[] => {
            return _.map(connectionsItems, function (connectionItem) {
                if (connectionItem.type === 'WixCodeConnectionItem') {
                    return connectionItem
                }
                const {dataModel} = extensionAPI as DataModelExtensionAPI
                const controllerDataItemId = dataModel.components.getComponentDataItemId(
                    (connectionItem as Connection).controllerRef,
                    'data'
                )
                const newConnectionItem: ControllerConnectionItem = _.assign(
                    {},
                    _.omit(connectionItem, 'controllerRef'),
                    {
                        controllerId: controllerDataItemId
                    }
                ) as ControllerConnectionItem
                if (!_.has(newConnectionItem, ['config'])) {
                    return newConnectionItem
                }
                try {
                    newConnectionItem.config = JSON.stringify(newConnectionItem.config)
                } catch (e) {
                    throw new Error('Invalid connection configuration - should be JSON stringifiable')
                }
                return newConnectionItem
            })
        }
        const connect = (
            compRef: Pointer,
            controllerRef: Pointer,
            role: string,
            connectionConfig?: any,
            isPrimary?: boolean,
            subRole?: string
        ) => {
            const controllerCompType = getComponentType(dal, controllerRef)

            if (isAppWidgetType(controllerCompType) && isPrimary !== false) {
                isPrimary = true
            } else {
                isPrimary = isPrimary || false
            }

            const newConnectionItem: any = {
                type: 'ConnectionItem',
                controllerRef,
                role,
                isPrimary
            }
            if (connectionConfig) {
                newConnectionItem.config = connectionConfig
            }
            if (subRole) {
                newConnectionItem.subRole = subRole
            }
            // The controllerRef might be null when reconnecting a connection to the page itself (rather than to another comp).
            // For example, if a page has a background connected to a dataset, then during the duplication of that page the
            // controllerRef might be null. This has to do with the order in which the components are added to the new page.
            // We reject connections with null controllerRefs to avoid issues in these scenarios
            const existingConnections: IConnectionItem[] = getResolvedConnections(compRef)
            const otherConnections = _(existingConnections)
                .reject({controllerRef: null} as any)
                .reject({controllerRef, role} as any)
                .value()

            validateConnection(
                dal,
                pointers,
                tpa,
                componentsMetaData,
                newConnectionItem,
                otherConnections as unknown as ConnectionItemForValidation[],
                compRef,
                controllerRef
            )
            const serializedConnection = serializeConnectionsItem(otherConnections.concat(newConnectionItem))
            updateConnectionsItem(compRef, serializedConnection)
        }
        return {
            connections: {
                connect,
                get: getConnections,
                getResolved: getResolvedConnections,
                getConnectionsByDataItem,
                getResolvedConnectionsByDataItem,
                updateConnectionsItem,
                updateConnectionsItemByDataItem,
                createWixCodeConnectionItem,
                createConnectionItem,
                validateConnection: (
                    connectionItem: ConnectionItemForValidation,
                    otherConnectionItems: ConnectionItemForValidation[],
                    componentRef: Pointer,
                    controllerRef: Pointer
                ): void =>
                    validateConnection(
                        dal,
                        pointers,
                        tpa,
                        componentsMetaData,
                        connectionItem,
                        otherConnectionItems,
                        componentRef,
                        controllerRef
                    )
            }
        }
    }

    return {
        name: 'connections',
        EVENTS,
        dependencies: new Set(['structure', 'tpa', 'componentsMetadata']),
        createExtensionAPI
    }
}

export {createExtension}
