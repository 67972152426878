import fullFunctionality from './fullFunctionalityConfig'
import {
    siteHistoryConfig,
    externalPreviewConfig,
    snapshotterConfig,
    siteHistoryResponsiveConfig
} from './lazyPageLoadingConfigs'
import responsive from './responsiveConfig'
import responsiveBlocks from './responsiveBlocksConfig'
import mobileConfig from './mobileConfig'
import test from './testConfig'
import testResponsive from './testResponsiveConfig'
import testResponsiveBlocks from './testResponsiveBlocksConfig'
import appStudio from './appStudioConfig'
import serverApi from './serverAPIConfig'
import serverApiNoMigrators from './serverAPIConfigNoMigrators'
import serverApiNoFixers from './serverAPIConfigNoFixers'
import serverApiNoFixersNoMigrators from './serverAPIConfigNoFixersNoMigrators'
import onboarding from './onboardingConfig'
import wem from './wemConfig'
import testFullFunctionality from './test-fullFunctionalityConfig'
import testFullFunctionalityWithDisableAutoSave from './test-fullFunctionalityWithDisableAutoSaveConfig'
import testFullFunctionalityWithDisableCSaveConfig from './testFullFunctionalityWithDisableCSaveConfig'
import testResponsiveWithDisableCSaveConfig from './testResponsiveWithDisableCSaveConfig'
import wix2x from './wix2xConfig'
import dsLegacyServerConfig from './dsLegacyServerConfig'
import * as getFeedbackConfig from './getFeedbackConfig'
import * as debugRevisionConfig from './debugRevisionConfig'
import * as anyIDEConfig from './anyIDEConfig'
import * as localEditorConfig from './localEditorConfig'
import * as deployerConfigs from './deployerConfig'
import mobileEditor from './mobileEditorConfig'
import domainEvents from './domainEventsConfig'
import testConfigPreview from './testConfigPreview'

const configs = {
    fullFunctionality,
    responsive,
    responsiveBlocks,
    responsiveDeployer: deployerConfigs.responsive,
    responsiveBlocksDeployer: deployerConfigs.blocks,
    mobileConfig,
    test,
    testFullFunctionality,
    testFullFunctionalityWithDisableAutoSave,
    testFullFunctionalityWithDisableCSaveConfig,
    testResponsiveWithDisableCSaveConfig,
    testResponsive,
    testResponsiveBlocks,
    appStudio,
    serverApi,
    serverApiNoMigrators,
    serverApiNoFixers,
    serverApiNoFixersNoMigrators,
    wix2x,
    onboarding,
    wem,
    dsLegacyServerConfig,
    getFeedback: getFeedbackConfig.classic,
    getFeedbackResponsive: getFeedbackConfig.responsive,
    debugRevisionClassic: debugRevisionConfig.classic,
    debugRevisionResponsive: debugRevisionConfig.responsive,
    siteHistory: siteHistoryConfig,
    siteHistoryResponsive: siteHistoryResponsiveConfig,
    externalPreview: externalPreviewConfig,
    snapshotter: snapshotterConfig,
    anyIDEClassic: anyIDEConfig.classic,
    anyIDEResponsive: anyIDEConfig.responsive,
    localEditorResponsive: localEditorConfig.responsive,
    localEditorBlocks: localEditorConfig.blocks,
    mobileEditor,
    domainEvents,
    testConfigPreview
}

export type ConfigName = keyof typeof configs

export default configs
