import type {PS} from '@wix/document-services-types'
import {coreUtils, CharSet} from '@wix/santa-ds-libs'
import theme from '../theme/theme'
import generalInfo from '../siteMetadata/generalInfo'
import once from 'lodash/once'

const fontUtils = once((ps: PS) => {
    const {createFontUtils, FONT_EXPERIMENT_NAME} = coreUtils.fontUtils
    return createFontUtils(ps.siteAPI.isExperimentOpen(FONT_EXPERIMENT_NAME))
})

/**
 * @param ps
 * returns ['googleApiUrl', 'languageStaticsUrl', 'helveticaStaticsUrl']
 * @returns {Record<string, any>}
 */
function getCssUrls(ps: PS) {
    const documentType = generalInfo.getDocumentType(ps)
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    return fontUtils(ps).getCssUrls(documentType, serviceTopology)
}

/**
 * @param ps
 * returns [{lang: 'latin', fonts:[{fontMetaData}]}]
 * @returns {{lang:string, fonts: [{fontMetaData}]}}
 */
function getSiteFontsOptions(ps: PS) {
    const documentType = generalInfo.getDocumentType(ps)
    const characterSets = theme.fonts.getCharacterSet(ps)
    return fontUtils(ps).getCurrentSelectablefontsWithParams(documentType, characterSets)
}

function getAllFontsOptions(ps: PS) {
    const POSSIBLE_CHARACTERS_SETS: CharSet[] = [
        'latin-ext',
        'cyrillic',
        'japanese',
        'korean',
        'arabic',
        'hebrew',
        'latin'
    ]
    const documentType = generalInfo.getDocumentType(ps)
    return fontUtils(ps).getCurrentSelectablefontsWithParams(documentType, POSSIBLE_CHARACTERS_SETS)
}

export default {
    css: {
        getCssUrls
    },
    getSiteFontsOptions,
    getAllFontsOptions
}
