import _ from 'lodash'
import errorConstants from '../../errors/errors'
import wixCodeConstants from '../../wixCode/utils/constants'
import type {ErrorInfo} from '../saveErrors'
import type {SaveServerResponse} from './saveDocument'
import {findOption, saveErrors} from '@wix/document-manager-utils'

const {save} = errorConstants

const convertValidationError = (response: SaveServerResponse): string | undefined => {
    const {payload} = response
    const payloadToErrorMap = [
        ['duplicateComponents', save.DUPLICATE_COMPONENTS],
        ['dataReferenceMismatches', save.DATA_REFERENCE_MISMATCH],
        ['missingContainers', save.MISSING_CONTAINERS],
        ['appControllerReferenceMismatches', save.APP_CONTROLLER_REFERENCE_MISMATCH],
        ['connectionListReferenceMismatches', save.CONNECTION_LIST_REFERENCE_MISMATCHES],
        ['styleReferenceMismatches', save.STYLE_REFERENCE_MISMATCHES],
        ['behaviorReferenceMismatches', save.BEHAVIOR_REFERENCE_MISMATHCHES],
        ['propertyReferenceMismatches', save.PROPERTY_REFERENCE_MISMATCHES],
        ['designReferenceMismatches', save.DESIGN_REFERENCE_MISMATCHES]
    ]

    return findOption(payloadToErrorMap, ([key]) => !_.isEmpty(payload[key]))
        .map<string>(([, value]) => value)
        .getOrElseOption(() =>
            findOption(payload.fatalErrorsNamesAndCount, ({name}) => name === 'MissingReference').map(
                () => save.MISSING_REFERENCE
            )
        )
        .getOrDefault(undefined)
}

const ERROR_CODE_MAPPING: Record<string, string | ((response: SaveServerResponse) => string)> = {
    '-12': save.SESSION_EXPIRED,
    '-15': save.NOT_LOGGED_IN,
    '-17': save.USER_NOT_AUTHORIZED_FOR_SITE,
    '-10163': save.PARTIAL_SAVE_THROTTLE,
    '-10156': save.SITE_DELETED,
    '-10134': save.FAILED_TO_PARSE_REVIEW_TOKEN,
    '-10135': save.REVIEW_TOKEN_EXPIRED,
    '-10116': save.CONCURRENT_SAVE,
    '-40003': save.SITE_NAME_TAKEN,
    '-10132': save.SAVE_PUBLISH_DISABLED_ON_SERVER,
    '-10154': save.SAVE_PUBLISH_RC_FAILED_ON_SERVER,
    '-10157': save.USER_BLOCKED_FOR_PUBLISH,
    '-10160': save.OUTDATED_LAST_TX_ID,
    '-10104': convertValidationError,
    '-10145': save.CONCURRENT_AUTO_SAVE,
    '-10148': save.SITE_STALE_STATE_FROM_AUTO_SAVE,
    '-10124': save.SITE_DESERIALIZATION_ERROR,
    '-41231': save.TOO_MANY_SITES_FOR_FREE_USER,
    '-10933': save.RC_EXISTS,
    '-10934': save.RC_ROLLED_OUT,
    '-1888': save.IDENTITY_UNKNOWN_RUNTIME_ERROR,
    '-41239': save.USER_BLOCKED_FOR_PUBLISH,
    '-10902': save.HTTP_REQUEST_ERROR,
    '-41218': save.SITE_DELETED,
    '-74770': save.HTTP_REQUEST_ERROR,
    // platform errors / framework errors
    '-7002': save.TRANSPORTATION_ERROR, // EC_RpcTransportError
    '-10117': save.STATIC_DOWNLOAD_FAILED, // DS_StaticDownloadException
    '-40108': save.USER_NOT_AUTHORIZED_FOR_SITE, // MetaSiteNotFoundInContextException / NoPermissionsException
    '-14': save.USER_NOT_AUTHORIZED_FOR_SITE // EC_AccessDenied
    /*
    -100	3584	EC_Unknown			UNKNOWN_SERVER_ERROR
    -7003	1475	EC_RpcInvocationError			UNKNOWN_SERVER_ERROR	Network error
    -10200	521	SETTLE_FAILED			SETTLE_FAILED	Int id exists
    -10903	305	UNKNOWN_SERVER_ERROR	link		UNKNOWN_SERVER_ERROR	UNKNOWN_SERVER_ERROR
    -1043	265	EC_IllegalArgumentException
    -1020	239	EC_ValidationException
    -2002	159	EC_SPRING_NonTransientDataAccess
    -10140	86	PAGE_DATA_NOT_FOUND_DURING_PARTIAL_UPDATE
    -16	72	EC_OfacCountryNotAllowed			OFAC_COUNTRY_ERROR
    -2004	14	EC_SPRING_RecoverableDataAccess
    -10107	11	DS_DataSanitationViolation
    -10130	6	HEAD_TAGS_VALIDATION_FAILED
    -10208	5	INVALID_FOOTER / StoredPageDeserializationError	Duplicated Error Code		-10124	SITE_DESERIALIZATION_ERROR - but shows a this message which is not suitable here
     */
}

const staticCodeMapping = {...ERROR_CODE_MAPPING, '-10104': save.DATA_REFERENCE_MISMATCH}

const VALIDATION_ERROR_TYPES = [
    save.DUPLICATE_COMPONENTS,
    save.DATA_REFERENCE_MISMATCH,
    save.MISSING_CONTAINERS,
    save.APP_CONTROLLER_REFERENCE_MISMATCH,
    save.DESIGN_REFERENCE_MISMATCHES,
    save.BEHAVIOR_REFERENCE_MISMATHCHES,
    save.PROPERTY_REFERENCE_MISMATCHES,
    save.STYLE_REFERENCE_MISMATCHES,
    save.CONNECTION_LIST_REFERENCE_MISMATCHES,
    save.MISSING_REFERENCE
]

const REST_TO_DS_ERROR_CODE_MAPPING = {
    RC_EXISTS: '-10933',
    RC_ROLLED_OUT: '-10934'
}

const REST_TO_DS_ERROR_MAPPING = {
    RC_EXISTS: save.RC_EXISTS,
    RC_ROLLED_OUT: save.RC_ROLLED_OUT
}

const PARTIAL_UPDATE_CODE_MAPPING = {
    CLONE_GRID_APP_FAILED: saveErrors.CLONE_GRID_APP_FAILED,
    ABAC: saveErrors.IDENTITY_UNKNOWN_RUNTIME_ERROR
}
const partialUpdateErrorsRegex = new RegExp(Object.keys(PARTIAL_UPDATE_CODE_MAPPING).join('|'))

const extractErrorTypeFromDescription = (response: SaveServerResponse): string | undefined => {
    const {errorDescription} = response
    const [matchedCode] = errorDescription?.match(partialUpdateErrorsRegex) ?? []
    return PARTIAL_UPDATE_CODE_MAPPING[matchedCode]
}

function getErrorType(response: SaveServerResponse) {
    let errorType = ERROR_CODE_MAPPING[response.errorCode]
    errorType = errorType || extractErrorTypeFromDescription(response)

    if (_.isFunction(errorType)) {
        errorType = errorType(response)
    }
    return errorType || save.UNKNOWN_SERVER_ERROR
}

function isValidationError(response: SaveServerResponse) {
    return _.includes(VALIDATION_ERROR_TYPES, getErrorType(response))
}

export interface Change {
    path: string[]
    value: any
}

export interface SaveResult {
    changes: Change[]
    historyAlteringChanges?: Change[]
}

function getHistoryAlteringChanges(itemsToDelete) {
    const changes: Change[] = []
    _.forEach(itemsToDelete, (itemsMap, pageId) => {
        _.forEach(itemsMap, (deletedIds, dataType) => {
            _.forEach(deletedIds, deletedItemId => {
                changes.push({
                    path: ['pagesData', pageId, 'data', dataType, deletedItemId],
                    value: undefined
                })
            })
        })
    })
    return changes
}

const createErrorObjectFromRestException = async (
    error: any /*: Response | SaveServerResponse*/
): Promise<ErrorInfo> => {
    const errorDetails = await error.json()
    const code = _.get(errorDetails, 'details.applicationError.code')
    if (_.has(REST_TO_DS_ERROR_MAPPING, code)) {
        return {
            errorType: REST_TO_DS_ERROR_MAPPING[code],
            errorCode: REST_TO_DS_ERROR_CODE_MAPPING[code],
            errorDescription: _.get(errorDetails, 'details.applicationError.data')
        }
    }
    return createErrorObject(error)
}

function addWixCodeSavedGridAppToResult(extensionsAPI, returnedPayload, result: SaveResult) {
    extensionsAPI.wixCodeFileSystem.snapshots.takeTaggedSnapshot('revision')
    if (returnedPayload.wixCodeModel?.appData?.codeAppId) {
        result.changes.push({
            path: wixCodeConstants.paths.REVISION_GRID_APP_ID,
            value: returnedPayload.wixCodeModel.appData.codeAppId
        })
    }
}

function createErrorObject(response: SaveServerResponse): ErrorInfo {
    return {
        errorCode: response.errorCode,
        errorType: getErrorType(response),
        errorDescription: response.errorDescription
    }
}

export {
    staticCodeMapping,
    ERROR_CODE_MAPPING,
    VALIDATION_ERROR_TYPES,
    isValidationError,
    getHistoryAlteringChanges,
    createErrorObject,
    addWixCodeSavedGridAppToResult,
    createErrorObjectFromRestException,
    getErrorType
}
