import _ from 'lodash'
import {pointerUtils, type CreateExtArgs, type DAL} from '@wix/document-manager-core'
import {getComponentType} from '../../../utils/dalUtils'
import type {ComponentDefinitionExtensionAPI} from '../../componentDefinition'
import type {CompRef, CompStructure, Pointer} from '@wix/document-services-types'
import {
    COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE,
    CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE,
    SITE_SEGMENT_CONTAINERS
} from './componentConstants'
import {dockUtils} from '@wix/santa-core-utils'
import {LEGACY_FULL_WIDTH_CONTAINERS} from '../legacyFullWidthContainers'
import type {ComponentsMetadataAPI} from '../componentsMetadata'
import {isHeaderOrFooterOrPageOrMasterPage, isPopupContainer, isPopupContainerByType} from './pageUtils'
import {METADATA_TYPES, VIEW_MODES} from '../../../constants/constants'
import {getRepeaterAncestor, isRepeatedComponent} from '../../../utils/repeaterUtils'

const {getRepeatedItemPointerIfNeeded} = pointerUtils

export const isComponentAContainer = (createExtArgs: CreateExtArgs, componentPointer: Pointer): boolean => {
    const {componentDefinition} = createExtArgs.extensionAPI as ComponentDefinitionExtensionAPI

    return componentDefinition.isContainer(getComponentType(createExtArgs.dal, componentPointer))
}

export const isComponentSuitableForNonRenderingState = (componentType: string) => {
    const list = _.without(
        COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE,
        'wysiwyg.viewer.components.StripColumnsContainer'
    )
    return !_.includes(list, componentType)
}

export const isComponentSuitableForNonRenderingStateByPointer = (dal: DAL, componentPointer: Pointer) => {
    const componentType = getComponentType(dal, componentPointer)
    return isComponentSuitableForNonRenderingState(componentType)
}

export const isContainerSuitableForNonRenderingState = (componentType: string): boolean => {
    return _.includes(CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE, componentType)
}

export const isLegacyFullWidthContainerByType = (compType: string) => {
    return !!(LEGACY_FULL_WIDTH_CONTAINERS[compType] && !_.isFunction(LEGACY_FULL_WIDTH_CONTAINERS[compType]))
}

export const defaultIsFullWidthByStructure = (compStructure: CompStructure) => {
    return (
        dockUtils.isHorizontalDockToScreen(compStructure.layout) ||
        isLegacyFullWidthContainerByType(compStructure.componentType)
    )
}

export const isLegacyFullWidthContainer = (createExtArgs: CreateExtArgs, compPtr: Pointer) => {
    const compType = getComponentType(createExtArgs.dal, compPtr)
    const isLegacyFullWidth = LEGACY_FULL_WIDTH_CONTAINERS[compType]

    if (_.isFunction(isLegacyFullWidth)) {
        return isLegacyFullWidth(createExtArgs, compPtr)
    }

    return !!isLegacyFullWidth
}

export const defaultIsFullWidth = (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
    const compLayoutPointer = pointerUtils.getInnerPointer(compPointer, 'layout')
    const compLayout = createExtArgs.dal.get(compLayoutPointer)

    return dockUtils.isHorizontalDockToScreen(compLayout) || isLegacyFullWidthContainer(createExtArgs, compPointer)
}

const isCompFullWidth = ({extensionAPI}: CreateExtArgs, comp: CompStructure | Pointer, isByStructure: boolean) => {
    const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
    return isByStructure
        ? componentsMetadata.isFullWidthByStructure(comp as CompStructure)
        : componentsMetadata.isFullWidth(comp as Pointer)
}

const isSiteSegmentContainer = (componentType: string) => {
    return isHeaderOrFooterOrPageOrMasterPage(componentType) || _.includes(SITE_SEGMENT_CONTAINERS, componentType)
}

const isContainerWideEnoughForComp = (
    createExtArgs: CreateExtArgs,
    containedComp: CompStructure | Pointer,
    potentialContainerPointer: Pointer,
    isByStructure: boolean
): boolean => {
    const potentialContainerCompType = getComponentType(createExtArgs.dal, potentialContainerPointer)

    const isContainedFullWidth = isCompFullWidth(createExtArgs, containedComp, isByStructure)
    const isPotentialContainerFullWidth = isCompFullWidth(createExtArgs, potentialContainerPointer, false)
    const isPotentialContainerSiteSegment = isSiteSegmentContainer(potentialContainerCompType)

    return !isContainedFullWidth || isPotentialContainerFullWidth || isPotentialContainerSiteSegment
}

const isValidPageSwitch = (
    createExtArgs: CreateExtArgs,
    compPointer: Pointer,
    potentialContainerPointer: Pointer
): boolean => {
    const {pointers} = createExtArgs
    const {componentsMetadata} = createExtArgs.extensionAPI as ComponentsMetadataAPI

    if (!pointers.structure.isMobile(compPointer) && !pointers.structure.isMobile(potentialContainerPointer)) {
        return true
    }
    const desktopPageOfComp = pointers.structure.getPageOfComponent(
        pointers.structure.getDesktopPointer(compPointer)
    )?.id
    const newPage = pointers.structure.getPageOfComponent(potentialContainerPointer)?.id

    return componentsMetadata.isMobileOnly(compPointer) || desktopPageOfComp === newPage
}

const isSwitchingScopesAllowed = (
    createExtArgs: CreateExtArgs,
    componentPointer: Pointer,
    potentialContainerPointer: Pointer,
    isMobileDocumentMode?: boolean
): boolean => {
    const compPageId = createExtArgs.pointers.structure.getPageOfComponent(componentPointer).id
    const containerPageId = createExtArgs.pointers.structure.getPageOfComponent(potentialContainerPointer).id

    if (compPageId !== containerPageId) {
        if (
            !createExtArgs.pointers.structure.isInMasterPage(componentPointer) &&
            !createExtArgs.pointers.structure.isInMasterPage(potentialContainerPointer)
        ) {
            return false
        } else if (isMobileDocumentMode) {
            return isValidPageSwitch(createExtArgs, componentPointer, potentialContainerPointer)
        }
    }
    return true
}

export const isContainable = (
    createExtArgs: CreateExtArgs,
    compStructureOrPointer: CompStructure | Pointer,
    potentialContainerPointer: Pointer,
    isByStructure: boolean,
    isMobileView?: boolean
) => {
    const containerWideEnough = isContainerWideEnoughForComp(
        createExtArgs,
        compStructureOrPointer,
        potentialContainerPointer,
        isByStructure
    )
    const canBeContained =
        isComponentAContainer(createExtArgs, potentialContainerPointer) &&
        (containerWideEnough || isPopupContainer(createExtArgs, potentialContainerPointer))

    if (isByStructure) {
        return canBeContained
    }
    return (
        canBeContained &&
        isSwitchingScopesAllowed(
            createExtArgs,
            compStructureOrPointer as Pointer,
            potentialContainerPointer,
            isMobileView
        )
    )
}

const getCompTypeByStructureOrPointer = (dal: DAL, containedComp: Pointer | CompStructure, isByStructure: boolean) => {
    return isByStructure
        ? (containedComp as CompStructure).componentType
        : getComponentType(dal, containedComp as Pointer)
}

export const areChildAndParentTypesMatching = (
    {extensionAPI, dal}: CreateExtArgs,
    compStructureOrPointer: CompStructure | Pointer,
    containerPointer: Pointer,
    isByStructure: boolean
) => {
    const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
    const isParentAllowed = componentsMetadata.isParentTypeAllowed(
        compStructureOrPointer,
        getComponentType(dal, containerPointer),
        isByStructure
    )

    const isChildAllowed = componentsMetadata.isChildTypeAllowed(
        containerPointer,
        getCompTypeByStructureOrPointer(dal, compStructureOrPointer, isByStructure)
    )

    return !!containerPointer && isParentAllowed && isChildAllowed
}

export const isComponentContainableRecursively = (
    createExtArgs: CreateExtArgs,
    compStructureOrPointer: CompStructure,
    containerPointer: CompRef | null,
    containCheckFunc: (
        createExtArgs: CreateExtArgs,
        compStructureOrPointer: CompStructure,
        potentialContainerPointer: CompRef,
        targetedContainerPointer: CompRef
    ) => boolean
) => {
    const {componentsMetadata} = createExtArgs.extensionAPI as ComponentsMetadataAPI

    let isRecursive: boolean
    let res = true
    const targetedContainerPointer = containerPointer
    while (containerPointer && res) {
        isRecursive = componentsMetadata.isContainCheckRecursive(containerPointer)
        res = containCheckFunc(createExtArgs, compStructureOrPointer, containerPointer, targetedContainerPointer!)

        if (isRecursive) {
            containerPointer = createExtArgs.pointers.structure.getParent(
                getRepeatedItemPointerIfNeeded(containerPointer)
            ) as CompRef
        } else {
            containerPointer = null
        }
    }
    return res
}

const hasChildren = ({pointers}: CreateExtArgs, componentPointer: Pointer) => {
    const children = pointers.structure.getChildren(componentPointer)
    return children?.length > 0
}

const getChildrenTypesDeep = (dal: DAL, descendants: (string | CompStructure)[] = []): string[] => {
    if (!descendants.length) {
        return []
    }

    return _.flatMap(descendants, (descendant: string | CompStructure) => {
        if (_.isString(descendant)) {
            const descendantPointer = pointerUtils.getPointer(descendant, VIEW_MODES.DESKTOP)
            descendant = dal.get(descendantPointer)
        }
        const {componentType} = descendant as CompStructure
        const childrenTypesDeep = getChildrenTypesDeep(dal, (descendant as CompStructure).components)
        return [componentType].concat(childrenTypesDeep) as string[]
    }) as any as string[]
}

const isTryingToRepeatNonRepeatableComponentsInsideRepeater = (
    createExtArgs: CreateExtArgs,
    componentStructure: CompStructure,
    potentialContainerPointer: CompRef,
    targetedContainerPointer: CompRef
) => {
    const {componentsMetadata} = createExtArgs.extensionAPI as ComponentsMetadataAPI

    const isCheckingTarget = targetedContainerPointer.id === potentialContainerPointer.id
    if (!isCheckingTarget) {
        return false
    }

    const templateTargetedContainerPointer = getRepeatedItemPointerIfNeeded(targetedContainerPointer)

    // if parent is a repeater
    const isTargetCompRepeater = componentsMetadata.isRepeater(templateTargetedContainerPointer)
    const repeaterAlreadyHaveDirectChild =
        isTargetCompRepeater && hasChildren(createExtArgs, templateTargetedContainerPointer)

    if (repeaterAlreadyHaveDirectChild) {
        // early return, to prevent expensive calculations
        return true
    }

    // if descendant of a repeater
    const isTargetedContainerInsideRepeater = isTargetCompRepeater || isRepeatedComponent(targetedContainerPointer.id)
    const repeaterPointer = createExtArgs.coreConfig.experimentInstance.isOpen('dm_dynamicRepeater')
        ? getRepeaterAncestor(createExtArgs, templateTargetedContainerPointer)
        : undefined

    if (isTargetedContainerInsideRepeater) {
        const childrenTypes = getChildrenTypesDeep(createExtArgs.dal, [componentStructure])
        return !_.every(childrenTypes, (childType: string) =>
            componentsMetadata.isCompTypeRepeatable(childType, repeaterPointer)
        )
    }

    return false
}

const isPotentialContainerForScreenWidthComp = (createExtArgs: CreateExtArgs, potentialContainerPointer: Pointer) => {
    const compType = getComponentType(createExtArgs.dal, potentialContainerPointer)
    return isLegacyFullWidthContainer(createExtArgs, potentialContainerPointer) || isPopupContainerByType(compType)
}

export const isComponentCanContainByStructure = (
    createExtArgs: CreateExtArgs,
    componentStructure: CompStructure,
    potentialContainerPointer: CompRef,
    targetedContainerPointer: CompRef
) => {
    if (
        isTryingToRepeatNonRepeatableComponentsInsideRepeater(
            createExtArgs,
            componentStructure,
            potentialContainerPointer,
            targetedContainerPointer
        )
    ) {
        return false
    }

    const {componentsMetadata} = createExtArgs.extensionAPI as ComponentsMetadataAPI

    const alwaysContainRecursively = componentsMetadata.isAlwaysContainRecursively(potentialContainerPointer)
    const indirectParent = potentialContainerPointer.id !== targetedContainerPointer.id
    if (alwaysContainRecursively && indirectParent) {
        return true
    }

    let isCompCanContainByStructure = componentsMetadata.getMetadataValue(
        potentialContainerPointer,
        METADATA_TYPES.CAN_CONTAIN_BY_STRUCTURE,
        componentStructure,
        targetedContainerPointer
    )

    if (dockUtils.isHorizontalDockToScreen(componentStructure.layout)) {
        isCompCanContainByStructure =
            isCompCanContainByStructure &&
            isPotentialContainerForScreenWidthComp(createExtArgs, potentialContainerPointer)
    }

    return isCompCanContainByStructure
}
