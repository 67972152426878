import type {Pointer, PS} from '@wix/document-services-types'
import metaDataUtils from '../metaDataUtils'

function canContain(isByStructure: boolean, ps: PS, componentPointer: Pointer, potentialContainee) {
    if (potentialContainee) {
        const containeeCompType = isByStructure
            ? potentialContainee.componentType
            : metaDataUtils.getComponentType(ps, potentialContainee)
        return metaDataUtils.isComponentSuitableForNonRenderingState(containeeCompType)
    }

    return false
}

export default {
    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        stackLayout: true,
        structuralItem: true
    },
    canContain: canContain.bind(null, false),
    canContainByStructure: canContain.bind(null, true)
}
