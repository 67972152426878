import type {DalItem} from '@wix/document-manager-core'
import {addIdToMap, getCustomId} from '../utils'
import {stripHashIfExists} from '../../../../utils/refArrayUtils'
import {DATA_TYPES} from '../../../../constants/constants'
import type {DataModelAPI, DataModelExtensionAPI} from '../../../dataModel/dataModel'
import type {DeserializeComponentParams} from '../types'

const createPatternsStructure = (dataModel: DataModelAPI, item: DalItem, pageId: string, customId?: string) => {
    const patternsQuery = dataModel.addItem(item, DATA_TYPES.patterns, pageId, customId)
    return patternsQuery.id!
}

export const updateComponentPatternsStructure = (deserializeComponentParams: DeserializeComponentParams) => {
    const {createExtArgs, compStructure, pageId, mappers, customId} = deserializeComponentParams
    const {oldToNewIdMap} = mappers ?? {}
    deserializeComponentParams.customId = getCustomId(oldToNewIdMap, compStructure.patterns, customId)

    if (compStructure.patterns?.items) {
        compStructure.patterns.items.forEach((nthChild: any) => {
            const oldRepeaterPatternVariant = stripHashIfExists(nthChild.variant)

            if (nthChild.variant) {
                nthChild.variant = `#${getCustomId(oldToNewIdMap, {id: oldRepeaterPatternVariant})}`
            }
        })

        const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI
        const patternsId = createPatternsStructure(
            dataModel,
            compStructure.patterns,
            pageId,
            deserializeComponentParams.customId
        )
        compStructure.patternsQuery = patternsId

        addIdToMap(compStructure.patterns, patternsId, oldToNewIdMap)
    }
}
