import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Experiment} from '@wix/document-services-types'
import {cleanBrokenReferences} from './utils/variantRelationUtils'
import {stripHashIfExists} from '../../../../utils/refArrayUtils'
import type {PageMigrator} from '../dataMigrationRunner'

const experimentName = 'dm_fixBrokenScopedLayout'

const migratePage = (documentManager: DocumentManager, pageId: string, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen(experimentName)) {
        return
    }
    const valuesTorefMap = documentManager.pointers.data
        .getLayoutItemsWithPredicate(layoutItem => layoutItem.type === 'VariantRelation', pageId)
        .map(pointer => documentManager.dal.get(pointer))
        .filter(
            relation =>
                relation.variants.length > 1 &&
                relation.variants.every(
                    (ref: string) =>
                        documentManager.dal.get(documentManager.pointers.getPointer(stripHashIfExists(ref), 'variants'))
                            .type === 'BreakpointRange'
                )
        )
        .reduce((refMap, relation) => ({...refMap, [relation.id]: relation}), {})
    cleanBrokenReferences(documentManager, 'layout', pageId, valuesTorefMap, _.stubTrue)
}

const name = 'fixBrokenScopedLayout'
const version = 1
const experimentalVersions = [{version: 1, experiment: experimentName}]

export const fixBrokenScopedLayout: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions
}
