import _ from 'lodash'
import {CreateExtArgs, pointerUtils} from '@wix/document-manager-core'
import {isError, sendBreadCrumbOnValidationError} from '../utils'
import {ALLOWED_LAYOUT_PARAMS, ERRORS} from '../constants'
import type {Result} from '../types'
import type {CompStructure, ConnectionItem, Pointer, SerializedCompStructure} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'
import type {LoggerExtAPI} from '../../logger'
import {validateStructure} from './compStructureValidations'
import type {ComponentsMetadataAPI} from '../../componentsMetadata/componentsMetadata'
import {getDeprecationMessage, isComponentDeprecated, shouldWarnForDeprecation} from './compDeprecation'
import * as santaCoreUtils from '@wix/santa-core-utils'
import type {EffectsExtensionAPI} from '../../effects'

const {getRepeatedItemPointerIfNeeded} = pointerUtils

export const validateLayoutParam = (createExtArgs: CreateExtArgs, param: string, value: number): Result => {
    if (!_.includes(ALLOWED_LAYOUT_PARAMS, param)) {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.LAYOUT_PARAM_IS_NOT_ALLOWED, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_IS_NOT_ALLOWED}
    }

    if (param === 'fixedPosition' && _.isBoolean(value)) {
        return {success: true}
    }

    if (param === 'docked') {
        return {success: true}
    }

    if (!_.isNumber(value)) {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.LAYOUT_PARAM_MUST_BE_NUMERIC, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_MUST_BE_NUMERIC}
    }

    const nonNegativeParams = _.without(ALLOWED_LAYOUT_PARAMS, 'x', 'y')

    if (_.includes(nonNegativeParams, param) && value < 0) {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.LAYOUT_PARAM_CANNOT_BE_NEGATIVE, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_CANNOT_BE_NEGATIVE}
    }

    if (param === 'rotationInDegrees' && value > 360) {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.LAYOUT_PARAM_ROTATATION_INVALID_RANGE, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_ROTATATION_INVALID_RANGE}
    }

    return {success: true}
}

const validateComponentConnection = (createExtArgs: CreateExtArgs, connectionItem: ConnectionItem): Result => {
    if (connectionItem.role === '*') {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.INVALID_COMPONENT_CONNECTION_ROLE, {
            role: connectionItem.role
        })
        return {success: false, error: ERRORS.INVALID_COMPONENT_CONNECTION_ROLE}
    }
    return {success: true}
}

export const validateCompConnections = (createExtArgs: CreateExtArgs, compStructure: CompStructure): void => {
    const connectionItems = compStructure?.connections?.items ?? []
    for (const connectionItem of connectionItems) {
        const validationResult = validateComponentConnection(createExtArgs, connectionItem)
        if (isError(validationResult)) {
            throw new ReportableError({
                errorType: 'compConnectionValidationError',
                message: validationResult.error
            })
        }
    }
}

export const createValidationError = (msg: string, extras?: Record<string, any>) => {
    return new ReportableError({
        errorType: 'componentValidationError',
        message: msg,
        extras
    })
}

export const validateCustomId = (customId?: string) => {
    if (!_.isNil(customId) && !_.isString(customId)) {
        throw createValidationError('customId must be a string', {customId})
    }
}

export const reportUnknownSystemStyle = (createExtArgs: CreateExtArgs, compStructure: CompStructure) => {
    const isStyleTypeSystem = _.get(compStructure, ['style', 'styleType']) === 'system'
    const isStyleInBreakpointsSystem = _.some(
        _.get(compStructure, ['style', 'stylesInBreakpoints'], []),
        style => _.get(style, ['styleType']) === 'system'
    )
    const isScopedStyleSystem = _.some(
        _.get(compStructure, ['scopedStyles'], []),
        style => _.get(style, ['styleType']) === 'system'
    )

    if (isStyleTypeSystem || isStyleInBreakpointsSystem || isScopedStyleSystem) {
        const {logger} = createExtArgs.extensionAPI as LoggerExtAPI
        logger.captureError(
            new ReportableError({
                errorType: 'unknownSystemStyle',
                message: 'Adding unknown system style',
                extras: {
                    id: compStructure.id,
                    componentType: compStructure.componentType
                }
            })
        )
        sendBreadCrumbOnValidationError(createExtArgs, 'Adding unknown system style', {
            id: compStructure.id,
            componentType: compStructure.componentType
        })
    }
}

const isContainableByStructure = (
    createExtArgs: CreateExtArgs,
    componentStructure: SerializedCompStructure,
    containerPointer: Pointer,
    isPublic?: boolean
): boolean => {
    const {componentsMetadata} = createExtArgs.extensionAPI as ComponentsMetadataAPI
    return isPublic
        ? componentsMetadata.isContainableByStructurePublic(componentStructure, containerPointer)
        : componentsMetadata.isContainableByStructure(componentStructure, containerPointer)
}

const validateContainer = (
    createExtArgs: CreateExtArgs,
    componentStructure: SerializedCompStructure,
    containerPointer: Pointer,
    isPublic?: boolean
) => {
    if (!containerPointer) {
        throw createValidationError(`containerPointer ${containerPointer} is illegal`, {
            componentType: componentStructure.componentType
        })
    }

    if (!createExtArgs.dal.has(getRepeatedItemPointerIfNeeded(containerPointer))) {
        throw createValidationError('container does not exist', {
            containerPointer,
            componentType: componentStructure.componentType
        })
    }

    if (!isContainableByStructure(createExtArgs, componentStructure, containerPointer, isPublic)) {
        throw createValidationError(
            `component ${componentStructure.componentType} is not containable in ${containerPointer.id}`,
            {
                containerPointer,
                componentType: componentStructure.componentType
            }
        )
    }
}

export const validateComponentToSet = (
    createExtArgs: CreateExtArgs,
    componentStructure: SerializedCompStructure,
    optionalCustomId?: string,
    containerPointer?: Pointer,
    isPage?: boolean
): Result => {
    try {
        validateCustomId(optionalCustomId)
        validateStructure(createExtArgs, componentStructure)

        if (!isPage) {
            validateContainer(createExtArgs, componentStructure, containerPointer!)
        }

        if (createExtArgs.coreConfig.experimentInstance.isOpen('dm_reportUnknownSystemStyle')) {
            reportUnknownSystemStyle(createExtArgs, componentStructure)
        }
    } catch (e: any) {
        sendBreadCrumbOnValidationError(createExtArgs, e.message, {
            id: componentStructure?.id,
            componentType: componentStructure?.componentType
        })

        return {success: false, error: e.message}
    }

    return {success: true}
}

const isValidContainerDefinition = (
    createExtArgs: CreateExtArgs,
    componentStructure: SerializedCompStructure,
    containerPointer: Pointer
) => {
    try {
        validateContainer(createExtArgs, componentStructure, containerPointer, true)
        return true
    } catch (e) {
        return false
    }
}

const isValidIndexOfChild = (
    createExtArgs: CreateExtArgs,
    containerPointer: Pointer,
    optionalIndex?: number
): boolean => {
    if (!_.isNumber(optionalIndex)) {
        return true
    }

    const childrenPointers = createExtArgs.pointers.structure.getChildren(
        getRepeatedItemPointerIfNeeded(containerPointer)
    )

    if (!_.isFinite(optionalIndex) || optionalIndex < 0 || optionalIndex > childrenPointers.length) {
        return false
    }

    return true
}

export const validateComponentToAdd = (
    createExtArgs: CreateExtArgs,
    componentPointer: Pointer,
    componentStructure: SerializedCompStructure,
    containerPointer: Pointer,
    optionalIndex?: number,
    shouldThrowOnDeprecation?: boolean
): Result => {
    if (isComponentDeprecated(componentStructure.componentType)) {
        sendBreadCrumbOnValidationError(createExtArgs, getDeprecationMessage(componentStructure.componentType), {
            id: componentStructure.id,
            componentType: componentStructure.componentType
        })
        return {success: false, error: getDeprecationMessage(componentStructure.componentType)}
    }

    if (shouldWarnForDeprecation(componentStructure.componentType)) {
        if (shouldThrowOnDeprecation) {
            sendBreadCrumbOnValidationError(createExtArgs, getDeprecationMessage(componentStructure.componentType), {
                id: componentStructure.id,
                componentType: componentStructure.componentType
            })
            return {success: false, error: getDeprecationMessage(componentStructure.componentType)}
        }
        santaCoreUtils.log.warnDeprecation(getDeprecationMessage(componentStructure.componentType))
    }

    if (!isValidContainerDefinition(createExtArgs, componentStructure, containerPointer)) {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.INVALID_CONTAINER_POINTER, {
            id: componentStructure.id,
            componentType: componentStructure.componentType
        })
        return {success: false, error: ERRORS.INVALID_CONTAINER_POINTER}
    }
    if (!isValidIndexOfChild(createExtArgs, containerPointer, optionalIndex)) {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.INVALID_CONTAINER_POINTER, {
            id: componentStructure.id,
            componentType: componentStructure.componentType
        })
        return {success: false, error: ERRORS.INVALID_COMPONENT_INDEX}
    }

    if (componentPointer.type !== containerPointer.type) {
        return {success: false, error: ERRORS.COMPONENT_AND_CONTAINER_HAVE_DIFFERENT_TYPE}
    }
    if (componentPointer.id && createExtArgs.dal.has(componentPointer)) {
        return {success: false, error: ERRORS.COMPONENT_ALREADY_EXISTS}
    }

    const {componentsMetadata} = createExtArgs.extensionAPI as ComponentsMetadataAPI
    if (!componentsMetadata.allowedToContainMoreChildren(containerPointer)) {
        sendBreadCrumbOnValidationError(createExtArgs, ERRORS.MAXIMUM_CHILDREN_NUMBER_REACHED, {
            id: componentStructure.id,
            componentType: componentStructure.componentType
        })
        return {success: false, error: ERRORS.MAXIMUM_CHILDREN_NUMBER_REACHED}
    }
    if (createExtArgs.pointers.structure.isWithVariants(componentPointer)) {
        return {success: false, error: ERRORS.CANNOT_ADD_COMPONENT_WITH_VARIANT}
    }
    if (componentStructure.effects) {
        const {effects} = createExtArgs.extensionAPI as EffectsExtensionAPI
        if (!effects.usesNewAnimations()) {
            return {success: false, error: ERRORS.SITE_CANNOT_USE_EFFECTS}
        }
    }

    return {success: true}
}
