const constants = require('../../helpers/constants')
const {NAMESPACE_MAPPING} = require('@wix/document-services-json-schemas').namespaceMapping
const {VARIANT_RELATION_TYPE} = constants
const SLOTS_NAMESPACE = NAMESPACE_MAPPING.slots
const LAYOUT_DATA_NAMESPACE = NAMESPACE_MAPPING.layout

const hasNonMatchingLayoutQueries = (layoutQueries, layoutData) => {
    if (Object.keys(layoutData).length !== layoutQueries.size) {
        return true
    }
    for (let i = 0; i < layoutQueries.length; i++) {
        if (!layoutData[layoutQueries[i].replace('#', '')]) {
            return true
        }
    }

    return false
}

const collectLayoutQueries = (componentIds, components, layoutData) => {
    const layoutQueries = new Set()

    const traverseComponents = component => {
        if (componentIds.has(component.id) && component.layoutQuery) {
            layoutQueries.add(component.layoutQuery)
        }

        const layoutDataRefArray = layoutData[component.layoutQuery?.replace('#', '')]
        if (layoutDataRefArray) {
            layoutDataRefArray.values.forEach(ref => {
                layoutQueries.add(ref)
                const layoutEntry = layoutData[ref.replace('#', '')]
                if (layoutEntry.type === VARIANT_RELATION_TYPE) {
                    layoutQueries.add(layoutEntry.to)
                }
            })
        }

        if (component.components) {
            component.components.forEach(traverseComponents)
        }
    }

    components.forEach(traverseComponents)
    return layoutQueries
}

const getComponentIdsFromSlots = slots => {
    const componentIds = new Set()
    Object.values(slots).forEach(slot => {
        if (slot && slot.slots) {
            Object.values(slot.slots).forEach(componentId => {
                componentIds.add(componentId)
            })
        }
    })
    return componentIds
}

const isEmpty = namespace => {
    return !namespace || Object.keys(namespace).length === 0
}

const hasNonPluginLayoutData = pageJson => {
    const layoutData = pageJson.data[LAYOUT_DATA_NAMESPACE]
    if (isEmpty(layoutData)) {
        return false
    }
    if (pageJson.structure.id !== 'masterPage' && pageJson.structure.layoutQuery) {
        return true
    }
    const slots = pageJson.data[SLOTS_NAMESPACE]
    if (isEmpty(slots)) {
        return true
    }

    const componentIdsFromSlots = getComponentIdsFromSlots(slots)

    const componentLayoutQueries = collectLayoutQueries(
        componentIdsFromSlots,
        pageJson.structure.components ?? pageJson.structure.children,
        layoutData
    )
    const mobileLayoutQueries = collectLayoutQueries(
        componentIdsFromSlots,
        pageJson.structure.mobileComponents,
        layoutData
    )

    const allLayoutQueries = new Set([...componentLayoutQueries, ...mobileLayoutQueries])

    return hasNonMatchingLayoutQueries(allLayoutQueries, layoutData)
}

const shouldRunConversion = (magicObject, pageJson) => {
    return (
        magicObject.isViewerMode &&
        magicObject.renderHintsFlags?.oneDoc &&
        magicObject.isExperimentOpen('dm_meshLayout') &&
        !hasNonPluginLayoutData(pageJson)
    )
}

module.exports = {
    shouldRunConversion
}
