import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {getComponentType} from '../../../utils/dalUtils'
import {isSiteStructure} from '../utils/pageUtils'

const isContainable = (
    createExtArgs: CreateExtArgs,
    compStructureOrPointer: CompStructure | Pointer,
    containerPointer: Pointer
) => {
    const potentialContainerCompType = getComponentType(createExtArgs.dal, containerPointer)
    return isSiteStructure(potentialContainerCompType)
}

export const siteRegionContainerMetaData = {
    enforceContainerChildLimitsByHeight: true,
    containableByStructure: isContainable,
    fullWidth: ({pointers}: CreateExtArgs, compPointer: Pointer) => {
        return pointers.structure.isMobile(compPointer)
    }
}
