import type {AppDefinitionId, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {setState} from '../../../appControllerData/appControllerState'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {PlatformExtensionAPI} from '../../../platform'
export const setControllerStateFromSerializedStructure = (
    createExtArgs: CreateExtArgs,
    controllerRef: Pointer,
    controllerDefinition: Record<string, any>
) => {
    if (_.has(controllerDefinition, ['custom', 'state'])) {
        setState(createExtArgs, _.set({}, _.get(controllerDefinition, ['custom', 'state'])!, [controllerRef]))
    }
}
export const addPlatformAppToPageIfFirstController = (
    {pointers, extensionAPI}: CreateExtArgs,
    controllerRef: Pointer,
    appDefinitionId: AppDefinitionId,
    controllerPageRef?: Pointer
) => {
    const {platform} = extensionAPI as PlatformExtensionAPI
    controllerPageRef = controllerPageRef || pointers.structure.getPageOfComponent(controllerRef)
    if (controllerPageRef) {
        platform.pagesPlatformApplication.updatePagePlatformApp(controllerPageRef, appDefinitionId, true)
    }
}
