import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        extensionPublicAPI: {dataManipulation, getter}
    } = publicMethodUtils

    return {
        methods: {
            globalStyle: {
                get: getter('globalStyle.get'),
                list: getter('globalStyle.list'),
                add: dataManipulation('globalStyle.add'),
                update: dataManipulation('globalStyle.update'),
                remove: dataManipulation('globalStyle.remove'),
                getByComponent: getter('globalStyle.getByComponent'),
                listByComponentCategories: getter('globalStyle.listByComponentCategories'),
                listComponentsByGlobalStyle: getter('globalStyle.listComponentsByGlobalStyle'),
                attachComponent: dataManipulation('globalStyle.attachComponent'),
                detachComponent: dataManipulation('globalStyle.detachComponent'),
                style: {
                    get: getter('globalStyle.style.get'),
                    update: dataManipulation('globalStyle.style.update'),
                    remove: dataManipulation('globalStyle.style.remove')
                }
            }
        }
    }
}
