import type {PublicMethodUtils} from '@wix/document-services-types'
import {
    component,
    dataModel,
    componentStylesAndSkinsAPI,
    componentCode,
    componentBehaviors,
    constants,
    componentStructureInfo,
    componentScroll,
    utils,
    design,
    innerElements,
    componentInit
} from '@wix/document-services-implementation'
import * as documentServicesSchemas from '@wix/document-services-json-schemas'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                /**
                 * @param {ComponentReference} containerReference
                 * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}
                 * @param {string} [optionalCustomId]
                 * @returns {*}
                 */
                add: publicMethodUtils.defineDataManipulationAction(component.add, {
                    isUpdatingAnchors: utils.YES,
                    getReturnValue: component.getComponentToAddRef,
                    /**
                     * @param {ps} ps
                     * @param {AbstractComponent} componentToAddPointer
                     * @param {AbstractComponent} containerPointer
                     * @param {{componentType: string}} componentDefinition
                     * @return {{component_type: string}}
                     */
                    getInteractionParams: component.getAddComponentInteractionParams
                }),
                addAndAdjustLayout: publicMethodUtils.defineDataManipulationAction(component.addAndAdjustLayout, {
                    isUpdatingAnchors: utils.NO,
                    getReturnValue: component.getComponentToAddRef,
                    getInteractionParams: component.getAddComponentInteractionParams
                }),
                duplicate: publicMethodUtils.defineDataManipulationAction(component.duplicate, {
                    isUpdatingAnchors: utils.YES,
                    getReturnValue: component.getComponentToDuplicateRef,
                    getInteractionParams: component.getDuplicateComponentInteractionParams
                }),
                serialize: publicMethodUtils.defineGetter(component.serialize),
                remove: publicMethodUtils.defineDataManipulationAction(component.remove, {
                    isAsyncOperation: component.shouldDelayDeletion,
                    asyncPreDataManipulation: component.validateRemoval,
                    isUpdatingAnchors: dataModel.shouldUpdateAnchorsAfterRemove,
                    getInteractionParams: component.getRemoveComponentInteractionParams
                }),
                buildDefaultComponentStructure: publicMethodUtils.defineGetter(
                    componentStructureInfo.buildDefaultComponentStructure
                ),
                getContainer: publicMethodUtils.defineGetter(component.getContainer),
                getPage: publicMethodUtils.defineGetter(component.getPage),
                getAncestors: publicMethodUtils.defineGetter(component.getAncestors),
                getSiblings: publicMethodUtils.defineGetter(component.getSiblings),
                getRepeatedComponents: publicMethodUtils.defineGetter(component.getRepeatedComponents),
                getChildren: publicMethodUtils.defineGetter(component.getChildrenWithScopes),
                getChildrenFromFull: publicMethodUtils.defineGetter(component.getChildrenFromFull),
                getTpaChildren: publicMethodUtils.defineGetter(component.getTpaChildren),
                getBlogChildren: publicMethodUtils.defineGetter(component.getBlogChildren),
                getType: publicMethodUtils.defineGetter(component.getType),
                getDefinition: publicMethodUtils.defineGetter(component.getDefinition),
                getMobileRef: publicMethodUtils.defineGetter(component.getMobileRef),
                isRenderedOnSite: publicMethodUtils.defineGetter(component.isRenderedOnSite),
                migrate: publicMethodUtils.defineDataManipulationAction(component.migrate),
                properties: {
                    update: publicMethodUtils.defineDataManipulationAction(component.properties.update, {
                        isUpdatingAnchors: dataModel.shouldUpdateAnchorsAfterPropertiesUpdate
                    }),
                    get: publicMethodUtils.defineGetter(component.properties.get)
                },
                skin: {
                    set: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.skin.set),
                    get: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.skin.get),
                    getComponentSkinExports: publicMethodUtils.defineGetter(
                        componentStylesAndSkinsAPI.skin.getComponentSkinExports
                    )
                },
                layout: {
                    updateAndPushStart: publicMethodUtils.defineDataManipulationAction(
                        component.layout.updateAndPushStart
                    ),
                    updateAndPushUpdate: publicMethodUtils.defineDataManipulationAction(
                        component.layout.updateAndPushUpdate,
                        {
                            disableLogInteraction: true
                        }
                    ),
                    updateAndPushEnd: publicMethodUtils.defineDataManipulationAction(component.layout.updateAndPushEnd),
                    runtime: {
                        detachLayout: publicMethodUtils.defineDataManipulationAction(
                            component.layout.runtime.detachLayout
                        ),
                        updateDetachedLayout: publicMethodUtils.defineDataManipulationAction(
                            component.layout.runtime.updateDetachedLayout,
                            {
                                disableLogInteraction: true
                            }
                        ),
                        reattachLayoutAndUpdate: publicMethodUtils.defineDataManipulationAction(
                            component.layout.runtime.reattachLayoutAndUpdate
                        ),
                        updateAndPush: publicMethodUtils.defineAction(component.layout.runtime.updateAndPush),
                        remove: publicMethodUtils.defineAction(component.layout.runtime.remove),
                        detachComponents: publicMethodUtils.defineAction(component.layout.runtime.detachComponents),
                        reattachComponents: publicMethodUtils.defineAction(component.layout.runtime.reattachComponents),
                        reattachLayout: publicMethodUtils.defineDataManipulationAction(
                            component.layout.runtime.reattachLayout
                        )
                    },
                    measure: {
                        getBoundingClientRect: publicMethodUtils.defineGetter(
                            component.layout.measure.getBoundingClientRect
                        ),
                        getInnerElementBoundingClientRects: publicMethodUtils.defineGetter(
                            component.layout.measure.getInnerElementBoundingClientRects
                        ),
                        getRelativeToViewportBoundingBox: publicMethodUtils.defineGetter(
                            component.layout.measure.getRelativeToViewportBoundingBox
                        ),
                        getPadding: publicMethodUtils.defineGetter(component.layout.measure.getPadding)
                    },
                    get: publicMethodUtils.defineGetter(component.layout.get),
                    RESIZE_SIDES: constants.RESIZE_SIDES
                },
                is: {
                    /**
                     * Checks if a component is duplicable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @param {AbstractComponent} pageReference
                     * @returns {boolean}
                     */
                    duplicatable: publicMethodUtils.defineGetter(component.isComponentDuplicatable),
                    /**
                     * Checks if a component is removable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @param {AbstractComponent} pageReference
                     * @returns {boolean}
                     */
                    removable: publicMethodUtils.defineGetter(component.isComponentRemovable),
                    modal: publicMethodUtils.defineGetter(component.isComponentModal),
                    usingLegacyAppPartSchema: publicMethodUtils.defineGetter(
                        component.isComponentUsingLegacyAppPartSchema
                    ),
                    exist: publicMethodUtils.defineGetter(component.isExist),
                    /**
                     * Checks if a component is visible on site
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @returns {boolean}
                     */
                    visible: publicMethodUtils.defineGetter(component.isComponentVisible),
                    rendered: publicMethodUtils.defineGetter(componentStructureInfo.isRenderedOnSite)
                },
                COMPONENT_DEFINITION_MAP: documentServicesSchemas.schemas.default.allComponentsDefinitionsMap,
                behaviors: {
                    execute: publicMethodUtils.defineDataManipulationAction(componentBehaviors.executeBehavior),
                    getRuntimeState: publicMethodUtils.defineGetter(componentBehaviors.getRuntimeState)
                },
                scroll: {
                    to: publicMethodUtils.defineAction(componentScroll.scroll),
                    by: publicMethodUtils.defineAction(componentScroll.scrollBy)
                },
                code: {
                    generateNicknamesForSite: publicMethodUtils.defineDataManipulationAction(
                        componentCode.generateNicknamesForSite
                    ),
                    getNickname: publicMethodUtils.defineGetter(componentCode.getNickname),
                    setNickname: publicMethodUtils.defineDataManipulationAction(componentCode.setNickname),
                    validateNickname: publicMethodUtils.defineGetter(componentCode.validateNickname),
                    getComponentsInPage: publicMethodUtils.defineGetter(componentCode.getComponentsInPage),
                    VALIDATIONS: componentCode.VALIDATIONS
                },
                modes: {
                    /** JSON getters **/
                    getTypes: publicMethodUtils.defineGetter(component.modes.getModeTypes),
                    getModeById: publicMethodUtils.defineGetter(component.modes.getComponentModeById),
                    getModes: publicMethodUtils.defineGetter(component.modes.getComponentModes),
                    getModesByType: publicMethodUtils.defineGetter(component.modes.getComponentModesByType),
                    getAllCompOverrides: publicMethodUtils.defineGetter(component.modes.overrides.getAllOverrides),
                    isDisplayedByDefault: publicMethodUtils.defineGetter(component.modes.isComponentDisplayedByDefault),
                    isComponentDisplayedInModes: publicMethodUtils.defineGetter(
                        component.modes.isComponentDisplayedInModes
                    ),
                    getModesIdsAndParentNeededToShowComp: publicMethodUtils.defineGetter(
                        component.modes.getModesIdsAndParentNeededToShowComp
                    ),

                    /** JSON setters **/
                    addModeDefinition: publicMethodUtils.defineDataManipulationAction(
                        component.modes.addModeDefinition,
                        {
                            getReturnValue: component.modes.getModeToAddId
                        }
                    ),
                    remove: publicMethodUtils.defineDataManipulationAction(component.modes.removeComponentMode, {
                        noBatchingAfter: true
                    }),

                    /** stateful getters (considering currently active modes) **/
                    getComponentActiveModeIds: publicMethodUtils.defineGetter(
                        component.modes.getComponentActiveModeIds
                    ),
                    getFirstAncestorActiveModes: publicMethodUtils.defineGetter(
                        component.modes.getFirstAncestorActiveModes
                    ),
                    getFirstAncestorWithActiveModes: publicMethodUtils.defineGetter(
                        component.modes.getFirstAncestorWithActiveModes
                    ),
                    isComponentCurrentlyDisplayed: publicMethodUtils.defineGetter(
                        component.modes.isComponentCurrentlyDisplayed
                    ),

                    /** stateful setters (considering currently active modes) **/
                    activateComponentMode: publicMethodUtils.defineDataManipulationAction(
                        component.modes.activateComponentMode,
                        {
                            noBatchingAfter: true
                        }
                    ),
                    deactivateComponentMode: publicMethodUtils.defineDataManipulationAction(
                        component.modes.deactivateCompMode,
                        {
                            noBatchingAfter: true
                        }
                    ),
                    resetAllActiveModes: publicMethodUtils.defineDataManipulationAction(
                        component.modes.resetAllActiveModes,
                        {
                            noBatchingAfter: true,
                            disableLogInteraction: true
                        }
                    ),
                    applyCurrentToAllModes: publicMethodUtils.defineDataManipulationAction(
                        component.modes.overrides.applyCurrentToAllModes
                    ),
                    applyComponentToMode: publicMethodUtils.defineDataManipulationAction(
                        component.applyComponentToMode
                    ),
                    showComponentOnlyInModesCombination: publicMethodUtils.defineDataManipulationAction(
                        component.modes.overrides.showComponentOnlyInModesCombination
                    ),
                    showComponentOnlyOnPagesGroup: publicMethodUtils.defineDataManipulationAction(
                        component.modes.showComponentOnlyOnPagesGroup
                    ),
                    getComponentModesAvailableInView: publicMethodUtils.defineGetter(
                        component.modes.getComponentModesAvailableInView
                    ),
                    removeDesignBehaviorsFromAllModes: publicMethodUtils.defineDataManipulationAction(
                        component.modes.removeDesignBehaviorsFromAllModes
                    )
                },
                design: {
                    update: publicMethodUtils.defineDataManipulationAction(component.design.updateDesignItem),
                    get: publicMethodUtils.defineGetter(design.getDesignItem),
                    getByModes: publicMethodUtils.defineGetter(design.getDesignItemByModes),
                    remove: publicMethodUtils.defineDataManipulationAction(design.removeComponentDesignItem),
                    behaviors: {
                        update: publicMethodUtils.defineDataManipulationAction(design.updateDesignItemBehaviors),
                        remove: publicMethodUtils.defineDataManipulationAction(design.removeDesignItemBehaviors)
                    }
                },
                innerElements: {
                    buildPaths: publicMethodUtils.defineAction(innerElements.buildPaths),
                    getInnerElementBoundingBoxes: publicMethodUtils.defineGetter(
                        innerElements.getInnerElementBoundingBoxes
                    ),
                    serializeInnerElementPath: publicMethodUtils.extensionPublicAPI.getter(
                        'innerElements.serializeInnerElementPath'
                    ),
                    style: {
                        get: publicMethodUtils.extensionPublicAPI.getter('innerElements.getInnerElementStyle'),
                        set: publicMethodUtils.extensionPublicAPI.dataManipulation('innerElements.setInnerElementStyle')
                    },
                    data: {
                        get: publicMethodUtils.extensionPublicAPI.getter('innerElements.getInnerElementData'),
                        set: publicMethodUtils.extensionPublicAPI.immediate('innerElements.setInnerElementData')
                    }
                }
            },
            siteSegments: {
                /**
                 * @class documentServices.siteSegments.layout
                 * @link documentServices.components.layout
                 */
                layout: {
                    get: publicMethodUtils.defineGetter(component.layout.get)
                }
            }
        },
        initMethod: componentInit.initialize
    }
}
