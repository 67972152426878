import type {CreateExtArgs, DeepFunctionMap} from '@wix/document-manager-core'
import type {PageAPI, PageExtensionAPI} from '../page'
import type {RoutersExtensionAPI} from '../routers'
import type {SiteStructureAPI} from './siteStructure'
import {VIEW_MODES} from '../../constants/constants'
import {patchPageTranslations} from '../page/language'

enum PageTypes {
    STATIC_PAGE = 'staticPage',
    DYNAMIC_PAGE = 'dynamicPage',
    STATUS_PAGE = 'statusPage',
    POPUP_PAGE = 'popupPage'
}

function buildChildrenMap(allPageIds: string[], extensionAPI: DeepFunctionMap) {
    const childrenMap = new Map<string, string[]>()
    allPageIds.forEach(pageId => {
        const parentPageId = (extensionAPI as PageExtensionAPI).page.getParentPageId(pageId)
        if (parentPageId) {
            const children = childrenMap.get(parentPageId) || []
            children.push(pageId)
            childrenMap.set(parentPageId, children)
        }

        if (!childrenMap.get(pageId)) {
            childrenMap.set(pageId, [])
        }
    })
    return childrenMap
}

function removePageFromChildren(createExtArgs: CreateExtArgs, pageId: string) {
    const {extensionAPI, pointers} = createExtArgs
    const {siteStructure} = (extensionAPI as SiteStructureAPI).siteStructure.getSiteStructure()
    const pageFromStructure = siteStructure?.[pageId]
    const subPages = pageFromStructure?.dynamicPage?.subPages || pageFromStructure?.staticPage?.subPages

    if (subPages?.length) {
        subPages.forEach((childPageId: string) => {
            const dataToUpdate = {parentPageId: null}

            ;(extensionAPI.page as PageAPI).data.update(
                pointers.getPointer(childPageId, VIEW_MODES.DESKTOP),
                dataToUpdate
            )
            patchPageTranslations(createExtArgs, childPageId, dataToUpdate)
        })
    }
}

const PAGE_PROPERTIES_FOR_STRUCTURE = [
    'id',
    'prefix',
    'title',
    'pageUriSEO',
    'tpaApplicationId',
    'appDefinitionId',
    'managingAppDefId',
    'isPopup'
]
function getSiteStructure(createExtArgs: CreateExtArgs, pageId: string | null, langCode?: string) {
    const {extensionAPI} = createExtArgs
    try {
        const allPagesIds = (extensionAPI as PageExtensionAPI).page.getAllPagesIds(false)
        const pageListToGetStructureFor = pageId ? [pageId] : allPagesIds
        const childrenMap = buildChildrenMap(allPagesIds, extensionAPI)
        const siteStructure: any = {}
        const mainPageId = (extensionAPI as PageExtensionAPI).page.getMainPageId()

        for (const [key, value] of childrenMap) {
            if (!pageListToGetStructureFor.includes(key)) {
                continue
            }

            const pageData =
                (extensionAPI as PageExtensionAPI).page.data.pick(key, PAGE_PROPERTIES_FOR_STRUCTURE, langCode) ??
                (extensionAPI as PageExtensionAPI).page.data.pick(key, PAGE_PROPERTIES_FOR_STRUCTURE)
            const dynamicPageData = (extensionAPI as RoutersExtensionAPI).routers.getRouterByPage(key!)
            let {prefix} = pageData
            if (dynamicPageData) {
                prefix = dynamicPageData.prefix
            }

            const pageEntity: any = {}
            let pageType

            pageEntity.title = pageData.title
            if (pageData.id === mainPageId) {
                pageEntity.isMainPage = true
            }

            if (prefix) {
                pageEntity.prefix = prefix
                pageType = PageTypes.DYNAMIC_PAGE
            } else if (pageData.pageUriSEO === 'error404') {
                pageEntity.uri = `/${pageData.pageUriSEO}`
                pageType = PageTypes.STATUS_PAGE
            } else if (pageData.isPopup) {
                pageEntity.uri = `/${pageData.pageUriSEO}`
                pageType = PageTypes.POPUP_PAGE
            } else {
                pageEntity.uri = `/${pageData.pageUriSEO}`
                pageEntity.subPages = value

                if (pageData.tpaApplicationId > 0) {
                    pageEntity.tpaData = {
                        pageIntent: pageData.tpaApplicationId.toString(),
                        wixAppId: pageData.appDefinitionId
                    }
                }
                if (pageData.managingAppDefId) {
                    pageEntity.managedByWixAppId = pageData.managingAppDefId
                }
                pageType = PageTypes.STATIC_PAGE
            }

            siteStructure[key] = {id: key, [pageType]: pageEntity}
        }
        return {siteStructure}
    } catch (e) {
        throw e
    }
}

export {getSiteStructure, removePageFromChildren}
