import _ from 'lodash'
import type {DalValue, Pointer} from '@wix/document-services-types'
import {DAL, pointerUtils} from '@wix/document-manager-core'
import {addPageJson} from '../../utils/addPageJson'

const getRemovalCandidates = (pageId: string, pageJson: any) => {
    const removalCandidates = {}
    const addFunc = (pointer: Pointer, value: any) =>
        _.setWith(removalCandidates, [pointer.type, pointer.id], value, Object)
    addPageJson(pageId, pageJson, addFunc)

    return removalCandidates
}

const createAddFunc =
    (setter: (pointer: Pointer, value: DalValue) => void, removalCandidates?: any) =>
    (pointer: Pointer, value: any) => {
        if (removalCandidates) {
            _.unset(removalCandidates, [pointer.type, pointer.id])
        }
        setter(pointer, value)
    }

const addStoreToDal = (dal: DAL, pageId: string, pageJson: any, removalCandidates?: any) => {
    addPageJson(
        pageId,
        pageJson,
        createAddFunc((pointer, value) => dal.setIfChanged(pointer, value), removalCandidates)
    )
}

const removeData = (removalCandidates: any, remover: (pointer: Pointer) => void) => {
    _.forEach(removalCandidates, (typeMap, type) => {
        _.forEach(typeMap, (data, id) => {
            const pointer = pointerUtils.getPointer(id, type)
            remover(pointer)
        })
    })
}

const removeDataFromDal = (dal: DAL, removalCandidates: any) => {
    removeData(removalCandidates, pointer => dal.remove(pointer))
}

export default async function applyFixAndUpdate(
    dal: DAL,
    pageId: string,
    pageJson: any,
    op: (pageJson: any) => Promise<any>
): Promise<void> {
    const removalCandidates = getRemovalCandidates(pageId, pageJson)
    const newPageJson = await op(pageJson)
    addStoreToDal(dal, pageId, newPageJson, removalCandidates)
    removeDataFromDal(dal, removalCandidates)
}
