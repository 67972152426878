import type {
    CreateExtArgs,
    CreateExtensionArgument,
    DalJsStore,
    DeepFunctionMap,
    DmApis,
    Extension,
    ExtensionAPI,
    SnapshotDal
} from '@wix/document-manager-core'
import type {CompRef, VariantPointer} from '@wix/document-services-types'
import type {
    BaseStageData,
    ComponentStageData,
    MobileAlgoContext,
    Stage,
    StageHandler,
    StructureStageData,
    MobileAlgoPluginFactory,
    MobileAlgoPluginWithContext,
    MobileAlgoPluginInitializationArgs,
    ReadOnlyExtensionAPI,
    StructureIterators,
    ConversionDal,
    MobileAlgoConfig
} from './types'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import _ from 'lodash'
import * as loadablePlugins from './plugins/plugins'
import {createStructureStage} from './stages/structureStage'
import {createComponentStage} from './stages/componentStage'
import {createPluginContextHelper} from './pluginLifeCycle/pluginContextHelper'
import {createPluginHeuristicsRegistry} from './pluginLifeCycle/pluginHeuristicsRegistry'
import {createPluginContext} from './pluginLifeCycle/pluginContext'
import {createConversionDal} from './conversionDal/conversionDal'
import {createReadOnlyExtensionApi} from './readOnlyExtensionApi'
import type {VariantsExtensionAPI} from '../variants/variants'
import type {GroupApi} from './plugins/grouping/types'
import {sendRequest} from './plugins/grouping/request'
import {DATA_TYPES, MASTER_PAGE_ID, VARIANTS, VIEW_MODES} from '../../constants/constants'
import {conversionDataBuilders} from './preprocess'
import {conversionDataTransformers} from './postprocess'
import {mobileConversionValidation} from './validation'
import {createStructureIterators} from './conversionDal/utils/structureIterators'
import {ConversionDalNamespaces} from './conversionDal/constants'
import type {MeasurementsEntryItem, StructureEntryItem} from './conversionDal/types'
import {
    getLayoutPointer,
    getMeasurementsPointer,
    getStructurePointer,
    getStylePointer
} from './conversionDal/pointerUtils'

const pluginsNames = _.keys(loadablePlugins)

export const DOCUMENT_STAGE_WIDTH = 980
export const MOBILE_VARIANT = {type: 'variants', id: VARIANTS.MOBILE_VARIANT_ID}

export interface MobileAlgoApi extends ExtensionAPI {
    locking: {
        lockComponent(compRef: CompRef): void
        isComponentLocked(compRef: CompRef): boolean
        unlockComponent(compRef: CompRef): void
    }
    context: {
        enable(ctx: MobileAlgoContext, plugins?: string[]): void
        disable(ctx: MobileAlgoContext, plugins?: string[]): void
        create(enabledPlugins?: string[]): MobileAlgoContext
    }
    plugins: {
        register(pluginFactory: MobileAlgoPluginFactory): void
        getApis(ctx: MobileAlgoContext): DeepFunctionMap
    }
    heuristics: HeuristicRegistry
    writeConversionResult(
        conversionDal: ConversionDal,
        initialSnapshot: SnapshotDal,
        structureIterators: StructureIterators,
        variants: VariantPointer[]
    ): void
    runWithContext(
        containerPointer: CompRef,
        ctx: MobileAlgoContext
    ): Promise<{
        conversionDal: ConversionDal
        initialSnapshot: SnapshotDal
        structureIterators: StructureIterators
    }>
    run(containerPointer: CompRef, variants: VariantPointer[]): Promise<void>
}

export interface HeuristicRegistry extends ExtensionAPI {
    registerHeuristic<T extends BaseStageData>(stage: Stage<T>, handler: StageHandler<T>): void
    getStages(): Stages
}
export interface MobileAlgoExtensionApi extends ExtensionAPI {
    mobileAlgo: MobileAlgoApi
}

export interface Stages {
    ANALYZE: Stage<StructureStageData>
    SCALE: Stage<ComponentStageData>
    POSITION: Stage<StructureStageData>
    ADJUST: Stage<ComponentStageData>
}

const createExtension = ({environmentContext, experimentInstance, logger}: CreateExtensionArgument): Extension => {
    const bootPlugins: MobileAlgoPluginFactory[] = [
        loadablePlugins.grouping,
        loadablePlugins.order,
        loadablePlugins.textScale,
        loadablePlugins.horizontalScaleAndSize,
        loadablePlugins.verticalScaleAndSize
    ]

    const createExtensionAPI = ({pointers, dal, extensionAPI}: CreateExtArgs): MobileAlgoExtensionApi => {
        const dataModelApi = () => extensionAPI as DataModelExtensionAPI
        const variantsApi = () => (extensionAPI as VariantsExtensionAPI).variants

        const readOnlyExtensionAPI: ReadOnlyExtensionAPI = createReadOnlyExtensionApi(extensionAPI)

        const stages: Stages = {
            ANALYZE: createStructureStage(),
            //hide
            SCALE: createComponentStage(),
            POSITION: createStructureStage(),
            ADJUST: createComponentStage()
        }

        const pluginRegistry = new Map<string, MobileAlgoPluginWithContext>()

        const getPageId = (containerPointer: CompRef) => pointers.structure.getPageOfComponent(containerPointer).id

        const getSiteConfig = () => {
            const {siteWidth} = dal.get({id: MASTER_PAGE_ID, type: DATA_TYPES.data, innerPath: ['renderModifiers']})
            return {
                stageWidth: siteWidth ?? DOCUMENT_STAGE_WIDTH
            }
        }

        const getStages = (): Stages => stages

        const filterPlugins = (plugins?: string[]): MobileAlgoPluginWithContext[] => {
            if (!plugins) {
                return Object.values(pluginRegistry)
            }

            return Object.values(pluginRegistry).filter(plugin => plugins.includes(plugin.name))
        }

        const disablePluginsForContext = (ctx: MobileAlgoContext, plugins?: string[]): void => {
            filterPlugins(plugins).forEach(plugin => {
                plugin.contextHelper.disable(ctx)
            })
        }

        const enablePluginsForContext = (ctx: MobileAlgoContext, plugins?: string[]) => {
            filterPlugins(plugins).forEach(plugin => {
                plugin.contextHelper.enable(ctx)

                if (plugin.dependencies) {
                    enablePluginsForContext(ctx, plugin.dependencies)
                }
            })
        }

        const getPluginApis = (ctx: MobileAlgoContext): DeepFunctionMap => {
            const result: DeepFunctionMap = {}

            Object.values(pluginRegistry).forEach((plugin: MobileAlgoPluginWithContext) => {
                if (plugin.createApi) {
                    const pluginContext = createPluginContext(plugin.contextHelper, ctx)
                    result[plugin.name] = plugin.createApi(pluginContext)
                }
            })

            return result
        }

        const registerRequest = (ctx: MobileAlgoContext) => {
            const apis = getPluginApis(ctx)
            const {grouping} = apis as GroupApi
            grouping.registerServer(sendRequest)
        }

        const createContext = (enabledPlugins?: string[]): MobileAlgoContext => {
            const ctx = {}

            if (enabledPlugins) {
                disablePluginsForContext(ctx)
                enablePluginsForContext(ctx, enabledPlugins)
            } else {
                enablePluginsForContext(ctx)
            }

            registerRequest(ctx)

            return ctx
        }

        const registerPlugin = (pluginFactory: MobileAlgoPluginFactory) => {
            const algoApi = (extensionAPI as MobileAlgoExtensionApi).mobileAlgo
            const initializeArgs: MobileAlgoPluginInitializationArgs = {
                experimentInstance,
                readOnlyExtensionAPI,
                stages,
                environmentContext,
                logger
            }
            const plugin = pluginFactory.createPlugin(initializeArgs)
            const contextHelper = createPluginContextHelper(plugin.name)
            const pluginWithContext = {...plugin, contextHelper}
            const heuristicsRegistry = createPluginHeuristicsRegistry(algoApi.heuristics, contextHelper)
            pluginRegistry[plugin.name] = pluginWithContext
            plugin.register(heuristicsRegistry)
        }

        const registerHeuristic = <T extends BaseStageData>(stage: Stage<T>, handler: StageHandler<T>) => {
            stage.register(handler)
        }

        interface IdAndDepth {
            id: string
            depth: number
        }

        const prepareConversionData = (containerPointer: CompRef): DalJsStore => {
            const queue: IdAndDepth[] = [{id: containerPointer.id, depth: 0}]
            const initialStore: DalJsStore = {
                [ConversionDalNamespaces.structure]: {},
                [ConversionDalNamespaces.measurements]: {},
                [ConversionDalNamespaces.layout]: {},
                [ConversionDalNamespaces.style]: {}
            }
            const shouldValidateComponentLayout = experimentInstance.isOpen(
                'dm_shouldValidateComponentLayoutMobileAlgo'
            )

            while (queue.length > 0) {
                const {id, depth} = queue.shift()!
                const compPointer = pointers.structure.getComponentById(id, VIEW_MODES.DESKTOP)
                const component = dal.get(compPointer)
                const parentComponent = dal.get({id: component.parent, type: VIEW_MODES.DESKTOP})

                if (shouldValidateComponentLayout) {
                    mobileConversionValidation(component, parentComponent)
                }

                const conversionData = conversionDataBuilders.getConversionData(dal, extensionAPI, compPointer)

                for (const [namespace, value] of Object.entries(conversionData)) {
                    initialStore[namespace][id] = value
                }

                for (const childId of component.components ?? []) {
                    queue.push({id: childId, depth: depth + 1})
                }
            }

            return initialStore
        }

        const writeConversionResult = (
            conversionDal: ConversionDal,
            initialSnapshot: SnapshotDal,
            structureIterators: StructureIterators,
            variants: VariantPointer[]
        ): void => {
            const updateResponsiveLayout = (compPointer: CompRef) => {
                const layout = conversionDal.get(getLayoutPointer(compPointer))

                if (!layout) {
                    return
                }

                variantsApi().updateComponentDataConsideringVariants(compPointer, layout, DATA_TYPES.layout)
            }
            const updateStyle = (compPointer: CompRef, changes: DalJsStore) => {
                if (!changes[ConversionDalNamespaces.style]?.[compPointer.id]) {
                    return
                }

                const styleTransformer = conversionDataTransformers.getStyleTransformer(
                    extensionAPI,
                    compPointer,
                    conversionDal.get(getStructurePointer(compPointer)),
                    conversionDal.get(getStylePointer(compPointer))
                )

                if (!styleTransformer) {
                    return
                }

                const styleItem = styleTransformer()

                variantsApi().updateComponentDataConsideringVariants(compPointer, styleItem, DATA_TYPES.theme)
            }
            const updateAbsoluteLayout = (compPointer: CompRef) => {
                const measurements: MeasurementsEntryItem = conversionDal.get(getMeasurementsPointer(compPointer))

                if (!measurements) {
                    return
                }

                const comp = dal.get(compPointer)
                dal.set(compPointer, {
                    ...comp,
                    layout: {
                        ...comp.layout,
                        ...measurements
                    }
                })
            }

            const writersToExecute = _.over([updateAbsoluteLayout, updateResponsiveLayout, updateStyle])
            const changes = conversionDal.getLastSnapshot()!.diff(initialSnapshot)

            structureIterators.forEachComponent((component: StructureEntryItem) => {
                const compPointerWithVariants = pointers.getPointer(component.id, VIEW_MODES.DESKTOP, {variants})

                writersToExecute(compPointerWithVariants, changes)
            })
        }

        const runWithContext = async (containerPointer: CompRef, ctx: MobileAlgoContext) => {
            const conversionData = prepareConversionData(containerPointer)
            const conversionDal = createConversionDal(conversionData)
            const initialSnapshot = conversionDal.getLastSnapshot()!
            const structureIterators = createStructureIterators(conversionDal, getStructurePointer(containerPointer))
            const pageId = getPageId(containerPointer)
            const config: MobileAlgoConfig = getSiteConfig()

            for (const [name, stage] of Object.entries(stages)) {
                const {run} = stage

                await run(ctx, conversionDal, initialSnapshot, structureIterators, pageId, config)

                conversionDal.commitChanges(name)
            }

            return {
                conversionDal,
                structureIterators,
                initialSnapshot
            }
        }

        const run = async (containerPointer: CompRef, variants: VariantPointer[] = [MOBILE_VARIANT]) => {
            const ctx = createContext(pluginsNames)
            const {conversionDal, initialSnapshot, structureIterators} = await runWithContext(containerPointer, ctx)

            writeConversionResult(conversionDal, initialSnapshot!, structureIterators, variants)
        }

        const lockComponent = (compRef: CompRef): void => {
            const mobileHints = dataModelApi().dataModel.components.getItem(compRef, DATA_TYPES.mobileHints) ?? {
                type: 'MobileHints'
            }
            mobileHints.isLocked = true
            dataModelApi().dataModel.components.addItem(compRef, DATA_TYPES.mobileHints, mobileHints)
        }

        const unlockComponent = (compRef: CompRef): void => {
            const mobileHints = dataModelApi().dataModel.components.getItem(compRef, DATA_TYPES.mobileHints) ?? {}
            mobileHints.isLocked = false
            dataModelApi().dataModel.components.addItem(compRef, DATA_TYPES.mobileHints, mobileHints)
        }

        const isComponentLocked = (compRef: CompRef): boolean => {
            return !!dataModelApi().dataModel.components.getItem(compRef, DATA_TYPES.mobileHints)?.isLocked
        }

        return {
            mobileAlgo: {
                locking: {
                    lockComponent,
                    unlockComponent,
                    isComponentLocked
                },
                plugins: {
                    register: registerPlugin,
                    getApis: getPluginApis
                },
                context: {
                    create: createContext,
                    enable: enablePluginsForContext,
                    disable: disablePluginsForContext
                },
                heuristics: {
                    getStages,
                    registerHeuristic
                },
                runWithContext,
                writeConversionResult,
                run
            }
        }
    }

    return {
        name: 'mobileAlgo',
        initialize: async ({extensionAPI}: DmApis) => {
            const mobileAlgoApi = extensionAPI as MobileAlgoExtensionApi
            bootPlugins.forEach(pluginFactory => {
                mobileAlgoApi.mobileAlgo.plugins.register(pluginFactory)
            })
        },
        createExtensionAPI
    }
}

export {createExtension}
