import type {AppDefinitionId, PS, Callback1} from '@wix/document-services-types'
import _ from 'lodash'
import type {PlatformEvent} from '@wix/platform-editor-sdk'
import workerService from './workerService'
import platformEventsService from './platformEventsService'
import messageFormatter, {CustomEventOptions} from '../core/messageFormatter'
import componentAddedToStageCompatability from '../componentAddedToStageCompatability'

let eventCallbacksQueue: Callback1<any>[] = []
let customEventCallbacksQueue: Callback1<any>[] = []

function reset() {
    eventCallbacksQueue = []
    customEventCallbacksQueue = []
}

function subscribeOnAppEvents(ps: PS, callback: Callback1<any>) {
    eventCallbacksQueue.push(callback)

    return () => {
        const index = eventCallbacksQueue.indexOf(callback)
        if (index > -1) {
            eventCallbacksQueue.splice(index, 1)
        }
    }
}

function notifyEventSubscribers(appDefinitionId: AppDefinitionId, options: Partial<PlatformEvent>) {
    const message = messageFormatter.triggerEvent(appDefinitionId, options)
    eventCallbacksQueue.forEach(callback => callback(message))
}

function subscribeOnCustomAppEvents(ps: PS, callback: Callback1<any>) {
    customEventCallbacksQueue.push(callback)

    return () => {
        const index = customEventCallbacksQueue.indexOf(callback)
        if (index > -1) {
            customEventCallbacksQueue.splice(index, 1)
        }
    }
}

function notifyCustomEventSubscribers(options: CustomEventOptions) {
    const message = messageFormatter.triggerCustomEvent(options)
    customEventCallbacksQueue.forEach(callback => callback(message))
}

const notifyApplication: NotifyApplication = (
    ps: PS,
    appDefinitionId: AppDefinitionId,
    options: Partial<PlatformEvent>,
    isAmendableAction?: boolean
) => {
    if (isAmendableAction) {
        _.set(options, 'eventPayload.historySnapshotId', ps.runtimeConfig.nextHistorySnapshotId)
    }

    notifyEventSubscribers(appDefinitionId, options)
    workerService.triggerEvent(ps, appDefinitionId, options)
    if (appDefinitionId) {
        platformEventsService.propagateGeneralEvent({
            appDefinitionId,
            eventType: options.eventType,
            eventPayload: options.eventPayload
        })
    }
}

export type NotifyApplication = (
    ps: PS,
    appDefinitionId: AppDefinitionId,
    options: Partial<PlatformEvent>,
    isAmendableAction?: boolean
) => void

const notifyAppsOnCustomEvent = (
    ps: PS,
    {eventType, eventPayload, eventOrigin}: {eventType: string; eventPayload: any; eventOrigin?: string}
) => {
    notifyCustomEventSubscribers({
        eventType,
        eventPayload
    })
    const compatEventType = componentAddedToStageCompatability.getCustomEventCompatibleType(eventType)
    // ignoring events that were moved to DM from editor
    if (componentAddedToStageCompatability.shouldIgnoreEvent(eventType, eventOrigin)) {
        return
    }
    if (compatEventType) {
        platformEventsService.notifyAppsOnCustomEvent(ps, compatEventType, eventPayload, workerService.triggerEvent)
    }

    return platformEventsService.notifyAppsOnCustomEvent(ps, eventType, eventPayload, workerService.triggerEvent)
}

export default {
    subscribeOnAppEvents,
    subscribeOnCustomAppEvents,
    reset,
    notifyApplication,
    notifyAppsOnCustomEvent
}
