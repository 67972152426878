import _ from 'lodash'
import {setCompQueryValue, updateComponentNonRootScopedValues} from './scopedValues'
import type {DeserializeComponentParams} from '../types'
import {addIdToMap, getCustomId, getIdentifierForQuery} from '../utils'
import {DATA_TYPES} from '../../../../constants/constants'
import type {ComponentEffectsList} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../../../dataModel/dataModel'

export const updateComponentEffectsStructure = (deserializationParams: DeserializeComponentParams) => {
    const {createExtArgs, compStructure, pageId, mappers} = deserializationParams
    const {extensionAPI} = createExtArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {oldToNewIdMap} = mappers ?? {}
    const itemType: string = DATA_TYPES.effects
    const effectsList: ComponentEffectsList<string> = compStructure[itemType]
    if (!_.isObject(effectsList)) return

    updateComponentNonRootScopedValues(createExtArgs, compStructure, itemType, pageId, mappers)

    const effectValues: string[] = []
    for (const effect of effectsList.values) {
        const optionalCustomId = getCustomId(oldToNewIdMap, effect)
        const effectNewId = dataModel.addDeserializedItem(effect, itemType, pageId, optionalCustomId).id
        addIdToMap(effect, effectNewId, oldToNewIdMap)
        effectValues.push(`#${effectNewId}`)
    }

    effectsList.values = effectValues
    const key = getIdentifierForQuery(extensionAPI, compStructure, itemType)
    const customId = getCustomId(oldToNewIdMap, {id: key})
    const effectsQuery: string = dataModel.addDeserializedItem(effectsList, itemType, pageId, customId).id
    addIdToMap({id: key}, effectsQuery, oldToNewIdMap)
    setCompQueryValue(compStructure, effectsQuery, itemType)
}
