import type {BaseStyle, CompRef, CompStructure, Size, UnitSize, ValueOf} from '.'
import type {AppFields, EditorClientSpecMapEntry} from './clientSpecMap'
import type {CompLayout} from './componentsObject'

export const WidgetKindType = {
    Section: 'section',
    Container: 'container'
} as const

export type WidgetKind = ValueOf<typeof WidgetKindType>

export interface Preset {
    presetId: string
    name: string
    defaultSize?: {
        height?: UnitSize
        width?: UnitSize
    }
}

export interface Panel {
    height: number
    kind: string
    name: string
    pageUrl: string
    rootCompId: string
    title: string
    helpId: string
}

export interface WidgetDescriptor {
    name: string
    rootCompId: string
    devCenterWidgetId: string
    kind?: WidgetKind
    defaultSize?: Partial<Size>
    presets?: Preset[]
    panels: Record<string, Panel>
    variations?: Variation[]
    widgetApi: WidgetAPI
}

export interface WidgetAPI {
    functions?: WidgetAPIFuncton[]
    events?: WidgetAPIEvent[]
    propertiesSchemas?: Record<'structure', any>[]
}

export interface WidgetAPIFuncton {
    name: string
    description: string
    params?: {
        name: string
        descriptions: string
    }[]
}

export interface WidgetAPIEvent {
    name: string
    description: string
}

export interface Variation {
    rootCompId: string
    name: string
}

export interface BlocksAppDescriptor {
    widgets: Record<string, WidgetDescriptor>
    customDefinitions?: unknown[]
}

export interface BlocksAppData extends Omit<EditorClientSpecMapEntry, 'appFields'> {
    appFields: AppFields
}

export interface PresetsValue {
    type: 'PresetData'
    style: string
    layout: string
}

export interface ChangeVariationOptions {
    keepOverrides: boolean
    customOverrides: VariantOverrides
}

export interface VariantOverrides {
    itemTypes: string
    dataItem: Record<string, BaseStyle>
    isMobile: boolean
    compId: string
    rolePath: string
}

export interface StyleOverridesGroupedByVariant {
    compRef: CompRef
    overridesByVariant: Record<string, BaseStyle[]>
}

type PresetIds = Omit<PresetsValue, 'type'>

type LogicalBreakpoints = 'desktop' | 'tablet' | 'mobile'

export interface WidgetOptions {
    widgetId?: string
    variationId?: string
    presetIds?: PresetIds
    scopedPresets?: Partial<Record<LogicalBreakpoints, PresetIds>>
    layout?: Partial<CompLayout>
}

export type BuildRefWidgetStructureOptions = WidgetOptions

export interface AddWidgetOptions extends WidgetOptions {
    presets?: PresetsValue
    containerRef: CompRef
    presetIds?: PresetIds
    scopedPresets?: Partial<Record<LogicalBreakpoints, PresetIds>>
    installationType: 'open' | 'closed'
    onSuccess(componentToAddRef: CompRef): void
    onError(err: Error): void
}

export interface ComponentStageBehavior {
    duplicatable?: boolean
    rotatable?: boolean
    pinnable?: boolean
    toggleShowOnAllPagesEnabled?: boolean
    resizable?: boolean
    canReparent?: boolean
    dataEditOptions?: string
    preventHide?: boolean
    essential?: {
        enabled: boolean
        text?: string
    }
    closed?: ComponentClosedBehavior
}

export interface ComponentClosedBehavior {
    selectable?: boolean
    hideFromHierarchy?: boolean
}

export interface ConnectionStageData {
    displayName: string
    behavior: ComponentStageBehavior
    relatedToRole: string
}

export interface AppStudioStageData {
    showFocusBox?: boolean
    displayName?: string
    behavior?: ComponentStageBehavior
    connections?: {[role: string]: ConnectionStageData}
    gfpp?: ManifestComponentStageGfpp
}

export type GfppActionType = 'settings' | 'layout' | 'design' | 'manage' | 'background'

export type ManifestGenericMainActionConfig<T> =
    | ManifestFeatureGfppMainActionButtonConfig
    | ManifestGenericGfppMainActionDefinition<T>
export type ManifestFeatureMainActionConfig = ManifestGenericMainActionConfig<TranslatableString>
export type ManifestMainActionConfig = ManifestGenericMainActionConfig<string>

export interface ManifestGfppDesktopConfig {
    mainAction1?: ManifestMainActionConfig
    mainAction2?: ManifestMainActionConfig
    iconButtons?: ManifestFeatureIconButtons
    helpId?: string
}

export interface ManifestComponentStageGfpp {
    desktop: ManifestGfppDesktopConfig
}

export interface ManifestFeatureGfppMainActionButtonConfig {
    behavior: 'HIDE' | 'DEFAULT' | 'DEFAULT1' | 'DEFAULT2'
}

export interface TranslatableString {
    value: string
    shouldTranslate: boolean
}

export interface ManifestGenericGfppMainActionDefinition<T> {
    behavior: 'CUSTOM_ACTION'
    actionId?: string
    actionType?: GfppActionType
    label: T
}

export interface ManifestFeatureUpgradeButtonConfig {
    behavior: 'SITE_UPGRADE'
}

export interface ManifestFeatureGfppIconButtonConfig {
    behavior: 'HIDE' | 'DEFAULT'
}

export interface ManifestFeatureGfppIconActionConfig {
    behavior: 'CUSTOM_ACTION'
    actionId: string
}
export type ManifestFeatureIconButtonConfig = ManifestFeatureGfppIconButtonConfig | ManifestFeatureGfppIconActionConfig

export interface ManifestFeatureIconButtons {
    settings?: ManifestFeatureIconButtonConfig
    add?: ManifestFeatureIconButtonConfig
    design?: ManifestFeatureIconButtonConfig
    crop?: ManifestFeatureGfppIconButtonConfig
    filters?: ManifestFeatureGfppIconButtonConfig
    animation?: ManifestFeatureGfppIconButtonConfig
    link?: ManifestFeatureGfppIconButtonConfig
    stretch?: ManifestFeatureGfppIconButtonConfig
    layout?: ManifestFeatureIconButtonConfig
    upgrade?: ManifestFeatureIconButtonConfig | ManifestFeatureUpgradeButtonConfig
    connect?: ManifestFeatureGfppIconButtonConfig
    pinMode?: ManifestFeatureGfppIconButtonConfig
    customConnect?: ManifestFeatureGfppIconActionConfig
}
export interface ManifestFeatureGfppDesktopConfig {
    mainAction1?: ManifestFeatureMainActionConfig
    mainAction2?: ManifestFeatureMainActionConfig
    iconButtons?: ManifestFeatureIconButtons
    helpId?: string
}

export interface ManifestFeatureComponentStageBehavior {
    duplicatable?: boolean
    resizable?: boolean
    preventHide?: boolean
    dataEditOptions?: string
    closed?: {
        selectable: boolean
    }
}

export interface ManifestFeatureComponentStageGfpp {
    desktop?: ManifestFeatureGfppDesktopConfig
}

export interface ManifestFeature {
    gfpp?: ManifestFeatureComponentStageGfpp
    behavior?: ManifestFeatureComponentStageBehavior
    displayName?: TranslatableString
    type: 'Manifest'
}

export interface SetPropsOptions {
    shouldFetchData?: boolean
}

export interface AppStudioWidgets {
    changeVariation(
        componentRef: CompRef,
        variationId: string,
        onSuccess: (compRef: CompRef) => void,
        onError: (err: Error) => void,
        options: ChangeVariationOptions
    ): void
    changePreset(compRef: CompRef, stylePresetId: string, layoutPresetId: string): void
    presets: {
        change: AppStudioWidgets['changePreset']
        get(compRef: CompRef): Promise<PresetsValue>
    }
    getOverridesWithPrimaryRole(refComponentPtr: CompRef): VariantOverrides
    getStyleOverridesGroupedByVariant(refComponentPtr: CompRef): StyleOverridesGroupedByVariant[]
    addWidget(appDefinitionId: string, widgetId: string, options: AddWidgetOptions): void
    buildRefWidgetStructure(appDefinitionId: string, widgetId: string, options: BuildRefWidgetStructureOptions): void
    props: {
        get(widgetRef: CompRef): Record<string, unknown>
        set(widgetRef: CompRef, newProps: Record<string, unknown>, options?: SetPropsOptions): void
    }
    getStageData(compRef: CompRef): AppStudioStageData
    getRemoteWidgetStructure(appDefinitionId: string, widgetId: string): Promise<CompStructure>
    getAppDescriptor(appDefinitionId: string): Promise<BlocksAppDescriptor>
    getWidgetStageData(compRef: CompRef): AppStudioStageData
}
