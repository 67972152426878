export const CTX_WIDTH_SCALING_RESULT = 'widthResult'

export const FONT_SIZE_SMALL = 16
export const FONT_SIZE_MEDIUM = 21
export const FONT_SIZE_LARGE = 26
export const FONT_SIZE_EXTRA_LARGE = 32

export const CHARACTERS_1 = 7
export const CHARACTERS_2 = 12
export const CHARACTERS_3 = 18
export const CHARACTERS_4 = 24
export const CHARACTERS_5 = 30

export const BASE_HORIZONTAL_PADDING = 24
export const MAXIMAL_WIDTH = 390
