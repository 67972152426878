import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import constants from '../../constants/constants'
import componentStructureInfo from '../../component/componentStructureInfo'
import {getMeasureMapValue} from '../../utils/utils'

function isRotated(ps, componentPointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)

    return !!compLayout.rotationInDegrees
}

const metaData = {
    layoutLimits(ps, compPointer) {
        // height of the WRichText component cannot be less than its content
        let minHeight = santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT
        // Applied only on desktop pointer because on mobile height of text
        // component can't be changed. Related issue https://jira.wixpress.com/browse/WEED-26008
        if (compPointer.type === constants.VIEW_MODES.DESKTOP) {
            const wrichTextProperties = componentStructureInfo.getPropertiesItem(ps, compPointer)
            // do not mess with vertical text
            const isVerticalText = wrichTextProperties?.verticalText
            if (!isVerticalText) {
                minHeight = getMeasureMapValue(ps, compPointer, 'contentHeight', minHeight)
            }
        }

        return {
            minWidth: 10,
            minHeight
        }
    },
    rotatable: true,
    isProportionallyResizable: true,
    resizableSides(ps, compPointer) {
        if (isRotated(ps, compPointer)) {
            return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
        }

        return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT, constants.RESIZE_SIDES.BOTTOM]
    },
    defaultMobileProperties: {
        packed: false,
        verticalText: false,
        minHeight: null
    },
    mobileConversionConfig: {
        isSuitableForProportionGrouping: true,
        category: 'text',
        preserveAspectRatio: false,
        shouldEnlargeToFitWidth: true
    },
    heightAuto: true,
    canBeStretched: () => experiment.isOpen('dm_stretchRichText')
}

export default metaData
