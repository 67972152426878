import _ from 'lodash'
import {
    aiRequestErrorBlacklistValidationFailedTag,
    aiRequestErrorOutlineValidationFailedTag,
    blacklistedContentPhrases
} from './constants'
import * as constants from '../../constants/constants'
import {extractTextFromHTML, findMissingKeys, insertTextInHtml, normalizeString} from './aiExtensionUtils'
import type {CoreLogger} from '@wix/document-manager-core'

import type {FlatOutline, Outline, OutlineValidationResults} from './aiContentExtension'
import {ReportableError} from '@wix/document-manager-utils'

export interface OutlineValidationCounters {
    totalComponentTextCount: number
    missingComponentTextCount: number
    componentTextsWithBlacklistedPhrasesCount: number
    validComponentTextCount: number
}
export interface CompletionCallMetadata {
    textCounters?: OutlineValidationCounters
    isBadJson: boolean
}
export const unsectionedOutlineSectionName = 'unsectioned'
export const outlineCompIdSectionNameOverrides = {
    [constants.COMP_IDS.HEADER]: 'header',
    [constants.COMP_IDS.FOOTER]: 'footer'
}
// currently only sosp is ignored
export const ignoredContainerTypes = new Set(['wysiwyg.viewer.components.SiteRegionContainer'])

const buttonComponentType = 'wysiwyg.viewer.components.SiteButton'
const styleableButtonComponentType = 'wixui.StylableButton'
export const textComponentType = 'wysiwyg.viewer.components.WRichText'
const textInputComponentType = 'wysiwyg.viewer.components.inputs.TextInput'
const textMaskType = 'wixui.TextMask'

export const serializedRepeaterDataItemType = 'RepeatedData'
export const componentContentExtractionMetadata = {
    [styleableButtonComponentType]: {
        dataContentField: 'label',
        contentContainerTypes: ['button'],
        html: false
    },
    [buttonComponentType]: {
        dataContentField: 'label',
        contentContainerTypes: ['button'],
        html: false
    },
    [textComponentType]: {
        dataContentField: 'text',
        contentContainerTypes: ['title', 'paragraph'],
        html: true
    },
    [textInputComponentType]: {
        dataContentField: 'label',
        html: false
    },
    [textMaskType]: {
        dataContentField: 'text',
        html: false
    }
}
const blacklistedPhraseOccurrenceThreshold = 1
const ratioOfLengthTooLong = 5
export const invalidOutlineRetryAmount = 1

export const structureTypes = {
    PAGE: 'PAGE',
    SECTION: 'SECTIOM'
}
export const getSectionName = (anchorItem: Record<string, any>): string => {
    if (anchorItem?.name) {
        // convert to a readable JSON property name and filter out non-alphanumeric characters
        return _.snakeCase(anchorItem.name).replace(/[^a-z0-9_]/g, '')
    }

    return 'section'
}

const getContentValue = (componentType: string, dataItem: Record<string, any>) => {
    const value = dataItem[componentContentExtractionMetadata[componentType].dataContentField]

    if (componentContentExtractionMetadata[componentType].html) {
        return normalizeString(extractTextFromHTML(value))
    }

    return normalizeString(value)
}

const getContentMetadataTypeForComponent = (componentType: string, dataItem: Record<string, any>) => {
    if (componentType === buttonComponentType || componentType === styleableButtonComponentType) {
        return 'button'
    }

    if (componentType === textInputComponentType) {
        return 'textInput'
    }

    if (componentType === textComponentType || componentType === textMaskType) {
        const value = getContentValue(componentType, dataItem)
        if (value.includes('title')) {
            return 'title'
        }
        if (_.words(value).length < 10 && !value.includes('paragraph')) {
            return 'title'
        }

        return 'paragraph'
    }

    return undefined
}
export const getNextIdForPrefix = (prefix: string, typeCounter: Record<string, number>) => {
    const currentValue = typeCounter[prefix] || 0
    const newValue = currentValue + 1
    typeCounter[prefix] = newValue

    if (newValue === 1) {
        return prefix
    }

    return `${prefix}_${newValue}`
}
export const getContentForComponent = (
    componentType: string,
    dataItem: Record<string, any>
): {value: string; type: string} | undefined => {
    const type = getContentMetadataTypeForComponent(componentType, dataItem)

    if (type) {
        const value = getContentValue(componentType, dataItem)

        return {value, type}
    }

    return undefined
}
const countOutlineBlacklistPhraseOccurrenceForSection = (section: Record<string, any>): number => {
    let occurrences = 0
    for (const fieldName of Object.keys(section)) {
        for (const blacklistedContentPhrase of blacklistedContentPhrases) {
            const value = section[fieldName]
            if (!!value && typeof value === 'string') {
                const normalizedResultValue = value.toLowerCase()
                if (normalizedResultValue.includes(blacklistedContentPhrase)) {
                    occurrences += 1
                }
            }
        }
    }
    return occurrences
}

const countOutlineBlacklistPhraseOccurrence = (outline: Outline): number => {
    let occurrences = 0
    for (const sectionName of Object.keys(outline)) {
        const occurrencesForSection = countOutlineBlacklistPhraseOccurrenceForSection(outline[sectionName])
        occurrences += occurrencesForSection
    }

    return occurrences
}
export const validateOutlineSuggestionInternal = (
    outlineFieldsMismatch: boolean,
    totalComponentTextCount: number,
    missingComponentTextCount: number,
    componentTextsWithBlacklistedPhrasesCount: number
): OutlineValidationResults => {
    const blackListThresholdExceeded = componentTextsWithBlacklistedPhrasesCount > blacklistedPhraseOccurrenceThreshold

    return {
        isValid: !outlineFieldsMismatch && !blackListThresholdExceeded,
        outlineFieldsMismatch,
        blackListThresholdExceeded,
        counters: {
            totalComponentTextCount,
            missingComponentTextCount,
            componentTextsWithBlacklistedPhrasesCount,
            validComponentTextCount:
                totalComponentTextCount - missingComponentTextCount - componentTextsWithBlacklistedPhrasesCount
        }
    }
}

export const validateFlatOutlineSuggestion = (result: FlatOutline, outline: FlatOutline): OutlineValidationResults => {
    let outlineFieldsMismatch = false
    const totalComponentTextCount = Object.keys(outline).length
    let missingComponentTextCount = 0
    const missingContentFields = findMissingKeys(outline, result)
    if (missingContentFields.length > 0) {
        outlineFieldsMismatch = true
        missingComponentTextCount += missingContentFields.length
    }

    const componentTextsWithBlacklistedPhrasesCount = countOutlineBlacklistPhraseOccurrenceForSection(result)
    return validateOutlineSuggestionInternal(
        outlineFieldsMismatch,
        totalComponentTextCount,
        missingComponentTextCount,
        componentTextsWithBlacklistedPhrasesCount
    )
}

export const validateOutlineSuggestion = (result: Outline, outline: Outline): OutlineValidationResults => {
    let outlineFieldsMismatch = false
    const totalComponentTextCount = _.sumBy(Object.values(outline), section => Object.keys(section).length)
    let missingComponentTextCount = 0
    for (const sectionName of Object.keys(outline)) {
        const missingContentFields = findMissingKeys(outline[sectionName], result[sectionName])
        if (missingContentFields.length > 0) {
            outlineFieldsMismatch = true
            missingComponentTextCount += missingContentFields.length
        }
    }

    const componentTextsWithBlacklistedPhrasesCount = countOutlineBlacklistPhraseOccurrence(result)
    return validateOutlineSuggestionInternal(
        outlineFieldsMismatch,
        totalComponentTextCount,
        missingComponentTextCount,
        componentTextsWithBlacklistedPhrasesCount
    )
}
export const reportOutlineBIError = (
    logger: CoreLogger,
    errorType: string,
    message: string,
    result: Outline | FlatOutline,
    pageId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    validationResults: OutlineValidationResults
) => {
    const error = new ReportableError({
        errorType,
        message,
        tags: {
            [aiRequestErrorOutlineValidationFailedTag]: validationResults.outlineFieldsMismatch,
            [aiRequestErrorBlacklistValidationFailedTag]: validationResults.blackListThresholdExceeded
        },
        extras: {
            pageId,
            businessType,
            businessName,
            additionalInformation,
            suggestedOutline: result
        }
    })
    logger.captureError(error)
}
// A function that checks if the length of the content provided is more than 5 times the current content length
export const validateContentLengthInternal = (currentContent: string | undefined, newContent: string): boolean => {
    const currentContentLength = currentContent?.length ?? 0
    const newContentLength = newContent.length

    if (newContentLength > currentContentLength * ratioOfLengthTooLong) {
        return false
    }

    return true
}
export const updateDataItemValue = (dataItem: Record<string, any>, componentType: string, content: string) => {
    const contentExtractionMetadata = componentContentExtractionMetadata[componentType]
    if (contentExtractionMetadata.html) {
        const currentHtmlContent = dataItem[contentExtractionMetadata.dataContentField]
        content = insertTextInHtml(currentHtmlContent, content, true)
    }
    dataItem[contentExtractionMetadata.dataContentField] = content
}
