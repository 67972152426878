import {viewerExtensions, ViewerExtensionFactory} from '@wix/document-manager-extensions'
import {extensionLoader} from '@wix/document-manager-host-common'
import type {HostWithViewerManager} from './host'

const {loadExtensions} = extensionLoader

const loadViewerExtensions = async (host: HostWithViewerManager) => {
    const {documentManager, viewerManager, config: dsConfig, environmentContext} = host
    const {experimentInstance, logger} = documentManager

    const {dal, extensionAPI, pointers, config, events} = documentManager

    const extensionsToRegister: ViewerExtensionFactory[] = [
        viewerExtensions.viewerExtension,
        viewerExtensions.displayedStructure,
        viewerExtensions.refComponents,
        viewerExtensions.scopes,
        viewerExtensions.scopesMetaData,
        viewerExtensions.patterns,
        viewerExtensions.remeasure
    ]

    for (const extensionToRegister of extensionsToRegister) {
        const extension = extensionToRegister.createExtension({
            viewerManager,
            experimentInstance,
            environmentContext,
            dsConfig,
            logger
        })
        documentManager.registerExtension(extension)
        await extension.initialize?.({dal, extensionAPI, pointers, EVENTS: events, coreConfig: config})
    }
}

export {loadExtensions, loadViewerExtensions}
