import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {getComponentType} from '../../../utils/dalUtils'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import {DATA_TYPES, COMP_TYPES} from '../../../constants/constants'

const setPlayerIdToComponent = (
    {dal, extensionAPI}: CreateExtArgs,
    componentPointer: Pointer,
    containerPointer: Pointer
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI

    const containerType = getComponentType(dal, containerPointer)
    if (containerType === COMP_TYPES.MEDIA_PLAYER) {
        const playerId = containerPointer.id
        dataModel.components.addItem(componentPointer, DATA_TYPES.prop, {playerId})
    }
}
export const setPlayerIdAfterAddHook = (createExtArgs: CreateExtArgs, data: any) => {
    const {compToAddPointer, containerPointer} = data
    setPlayerIdToComponent(createExtArgs, compToAddPointer, containerPointer)
}
