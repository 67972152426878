import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {AbsoluteLayout, CompStructure} from '@wix/document-services-types'
import {ALLOWED_LAYOUT_PARAMS, defaultLayout} from './constants'
import {isError} from './utils'
import {validateLayoutParam} from './validation/validation'

const updateLayoutPropsForDockedComponent = (componentLayout: Partial<AbsoluteLayout>) => {
    if (componentLayout.docked?.right || componentLayout.docked?.left || componentLayout.docked?.hCenter) {
        if (componentLayout.docked.right && componentLayout.docked.left) {
            delete componentLayout.width
        }
        delete componentLayout.x
    }
    if (componentLayout.docked?.top || componentLayout.docked?.bottom || componentLayout.docked?.vCenter) {
        if (componentLayout.docked.top && componentLayout.docked.bottom) {
            delete componentLayout.height
        }
        delete componentLayout.y
    }
}

export const sanitizeCompLayout = (createExtArgs: CreateExtArgs, compStructure: CompStructure) => {
    compStructure.layout = compStructure.layout || {}
    const sanitizedComponentDefLayout: any = {}
    const allowedLayoutParams = ALLOWED_LAYOUT_PARAMS

    const layout = {...defaultLayout}

    _.forEach(allowedLayoutParams, layoutParam => {
        const value = compStructure.layout![layoutParam]
        if (value) {
            const validationResult = validateLayoutParam(createExtArgs, layoutParam, value)
            if (isError(validationResult)) {
                throw new Error(validationResult.error)
            }
            sanitizedComponentDefLayout[layoutParam] = value
        }
    })
    delete sanitizedComponentDefLayout.anchors

    compStructure.layout = _.assign(layout, sanitizedComponentDefLayout)

    if (compStructure.layout!.docked && !createExtArgs.coreConfig.experimentInstance.isOpen('dm_meshLayout')) {
        updateLayoutPropsForDockedComponent(compStructure.layout as AbsoluteLayout)
    }
}
