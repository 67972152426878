import type {TriggerVariant, ScrubAnimationDef, PS, Callback} from '@wix/document-services-types'

const enable = (ps: PS) => {
    ps.siteAPI.motion.enable()
}
const disable = (ps: PS) => {
    ps.siteAPI.motion.disable()
}
/**
 * Preview an animation on a component
 * @param {ps}
 * @param {componentReference}
 * @param {animationData}
 * @param {onComplete} callback to run at the end of the preview animation
 * @returns {AnimationGroup} animations data object
 */
const play = (ps: PS, componentReference: string, animationData: any, onComplete?: Callback): string => {
    return ps.siteAPI.motion.play(componentReference, animationData, onComplete)
}

/**
 * Stop animation preview by sequence id returned by previewAnimation
 * @param {ps} ps
 * @param {string} effectId
 */
const cancel = (ps: PS, effectId: string) => {
    ps.siteAPI.motion.cancel(effectId)
}
/**
 * Stop all animation previews
 * @param {ps} ps
 */
const cancelAll = (ps: PS) => {
    ps.siteAPI.motion.cancelAll()
}
/**
 * Preview scrub animations on stage by animations and corresponding triggers
 * @param {ps} ps
 * @param {Record<string, TriggerVariant>} triggers
 * @param {Record<string, ScrubAnimationDef>} animationMap
 */
const startScrub = (
    ps: PS,
    triggers: Record<string, TriggerVariant>,
    animationMap: Record<string, ScrubAnimationDef>
) => {
    ps.siteAPI.motion.startScrub(triggers, animationMap)
}
/**
 * Update preview of current scrub animations on stage by animations and corresponding triggers
 * @param {ps} ps
 * @param {Record<string, TriggerVariant>} triggers
 * @param {Record<string, ScrubAnimationDef>} animationMap
 */
const updateScrub = (
    ps: PS,
    triggers: Record<string, TriggerVariant>,
    animationMap: Record<string, ScrubAnimationDef>
) => {
    ps.siteAPI.motion.updateScrub(triggers, animationMap)
}
/**
 * Stop preview of all scrub animations on stage
 * @param {ps} ps
 * @param {function|null} onComplete a callback to run when scrub preview is stopped
 */
const cancelScrub = (ps: PS, onComplete?: () => void) => {
    ps.siteAPI.motion.cancelScrub(onComplete)
}

export default {
    enable,
    disable,
    play,
    cancel,
    cancelAll,
    startScrub,
    updateScrub,
    cancelScrub
}
