import type {PS} from '@wix/document-services-types'
import nicknameContextRegistrar from './nicknameContextRegistrar'
import * as experiment from 'experiment'
import {registerHookWithPs} from '../hooks/eventHooks/eventHooks'
import {DATA_MODEL_HOOKS, COMPONENTS_HOOKS} from '@wix/document-manager-extensions/src/hooks'
import repeaterHooks from '../hooks/componentHooks/repeaterHooks'
import {upgradeFacebookShareUrlOnAdditionFromExt} from '../hooks/componentHooks/facebookShare'
import {upgradePinterestPinUrlOnAdditionFromExt} from '../hooks/componentHooks/pinterestPinIt'
import {upgradeVKShareButtonUrlOnAdditionFromExt} from '../hooks/componentHooks/vkShareButton'
import {upgradeFacebookCommentsOnAdditionFromExt} from '../hooks/componentHooks/wFacebookComment'
import {upgradeWFacebookLinkOnAdditionFromExt} from '../hooks/componentHooks/wFacebookLike'
import fiveGridLineHooks from '../hooks/componentHooks/fiveGridLineHooks'
import {convertCompToNewSiteWidthHook} from '../hooks/layoutHooks/siteWidthMigration'
import popupPageHooks from '../hooks/componentHooks/popupPageHooks'
import hooks from '../hooks/hooks'

const registerComponentHooksFromExt = (ps: PS) => {
    registerHookWithPs(
        ps,
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER_FROM_EXT.id,
        upgradeFacebookShareUrlOnAdditionFromExt,
        'wysiwyg.viewer.components.FacebookShare'
    )
    registerHookWithPs(
        ps,
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER_FROM_EXT.id,
        upgradeFacebookCommentsOnAdditionFromExt,
        'wysiwyg.viewer.components.WFacebookComment'
    )
    registerHookWithPs(
        ps,
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER_FROM_EXT.id,
        upgradeWFacebookLinkOnAdditionFromExt,
        'wysiwyg.viewer.components.WFacebookLike'
    )
    registerHookWithPs(
        ps,
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER_FROM_EXT.id,
        fiveGridLineHooks.replaceFullScreenPropertyWithDockingFromExt,
        'wysiwyg.viewer.components.FiveGridLine'
    )
    registerHookWithPs(
        ps,
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER_FROM_EXT.id,
        upgradePinterestPinUrlOnAdditionFromExt,
        'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt'
    )
    registerHookWithPs(
        ps,
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER_FROM_EXT.id,
        upgradeVKShareButtonUrlOnAdditionFromExt,
        'wysiwyg.viewer.components.VKShareButton'
    )
    registerHookWithPs(
        ps,
        COMPONENTS_HOOKS.ADD_COMPONENT.ADJUST_COMP_LAYOUT.id,
        popupPageHooks.adjustCompLayoutInPopupPageFromExt,
        'mobile.core.components.Page'
    )
}
function initialize(ps: PS) {
    registerHookWithPs(
        ps,
        DATA_MODEL_HOOKS.GET_REPEATED_ITEM_POINTER.BEFORE.id,
        repeaterHooks.beforeGetRepeatedItemPointerHook
    )
    registerComponentHooksFromExt(ps)
    /*should register after ext hooks - depends on convert mesh layout*/
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, convertCompToNewSiteWidthHook)
    if (experiment.isOpen('dm_moveNicknameContextProviderExt')) {
        ps.extensionAPI.nicknameContext.setContextProvider(null)
    } else {
        nicknameContextRegistrar.setContextProvider(null)
    }
}
export default {
    initialize
}
