import type {DAL, DocumentManager} from '@wix/document-manager-core'
import type {PageAPI} from '../../../page'
import type {MasterPageMigrator} from '../dataMigrationRunner'
import {COMP_TYPES, VIEW_MODES} from '../../../../constants/constants'
import type {Experiment, Pointer} from '@wix/document-services-types'

const shouldDeleteMegaMenu = (dal: DAL, mobilePointer: Pointer, parentPointer: Pointer) => {
    const megaMenuParent = dal.get(parentPointer)
    return (
        dal.get(mobilePointer) && megaMenuParent && megaMenuParent.componentType !== COMP_TYPES.STYLABLE_HORIZONTAL_MENU
    )
}

const migrateMasterPage = (documentManager: DocumentManager, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen('dm_deleteMegaMenusInMobile')) {
        return
    }
    const {dal, extensionAPI, pointers} = documentManager
    const pageIndex = (extensionAPI.page as PageAPI).getPageIndexId('masterPage')

    const allMobileMegaMenus = dal.query(
        VIEW_MODES.MOBILE,
        pageIndex,
        comp => comp.componentType === COMP_TYPES.MEGA_MENU_CONTAINER_ITEM
    )
    for (const megaMenuId of Object.keys(allMobileMegaMenus)) {
        const mobilePointer = pointers.getPointer(megaMenuId, VIEW_MODES.MOBILE)
        const parentPointer = pointers.structure.getParent(mobilePointer)
        if (parentPointer && shouldDeleteMegaMenu(dal, mobilePointer, parentPointer)) {
            const siblingsListPointer = pointers.getInnerPointer(parentPointer, 'components')
            dal.set(
                siblingsListPointer,
                dal.get(siblingsListPointer).filter((id: string) => id !== megaMenuId)
            )
            dal.remove(mobilePointer)
        }
    }
}

const name = 'deleteMegaMenusInMobile'
const version = 0
const experimentalVersions = [{version: 1, experiment: 'dm_deleteMegaMenusInMobile'}]

export const deleteMegaMenusInMobile: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version,
    experimentalVersions,
    fixerRequiresReruns: false
}
