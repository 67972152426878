import type {CompRef, Rect} from '@wix/document-services-types'
import {type DAL, type ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {VariantsExtensionAPI} from '../../variants/variants'
import type {ConversionDataBuilder} from './types'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import type {ComponentConversionData} from '../types'
import {DATA_TYPES} from '../../../constants/constants'
import _ from 'lodash'
import {createAxisAlignedEnvelope} from './utils/axisAlignedEnvelope'
import {ConversionDalNamespaces} from '../conversionDal/constants'

export const enum SemanticTypes {
    TEXT = 'text',
    BUTTON = 'button',
    IMAGE = 'image',
    SVG = 'svg',
    HORIZONTAL_LINE = 'horizontal line',
    BOX = 'box',
    SECTION = 'section',
    EMBEDDED_MEDIA = 'embedded media',
    UNCLASSIFIED = 'unclassified'
}

interface CompTypeToSemanticTypePredicate {
    predicate(componentType: string): boolean
    semanticType: SemanticTypes
}

const TEXT_COMP_TYPES = new Set(['wysiwyg.viewer.components.WRichText', 'wixui.CollapsibleText'])
const BUTTON_COMP_TYPES = new Set(['wysiwyg.viewer.components.SiteButton'])
const IMAGE_COMP_TYPES = new Set(['wixui.ImageX', 'wysiwyg.viewer.components.WPhoto'])
const SVG_COMP_TYPES = new Set(['wysiwyg.viewer.components.VectorImage'])
const MEDIA_CONTAINER_COMP_TYPE = new Set([
    'wysiwyg.viewer.components.MediaBox',
    'wysiwyg.viewer.components.StripColumnsContainer',
    'wysiwyg.viewer.components.ClassicSection',
    'wysiwyg.viewer.components.Column',
    'wysiwyg.viewer.components.StateBoxState',
    'wysiwyg.viewer.components.StateBoxFormState',
    'wysiwyg.viewer.components.StateStripState',
    'wysiwyg.viewer.components.BoxSlideShowSlide',
    'wysiwyg.viewer.components.StripContainerSlideShowSlide',
    'wysiwyg.viewer.components.MediaContainer',
    'wysiwyg.viewer.components.MenuContainer'
])
const EMBEDDED_MEDIA_COMP_TYPES = new Set([
    'wysiwyg.viewer.components.MediaPlayer',
    'wysiwyg.viewer.components.HtmlComponent',
    'wixui.VideoPlayer',
    'wixui.CustomElementComponent',
    'wysiwyg.viewer.components.GoogleMap'
])
const HORIZONTAL_LINE_COMP_TYPE = 'wysiwyg.viewer.components.FiveGridLine'
const CONTAINER_COMP_TYPE = 'mobile.core.components.Container'
const SECTION_COMP_TYPE = 'wysiwyg.viewer.components.ClassicSection'
const COMP_TYPES_TO_SEMANTIC_TYPE_MAPPER: CompTypeToSemanticTypePredicate[] = [
    {
        predicate: (componentType: string) => TEXT_COMP_TYPES.has(componentType),
        semanticType: SemanticTypes.TEXT
    },
    {
        predicate: (componentType: string) => BUTTON_COMP_TYPES.has(componentType),
        semanticType: SemanticTypes.BUTTON
    },
    {
        predicate: (componentType: string) => IMAGE_COMP_TYPES.has(componentType),
        semanticType: SemanticTypes.IMAGE
    },
    {
        predicate: (componentType: string) => SVG_COMP_TYPES.has(componentType),
        semanticType: SemanticTypes.SVG
    },
    {
        predicate: (componentType: string) => EMBEDDED_MEDIA_COMP_TYPES.has(componentType),
        semanticType: SemanticTypes.EMBEDDED_MEDIA
    },
    {
        predicate: (componentType: string) => componentType === HORIZONTAL_LINE_COMP_TYPE,
        semanticType: SemanticTypes.HORIZONTAL_LINE
    },
    {
        predicate: (componentType: string) => componentType === CONTAINER_COMP_TYPE,
        semanticType: SemanticTypes.BOX
    },
    {
        predicate: (componentType: string) => componentType === SECTION_COMP_TYPE,
        semanticType: SemanticTypes.SECTION
    }
]

const getSemanticType = (componentType: string): SemanticTypes => {
    for (const {predicate, semanticType} of COMP_TYPES_TO_SEMANTIC_TYPE_MAPPER) {
        if (predicate(componentType)) {
            return semanticType
        }
    }

    return SemanticTypes.UNCLASSIFIED
}

const createEnvelope = (rect: Rect, degrees: number | undefined): Rect => {
    if (degrees && degrees !== 0) {
        return createAxisAlignedEnvelope(rect, degrees)
    }
    return {...rect}
}

const commonBuilder: ConversionDataBuilder = (
    dal: DAL,
    extensionAPI: ExtensionAPI,
    compPointer: CompRef
): Omit<ComponentConversionData, 'virtualParentId'> => {
    const component = dal.get(compPointer)
    const {variants} = extensionAPI as VariantsExtensionAPI
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const layout = variants.getComponentItemConsideringVariants(compPointer, DATA_TYPES.layout)
    const measurements = _.pick(component.layout, ['x', 'y', 'width', 'height']) as Rect

    return {
        [ConversionDalNamespaces.structure]: {
            id: compPointer.id,
            componentType: component.componentType,
            semanticType: getSemanticType(component.componentType),
            type: component.type!,
            skin: component.skin!,
            components: component.components,
            parent: component.parent!,
            axisAlignedEnvelope: createEnvelope(measurements, component.layout.rotationInDegrees),
            data: dataModel.components.getItem(compPointer, DATA_TYPES.data),
            media: {
                uri: ''
            }
        },
        [ConversionDalNamespaces.measurements]: measurements,
        [ConversionDalNamespaces.layout]: layout
    }
}

const customConversionDataBuilders: Record<string, ConversionDataBuilder> = {
    'wysiwyg.viewer.components.WRichText': (dal: DAL, extensionAPI: ExtensionAPI, compPointer: CompRef) => {
        const baseStyleSettings = (extensionAPI as VariantsExtensionAPI).variants.getComponentItemConsideringVariants(
            compPointer,
            DATA_TYPES.theme
        )
        const fontSize = parseFloat(baseStyleSettings?.style?.properties?.fontSize?.match(/\d+/)[0])

        return {
            [ConversionDalNamespaces.style]: {
                fontSize
            }
        }
    },
    'wysiwyg.viewer.components.SiteButton': (dal: DAL, extensionAPI: ExtensionAPI, compPointer: CompRef) => {
        const baseStyleSettings = (extensionAPI as VariantsExtensionAPI).variants.getComponentItemConsideringVariants(
            compPointer,
            DATA_TYPES.theme
        )
        const fontSize = parseFloat(baseStyleSettings?.style?.propertiesOverride?.fnt?.fontSize.match(/\d+/)[0])

        return {
            [ConversionDalNamespaces.style]: {
                fontSize
            }
        }
    },
    'wixui.CollapsibleText': (dal: DAL, extensionAPI: ExtensionAPI, compPointer: CompRef) => {
        const baseStyleSettings = (extensionAPI as VariantsExtensionAPI).variants.getComponentItemConsideringVariants(
            compPointer,
            DATA_TYPES.theme
        )
        const fontSize = parseFloat(baseStyleSettings.style.propertiesOverride?.['text-font'].fontSize.match(/\d+/)[0])
        const collapsibleTextButtonFontSize = parseFloat(
            baseStyleSettings.style.propertiesOverride?.['button-font'].fontSize.match(/\d+/)[0]
        )

        return {
            [ConversionDalNamespaces.style]: {
                fontSize,
                collapsibleTextButtonFontSize
            }
        }
    },
    ...Object.fromEntries(
        Array.from(MEDIA_CONTAINER_COMP_TYPE).map((compType: string) => [
            compType,
            (dal: DAL, extensionAPI: ExtensionAPI, compPointer: CompRef) => {
                const {dataModel} = extensionAPI as DataModelExtensionAPI
                const designData = dataModel.components.getItem(compPointer, DATA_TYPES.design)
                const uri = designData?.background?.mediaRef?.uri

                if (uri) {
                    return {
                        [ConversionDalNamespaces.structure]: {
                            media: {
                                uri
                            }
                        }
                    }
                }
            }
        ])
    )
}

export const getConversionData: ConversionDataBuilder = (
    dal: DAL,
    extensionAPI: ExtensionAPI,
    compPointer: CompRef
) => {
    const componentType = dal.get(pointerUtils.getInnerPointer(compPointer, 'componentType'))
    const conversionDataBuilders = _.over(commonBuilder, customConversionDataBuilders[componentType] ?? _.noop)
    return _.merge({}, ...conversionDataBuilders(dal, extensionAPI, compPointer))
}
