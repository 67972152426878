import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {
    ComponentFeature,
    CompRef,
    DalValue,
    ExternalsMap,
    RawComponentExport,
    RawComponentExportStructure,
    RawImportExportOptions
} from '@wix/document-services-types'
import {
    STRUCTURE_PROPERTIES_TO_KEEP,
    SPECIAL_DATA_NODE_IDS_MAP,
    BASE_STRUCTURE_PROPERTIES,
    COMPONENT_QUERIES,
    COMPONENT_QUERY_TO_NAMESPACE_MAP
} from '../constants'
import {mergePageBreakpoints} from './utils/breakpoints'
import {updateChildren} from './utils/children'
import {updateComponentDataItem} from './utils/dataItems'
import {updateOverrides} from './utils/overrides'
import {getComponentFeaturesByType, isSystemDataItem} from '../utils'
import {updateGlobalData} from './utils/globalData'

const updateDataItems = (
    extensionContext: CreateExtArgs,
    componentPointer: CompRef,
    component: RawComponentExportStructure,
    externals: ExternalsMap,
    {componentFeatures}: RawImportExportOptions
) => {
    const {dal, pointers, extensionAPI} = extensionContext

    const features = getComponentFeaturesByType(component.structure.componentType, componentFeatures)
    _.forOwn(features, ({query, namespace, alias}: ComponentFeature) => {
        const dataItem = component.data[alias]

        updateComponentDataItem(extensionContext, componentPointer, dataItem, namespace, externals)

        const exportQueryValue = component.structure[query]
        if (isSystemDataItem(exportQueryValue, namespace, extensionAPI)) {
            const queryPointer = pointers.getInnerPointer(componentPointer, query)
            dal.set(queryPointer, exportQueryValue)
        }
    })
}

const updateComponentStructure = (
    extensionContext: CreateExtArgs,
    componentPointer: CompRef,
    componentStructure: RawComponentExportStructure['structure']
) => {
    const {dal, extensionAPI} = extensionContext
    const origStructure = dal.get(componentPointer)
    const newStructure = {
        ..._.pick(origStructure, ...BASE_STRUCTURE_PROPERTIES),
        ...componentStructure,
        ..._.pickBy(origStructure, (value: DalValue, property: string) => {
            if (STRUCTURE_PROPERTIES_TO_KEEP.has(property)) {
                return true
            }

            if (COMPONENT_QUERIES.has(property)) {
                const namespace = COMPONENT_QUERY_TO_NAMESPACE_MAP[property]
                return !isSystemDataItem(value, namespace, extensionAPI)
            }
        })
    }

    if (origStructure.components === undefined) {
        delete newStructure.components
    }

    dal.set(componentPointer, newStructure)
}

const updateMobileStructure = ({dal, pointers}: CreateExtArgs, componentPointer: CompRef) => {
    const mobilePointer = pointers.structure.getMobilePointer(componentPointer)
    const origMobileStructure = dal.get(mobilePointer)
    if (origMobileStructure) {
        const componentStructure = dal.get(componentPointer)

        const newStructure = {
            ...componentStructure,
            components: []
        }

        if (origMobileStructure.components === undefined) {
            delete newStructure.components
        }

        if (origMobileStructure.components) dal.set(mobilePointer, newStructure)
    }
}
const addAppControllers = (componentExport: RawComponentExport): void => {
    const {components, externalRefs, appControllers} = componentExport

    for (const [id, {externalRef, parent, appController}] of Object.entries(appControllers)) {
        if (externalRef) {
            externalRefs[id] = externalRef
        } else if (parent) {
            const {structure} = components[parent]
            structure.components ??= []
            structure.components.push(appController.structure.id)
            components[appController.structure.id] = appController
        }
    }
}

export const importAllComponents = (
    extensionContext: CreateExtArgs,
    componentId: RawComponentExport['rootComponent'],
    componentExport: RawComponentExport,
    componentToReplace: CompRef,
    externals: ExternalsMap,
    options: RawImportExportOptions
) => {
    const {components} = componentExport
    const component = components[componentId]
    externals.externalRefs[componentId] = componentToReplace.id

    updateComponentStructure(extensionContext, componentToReplace, component.structure)
    updateDataItems(extensionContext, componentToReplace, component, externals, options)
    updateOverrides(extensionContext, componentToReplace, component, externals, options)
    updateChildren(extensionContext, componentToReplace, component.structure, componentExport, externals, options)
    updateMobileStructure(extensionContext, componentToReplace)
}

export const importRawComponent = (
    extensionContext: CreateExtArgs,
    componentId: RawComponentExport['rootComponent'],
    componentExport: RawComponentExport,
    componentToReplace: CompRef,
    options: RawImportExportOptions
) => {
    const {components, externalRefs, appControllers, globalData} = componentExport
    const component = components[componentId]

    const externals: ExternalsMap = {
        externalRefs: _.defaults(externalRefs, SPECIAL_DATA_NODE_IDS_MAP),
        appControllers
    }

    addAppControllers(componentExport)
    mergePageBreakpoints(extensionContext, componentToReplace, component, componentExport, externals)
    updateGlobalData(extensionContext, componentToReplace, globalData, externals)
    importAllComponents(extensionContext, componentId, componentExport, componentToReplace, externals, options)
}
