import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import * as constants from '../../../../constants/constants'
import {runOnPageComponents} from './utils/migrationUtil'
import type {PageMigrator} from '../dataMigrationRunner'

const fixBreakpointVariantsQuery = (documentManager: DocumentManager, pageId: string, pointer: Pointer) => {
    const {dal, pointers} = documentManager
    const breakpointVariantsPointer = pointers.getInnerPointer(pointer, 'breakpointVariantsQuery')
    const variantId = dal.get(breakpointVariantsPointer)
    const variantPointer = pointers.data.getVariantsDataItem(variantId, pageId)
    const variantData = dal.get(variantPointer)
    const withCorruptedQuery = variantData && variantData.componentId !== pointer.id
    if (withCorruptedQuery) {
        dal.remove(breakpointVariantsPointer)
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    _.forEach(constants.VIEW_MODES, viewMode => {
        runOnPageComponents(documentManager, pageId, viewMode, fixBreakpointVariantsQuery)
    })
}

const name = 'fixBreakpointsVariantsQuery'
const version = 1

export const fixBreakpointsVariantsQuery: PageMigrator = {
    migratePage,
    name,
    version
}
