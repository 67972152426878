import type {PublicMethodUtils} from '@wix/document-services-types'
import {actionsAndBehaviors, utils, motion} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            behaviors: {
                /**
                 * Return the definition containing settings and parameters of a behavior
                 * @member documentServices.behaviors
                 * @param {String} behaviorName
                 * @returns {Object|null}
                 */
                getDefinition: publicMethodUtils.defineGetter(actionsAndBehaviors.getBehaviorDefinition),
                /**
                 * Get names of behaviors.
                 * Filter by optional compType and/or actionName
                 * @member documentServices.behaviors
                 * @param {String} [compType]
                 * @param {String} [actionName]
                 * @returns {Array}
                 */
                getNames: publicMethodUtils.defineGetter(actionsAndBehaviors.getBehaviorNames),
                /**
                 * Update an existing behaviors or add a new one (if no such behavior exists), for the actionSourceRef behaviors
                 * @member documentServices.behaviors  actionSourceRef, action, behaviorTargetRef, behavior
                 * @param {AbstractComponent} actionSourceRef - a reference to the element the action is triggered on
                 * @param {ActionObj} action - The object representing the action {type: '', name: ''}
                 * @param {AbstractComponent} behaviorTargetRef - a reference to the behavior target entity
                 * @param {BehaviorObj} behavior - The object representing the behavior that should be activated on the behaviorTargetRef, once the action is triggered
                 */
                update: publicMethodUtils.defineDataManipulationAction(actionsAndBehaviors.updateBehavior),
                /**
                 * Returns the behaviors saved on a component structure
                 * @member documentServices.behaviors
                 * @param {AbstractComponent} actionSourceRef - a reference to the element the behaviors are saved on
                 * @returns {Array<BehaviorObjr>}
                 */
                get: publicMethodUtils.defineGetter(actionsAndBehaviors.getBehaviors),
                /**
                 * Removes all behaviors between source and target refs that match action and behavior type and name
                 *
                 * @member documentServices.behaviors
                 * @param {AbstractComponent} actionSourceRef - a reference to the element the action is triggered on
                 * @param {ActionObj} action - The object representing the action {type: '', name: ''}
                 * @param {AbstractComponent} behaviorTargetRef - a reference to the behavior target entity
                 * @param {BehaviorObj} behavior - The object representing the behavior {type: '', name: ''}
                 */
                remove: publicMethodUtils.defineDataManipulationAction(actionsAndBehaviors.removeBehavior)
            },
            actions: {
                /**
                 * Return the definition containing settings and parameters of an action
                 * @member documentServices.actions
                 * @param {String} actionName
                 * @returns {Object|null}
                 */
                getDefinition: publicMethodUtils.defineGetter(actionsAndBehaviors.getActionDefinition),
                /**
                 * Trigger an action
                 * @member documentServices.actions
                 * @param {String} actionName
                 */
                executeAction: publicMethodUtils.defineAction(actionsAndBehaviors.executeAction),
                /**
                 * Names of available actions, sorted a-z
                 * @member documentServices.actions
                 * @returns {Array}
                 */
                getNames: publicMethodUtils.defineGetter(actionsAndBehaviors.getActionNames),

                /**
                 * Executes all animations for this page as if the page has reloaded
                 */
                executeAnimationsInPage: publicMethodUtils.defineAction(actionsAndBehaviors.executeAnimationsInPage)
            },
            components: {
                is: {
                    /**
                     * Test if a component type can be animated
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @param {String} [actionName]
                     * @returns {Boolean}
                     */
                    animatable: publicMethodUtils.defineGetter(actionsAndBehaviors.isBehaviorable)
                },
                behaviors: {
                    /**
                     * Set behaviors to a component structure, will override any previous behaviors
                     * @member documentServices.components.behaviors
                     * @param {AbstractComponent} componentReference
                     * @param {SavedBehavior} behavior
                     * @param {String} [actionName]
                     */
                    update: publicMethodUtils.defineDataManipulationAction(actionsAndBehaviors.setComponentBehavior, {
                        isUpdatingAnchors: utils.DONT_CARE
                    }),
                    /**
                     * Returns the behaviors saved on a component structure
                     * @member documentServices.components.behaviors
                     * @param {AbstractComponent} componentReference
                     * @returns {Array<SavedBehavior>|null}
                     */
                    get: publicMethodUtils.defineGetter(actionsAndBehaviors.getComponentBehaviors),
                    /**
                     * Remove a single behavior from a component structure
                     * @member documentServices.components.behaviors
                     * @param {AbstractComponent} componentReference
                     * @param {String} [behaviorName] if not set will remove all behaviors of actionName
                     * @param {String} actionName
                     * @deprecated
                     */
                    remove: publicMethodUtils.defineDataManipulationAction(
                        actionsAndBehaviors.removeComponentSingleBehavior,
                        {
                            isUpdatingAnchors: utils.DONT_CARE
                        }
                    ),
                    /**
                     * Remove behaviors from a component structure
                     * @member documentServices.components.behaviors
                     * @param {AbstractComponent} componentReference
                     */
                    removeAll: publicMethodUtils.defineDataManipulationAction(
                        actionsAndBehaviors.removeComponentBehaviors
                    ),
                    /**
                     * Preview an animation on a component
                     * @deprecated
                     * @member documentServices.components.behaviors
                     * @param {AbstractComponent} componentReference
                     * @param {{name:string, duration:number, delay:number, params:object}} animationDef
                     * @param {object} transformationsToRestore a list of animation params that represent values of the component style that should be restored after clearProps
                     * @returns {string} sequence id (to be used with stopPreview)
                     */
                    preview: publicMethodUtils.defineAction(actionsAndBehaviors.deprecatedPreviewAnimation),
                    /**
                     * Stop animation preview by sequence id returned by previewAnimation
                     * @deprecated
                     * @member documentServices.components.behaviors
                     * @param {AbstractComponent} componentReference
                     * @param {string} sequenceId
                     */
                    stopPreview: publicMethodUtils.defineAction(actionsAndBehaviors.deprecatedStopPreviewAnimation),
                    /**
                     * Preview an animation on a component
                     * @member documentServices.components.behaviors
                     * @param {AbstractComponent} componentReference
                     * @param {{name:string, duration:number, delay:number, params:object}} animationDef
                     * @param {function} onComplete a callback to run at the end of the preview animation
                     * @returns {string} sequence id (to be used with stopPreview)
                     */
                    previewAnimation: publicMethodUtils.defineAction(actionsAndBehaviors.previewAnimation),
                    /**
                     * Preview a transition on 2 components
                     * @param {AbstractComponent} srcCompReference
                     * @param {AbstractComponent} targetCompReference
                     * @param {{name:string, duration:number, delay:number, params:object}} transitionDef
                     * @param {function} onComplete a callback to run at the end of the preview animation
                     * @returns {string} sequence id (to be used with stopPreview)
                     */
                    previewTransition: publicMethodUtils.defineAction(actionsAndBehaviors.previewTransition),
                    /**
                     * Stop animation preview by sequence id returned by previewAnimatio
                     * @member documentServices.components.behaviors
                     * @param {string} sequenceId
                     */
                    stopPreviewAnimation: publicMethodUtils.defineAction(actionsAndBehaviors.stopPreviewAnimation),
                    /**
                     * Preview scrub animations on stage by animations and corresponding triggers
                     * @member documentServices.components.behaviors
                     * @param {Record<string, ScrubAnimationDef>}  animations
                     * @param {Record<string, TriggerVariant>} triggers
                     */
                    previewScrubAnimations: publicMethodUtils.defineAction(actionsAndBehaviors.previewScrubAnimations),
                    /**
                     * Update preview of current scrub animations on stage by animations and corresponding triggers
                     * @member documentServices.components.behaviors
                     * @param {Record<string, ScrubAnimationDef>}  animations
                     * @param {Record<string, TriggerVariant>} triggers
                     */
                    updateScrubAnimationsPreview: publicMethodUtils.defineAction(
                        actionsAndBehaviors.updateScrubAnimationsPreview
                    ),
                    /**
                     * Stop preview of all scrub animations on stage
                     * @member documentServices.components.behaviors
                     */
                    stopPreviewScrubAnimations: publicMethodUtils.defineAction(
                        actionsAndBehaviors.stopPreviewScrubAnimations
                    )
                },
                motion: {
                    enable: publicMethodUtils.defineAction(motion.enable),
                    disable: publicMethodUtils.defineAction(motion.disable),
                    /**
                     * Preview an animation on a component
                     * @member documentServices.components.motion
                     * @param {ComponentRef} componentReference
                     * @param {{name:string, duration:number, delay:number, params:object}} animationData
                     * @param {function} onComplete a callback to run at the end of the preview animation
                     * @returns {object} animations data object
                     */
                    play: publicMethodUtils.defineAction(motion.play),
                    /**
                     * Stop animation preview by sequence id, accessed in 'play''s return oject
                     * @member documentServices.components.motion
                     * @param {string} effectId
                     */
                    cancel: publicMethodUtils.defineAction(motion.cancel),

                    /**
                     * Stop all animation previews
                     * @member documentServices.components.motion
                     */
                    cancelAll: publicMethodUtils.defineAction(motion.cancelAll),

                    /**
                     * Preview scrub animations on stage by animations and corresponding triggers
                     * @member documentServices.components.motion
                     * @param {ps} ps
                     * @param {Record<string, TriggerVariant>} triggers
                     * @param {Record<string, ScrubAnimationDef>}  animationMap
                     */
                    startScrub: publicMethodUtils.defineAction(motion.startScrub),

                    /**
                     * Update preview of current scrub animations on stage by animations and corresponding triggers
                     * @member documentServices.components.behaviors
                     * @param {Record<string, TriggerVariant>} triggers
                     * @param {Record<string, ScrubAnimationDef>}  animationMap
                     */
                    updateScrub: publicMethodUtils.defineAction(motion.updateScrub),

                    /**
                     * Stop preview of all scrub animations on stage
                     * @member documentServices.components.behaviors
                     * @param {function|null} onComplete a callback to run when scrub preview is stopped
                     */
                    cancelScrub: publicMethodUtils.defineAction(motion.cancelScrub)
                }
            },
            pages: {
                transitions: {
                    /**
                     * Set the pages transition
                     * @member documentServices.pages.transitions
                     * @param {String} transitionName
                     */
                    set: publicMethodUtils.defineDataManipulationAction(actionsAndBehaviors.setPagesTransition),
                    /**
                     * Get the current pages transition
                     * @member documentServices.pages.transitions
                     * @returns {String}
                     */
                    get: publicMethodUtils.defineGetter(actionsAndBehaviors.getPagesTransition),
                    /**
                     * Returns the names of *legacy* transitions sorted a-z
                     * @member documentServices.pages.transitions
                     * @todo: this will change when we will have transition per page
                     * @returns {String[]}
                     */
                    getNames: publicMethodUtils.defineGetter(actionsAndBehaviors.getPageTransitionsNames)
                },
                animations: {
                    stopAll: publicMethodUtils.defineAction(actionsAndBehaviors.stopAndClearAllAnimations)
                }
            }
        }
    }
}
