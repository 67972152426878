import type {CreateExtArgs, DalItem, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {CompRef, ObjectBasedStyle, Pointer} from '@wix/document-services-types'
import type {VariantsExtensionAPI} from '../variants/variants'
import {stripHashIfExists} from '../../utils/refArrayUtils'
import type {SchemaExtensionAPI} from '../schema/schema'
import {ReportableError} from '@wix/document-manager-utils'
import {DATA_TYPES} from '../../constants/constants'
import {withHash} from '../import-export/json/utils'

type InnerElementPath = string[]

interface InnerElementsApi extends ExtensionAPI {
    getInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath): DalItem | undefined
    getInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath): ObjectBasedStyle | undefined
    setInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath, data: any): void
    setInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath, style: any): void
    serializeInnerElementPath(innerElementPath: InnerElementPath): string
}

interface InnerElementsExtensionAPI extends ExtensionAPI {
    innerElements: InnerElementsApi
}

interface InnerElementsPublicApi extends ExtensionAPI {
    innerElements: InnerElementsApi
}

function error(message: string, extras: any = {}) {
    throw new ReportableError({errorType: 'InnerElementsError', message, ...extras})
}

function namesFromPath(path: InnerElementPath): string[] {
    // The first element is always the root, we can skip it
    const [, ...rest] = path
    return rest
}

const createExtension = (): Extension => {
    return {
        name: 'innerElements',
        dependencies: new Set(['schema', 'dataModel', 'variants']),
        createPublicAPI(args: CreateExtArgs): InnerElementsPublicApi {
            const {innerElements} = args.extensionAPI as InnerElementsExtensionAPI
            return {
                innerElements: {
                    getInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath) {
                        return innerElements.getInnerElementData(compRef, innerElementPath)
                    },
                    getInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath) {
                        return innerElements.getInnerElementStyle(compRef, innerElementPath)
                    },
                    setInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath, data: any) {
                        return innerElements.setInnerElementData(compRef, innerElementPath, data)
                    },
                    setInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath, style: any) {
                        return innerElements.setInnerElementStyle(compRef, innerElementPath, style)
                    },
                    serializeInnerElementPath(innerElementPath: InnerElementPath): string {
                        return innerElements.serializeInnerElementPath(innerElementPath)
                    }
                }
            }
        },
        createExtensionAPI(args: CreateExtArgs): InnerElementsExtensionAPI {
            const {dal, pointers} = args
            const {schemaAPI, dataModel, variants} = args.extensionAPI as SchemaExtensionAPI &
                DataModelExtensionAPI &
                VariantsExtensionAPI
            const namespace = DATA_TYPES.innerElements

            function innerElementsPointer(id: string, overrides?: Partial<Pointer>): Pointer {
                return pointers.getPointer(id, DATA_TYPES.innerElements, overrides)
            }

            function dataPointer(id: string): Pointer {
                return pointers.getPointer(id, DATA_TYPES.data)
            }

            function getComp(compRef: CompRef): DalItem {
                const comp = dal.get(compRef)
                if (!comp) {
                    throw error('Component not found', {compRef})
                }
                return comp
            }

            function getOrCreateInnerElementsMapPointerFromComp(compRef: CompRef): Pointer {
                const comp = getComp(compRef)
                let innerElementsQuery = stripHashIfExists(comp.innerElementsQuery)
                if (!innerElementsQuery) {
                    const def = schemaAPI.getDefinition(comp.componentType)
                    if (!def) {
                        throw error('No definition for component type', {compType: comp.componentType})
                    }
                    const {innerElementsMapType} = def
                    if (!innerElementsMapType) {
                        throw error('No inner elements map type for component type', {compType: comp.componentType})
                    }
                    const mapPointer = dataModel.components.addItem(compRef, namespace, {
                        type: innerElementsMapType,
                        metaData: {
                            pageId: compRef.pageId
                        }
                    })
                    innerElementsQuery = mapPointer.id
                }
                return innerElementsPointer(innerElementsQuery)
            }

            function getOrCreateInnerElementsMapPointerFromInnerElement(innerElementId: string): Pointer {
                const pointer = innerElementsPointer(innerElementId)
                const innerElement = dal.get(pointer)
                if (!innerElement) {
                    throw error('Inner element not found', {innerElementId})
                }
                if (!innerElement.innerElementsQuery) {
                    const def = schemaAPI.getDefinition(innerElement.referredType)
                    if (!def) {
                        throw error('No definition for inner element type', {
                            innerElementType: innerElement.referredType
                        })
                    }
                    const {innerElementsMapType} = def
                    if (!innerElementsMapType) {
                        throw error('No inner elements map type for inner element type', {
                            innerElementType: innerElement.referredType
                        })
                    }

                    const mapPointer = dataModel.addItem(
                        {
                            type: innerElementsMapType
                        },
                        namespace,
                        innerElement.metaData.pageId!
                    )
                    const innerElementsQuery = mapPointer.id
                    dal.modify(pointer, (i: any) => ({
                        ...i,
                        innerElementsQuery: withHash(innerElementsQuery)
                    }))
                }
                const updatedElement = dal.get(pointer)
                return innerElementsPointer(stripHashIfExists(updatedElement.innerElementsQuery!))
            }

            function getOrCreateInnerElementFromComponent(compRef: CompRef, innerElementName: string): DalItem {
                const comp = getComp(compRef)
                const compType = comp.componentType
                const innerType = schemaAPI.getDefinition(compType).innerTypes?.[innerElementName]
                if (!innerType) {
                    throw error('No inner element type for inner element', {innerElementName})
                }
                const mapPointer = getOrCreateInnerElementsMapPointerFromComp(compRef)
                const map = dal.get(mapPointer)
                if (!map[innerElementName]) {
                    const ptr = dataModel.addItem(
                        {
                            type: 'InnerElement',
                            referredType: innerType
                        },
                        namespace,
                        compRef.pageId!
                    )
                    dal.modify(mapPointer, (m: any) => ({
                        ...m,
                        [innerElementName]: withHash(ptr.id)
                    }))
                }

                // Possible micro-optimization: No need for dal accesses here, we already have the info in scope
                const updatedMap = dal.get(mapPointer)
                const innerElementId = stripHashIfExists(updatedMap[innerElementName])
                return dal.get(innerElementsPointer(innerElementId))
            }

            function getOrCreateInnerElementFromInnerElement(
                pageId: string,
                source: DalItem,
                targetName: string
            ): DalItem {
                const mapPointer = getOrCreateInnerElementsMapPointerFromInnerElement(source.id!)
                const map = dal.get(mapPointer)
                if (!map[targetName]) {
                    const ptr = dataModel.addItem(
                        {
                            type: 'InnerElement',
                            referredType: schemaAPI.getDefinition(source.referredType).innerTypes?.[targetName]
                        },
                        namespace,
                        pageId
                    )
                    dal.modify(mapPointer, (m: any) => ({
                        ...m,
                        [targetName]: withHash(ptr.id)
                    }))
                }
                const updatedMap = dal.get(mapPointer)
                return dal.get(innerElementsPointer(stripHashIfExists(updatedMap[targetName])))
            }

            function getOrCreateInnerElement(compRef: CompRef, innerElementPath: InnerElementPath): DalItem {
                const [firstName, ...restOfNames] = namesFromPath(innerElementPath)
                const firstElement = getOrCreateInnerElementFromComponent(compRef, firstName)
                return restOfNames.reduce(
                    (acc, name) => getOrCreateInnerElementFromInnerElement(compRef.pageId!, acc, name),
                    firstElement
                )
            }

            function getInnerElementFromComponent(compRef: CompRef, innerElementName: string): DalItem | undefined {
                const comp = getComp(compRef)
                const innerElementsQuery = stripHashIfExists(comp.innerElementsQuery)
                if (!innerElementsQuery) {
                    return undefined
                }
                const map = dal.get(innerElementsPointer(innerElementsQuery))
                if (!map) {
                    return undefined
                }
                const innerElementId = stripHashIfExists(map[innerElementName])
                if (!innerElementId) {
                    return undefined
                }
                return dal.get(innerElementsPointer(innerElementId))
            }

            function getInnerElementFromInnerElement(source: DalItem, innerElementName: string): DalItem | undefined {
                const innerElementsQuery = stripHashIfExists(source.innerElementsQuery)
                if (!innerElementsQuery) {
                    return undefined
                }
                const map = dal.get(innerElementsPointer(innerElementsQuery))
                if (!map) {
                    return undefined
                }
                const innerElementId = stripHashIfExists(map[innerElementName])
                if (!innerElementId) {
                    return undefined
                }
                return dal.get(innerElementsPointer(innerElementId))
            }

            function getInnerElement(compRef: CompRef, innerElementPath: InnerElementPath): DalItem | undefined {
                // The first element is always the root, we can skip it
                const [, firstName, ...restOfNames] = innerElementPath
                const firstElement = getInnerElementFromComponent(compRef, firstName)
                return restOfNames.reduce(
                    (acc, name) => (acc ? getInnerElementFromInnerElement(acc, name) : undefined),
                    firstElement
                )
            }

            return {
                innerElements: {
                    serializeInnerElementPath(innerElementPath: InnerElementPath) {
                        return innerElementPath.join('|')
                    },
                    getInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath) {
                        const innerElement = getInnerElement(compRef, innerElementPath)
                        const dataQuery = stripHashIfExists(innerElement?.dataQuery)
                        return dal.get(dataPointer(dataQuery))
                    },
                    setInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath, data: any) {
                        const innerElement = getOrCreateInnerElement(compRef, innerElementPath)
                        if (!innerElement.dataQuery) {
                            const dataQuery = dataModel.addItem(data, 'data', compRef.pageId!)
                            dal.modify(innerElementsPointer(innerElement.id!), (i: any) => ({
                                ...i,
                                dataQuery: withHash(dataQuery.id)
                            }))
                        } else {
                            const dataQuery = stripHashIfExists(innerElement.dataQuery)
                            dal.set(dataPointer(dataQuery), data)
                        }
                    },
                    getInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath) {
                        const comp = dal.get(compRef)
                        if (!comp) {
                            throw error('Component not found', {compRef})
                        }

                        const innerElement = getInnerElement(compRef, innerElementPath)
                        if (!innerElement?.styleId) {
                            return undefined
                        }

                        return variants.getComponentItemConsideringVariants(
                            innerElementsPointer(innerElement.id!, {variants: compRef.variants}),
                            DATA_TYPES.theme
                        )
                    },
                    setInnerElementStyle(
                        compRef: CompRef,
                        innerElementPath: InnerElementPath,
                        style: ObjectBasedStyle
                    ) {
                        const innerElement = getOrCreateInnerElement(compRef, innerElementPath)
                        const styleRef = variants.updateComponentDataConsideringVariants(
                            innerElementsPointer(innerElement.id!, {variants: compRef.variants}),
                            style,
                            DATA_TYPES.theme
                        )
                        dal.modify(innerElementsPointer(innerElement.id!), (ie: any) => ({
                            ...ie,
                            styleId: withHash(styleRef.id)
                        }))
                    }
                }
            }
        }
    }
}

export {createExtension}
export type {InnerElementsExtensionAPI, InnerElementsApi, InnerElementPath}
