import type {DocumentManager} from '@wix/document-manager-core'
import type {MasterPageMigrator} from '../dataMigrationRunner'
import * as constants from '../../../../constants/constants'

const {DATA_TYPES} = constants
const TPAS0_ID = 'tpas0'
const TPAS0_SKIN = 'wysiwyg.viewer.skins.TPASectionSkin'

const SYSTEM_STYLES_TO_FIX = [
    {id: 'tpas0', skin: 'wysiwyg.viewer.skins.TPASectionSkin'},
    {id: 'tpaw0', skin: 'wysiwyg.viewer.skins.TPAWidgetSkin'}
]

const fixSystemStyle = ({dal, pointers}: DocumentManager, styleId: string, skin: string) => {
    const stylePointer = pointers.getPointer(styleId, DATA_TYPES.theme)
    const style = dal.get(stylePointer)
    if (style === undefined || style.skin !== undefined) {
        return
    }

    const styleSkinPointer = pointers.getInnerPointer(stylePointer, 'skin')
    dal.set(styleSkinPointer, skin)
}

const migrateMasterPage = (documentManager: DocumentManager) => {
    const {dal, pointers, experimentInstance} = documentManager
    if (experimentInstance.isOpen('dm_fixTpaw0SystemStyleMissingSkin')) {
        for (const {id, skin} of SYSTEM_STYLES_TO_FIX) {
            fixSystemStyle(documentManager, id, skin)
        }
        return
    }

    const tpas0Pointer = pointers.getPointer(TPAS0_ID, DATA_TYPES.theme)
    const tpas0 = dal.get(tpas0Pointer)
    if (tpas0 === undefined || tpas0.skin !== undefined) {
        return
    }

    const tpas0SkinPointer = pointers.getInnerPointer(tpas0Pointer, 'skin')
    dal.set(tpas0SkinPointer, TPAS0_SKIN)
}

const name = 'fixTpas0SystemStyleMissingSkin'
const version = 1

export const fixTpas0SystemStyleMissingSkin: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version,
    experimentalVersions: [{version: 2, experiment: 'dm_fixTpaw0SystemStyleMissingSkin'}]
}
