import {ReportableError, ReportableWarning} from '@wix/document-manager-utils'
import type {DropCommitContext, Pointer, SimpleDalAPI, Tags} from '@wix/document-services-types'
import {
    createImmutableProxy,
    createImmutableProxyForMap,
    createImmutableProxyForMapOfMaps,
    deepClone
} from '@wix/wix-immutable-proxy'
import type {EventEmitter} from 'events'
import _ from 'lodash'
import type {CoreConfig} from '../documentManagerCore'
import type {
    DocumentDataTypes,
    FilterGetter,
    IdGenerator,
    IndexKey,
    KeyValPredicate,
    Null,
    NullUndefined,
    PostSetOperation,
    PostTransactionOperation,
    PostTransactionSideEffect,
    Transaction,
    ValidationWhitelistCheck,
    BaselineBlacklistCheck
} from '../types'
import {debug} from '../utils/debug'
import {deepCompare} from '../utils/deepCompare'
import {deepCompareIgnoreSignature} from '../utils/deepCompareIgnoreSignature'
import {map_findBy, map_pickBy} from '../utils/pickBy'
import {
    getInnerPath,
    getInnerPointer,
    getInnerValue,
    getPointer,
    hasInnerPath,
    stripInnerPath
} from '../utils/pointerUtils'
import {createUniqueIdGenerator} from '../utils/uniqueIdGenerator'
import {createQueryIndex, IndexedValues, QueryIndex, QueryNamespace, ValueToIndexIds} from './queryIndex/queryIndex'
import {createDalSchema, DalSchema} from './schema/dalSchema'
import {isConflicting as isConflictingValue} from './signatures/isConflicting'
import {createSnapshotChain, SnapshotDal} from './snapshot/snapshotDal'
import {createList, SnapshotList} from './snapshot/snapshotList'
import {createTagManager, TagManager} from './snapshot/tagManager'
import {createStore, createStoreFromJS, DalJsStore, DalStore, DalValue, DmStore} from './store'
import {createValidator, ValidateValue} from './validation/dalValidation'

export type SetterSet = (pointer: Pointer, value: any) => void
export type Getter = (pointer: Pointer) => DalValue
export type CustomGetter = (dal: PDAL, rootPointer: Pointer) => DalValue
export type QueryFilterGetters = Record<string, FilterGetter>
export type GetIndexed = (indexKey: IndexKey) => IndexedValues
export type DalValueChangeCallback = (pointer: Pointer, oldValue: DalValue, newValue: DalValue) => void

export type PDAL = Pick<DAL, 'queryFilterGetters' | 'query' | 'get'>

export const TransactionEvents = {
    TRANSACTION_REJECTED: 'TRANSACTION_REJECTED',
    TRANSACTION_BY_OTHER: 'TRANSACTION_BY_OTHER',
    LOCAL_TRANSACTION_APPROVED: 'LOCAL_TRANSACTION_APPROVED'
} as const

export type TransactionEvent = keyof typeof TransactionEvents

export interface CreateArgs {
    coreConfig: CoreConfig
    postSetOperations: PostSetOperation[]
    dmTypes: DocumentDataTypes
    customGetters: Record<string, CustomGetter>
    eventEmitter: EventEmitter
    initialStore?: DalStore
}

interface OpenTransaction {
    id: string
    store: DmStore
    attempt: number
}

// These error constructors explicitly set the prototype since it's the recommended workaround for a TypeScript issue:
// See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
export class CommitsDisabledError extends ReportableError {
    constructor() {
        super({
            errorType: 'CommitsDisabledError',
            message:
                'commitTransaction was called when commits were disabled. Perhaps disableCommits was called without a subsequent call to enableCommits?'
        })
        Object.setPrototypeOf(this, CommitsDisabledError.prototype)
    }
}

export class TransactionRejectionError extends Error {
    constructor(id: string, reason?: string) {
        const reasonMessage = reason ? `. Reason: ${reason}` : ''
        super(`Transaction was rejected by server, transaction id ${id}${reasonMessage}`)
        Object.setPrototypeOf(this, TransactionRejectionError.prototype)
    }
}

export class CannotApproveError extends ReportableError {
    constructor(tx: SnapshotDal | null, id: string, args: any, txCompareIds: SnapshotDal | null) {
        super({
            errorType: 'cannotApproveError',
            message: `Cannot approve, ${id} is not a pending transaction`,
            extras: {
                id,
                ...args,
                unlinked: !tx,
                foundByCompareIds: !!txCompareIds,
                content: _.keys(tx?.getStore().asJson())
            }
        })
        Object.setPrototypeOf(this, CannotApproveError.prototype)
    }
}

/**
 * document manager DAL
 */
export interface DAL extends SimpleDalAPI {
    readonly queryFilterGetters: Record<string, FilterGetter>
    readonly tagManager: TagManager
    readonly registrar: {
        registerFilter(name: string, filter: ValueToIndexIds): void
        registerValidator(name: string, validate: ValidateValue): void
        registerRebaseValidator(name: string, validate: ValidateValue): void
        registerValidationWhitelistCheck(whitelistCheck: ValidationWhitelistCheck): void
        registerPostTransactionOperation(name: string, operation: PostTransactionOperation): void
        registerBaselineBlacklistCheck(check: BaselineBlacklistCheck): void
        registerForChangesCallback(callback: DalValueChangeCallback): void
        unregisterForChangesCallback(callback: DalValueChangeCallback): void
    }

    // for debug / test
    readonly _store: DmStore
    readonly _queryIndex: QueryIndex
    readonly _tentativeStore: DmStore
    readonly _snapshots: SnapshotList
    readonly _currentTransaction: OpenTransaction
    _getApprovedStoreAsJson(): DalJsStore
    _getTentativeStoreAsJson(): DalJsStore
    _getCommittedStoreAsJson(): DalJsStore
    _getMergedStoreAsJson(): DalJsStore
    _getAllTags(): Record<string, string[]>

    /** mark items as approved and move them from tentative store to the store */
    approve(pid: string): void
    reject(pid: string): void

    commitTransaction(committer?: string, skipValidations?: boolean): SnapshotDal
    find(namespace: string, indexKey: IndexKey, predicate: KeyValPredicate): any
    getIndexed(indexKey: IndexKey): IndexedValues
    getIndexKeys(indexName: string): string[]
    get(pointer: Pointer): DalValue
    getRegisteredTypes(): DocumentDataTypes
    getWithPath(pointer: Pointer, innerPath: string | string[]): any
    has(pointer: Pointer): boolean
    isDirty(pointer: Pointer): boolean
    query(namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate): Record<string, DalValue>
    queryKeys(namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate): any
    getIndexPointers(indexKey: IndexKey, namespace?: string): Pointer[]
    rebase(change: DmStore, position: string, label?: string): SnapshotDal
    rebaseForeignChange(change: DmStore, position: string, correlationId?: string): SnapshotDal
    remove(pointer: Pointer): void
    set(pointer: Pointer, value: DalValue): void
    setIfChanged(pointer: Pointer, value: DalValue): void

    /** Modify a dal value using the given function
     *
     * This is syntactic sugar, instead of:
     * ```
     * const v1 = dal.get(pointer)
     * const v2 = doSomething(v1)
     * dal.set(pointer, v2)
     * ```
     * You can use: `dal.modify(pointer, doSomething)`
     */
    modify<A, B = A>(pointer: Pointer, f: (a: A) => B): void
    touch(pointer: Pointer): void
    getLastSnapshot(): SnapshotDal | null
    takeSnapshot(tag: string): number
    takeLastApprovedSnapshot(tag: string): void
    validate(tags?: Record<string, any>): void
    validatePendingCommit(tags?: Record<string, any>): void
    createValidationBaseline(): void
    sign(
        rootPointer: Pointer,
        value: DalValue,
        signOver?: Null<SnapshotDal>
    ): {value: DalValue; basedOnSignature: NullUndefined<string>}
    getBasedOnSignatureId(namespace?: string, id?: string): string
    getBasedOnSignaturePointer(pointer: Pointer): Pointer
    getBasedOnSignature(pointer: Pointer): NullUndefined<string>
    setBasedOnSignature(pointer: Pointer, signature: NullUndefined<string>): void
    getTentativeAndAcceptedAsTransaction(): Transaction
    getCurrentOpenTransaction(): Transaction
    mergeToApprovedStore(changes: DmStore, id?: string): SnapshotDal
    getLastApprovedSnapshot(): SnapshotDal
    dropUncommittedTransaction(reason?: string, e?: any, context?: DropCommitContext): void
    enableCommits(): void
    disableCommits(): void
    withoutCustomGetters(func: Function): any
    readonly schema: DalSchema
    hasSignature(pointer: Pointer): boolean
}

const EMPTY_MAP = new Map()
const basedOnSignatureNS = 'basedOnSignature'

const pointersInNamespace = (namespace: QueryNamespace | undefined, pointerType: string): Pointer[] =>
    namespace ? [...namespace.keys()].map(id => getPointer(id, pointerType)) : []

// As part of the major wix wide refactor known as "the builder project"
// Some editors will wrap our pointer.
// To help them out - we throw an exception if they accidentally passed it on to us
const isEditorPointer = (pointer: Pointer & {documentPointer?: any}): boolean => pointer.documentPointer

const validatePointer = (pointer: Pointer & {documentPointer?: any}): void => {
    if (isEditorPointer(pointer)) {
        throw new ReportableError({
            errorType: 'editorPointer',
            message: 'Editor pointer detected, please unwrap the pointer before using it',
            extras: {pointer}
        })
    }
}

class Registrar {
    constructor(private dal: DocumentManagerDAL) {}

    registerFilter(name: string, filter: ValueToIndexIds): void {
        const filterFactory = this.dal._queryIndex.createFilterFactory(name, filter)
        this.dal.queryFilterGetters[filterFactory.indexName] = filterFactory.getFilter
    }
    registerValidator(name: string, validateValue: ValidateValue): void {
        this.dal.validator.registerValidator(name, validateValue)
    }
    registerRebaseValidator(name: string, validateValue: ValidateValue): void {
        this.dal.rebaseValidator.registerValidator(name, validateValue)
    }
    registerValidationWhitelistCheck(whitelistCheck: ValidationWhitelistCheck) {
        this.dal.validator.registerWhitelistCheck(whitelistCheck)
    }
    registerPostTransactionOperation(name: string, operation: PostTransactionOperation): void {
        this.dal.postTransactionOperations[name] = operation
    }
    registerBaselineBlacklistCheck(check: BaselineBlacklistCheck): void {
        this.dal.validator.registerBaselineBlacklistCheck(check)
    }
    registerForChangesCallback(callback: DalValueChangeCallback) {
        this.dal.updateCallbacks.push(callback)
    }
    unregisterForChangesCallback(callback: DalValueChangeCallback) {
        _.remove(this.dal.updateCallbacks, e => e === callback)
    }
}

class DocumentManagerDAL implements DAL {
    private debugLogger = debug('dal')
    private queryIndex = createQueryIndex()
    queryFilterGetters: Record<string, FilterGetter> = {}
    tagManager = createTagManager()
    registrar = new Registrar(this)

    private store = createStore()
    private createOpenTxId = createUniqueIdGenerator(3)
    private tentativeStore = createStore()
    private currentOpenTransaction: OpenTransaction = this.createNewOpenTransaction()
    private lastApproved: Null<SnapshotDal> = null
    private snapshots = createList()
    readonly schema
    postTransactionOperations: Record<string, PostTransactionOperation> = {}
    validator
    rebaseValidator

    private createSignature: IdGenerator
    private commitsEnabled = true
    private customGettersEnabled = true
    updateCallbacks: DalValueChangeCallback[] = []
    constructor(
        private coreConfig: CoreConfig,
        private postSetOperations: PostSetOperation[],
        private dmTypes: DocumentDataTypes,
        private customGetters: Record<string, CustomGetter>,
        private eventEmitter: EventEmitter,
        initialStore?: DalStore
    ) {
        this.schema = createDalSchema({
            schemaService: this.coreConfig.schemaService,
            experiments: {}
        })
        this.validator = createValidator(this.coreConfig, [basedOnSignatureNS])
        this.rebaseValidator = createValidator(this.coreConfig, [basedOnSignatureNS])

        this.createSignature = createUniqueIdGenerator(3, this.coreConfig.signatureSeed)
        if (initialStore) {
            this.mergeToApprovedStore(createStore(initialStore))
        }
    }

    private getMergedStoreAsJson(includeCurrentTransaction: boolean): DalJsStore {
        const mergedStore = this.getMergedStore(includeCurrentTransaction)
        return mergedStore.asJson()
    }

    private validateTransactionStoreSize(store: DmStore, tags?: Tags) {
        const storeActionsAmount = store.size()
        if (storeActionsAmount > this.coreConfig.transactionActionsLimit) {
            const err = new ReportableWarning({
                errorType: 'exceedsMaxActionsPerTransaction',
                message: `Exceeds actions reached ${storeActionsAmount}`,
                extras: {tags}
            })
            this.coreConfig.logger.captureError(err)
            if (this.coreConfig.experimentInstance.isOpen('dm_failOnExceedsMaxActionsPerTransaction')) {
                throw err
            }
        }
    }

    /**
     * Merge all the changes since the last approved snapshot up to the new approved
     * snapshot into the main store and update the approved snapshot pointer.
     * Rebuild the tentative store with the remaining unapproved changes.
     * @param id id of the approved snapshot
     */
    approve(id: string) {
        const tx = this.snapshots.findByIdLimit(id, this.lastApproved!)
        if (!tx) {
            throw this.createCannotApproveError(id)
        }

        const diff: DmStore = createStoreFromJS(tx.mutableDiff(this.lastApproved))
        this.store.merge(diff)

        const newTentativeStore = createStoreFromJS(this.snapshots.last()?.mutableDiff(tx) as DalJsStore)
        this.tentativeStore = newTentativeStore
        this.lastApproved = tx
    }
    /**
     * Delete all the tentative snapshots from the snapshot with the given id to the last
     * approved snapshot and rebuild the tentative store with the remaining tentative snapshots
     * @param id - id of the rejected snapshot
     */
    reject(id: string) {
        const tx = this.snapshots.findByIdLimit(id, this.lastApproved!)
        if (!tx) {
            // Possibly sent and later identified as conflict, not necessarily a bug
            return
        }

        this.applyOnSnapshotsRange(this.lastApproved!, tx, (snapshot: SnapshotDal) => {
            this.snapshots.remove(snapshot)
            this.tagManager.remove(snapshot)
        })

        this.removeConflictsAndReport(TransactionEvents.TRANSACTION_REJECTED)
    }
    /**
     * Add the current open transaction to the snapshot chain and merge it to the tentative store.
     * Notify listeners of the commit
     * @param committer
     * @param skipValidations
     */
    commitTransaction(committer?: string | undefined, skipValidations?: boolean | undefined): SnapshotDal {
        if (!this.commitsEnabled) {
            this.coreConfig.logger.captureError(new CommitsDisabledError())
            return this.snapshots.last()!
        }
        const currentOpenTransactionStore = this.currentOpenTransaction.store
        if (currentOpenTransactionStore.isEmpty()) {
            return this.snapshots.last()!
        }

        const attempt = ++this.currentOpenTransaction.attempt
        if (!skipValidations) {
            this.validatePendingCommit({committer, attempt, openTxId: this.currentOpenTransaction.id})
        }

        const pointersModifiedInTheTransaction = currentOpenTransactionStore.keys()
        this.createNewOpenTransaction()

        if (this.isConflictingWithCommittedValue(currentOpenTransactionStore)) {
            this.updateIndex(pointersModifiedInTheTransaction)
            return this.snapshots.last()!
        }

        this.tentativeStore.merge(currentOpenTransactionStore)
        const snapshotId = this.snapshots.add(currentOpenTransactionStore)

        this.report(currentOpenTransactionStore, TransactionEvents.LOCAL_TRANSACTION_APPROVED)
        return snapshotId
    }
    find(namespace: string, indexKey: IndexKey, predicate: KeyValPredicate) {
        this.verifyQueryParams(namespace)

        const namespaces = this.queryIndex.getIndexedValues(indexKey)
        const queryResult = namespaces.get(namespace)
        const findResult = map_findBy(queryResult, predicate)

        return createImmutableProxy(findResult)
    }
    getIndexed(indexKey: IndexKey): IndexedValues {
        const namespaces = this.queryIndex.getIndexedValues(indexKey)

        return createImmutableProxyForMapOfMaps(namespaces ?? EMPTY_MAP) as IndexedValues
    }
    getIndexKeys(indexName: string): string[] {
        return this.queryIndex.getIndexKeys(indexName)
    }
    get(pointer: Pointer) {
        return createImmutableProxy(this.getRawValue(pointer))
    }
    getWithPath(pointer: Pointer, innerPath: string | string[]) {
        return this.get(getInnerPointer(pointer, innerPath))
    }
    has(pointer: Pointer): boolean {
        return !_.isNil(this.getRawValue(pointer))
    }
    isDirty(pointer: Pointer): boolean {
        return this.currentOpenTransaction.store.has(pointer)
    }
    query(namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate | undefined): Record<string, any> {
        const result = this.queryRaw(namespace, indexKey, optionalPredicate)

        return createImmutableProxyForMap(result ?? EMPTY_MAP)
    }
    queryKeys(namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate | undefined) {
        const result = this.queryRaw(namespace, indexKey, optionalPredicate)
        return result ? [...result.keys()] : []
    }
    /**
     * Returns all the pointers in the index referred to by the specified index key and optionally only in the given namespace
     * @param indexKey
     * @param namespace
     * @returns An array of the pointers in the specified index
     */
    getIndexPointers(indexKey: IndexKey, namespace?: string | undefined): Pointer[] {
        const namespaces = this.queryIndex.getIndexedValues(indexKey)

        if (namespace) {
            const namespaceValues = namespaces.get(namespace)

            return pointersInNamespace(namespaceValues, namespace)
        }

        return [...namespaces.entries()].flatMap(([ns, namespaceValues]) => pointersInNamespace(namespaceValues, ns))
    }
    /**
     * Insert the given snapshot into the snapshot chain at the specified position
     *
     * @param change
     * @param position
     * @param event
     * @param id
     */
    _rebase(change: DmStore, position: string, event: TransactionEvent, id: string) {
        const tx = this.snapshots.findByIdLimitInclusive(position, this.lastApproved!)
        if (!tx) {
            return this.rebaseApprovedHistory(change, position, event, id)
        }

        const newSnapshot = this.snapshots.insert(change, position, id)
        this.removeConflictsReportAndVerify(change, position, event, newSnapshot.id)
        return newSnapshot
    }
    rebase(change: DmStore, position: string, label?: string) {
        return this._rebase(
            change,
            position,
            TransactionEvents.LOCAL_TRANSACTION_APPROVED,
            this.snapshots.generateId(label)
        )
    }
    /**
     * Rebase the given snapshot and mark the new snapshot as foreign
     * @param change
     * @param position
     * @param correlationId
     */
    rebaseForeignChange(change: DmStore, position: string, correlationId?: string) {
        const id = correlationId ?? this.snapshots.generateId('unknown-foreign')
        const newSnapshot = this._rebase(change, position, TransactionEvents.TRANSACTION_BY_OTHER, id)
        newSnapshot.setAsForeign()
        return newSnapshot
    }
    remove(pointer: Pointer): void {
        const innerPath = getInnerPath(pointer)
        const rootPointer = stripInnerPath(pointer)
        let newValue
        if (innerPath.length > 0) {
            newValue = deepClone(this.getRawValue(rootPointer))
            _.unset(newValue, innerPath)
        }
        this.signAndUpdateTransactionAndIndex(rootPointer, newValue)
    }
    _set(pointer: Pointer, value: DalValue): void {
        validatePointer(pointer)
        const innerPath = getInnerPath(pointer)
        const rootPointer = stripInnerPath(pointer)
        let newValue = deepClone(value)
        if (innerPath.length > 0) {
            const rootValue = deepClone(this.getRawValue(rootPointer)) || {}
            newValue = _.setWith(rootValue, innerPath, newValue, Object)
        }
        this.signAndUpdateTransactionAndIndex(rootPointer, newValue)
    }
    set(pointer: Pointer, value: any): void {
        this.setIfChanged(pointer, value)
    }
    setIfChanged(pointer: Pointer, value: any): void {
        if (this.isChanged(pointer, value)) {
            this._set(pointer, value)
        }
    }
    modify<A, B = A>(pointer: Pointer, f: (a: A) => B): void {
        return this.set(pointer, f(this.get(pointer)))
    }
    touch(pointer: Pointer): void {
        return this._set(pointer, this.getterForSetAndTouch(pointer))
    }
    getLastSnapshot(): SnapshotDal | null {
        return this.snapshots.last()
    }
    takeSnapshot(tag: string): number {
        const snapshot = this.commitTransaction('takeSnapshot')
        return this.tagManager.addSnapshot(tag, snapshot)
    }
    takeLastApprovedSnapshot(tag: string): void {
        this.tagManager.addSnapshot(tag, this.getLastApprovedSnapshot())
    }
    validate(tags?: Record<string, any>) {
        const tentativeAndAccepted = this.getMergedStore(false)
        this.validator.validateStore(tentativeAndAccepted, false, tags)
    }
    validatePendingCommit(tags?: Record<string, any> | undefined): void {
        this.validateTransactionStoreSize(this.currentOpenTransaction.store, tags)
        return this.validator.validateStore(this.currentOpenTransaction.store, true, tags)
    }
    createValidationBaseline() {
        const tentativeAndAccepted = this.getMergedStore(false)
        this.validator.createBaseline(tentativeAndAccepted)
    }
    sign(
        rootPointer: Pointer,
        value: any,
        signOver?: Null<SnapshotDal> | undefined
    ): {value: any; basedOnSignature: NullUndefined<string>} {
        let basedOnSignature
        if (value && this.hasSignature(rootPointer)) {
            const previousValue = signOver ? signOver.getValue(rootPointer) : this.getCommittedValue(rootPointer)
            basedOnSignature = previousValue ? previousValue.metaData?.sig ?? null : undefined
            value.metaData = Object.assign(value.metaData || {}, {
                sig: this.createSignature()
            })
        }
        return {value, basedOnSignature}
    }
    getBasedOnSignatureId(namespace?: string | undefined, id?: string | undefined): string {
        return `${namespace}$${id}`
    }
    getBasedOnSignaturePointer(pointer: Pointer): Pointer {
        return getPointer(this.getBasedOnSignatureId(pointer.type, pointer.id), basedOnSignatureNS)
    }
    getBasedOnSignature(pointer: Pointer): NullUndefined<string> {
        return this.get(this.getBasedOnSignaturePointer(pointer))
    }
    setBasedOnSignature(pointer: Pointer, basedOnSignature: NullUndefined<string>): void {
        this._set(this.getBasedOnSignaturePointer(pointer), basedOnSignature)
    }
    getTentativeAndAcceptedAsTransaction(): Transaction {
        const tentativeAndAccepted = this.getMergedStore(false)
        const transaction = {
            id: 'tentativeAndAccepted',
            items: tentativeAndAccepted.getValues()
        }
        return createImmutableProxy(transaction)
    }
    getCurrentOpenTransaction() {
        return createImmutableProxy({
            id: 'openTransaction',
            items: this.currentOpenTransaction.store.getValues()
        })
    }
    /**
     * Copies changes into the dal store. Called during DS initialization
     * @param changes - the store to copy into the dal store
     * @param label
     */
    mergeToApprovedStore(changes: DmStore, label?: string): SnapshotDal {
        const id = this.getMergeToApprovedStoreSnapshotId(changes, label)
        const clonedChanges = changes.clone()

        return this.mergeSnapshotToApprovedStore(clonedChanges, id)
    }
    getLastApprovedSnapshot(): SnapshotDal {
        if (!this.lastApproved) {
            throw new Error('Empty DAL does not have a last approved snapshot')
        }
        return this.lastApproved
    }
    dropUncommittedTransaction(reason?: string, e?: any, context: DropCommitContext = {}) {
        const pointersToUpdate = this.currentOpenTransaction.store.keys()
        const pointerToReport = _(pointersToUpdate)
            .reject(ptr => {
                return ptr.type === 'basedOnSignature'
            })
            .map('id')
            .join(',')
        console.error('dropUncommittedTransaction', e, context)
        this.coreConfig.logger.captureError(
            new ReportableError({
                message: 'dropUncommittedTransaction',
                errorType: 'dropUncommittedTransaction',
                tags: {
                    attempt: this.currentOpenTransaction.attempt,
                    id: this.currentOpenTransaction.id
                },
                extras: {
                    reason,
                    appDefinitionId: _.get(context, ['appDefinitionId'], ''),
                    stack: e?.stack ? e.stack.slice(0, 3000) : '',
                    pointersToUpdate: pointerToReport
                }
            })
        )
        this.createNewOpenTransaction()
        this.updateIndex(pointersToUpdate)
    }
    enableCommits(): void {
        this.commitsEnabled = true
    }
    disableCommits(): void {
        this.commitsEnabled = false
    }
    withoutCustomGetters(func: Function) {
        return (...args: any[]) => {
            try {
                this.customGettersEnabled = false
                return func(...args)
            } finally {
                this.customGettersEnabled = true
            }
        }
    }
    hasSignature(pointer: Pointer): boolean {
        const idsWithSignature = this.dmTypes[pointer.type]?.idsWithSignature
        if (idsWithSignature) {
            return idsWithSignature.has(pointer.id)
        }
        return this.dmTypes[pointer.type]?.hasSignature ?? false
    }
    private isRegisteredNamespace(type: string): boolean {
        return !!this.dmTypes[type]
    }
    getRegisteredTypes(): DocumentDataTypes {
        return this.dmTypes
    }
    private verifyQueryParams(namespace: string): void {
        if (!this.isRegisteredNamespace(namespace)) {
            console.log(`Namespace ${namespace} is not registered`)
            throw new Error(`Namespace ${namespace} is not registered`)
        }
    }
    private queryRaw(
        namespace: string,
        indexKey: IndexKey,
        optionalPredicate?: KeyValPredicate
    ): QueryNamespace | undefined {
        const namespaces = this.queryIndex.getIndexedValues(indexKey)
        let queryNamespace = namespaces.get(namespace)

        if (optionalPredicate && queryNamespace) {
            queryNamespace = map_pickBy(queryNamespace, optionalPredicate)
        }

        return queryNamespace
    }
    private getFromStores(stores: DmStore[], rootPointer: Pointer): DalValue {
        const foundStore = stores.find((s: DmStore) => s.has(rootPointer))
        return foundStore ? foundStore.get(rootPointer) : undefined
    }
    private getMergedStoreValue(pointer: Pointer): any {
        return this.getFromStores([this.currentOpenTransaction.store, this.tentativeStore, this.store], pointer)
    }
    private getCommittedValue(pointer: Pointer): DalValue {
        return this.getFromStores([this.tentativeStore, this.store], pointer)
    }
    private getApprovedValue(pointer: Pointer): DalValue {
        return this.getFromStores([this.store], pointer)
    }
    private getRootValueFromCustomGetterOrStore(rootPointer: Pointer): DalValue {
        const customGetterArgument = {
            queryFilterGetters: this.queryFilterGetters,
            query: this.query,
            get: (ptr: Pointer) => createImmutableProxy(this.getMergedStoreValue(ptr))
        }
        //either using an extension defined getter or the default store
        if (this.customGettersEnabled) {
            const customGetter = this.customGetters[rootPointer.type]
            if (customGetter) {
                return customGetter(customGetterArgument, rootPointer)
            }
        }
        return this.getCommittedValue(rootPointer)
    }
    private getRawValue(pointer: Pointer): DalValue {
        validatePointer(pointer)
        const transactionStore = this.currentOpenTransaction.store
        const rootPointer = stripInnerPath(pointer)
        let rootValue
        if (transactionStore.has(rootPointer)) {
            rootValue = transactionStore.get(rootPointer)
        } else {
            rootValue = this.getRootValueFromCustomGetterOrStore(rootPointer)
        }
        return getInnerValue(pointer, rootValue)
    }
    private createNewOpenTransaction() {
        const newTransaction = {
            attempt: 0,
            id: this.createOpenTxId(),
            store: createStore()
        }
        this.currentOpenTransaction = newTransaction

        return newTransaction
    }
    private callPostTransactionOperationsAndAccumulateAsyncSideEffects(
        transaction: Transaction
    ): null | PostTransactionSideEffect {
        const asyncSideEffects = _(this.postTransactionOperations)
            .map(cb => cb(transaction))
            .compact()
            .value() as PostTransactionSideEffect[]

        const hasSideEffects = !_.isEmpty(asyncSideEffects)
        if (hasSideEffects) {
            return async () => {
                await Promise.all(asyncSideEffects.map(p => p()))
            }
        }
        return null
    }
    private report(reportStore: DmStore, event: TransactionEvent): void {
        if (reportStore.isEmpty()) {
            return
        }
        const transaction = {
            id: 'post-transaction-report',
            items: reportStore.getValues()
        }
        const postTransactionSideEffectsFunction =
            this.callPostTransactionOperationsAndAccumulateAsyncSideEffects(transaction)
        this.eventEmitter.emit(event, postTransactionSideEffectsFunction)
    }
    /**
     * Returns true if any of the values in the store have a signature
     * that conflicts with that of the getter value for the same pointer
     * @param transactionStore
     * @param getter
     */
    private isConflicting(transactionStore: DmStore, getter: Getter): boolean {
        return transactionStore.some(
            (pointer, value) =>
                this.hasSignature(pointer) &&
                isConflictingValue(
                    value,
                    getter(pointer),
                    transactionStore.get(this.getBasedOnSignaturePointer(pointer))
                )
        )
    }
    private isConflictingWithMergedValue(transactionStore: DmStore): boolean {
        return this.isConflicting(transactionStore, pointer => this.getMergedStoreValue(pointer))
    }
    private isConflictingWithCommittedValue(transactionStore: DmStore): boolean {
        return this.isConflicting(transactionStore, pointer => this.getCommittedValue(pointer))
    }
    private isConflictingWithApprovedValue(transactionStore: DmStore): boolean {
        return this.isConflicting(transactionStore, pointer => this.getApprovedValue(pointer))
    }

    /** Update the query index of the `pointer` to its value in the merged store
     *
     * The index must always reflect the overall current state of the system.
     * Externally that would be the result from `get`, internally that would be the merged store.
     *
     * In other words, it's illegal to update the index to a value other than the one in the merged store.
     * To enforce this invariant, this function accepts only a pointer, not a value. It then fetches the value
     * from the merged store.
     */
    private updateSingleIndexValue(pointer: Pointer): void {
        const newValue = this.getMergedStoreValue(pointer)
        const previousValue = this.queryIndex.updateIndex(pointer, newValue)
        this.updateCallbacks.forEach((callback: DalValueChangeCallback) => {
            callback(pointer, previousValue, newValue)
        })
    }

    private updateIndex(pointers: Pointer[]): void {
        pointers.forEach(pointer => this.updateSingleIndexValue(pointer))
    }
    private runPostSetOperations(pointer: Pointer, value: DalValue) {
        try {
            _.forEach(this.postSetOperations, postSet => postSet(pointer, value))
        } catch (error) {
            this.coreConfig.logger.captureError(error as Error, {
                tags: {postSetOp: true},
                extras: {
                    pointer,
                    value
                }
            })
            throw error
        }
    }

    private setWithSignature(pointer: Pointer, valueToSet: DalValue) {
        const {value, basedOnSignature} = this.sign(pointer, valueToSet)
        if (value && this.hasSignature(pointer)) {
            this.setBasedOnSignature(pointer, basedOnSignature)
        }
        this.currentOpenTransaction.store.set(pointer, value)
    }

    private signAndUpdateTransactionAndIndex(rootPointer: Pointer, value: DalValue) {
        this.setWithSignature(rootPointer, value)
        this.updateSingleIndexValue(rootPointer)
        this.runPostSetOperations(rootPointer, value)
    }

    private getterForSetAndTouch(pointer: Pointer) {
        if (this.coreConfig.experimentInstance.isOpen('dm_useCustomGettersForSetAndTouch')) {
            return this.getRawValue(pointer)
        }
        return this.getMergedStoreValue(pointer)
    }

    private isChanged(pointer: Pointer, value: DalValue): boolean {
        const currentValue = this.getterForSetAndTouch(pointer)
        if (hasInnerPath(pointer)) {
            const currentInnerValue = getInnerValue(pointer, currentValue)
            return !deepCompare(currentInnerValue, value)
        }

        return !deepCompareIgnoreSignature(currentValue, value)
    }
    private isSameValue(a: DalValue, b: DalValue) {
        const sigA = a?.metaData?.sig
        const sigB = b?.metaData?.sig
        return sigA || sigB ? sigA === sigB : deepCompare(a, b)
    }

    private collectChanges(oldTentativeStore: DmStore, originalChange?: DmStore): DmStore {
        const changes = createStore()

        oldTentativeStore.forEach((pointer, otherValue) => {
            const dalValue = this.getCommittedValue(pointer)
            if (!this.isSameValue(dalValue, otherValue)) {
                changes.set(pointer, dalValue)
            }
        })

        if (originalChange) {
            originalChange.forEach((pointer, value) => {
                if (!oldTentativeStore.has(pointer)) {
                    changes.set(pointer, value)
                }
            })
        }

        return changes
    }

    private getMergedStore(includeCurrentTransaction: boolean): DmStore {
        const mergedStore = createStore()
        mergedStore.merge(this.store)
        mergedStore.merge(this.tentativeStore)
        if (includeCurrentTransaction) {
            mergedStore.merge(this.currentOpenTransaction.store)
        }

        return mergedStore
    }

    /** Run validators on the pointers that exist in `storeToValidate`
     *
     * The validators will be called only on these pointers but remember that they already
     * have access to the entire DAL (since they're part of the extensions).
     * That means that to ensure correctness this function needs to run on a valid DAL with an
     * updated index.
     * In addition, extensions that hold internal state usually update said state in postTransactionOperations.
     * If validators rely on that state and postTransactionOperations weren't called, this function may behave poorly.
     */
    private hasRebaseValidationErrors(storeToValidate: DmStore): boolean {
        try {
            this.rebaseValidator.validateStore(storeToValidate, true, {isRebasing: true})
            return false
        } catch (e) {
            return true
        }
    }

    private rebuildOpenStore(snaps: SnapshotDal[]): void {
        const keys = this.currentOpenTransaction.store.keys()
        this.createNewOpenTransaction()
        snaps.forEach(snap => {
            const snapStore = snap.getStore()
            this.currentOpenTransaction.store.merge(snapStore)
            keys.push(...snapStore.keys())
        })
        this.updateIndex(keys)
    }

    private removeTentativeSnapshotWithoutUpdatingTheIndex(snapshot: SnapshotDal): void {
        this.snapshots.remove(snapshot)
        this.tagManager.remove(snapshot)
    }

    private resetToApprovedState(): void {
        const pointersToUpdate = _.concat(this.tentativeStore.keys(), this.currentOpenTransaction.store.keys())
        this.tentativeStore = createStore()
        this.createNewOpenTransaction()
        this.updateIndex(pointersToUpdate)
    }

    /** Rebuild the tentative store with conflicting snapshots removed.
     *
     * Conflicting snapshots are snapshots with a broken signature chain to the committed value or snapshots that
     * lead to an invalid store when applied to the approved state.
     */
    private removeConflicts() {
        /* To rebase we reset the dal to the approved state since it's the last "consensus" point from the server,
         * it cannot change.
         * We then re-apply the tentative transactions, which weren't approved by the server yet, one-by-one.
         * If a tentative transaction conflicts with previous values we remove it. There is no need to send it to the
         * server since we already know it's conflicting and will be rejected.
         * We refer to that situation as a "local rejection"
         */
        const openTransaction = this.currentOpenTransaction
        const droppedSnapshots: SnapshotDal[] = []
        const pendingSnapshots = createSnapshotChain(this.lastApproved, this.snapshots.last(), 'removeConflicts')
        this.resetToApprovedState()

        for (const [i, snapshot] of pendingSnapshots.entries()) {
            /* There's no need to apply the snapshot in order to check for signature conflicts.
             * But validators work on the DAL as a whole, so we have to apply the snapshot, validate and then
             * "unapply" it if a validation conflict occurred.
             * To "unapply" we rebuild the tentative store but we only use the snapshots that weren't
             * already locally rejected.
             */
            const snapshotStore = snapshot.getStore()
            if (this.isConflictingWithMergedValue(snapshotStore)) {
                droppedSnapshots.push(snapshot)
                this.removeTentativeSnapshotWithoutUpdatingTheIndex(snapshot)
            } else {
                this.currentOpenTransaction.store.merge(snapshotStore)
                this.updateIndex(snapshotStore.keys())
                if (this.hasRebaseValidationErrors(snapshotStore)) {
                    this.debugLogger.info(
                        `dmDal rebase is removing a snapshot due to validation conflict. Snapshot id: ${snapshot.id}`
                    )
                    droppedSnapshots.push(snapshot)
                    this.removeTentativeSnapshotWithoutUpdatingTheIndex(snapshot)
                    const validPendingSnapshotsUpToThisPoint = _(pendingSnapshots)
                        .take(i)
                        .without(...droppedSnapshots)
                        .value()
                    this.rebuildOpenStore(validPendingSnapshotsUpToThisPoint)
                }
            }
        }
        this.tentativeStore.merge(this.currentOpenTransaction.store)

        this.currentOpenTransaction = openTransaction
        this.updateIndex(openTransaction.store.keys())
    }

    /**
     * Build a new tentative store with all conflicting snapshots removed
     * Aggregate all the changes between the old tentative store and new one, update
     * the query index with these changes and notify listeners of the changes
     * @param {TransactionEvent} event - event type to report
     * @param {DmStore} originalChange - change of data that is not included in the tentative store
     */
    private removeConflictsAndReport(event: TransactionEvent, originalChange?: DmStore) {
        const inconsistentTentativeStore = this.tentativeStore

        // remove invalidated snapshots
        this.removeConflicts()
        const changes: DmStore = this.collectChanges(inconsistentTentativeStore, originalChange)

        // notify listeners
        this.report(changes, event)
    }

    /**
     * apply function on all the snapshots from but not including fromSnapshot, up to and including toSnapshot
     * @param {SnapshotDal} from
     * @param {SnapshotDal} to
     * @param {Function} fn
     */
    private applyOnSnapshotsRange(from: SnapshotDal, to: SnapshotDal, fn: _.ListIterator<SnapshotDal, any>) {
        const snapshotsToApply = createSnapshotChain(from, to, 'applyOnSnapshotsRange')
        _(snapshotsToApply).reverse().forEach(fn)
    }

    private createSnapshotTrace() {
        const snapshotChain = _(createSnapshotChain(null, this.snapshots.last(), 'createSnapshotTrace'))
            .slice(-30)
            .map('id')
            .value()
        return {
            lastApproved: this.lastApproved?.id,
            snapshotChain,
            currentTags: this.tagManager._getAllTags()
        }
    }

    private createCannotApproveError(id: string) {
        const tx = this.snapshots.findById(id)
        const txCompareIds = this.snapshots.findByIdLimitCompareIds(id, this.lastApproved!)
        return new CannotApproveError(tx, id, this.createSnapshotTrace(), txCompareIds)
    }

    private createCannotRebaseError(position: string, id: string | undefined) {
        return new ReportableError({
            errorType: 'cannotRebaseError',
            message: `Cannot rebase, ${position} does not come before pending transaction`,
            extras: {
                position,
                id
            }
        })
    }
    private createRebaseUnlinkedError(position: string, id: string | undefined) {
        return new ReportableError({
            errorType: 'rebaseUnlinkedError',
            message: `Cannot rebase, ${position} is unlinked`,
            extras: {
                position,
                id,
                ...this.createSnapshotTrace()
            }
        })
    }
    private createRemovedApprovedError(position: string, id: string | undefined) {
        return new ReportableError({
            errorType: 'removedApprovedSnapshotError',
            message: 'We just removed the approved snapshot due to a conflict',
            extras: {
                position,
                id,
                ...this.createSnapshotTrace()
            }
        })
    }
    private removeConflictsReportAndVerify(change: DmStore, position: string, event: TransactionEvent, id: string) {
        this.removeConflictsAndReport(event, change)
        if (!this.snapshots.findById(id)) {
            throw this.createRemovedApprovedError(position, id)
        }
    }
    private rebaseApprovedHistory(change: DmStore, position: string, event: TransactionEvent, id?: string) {
        const tx = this.snapshots.findById(position)
        if (!tx) {
            throw this.createRebaseUnlinkedError(position, id)
        }

        if (this.isConflictingWithApprovedValue(change)) {
            throw this.createRemovedApprovedError(position, id)
        }

        this.coreConfig.logger.captureError(this.createCannotRebaseError(position, id))

        const approvedLater: DmStore = _.reduce(
            createSnapshotChain(tx, this.lastApproved, 'rebaseApprovedHistory'),
            (mergedStore: DmStore, snapshotInRange: SnapshotDal) => {
                mergedStore.merge(snapshotInRange.getStore())
                return mergedStore
            },
            createStore()
        )

        const relevantChange: DmStore = createStore()
        change.forEach((pointer, value) => {
            if (!approvedLater.has(pointer)) {
                relevantChange.set(pointer, value)
            }
        })

        this.store.merge(relevantChange)
        const newSnapshot = this.snapshots.insert(change, position, id)
        this.updateIndex(relevantChange.keys())
        this.removeConflictsReportAndVerify(relevantChange, position, event, newSnapshot.id)
        return newSnapshot
    }
    private createMergeToApprovedError(changes: DmStore, id?: string) {
        return new ReportableError({
            errorType: 'cannotMergeToApprovedError',
            message: `MergeToApprovedStore called for ${id} outside initialization flow`,
            extras: {
                id,
                ...this.createSnapshotTrace(),
                content: _.keys(changes.asJson())
            }
        })
    }
    private getMergeToApprovedStoreSnapshotId(changes: DmStore, label?: string): string {
        const id = this.snapshots.generateId(label)
        if (!this.tentativeStore.isEmpty() || !this.currentOpenTransaction.store.isEmpty()) {
            throw this.createMergeToApprovedError(changes, id)
        }

        return id
    }
    private mergeSnapshotToApprovedStore(clonedChanges: DmStore, snapshotId: string): SnapshotDal {
        const newApprovedSnapshot = this.lastApproved
            ? this.snapshots.insert(clonedChanges, this.lastApproved.id, snapshotId)
            : this.snapshots.add(clonedChanges, snapshotId)

        this.lastApproved = newApprovedSnapshot
        this.store.merge(clonedChanges)
        this.updateIndex(clonedChanges.keys())
        return newApprovedSnapshot
    }
    get _snapshots() {
        return this.snapshots
    }

    get _store() {
        return this.store
    }
    get _tentativeStore() {
        return this.tentativeStore
    }

    _getApprovedStoreAsJson() {
        return this.store.asJson()
    }
    _getTentativeStoreAsJson() {
        return this.tentativeStore.asJson()
    }
    _getCommittedStoreAsJson() {
        return this.getMergedStoreAsJson(false)
    }
    _getMergedStoreAsJson() {
        return this.getMergedStoreAsJson(true)
    }

    /** @private */
    get _queryIndex() {
        return this.queryIndex
    }

    /** @private */
    get _currentTransaction() {
        return this.currentOpenTransaction
    }
    _getAllTags() {
        return this.tagManager._getAllTags()
    }
}

export const createDal = ({
    coreConfig,
    postSetOperations,
    dmTypes,
    customGetters,
    eventEmitter,
    initialStore
}: CreateArgs): DAL => {
    return new DocumentManagerDAL(coreConfig, postSetOperations, dmTypes, customGetters, eventEmitter, initialStore)
}
