import type {ConversionDal} from '../../types'
import type {WidthHeuristics} from './types'
import {
    BASE_HORIZONTAL_PADDING,
    CHARACTERS_2,
    CHARACTERS_3,
    CHARACTERS_4,
    CHARACTERS_5,
    FONT_SIZE_EXTRA_LARGE,
    FONT_SIZE_LARGE,
    FONT_SIZE_MEDIUM,
    FONT_SIZE_SMALL,
    MAXIMAL_WIDTH
} from './constants'
import {SemanticTypes} from '../../preprocess/conversionDataBuilders'
import type {Pointer} from '@wix/document-services-types'
import type {SnapshotDal} from '@wix/document-manager-core'
import type {MeasurementsEntryItem, StructureEntryItem, StyleEntryItem} from '../../conversionDal/types'
import {getMeasurementsPointer, getStylePointer} from '../../conversionDal/pointerUtils'

const getLabelWidth = (fontSize: number, label: string) => {
    if (fontSize <= FONT_SIZE_SMALL) {
        if (label.length <= CHARACTERS_2) return 160
        if (label.length <= CHARACTERS_3) return 200
        if (label.length <= CHARACTERS_4) return 240
        if (label.length <= CHARACTERS_5) return 342
        return 342
    } else if (fontSize <= FONT_SIZE_MEDIUM) {
        if (label.length <= CHARACTERS_2) return 200
        if (label.length <= CHARACTERS_3) return 240
        if (label.length <= CHARACTERS_4) return 280
        if (label.length <= CHARACTERS_5) return 342
        return 342
    } else if (fontSize <= FONT_SIZE_LARGE) {
        if (label.length <= CHARACTERS_2) return 240
        if (label.length <= CHARACTERS_5) return 342
        return 342
    } else if (fontSize <= FONT_SIZE_EXTRA_LARGE) {
        return 342
    }

    return 342
}

const getAvailableWidth = (conversionDal: ConversionDal, compPointer: Pointer): number => {
    const currentComp: StructureEntryItem = conversionDal.get(compPointer)
    const parentMeasurementsPointer = getMeasurementsPointer({id: currentComp.parent!})
    const parentCompMeasurements: MeasurementsEntryItem = conversionDal.get(parentMeasurementsPointer)

    return parentCompMeasurements.width - BASE_HORIZONTAL_PADDING * 2
}

export const widthHeuristics: WidthHeuristics = [
    {
        predicate: (type: string) => type === SemanticTypes.SECTION,
        run: () => {
            return {width: MAXIMAL_WIDTH, x: 0}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.BOX,
        run: (conversionDal: ConversionDal, compPointer: Pointer) => ({
            width: getAvailableWidth(conversionDal, compPointer),
            x: BASE_HORIZONTAL_PADDING
        })
    },
    {
        predicate: (type: string) => type === SemanticTypes.IMAGE,
        run: (conversionDal: ConversionDal, compPointer: Pointer, initialSnapshot: SnapshotDal) => {
            const availableWidth = getAvailableWidth(conversionDal, compPointer)
            const originalMeasurements = initialSnapshot.getValue(getMeasurementsPointer(compPointer))
            let calculatedWidth

            if (originalMeasurements.width <= 200) {
                calculatedWidth = originalMeasurements.width
            } else {
                calculatedWidth = MAXIMAL_WIDTH
            }

            const mobileWidth = Math.min(calculatedWidth, availableWidth)

            return {width: mobileWidth, x: BASE_HORIZONTAL_PADDING}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.SVG,
        run: (conversionDal: ConversionDal, compPointer: Pointer, initialSnapshot: SnapshotDal) => {
            const availableWidth = getAvailableWidth(conversionDal, compPointer)
            const originalMeasurements = initialSnapshot.getValue(getMeasurementsPointer(compPointer))
            let calculatedWidth

            if (originalMeasurements.width <= 100) {
                calculatedWidth = originalMeasurements.width
            } else {
                calculatedWidth = originalMeasurements.width * 0.7
            }

            const mobileWidth = Math.min(calculatedWidth, availableWidth)

            return {width: mobileWidth, x: BASE_HORIZONTAL_PADDING}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.TEXT,
        run: (conversionDal: ConversionDal, compPointer: Pointer) => ({
            width: getAvailableWidth(conversionDal, compPointer),
            x: BASE_HORIZONTAL_PADDING
        })
    },
    {
        predicate: (type: string) => type === SemanticTypes.HORIZONTAL_LINE,
        run: (conversionDal: ConversionDal, compPointer: Pointer, initialSnapshot: SnapshotDal) => {
            const availableWidth = getAvailableWidth(conversionDal, compPointer)
            const originalMeasurements = initialSnapshot.getValue(getMeasurementsPointer(compPointer))
            let calculatedWidth

            if (originalMeasurements.width <= 200) {
                calculatedWidth = originalMeasurements.width
            } else {
                calculatedWidth = MAXIMAL_WIDTH
            }

            const mobileWidth = Math.min(calculatedWidth, availableWidth)

            return {width: mobileWidth, x: BASE_HORIZONTAL_PADDING}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.BUTTON,
        run: (conversionDal: ConversionDal, compPointer: Pointer) => {
            const availableWidth = getAvailableWidth(conversionDal, compPointer)
            const currentComp: StructureEntryItem = conversionDal.get(compPointer)
            const {label} = currentComp.data
            const {fontSize}: StyleEntryItem = conversionDal.get(getStylePointer(compPointer))
            const calculatedWidth = getLabelWidth(fontSize!, label)
            const mobileWidth = Math.min(calculatedWidth, availableWidth)

            return {
                width: mobileWidth,
                x: BASE_HORIZONTAL_PADDING
            }
        }
    },
    {
        predicate: () => true,
        run: (conversionDal: ConversionDal, compPointer: Pointer) => ({
            width: getAvailableWidth(conversionDal, compPointer),
            x: BASE_HORIZONTAL_PADDING
        })
    }
]
