import type {Callback1, AppDefinitionId, PS, AppDataToDelete, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import page from '../../page/page'
import component from '../../component/component'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import constants from '../../platform/common/constants'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import appInstallationAndDeletionEvents from './appInstallationAndDeletionEvents'
import uninstall from '../../platform/uninstall'
import tpaNotifyDeleteService from './tpaNotifyDeleteService'
import experiment from 'experiment-amd'

const actualDeleteHiddenSections = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const hiddenPages = installedTpaAppsOnSiteService.getHiddenSections(ps, appDefinitionId)
    _.forEach(hiddenPages, function (hiddenPage) {
        page.remove(ps, hiddenPage.pageId)
    })
}

const getMainWidgetId = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (appData) {
        const widgetData = _.find(appData.widgets, {default: true})
        return _.get(widgetData, 'widgetId')
    }
    return undefined
}

/**
 * @param {ps} ps
 * @param {{appsPagesToDelete?, appDefIdsToDelete?, isPlatformRemoval?, intent?}} p
 * @param appDefinitionId
 * @returns {Promise<*>}
 */
const actualDeleteApps = async function (
    ps: PS,
    {appsPagesToDelete, appDefIdsToDelete}: AppDataToDelete,
    appDefinitionId: AppDefinitionId
) {
    if (!appsPagesToDelete) {
        const dependentAppsIds = _.map(
            installedTpaAppsOnSiteService.getInstalledDependentAppsData(ps, appDefinitionId),
            'appDefinitionId'
        )
        appsPagesToDelete = installedTpaAppsOnSiteService.getPagesByAppDefinitionIds(ps, dependentAppsIds)
        appDefIdsToDelete = dependentAppsIds
    }

    const allAppDefIdsToDelete = [...new Set([...appDefIdsToDelete, appDefinitionId])]

    if (experiment.isOpen('dm_refactorPlatformUninstall')) {
        await uninstall.asyncUninstall(ps, allAppDefIdsToDelete)
    } else {
        const uninstallPromise = new Promise((resolve, reject) =>
            uninstall.uninstall(
                ps,
                [
                    {
                        appsPagesToDelete,
                        appDefIdsToDelete: allAppDefIdsToDelete,
                        intent: constants.Intents.TPA_DELETE_TO_UNINSTALL
                    }
                ],
                [appDefinitionId],
                resolve,
                reject
            )
        )

        const notify = _.partial(uninstall.notifyBeforeApplicationDeleteInternal, ps)
        const promises = allAppDefIdsToDelete.map(notify)
        await Promise.all(promises)
        await uninstallPromise
    }
}

const deleteWidget = async function (
    ps: PS,
    compPointer: CompRef,
    appDefinitionId: AppDefinitionId,
    callback: Callback1<any>
) {
    const installedWidgets = installedTpaAppsOnSiteService.getWidgetsByAppDefId(ps, appDefinitionId)
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (
        tpaNotifyDeleteService.isLastInstalledMainWidget(installedWidgets, ps, appDefinitionId, compPointer) &&
        clientSpecMapService.isAppActive(ps, appData)
    ) {
        actualDeleteHiddenSections(ps, appDefinitionId)
        deleteAppOtherWidgets(ps, appDefinitionId, installedWidgets)
        await actualDeleteApps(ps, {appsPagesToDelete: [], appDefIdsToDelete: []}, appDefinitionId)
    }
    if (ps.dal.isExist(compPointer)) {
        component.deleteComponent(ps, compPointer)
    }
    if (_.isFunction(callback)) {
        // @ts-expect-error
        callback()
    }
}

const deleteAppOtherWidgets = function (ps: PS, appDefinitionId: AppDefinitionId, installedWidgets) {
    const widgetsToDelete = _.reject(installedWidgets, {widgetId: getMainWidgetId(ps, appDefinitionId)})
    _.forEach(widgetsToDelete, function (widget) {
        const compPointer = componentDetectorAPI.getComponentById(ps, widget.id, widget.pageId)
        component.deleteComponent(ps, compPointer)
    })
}

const deleteTpaCompleteCallback = function (ps: PS, appDefinitionIds: AppDefinitionId[]) {
    _.forEach(appDefinitionIds, function (appDefinitionId) {
        if (!installedTpaAppsOnSiteService.isAppDefinitionIdExists(ps, appDefinitionId)) {
            appInstallationAndDeletionEvents.invokeDeleteAppCallbacks(appDefinitionId)
        }
    })
}

export default {
    actualDeleteApps,
    deleteWidget,
    deleteTpaCompleteCallback
}
