import {createLoggerApi as _createLoggerApi} from '@wix/thunderbolt-logger'
import {
    create as createFedopsLogger,
    type IAppIdentifier,
    type IEndInteractionOptions,
    type IEndPhaseOptions,
    type IStartInteractionOptions
} from '@wix/fedops-logger'
import type {QueryUtils, WixBiSession} from '@wix/document-services-types'
import {presetsTypes} from '@wix/fedops-presets'
import _ from 'lodash'
import {shouldSuppressBI} from './bi'
import {createNoopLogger} from './loggerUtils'
import type {PanoramaFactory} from './panoramaLoggerFactory'
import type {PanoramaClient} from '@wix/panorama-client-web'

const maxExtrasPanorama = 15000 //this will allow for the extras to be 50% of the maximum total log size in panorama

// @wix/thunderbolt-logger didn't export the type of the createLoggerApi function, so we have to cast it to any because the types breaks otherwise.
const createLoggerApi = _createLoggerApi as any
const extractVersion = (base: string) => base.split('/').pop()

const getEnvironment = (isRollout: number | boolean | null) => {
    if (_.isNull(isRollout)) {
        return 'Canary'
    }
    return isRollout ? 'Rollout' : 'Production'
}

const combineLoggers = (
    primaryLogger: MinimalLoggerForThunderbolt,
    secondaryLogger: MinimalLoggerForThunderbolt
): MinimalLoggerForThunderbolt => {
    return {
        appLoadStarted: (appIdentifier?: IAppIdentifier): void => {
            primaryLogger.appLoadStarted(appIdentifier)
            secondaryLogger.appLoadStarted(appIdentifier)
        },

        appLoaded: (appIdentifier?: IAppIdentifier): void => {
            primaryLogger.appLoaded(appIdentifier)
            secondaryLogger.appLoaded(appIdentifier)
        },

        registerPlatformTenants: (appNames: string[]): void => {
            primaryLogger.registerPlatformTenants(appNames)
            secondaryLogger.registerPlatformTenants(appNames)
        },

        appLoadingPhaseStart: (name: string, appIdentifier?: IAppIdentifier): void => {
            primaryLogger.appLoadingPhaseStart(name, appIdentifier)
            secondaryLogger.appLoadingPhaseStart(name, appIdentifier)
        },

        appLoadingPhaseFinish: (
            name: string,
            appIdentifier?: IAppIdentifier,
            endPhaseOptions?: Partial<IEndPhaseOptions>
        ): void => {
            const result = primaryLogger.appLoadingPhaseFinish(name, appIdentifier, endPhaseOptions)
            secondaryLogger.appLoadingPhaseFinish(name, appIdentifier, endPhaseOptions)
            return result
        },

        interactionStarted: (
            interactionName: string,
            startInteractionOptions?: Partial<IStartInteractionOptions>
        ): {timeoutId: number} => {
            const result = primaryLogger.interactionStarted(interactionName, startInteractionOptions)
            secondaryLogger.interactionStarted(interactionName, startInteractionOptions)
            return result
        },

        interactionEnded: (interactionName: string, endInteractionOptions?: Partial<IEndInteractionOptions>): void => {
            primaryLogger.interactionEnded(interactionName, endInteractionOptions)
            secondaryLogger.interactionEnded(interactionName, endInteractionOptions)
        }
    }
}

function fedopsToPanoramaAdapter(panoramaClient: PanoramaClient, biContext: WixBiSession): MinimalLoggerForThunderbolt {
    const cleanupExtras = (data: object | undefined): string | undefined => {
        const extraData = _.get(data, ['extras']) || _.get(data, ['paramsOverrides', 'extras'])
        if (!extraData) {
            return
        }

        return JSON.stringify(extraData)?.substring(0, maxExtrasPanorama)
    }
    const createLoggableDataFromOptions = (data: object | undefined) => {
        const dataWithoutExtras = _.omit(data, ['extras', 'paramsOverrides.extras'])
        return {
            ...dataWithoutExtras,
            extras: cleanupExtras(data),
            is_rollout: biContext.is_rollout,
            origin: biContext.origin
        }
    }

    const isError = (data: object | undefined): boolean => {
        return _.get(data, ['evid']) === 36 || _.get(data, ['paramsOverrides', 'evid']) === 36
    }

    const getIssueSeverity = (data: object | undefined): string => {
        return _.get(data, ['paramsOverrides', 'issueSeverity']) || _.get(data, ['issueSeverity']) || 'error'
    }
    const getErrorMessage = (data: object | undefined): string | undefined => {
        return _.get(data, ['paramsOverrides', 'errorInfo']) || _.get(data, ['errorInfo'])
    }

    const logError = (startInteractionOptions?: Partial<IStartInteractionOptions>) => {
        const data = createLoggableDataFromOptions(startInteractionOptions)
        const loggerFunc =
            getIssueSeverity(startInteractionOptions) === 'warning'
                ? panoramaClient.logger().warn
                : panoramaClient.logger().error

        loggerFunc(getErrorMessage(startInteractionOptions) ?? 'unknown error', data)
    }

    return {
        appLoadStarted: (appIdentifier?: IAppIdentifier): void => {
            panoramaClient.reportLoadStart(appIdentifier)
        },

        appLoaded: (appIdentifier?: IAppIdentifier): void => {
            panoramaClient.reportLoadFinish(appIdentifier)
        },

        registerPlatformTenants: (appNames: string[]): void => {
            panoramaClient.logger().info('registerPlatformTenants', {appNames})
        },

        appLoadingPhaseStart: (name: string, appIdentifier?: IAppIdentifier): void => {
            panoramaClient.transaction(name).start(appIdentifier)
        },

        appLoadingPhaseFinish: (
            name: string,
            appIdentifier?: IAppIdentifier,
            endPhaseOptions?: Partial<IEndPhaseOptions>
        ): void => {
            panoramaClient.transaction(name).finish({appIdentifier, endPhaseOptions})
        },

        interactionStarted: (
            interactionName: string,
            startInteractionOptions?: Partial<IStartInteractionOptions>
        ): {timeoutId: number} => {
            if (isError(startInteractionOptions)) {
                logError(startInteractionOptions)
            } else {
                const data = createLoggableDataFromOptions(startInteractionOptions)
                panoramaClient.transaction(interactionName).start(data)
            }
            return {timeoutId: 0}
        },

        interactionEnded: (interactionName: string, endInteractionOptions?: Partial<IEndInteractionOptions>): void => {
            const data = createLoggableDataFromOptions(endInteractionOptions)
            panoramaClient.transaction(interactionName).finish(data)
        }
    }
}

interface MinimalLoggerForThunderbolt {
    appLoadStarted(appIdentifier?: IAppIdentifier): void

    appLoaded(appIdentifier?: IAppIdentifier): void

    registerPlatformTenants(appNames: string[]): void

    appLoadingPhaseStart(name: string, appIdentifier?: IAppIdentifier): void

    appLoadingPhaseFinish(
        name: string,
        appIdentifier?: IAppIdentifier,
        endPhaseOptions?: Partial<IEndPhaseOptions>
    ): void

    interactionStarted(
        interactionName: string,
        startInteractionOptions?: Partial<IStartInteractionOptions>
    ): {timeoutId: number}

    interactionEnded(interactionName: string, endInteractionOptions?: Partial<IEndInteractionOptions>): void
}

export const createThunderboltLogger = (
    dmBase: string,
    biContext: WixBiSession,
    biLoggerFactory: any,
    panoramaFactory: PanoramaFactory,
    queryUtil: QueryUtils
) => {
    if (shouldSuppressBI(queryUtil)) {
        return createNoopLogger()
    }
    const originalFedopsLogger = createFedopsLogger('tb-ds', {
        presetType: presetsTypes.DS,
        isServerSide: false,
        reportBlackbox: true,
        biLoggerFactory,
        phasesConfig: 'SEND_ON_FINISH',
        // @ts-ignore
        paramsOverrides: {is_rollout: biContext.is_rollout}
    })
    const panoramaLogger = fedopsToPanoramaAdapter(panoramaFactory.client(), biContext)
    const fedopsLogger = combineLoggers(originalFedopsLogger, panoramaLogger)

    const sentryStore = {
        release: extractVersion(dmBase),
        environment: getEnvironment(biContext.is_rollout),
        user: biContext.viewerSessionId
    }

    const logger = createLoggerApi({
        fedopsLogger,
        sentry: _.isUndefined(window.Sentry) ? {} : window.Sentry,
        sentryStore,
        errorLimit: 10000
    })

    logger.setGlobalsForErrors({
        tags: {}, //todo: add relevant tags to DS
        extra: {} //todo: add relevant extra data to DS
    })

    return logger
}
