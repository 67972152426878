import fetchFn from 'node-fetch'
import {fetchWithAuth} from '@wix/document-manager-extensions'
import type {RMApi} from '@wix/document-manager-extensions/src/extensions/rendererModel'

export const sendInputToBo = async (boPageId: string, input: any, siteAPI: RMApi) => {
    const endpoint = `/_api/any2wix-website-analyzer-bo-web/api/sites/${boPageId}/enriched/12`
    const instance = siteAPI.siteAPI.getInstance()
    // @ts-ignore
    await fetchWithAuth(endpoint, instance, fetchFn, 'PUT', {data: JSON.stringify(input)})
}

export const getPageBoId = async (url: string, siteAPI: RMApi) => {
    const endpoint = `/_api/any2wix-website-analyzer-bo-web/api/sites/byUrl?projectId=17&url=${url}`
    const instance = siteAPI.siteAPI.getInstance()
    // @ts-ignore
    return await fetchWithAuth(endpoint, instance, fetchFn, 'GET')
}
