import _ from 'lodash'
import type {CompStructure, SerializedCompStructure} from '@wix/document-services-types'
import {SERIALIZED_DATA_KEYS} from '../../constants'
import type {SchemaExtensionAPI} from '../../../..'
import {addIdToMap, getCustomId} from '../utils'
import {DATA_TYPES} from '../../../../constants/constants'
import type {DeserializeComponentParams} from '../types'
import {stripHashIfExists} from '../../../../utils/refArrayUtils'
import {generateUniqueIdByType} from '../../../../utils/dataUtils'

export const updateVariantsArrayStructureByType = (
    deserializationParams: DeserializeComponentParams,
    namespace: string
) => {
    const {createExtArgs, customId, compStructure, mappers, pageId} = deserializationParams
    const {dal, pointers, extensionAPI} = createExtArgs

    const {schemaAPI} = extensionAPI as SchemaExtensionAPI

    const key = SERIALIZED_DATA_KEYS[namespace as keyof typeof SERIALIZED_DATA_KEYS] as keyof CompStructure

    const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}
    const {query} = schemaAPI.getNamespaceConfig(namespace)

    if (!compStructure[key]) {
        return
    }

    const stateCustomId = getCustomId(oldToNewIdMap, compStructure[key], customId)
    const values = _(compStructure[key].values)
        .map(id => {
            const oldId = stripHashIfExists(id)
            return oldToNewIdMap[oldId] && `#${oldToNewIdMap[oldId]}`
        })
        .compact()
        .value()

    if (!values.length) {
        return
    }

    const newId = stateCustomId ?? generateUniqueIdByType(namespace, pageId, dal, pointers)
    const metaData = {...compStructure[key].metaData, pageId}
    const toUpdate = {...compStructure[key], id: newId, values, metaData}

    const pointer = pointers.data.getItem(namespace, newId, pageId)
    dal.set(pointer, toUpdate)

    compStructure[query as keyof SerializedCompStructure] = newId
    addIdToMap(compStructure[key], newId, oldToNewIdMap)
}

export const updateComponentStatesStructure = (deserializationParams: DeserializeComponentParams) =>
    updateVariantsArrayStructureByType(deserializationParams, DATA_TYPES.states)

export const updateComponentTriggersStructure = (deserializationParams: DeserializeComponentParams) =>
    updateVariantsArrayStructureByType(deserializationParams, DATA_TYPES.triggers)
