import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef, ImportExportOptions, RawComponentExport} from '@wix/document-services-types'
import {exportRawComponent} from './raw/export'
import {getRawOptions} from './options'

export const exportComponent = (
    extensionContext: CreateExtArgs,
    rootPointer: CompRef,
    options?: ImportExportOptions
): RawComponentExport => {
    return exportRawComponent(extensionContext, rootPointer, getRawOptions(options))
}
