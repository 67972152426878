import type {Pointer, PS, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import pageData from '../page/pageData'
import popupUtils from '../page/popupUtils'
import theme from '../theme/theme'
import constants from '../constants/constants'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import deprecation from './componentDeprecation'
import dsUtils, {Result} from '../utils/utils'
import * as santaCoreUtils from '@wix/santa-core-utils'
import * as documentManagerUtils from '@wix/document-manager-utils'
import experiment from 'experiment-amd'

const {ReportableError} = documentManagerUtils

const ERRORS = {
    COMPONENT_DOES_NOT_EXIST: 'component param does not exist',
    COMPONENT_DOES_NOT_HAVE_TYPE: 'component does not have type',
    INVALID_COMPONENT_STRUCTURE: 'invalid component structure',
    UNKNOWN_COMPONENT_TYPE: 'unknown component type',
    INVALID_COMPONENT_PROPERTIES: 'invalid component properties',
    INVALID_COMPONENT_DATA: 'invalid component data',
    COMPONENT_MISSING_STYLE_OR_SKIN: 'component missing style or skin',
    INVALID_MOBILE_HINTS: 'invalid mobile hints',
    INVALID_CONTAINER_STRUCTURE: 'invalid container structure',
    INVALID_CONTAINER_POINTER: 'invalid container pointer',
    MAXIMUM_CHILDREN_NUMBER_REACHED: 'maximum number of child components reached',
    CANNOT_ADD_COMPONENT_TO_MOBILE_PATH: 'cannot add component to mobile path',
    CANNOT_DELETE_MASTER_PAGE: 'cannot delete master page',
    SITE_MUST_HAVE_AT_LEAST_ONE_PAGE: 'site must have at least one page',
    CANNOT_DELETE_MOBILE_COMPONENT: 'cannot delete mobile component',
    CUSTOM_ID_MUST_BE_STRING: 'customId must be a string',
    COMPONENT_IS_NOT_CONTAINER: 'component is not a container',
    LAYOUT_PARAM_IS_INVALID: 'layout param is invalid',
    LAYOUT_PARAM_IS_NOT_ALLOWED: 'layout param is not allowed',
    LAYOUT_PARAM_MUST_BE_NUMERIC: 'layout param must be numeric',
    LAYOUT_PARAM_MUST_BE_BOOLEAN: 'layout param must be boolean',
    LAYOUT_PARAM_ROTATATION_INVALID_RANGE: 'rotationInDegrees must be a valid range (0-360)',
    LAYOUT_PARAM_CANNOT_BE_NEGATIVE: 'layout param cannot be a negative value',
    CANNOT_DELETE_HEADER_COMPONENT: 'cannot delete a header component',
    CANNOT_DELETE_FOOTER_COMPONENT: 'cannot delete a footer component',
    CANNOT_DELETE_NON_EXISTING_COMPONENT: 'cannot delete a non existing component',
    CANNOT_DELETE_NON_DISPLAYED_COMPONENT: 'cannot delete a non displayed component',
    SKIN_PARAM_MUST_BE_STRING: 'skin name param must be a string',
    CANNOT_SET_BOTH_SKIN_AND_STYLE: 'skin cannot be set if style already exists',
    STYLE_ID_PARAM_MUST_BE_STRING: 'style id param must be a string',
    STYLE_ID_PARAM_DOES_NOT_EXIST: 'style id param does not exist and cannot be set',
    STYLE_ID_PARAM_ALREADY_EXISTS: 'style id param already exists and cannot be overridden with custom style',
    STYLE_PROPERTIES_PARAM_MUST_BE_OBJECT: 'style properties param must be an object',
    COMPONENT_IS_DEPRECATED: 'cannot add because component was deprecated',
    INVALID_COMPONENT_CONNECTION_ROLE: 'invalid connection role - cannot be *',
    CANNOT_ADD_COMPONENT_WITH_VARIANT: 'cannot add component with variants',
    CANNOT_DELETE_COMPONENT_WITH_VARIANT: 'cannot delete component with variants',
    UNKNOWN_SYSTEM_STYLE: 'Adding unknown system style',
    INVALID_DESKTOP_POINTER: 'invalid desktop pointer',
    SITE_CANNOT_USE_EFFECTS: 'The site does not yet use effects so a component with effects data cannot be added'
}

const ALLOWED_LAYOUT_PARAMS = [
    'x',
    'y',
    'width',
    'height',
    'scale',
    'rotationInDegrees',
    'fixedPosition',
    'docked',
    'aspectRatio'
]

const FORBIDDEN_COMPONENT_TYPE = ['wysiwyg.viewer.components.HoverBox']

/**
 * Perform component validation not including children, should be called recursively for children
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {Object} componentDefinition
 * @param {String} [optionalCustomId]
 * @param {Pointer} [containerPointer]
 * @param {boolean} [isPage]
 * @returns {Object}
 */
function validateComponentToSet(
    ps: PS,
    componentPointer: CompRef,
    componentDefinition,
    optionalCustomId?: string,
    containerPointer?: CompRef,
    isPage?: boolean
): Result {
    return ps.extensionAPI.components.validation.validateComponentToSet(
        componentDefinition,
        optionalCustomId,
        containerPointer,
        isPage
    )
}

function validateComponentToAdd(
    ps: PS,
    componentPointer: CompRef,
    componentDefinition,
    containerPointer?: CompRef,
    optionalIndex?: number
): Result {
    if (experiment.isOpen('dm_useExtensionValidateComponentToAdd')) {
        return ps.extensionAPI.components.validation.validateComponentToAdd(
            componentPointer,
            componentDefinition,
            containerPointer,
            optionalIndex
        )
    }

    if (deprecation.isComponentDeprecated(componentDefinition.componentType)) {
        sendBreadCrumbOnValidationError(ps, deprecation.getDeprecationMessage(componentDefinition.componentType), {
            id: componentDefinition.id,
            componentType: componentDefinition.componentType
        })
        return {success: false, error: deprecation.getDeprecationMessage(componentDefinition.componentType)}
    }
    if (deprecation.shouldWarnForDeprecation(componentDefinition.componentType)) {
        if (ps.runtimeConfig.shouldThrowOnDeprecation) {
            sendBreadCrumbOnValidationError(ps, deprecation.getDeprecationMessage(componentDefinition.componentType), {
                id: componentDefinition.id,
                componentType: componentDefinition.componentType
            })
            return {success: false, error: deprecation.getDeprecationMessage(componentDefinition.componentType)}
        }
        santaCoreUtils.log.warnDeprecation(deprecation.getDeprecationMessage(componentDefinition.componentType))
    }
    if (!isValidContainerDefinition(ps, componentDefinition, containerPointer)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.INVALID_CONTAINER_POINTER, {
            id: componentDefinition.id,
            componentType: componentDefinition.componentType
        })
        return {success: false, error: ERRORS.INVALID_CONTAINER_POINTER}
    }
    if (!isValidIndexOfChild(ps, containerPointer, optionalIndex)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.INVALID_CONTAINER_POINTER, {
            id: componentDefinition.id,
            componentType: componentDefinition.componentType
        })
        return {success: false, error: ERRORS.INVALID_CONTAINER_POINTER}
    }
    if (!canContainMoreChildren(ps, containerPointer)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.MAXIMUM_CHILDREN_NUMBER_REACHED, {
            id: componentDefinition.id,
            componentType: componentDefinition.componentType
        })
        return {success: false, error: ERRORS.MAXIMUM_CHILDREN_NUMBER_REACHED}
    }
    if (ps.pointers.components.isWithVariants(componentPointer)) {
        return {success: false, error: ERRORS.CANNOT_ADD_COMPONENT_WITH_VARIANT}
    }
    if (componentDefinition.effects) {
        if (!ps.extensionAPI.effects.usesNewAnimations()) {
            return {success: false, error: ERRORS.SITE_CANNOT_USE_EFFECTS}
        }
    }

    return {success: true}
}

function canContainMoreChildren(ps: PS, containerPointer: Pointer) {
    return componentsMetaData.public.allowedToContainMoreChildren(ps, containerPointer)
}

function isValidIndexOfChild(ps: PS, containerPointer: Pointer, optionalIndex: number) {
    if (!_.isNumber(optionalIndex)) {
        return true
    }
    const componentPointers = ps.pointers.full.components
    const childrenPointers = componentPointers.getChildren(containerPointer)
    if (!_.isFinite(optionalIndex) || optionalIndex < 0 || optionalIndex > childrenPointers.length) {
        return false
    }
    return true
}

function isContainableByStructure(ps: PS, componentDefinition, containerPointer: CompRef, isPublic: boolean) {
    return isPublic
        ? componentsMetaData.public.isContainableByStructure(ps, componentDefinition, containerPointer)
        : componentsMetaData.isContainableByStructure(ps, componentDefinition, containerPointer)
}

function validateContainer(ps: PS, componentDefinition, containerPointer: CompRef, isPublic?: boolean) {
    if (!containerPointer) {
        throw createValidationError(`containerPointer ${containerPointer} is illegal`, {
            componentType: componentDefinition.componentType
        })
    }

    if (!ps.dal.isExist(containerPointer) && !ps.dal.full.isExist(containerPointer)) {
        throw createValidationError('container does not exist', {
            containerPointer,
            componentType: componentDefinition.componentType
        })
    }

    if (!isContainableByStructure(ps, componentDefinition, containerPointer, isPublic)) {
        throw createValidationError(
            `component ${componentDefinition.componentType} is not containable in ${containerPointer.id}`,
            {
                containerPointer,
                componentType: componentDefinition.componentType
            }
        )
    }
}

function isValidContainerDefinition(ps: PS, componentDefinition, containerPointer: CompRef) {
    try {
        validateContainer(ps, componentDefinition, containerPointer, true)
        return true
    } catch (e) {
        return false
    }
}

function validateSetSkinParams(ps: PS, componentPointer: Pointer, skinName: string): Result {
    if (!_.isString(skinName)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.SKIN_PARAM_MUST_BE_STRING, {skinName})
        return {success: false, error: ERRORS.SKIN_PARAM_MUST_BE_STRING}
    }
    if (getComponentStyleId(ps, componentPointer)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.SKIN_PARAM_MUST_BE_STRING, {
            styleId: getComponentStyleId(ps, componentPointer)
        })
        return {success: false, error: ERRORS.CANNOT_SET_BOTH_SKIN_AND_STYLE}
    }
    //todo Shimi_Liderman 12/14/14 18:58 should add a validation that the skin is compatible with the component when @moranw will add the relevant mapping

    return {success: true}
}

function validateSetStyleIdParams(ps: PS, styleId: string, pageId = constants.MASTER_PAGE_ID): Result {
    if (!_.isString(styleId)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.STYLE_ID_PARAM_MUST_BE_STRING, {styleId})
        return {success: false, error: ERRORS.STYLE_ID_PARAM_MUST_BE_STRING}
    }
    if (!theme.styles.get(ps, styleId, pageId)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.STYLE_ID_PARAM_MUST_BE_STRING, {pageId, styleId})
        return {success: false, error: ERRORS.STYLE_ID_PARAM_DOES_NOT_EXIST}
    }

    return {success: true}
}

function validateExistingComponent(ps: PS, componentPointer: Pointer, isFull = false): Result {
    const exists = isFull ? ps.dal.full.isExist(componentPointer) : ps.dal.isExist(componentPointer)
    if (!exists) {
        sendBreadCrumbOnValidationError(ps, ERRORS.COMPONENT_DOES_NOT_EXIST, {componentPointer})
        return {success: false, error: ERRORS.COMPONENT_DOES_NOT_EXIST}
    }
    return {success: true}
}

function validateComponentCustomStyleParams(ps: PS, optionalSkinName: string, optionalStyleProperties): Result {
    function isUsed(value) {
        return !_.isNil(value)
    }

    if (isUsed(optionalSkinName) && !_.isString(optionalSkinName)) {
        //todo Shimi_Liderman 12/14/14 18:58 should add a validation that the skin is compatible with the component when @moranw will add the relevant mapping
        sendBreadCrumbOnValidationError(ps, ERRORS.SKIN_PARAM_MUST_BE_STRING, {optionalSkinName})
        return {success: false, error: ERRORS.SKIN_PARAM_MUST_BE_STRING}
    }
    if (
        isUsed(optionalStyleProperties) &&
        (!_.isObject(optionalStyleProperties) || _.isArray(optionalStyleProperties))
    ) {
        sendBreadCrumbOnValidationError(ps, ERRORS.STYLE_PROPERTIES_PARAM_MUST_BE_OBJECT, {optionalStyleProperties})
        return {success: false, error: ERRORS.STYLE_PROPERTIES_PARAM_MUST_BE_OBJECT}
    }

    return {success: true}
}

function getComponentStyleId(ps: PS, componentPointer: Pointer) {
    let result = null
    if (ps && componentPointer) {
        const compStylePointer = ps.pointers.getInnerPointer(componentPointer, 'styleId')
        result = ps.dal.get(compStylePointer)
    }
    return result
}

function validateComponentTypeToDelete(ps: PS, componentPointer: Pointer): Result {
    if (isMasterPage(componentPointer)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.CANNOT_DELETE_MASTER_PAGE)
        return {success: false, error: ERRORS.CANNOT_DELETE_MASTER_PAGE}
    }

    const compType = dsUtils.getComponentType(ps, componentPointer)
    const type =
        ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'type')) ||
        ps.dal.full.get(ps.pointers.getInnerPointer(componentPointer, 'type'))

    if (!compType) {
        sendBreadCrumbOnValidationError(ps, ERRORS.COMPONENT_DOES_NOT_HAVE_TYPE, {componentPointer})
        return {success: false, error: ERRORS.COMPONENT_DOES_NOT_HAVE_TYPE}
    }

    if (isPageComponent(type)) {
        if (popupUtils.isPopup(ps, componentPointer.id)) {
            return {success: true}
        } else if (siteHasOnlyOnePage(ps)) {
            sendBreadCrumbOnValidationError(ps, ERRORS.SITE_MUST_HAVE_AT_LEAST_ONE_PAGE, {componentPointer})
            return {success: false, error: ERRORS.SITE_MUST_HAVE_AT_LEAST_ONE_PAGE}
        }
        return {success: true}
    }

    if (isSiteHeaderComponent(componentPointer, compType)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.CANNOT_DELETE_HEADER_COMPONENT, {componentPointer, compType})
        return {success: false, error: ERRORS.CANNOT_DELETE_HEADER_COMPONENT}
    }

    if (isSiteFooterComponent(componentPointer, compType)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.CANNOT_DELETE_FOOTER_COMPONENT, {componentPointer, compType})
        return {success: false, error: ERRORS.CANNOT_DELETE_FOOTER_COMPONENT}
    }

    return {success: true}
}

function validateComponentExistToDelete(ps: PS, componentPointer: Pointer, deletedParentFromFull): Result {
    if (!deletedParentFromFull && !ps.dal.isExist(componentPointer) && !ps.dal.full.isExist(componentPointer)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.CANNOT_DELETE_NON_EXISTING_COMPONENT, {componentPointer})
        return {success: false, error: ERRORS.CANNOT_DELETE_NON_EXISTING_COMPONENT}
    }
    return {success: true}
}

function validateComponentExistOnFull(ps: PS, componentPointer: Pointer): Result {
    if (!ps.dal.full.isExist(componentPointer)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.COMPONENT_DOES_NOT_EXIST, {componentPointer})
        return {success: false, error: ERRORS.COMPONENT_DOES_NOT_EXIST}
    }
    return {success: true}
}

function validateComponentToDelete(ps: PS, componentPointer: Pointer, deletedParentFromFull?) {
    const res = validateComponentTypeToDelete(ps, componentPointer)
    if (!res.success) {
        return res
    }
    return validateComponentExistToDelete(ps, componentPointer, deletedParentFromFull)
}

/**
 * @param {Pointer} componentPointer
 * @returns {Boolean}
 */
function isMasterPage(componentPointer: Pointer) {
    return componentPointer.id === 'masterPage'
}

/**
 * @param {Pointer} compPointer
 * @param {string} compType
 * @returns {Boolean}
 */
function isSiteHeaderComponent(compPointer: Pointer, compType: string) {
    return compPointer.id === constants.COMP_IDS.HEADER && compType === constants.COMP_TYPES.HEADER
}

/**
 * @param {Pointer} compPointer
 * @param {string} componentType
 * @returns {Boolean}
 */
function isSiteFooterComponent(compPointer: Pointer, componentType: string) {
    return compPointer.id === constants.COMP_IDS.FOOTER && componentType === constants.COMP_TYPES.FOOTER
}

/**
 * @param {ps} ps
 * @returns {Boolean}
 */
function siteHasOnlyOnePage(ps: PS) {
    return pageData.getNumberOfPages(ps) === 1
}

function validateDataType(ps, compType: string, compData) {
    const compDefinitions = ps.extensionAPI.schemaAPI.getDefinition(compType)
    // @ts-expect-error
    if (_.isObject(compData) && !compData.type) {
        throw createValidationError('component data is missing a type', {compData})
    }

    const dataType = _.get(compData, 'type', compData || '')
    const dataTypes = compDefinitions.dataTypes || [''] //support no data by default

    if (dataType === 'RepeatedData') {
        validateRepeatedData(ps, compType, compData)
    } else if (!_.includes(dataTypes, dataType)) {
        throw createValidationError(`data type ${dataType} is not allowed for componentType ${compType}`, {compData})
    }
}

function validateRepeatedData(ps, compType: string, compData) {
    validateDataType(ps, compType, compData.original)
    _.forEach(compData.overrides, _.partial(validateDataType, ps, compType))
}

function createValidationError(msg: string, extras?) {
    return new ReportableError({
        errorType: 'componentValidationError',
        message: msg,
        extras
    })
}

/**
 * @param {Object} compType
 * @returns {Boolean}
 */
function isPageComponent(compType: string) {
    return compType === 'Page'
}

function validateLayoutParam(ps: PS, param: string, value: number): Result {
    if (!_.includes(ALLOWED_LAYOUT_PARAMS, param)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.LAYOUT_PARAM_IS_NOT_ALLOWED, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_IS_NOT_ALLOWED}
    }

    if (param === 'fixedPosition' && _.isBoolean(value)) {
        return {success: true}
    }

    // TODO: Add validations for docking
    if (param === 'docked') {
        return {success: true}
    }

    if (!_.isNumber(value)) {
        sendBreadCrumbOnValidationError(ps, ERRORS.LAYOUT_PARAM_MUST_BE_NUMERIC, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_MUST_BE_NUMERIC}
    }

    const nonNegativeParams = _.without(ALLOWED_LAYOUT_PARAMS, 'x', 'y')

    if (_.includes(nonNegativeParams, param) && value < 0) {
        sendBreadCrumbOnValidationError(ps, ERRORS.LAYOUT_PARAM_CANNOT_BE_NEGATIVE, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_CANNOT_BE_NEGATIVE}
    }

    // TODO evgenyb: refactor degrees validation
    if (param === 'rotationInDegrees' && value > 360) {
        sendBreadCrumbOnValidationError(ps, ERRORS.LAYOUT_PARAM_ROTATATION_INVALID_RANGE, {param, value})
        return {success: false, error: ERRORS.LAYOUT_PARAM_ROTATATION_INVALID_RANGE}
    }

    return {success: true}
}

/**
 * @param {PS} ps
 * @param {Object} connectionItem
 * @returns {{success:boolean, error?:string}}
 */
function validateComponentConnection(ps: PS, connectionItem): Result {
    if (connectionItem.role === '*') {
        sendBreadCrumbOnValidationError(ps, ERRORS.INVALID_COMPONENT_CONNECTION_ROLE, {role: connectionItem.role})
        return {success: false, error: ERRORS.INVALID_COMPONENT_CONNECTION_ROLE}
    }
    return {success: true}
}

function sendBreadCrumbOnValidationError(ps: PS, validationError, extras?) {
    ps.extensionAPI.logger.breadcrumb(validationError, {extras})
}

/** @exports documentServices.component.componentValidations */
export default {
    validateComponentToSet,
    validateComponentToAdd,
    validateComponentTypeToDelete,
    validateComponentToDelete,
    validateComponentExistOnFull,
    validateLayoutParam,
    validateSetSkinParams,
    validateSetStyleIdParams,
    validateExistingComponent,
    validateComponentCustomStyleParams,
    validateComponentConnection,
    ALLOWED_LAYOUT_PARAMS,
    ERRORS,
    FORBIDDEN_COMPONENT_TYPE
}
