import type {CreateExtArgs, DeepFunctionMap, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {PageExtensionAPI} from './page'
import type {
    Pointer,
    RoutersDefinition,
    RoutersDefinitionWithId,
    MenuItem,
    PageLink,
    PagesData,
    PossibleViewModes,
    DalValue
} from '@wix/document-services-types'
import _ from 'lodash'
import {guidUtils} from '@wix/santa-core-utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import {asArray} from '@wix/document-manager-utils'
import type {MenuExtensionAPI} from './menu/menu'
import type {DataExtensionAPI} from './data'
import {VIEW_MODES, CUSTOM_MAIN_MENU} from '../constants/constants'
import {patchPageTranslations} from './page/language'
export interface RouterUpdateData {
    prefix?: string
    config?: any
    hide?: boolean
    pages?: Record<string, string>
}

export interface RouterAddData extends RouterUpdateData {
    appDefinitionId: string
}

export interface RoutersExtensionAPI extends ExtensionAPI {
    routers: RoutersAPI
}

export interface RoutersAPI extends DeepFunctionMap {
    getAllRouters(): {[routerId: string]: RoutersDefinition}
    updateRouter(routerPtr: Pointer, updateData: RouterUpdateData): void
    addRouter(routerPtr: Pointer, routerData: RouterAddData): Pointer
    isDynamicPage(pageId: string): boolean
    getRouterByPage(pageId: string): RoutersDefinition | undefined
    removePageFromRoutersConfigMap(pageId: string): void
    getRouterPointer(): Pointer
    getRouterByPointer(routerPtr: Pointer): RoutersDefinitionWithId | undefined
    getRouterIdByPrefix(prefix: string): string | undefined
    removeRouter(routerPtr: Pointer, viewMode?: PossibleViewModes): void
    pages: {
        disconnect(routerPtr: Pointer, pageId: string, viewMode?: PossibleViewModes): void
        remove(routerPtr: Pointer, pageId: string): void
        connect(
            routerPtr: Pointer,
            pageId: string,
            pageRoles: string | string[],
            connectRouterConfig?: ConnectRouterConfig,
            viewMode?: PossibleViewModes
        ): void
        convertPageLinks(pageId: string, routerId: string, innerRoute: string): void
    }
}

export interface ConnectRouterConfig {
    innerRoute?: string
}

const EVENTS = {
    ROUTERS: {
        BEFORE_UPDATE: 'ROUTER_BEFORE_UPDATE',
        AFTER_ADD: 'ROUTER_AFTER_ADD',
        BEFORE_REMOVE: 'ROUTER_BEFORE_REMOVE',
        ON_DISCONNECT: 'ON_DISCONNECT',
        ON_REMOVE_PAGE: 'ON_REMOVE_PAGE'
    }
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI, eventEmitter}: CreateExtArgs): RoutersExtensionAPI => {
        const {page} = extensionAPI as PageExtensionAPI
        const findRouteByPageId = (routerData: RoutersDefinition, pageId: string): string | undefined => {
            const pageRole = _.findKey(routerData.pages, id => id === pageId)
            const {patterns} = JSON.parse(routerData.config)
            return _.findKey(patterns, {pageRole})
        }

        const getIsDynamicPageIndexable = (routerData: RoutersDefinition, route: string) => {
            const config = JSON.parse(routerData.config)
            const robotsMetaTag = _.get(config.patterns[route].seoMetaTags, ['robots'])
            return robotsMetaTag !== 'noindex'
        }

        const safelyGetIsDynamicPageIndexable = (routerData: RoutersDefinition, pageId: string) => {
            try {
                const route = findRouteByPageId(routerData, pageId)
                return getIsDynamicPageIndexable(routerData, route!)
            } catch (error) {
                return true
            }
        }
        const getAllRouterPrefixes = () => {
            const routersPointer = pointers.routers.getRoutersConfigMapPointer()
            return _.map(dal.get(routersPointer), 'prefix')
        }

        const isPrefixExist = (prefix: string) => {
            const allRoutersPrefixes = getAllRouterPrefixes()
            return _.includes(allRoutersPrefixes, prefix)
        }

        const isPageUriSEOExist = (prefix: string) => {
            const allPageIds = page.getAllPagesIds(true)
            const allPageUriSEO = _.map(allPageIds, function (pageId) {
                return page.getPageUriSEO(pageId)
            })
            return _.includes(allPageUriSEO, prefix)
        }

        const validateUpdatedRouter = (updateRouterData: RouterUpdateData) => {
            if (isPrefixExist(updateRouterData.prefix!)) {
                throw new Error(`Router not valid - Prefix: ${updateRouterData.prefix}, already exist`)
            }

            if (isPageUriSEOExist(updateRouterData.prefix!)) {
                throw new Error(`Router not valid - Page Uri SEO: ${updateRouterData.prefix}, already exist.`)
            }
        }

        const validateNewRouter = (router: RouterAddData) => {
            if (!router.prefix) {
                throw new Error('Router not valid - Missing prefix.')
            }

            if (!router.appDefinitionId) {
                throw new Error('Router not valid - Missing appDefinitionId.')
            }
            if (!_.isNil(router.hide) && !_.isBoolean(router.hide)) {
                throw new Error('Router not valid - hide parameter need to be boolean')
            }
            // @ts-ignore
            if (router.pages) {
                throw new Error('Router not valid - pages should not be on the router object')
            }
            validateUpdatedRouter(router)
        }

        const updateRouter = (routerPtr: Pointer, updateData: RouterUpdateData) => {
            const routerData = deepClone(dal.get(routerPtr))
            if (updateData.prefix && updateData.prefix !== routerData.prefix) {
                validateUpdatedRouter(updateData)
                routerData.prefix = updateData.prefix
            }

            if (updateData.config) {
                if (_.isObject(updateData.config)) {
                    routerData.config = JSON.stringify(updateData.config)
                } else {
                    routerData.config = updateData.config
                }
            }
            if (_.isBoolean(updateData.hide) && updateData.hide !== routerData.hide) {
                routerData.hide = updateData.hide
            }
            if (updateData.pages && !_.isEqual(updateData.pages, routerData.pages)) {
                routerData.pages = updateData.pages
            }

            eventEmitter.emit(EVENTS.ROUTERS.BEFORE_UPDATE, routerPtr, routerData)
            dal.set(routerPtr, routerData)
        }

        const addRouter = (routerPtr: Pointer, newRouter: RouterAddData) => {
            const routerToAdd = _.clone(newRouter)
            //init config field to be an object is undefined
            if (routerToAdd.config) {
                if (_.isObject(routerToAdd.config)) {
                    routerToAdd.config = JSON.stringify(routerToAdd.config)
                }
            } else {
                routerToAdd.config = JSON.stringify({})
            }
            validateNewRouter(routerToAdd)
            dal.set(routerPtr, routerToAdd)
            eventEmitter.emit(EVENTS.ROUTERS.AFTER_ADD, routerPtr)
            return routerPtr
        }

        const getAllRouters = () => {
            const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
            return dal.get(routersConfigMapPointer)
        }
        const isDynamicPage = (pageId: string) => {
            const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
            const routers = dal.get(routersConfigMapPointer)
            return _.some(routers, routerData => _.includes(_.values(routerData.pages), pageId))
        }
        const getRouterPointerByPage = (pageId: string) => {
            const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
            const allRouters = dal.get(routersConfigMapPointer)
            const routerId = _.findKey(allRouters, function (router) {
                return router.pages && _.includes(_.values(router.pages), pageId)
            })
            return routerId && pointers.routers.getRouterPointer(routerId)
        }

        const getRouterByPage = (pageId: string) => {
            const routerPointer = getRouterPointerByPage(pageId)
            return routerPointer && dal.get(routerPointer)
        }
        const removePageFromRoutersConfigMap = (pageId: string): void => {
            const configMapPointer = pointers.routers.getRoutersConfigMapPointer()
            type ConfigMap = Record<string, {pages: Record<string, string>}>
            dal.modify<ConfigMap>(configMapPointer, map =>
                _.mapValues(map, router => ({
                    ...router,
                    pages: _.omitBy(router.pages, value => value === pageId)
                }))
            )
        }
        const generateRouterId = (allRouters: Record<string, RoutersDefinition>) => {
            let routerId = guidUtils.getUniqueId('routers', '-')

            while (_(allRouters).keys().includes(routerId)) {
                routerId = guidUtils.getUniqueId('routers', '-')
            }
            return routerId
        }
        const initRoutersConfigMapIfNeeded = () => {
            const allRouters = getAllRouters()

            if (_.isEmpty(allRouters)) {
                const routersPointer = pointers.routers.getRoutersPointer()
                dal.set(routersPointer, {
                    configMap: {}
                })
            }
        }
        const getRouterPointer = () => {
            const allRouters = getAllRouters()
            const routerId = generateRouterId(allRouters)
            initRoutersConfigMapIfNeeded()
            return pointers.routers.getRouterPointer(routerId)
        }

        const getRouterIdByPrefix = (routerPrefix: string) => {
            const allRouters = getAllRouters()
            return _.findKey(allRouters, {prefix: routerPrefix})
        }

        const getRouterByPointer = (routerPtr: Pointer) => {
            const routerData = deepClone(dal.get(routerPtr))
            if (routerData?.config) {
                routerData.config = JSON.parse(routerData.config)
                routerData.id = getRouterIdByPrefix(routerData.prefix)
            }
            return routerData || null
        }

        const removeRouter = (routerPointer: Pointer, viewMode: PossibleViewModes = VIEW_MODES.DESKTOP) => {
            const allRouters = deepClone(getAllRouters())

            const routerData = getRouterByPointer(routerPointer)
            const routerId = _.findKey(allRouters, {prefix: routerData.prefix})
            if (!routerId) {
                throw new Error('Router not exist')
            }
            eventEmitter.emit(EVENTS.ROUTERS.BEFORE_REMOVE, routerPointer)

            _.forEach(routerData.pages, function (pageId) {
                disconnectPageFromRouter(routerPointer, pageId, viewMode)
            })

            delete allRouters[routerId!]
            const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
            dal.set(routersConfigMapPointer, allRouters)
        }

        const getPageRoles = (routerPages: Record<string, string>, pageId: string) => {
            const pageRoles = _.invertBy(routerPages)
            return pageRoles[pageId]
        }

        const removePageFromRouter = (routerPtr: Pointer, pageId: string) => {
            const routerData = deepClone(dal.get(routerPtr))
            const pageRoles = getPageRoles(routerData.pages, pageId)

            if (pageRoles) {
                routerData.pages = _.omit(routerData.pages, pageRoles)
                dal.set(routerPtr, routerData)
                eventEmitter.emit(EVENTS.ROUTERS.ON_REMOVE_PAGE, routerPtr, pageRoles)
            }
        }

        function disconnectPageFromRouter(
            routerPtr: Pointer,
            pageId: string,
            viewMode: PossibleViewModes = VIEW_MODES.DESKTOP
        ) {
            const routerData = dal.get(routerPtr)
            const isPageBelongsRouter = routerData.pages && _.includes(_.values(routerData.pages), pageId)
            if (!isPageBelongsRouter) {
                throw new Error('the page is not connected to this router')
            }
            removePageFromRouter(routerPtr, pageId)
            const title = page.getPageTitle(pageId)
            const pageUriSEO = page.getValidPageUriSEO(pageId, title)
            eventEmitter.emit(EVENTS.ROUTERS.ON_DISCONNECT, routerPtr, pageId)
            const indexable = safelyGetIsDynamicPageIndexable(routerData, pageId)
            const pagePointer = pointers.structure.getPage(pageId, viewMode)
            page.data.update(
                pagePointer,
                {
                    pageUriSEO,
                    title,
                    hidePage: false,
                    indexable
                },
                true
            )
        }

        const generateRouterPages = (pageRoles: string[], pageId: string) => {
            return _.transform(
                pageRoles,
                (acc, role) => {
                    acc[role] = pageId
                },
                {}
            )
        }

        const getSubItems = (menuItems: MenuItem[], pageId: string): MenuItem[] => {
            let subItems: MenuItem[] = []
            _.forEach(menuItems, menuItem => {
                const menuItemPageId: string = _.get(menuItem, ['link', 'pageId'])
                if (menuItemPageId && menuItemPageId.slice(1) === pageId) {
                    subItems = menuItem.items
                }
            })
            return subItems
        }
        const getMenuItemIfSubPage = (menuItems: MenuItem[], pageId: string) => {
            let res: {
                isSubPage: boolean
                menuItemId?: string
            } = {isSubPage: false}
            _.forEach(menuItems, (menuItem: MenuItem) => {
                const subItems = menuItem.items || []
                _.forEach(subItems, subItem => {
                    const subItemPageId = (subItem.link as PageLink)?.pageId?.slice(1) ?? ''
                    const isPageLink = (subItem.link as PageLink)?.type === 'PageLink'
                    if (subItemPageId === pageId && isPageLink) {
                        res = {
                            isSubPage: true,
                            menuItemId: subItem.id
                        }
                    }
                })
            })
            return res
        }
        const makeSubPageMainPage = (pageId: string) => {
            const {menus} = extensionAPI as MenuExtensionAPI
            const menuItems = menus.getById(CUSTOM_MAIN_MENU)
            const subPage = getMenuItemIfSubPage(menuItems.items, pageId)
            if (subPage.isSubPage) {
                menus.moveItem(CUSTOM_MAIN_MENU, subPage.menuItemId!, null, menuItems.items.length)
            }
        }

        const removeSubPageForPage = (pageId: string) => {
            const {menus} = extensionAPI as MenuExtensionAPI
            const menuItems = menus.getById(CUSTOM_MAIN_MENU)
            const subPages = getSubItems(menuItems.items, pageId)
            _.forEach(subPages, (subPage, index) => {
                menus.moveItem(CUSTOM_MAIN_MENU, subPage.id!, null, menuItems.items.length + index)
            })
        }

        const convertPageLinks = (pageId: string, routerId: string, innerRoute: string) => {
            ;['data', 'multilingualTranslations'].forEach(namespace => {
                const pageLinks = (extensionAPI as DataExtensionAPI).data.query(
                    namespace,
                    null,
                    _.matches({type: 'PageLink', pageId: `#${pageId}`})
                )
                _.forEach(pageLinks, (pageLink, id) => {
                    const newLink = {
                        ...deepClone(pageLink),
                        type: 'DynamicPageLink',
                        routerId,
                        innerRoute
                    }
                    dal.set({type: namespace, id}, newLink)
                })
            })
        }

        const connectPageToRouter = (
            routerPtr: Pointer,
            pageIdToConnect: string,
            pageRoles: string | string[],
            connectRouterConfig: ConnectRouterConfig = {},
            viewMode: PossibleViewModes = VIEW_MODES.DESKTOP
        ) => {
            pageRoles = asArray(pageRoles)
            //hide the dynamic page from the menu and site map
            const mobileHidePage = page.getMobileHidePage(pageIdToConnect)
            const updatedData = {
                hidePage: true,
                indexable: false,
                pageTitleSEO: undefined,
                advancedSeoData: undefined,
                descriptionSEO: undefined,
                metaKeywordsSEO: undefined,
                ogImageRef: undefined
            } as Partial<PagesData>
            /* seeting just if boolean since if undefined that when the page is back to static it will keep original definition*/
            if (_.isBoolean(mobileHidePage)) {
                _.assign(updatedData, {mobileHidePage: true})
            }
            const pagePointer = pointers.structure.getPage(pageIdToConnect, viewMode)
            page.data.update(pagePointer, updatedData, true)
            patchPageTranslations({dal, pointers} as CreateExtArgs, pageIdToConnect, (translationDataItem: DalValue) =>
                _.assign(translationDataItem, updatedData)
            )

            const routerData = deepClone(dal.get(routerPtr))
            const routerInUse = getRouterPointerByPage(pageIdToConnect)
            if (routerInUse && !pointers.isSamePointer(routerPtr, routerInUse)) {
                throw new Error('page already exist on another router')
            } else if (page.getMainPageId() === pageIdToConnect) {
                throw new Error("home page can't become dynamic page")
            }

            if (routerInUse) {
                routerData.pages = _(routerData.pages)
                    .omitBy(pageId => pageId === pageIdToConnect)
                    .assign(generateRouterPages(pageRoles, pageIdToConnect))
                    .value()
            } else {
                //todo - if pages is empty obj by default so use assign instead of merge
                routerData.pages = _.merge(
                    routerData.pages || {},
                    _.omitBy(generateRouterPages(pageRoles, pageIdToConnect), _.isUndefined)
                )
            }
            makeSubPageMainPage(pageIdToConnect)
            removeSubPageForPage(pageIdToConnect)
            const routerId = getRouterIdByPrefix(routerData.prefix)
            if (routerId && connectRouterConfig.innerRoute) {
                convertPageLinks(pageIdToConnect, routerId, connectRouterConfig.innerRoute)
            }
            dal.set(routerPtr, routerData)
        }

        return {
            routers: {
                addRouter,
                updateRouter,
                getAllRouters,
                isDynamicPage,
                getRouterByPage,
                removePageFromRoutersConfigMap,
                getRouterPointer,
                removeRouter,
                getRouterByPointer,
                getRouterIdByPrefix,
                pages: {
                    disconnect: disconnectPageFromRouter,
                    remove: removePageFromRouter,
                    connect: connectPageToRouter,
                    convertPageLinks
                }
            }
        }
    }

    return {
        name: 'routers',
        dependencies: new Set(['rendererModel', 'page']),
        createExtensionAPI,
        EVENTS
    }
}

export {createExtension}
