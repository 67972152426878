import type {DocumentManager} from '@wix/document-manager-core'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {Pointer} from '@wix/document-services-types'
import * as constants from '../../../../constants/constants'
import type {RelationshipsAPI} from '../../../relationships'
import type {PageMigrator} from '../dataMigrationRunner'

const {VARIANTS} = constants
const {MOBILE} = VARIANTS.VARIANT_TYPES
const {MOBILE_VARIANT_ID} = VARIANTS

const getMobileVariants = (documentManager: DocumentManager, pageId: string) => {
    return documentManager.pointers.data.getVariantItemsWithPredicate(
        (variantItem: any) => variantItem.type === MOBILE && variantItem.id !== MOBILE_VARIANT_ID,
        pageId
    )
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const pageMobileVariants = getMobileVariants(documentManager, pageId)

    if (pageMobileVariants.length) {
        const {relationships} = documentManager.extensionAPI as RelationshipsAPI

        pageMobileVariants.forEach((mobileVariant: Pointer) => {
            const relations = relationships.getReferencesToPointer(mobileVariant)
            relations.forEach(relationPointer => {
                const relationData = deepClone(documentManager.dal.get(relationPointer))
                relationData.variants = relationData.variants.map((variantId: string) => {
                    return variantId === `#${mobileVariant.id}` ? `#${MOBILE_VARIANT_ID}` : variantId
                })
                documentManager.dal.set(relationPointer, relationData)
            })
            documentManager.dal.set(mobileVariant, undefined)
        })
    }
}

const name = 'migrateMobileVariant'
const version = 2

export const migrateMobileVariant: PageMigrator = {
    migratePage,
    name,
    version
}
