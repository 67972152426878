import type {Pointer} from '@wix/document-services-types'
import {METADATA_TYPES} from '../../constants/constants'
import {createHookDefinition} from '../../utils/hooksUtils'
import type {HookEventData} from '../hooks/hooks'

export interface MetadataHookParams {
    additionalArguments: any[]
    componentPointer?: Pointer
}

export interface MetadataHookEventData extends MetadataHookParams, HookEventData<'metadata_max_children_number'> {}

export const METADATA_HOOKS = {
    [METADATA_TYPES.MAXIMUM_CHILDREN_NUMBER]: createHookDefinition<'metadata_max_children_number', MetadataHookParams>(
        'metadata_max_children_number'
    ),
    [METADATA_TYPES.CONTAINABLE_BY_STRUCTURE]: createHookDefinition<
        'metadata_containable_by_structure',
        MetadataHookParams
    >('metadata_containable_by_structure')
}
