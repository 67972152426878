const createPropertiesSource = require('./createPropertiesSource')
const {extractComponentStyleItems} = require('./extractComponentStyleItems')
const getAllComponentsOfType = require('./getAllComponentsOfType')
const initiateStyleObject = require('./initiateStyleObject')
const mergeStylesByBreakpoints = require('./mergeStylesByBreakpoints')
const migrateProperties = require('./migrateProperties')
const {migrateComponentSkin, migrateStyleItemsSkin} = require('./migrateSkin')
const {parseStylableStyleItems} = require('./parseStylableStyleItems')
const {migratedStyleToStyleProperties} = require('./migratedStyleToStyleProperties')
const updateStyleItem = require('./updateStyleItem')
const updateOldComponentStructure = require('./updateOldComponentStructure')

module.exports = {
    createPropertiesSource,
    extractComponentStyleItems,
    getAllComponentsOfType,
    initiateStyleObject,
    parseStylableStyleItems,
    migrateProperties,
    migrateComponentSkin,
    migrateStyleItemsSkin,
    mergeStylesByBreakpoints,
    migratedStyleToStyleProperties,
    updateStyleItem,
    updateOldComponentStructure
}

/**
 * @typedef {{
 *  id: string,
 * styleId: string,
 *  skin: string
 * }} ComponentStructure
 *
 * @typedef {{
 * id: string,
 * skin: string,
 * componentClassName: string,
 * style:
 *  {
 *      properties:
 *          {
 *              '$st-css': string
 *          }
 *  }}} StyleItemWithStylable
 *
 * @typedef {{
 * fontStyle?: string,
 * fontWeight?: string,
 * fontVariant?: string,
 * fontSize?: string,
 * lineHeight?: string,
 * fontFamily?: string
 * }} FontPropertiesOverride
 *
 * @typedef {{
 * id: string,
 * skin: string,
 * componentClassName: string,
 * styleType: string,
 * type: string,
 * style:
 *  {
 *              properties: Record<string, string>,
 *              propertiesSource: Record<string, string>,
 *              propertiesOverride: FontPropertiesOverride
 *              groups: any
 *  }}} StyleItemWithStyleProperties
 *
 * @typedef {{
 * metadata: {
 *    min: number,
 *    max: number,
 *    type: 'default' | 'breakpoint',
 *    isMobileOnly: boolean
 * }
 * } & StyleItemWithStylable } StyleItemWithMetadata
 *
 * @typedef {{ prop: string, cssProp: string, value: string, valueRaw?: FontObjectParts, override?: FontPropertiesOverride, absolutePropName?: string}} Property
 *
 * @typedef {{
 *  parsedStyle:
 *  {
 *      [selector: string]: {
 *          properties:Array<Property>,
 *          mobileProperties:Array<Property>,
 *          propertiesOverride: FontPropertiesOverride,
 *          mobilePropertiesOverride: FontPropertiesOverride,
 *          propertiesSource?:{
 *             [prop: string]: 'theme' | 'value' | string
 *          }
 *          metadata: {
 *              root: string,
 *              type: 'root' | 'state' | string,
 *          }
 *          removePrefix?: boolean
 *         }
 *      }
 *   } & StyleItemWithMetadata
 *  } ParsedStyleItem
 *
 * @typedef {{
 *      properties: Record<string, string>,
 *      mobileProperties: Record<string, string>,
 *      propertiesOverride: FontPropertiesOverride,
 *      mobilePropertiesOverride: FontPropertiesOverride,
 *      propertiesSource: Record<string, string>,
 * } & ParsedStyleItem} FlattenedMigratedStyleItem
 *
 *  * @typedef {{
 * [selector: string]: {
 *  name: string,
 *  root?: string,
 *  properties?: {
 *      [prop: string]: string
 *  }
 * removePrefix?: boolean
 * }
 * }} SelectorsMapping
 *
 * * @typedef {{
 * fontStyle: string
 * fontVariant: string
 * fontWeight: string
 * fontSize: string
 * fontSizeUnit?: string
 * lineHeight: string
 * fontFamily: string
 * }} FontObject
 *
 * * @typedef {{
 * fontStyle?: string
 * fontVariant?: string
 * fontWeight?: string
 * fontSize?: string
 * fontSizeUnit?: string
 * lineHeight?: string
 * fontFamily?: string
 * }} FontObjectParts
 *
 * @exports ParsedStyleItem
 * @exports StyleItemWithStylable
 * @exports StyleItemWithStyleProperties
 * @exports StyleItemWithMetadata
 * @exports ComponentStructure
 * @exports SelectorsMapping
 * @exports Property
 * @exports FlattenedMigratedStyleItem
 * @exports FontObject
 * @exports FontObjectParts
 * @exports FontPropertiesOverride
 */
