import {pointerUtils, SnapshotDal} from '@wix/document-manager-core'
import * as documentServicesJsonSchemas from '@wix/document-services-json-schemas'
import type {Pointer} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'

const {
    namespaceMapping: {NAMESPACE_MAPPING}
} = documentServicesJsonSchemas
const {getPointer} = pointerUtils

const getPointerWithInnerPath = (type: string, id: string, innerPath?: string | string[]) => {
    if (typeof innerPath === 'string') {
        innerPath = [innerPath]
    }

    return getPointer(id, type, {innerPath})
}

class SnapshotDalDataProvider {
    private snapshotDal: SnapshotDal

    constructor(snapshotDal: SnapshotDal) {
        this.snapshotDal = snapshotDal
    }

    toJS() {
        return this.snapshotDal.toJS()
    }

    toJSForType(type) {
        return this.snapshotDal.toJSForType(type)
    }

    exists(pointer: Pointer) {
        return this.snapshotDal.exists(pointer)
    }

    getValue(pointer: Pointer) {
        return this.snapshotDal.getValue(pointer)
    }

    diff(from) {
        return this.snapshotDal.diff(from.snapshotDal)
    }

    getAndCloneValue(pointer: Pointer) {
        return deepClone(this.snapshotDal.getValue(pointer))
    }

    rendererModel(id, innerPath) {
        return this.getAndCloneValue(getPointerWithInnerPath('rendererModel', id, innerPath))
    }

    documentServicesModel(id, innerPath) {
        return this.getAndCloneValue(getPointerWithInnerPath('documentServicesModel', id, innerPath))
    }

    serviceTopology(key) {
        return this.getAndCloneValue(getPointerWithInnerPath('serviceTopology', 'serviceTopology', key))
    }

    pagesPlatformApplications(appId) {
        return this.getAndCloneValue(
            getPointerWithInnerPath('pagesPlatformApplications', 'pagesPlatformApplications', appId)
        )
    }

    wixCodeNonUndoable(innerPath) {
        return this.getAndCloneValue(getPointerWithInnerPath('wixCode', 'wixCode', innerPath))
    }

    wixCodeUndoable(innerPath) {
        return this.getAndCloneValue(getPointerWithInnerPath('wixCodeUndoable', 'wixCode', innerPath))
    }
}

const savePaths = [
    {path: ['multilingualTranslations']},
    {path: ['DESKTOP']},
    {path: ['MOBILE']},
    {path: ['orphanPermanentDataNodes']},
    {path: ['routers']},

    {path: ['pagesPlatformApplications', 'pagesPlatformApplications']},
    {path: ['semanticAppVersions', 'semanticAppVersions']},

    {path: ['rendererModel'], id: 'siteMetaData'},
    {path: ['rendererModel'], id: 'pageToHashedPassword'},
    {path: ['rendererModel'], id: 'siteInfo'},
    {path: ['rendererModel'], id: 'metaSiteId'},
    {path: ['rendererModel'], id: 'premiumFeatures'},
    {path: ['rendererModel'], id: 'clientSpecMap'},
    {path: ['rendererModel'], id: 'wixCodeModel'},
    {path: ['rendererModel'], id: 'routers'},

    {path: ['documentServicesModel'], id: 'siteName'},
    {path: ['documentServicesModel'], id: 'editorSessionId'},
    {path: ['documentServicesModel'], id: 'customHeadTags'},
    {path: ['documentServicesModel'], id: 'metaSiteData'}
].concat(Object.keys(NAMESPACE_MAPPING).map(namespace => ({path: [namespace]})))

const shouldSaveDiff = (diff, additionalPaths = []) => {
    for (const {path, id} of _.concat(savePaths, additionalPaths)) {
        const value = _.get(diff, path)
        if (typeof value === 'object' && value !== null) {
            if (id) {
                if (value.hasOwnProperty(id)) {
                    return true
                }
            } else if (!_.isEmpty(value)) {
                return true
            }
        }
    }
    return false
}

const revisionPointer = {type: 'documentServicesModel', id: 'versionInfo', innerPath: ['revision']}
const versionPointer = {type: 'documentServicesModel', id: 'versionInfo', innerPath: ['version']}

const santaVersionPath = ['documentServicesModel', 'version']
const santaRevisionPath = ['documentServicesModel', 'revision']

const versionPath = ['documentServicesModel', 'versionInfo', 'version']
const revisionPath = ['documentServicesModel', 'versionInfo', 'revision']

const lastTransactionIdPath = ['documentServicesModel', 'autoSaveInfo', 'lastTransactionId']

export {
    santaVersionPath,
    santaRevisionPath,
    versionPath,
    revisionPath,
    revisionPointer,
    versionPointer,
    lastTransactionIdPath,
    shouldSaveDiff,
    SnapshotDalDataProvider
}
