import {
    type DmApis,
    type Extension,
    type ResourceLoaderDefinition,
    type DmStore,
    type ModelsInitializationType,
    type CreateExtensionArgument,
    store as dmStore
} from '@wix/document-manager-core'
import type {Pointer, RMPage, RMTopology} from '@wix/document-services-types'
import _ from 'lodash'
import {addPageJson} from '../utils/addPageJson'
import type {CSaveApi} from './csave/continuousSave'
const {createStore} = dmStore

const LOADED = 'loaded'
const FAILED = 'failed'
type CompletedRequestStatus = 'loaded' | 'failed'
const createExtension = ({environmentContext}: CreateExtensionArgument): Extension => {
    const pageLoadDefinitions: Record<string, RMPage> = {}
    let pageTopology: RMTopology[]
    const inProgress: Record<string, Promise<any> | void> = {}
    const completedStatus: Record<string, CompletedRequestStatus> = {}

    const fetchPages = async (pageIds: string[]) => {
        const pageList = {
            pages: _.map(pageIds, pageId => pageLoadDefinitions[pageId]),
            topology: pageTopology
        }
        const resolvedPagesPromise = environmentContext.fetchPagesFacade!.fetchPages(
            environmentContext.fetchFn,
            pageList,
            100
        )

        pageIds.forEach(pageId => {
            inProgress[pageId] = resolvedPagesPromise
        })

        let result
        let resultStatus: CompletedRequestStatus
        try {
            result = await resolvedPagesPromise
            resultStatus = LOADED
        } catch (e) {
            resultStatus = FAILED
            throw e
        } finally {
            pageIds.forEach(pageId => {
                completedStatus[pageId] = resultStatus
                delete inProgress[pageId]
            })
        }

        return result
    }

    const setPagesToDal = async (dmAPIs: DmApis, pages: Record<string, any>) => {
        const changes = createStore()
        const addFunc = (pointer: Pointer, value: any) => {
            changes.set(pointer, value)
        }
        _.forOwn(pages, (pageJson: any, pageId: string) => {
            addPageJson(pageId, pageJson, addFunc)
        })
        dmAPIs.dal.mergeToApprovedStore(changes, 'add-initial-pages')
        const {continuousSave} = dmAPIs.extensionAPI as CSaveApi

        await continuousSave.initCSave(Object.keys(pages))
    }

    const load = async (dmAPIs: DmApis, pageId: string) => {
        //TODO: load / fetchPage should accept an array of pageIds (resourceIds)
        const pagesToLoad = [pageId]

        if (inProgress[pageId]) {
            return inProgress[pageId]
        }
        if (!pageLoadDefinitions[pageId]) {
            throw new Error(`pageResourceLoader: no page with id {${pageId}} available to load`)
        }

        if (completedStatus[pageId] === LOADED) {
            return
        }

        if (completedStatus[pageId] === FAILED) {
            throw new Error(`pageResourceLoader: {${pageId}} already failed to load`)
        }

        if (!completedStatus.masterPage && !inProgress.masterPage) {
            //TODO: support *optional* fetching of masterPage
            pagesToLoad.push('masterPage')
        }
        const result = await fetchPages(pagesToLoad)

        await setPagesToDal(dmAPIs, result)
    }

    const createResourceLoader = (dmAPIs: DmApis): ResourceLoaderDefinition[] => {
        const getAvailableResources = () => Object.keys(pageLoadDefinitions)

        return [
            {
                resourceType: 'PAGE',
                load: (pageId: string) => load(dmAPIs, pageId),
                getAvailableResources
            }
        ]
    }

    const initializeModels = (initialStore: DmStore, initialModels: ModelsInitializationType) => {
        const {pageList} = initialModels.rendererModel
        const {masterPageJsonFileName, /*mainPageId,*/ pages, topology} = pageList
        pageTopology = topology
        pages.forEach(({pageId, pageJsonFileName, pageUriSEO, title}: any) => {
            pageLoadDefinitions[pageId] = {pageId, pageJsonFileName, title, pageUriSEO}
        })
        pageLoadDefinitions.masterPage = {
            title: 'masterPage',
            pageId: 'masterPage',
            pageJsonFileName: masterPageJsonFileName
        }
    }
    return {
        name: 'pageResourceLoader',
        createResourceLoader,
        initializeModels
    }
}

export {createExtension}
