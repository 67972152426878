import fullFunctionalityConfig from './fullFunctionalityConfig'
import _ from 'lodash'

// componentsLoaderPublicAPI creates major issues when initializing integration tests with this config
// we probably need to mock editor-elements-registry for this configuration
// they are missing fetch + they assume window/document exists and try to execute querySelector
const OMIT_FROM_TEST_CONFIG = ['componentsLoaderPublicAPI']
const modulesList = _.without(fullFunctionalityConfig.modulesList, ...OMIT_FROM_TEST_CONFIG)

export default {
    ...fullFunctionalityConfig,
    minNumOfPagesToCalculateRenderHints: 0,
    initialSeed: 1000,
    keepMultiLingualModelsFromServer: true,
    modulesList
}
