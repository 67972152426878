import type {Pointer} from '@wix/document-services-types'
import {createHookDefinition} from '../../utils/hooksUtils'
import type {HookEventData} from '../hooks/hooks'
import type {FeatureData} from '../features/features'

export interface GetItemQueryIdData {
    compPointer: Pointer
    propName: string
    compId: string
}
export interface FeatureDataUpdate {
    compPointer: Pointer
    featureName: string
    featureData: FeatureData
}

export interface GetQueryIdEvent extends GetItemQueryIdData, HookEventData<'get_query_id'> {}
export interface FeatureDataUpdatedEvent extends FeatureDataUpdate, HookEventData<'after_feature_data_update'> {}

export interface BeforeGetRepeatedPointer {}

export interface BeforeGetRepeatedPointerEvent
    extends BeforeGetRepeatedPointer,
        HookEventData<'before_get_repeated_pointer'> {}

export const DATA_MODEL_HOOKS = {
    NAMESPACE_ITEM: {
        GET_QUERY_ID: createHookDefinition<'get_query_id', GetItemQueryIdData>('get_query_id')
    },
    GET_REPEATED_ITEM_POINTER: {
        BEFORE: createHookDefinition<'before_get_repeated_pointer', BeforeGetRepeatedPointer>(
            'before_get_repeated_pointer'
        )
    },
    FEATURE_DATA_UPDATE: {
        AFTER: createHookDefinition<'after_feature_data_update', FeatureDataUpdate>('after_feature_data_update')
    }
}
