import type {Pointer, ControllerConnectionItem, PS, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../dataModel/dataModel'
import connectionsDataGetter from './connectionsDataGetter'
import component from '../component/component'
import hooks from '../hooks/hooks'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import tpaUtils from '../tpa/utils/tpaUtils'
import {pointerUtils} from '@wix/document-manager-core'
const {getRepeatedItemPointerIfNeeded} = pointerUtils
const CONTROLLER_TYPE = 'platform.components.AppController'
const APP_WIDGET_TYPE = 'platform.components.AppWidget'

function isControllerType(compType: string) {
    return isAppControllerType(compType) || isAppWidgetType(compType)
}

function isAppControllerType(compType: string) {
    return compType === CONTROLLER_TYPE
}

function isAppWidgetType(compType: string) {
    return compType === APP_WIDGET_TYPE
}

function isOOIController(compType: string) {
    return tpaUtils.isTpaByCompType(compType)
}

function connect(
    ps: PS,
    compRef: Pointer,
    controllerRef: Pointer,
    role: string,
    connectionConfig?: any,
    isPrimary?: boolean,
    subRole?: string
) {
    ps.extensionAPI.connections.connect(compRef, controllerRef, role, connectionConfig, isPrimary, subRole)
    hooks.executeHook(hooks.HOOKS.CONNECTION.AFTER_CONNECT, null, [ps, compRef, controllerRef])
}

function createConnectionItem(
    role: string,
    controllerId: string,
    isPrimary: boolean = true,
    subRole?: string
): ControllerConnectionItem {
    return {
        type: 'ConnectionItem',
        role,
        controllerId,
        isPrimary,
        subRole
    }
}

function disconnect(ps: PS, compRef: Pointer, controllerRef: Pointer, role?: string) {
    const existingConnections = connectionsDataGetter.getConnections(ps, compRef)
    const filter: any = {controllerRef}
    if (role) {
        filter.role = role
    }
    const newConnections = _.reject(existingConnections, filter)
    if (_.isEmpty(newConnections)) {
        dataModel.removeConnectionsItem(ps, compRef)
    } else {
        dataModel.updateConnectionsItem(ps, compRef, newConnections)
    }
    const compType = component.getType(ps, compRef)

    hooks.executeHook(hooks.HOOKS.CONNECTION.AFTER_DISCONNECT, compType, [ps, compRef, controllerRef, role])
}

function getConnectedComponentsForController(ps: PS, controllerRef: CompRef, useControllerNamespace?: boolean) {
    const isConnectedComponentPredicate = pointer =>
        connectionsDataGetter.isCompConnectedToController(ps, pointer, controllerRef)

    const pageRef = ps.pointers.full.components.getPageOfComponent(controllerRef)

    if (!pageRef) {
        return []
    }

    if (isAppWidgetType(component.getType(ps, controllerRef))) {
        return ps.pointers.full.components.getChildrenRecursively(controllerRef).filter(isConnectedComponentPredicate)
    }

    const pageId = ps.pointers.full.components.isMasterPage(pageRef) ? null : pageRef.id
    //when the controller is on master pages it will search on all pages from full json

    if (useControllerNamespace) {
        return componentDetectorAPI.getAllComponentsFromFullWithResolvers(
            ps,
            pageId,
            function (pointer) {
                return connectionsDataGetter.isCompConnectedToController(ps, pointer, controllerRef)
            },
            controllerRef.type
        )
    }

    return componentDetectorAPI.getAllComponentsFromFullWithResolvers(ps, pageId, isConnectedComponentPredicate)
}

function getConnectedComponentsRecursively(ps: PS, controllerRef: Pointer) {
    const connectedComponents = getConnectedComponentsForController(
        ps,
        getRepeatedItemPointerIfNeeded(controllerRef as CompRef) as CompRef
    )
    const allConnectedComponents = connectedComponents.reduce((acc, comp) => {
        if (isAppWidgetType(component.getType(ps, comp))) {
            acc.push(comp, ...getConnectedComponentsRecursively(ps, comp))
        } else {
            acc.push(comp)
        }
        return acc
    }, connectedComponents)
    return _.uniq(allConnectedComponents)
}

function getConnectedComponents(ps: PS, controllerRef: Pointer, isRecursive?: boolean) {
    if (isRecursive) {
        return getConnectedComponentsRecursively(ps, controllerRef)
    }
    return getConnectedComponentsForController(ps, controllerRef as CompRef)
}

export interface ConnectionWithRef {
    componentRef: Pointer
    connection: ControllerConnectionItem
}

function getControllerConnections(ps: PS, controllerRef: Pointer): ConnectionWithRef[] {
    const pageRef = ps.pointers.full.components.getPageOfComponent(controllerRef)
    if (!pageRef) {
        return []
    }
    const pageId = ps.pointers.full.components.isMasterPage(pageRef) ? null : pageRef.id
    //when the controller is on master pages it will search on all pages from full json
    const allComponents = componentDetectorAPI.getAllComponentsFromFullWithResolvers(ps, pageId)
    return _.flatMap(allComponents, function (componentRef) {
        return _(connectionsDataGetter.getConnections(ps, componentRef))
            .reject({type: 'WixCodeConnectionItem'})
            .filter(['controllerRef.id', controllerRef.id] as any)
            .map(
                (connection): ConnectionWithRef => ({
                    componentRef,
                    connection: {
                        config: (connection as ControllerConnectionItem).config,
                        // @ts-expect-error
                        controllerRef: (connection as any).controllerRef,
                        role: connection.role,
                        subRole: (connection as ControllerConnectionItem).subRole,
                        isPrimary: (connection as ControllerConnectionItem).isPrimary
                    }
                })
            )
            .value()
    })
}

function getControllerConnectionsByAncestor(ps: PS, controllerRef: Pointer): CompRef[] {
    const pageRef = ps.pointers.full.components.getPageOfComponent(controllerRef)
    if (!pageRef) {
        return []
    }
    const pageId = ps.pointers.full.components.isMasterPage(pageRef) ? null : pageRef.id
    //when the controller is on master pages it will search on all pages from full json
    return componentDetectorAPI.getAllComponentsFromFullWithResolversByAncestor(
        ps,
        pageId,
        c => _.get(connectionsDataGetter.getPrimaryConnection(ps, c), ['controllerRef', 'id']) === controllerRef.id
    )
}

function getAppsConnectedToComponent(ps: PS, componentPointer: Pointer) {
    return (
        connectionsDataGetter
            .getPlatformAppConnections(ps, componentPointer)
            // @ts-expect-error BUG get without a path?
            .map(connection => _.get(component.data.get(ps, connection.controllerRef, 'appDefinitionId')))
    )
}

export default {
    connect,
    disconnect,
    createConnectionItem,
    get: connectionsDataGetter.getConnections,
    getByConnectionPointer: connectionsDataGetter.getConnectionsByPointer,
    getPlatformAppConnections: connectionsDataGetter.getPlatformAppConnections,
    getPrimaryConnection: connectionsDataGetter.getPrimaryConnection,
    getAppsConnectedToComponent,
    getConnectedComponents,
    getConnectedComponentsForController,
    getControllerConnections,
    getControllerConnectionsByAncestor,
    isAppControllerType,
    isAppWidgetType,
    isControllerType,
    isOOIController,
    CONTROLLER_TYPE
}
