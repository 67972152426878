//@ts-expect-error
import parser from '@wix/neat-html-parser'
import json5 from 'json5'
import type {FlatOutline, Outline} from './aiContentExtension'
import {ReportableError, searchRequestId} from '@wix/document-manager-utils'
import _ from 'lodash'
import type {Experiment} from '@wix/document-services-types'
import type {CoreLogger} from '@wix/document-manager-core'
import type {PromptHubRequest, PromptHubResponse} from './types'
import {
    aiBusinessNameFieldIdMissingMessage,
    aiBusinessNameFieldIdMissingType,
    aiBusinessNamePathMissingOnObjectMessage,
    aiBusinessNamePathMissingOnObjectType,
    aiGatewayUrl,
    aiRequestErrorMessage,
    aiRequestErrorType,
    aiShorterBusinessNameRequestErrorMessage,
    aiShorterBusinessNameRequestErrorType,
    experimentPromptHubDefaultPromptId,
    experimentPromptHubPageWithBusinessNamePromptId,
    experimentPromptHubTonePromptId,
    promptHubAppId,
    promptHubBusinessNameShorterPromptId,
    promptHubDefaultPromptId,
    promptHubPageWithBusinessNamePromptId,
    promptHubTonePromptId,
    shortenBusinessNameInteraction
} from './constants'

const getDefaultPromptIdForExperiment = (experiments: Experiment): string => {
    return experiments.isOpen('dm_use0125GPT3ForPageInjection')
        ? experimentPromptHubDefaultPromptId
        : promptHubDefaultPromptId
}

const getTonePromptIdForExperiment = (experiments: Experiment): string => {
    return experiments.isOpen('dm_use0125GPT3ForPageInjection')
        ? experimentPromptHubTonePromptId
        : promptHubTonePromptId
}

const getBusinessNamePromptIdForExperiment = (experiments: Experiment): string => {
    return experiments.isOpen('dm_use0125GPT3ForPageInjection')
        ? experimentPromptHubPageWithBusinessNamePromptId
        : promptHubPageWithBusinessNamePromptId
}

export const getPromptHubPromptIdForSiteInjection = (experiments: Experiment, toneOfVoice?: string): string => {
    const dm_addBusinessNameFieldIdExp = experiments.isOpen('dm_addBusinessNameFieldId')

    if (toneOfVoice) {
        return getTonePromptIdForExperiment(experiments)
    }
    return dm_addBusinessNameFieldIdExp
        ? getBusinessNamePromptIdForExperiment(experiments)
        : getDefaultPromptIdForExperiment(experiments)
}

const buildHtmlFromTagStack = (tagStack: any[], textInTheMiddle: string) => {
    let openTags = ''
    let closingTags = ''

    for (const {tagName, props} of tagStack) {
        openTags += props ? `<${tagName} ${props}>` : `<${tagName}>`
        closingTags = `</${tagName}>${closingTags}`
    }

    return openTags + textInTheMiddle + closingTags
}

export const insertTextInHtml = (html: string, text: string, replaceNewlineWithBreakline: boolean) => {
    const tagStack: any[] = []
    let stop = false

    if (replaceNewlineWithBreakline) {
        // rich texts ignore \n, so we replace them with <br/> to make sure formatting remains consistent
        text = text.replace(new RegExp(/(?:\\n|\n)+/, 'g'), '<br/>')
    }

    parser.parseFragment(html, {
        onText: () => {
            stop = true
        },
        onOpenTag: (tag: any) => {
            if (tag.tagName === 'span' && tag.props === 'class="wixGuard"') {
                stop = true
            }
            if (!stop) {
                tagStack.push(tag)
            }
        },
        onClosingTag: () => {
            if (!stop) {
                //if we are here then tag is empty
                tagStack.pop()
            }
        }
    })

    return buildHtmlFromTagStack(tagStack, text)
}

export const extractTextFromHTML = (html: string) => {
    let htmlText = ''

    parser.parseFragment(html, {
        onText: (text: string) => {
            htmlText += text
        }
    })

    return htmlText ? htmlText : html
}

export const findMissingKeys = (contained: Record<string, any>, container: Record<string, any>): string[] => {
    if (typeof container !== 'object') {
        return Object.keys(contained)
    }

    const containedKeys = Object.keys(contained)
    const missingKeys = containedKeys.filter((key: string) => (container[key] || '').length === 0)

    return missingKeys
}

export const normalizeString = (str: string): string => {
    // Replace curly single quotes with standard single quotes
    str = str.replace(/[\u2018\u2019]/g, "'")

    // Replace curly double quotes with standard double quotes
    str = str.replace(/[\u201C\u201D]/g, '"')

    // Replace non-breaking spaces and other non-standard space characters with standard spaces
    str = str.replace(/[\u00A0\u2000-\u200A\u2028\u2029\u202F\u205F\u3000]/g, ' ')

    return _.unescape(str)
}

export const parsePaddedJSON = (inputString: string): any => {
    const jsonRegex = /{[\s\S]*}/
    const jsonStringMatch = inputString.match(jsonRegex)

    if (jsonStringMatch === null) {
        throw new Error('Invalid or no JSON found in input string')
    }

    const jsonString = jsonStringMatch[0]

    // JSON5 is more forgiving than JSON.parse when encountering minor syntax errors, such as trailing commas or single quotes.
    return json5.parse(jsonString)
}

export const getNewOutlineFromJSON = (parsedJSON: any) => {
    if (!parsedJSON.newOutline) {
        throw new Error('No newOutline found in parsed JSON')
    }

    return parsedJSON.newOutline
}
const sendShortenBusinessNameRequest = async (serverFacade: any, url: string, requestData: any): Promise<any> => {
    try {
        const response = await serverFacade.post(url, requestData)
        return response
    } catch (e: any) {
        throw new ReportableError({
            errorType: aiShorterBusinessNameRequestErrorType,
            message: aiShorterBusinessNameRequestErrorMessage,
            extras: {
                httpStatus: e?.extras?.status,
                responseBody: e?.extras?.result,
                requestId: searchRequestId(e)
            },
            tags: {aiServer: true}
        })
    }
}

const fetchShorterBusinessNameFromPromptHub = async (
    serverFacade: any,
    businessName: string,
    charCount: string
): Promise<string> => {
    const requestData: PromptHubRequest = {
        appDefId: promptHubAppId,
        promptId: promptHubBusinessNameShorterPromptId,
        params: {
            business_name: businessName,
            char_count: charCount.toString()
        },
        userRequestInfo: {
            additionalTags: 'shortenBusinessName'
        }
    }
    const {response} = (await sendShortenBusinessNameRequest(
        serverFacade,
        aiGatewayUrl,
        requestData
    )) as PromptHubResponse
    return response.generatedTexts[0]
}

export const shortenBusinessNameFieldInCompletionIfNecessary = async (
    serverFacade: any,
    logger: CoreLogger,
    experiments: Experiment,
    parsedJSON: any,
    resultOutline: any,
    originalOutline: any
) => {
    if (
        experiments.isOpen('dm_addBusinessNameFieldId') &&
        parsedJSON &&
        (!parsedJSON.businessNameFieldId || parsedJSON.businessNameFieldId === 'none')
    ) {
        logger.captureError(
            new ReportableError({
                errorType: aiBusinessNameFieldIdMissingType,
                message: aiBusinessNameFieldIdMissingMessage,
                extras: {
                    businessNameFieldId: parsedJSON.businessNameFieldId
                }
            })
        )
        return resultOutline
    }
    if (parsedJSON?.businessNameFieldId && resultOutline) {
        const businessNameFieldId = _.replace(parsedJSON.businessNameFieldId, 'newOutline.', '')
        const newBusinessName = _.get(resultOutline, businessNameFieldId)
        if (_.isNil(businessNameFieldId) || _.isNil(newBusinessName)) {
            logger.captureError(
                new ReportableError({
                    errorType: aiBusinessNamePathMissingOnObjectType,
                    message: aiBusinessNamePathMissingOnObjectMessage,
                    extras: {
                        businessNameFieldId: parsedJSON.businessNameFieldId
                    }
                })
            )
            return resultOutline
        }
        const originalBusinessName = _.get(originalOutline, businessNameFieldId)
        if (newBusinessName.length > originalBusinessName.length) {
            logger.interactionStarted(shortenBusinessNameInteraction, {
                extras: {
                    templateBusinessName: originalBusinessName,
                    newBusinessName
                }
            })

            const shortenBusinessName = await fetchShorterBusinessNameFromPromptHub(
                serverFacade,
                newBusinessName,
                originalBusinessName.length
            )

            _.set(resultOutline, businessNameFieldId, shortenBusinessName)
            logger.interactionEnded(shortenBusinessNameInteraction, {
                extras: {
                    templateBusinessName: originalBusinessName,
                    newBusinessName,
                    shortenBusinessName
                }
            })
        }
    }

    return resultOutline
}
const fetchWithErrorHandling = async (
    url: string,
    serverFacade: any,
    params: any,
    pageId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    originalOutline: Outline | FlatOutline
): Promise<any> => {
    try {
        const response = await serverFacade.post(url, params)
        return response
    } catch (e: any) {
        throw new ReportableError({
            errorType: aiRequestErrorType,
            message: aiRequestErrorMessage,
            extras: {
                httpStatus: e?.extras?.status,
                responseBody: e?.extras?.result,
                requestId: searchRequestId(e),
                pageId,
                businessType,
                businessName,
                additionalInformation,
                originalOutline
            },
            tags: {
                aiServer: true
            }
        })
    }
}

export const getPromptHubInjectionParams = (
    businessName: string,
    businessType: string,
    freetext: string,
    outline: Outline | FlatOutline,
    tone?: string,
    section?: string,
    language?: string
): Record<string, string> => {
    const params: Record<string, string> = {
        business_name: businessName,
        business_type: businessType,
        freetext,
        outline: JSON.stringify(outline)
    }
    if (tone) {
        params.tone = tone
    }
    if (section) {
        params.section = section
    }
    if (language) {
        params.language = language
    }

    return params
}

export const fetchOutlineWithPromptParamsFromPromptHub = async (
    serverFacade: any,
    pageId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    outline: Outline | FlatOutline,
    promptId: string,
    params: Record<string, string>,
    additionalTags: string[]
): Promise<PromptHubResponse> => {
    const requestData: PromptHubRequest = {
        appDefId: promptHubAppId,
        promptId,
        params,
        userRequestInfo: {
            additionalTags: additionalTags.join(',')
        }
    }

    return fetchWithErrorHandling(
        aiGatewayUrl,
        serverFacade,
        requestData,
        pageId,
        businessType,
        businessName,
        additionalInformation,
        outline
    )
}
