import type {Pointer, PS} from '@wix/document-services-types'
import socialComp from './utils/socialComp'
import type {ComponentAfterAddDataFromExtEvent} from '@wix/document-manager-extensions/dist/src/extensions/components/hooks'

export const upgradeVKShareButtonUrlOnAddition = (ps: PS, compPointer: Pointer) => {
    socialComp.upgradeUrl(ps, compPointer, 'vk')
}
export const upgradeVKShareButtonUrlOnAdditionFromExt = (ps: PS, data: ComponentAfterAddDataFromExtEvent) => {
    const {compToAddPointer} = data
    upgradeVKShareButtonUrlOnAddition(ps, compToAddPointer)
}
