import type {SerializedCompStructure, Pointers, Experiment} from '@wix/document-services-types'
import type {
    EntitySuggestionResult,
    FlatOutline,
    Outline,
    OutlineFieldMetadata,
    OutlineSuggestionResultForStructure,
    OutlineValidationResults,
    OutlineWithIdMapForStructure
} from './aiContentExtension'
import type {CoreConfig, EnvironmentContext, ExtensionAPI} from '@wix/document-manager-core'
import {
    CompletionCallMetadata,
    invalidOutlineRetryAmount,
    reportOutlineBIError,
    validateOutlineSuggestion,
    validateFlatOutlineSuggestion,
    structureTypes
} from './aiExtensionContent'
import {
    aiContentBadJsonErrorMessage,
    aiContentBadJsonErrorType,
    aiContentMissingOnOutlineErrorType,
    aiContentOutlineResultConsecutiveErrorMessage,
    aiContentOutlineResultConsecutiveErrorType,
    aiContentOutlineResultErrorMessage,
    aiContentOutlineResultErrorType,
    aiContentUnexpectedError,
    aiContentUnexpectedErrorMessage,
    aiPageSuggestionInteraction,
    aiStructureMissingOutlineMessage,
    nonApplicableParamsVersion,
    promptHubCharCountPromptId,
    promptHubFlatStructurePromptId,
    promptHubFlatStructureWithLangPromptId,
    promptHubNewOutlineStructurePromptId,
    promptHubStructurePromptId
} from './constants'
import {
    parsePaddedJSON,
    getNewOutlineFromJSON,
    fetchOutlineWithPromptParamsFromPromptHub,
    getPromptHubInjectionParams
} from './aiExtensionUtils'
import {getReportableFromError, ReportableError} from '@wix/document-manager-utils'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {PromptUsage} from './types'
import {
    applyOutlinesToStructureInternal,
    getContentRecursivelyForSerializedStructure,
    preprocessOutline
} from './structureOutline'

const getOutlineByStructure = (
    extensionArgs: ExtensionArgs,
    compStructure: SerializedCompStructure,
    structureType: string,
    sectionCategory?: string
): OutlineWithIdMapForStructure => {
    const outline: Outline | FlatOutline = {}
    const typeCounter: Record<string, number> = {}
    const idMap: Record<string, string> = {}
    const idsToKeep: Record<string, string> = {}
    const metadataMap: Record<string, OutlineFieldMetadata> = {}
    getContentRecursivelyForSerializedStructure(
        extensionArgs,
        compStructure,
        outline,
        typeCounter,
        idMap,
        idsToKeep,
        structureType,
        metadataMap,
        sectionCategory
    )
    return {outline, idMap, idsToKeep, metadataMap}
}

const getFlatPromptVersion = (experimentInstance: Experiment, language: string | undefined) => {
    const usePromptWithLanguage = experimentInstance.isOpen('dm_aiFlatStructurePromptWithLanguage') && language
    return usePromptWithLanguage ? promptHubFlatStructureWithLangPromptId : promptHubFlatStructurePromptId
}

const getFlatStructureSuggestion = async (
    experimentInstance: Experiment,
    gptPromptVersion: string | null,
    serverFacade: any,
    entityId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    outline: Outline | FlatOutline,
    sectionCategory: string | undefined,
    language: string | undefined,
    versionOverrides: VersionOverrides | undefined
) => {
    gptPromptVersion = versionOverrides?.promptVersionOverride ?? getFlatPromptVersion(experimentInstance, language)

    const completionResponse = await fetchOutlineWithPromptParamsFromPromptHub(
        serverFacade,
        entityId,
        businessType,
        businessName,
        additionalInformation,
        outline,
        gptPromptVersion,
        getPromptHubInjectionParams(
            businessName,
            businessType,
            additionalInformation,
            outline,
            undefined,
            sectionCategory,
            language
        ),
        ['sectionGenerator']
    )
    const completionText = completionResponse.response.generatedTexts[0]
    const {usage} = completionResponse.response.openAiChatCompletionResponse

    return {gptPromptVersion, completionText: completionText!, usage, gptParamsVersion: nonApplicableParamsVersion}
}

const getPromptIdForStructureInjection = (
    experimentInstance: Experiment,
    versionOverrides?: VersionOverrides
): string => {
    if (versionOverrides?.promptVersionOverride) {
        return versionOverrides.promptVersionOverride
    }

    const newOutlineExp = experimentInstance.isOpen('dm_aiSgNewOutlinePrompt')
    const newOutlineWithCharCountExp = experimentInstance.isOpen('dm_aiSgCharCountOutline')

    if (newOutlineWithCharCountExp) {
        return promptHubCharCountPromptId
    }
    if (newOutlineExp) {
        return promptHubNewOutlineStructurePromptId
    }

    return promptHubStructurePromptId
}

const getStructureSuggestion = async (
    experimentInstance: Experiment,
    gptPromptVersion: string | null,
    serverFacade: any,
    entityId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    outline: Outline | FlatOutline,
    sectionCategory: string | undefined,
    language: string | undefined,
    versionOverrides: VersionOverrides | undefined
) => {
    gptPromptVersion = getPromptIdForStructureInjection(experimentInstance, versionOverrides)

    const completionResponse = await fetchOutlineWithPromptParamsFromPromptHub(
        serverFacade,
        entityId,
        businessType,
        businessName,
        additionalInformation,
        outline,
        gptPromptVersion,
        getPromptHubInjectionParams(
            businessName,
            businessType,
            additionalInformation,
            outline,
            undefined,
            sectionCategory,
            language
        ),
        ['siteGenerator']
    )
    const completionText = completionResponse.response.generatedTexts[0]
    const {usage} = completionResponse.response.openAiChatCompletionResponse

    return {gptPromptVersion, completionText: completionText!, usage, gptParamsVersion: nonApplicableParamsVersion}
}

const getSuggestedOutline = async (
    extensionArgs: ExtensionArgs,
    entityId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    content: OutlineWithIdMapForStructure,
    structureType: string,
    sectionCategory?: string,
    versionOverrides?: VersionOverrides,
    language?: string
): Promise<OutlineSuggestionResultForStructure> => {
    const {environmentContext, coreConfig} = extensionArgs
    const {serverFacade} = environmentContext
    const {logger, experimentInstance} = coreConfig
    const {outline, idMap, idsToKeep, metadataMap} = content
    const isFlatStructure = structureType === structureTypes.SECTION

    let resultOutline: Outline | FlatOutline | null = null

    let apiCallCount = 0
    let validationResults: OutlineValidationResults
    let lastError: Error | null = null
    const tokenUsage: any[] = []
    const callsMetadata: CompletionCallMetadata[] = []

    let gptParamsVersion: string | null = null
    let gptPromptVersion: string | null = null

    try {
        while (apiCallCount <= invalidOutlineRetryAmount) {
            let completionText: string | null
            let usage: PromptUsage

            if (isFlatStructure) {
                ;({gptPromptVersion, completionText, usage, gptParamsVersion} = await getFlatStructureSuggestion(
                    experimentInstance,
                    gptPromptVersion,
                    serverFacade,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    outline,
                    sectionCategory,
                    language,
                    versionOverrides
                ))
            } else {
                ;({gptPromptVersion, completionText, usage, gptParamsVersion} = await getStructureSuggestion(
                    experimentInstance,
                    gptPromptVersion,
                    serverFacade,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    outline,
                    sectionCategory,
                    language,
                    versionOverrides
                ))
            }
            apiCallCount += 1
            tokenUsage.push(usage)

            try {
                let parsedOutline = parsePaddedJSON(completionText!)
                if (!isFlatStructure) {
                    parsedOutline = getNewOutlineFromJSON(parsedOutline)
                }
                resultOutline = parsedOutline
            } catch (e) {
                callsMetadata.push({
                    isBadJson: true
                })
                lastError = new ReportableError({
                    errorType: aiContentBadJsonErrorType,
                    message: aiContentBadJsonErrorMessage,
                    extras: {
                        completionText,
                        entityId,
                        businessType,
                        businessName,
                        additionalInformation
                    }
                })
                continue
            }
            if (isFlatStructure) {
                validationResults = validateFlatOutlineSuggestion(resultOutline! as FlatOutline, outline as FlatOutline)
            } else {
                validationResults = validateOutlineSuggestion(resultOutline! as Outline, outline as Outline)
            }

            callsMetadata.push({
                isBadJson: false,
                textCounters: validationResults.counters
            })

            if (validationResults.isValid) {
                break
            } else if (apiCallCount > 1) {
                reportOutlineBIError(
                    logger,
                    aiContentOutlineResultConsecutiveErrorType,
                    aiContentOutlineResultConsecutiveErrorMessage,
                    resultOutline!,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    validationResults
                )
            } else {
                reportOutlineBIError(
                    logger,
                    aiContentOutlineResultErrorType,
                    aiContentOutlineResultErrorMessage,
                    resultOutline!,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    validationResults
                )
            }
        }

        if (!resultOutline) {
            throw (
                lastError ??
                new ReportableError({
                    errorType: aiContentUnexpectedError,
                    message: aiContentUnexpectedErrorMessage
                })
            )
        }

        return {
            outline: resultOutline!,
            idMap,
            idsToKeep,
            validationResults: validationResults!,
            originalOutline: outline,
            retries: apiCallCount - 1,
            completionMetadata: {
                gptParamsVersion: gptParamsVersion ?? nonApplicableParamsVersion,
                promptsVersion: gptPromptVersion!,
                tokenUsage
            },
            metadataMap
        }
    } catch (e: any) {
        // ensure that tokenUsage is always in the error
        const reportedError = getReportableFromError(e, {
            errorType: e.errorType ?? aiContentUnexpectedError,
            tags: {
                aiContentInjection: true
            },
            extras: {tokenUsage}
        })
        logger.captureError(reportedError)

        throw reportedError
    } finally {
        logger.interactionEnded(aiPageSuggestionInteraction, {
            extras: {
                suggestionPurpose: isFlatStructure ? 'flatStructureInjection' : 'structureInjection',
                entityId,
                tokenUsage,
                outline,
                resultOutline,
                completionCalls: callsMetadata
            }
        })
    }
}

const safeGetSuggestion = async (
    extensionArgs: ExtensionArgs,
    entityId: string,
    outline: OutlineWithIdMapForStructure,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    structureType: string,
    compStructure: SerializedCompStructure,
    sectionCategory?: string,
    versionOverrides?: VersionOverrides,
    language?: string
): Promise<EntitySuggestionResult> => {
    try {
        const contentSuggestion = await getSuggestedOutline(
            extensionArgs,
            entityId,
            businessType,
            businessName,
            additionalInformation,
            outline,
            structureType,
            sectionCategory,
            versionOverrides,
            language
        )
        return {entityId, contentSuggestion, compStructure}
    } catch (e: unknown) {
        return {entityId, error: e as Error, compStructure}
    }
}
export interface ExtensionArgs {
    pointers: Pointers
    extensionAPI: ExtensionAPI
    environmentContext: EnvironmentContext
    coreConfig: CoreConfig
}
export interface VersionOverrides {
    paramsVersionOverride?: string
    promptVersionOverride?: string
}
export const getSuggestedOutlineByStructure = async (
    extensionArgs: ExtensionArgs,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    compStructure: SerializedCompStructure,
    sectionCategory?: string,
    versionOverrides?: VersionOverrides,
    language?: string
): Promise<EntitySuggestionResult> => {
    const structureType = structureTypes.SECTION
    const clonedStructure = deepClone(compStructure)
    const structureOutline = getOutlineByStructure(extensionArgs, clonedStructure, structureType, sectionCategory)
    return safeGetSuggestion(
        extensionArgs,
        clonedStructure.id!,
        structureOutline,
        businessType,
        businessName,
        additionalInformation,
        structureType,
        clonedStructure,
        sectionCategory ?? '',
        versionOverrides,
        language
    )
}

export const getSuggestedPageOutlineByStructure = async (
    extensionArgs: ExtensionArgs,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    compStructure: SerializedCompStructure,
    versionOverrides?: VersionOverrides,
    language?: string
): Promise<EntitySuggestionResult> => {
    const structureType = structureTypes.PAGE
    const clonedPage = deepClone(compStructure)
    const structureOutline = getOutlineByStructure(extensionArgs, clonedPage, structureType)
    return safeGetSuggestion(
        extensionArgs,
        clonedPage.id!,
        structureOutline,
        businessType,
        businessName,
        additionalInformation,
        structureType,
        clonedPage,
        '',
        versionOverrides,
        language
    )
}

export const applyOutlineToStructure = (
    extensionArgs: ExtensionArgs,
    outlineWithIds: OutlineWithIdMapForStructure,
    compStructure: SerializedCompStructure
): SerializedCompStructure => {
    if (!outlineWithIds) {
        throw new ReportableError({
            errorType: aiContentMissingOnOutlineErrorType,
            message: aiStructureMissingOutlineMessage,
            extras: {
                compStructure
            }
        })
    }
    const {outline, idMap} = outlineWithIds
    const compIdToName = _.invert(idMap)

    preprocessOutline(outline as FlatOutline, outlineWithIds.metadataMap ?? {})
    applyOutlinesToStructureInternal(extensionArgs, compStructure, outline, compIdToName, {})
    return compStructure
}

export const applyOutlineToPageStructure = (
    extensionArgs: ExtensionArgs,
    outlineWithIds: OutlineWithIdMapForStructure,
    compStructure: SerializedCompStructure
): SerializedCompStructure => {
    if (!outlineWithIds) {
        throw new ReportableError({
            errorType: aiContentMissingOnOutlineErrorType,
            message: aiStructureMissingOutlineMessage,
            extras: {
                compStructure
            }
        })
    }
    const {outline, idMap, idsToKeep} = outlineWithIds
    const compIdToName = _.invert(idMap)
    const flattenedOutline = Object.assign({}, ..._.values(outline))

    preprocessOutline(flattenedOutline, outlineWithIds.metadataMap ?? {})
    applyOutlinesToStructureInternal(extensionArgs, compStructure, flattenedOutline, compIdToName, idsToKeep ?? {})
    return compStructure
}
