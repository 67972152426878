import type {
    CompRef,
    ExternalsBuilderMap,
    ExternalsMap,
    GlobalData,
    Pointer,
    Pointers
} from '@wix/document-services-types'
import type {CreateExtArgs} from '@wix/document-manager-core'
import {getResolvedDataItemsByNamespaceMap, updateDataItemsByNamespacesDataMap} from './dataItems'
import {DATA_TYPES} from '../../../../../constants/constants'

const getPermanentDataNodePointers = (pointers: Pointers): Pointer[] => {
    const permanentDataNodePointers: Pointer[] = []
    for (const namespace of Object.values(DATA_TYPES)) {
        permanentDataNodePointers.push(...pointers.data.getPermanentNodes(namespace))
    }

    return permanentDataNodePointers
}

export const getGlobalData = (
    extensionContext: CreateExtArgs,
    componentPointer: CompRef,
    externals?: ExternalsBuilderMap
): GlobalData | undefined => {
    const {pointers} = extensionContext
    if (!pointers.structure.isMasterPage(componentPointer)) {
        return
    }

    const permanentDataNodePointers = getPermanentDataNodePointers(pointers)
    return getResolvedDataItemsByNamespaceMap(extensionContext, permanentDataNodePointers, componentPointer, externals)
}

export const updateGlobalData = (
    extensionContext: CreateExtArgs,
    componentPointer: CompRef,
    globalData: GlobalData | undefined,
    externals: ExternalsMap
) => {
    const {pointers} = extensionContext
    if (!pointers.structure.isMasterPage(componentPointer)) {
        return
    }

    const origPermanentDataNodesPointers = getPermanentDataNodePointers(pointers)
    updateDataItemsByNamespacesDataMap(
        extensionContext,
        origPermanentDataNodesPointers,
        globalData,
        componentPointer,
        externals
    )
}
