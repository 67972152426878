import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import hooks from '../hooks/hooks'
import componentStructureInfo from './componentStructureInfo'
import siteWidth from '../structure/siteWidth/siteWidth'
import componentModes from './componentModes'
import mobileConversionFacade from '../mobileConversion/mobileConversionFacade'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import dsUtils from '../utils/utils'
import relationUtils from '../variants/relationsUtils'
import isSystemStyle from '../theme/isSystemStyle'
import language from '../siteMetadata/language'
import {isCompTypeIsValidForDesignInVariants} from '../overrides/designOverVariants'
import type {CompStructure, Pointer, PS} from '@wix/document-services-types'
import dataSerialization from '../dataModel/dataSerialization'
import runtimeConfig from '../utils/runtimeConfig'

const {
    namespaceMapping: {getNamespaceConfig, featureNamespaces}
} = jsonSchemas
const {
    DATA_TYPES,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    SERIALIZED_SCOPED_DATA_KEYS,
    SERIALIZED_DATA_KEYS,
    RELATION_DATA_TYPES,
    REF_ARRAY_DATA_TYPE,
    DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT,
    VARIANTS
} = constants
const CUSTOM_COMP_DEFINITION_ATTRIBUTE = 'custom'
const SPLITTED_DATA_TYPES = ['dataQuery', 'designQuery']
const {stripHashIfExists} = dsUtils

interface VariantsCollection {
    inUse: Set<Pointer>
    owned: Set<string>
}

type Enricher = (comp: CompStructure) => void

const updateVariantsCollection = (ps: PS, variantsCollection: VariantsCollection, componentPointer: Pointer) => {
    const ownedVariants = Object.values(ps.pointers.data.getVariantDataItemsByComponentId(componentPointer.id))

    for (const variantPointer of ownedVariants) {
        variantsCollection.owned.add((variantPointer as Pointer).id)
    }

    for (const variantPointer of relationUtils.getAllAffectingVariants(ps, componentPointer)) {
        variantsCollection.inUse.add(variantPointer)
    }
}

const getOutOfScopeReferredVariants = (ps: PS, referred: Set<Pointer>, owned: Set<string>) => {
    const referredVariantsPointers = [...referred].filter(variantPointer => !owned.has(variantPointer.id))
    const referredVariants = {}

    for (const variantPointer of referredVariantsPointers) {
        referredVariants[variantPointer.id] = {
            pointer: variantPointer,
            data: dataSerialization.serializeDataItem(ps, DATA_TYPES.variants, variantPointer)
        }
    }

    return referredVariants
}

/**
 * Returns the Component as a Serialized ComponentDefinition Object.
 * @param ps
 * @param componentPointer The component Reference to serialize the corresponding Component.
 * @param dataItemPointer
 * @param ignoreChildren
 * @param maintainIdentifiers
 * @param flatMobileStructuresMap
 * @param structureEnricher
 * @param useOriginalLanguage
 * @returns a fully serialized with its Data & Properties from the document. null is returned
 * in case no corresponding component is found.
 *
 *      @example
 *      const myPhotoReference = ...;
 *      ...
 *      const serializedComp = documentServices.components.serialize(myPhotoReference);
 */
function serializeComponent(
    ps: PS,
    componentPointer: Pointer,
    dataItemPointer?: Pointer,
    ignoreChildren?: boolean,
    maintainIdentifiers?,
    flatMobileStructuresMap?,
    structureEnricher?: Enricher,
    useOriginalLanguage?
) {
    const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
    structureEnricher = structureEnricher || _.noop

    const flags = {
        shouldAddMobilePresets: mobileConversionFacade.shouldCreateMobilePreset(ps, actualComponentPointer),
        ignoreChildren,
        maintainIdentifiers
    }
    const variantsCollection: VariantsCollection = {
        inUse: new Set<Pointer>(),
        owned: new Set<string>()
    }
    const serializedComponent = serializeComponentStructureAndData(
        ps,
        actualComponentPointer,
        dataItemPointer,
        flatMobileStructuresMap,
        structureEnricher,
        flags,
        useOriginalLanguage,
        variantsCollection
    )
    if (serializedComponent) {
        serializedComponent.activeModes = getActiveModesOfComponentAncestors(ps, actualComponentPointer)

        if (!runtimeConfig.isBolt(ps)) {
            serializedComponent.referredVariants = getOutOfScopeReferredVariants(
                ps,
                variantsCollection.inUse,
                variantsCollection.owned
            )

            // Please note that activeVariants is dependent on viewer
            serializedComponent.activeVariants = ps.extensionAPI.patterns.getVariantsForItem(componentPointer)
        }
        if (ps.extensionAPI.componentDefinition.isContainer(serializedComponent.componentType)) {
            serializedComponent.siteWidth = siteWidth.getSiteWidthForViewMode(ps, componentPointer.type)
        }
    }
    return serializedComponent
}

function shouldMaintainIdentifiers(ps: PS, componentPointer: Pointer, currentValue) {
    return currentValue || componentsMetaData.shouldForceMaintainIDsOnSerialize(ps, componentPointer)
}

function getActiveModesOfComponentAncestors(ps: PS, componentPointer: Pointer) {
    const activeModesThatCanAffectSerializedComp = {}

    let componentAncestor = ps.pointers.full.components.getParent(componentPointer)
    while (componentAncestor) {
        const compActiveModesInPage = componentModes.getComponentActiveModeIds(ps, componentAncestor)
        _.merge(activeModesThatCanAffectSerializedComp, compActiveModesInPage)
        componentAncestor = ps.pointers.full.components.getParent(componentAncestor)
    }
    return activeModesThatCanAffectSerializedComp
}

function serializeComponentStructureAndData(
    ps: PS,
    componentPointer: Pointer,
    dataItemPointer: Pointer,
    flatMobileStructuresMap,
    structureEnricher: Enricher,
    flags,
    useOriginalLanguage,
    variantsCollection: VariantsCollection
) {
    flags = _.defaults(
        {
            maintainIdentifiers: shouldMaintainIdentifiers(ps, componentPointer, flags.maintainIdentifiers)
        },
        flags
    )
    flatMobileStructuresMap = flatMobileStructuresMap || getFlatMobileStructuresMap(ps)
    let compStructure

    if (ps.pointers.page.isExists(componentPointer.id)) {
        const pagePointer = ps.pointers.page.getPagePointer(componentPointer.id)
        const pageInnerPointer = ps.pointers.getInnerPointer(pagePointer, ['structure'])
        // @ts-expect-error REMOVE
        compStructure = ps.dal.full.get({...pageInnerPointer, source: 'serialize'})
    } else {
        compStructure = ps.dal.full.get(componentPointer)
    }

    if (!compStructure) {
        return null
    }

    const isRepeatedComponent = santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(componentPointer.id)

    compStructure = _.reduce(
        SPLITTED_DATA_TYPES,
        function (res, dataType) {
            if (res[dataType]) {
                if (flags.itemId) {
                    res[dataType] = santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(
                        res[dataType],
                        flags.itemId
                    )
                } else if (isRepeatedComponent) {
                    res[dataType] = ps.dal.get(ps.pointers.getInnerPointer(componentPointer, dataType))
                }
            }
            return res
        },
        compStructure
    )

    const pagePointer = ps.pointers.full.components.getPageOfComponent(componentPointer)
    const pageId = pagePointer.id

    if (!runtimeConfig.isBolt(ps)) {
        updateVariantsCollection(ps, variantsCollection, componentPointer)
    }

    resolveDataItems(ps, compStructure, dataItemPointer, flags, pageId, structureEnricher, useOriginalLanguage)
    hooks.executeHook(hooks.HOOKS.SERIALIZE.DATA_AFTER, compStructure.componentType, [
        ps,
        componentPointer,
        compStructure,
        flags,
        pageId
    ])

    if (!flags.ignoreChildren) {
        const childrenFlags = _.clone(flags)
        if (isRepeatedComponent) {
            childrenFlags.itemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
        }
        hooks.executeHook(hooks.HOOKS.SERIALIZE.CHILDREN_BEFORE, compStructure.componentType, [
            compStructure,
            childrenFlags
        ])
        serializeChildren(
            ps,
            compStructure,
            componentPointer,
            pagePointer,
            flatMobileStructuresMap,
            structureEnricher,
            childrenFlags,
            useOriginalLanguage,
            variantsCollection
        )
        if (flags.maintainIdentifiers) {
            mobileUtil.serializeMobileChildren(
                ps,
                compStructure,
                pageId,
                childrenFlags,
                flatMobileStructuresMap,
                structureEnricher,
                useOriginalLanguage,
                variantsCollection,
                serializeComponentStructureAndData
            )
        }
    } else {
        compStructure.components = []
    }

    if (!flags.maintainIdentifiers) {
        delete compStructure.mobileComponents
        delete compStructure.id
    }

    const customStructureData = {}
    const hookArguments = [ps, componentPointer, customStructureData]
    hooks.executeHook(hooks.HOOKS.SERIALIZE.SET_CUSTOM, compStructure.componentType, hookArguments)
    if (!_.isEmpty(customStructureData)) {
        compStructure[CUSTOM_COMP_DEFINITION_ATTRIBUTE] = _.defaults(
            compStructure[CUSTOM_COMP_DEFINITION_ATTRIBUTE] || {},
            customStructureData
        )
    }
    // TODO: should a failed hook fail the whole serialization process ?

    if (componentPointer.type === constants.VIEW_MODES.DESKTOP) {
        mobileUtil.serializeMobileStructure(
            ps,
            componentPointer,
            compStructure,
            pageId,
            flatMobileStructuresMap,
            structureEnricher,
            flags,
            resolveDataItems,
            serializeComponentStructureAndData,
            {
                // @ts-expect-error
                componentStructureInfo,
                mobileConversionFacade,
                dataModel
            }
        )
    }

    return compStructure
}

interface Flags {
    maintainIdentifiers: boolean
    repeatedItemIds: string[]
}

function serializeChildren(
    ps: PS,
    compStructure,
    componentPointer: Pointer,
    pagePointer: Pointer,
    flatMobileStructuresMap,
    structureEnricher: Enricher,
    flags: Flags,
    useOriginalLanguage,
    variantsCollection: VariantsCollection
) {
    const {slotsQuery} = compStructure
    const childrenPointers = ps.pointers.full.components.getChildren(componentPointer)
    const hadChildren = childrenPointers?.length

    if (slotsQuery) {
        const slotsData = ps.dal.get(ps.pointers.getPointer(slotsQuery, constants.DATA_TYPES.slots))
        const slotNamesAndValues = slotsData.slots

        const serializeComponentById = (id: string) => {
            const slotPointer = ps.pointers.components.getComponent(stripHashIfExists(id), pagePointer)
            return serializeComponentStructureAndData(
                ps,
                slotPointer,
                null,
                flatMobileStructuresMap,
                structureEnricher,
                flags,
                useOriginalLanguage,
                variantsCollection
            )
        }

        compStructure.slots = {
            type: slotsData.type,
            slots: {
                ..._.mapValues(slotNamesAndValues, compId => {
                    const idWithoutHash = stripHashIfExists(compId)

                    _.remove(childrenPointers, {id: idWithoutHash})
                    return serializeComponentById(idWithoutHash)
                })
            }
        }

        delete compStructure.slotsQuery
    }

    if (hadChildren) {
        compStructure.components = _.map(childrenPointers, compPointer =>
            serializeComponentStructureAndData(
                ps,
                compPointer,
                null,
                flatMobileStructuresMap,
                structureEnricher,
                flags,
                useOriginalLanguage,
                variantsCollection
            )
        )
    }
}

function getFlatMobileStructuresMap(ps: PS) {
    const mobileStructuresPointer = ps.pointers.general.getMobileStructuresPointer()
    const mobileStructures = ps.dal.get(mobileStructuresPointer)
    const map = {}

    function populateMap(component) {
        map[component.id] = _.omit(_.clone(component), 'components')
        _.forEach(component.components, populateMap)
    }

    _.forOwn(mobileStructures, populateMap)

    return map
}

function shouldUseDesignOverVariants(ps: PS, compStructure: CompStructure, pageId: string) {
    const designQuery = _.get(compStructure, COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.design])
    const designPointer = ps.pointers.data.getDesignItem(stripHashIfExists(designQuery), pageId)
    const compDesign = ps.dal.get(designPointer)

    return (
        isCompTypeIsValidForDesignInVariants(compStructure.componentType) &&
        dataModel.refArray.isRefArray(ps, compDesign)
    )
}

function resolveDataItems(
    ps: PS,
    compStructure: CompStructure,
    dataItemPointer: Pointer,
    flags: Flags,
    pageId: string,
    structureEnricher: Enricher,
    useOriginalLanguage: boolean
) {
    structureEnricher(compStructure)
    serializeDataOnStructure(ps, flags, compStructure, pageId, dataItemPointer, useOriginalLanguage)
    serializePropertiesOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializeMobileHintsOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    if (!shouldUseDesignOverVariants(ps, compStructure, pageId)) {
        serializeDesignOnStructure(ps, flags, compStructure, pageId)
    }
    serializeAnchorDataOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializeFeaturesOnStructure(ps, flags, compStructure, pageId)
    serializeBehaviorOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializeConnectionDataOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializeStatesOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializePatternsOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializeTriggersOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializeVariantsOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
    serializeVariablesOnStructure(ps, flags, compStructure, pageId)
    serializeEffectsOnStructure(ps, flags, compStructure, pageId)
    serializeAllRelationalDataOnStructure(ps, flags, compStructure, pageId)
    serializeTranslationDataOnStructure(ps, flags.maintainIdentifiers, compStructure, dataItemPointer, pageId)

    if (_.get(compStructure, 'modes.overrides')) {
        resolveDataItemsInOverrides(ps, compStructure.modes.overrides, flags, pageId, structureEnricher)
    }
}

function resolveDataItemsInOverrides(
    ps: PS,
    componentModesOverrides,
    flags: Flags,
    pageId: string,
    structureEnricher: Enricher
) {
    _.forEach(componentModesOverrides, function (override) {
        structureEnricher(override)
        serializeRelationalDataOnStructureByType(ps, flags, override, pageId, DATA_TYPES.theme)
        if (shouldUseDesignOverVariants(ps, componentModesOverrides, pageId)) {
            serializeRelationalDataOnStructureByType(ps, flags, override, pageId, DATA_TYPES.design)
        } else {
            serializeDesignOnStructure(ps, flags, override, pageId)
        }
        serializePropertiesOnStructure(ps, flags.maintainIdentifiers, override, pageId)
    })
}

/**
 * Checks if dataPointer has a translation in lang
 * @param ps
 * @param dataItemId
 * @param lang
 * @param pageId
 * @returns true if translation exists, false if it doesn't
 */
const hasTranslation = (ps: PS, dataItemId: string, lang: string, pageId: string): boolean => {
    const dataItemTranslationPointer = ps.pointers.multilingualTranslations.translationDataItem(
        pageId,
        lang,
        dataItemId
    )
    return ps.dal.isExist(dataItemTranslationPointer)
}

/**
 * Retrieves the serialized representation of component translations.
 * @param {ps} ps
 * @param {Pointer} dataPointer
 * @param {boolean} maintainIdentifiers
 * @param {string} pageId
 * @returns {object}
 */
const getSerializedTranslations = (ps: PS, dataPointer: Pointer, maintainIdentifiers: boolean, pageId: string) => {
    if (!dataPointer) {
        return
    }

    const langs = language.getFull(ps).map(lang => lang.languageCode)
    const langsForData = _.filter(langs, lang => hasTranslation(ps, dataPointer.id, lang, pageId))

    if (langsForData.length) {
        return _.reduce(
            langsForData,
            (result, langCode) => {
                const data = dataModel.getDataItemByIdInLang(ps, dataPointer.id, pageId, !maintainIdentifiers, langCode)
                result[langCode] = data
                return result
            },
            {}
        )
    }
}

function serializeTranslationDataOnStructure(
    ps: PS,
    maintainIdentifiers: boolean,
    compStructure: CompStructure,
    dataItemPointer: Pointer,
    pageId: string
) {
    if (!dataItemPointer) {
        const viewMode = ps.siteAPI.isMobileView() ? constants.VIEW_MODES.MOBILE : constants.VIEW_MODES.DESKTOP
        const compPointer = ps.pointers.components.getComponent(
            compStructure.id,
            ps.pointers.components.getPage(pageId, viewMode)
        )
        dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
    }

    const translations = getSerializedTranslations(ps, dataItemPointer, maintainIdentifiers, pageId)

    if (translations) {
        compStructure.translations = translations
    }
}

function generateRepeatedData(
    ps: PS,
    query: string,
    pageId: string,
    dataItemPointer: Pointer,
    repeatedItemIds: string[],
    getItemFunc: (ps: PS, query: string, pageId: string, dataItemPointer?: Pointer) => any
) {
    const dataItem = ps.extensionAPI.dataModel.createDataItemByType('RepeatedData')
    dataItem.original = getItemFunc(ps, query, pageId, dataItemPointer)
    dataItem.overrides = _.zipObject(
        repeatedItemIds,
        _.map(repeatedItemIds, function (itemId) {
            const displayedQuery = santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(query, itemId)
            return getItemFunc(ps, displayedQuery, pageId)
        })
    )
    return dataItem
}

function serializeDataOnStructure(
    ps: PS,
    flags: Flags,
    compStructure: CompStructure,
    pageId: string,
    dataItemPointer: Pointer,
    useOriginalLanguage: boolean
) {
    const {dataQuery} = compStructure
    if (dataQuery) {
        compStructure.data = serializeDataItem(
            ps,
            dataQuery,
            flags,
            pageId,
            DATA_TYPES.data,
            dataItemPointer,
            useOriginalLanguage
        )
        delete compStructure.dataQuery
    }
}

function getSerializedDesignItem(ps: PS, designQuery: string, pageId: string, designItemPointer: Pointer) {
    const designPointer = designItemPointer || ps.pointers.data.getDesignItem(stripHashIfExists(designQuery), pageId)
    return dataModel.serializeDataItem(ps, DATA_TYPES.design, designPointer, true)
}

function serializeDesignOnStructure(ps: PS, flags, structureWithDesign, pageId: string) {
    const {designQuery} = structureWithDesign
    if (designQuery) {
        const {repeatedItemIds} = flags
        const shouldGenerateRepeatedData = hasRepeatedItems(repeatedItemIds)
        const designPointer = ps.pointers.data.getDesignItem(stripHashIfExists(designQuery), pageId)
        structureWithDesign.design = shouldGenerateRepeatedData
            ? generateRepeatedData(ps, designQuery, pageId, designPointer, repeatedItemIds, getSerializedDesignItem)
            : getSerializedDesignItem(ps, designQuery, pageId, designPointer)
        if (flags.maintainIdentifiers && structureWithDesign.design) {
            structureWithDesign.design.id = stripHashIfExists(designQuery)
        }
        delete structureWithDesign.designQuery
    }
}

function serializeDataItem(
    ps: PS,
    itemId: string,
    flags: Flags,
    pageId: string,
    itemType: string,
    itemPointer?: Pointer,
    useOriginalLanguage?: boolean
) {
    const {repeatedItemIds} = flags
    const nameSpaceSupportRepeaters = dataModel.doesItemTypeSupportsRepeatedItem(itemType)
    const shouldGenerateRepeatedData = nameSpaceSupportRepeaters && hasRepeatedItems(repeatedItemIds)
    const _itemPointer = itemPointer || ps.pointers.data.getItem(itemType, stripHashIfExists(itemId), pageId)
    const item = shouldGenerateRepeatedData
        ? generateRepeatedData(ps, itemId, pageId, _itemPointer, repeatedItemIds, (_ps, _query, _pageId, _pointer) =>
              getSerializedItem(_ps, _query, _pageId, _pointer, itemType)
          )
        : getSerializedItem(ps, itemId, pageId, _itemPointer, itemType, useOriginalLanguage)
    if (flags.maintainIdentifiers && item) {
        item.id = stripHashIfExists(itemId)
    }
    return item
}

function hasRepeatedItems(repeatedItemIds: string[]): boolean {
    return _.isArray(repeatedItemIds) && repeatedItemIds.length > 0
}

function getSerializedItem(
    ps: PS,
    itemId: string,
    pageId: string,
    itemPointer: Pointer,
    itemType: string,
    useOriginalLanguage?: boolean
) {
    const _itemPointer = itemPointer || ps.pointers.data.getItem(itemType, stripHashIfExists(itemId), pageId)
    return dataModel.serializeDataItem(ps, itemType, _itemPointer, true, useOriginalLanguage)
}

function serializeFeaturesOnStructure(ps: PS, flags, structureWithFeatures, pageId: string) {
    _(featureNamespaces)
        .map(namespace => getNamespaceConfig(namespace))
        .reject({supportsVariants: true})
        .forEach(({query, namespace}) => {
            const featureId = structureWithFeatures[query]
            if (featureId && query) {
                structureWithFeatures[namespace] = serializeDataItem(ps, featureId, flags, pageId, namespace)
                delete structureWithFeatures[query]
            }
        })
}

function serializeDataOnStructureByType(
    ps: PS,
    maintainIdentifiers: boolean,
    compStructure: CompStructure,
    pageId: string,
    dataType: string
) {
    const compDataQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataType]
    if (compStructure[compDataQueryKey]) {
        const serializedCompDataKey = SERIALIZED_DATA_KEYS[dataType]
        const currentDataId = stripHashIfExists(compStructure[compDataQueryKey])
        const dataPointer = ps.pointers.data.getItem(dataType, currentDataId, pageId)
        const removeIds = !maintainIdentifiers
        compStructure[serializedCompDataKey] = dataModel.serializeDataItem(ps, dataType, dataPointer, removeIds)
        if (maintainIdentifiers && compStructure[serializedCompDataKey]) {
            compStructure[serializedCompDataKey].id = currentDataId
        }
        delete compStructure[compDataQueryKey]
    }
}

function serializePropertiesOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.prop)
}

function serializeAnchorDataOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.anchors)
}

function serializeMobileHintsOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.mobileHints)
}

function serializeBehaviorOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.behaviors)
}

function serializeStatesOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.states)
}

function serializePatternsOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.patterns)
}

function serializeTriggersOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.triggers)
}

function serializeConnectionDataOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.connections)
}

function serializeSingleStyle(ps, style, flags) {
    if (isSystemStyle(ps, style.id)) {
        return style.id
    }
    if (!flags.maintainIdentifiers) {
        return _.omit(style, 'id')
    }
    return style
}

const getLayoutObjectKey = layout => {
    switch (layout.type) {
        case 'FlexContainerLayout':
        case 'GridContainerLayout':
        case 'StackContainerLayout':
        case 'OrganizerContainerLayout':
            return 'containerLayout'
        case 'FlexItemLayout':
        case 'GridItemLayout':
        case 'StackItemLayout':
        case 'OrganizerItemLayout':
        case 'FixedItemLayout':
            return 'itemLayout'
        case 'ComponentLayout':
            return 'componentLayout'
        default:
            return 'componentLayout'
    }
}

function serializeSingleLayout(layout, flags, currentScopedValue) {
    let singleLayoutObject = _.merge(dataModel.createLayoutObject(), currentScopedValue)

    if (layout.type !== 'SingleLayoutData') {
        const layoutKey = getLayoutObjectKey(layout)
        singleLayoutObject.metaData = layout.metaData
        layout = _.omit(layout, 'id', 'metaData', 'breakpoint')
        singleLayoutObject[layoutKey] = layout
    } else {
        singleLayoutObject = layout
    }

    if (!flags.maintainIdentifiers) {
        return _.omit(singleLayoutObject, 'id')
    }

    return singleLayoutObject
}

function serializeSingleValue(ps: PS, scopedValue, flags, dataType: string, pageId: string, currentScopedValue) {
    switch (dataType) {
        case DATA_TYPES.layout:
            return serializeSingleLayout(scopedValue, flags, currentScopedValue) //TODO: remove once oldLayoutItems are migrated to SingleLayoutData via autopilot or dataFIxer
        case DATA_TYPES.theme:
            return serializeSingleStyle(ps, scopedValue, flags)
    }

    return serializeDataItem(ps, _.get(scopedValue, 'id'), flags, pageId, dataType)
}

function serializeVariantsOnStructure(ps: PS, maintainIdentifiers, compStructure, pageId: string) {
    delete compStructure.breakpointVariantsQuery
    delete compStructure.variantsQuery

    if (!compStructure.id) {
        return
    }

    const variantIds = Object.values(ps.pointers.data.getVariantDataItemsByComponentId(compStructure.id)).reduce(
        (filtered: string[], variant: any): string[] => {
            if (variant.type !== VARIANTS.TYPES.REPEATER_PATTERN && !VARIANTS.USE_VARIANTS_LIST.has(variant.type)) {
                filtered.push(variant.id)
            }

            return filtered
        },
        []
    )
    dataModel.serializeVariantsData(ps, compStructure, maintainIdentifiers, variantIds, pageId)
}

function serializeVariablesOnStructure(ps: PS, flags, compStructure, pageId: string) {
    serializeRelationalDataOnStructureByType(ps, flags, compStructure, pageId, DATA_TYPES.variables)
    if (_.get(compStructure, 'variables.id')) {
        delete compStructure.variables.id
    }
}

function serializeEffectsOnStructure(ps: PS, flags, compStructure, pageId: string) {
    serializeRelationalDataOnStructureByType(ps, flags, compStructure, pageId, DATA_TYPES.effects)
    if (_.get(compStructure, 'effects.id')) {
        delete compStructure.effects.id
    }
}

const shouldSerializeRelationalData = (ps: PS, compStructure, pageId: string, dataType: string) =>
    dataType !== DATA_TYPES.design || shouldUseDesignOverVariants(ps, compStructure, pageId)

function serializeAllRelationalDataOnStructure(ps: PS, flags, compStructure, pageId: string) {
    _.forEach(_.keys(SERIALIZED_SCOPED_DATA_KEYS), dataType => {
        if (shouldSerializeRelationalData(ps, compStructure, pageId, dataType)) {
            serializeRelationalDataOnStructureByType(ps, flags, compStructure, pageId, dataType)
        }
    })
}

function serializeRelationalDataOnStructureByType(ps: PS, flags, compStructure, pageId: string, dataType: string) {
    const compDataQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataType]
    if (!compStructure[compDataQueryKey]) {
        return
    }
    const itemId = dsUtils.stripHashIfExists(compStructure[compDataQueryKey])
    const itemPointer = ps.pointers.data.getItem(dataType, itemId, pageId)
    const serializeItem = dataModel.serializeDataItem(ps, dataType, itemPointer, false)
    const scopedDataKey = SERIALIZED_SCOPED_DATA_KEYS[dataType]
    const nonScopedDataKey = SERIALIZED_DATA_KEYS[dataType]
    if (serializeItem) {
        if (serializeItem.type === REF_ARRAY_DATA_TYPE) {
            serializeRefArray(
                ps,
                serializeItem,
                scopedDataKey,
                nonScopedDataKey,
                flags,
                compStructure,
                pageId,
                dataType
            )
        } else if (DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT[dataType]) {
            serializeRefArrayRecursively(ps, serializeItem, flags, pageId, dataType)
            compStructure[nonScopedDataKey] = serializeItem
        } else {
            compStructure[nonScopedDataKey] = serializeSingleValue(
                ps,
                serializeItem,
                flags,
                dataType,
                pageId,
                compStructure[nonScopedDataKey]
            )
        }
    }

    delete compStructure[compDataQueryKey]
}

function serializeRefArray(
    ps: PS,
    serializeItem,
    scopedDataKey: string,
    nonScopedDataKey: string,
    flags,
    compStructure,
    pageId: string,
    dataType: string
) {
    const values = dataModel.refArray.extractValues(ps, serializeItem)
    _.reduce(
        values,
        (structure, value) => {
            if (value.type === RELATION_DATA_TYPES.VARIANTS) {
                structure[scopedDataKey] = structure[scopedDataKey] || {}
                const variants = dataModel.variantRelation.extractAllVariants(ps, value)
                const scopedValue = value.to
                if (!_.isEmpty(variants)) {
                    const scopedKey = _.orderBy(variants).toString()
                    structure[scopedDataKey][scopedKey] = serializeSingleValue(
                        ps,
                        scopedValue,
                        flags,
                        dataType,
                        pageId,
                        structure[scopedDataKey][scopedKey]
                    )
                }
            } else if (dataType === DATA_TYPES.theme || dataType === DATA_TYPES.layout) {
                structure[scopedDataKey] = structure[scopedDataKey] || {}
                structure[nonScopedDataKey] = serializeSingleValue(
                    ps,
                    value,
                    flags,
                    dataType,
                    pageId,
                    structure[nonScopedDataKey]
                )
            } else {
                structure[nonScopedDataKey] = serializeSingleValue(
                    ps,
                    value,
                    flags,
                    dataType,
                    pageId,
                    structure[nonScopedDataKey]
                )
            }
            return structure
        },
        compStructure
    )
}

function serializeRefArrayRecursively(ps: PS, serializeItem, flags, pageId: string, dataType: string) {
    _.keys(serializeItem).forEach(key => {
        if (_.get(serializeItem, [key, 'type']) === REF_ARRAY_DATA_TYPE) {
            const serializedRefArray = {}
            serializeRefArray(ps, serializeItem[key], 'scoped', 'default', flags, serializedRefArray, pageId, dataType)
            serializeItem[key] = serializedRefArray
            return
        }

        if (_.isObject(serializeItem[key])) {
            serializeRefArrayRecursively(ps, serializeItem[key], flags, pageId, dataType)
        }
    })
}

export default {
    serializeComponent
}
