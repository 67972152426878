import type {CoreConfig, DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef, Pointer, Pointers, RawComponentExport, WMLFiles} from '@wix/document-services-types'
import type {ImportExportExtensionApi} from '../../import-export/importExportExtension'
import {buildPlaceholderStructure} from '../../import-export/json/utils'
import {reuseIds} from './reuseIds'

export const importPage = async (
    pagePointer: CompRef,
    pageData: WMLFiles | null,
    extensionAPI: ExtensionAPI,
    dal: DAL,
    pointers: Pointers,
    {schemaService}: CoreConfig
): Promise<void> => {
    const {importExport} = extensionAPI as ImportExportExtensionApi

    if (pageData === null) {
        const allItemsOfPage: Pointer[] = pointers.page.getAllPointersByPage(pagePointer.id)
        allItemsOfPage.forEach((ptr: Pointer) => dal.remove(ptr))
        return
    }

    const rawComponentExport: RawComponentExport = await importExport.wml.convertWmlToRawComponentExport(pageData)

    if (!dal.has(pagePointer)) {
        const pagePlaceholderStructure = buildPlaceholderStructure(pagePointer, pagePointer)
        dal.set(pagePointer, pagePlaceholderStructure)

        const mobilePointer = pointers.structure.getMobilePointer(pagePointer)
        dal.set(mobilePointer, pagePlaceholderStructure)
    }
    reuseIds(rawComponentExport, extensionAPI, dal, schemaService, pagePointer)
    importExport.pages.replace(pagePointer, rawComponentExport, {componentFeatures: 'All'})
}

export const exportPage = (pagePointer: CompRef, extensionAPI: ExtensionAPI, dal: DAL): WMLFiles | null => {
    if (!dal.has(pagePointer)) {
        return null
    }

    const {importExport} = extensionAPI as ImportExportExtensionApi
    return importExport.wml.exportPage(pagePointer, {componentFeatures: 'All'})
}
