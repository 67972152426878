import _ from 'lodash'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {SerializedCompStructure} from '@wix/document-services-types'
import type {SerializedStructureExtensionAPI} from '../serializedStructure'
import type {ComponentDefinitionExtensionAPI} from '../componentDefinition'
import {
    getSectionName,
    unsectionedOutlineSectionName,
    outlineCompIdSectionNameOverrides,
    ignoredContainerTypes,
    getContentForComponent,
    getNextIdForPrefix,
    updateDataItemValue,
    serializedRepeaterDataItemType,
    structureTypes,
    textComponentType,
    validateContentLengthInternal
} from './aiExtensionContent'
import type {ExtensionArgs} from './aiExtensionStructure'
import {ReportableError} from '@wix/document-manager-utils'
import {
    aiContentMissingOnOutlineErrorType,
    aiContentMissingOnOutlineMessage,
    aiContentTooLongErrorMessage,
    aiContentTooLongErrorType
} from './constants'
import type {FlatOutline, Outline, OutlineFieldMetadata} from './aiContentExtension'

const getSectionNameByStructure = (extensionAPI: ExtensionAPI, sectionStructure: SerializedCompStructure): string => {
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const anchorItem = serializedStructure.getComponentByNamespace(sectionStructure, 'anchor')
    return getSectionName(anchorItem)
}

const getOutlineContainerNameByStructure = (
    extensionAPI: ExtensionAPI,
    containerStructure: SerializedCompStructure,
    containerType: string
): string | undefined => {
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI

    if (componentDefinition.isSection(containerType)) {
        return getSectionNameByStructure(extensionAPI, containerStructure)
    }
    const overrideName = outlineCompIdSectionNameOverrides[serializedStructure.getComponentId(containerStructure)!]
    if (overrideName) {
        return overrideName
    } else if (componentDefinition.isPage(containerType)) {
        return 'page'
    }
}
const getOverrideId = (id: string, idSuffix: string) => (idSuffix ? `${id}_${idSuffix}` : id)
const getComponentsDataItemAndOverrides = (dataItem: Record<string, any>) => {
    const {original, overrides} = dataItem
    const overridesItems = Object.entries(overrides).map(([overrideId, item]) => ({item, overrideId}))
    return [{item: original, overrideId: ''}, ...overridesItems]
}

const MULTI_CHOICE_COUNT = 10
const MULTI_CHOICE_SECTION_NAMES = ['welcome']
const MULTI_CHOICE_FIELD_NAMES = ['title']

const getOutlineValueForMultiChoiceCharCount = (outlineKey: string, contentValue: string, metadataPrefix: string) => {
    const charCount = contentValue.length
    const outlineValueString = `length: ${charCount} characters`
    const outlineValue: Record<string, string> = {}

    for (let i = 0; i < MULTI_CHOICE_COUNT; i++) {
        const valueKey = `${outlineKey}_w_${i + 1}`
        outlineValue[valueKey] = `${metadataPrefix}${outlineValueString}`
    }

    return outlineValue
}

export const getContentRecursivelyForSerializedStructure = (
    extensionArgs: ExtensionArgs,
    compStructure: SerializedCompStructure,
    outline: Outline | FlatOutline,
    typeCounter: Record<string, number>,
    idMap: Record<string, string>,
    idsToKeep: Record<string, string>,
    structureType: string,
    metadataMap: Record<string, OutlineFieldMetadata>,
    unsectionedSectionName: string = unsectionedOutlineSectionName,
    outlineSectionName?: string | undefined
): void => {
    const {extensionAPI} = extensionArgs
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI

    const type = serializedStructure.getComponentType(compStructure)

    const isMultiChoiceField = (contentType: string) => {
        const charCountExp = extensionArgs.coreConfig.experimentInstance.isOpen('dm_aiSgCharCountOutline')
        const isSupportedSection = MULTI_CHOICE_SECTION_NAMES.includes(outlineSectionName ?? '')
        const isSupportedField = MULTI_CHOICE_FIELD_NAMES.includes(contentType)

        return charCountExp && isSupportedSection && isSupportedField
    }

    const getComponentOutlineValue = (contentValue: string, mappedId: string, contentType: string) => {
        const newPromptExp = extensionArgs.coreConfig.experimentInstance.isOpen('dm_aiSgNewOutlinePrompt')
        const isMultiChoice = isMultiChoiceField(contentType)

        if (newPromptExp || isMultiChoice) {
            const {listItemId} = serializedStructure.getComponentByNamespace(compStructure, 'contentRole') ?? {}
            const wordCount = contentValue.split(' ').length

            const metadataPrefix = _.isInteger(_.parseInt(listItemId)) ? `List item ${listItemId + 1}; ` : ''

            if (isMultiChoice) {
                metadataMap[mappedId] ??= {}
                metadataMap[mappedId].originalCharacterCount = contentValue.length
                metadataMap[mappedId].isMultiChoice = true
                return getOutlineValueForMultiChoiceCharCount(mappedId, contentValue, metadataPrefix)
            }

            if (newPromptExp) {
                return `${metadataPrefix}length: ${wordCount} words`
            }
        }

        return contentValue
    }

    const getContentAndUpdateOutline = (
        _outline: Outline | FlatOutline,
        dataItem: Record<string, any>,
        idSuffix: string = '',
        overrideFieldType?: string
    ) => {
        const content = getContentForComponent(type, dataItem)
        if (content) {
            content.type = overrideFieldType ?? content.type
            const mappedId = getNextIdForPrefix(content.type, typeCounter)

            const contentValue = getComponentOutlineValue(content.value, mappedId, content.type)

            const compId = serializedStructure.getComponentId(compStructure)!
            idMap[mappedId] = idSuffix ? getOverrideId(compId, idSuffix) : compId
            if (structureType === structureTypes.SECTION) {
                _outline[mappedId] = contentValue
            } else {
                const outlineSectionNameOrDefault = outlineSectionName ?? unsectionedSectionName
                _outline[outlineSectionNameOrDefault] ??= {}
                _outline[outlineSectionNameOrDefault][mappedId] = contentValue
            }
        }
    }
    if (ignoredContainerTypes.has(type)) {
        return
    }
    const id = serializedStructure.getComponentId(compStructure)
    if (!id) {
        serializedStructure.setComponentId(compStructure, generateItemIdWithPrefix('comp'))
    } else {
        idsToKeep[id] = id
    }
    if (componentDefinition.isContainer(type)) {
        const containerName = getOutlineContainerNameByStructure(extensionAPI, compStructure, type)
        const containerOutlineSectionName = containerName
            ? getNextIdForPrefix(containerName, typeCounter)
            : outlineSectionName
        const children = serializedStructure.getChildren(compStructure) ?? []
        for (const childStructure of children) {
            getContentRecursivelyForSerializedStructure(
                extensionArgs,
                childStructure as SerializedCompStructure,
                outline,
                typeCounter,
                idMap,
                idsToKeep,
                structureType,
                metadataMap,
                unsectionedSectionName,
                containerOutlineSectionName
            )
        }
    } else if (outlineSectionName) {
        let contentLabelOverride: string | undefined

        // for wrichtexts, prefer to use fieldName from contentRole to signify the outline parameter name (ie: subtitle instead of title/paragraph)
        const fieldRoleExp = extensionArgs.coreConfig.experimentInstance.isOpen('dm_aiSgNewOutlinePrompt')
        const isWRichText = serializedStructure.getComponentType(compStructure) === textComponentType

        if (fieldRoleExp && isWRichText) {
            const contentRoleItem = serializedStructure.getComponentByNamespace(compStructure, 'contentRole')
            contentLabelOverride = contentRoleItem?.fieldRole
        }

        const dataItem = serializedStructure.getComponentByNamespace(compStructure, 'data')
        if (dataItem && dataItem.type === serializedRepeaterDataItemType) {
            const dataItemsOverrides = getComponentsDataItemAndOverrides(dataItem)
            for (const dataItemOverride of dataItemsOverrides) {
                const idSuffix = dataItemOverride.overrideId
                getContentAndUpdateOutline(outline, dataItemOverride.item, idSuffix, contentLabelOverride)
            }
        } else {
            getContentAndUpdateOutline(outline, dataItem, '', contentLabelOverride)
        }
    }
}

const validateContentLengthForStructure = (
    extensionArgs: ExtensionArgs,
    componentType: string,
    dataItem: Record<string, any>,
    newContent: string
): boolean => {
    const {coreConfig} = extensionArgs
    const {logger} = coreConfig

    const currentContent = getContentForComponent(componentType, dataItem)?.value
    const res = validateContentLengthInternal(currentContent, newContent)
    if (!res) {
        logger.captureError(
            new ReportableError({
                errorType: aiContentTooLongErrorType,
                message: aiContentTooLongErrorMessage,
                extras: {
                    dataItem: JSON.stringify(dataItem),
                    componentType,
                    currentContent,
                    newContent
                }
            })
        )
    }
    return res
}

export const applyOutlinesToStructureInternal = (
    extensionArgs: ExtensionArgs,
    compStructure: SerializedCompStructure,
    section: any,
    compIdToName: Record<string, string>,
    idsToKeep: Record<string, string>
): void => {
    const {extensionAPI} = extensionArgs
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const {coreConfig} = extensionArgs
    const {logger} = coreConfig
    const updateContent = (id: string, dataItem: Record<string, any>) => {
        const fieldName = compIdToName[id]
        if (fieldName) {
            const content = section[fieldName]
            if (!content) {
                logger.captureError(
                    new ReportableError({
                        errorType: aiContentMissingOnOutlineErrorType,
                        message: aiContentMissingOnOutlineMessage,
                        extras: {
                            fieldName,
                            compStructure
                        }
                    })
                )
            }
            const componentType = serializedStructure.getComponentType(compStructure)
            if (content && validateContentLengthForStructure(extensionArgs, componentType, dataItem, content)) {
                updateDataItemValue(dataItem, componentType, content)
            }
        }
    }
    const dataItem = serializedStructure.getComponentByNamespace(compStructure, 'data')
    const compStructureId = serializedStructure.getComponentId(compStructure)

    if (dataItem && dataItem.type === serializedRepeaterDataItemType) {
        const dataItemsOverrides = getComponentsDataItemAndOverrides(dataItem)
        _.forEach(dataItemsOverrides, (dataItemOverride: Record<string, any>) => {
            const idSuffix = dataItemOverride.overrideId
            const idOnOutline = getOverrideId(compStructureId!, idSuffix)
            updateContent(idOnOutline, dataItemOverride.item)
        })
    } else {
        updateContent(compStructureId!, dataItem)
    }
    const children = compStructure.components ?? []
    const id = serializedStructure.getComponentId(compStructure)
    if (id && !idsToKeep[id]) {
        delete compStructure.id
    }
    if (_.isEmpty(children)) {
        return
    }
    for (const childStructure of children) {
        applyOutlinesToStructureInternal(
            extensionArgs,
            childStructure as SerializedCompStructure,
            section,
            compIdToName,
            idsToKeep
        )
    }
}

const handleMultiChoiceOutlineValue = (choices: string[], originalCharacterCount: number): string => {
    let closestChoice = choices[0]
    let closestChoiceLength = closestChoice.length

    for (const choice of Object.values(choices)) {
        const choiceCharCount = choice.length
        if (choiceCharCount === originalCharacterCount) {
            return choice
        }

        // prefer a negative difference of 1 over a positive difference of 1 to have better chances of the title fitting the element
        if (choiceCharCount === originalCharacterCount - 1) {
            closestChoice = choice
            closestChoiceLength = choiceCharCount
        }

        if (
            Math.abs(originalCharacterCount - choiceCharCount) < Math.abs(originalCharacterCount - closestChoiceLength)
        ) {
            closestChoice = choice
            closestChoiceLength = choiceCharCount
        }
    }

    return closestChoice
}

export const preprocessOutline = (
    flatOutline: FlatOutline,
    metadataMap: Record<string, OutlineFieldMetadata>
): void => {
    for (const [outlineName, fieldMetadata] of Object.entries(metadataMap)) {
        const {originalCharacterCount, isMultiChoice} = fieldMetadata
        if (isMultiChoice) {
            const choices = flatOutline[outlineName] as any as Record<string, string>
            flatOutline[outlineName] = handleMultiChoiceOutlineValue(Object.values(choices), originalCharacterCount!)
        }
    }
}
