import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import responsiveLayout from '../../responsiveLayout/responsiveLayout'
import * as experiment from 'experiment'
import componentStructureInfo from '../../component/componentStructureInfo'
import tpaUtils from '../../tpa/utils/tpaUtils'
import dataModel from '../../dataModel/dataModel'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import metaDataUtils from '../../componentsMetaData/metaDataUtils'

const getBasicDeltaX = (from: number, to: number) => (to - from) / 2
const isStrip = (type: string) => type === 'wysiwyg.viewer.components.StripColumnsContainer'
const isRef = (type: string) => type === 'wysiwyg.viewer.components.RefComponent'
const isSiteRegion = (type: string) => type === 'wysiwyg.viewer.components.SiteRegionContainer'
const isPlatformWidget = (type: string) => type === 'platform.components.AppWidget'
const isWidgetRef = (ps: PS, comp: Pointer) => {
    const compType = componentStructureInfo.getType(ps, comp)
    if (!isRef(compType)) return false

    const data = dataModel.getDataItem(ps, comp)
    return data.type === 'WidgetRef'
}

const shouldMigrateComponent = (ps: PS, compPointer: Pointer) => {
    if (!compPointer) return false

    const compType = componentStructureInfo.getType(ps, compPointer)
    return (
        isStrip(compType) ||
        isRef(compType) ||
        isSiteRegion(compType) ||
        tpaUtils.isTpaComp(ps, compPointer) ||
        isPlatformWidget(compType) ||
        metaDataUtils.isLegacyFullWidthContainer(ps, compPointer)
    )
}

const getDeltaXForColumnChildren = (ps: PS, column: Pointer, parentColumnsCount: number, baseDeltaX: number) => {
    const propQueryPointer = ps.pointers.getInnerPointer(column, 'propertyQuery')
    const propQuery = ps.dal.get(propQueryPointer)
    const propPointer = ps.pointers.data.getPropertyItem(propQuery)
    const {alignment} = ps.dal.get(propPointer)
    let deltaXByAligment = 0 // alignment === 0

    if (alignment === 50) {
        deltaXByAligment = baseDeltaX / parentColumnsCount
    }

    if (alignment === 100) {
        deltaXByAligment = (baseDeltaX * 2) / parentColumnsCount
    }

    return Math.round(deltaXByAligment)
}

const handleStripMigration = (ps: PS, strip: Pointer, from: number, to: number): void => {
    const columns = ps.pointers.components.getChildren(strip)
    const baseDeltaX = getBasicDeltaX(from, to)

    setCompWidth(ps, strip, to)

    columns?.forEach(column => {
        const deltaXByAligment = getDeltaXForColumnChildren(ps, column, columns.length, baseDeltaX)
        const columnChildern = ps.pointers.components.getChildren(column)

        columnChildern?.forEach(child => {
            if (componentsMetaData.public.isHorizontallyMovable(ps, child)) {
                shiftComponentByX(ps, child, deltaXByAligment)
            }

            if (shouldMigrateComponent(ps, child)) {
                return convertCompToNewSiteWidth(ps, child, column, from, to)
            }
        })
    })
}

const shiftComponentByX = (ps: PS, compPointer: Pointer, deltaX: number) => {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.full.get(layoutPointer)
    const x = layout.x ?? 0
    layout.x = x + deltaX
    ps.dal.set(layoutPointer, layout)

    if (experiment.isOpen('dm_meshLayout')) {
        const oldResponsiveLayout = responsiveLayout.get(ps, compPointer)
        const newResponsiveLayout = {
            ...oldResponsiveLayout,
            itemLayout: {
                ...oldResponsiveLayout.itemLayout,
                meshData: {
                    ...oldResponsiveLayout.itemLayout.meshData,
                    x: oldResponsiveLayout.itemLayout.meshData.x + deltaX
                }
            }
        }
        responsiveLayout.update(ps, compPointer as CompRef, newResponsiveLayout)
    }
}

const setCompWidth = (ps: PS, compPointer: Pointer, width: number) => {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.full.get(layoutPointer)
    layout.width = width
    ps.dal.set(layoutPointer, layout)
}

const isFitPage = (ps: PS, compPointer: Pointer, pageWidth: number) => {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.full.get(layoutPointer)
    return layout.width === pageWidth && layout.x === 0
}

export const convertCompToNewSiteWidth = (ps: PS, compPointer: Pointer, parent: Pointer, from: number, to: number) => {
    const isRootComponent = !parent
    const shouldSkipMigration = isRootComponent && !shouldMigrateComponent(ps, compPointer)

    if (shouldSkipMigration) return

    const compType = componentStructureInfo.getType(ps, compPointer)
    const basicDeltaX = getBasicDeltaX(from, to)

    if (isStrip(compType)) return handleStripMigration(ps, compPointer, from, to)

    const isAppWidget =
        tpaUtils.isTpaComp(ps, compPointer) ||
        isWidgetRef(ps, compPointer) ||
        isSiteRegion(compType) ||
        isPlatformWidget(compType)

    const shouldShiftByX =
        isRootComponent &&
        isAppWidget &&
        isFitPage(ps, compPointer, from) &&
        componentsMetaData.public.isHorizontallyMovable(ps, compPointer)

    if (shouldShiftByX) shiftComponentByX(ps, compPointer, basicDeltaX)

    const shouldTraverseChildren = !isAppWidget

    if (shouldTraverseChildren) {
        ps.pointers.components.getChildren(compPointer)?.forEach(child => {
            if (componentsMetaData.public.isHorizontallyMovable(ps, child)) {
                shiftComponentByX(ps, child, basicDeltaX)
            }

            if (shouldMigrateComponent(ps, child)) {
                return convertCompToNewSiteWidth(ps, child, compPointer, from, to)
            }
        })
    }
}
