import type {
    ComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginContext,
    PluginHeuristicsRegistry
} from '../../types'
import {widthHeuristics} from './widthHeuristic'
import type {ComponentToMeasurementsWidth, Heuristic, ScalingApiPlugin} from './types'
import {CTX_WIDTH_SCALING_RESULT} from './constants'
import {getMeasurementsPointer} from '../../conversionDal/pointerUtils'
import type {MeasurementsEntryItem} from '../../conversionDal/types'

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const writeComponentScaling = ({componentPointer, conversionDal, initialSnapshot}: ComponentStageData) => {
        const compType = conversionDal.get(componentPointer).semanticType
        const widthHeuristic: Heuristic = widthHeuristics.find(heuristic => heuristic.predicate(compType))!
        const horizontalMeasurements = widthHeuristic.run(conversionDal, componentPointer, initialSnapshot)

        conversionDal.modify(getMeasurementsPointer(componentPointer), (value: MeasurementsEntryItem) => ({
            ...value,
            ...horizontalMeasurements
        }))
    }

    const createApi = (context: PluginContext): ScalingApiPlugin => {
        return {
            widthScalingResult: (): ComponentToMeasurementsWidth => {
                return context.get(CTX_WIDTH_SCALING_RESULT)
            }
        }
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.SCALE, writeComponentScaling)
    }

    const name = 'horizontalScaleAndSize'
    return {register, name, dependencies: [], createApi}
}
