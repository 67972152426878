import type {DAL, DocumentManager} from '@wix/document-manager-core'
import type {SchemaExtensionAPI} from '../../../schema/schema'
import * as refArrayUtils from '../../../../utils/refArrayUtils'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {cleanBrokenVariantRelations, queryPointers, ShouldRemoveScopedItem} from './utils/variantRelationUtils'
import type {PageMigrator} from '../dataMigrationRunner'

interface MigrationConfig {
    namespace: string
    shouldRemoveScopedItem: ShouldRemoveScopedItem
}

const getRefArraysFromNamespace = (dal: DAL, ns: string, pageId: string): Pointer[] =>
    queryPointers(dal, ns, pageId, refArrayUtils.refArray.isRefArray)

const getValuesToRefArrayMap = (documentManager: DocumentManager, namespace: string, pageId: string) => {
    const refArrayPointers = getRefArraysFromNamespace(documentManager.dal, namespace, pageId)
    return _.reduce(
        refArrayPointers,
        (acc, pointer) => {
            const refArray = documentManager.dal.get(pointer)
            const values = refArrayUtils.refArray.extractValuesWithoutHash(refArray)
            _.forEach(values, dataId => {
                acc[dataId] = refArray.id
            })
            return acc
        },
        {}
    )
}

const migrateNamespace = (
    documentManager: DocumentManager,
    namespace: string,
    shouldRemoveScopedItem: ShouldRemoveScopedItem,
    pageId: string
): void => {
    const valuesToRefArrayMap = getValuesToRefArrayMap(documentManager, namespace, pageId)
    cleanBrokenVariantRelations(documentManager, namespace, pageId, valuesToRefArrayMap, shouldRemoveScopedItem)
}

const shouldRemoveScopedStyle = (documentManager: DocumentManager, styleId: string): boolean => {
    const {schemaAPI} = documentManager.extensionAPI as SchemaExtensionAPI
    return !schemaAPI.isSystemStyle(styleId)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const configs: MigrationConfig[] = [
        {namespace: 'style', shouldRemoveScopedItem: _.partial(shouldRemoveScopedStyle, documentManager)},
        {namespace: 'transformations', shouldRemoveScopedItem: _.stubTrue}
    ]
    configs.forEach(({namespace, shouldRemoveScopedItem}) => {
        migrateNamespace(documentManager, namespace, shouldRemoveScopedItem, pageId)
    })
}

const name = 'removeBrokenVariantRelations'
const version = 1

export const removeBrokenVariantRelations: PageMigrator = {
    migratePage,
    name,
    version
}
