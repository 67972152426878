import {COMPONENTS_HOOKS} from '../hooks'
import {COMP_TYPES, PAGE_COME_TYPES} from '../../../constants/constants'
import type {HooksExtensionApi} from '../../hooks/hooks'
import type {CreateExtArgs} from '@wix/document-manager-core'

import {handleAppWidgetAddition} from './appWidgetHooks'
import {handleControllerAddition} from './appControllerHooks'
import {duplicateLinksDataItems} from './wRichTextHooks'
import {setPlayerIdAfterAddHook} from './mediaPlayerHooks'
import {updateSerializedConnectionsDataForComponents} from './connectionsHooks'
import {handleDataOverridesAfterAdd} from './referredComponentsHooks'

import {mapHiddenAnchorsForAnchorsMenu, updateSerializedConnectionsDataForPage} from './pageHooks'
import {anchorBeforeAddHook} from './anchorHooks'
import {unDockNonDirectPageChildren} from './pinnedElementHooks'
import {verifySlideShowStructureOnAdd} from './slideShowHooks'
import {removeBehaviorsFromAddedPage} from './popupPageHooks'
import {convertToMeshAfterAddHook} from './meshLayoutHooks'
export const registerComponentHooks = (createExtArgs: CreateExtArgs) => {
    const {extensionAPI} = createExtArgs
    const {hooks} = extensionAPI as HooksExtensionApi
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        handleControllerAddition,
        COMP_TYPES.CONTROLLER_TYPE
    )
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        handleAppWidgetAddition,
        COMP_TYPES.APP_WIDGET_TYPE
    )

    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        setPlayerIdAfterAddHook,
        COMP_TYPES.MEDIA_OVERLAY_CONTROLS
    )
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        setPlayerIdAfterAddHook,
        COMP_TYPES.MEDIA_CONTROLS
    )
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        duplicateLinksDataItems,
        COMP_TYPES.WRICHTEXT
    )

    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        handleDataOverridesAfterAdd,
        COMP_TYPES.REF_TYPE
    )
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        removeBehaviorsFromAddedPage,
        COMP_TYPES.PAGE_TYPE
    )
    PAGE_COME_TYPES.forEach((pageCompType: string) => {
        hooks.registerHookWithExtensionArgs(
            COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
            mapHiddenAnchorsForAnchorsMenu,
            pageCompType
        )
    })
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        updateSerializedConnectionsDataForPage,
        COMP_TYPES.PAGE_TYPE
    )

    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.BEFORE.id,
        anchorBeforeAddHook,
        COMP_TYPES.ANCHOR
    )

    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.BEFORE.id,
        updateSerializedConnectionsDataForComponents
    )

    hooks.registerHookWithExtensionArgs(COMPONENTS_HOOKS.ADD_COMPONENT.BEFORE.id, unDockNonDirectPageChildren)

    for (const type of [
        'wysiwyg.viewer.components.StateBox',
        'wysiwyg.viewer.components.StateStrip',
        'wysiwyg.viewer.components.BoxSlideShow',
        'wysiwyg.viewer.components.StripContainerSlideShow'
    ]) {
        hooks.registerHookWithExtensionArgs(
            COMPONENTS_HOOKS.ADD_COMPONENT.BEFORE.id,
            verifySlideShowStructureOnAdd,
            type
        )
    }
    hooks.registerHookWithExtensionArgs(COMPONENTS_HOOKS.ADD_ROOT.AFTER.id, convertToMeshAfterAddHook)
}
