/* eslint-disable promise/prefer-await-to-then */
import type {Callback1, Pointer, Preset, PS, ValueOf} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import DSErrors from '../errors/errors'
import page from '../page/page'
import responsiveLayout from '../responsiveLayout/responsiveLayout'
import saveRunner from '../saveAPI/lib/saveRunner'
import saveState from '../saveAPI/lib/saveState'
import utils from '../utils/utils'
import variants from '../variants/variants'
import appStudioAppDataUtils from './appStudioAppDataUtils'
import appStudioDataModel from './appStudioDataModel'
import appStudioPanels from './appStudioPanels'
import appStudioPresets from './appStudioPresets'
import manifestUtils from './manifestUtils'
import wixBlocksService from './services/wixBlocksService'
import controllerConfig from './controllerConfig'
import widgetDescriptors from './widgetDescriptors'

const {
    INVALID_VERSION_TYPE,
    NO_GRID_APP_ID,
    NO_STUDIO_APP_APP_ID,
    USER_NOT_AUTHORIZED_FOR_APP,
    UNKNOWN_SERVER_ERROR,
    NO_APP_DEF_ID,
    NO_BUILD_ID,
    NO_PACKAGE_IMPORT_NAME
} = DSErrors.build

const {SAVE_DISABLED_IN_DOCUMENT_SERVICES, SESSION_EXPIRED, NOT_LOGGED_IN, USER_NOT_AUTHORIZED_FOR_SITE} = DSErrors.save

const setAppWidgetSettings = (ps: PS, widgetPageId: string, devCenterWidgetId: string, isVariation?: boolean) => {
    const pagePointer = page.getPage(ps, widgetPageId)
    const appWidget = appStudioDataModel.getRootAppWidgetByPage(ps, pagePointer)
    controllerConfig.update(ps, appWidget, {devCenterWidgetId})
    if (isVariation) {
        controllerConfig.update(ps, appWidget, {variationPageId: widgetPageId})
    }
}

const setWidgetRootsSettings = (ps: PS, widget, widgetPageId: string, devCenterWidgetId: string) => {
    setAppWidgetSettings(ps, widgetPageId, devCenterWidgetId)

    const serializedWidget = appStudioDataModel.serializeWidget(ps, widget.pointer)

    _.forEach(serializedWidget.variations, variation => {
        setAppWidgetSettings(ps, utils.stripHashIfExists(variation.rootCompId), devCenterWidgetId, true)
    })
}

const setDevCenterWidgetIds = (ps: PS, devCenterIdsMap) => {
    _.forEach(devCenterIdsMap, (devCenterWidgetId, widgetPageId) => {
        const widget = appStudioDataModel.findWidgetByPageId(ps, widgetPageId)
        appStudioDataModel.setWidgetDevCenterId(ps, widget.pointer, devCenterWidgetId)
        setWidgetRootsSettings(ps, widget, widgetPageId, devCenterWidgetId)
    })
}

const updatePresetsDefaultSize = (ps: PS) => {
    const widgets = appStudioDataModel.getAllWidgets(ps)
    widgets.forEach(({pointer: widgetPointer}) => {
        const rootContainer = appStudioDataModel.getRootContainerByWidgetPointer(ps, widgetPointer)

        const layout = responsiveLayout.get(ps, rootContainer)

        const widgetMinHeight = _.get(
            layout,
            ['componentLayouts', 0, 'minHeight', 'value'],
            _.get(layout, ['componentLayout', 'minHeight', 'value'])
        )

        const presets = appStudioPresets.getWidgetPresets(ps, widgetPointer)

        presets.forEach(({pointer: presetPointer}) => {
            const defaultSize = appStudioPresets.getPresetDefaultSize(ps, presetPointer) || {}

            const vp = appStudioPresets.getPresetVariantPointer(ps, presetPointer, widgetPointer)
            const cvp = variants.getPointerWithVariants(ps, rootContainer, vp)

            const presetStageContainerLayout = responsiveLayout.get(ps, cvp)
            const presetMinHeight = _.get(
                presetStageContainerLayout,
                ['componentLayout', 'minHeight', 'value'],
                widgetMinHeight
            )

            const newSize = _.defaultsDeep({height: {value: presetMinHeight, type: 'px'}}, defaultSize)
            appStudioPresets.setPresetDefaultSize(ps, presetPointer, newSize)
        })
    })
}

const getExistingWidgets = (ps: PS) => {
    const widgets = appStudioDataModel.getAllSerializedWidgets(ps)
    return widgets.map(widget => ({
        name: widget.name,
        id: utils.stripHashIfExists(widget.rootCompId)
    }))
}

const updatePanelsRootData = async (ps: PS, appDefinitionId: string) => {
    const appData = experiment.isOpen('dm_doNotFetchAppDataInBlocksPreBuild')
        ? undefined
        : await appStudioAppDataUtils.fetchAppData(ps, appDefinitionId)
    appStudioPanels.updatePanelsRootData(ps, {appData})
}

const preBuild = async (ps: PS, appDefinitionId: string, {onSuccess = _.noop, onError = _.noop} = {}) => {
    appStudioDataModel.updateWidgetContainedWidgets(ps)
    updatePresetsDefaultSize(ps)

    await Promise.all([
        updatePanelsRootData(ps, appDefinitionId),
        experiment.isOpen('dm_ignoreBlocksPreBuild')
            ? () => {}
            : wixBlocksService.preBuild(ps, appDefinitionId, getExistingWidgets(ps)).then(({devCenterWidgetIdsMap}) => {
                  setDevCenterWidgetIds(ps, devCenterWidgetIdsMap)
              })
    ])
        .then(() => {
            onSuccess()
        })
        .catch(onError)
}

const BUILD_ERROR_TYPES = {
    '-12': SESSION_EXPIRED,
    '-15': NOT_LOGGED_IN,
    '-17': USER_NOT_AUTHORIZED_FOR_SITE,
    '-10198': NO_GRID_APP_ID,
    '-15053': NO_STUDIO_APP_APP_ID,
    '-15054': USER_NOT_AUTHORIZED_FOR_APP
}

const getBuildError = ({errorCode, errorDescription, errorType = UNKNOWN_SERVER_ERROR, applicationError = undefined}) =>
    new BuildError({
        errorCode,
        errorDescription,
        errorType: BUILD_ERROR_TYPES[errorCode] || errorType,
        applicationError
    })

const VERSION_TYPES = {
    minor: 'minor',
    major: 'major',
    build: 'build',
    test: 'test'
} as const

export type VersionType = ValueOf<typeof VERSION_TYPES>

function isVersionTypeValid(versionType: VersionType) {
    return !!VERSION_TYPES[versionType]
}

function convertPresetInfoToMatchProtoDefinition(ps: PS, presetPointer: Pointer) {
    const presetName = appStudioPresets.getPresetName(ps, presetPointer)
    const presetId = appStudioPresets.getPresetVariantId(ps, presetPointer)
    const defaultSize: Preset['defaultSize'] = appStudioPresets.getPresetDefaultSize(ps, presetPointer)

    return {
        name: presetName,
        presetId,
        defaultSize: {
            width: defaultSize?.width && {
                value: Math.round(defaultSize.width.value),
                type: defaultSize.width.type.toUpperCase()
            },
            height: defaultSize?.height && {
                value: Math.round(defaultSize.height.value),
                type: defaultSize.height.type.toUpperCase()
            }
        }
    }
}

class BuildError extends Error {
    public errorCode: string
    public errorType: string
    public errorDescription: string
    public applicationError: string

    constructor(error) {
        super('Build Error')
        this.errorCode = error.errorCode
        this.errorType = error.errorType
        this.errorDescription = error.errorDescription
        this.applicationError = error.applicationError
    }
}

function getWidgetsMapForBuild(ps: PS) {
    if (experiment.isOpen('dm_useBlocksExtensionGetWidgetsMapForBuild')) {
        return ps.extensionAPI.blocks.getWidgetsMapForBuild()
    }
    const allWidgets = appStudioDataModel.getAllWidgets(ps)
    const containingWidgetsMap = _.mapValues(appStudioDataModel.getContainingWidgetsMap(ps), (widgetIds: string[]) =>
        widgetIds.map(widgetId => {
            const pointer = appStudioDataModel.getDescriptorPointerById(ps, widgetId)
            return appStudioDataModel.getWidgetDevCenterId(ps, pointer)
        })
    )

    const containingWidgetsPageIdsMap =
        experiment.isOpen('dm_passNestedWidgetsPageIdsMapForBuild') &&
        _.mapValues(appStudioDataModel.getContainingWidgetsMap(ps), (widgetIds: string[]) =>
            widgetIds.map(widgetId => {
                const pointer = appStudioDataModel.getDescriptorPointerById(ps, widgetId)
                return appStudioDataModel.getRootCompIdByPointer(ps, pointer)
            })
        )

    return _.reduce(
        allWidgets,
        (accumulator, {pointer, variations, name, plugin}) => {
            const widgetRootCompId = appStudioDataModel.getRootCompIdByPointer(ps, pointer)
            const variationRootCompIds = _.map(variations, variationId => {
                const variationPointer = ps.pointers.data.getDataItemFromMaster(_.replace(variationId, '#', ''))
                return appStudioDataModel.getRootCompIdByPointer(ps, variationPointer)
            })
            const manifestInfo = manifestUtils.getWidgetManifest(ps, pointer)
            const widgetPublicDescriptor = widgetDescriptors.createPublicDescriptor(ps, pointer)
            const presets = appStudioPresets.getWidgetPresets(ps, pointer)
            const presetsInfo = presets.map(preset => convertPresetInfoToMatchProtoDefinition(ps, preset.pointer))

            accumulator[widgetRootCompId] = {
                widgetId: widgetRootCompId,
                variations: variationRootCompIds,
                manifestInfo,
                widgetPublicDescriptor,
                name,
                nestedWidgetsIds: containingWidgetsMap[pointer.id] ?? []
            }

            if (containingWidgetsPageIdsMap) {
                accumulator[widgetRootCompId].nestedWidgetsPageIds = containingWidgetsPageIdsMap[pointer.id] ?? []
            }

            if (experiment.isOpen('dm_sendPresetInfoInBlocksBuild')) {
                accumulator[widgetRootCompId].presetsInfo = presetsInfo
            }

            accumulator[widgetRootCompId].plugin = plugin

            return accumulator
        },
        {}
    )
}

function getCodePackageData(ps: PS, packageImportName: string) {
    const codePackageData: any = _.head(appStudioDataModel.getAppStudioData(ps)?.codePackages)
    if (!codePackageData) {
        return undefined
    }

    if (!packageImportName) {
        throw getBuildError({
            errorCode: '-1',
            errorDescription: 'packageImportName must being passed',
            errorType: NO_PACKAGE_IMPORT_NAME
        })
    }

    return {
        packageDisplayName: codePackageData.displayName,
        packageImportName,
        description: codePackageData.description
    }
}

function performBuild(ps: PS, options) {
    const {
        versionType,
        appMarketingName,
        blocksVersion,
        appDefId,
        isAsyncBuild,
        releaseNotes,
        publishRc,
        packageImportName
    } = options

    return new Promise((resolve, reject) => {
        if (!saveState.canSave(ps)) {
            reject(
                getBuildError({
                    errorCode: '-1',
                    errorDescription:
                        'DocumentServices was created with parameter disableSave=true, so build is disabled.',
                    errorType: SAVE_DISABLED_IN_DOCUMENT_SERVICES
                })
            )
            return
        }
        const widgetsMap = getWidgetsMapForBuild(ps)

        const codePackage = getCodePackageData(ps, packageImportName)

        wixBlocksService.postBuildRequest(
            ps,
            {
                widgetsMap,
                appMarketingName,
                blocksVersion,
                versionType,
                codePackage,
                appDefId,
                isAsyncBuild,
                releaseNotes,
                publishRc
            },
            resolve,
            reject,
            getBuildError
        )
    })
}

function buildWithOptions(
    ps: PS,
    options: {
        onSuccess?: Callback1<any>
        onError?: Callback1<any>
        versionType: VersionType
        appDefId: string
        appMarketingName?: string
        blocksVersion?: string
        isAsyncBuild?: boolean
        releaseNotes?
        publishRc?: boolean
    }
) {
    const {onSuccess, onError, versionType, appDefId} = options

    if (!appDefId) {
        onError(
            getBuildError({
                errorCode: '-1',
                errorDescription: 'appDefId must being passed',
                errorType: NO_APP_DEF_ID
            })
        )
        return
    }

    if (isVersionTypeValid(versionType)) {
        saveRunner.runFunctionInSaveQueue(_.partial(performBuild, ps, options)).then(onSuccess, onError)
    } else {
        onError(
            getBuildError({
                errorCode: '-1',
                errorDescription: 'Version type should be one of: "build", "minor" "major".',
                errorType: INVALID_VERSION_TYPE
            })
        )
    }
}

function build(
    ps: PS,
    onSuccess,
    onError,
    versionType: VersionType,
    appMarketingName: string,
    blocksVersion = '0.0.1',
    appDefId?: string,
    isAsyncBuild?: boolean,
    releaseNotes?,
    publishRc?: boolean
) {
    const options = {
        onSuccess,
        onError,
        versionType,
        appMarketingName,
        blocksVersion,
        appDefId,
        isAsyncBuild,
        releaseNotes,
        publishRc
    }

    buildWithOptions(ps, options)
}

async function getBuildStatusById(ps: PS, buildId: string, appDefinitionId: string) {
    if (!buildId) {
        throw getBuildError({
            errorCode: '-1',
            errorDescription: 'buildId must being passed',
            errorType: NO_BUILD_ID
        })
    }

    if (!appDefinitionId) {
        throw getBuildError({
            errorCode: '-1',
            errorDescription: 'appDefId must being passed',
            errorType: NO_APP_DEF_ID
        })
    }

    return saveRunner.runFunctionInSaveQueue(() =>
        wixBlocksService.performGetBuildById(ps, buildId, appDefinitionId, getBuildError)
    )
}

export default {
    preBuild,
    buildWithOptions,
    build,
    getBuildStatusById
}
