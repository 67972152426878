/**
 * @param {string} selector
 * @param {string} prop
 * @param {boolean} removePrefix
 */
function createSelectorKey(selector, prop, removePrefix) {
    if (removePrefix) {
        return selector.includes('-') ? handleSelectorContainState(selector, prop) : prop
    }
    return `${selector}-${prop}`
}

/**
 * @param {string} selector
 * @param {string} prop
 */
function handleSelectorContainState(selector, prop) {
    const parts = selector.split('-')
    const state = parts[1]
    return `${state}-${prop}`
}

/**
 * @param {string} selector
 * @param {Array<import('./index').Property>} properties
 */
function flattenProperties(selector, properties = [], removePrefix) {
    return properties.reduce((acc, property) => {
        const key = property.absolutePropName
            ? property.absolutePropName
            : createSelectorKey(selector, property.prop, removePrefix)
        return {
            ...acc,
            [key]: property.value
        }
    }, {})
}

/**
 * @param {import('./index').ParsedStyleItem['parsedStyle'][0]['propertiesOverride']} propertiesOverride
 */
function flattenPropertiesOverride(selector, propertiesOverride = {}, removePrefix) {
    return Object.entries(propertiesOverride).reduce((flattenedPropertiesOverride, [prop, override]) => {
        const key = createSelectorKey(selector, prop, removePrefix)
        return {
            ...flattenedPropertiesOverride,
            [key]: override
        }
    }, {})
}

/**
 * @param {string} selector
 * @param {import('./index').ParsedStyleItem['parsedStyle'][0]['propertiesSource']} propertiesSource
 */
function flattenPropertiesSource(selector, propertiesSource = {}, removePrefix) {
    return Object.entries(propertiesSource).reduce((flattenedPropertiesSource, [prop, source]) => {
        const key = createSelectorKey(selector, prop, removePrefix)
        return {
            ...flattenedPropertiesSource,
            [prop.includes(selector) ? prop : key]: source
        }
    }, {})
}

/**
 * @param {import('./index').ParsedStyleItem['parsedStyle']} parsedStyle
 */
function flattenParsedStyle(parsedStyle) {
    return Object.entries(parsedStyle).reduce(
        (
            flattenedParsedStyle,
            [
                selector,
                {
                    mobileProperties,
                    properties,
                    mobilePropertiesOverride,
                    propertiesOverride,
                    propertiesSource,
                    removePrefix
                }
            ]
        ) => {
            return {
                properties: {
                    ...flattenedParsedStyle.properties,
                    ...flattenProperties(selector, properties, removePrefix)
                },
                mobileProperties: {
                    ...flattenedParsedStyle.mobileProperties,
                    ...flattenProperties(selector, mobileProperties, removePrefix)
                },
                propertiesOverride: {
                    ...flattenedParsedStyle.propertiesOverride,
                    ...flattenPropertiesOverride(selector, propertiesOverride, removePrefix)
                },
                mobilePropertiesOverride: {
                    ...flattenedParsedStyle.mobilePropertiesOverride,
                    ...flattenPropertiesOverride(selector, mobilePropertiesOverride, removePrefix)
                },
                propertiesSource: {
                    ...flattenedParsedStyle.propertiesSource,
                    ...flattenPropertiesSource(selector, propertiesSource, removePrefix)
                }
            }
        },
        {
            properties: {},
            mobileProperties: {},
            propertiesOverride: {},
            mobilePropertiesOverride: {},
            propertiesSource: {}
        }
    )
}

/**
 *
 * @param {import('./index').ParsedStyleItem} migratedStyleItem
 * @returns {import('./index').FlattenedMigratedStyleItem}
 */
function getFlattenedMigratedStyle(migratedStyleItem) {
    return {
        ...migratedStyleItem,
        ...flattenParsedStyle(migratedStyleItem.parsedStyle)
    }
}

/**
 * @param {import('./index').ParsedStyleItem} migratedStyleItem
 * @param {{isMobile?: boolean, postStyleMigrationCallback?: Function}} options
 * @returns {import('./index').StyleItemWithStyleProperties['style']}
 */
function migratedStyleToStyleProperties(migratedStyleItem, options = {}) {
    const {isMobile = false, postStyleMigrationCallback} = options
    const flattenedStyle = getFlattenedMigratedStyle(migratedStyleItem)
    const composedStyle = {
        properties: isMobile ? flattenedStyle.mobileProperties : flattenedStyle.properties,
        propertiesOverride: isMobile ? flattenedStyle.mobilePropertiesOverride : flattenedStyle.propertiesOverride,
        groups: {},
        propertiesSource: flattenedStyle.propertiesSource
    }

    if (postStyleMigrationCallback) {
        return postStyleMigrationCallback(composedStyle)
    }

    return composedStyle
}

module.exports = {
    migratedStyleToStyleProperties
}
