import _ from 'lodash'

export const deepFind = <T extends Record<string, T>>(obj: T, iteratee: (o: Object) => T) => {
    if (_.isPlainObject(obj)) {
        return _.find(_.values(obj), value => _.cloneDeepWith(value, iteratee))
    }
    if (_.isArray(obj)) {
        return _.find(obj, value => _.cloneDeepWith(value, iteratee))
    }
    return iteratee(obj)
}
