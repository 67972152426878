import {DAL, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
const {isRefPointer, getReferredCompId} = displayedOnlyStructureUtil
import {PAGE_DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} from '../constants/constants'

const {getPointer} = pointerUtils

const REF_SUFFIX = '-ref'

const RefDelimiter = '_r_'

// examples:
// comp-xxxxxxx-dataQuery__item1 => comp-xxxxxxx__item1
// comp-xxxxxxx-styleId-presets-l9o1a0b81-style => comp-xxxxxxx
const ANY_BULLSHIT_THAT_IS_NOT_A_REPEATER = '[^_]*'
const OVERRIDE_DATA_PATTERNS = _.mapValues(
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    query => new RegExp(`-${query}${ANY_BULLSHIT_THAT_IS_NOT_A_REPEATER}`)
)

const extractBaseComponentByNamespace = (id: string, namespace: string) => {
    const pattern = OVERRIDE_DATA_PATTERNS[namespace]
    if (_.isNil(pattern)) {
        throw new Error('Unkown data type')
    }
    return _.replace(id, pattern, '')
}
const extractBaseComponentId = (dataItemPointer: Pointer) => {
    return extractBaseComponentByNamespace(dataItemPointer.id, dataItemPointer.type)
}

const isRefHostType = (type: string): boolean => type === 'wysiwyg.viewer.components.RefComponent'

const isRefHost = (pointer: Pointer, dal: DAL): boolean => isRefHostType(dal.getWithPath(pointer, ['componentType']))

const STRUCTURE_TYPE_TO_REF_TYPE_MAP = {
    DESKTOP: `DESKTOP${REF_SUFFIX}`,
    MOBILE: `MOBILE${REF_SUFFIX}`
}

const DATA_TYPE_TO_REF_TYPE_MAP = _.mapValues(PAGE_DATA_TYPES, (v, k) => `${k}${REF_SUFFIX}`)

const TYPE_TO_REF_TYPE_MAP = {...STRUCTURE_TYPE_TO_REF_TYPE_MAP, ...DATA_TYPE_TO_REF_TYPE_MAP}

const structureRefTypes = _.values(STRUCTURE_TYPE_TO_REF_TYPE_MAP)
const dataRefTypes = _.values(DATA_TYPE_TO_REF_TYPE_MAP)

const getRefPointerType = (pointer: Pointer): Pointer => {
    const refType = TYPE_TO_REF_TYPE_MAP[pointer.type]

    if (refType) {
        return {...pointer, type: refType}
    }

    return pointer
}

export interface PointerNoRef extends Pointer {
    noRefFallbacks: boolean
}

const getPointerWithoutFallbacksFromPointer = (pointer: Pointer): PointerNoRef => ({...pointer, noRefFallbacks: true})

const getPointerWithoutFallbacks = (id: string, type: string): PointerNoRef =>
    getPointerWithoutFallbacksFromPointer(getPointer(id, type))

const createInflatedRefId = (compRefId: string, rootCompId: string) => `${compRefId}${RefDelimiter}${rootCompId}`

export {
    REF_SUFFIX,
    RefDelimiter,
    isRefHostType,
    isRefHost,
    getRefPointerType,
    isRefPointer,
    getReferredCompId,
    structureRefTypes,
    getPointerWithoutFallbacksFromPointer,
    getPointerWithoutFallbacks,
    dataRefTypes,
    extractBaseComponentId,
    extractBaseComponentByNamespace,
    createInflatedRefId
}
