import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Panel, Pointer, Preset, ScopeMetaDataTemplate} from '@wix/document-services-types'
import {getBlocksComponentNickname, getDataFromMaster, getWidgetDataByRefComp, WidgetData} from './blocksDataModel'
import {getComponentType} from '../../utils/dalUtils'
import {COMP_TYPES, DATA_TYPES} from '../../constants/constants'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'

type ComponentsMetaData = NonNullable<ScopeMetaDataTemplate['components']>
type InnerWidgetsMetaData = NonNullable<ScopeMetaDataTemplate['innerWidgets']>
type SlotsMetaData = NonNullable<ScopeMetaDataTemplate['slots']>
type ComponentsData = NonNullable<ScopeMetaDataTemplate['data']>

interface WidgetComponentsMetaData {
    components: ComponentsMetaData
    innerWidgets: InnerWidgetsMetaData
    slots: SlotsMetaData
    data?: ComponentsData
}

const getInnerWidgetMetaData = (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
    const widgetData = getWidgetDataByRefComp(createExtArgs, compPointer)

    if (widgetData) {
        return {
            widgetId: widgetData.devCenterWidgetId,
            compId: compPointer.id,
            rootCompId: widgetData.rootCompId
        }
    }
}

const getSlotMetaData = ({extensionAPI}: CreateExtArgs, compPointer: Pointer) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const slotData = dataModel.components.getItem(compPointer, DATA_TYPES.data)

    return {
        compId: compPointer.id,
        interfaces: slotData ? slotData.interfaces : []
    }
}

export const getWidgetComponentsMetadata = (
    createExtArgs: CreateExtArgs,
    rootWidget: Pointer,
    includeCompData: boolean
): WidgetComponentsMetaData => {
    const children = createExtArgs.pointers.structure.getChildrenRecursively(rootWidget)

    return children.reduce(
        (acc: WidgetComponentsMetaData, comp) => {
            const {dal} = createExtArgs
            const componentType = getComponentType(dal, comp)

            const role = getBlocksComponentNickname(createExtArgs, comp)

            acc.components[comp.id] = {
                role,
                componentType
            }
            if (includeCompData) {
                const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI
                acc.data![comp.id] = dataModel.components.getItem(comp, DATA_TYPES.data)
            }

            switch (componentType) {
                case COMP_TYPES.REF_TYPE: {
                    const innerWidgetMetaData = getInnerWidgetMetaData(createExtArgs, comp)

                    if (innerWidgetMetaData) {
                        acc.innerWidgets.push(innerWidgetMetaData)
                    }

                    break
                }
                case COMP_TYPES.SLOT_PLACEHOLDER: {
                    acc.slots.push(getSlotMetaData(createExtArgs, comp))
                    break
                }
            }

            return acc
        },
        {components: {}, innerWidgets: [], slots: [], data: {}}
    )
}

export const getPresetMetadata = (createExtArgs: CreateExtArgs, presetDescriptorId: string) => {
    const {presetId, name} = getDataFromMaster<Preset>(createExtArgs, presetDescriptorId)

    return {
        id: _.trimStart(presetId, '#'),
        name
    }
}

const getPanelMetadata = (createExtArgs: CreateExtArgs, panelDescriptorId: string) => {
    const {rootCompId, name, title, pageUrl} = getDataFromMaster<Panel>(createExtArgs, panelDescriptorId)

    return {
        id: _.trimStart(rootCompId, '#'),
        name,
        title,
        url: pageUrl
    }
}

export const getExtendedMetadataTemplate = (
    createExtArgs: CreateExtArgs,
    metaDataTemplate: ScopeMetaDataTemplate,
    appDefinitionId: string,
    widgetData: WidgetData,
    rootWidget: Pointer,
    includeCompData: boolean
): ScopeMetaDataTemplate => {
    const {components, innerWidgets, slots, data} = getWidgetComponentsMetadata(
        createExtArgs,
        rootWidget,
        includeCompData
    )

    return {
        ...metaDataTemplate,
        platform: metaDataTemplate.platform
            ? {
                  ...metaDataTemplate.platform,
                  appDefinitionId,
                  baseNickname: _.camelCase(widgetData.name),
                  controllerType: `${appDefinitionId}-${metaDataTemplate.platform.controllerType}`
              }
            : undefined,
        presets: widgetData.presets?.map(presetDescriptorId => getPresetMetadata(createExtArgs, presetDescriptorId)),
        panels: widgetData.panels?.map(panelDescriptorId => getPanelMetadata(createExtArgs, panelDescriptorId)),
        components,
        slots,
        innerWidgets,
        data
    }
}
