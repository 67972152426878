import {
    GET,
    getHttpResponseExtras,
    getReportableFromError,
    HttpRequestError,
    isFetchNetworkError,
    Method,
    ReportableError,
    retryTaskAndReport,
    searchRequestId
} from '@wix/document-manager-utils'
import _ from 'lodash'
import type {AdapterLogger} from '../adapter/adapterLogger'
import type {Experiment, UserInfo} from '@wix/document-services-types'
import {checkForCanaryError} from './noHealthyUpstream'

export async function postJson(url: string, instance: string, data: any): Promise<any> {
    return fetchJsonWithAuthorization(url, instance, 'POST', data)
}

export const checkResponseForError = (response: Response, errorType: string = 'httpError', message?: string) => {
    if (!response.ok) {
        throw new HttpRequestError(response, errorType, message)
    }
}

export class ServerError extends ReportableError {
    readonly details: ServerErrorDetails
    readonly status: number

    constructor(message: string, response: Response, result: Record<string, any>) {
        super({
            errorType: 'fetchJsonWithAuthorization',
            message,
            extras: {
                ...getHttpResponseExtras(response),
                result: JSON.stringify(result)
            }
        })
        this.status = response.status
        this.details = result.details
    }
}

const substr = (str: string, end: number) => (str ?? '').substring(0, end)

export class ServerTextError extends ReportableError {
    readonly status: number

    constructor(body: string, response: Response) {
        super({
            errorType: 'fetchJsonWithAuthorization',
            message: substr(body, 20),
            extras: {
                ...getHttpResponseExtras(response),
                result: substr(body, 200)
            }
        })
        this.status = response.status
    }
}

export interface ServerErrorDetails {
    applicationError?: {
        code: string
        description: string
    }
}

export interface ServerErrorData {
    message: string
    details: ServerErrorDetails
}

const ContentType = 'Content-Type'
const ContentTypeJson = 'application/json'

const isJson = (response: Response) => {
    const contentType = response.headers.get(ContentType) ?? ''
    return contentType.startsWith(ContentTypeJson)
}

const parseResponse = async (response: Response, logger?: AdapterLogger) => {
    let result
    try {
        result = isJson(response) ? await response.json() : {message: await response.text()}
    } catch (error: any) {
        logger?.captureError(
            new ReportableError({
                errorType: 'fetchJsonWithAuthorization',
                message: error.message,
                tags: {op: 'parseResponse'},
                extras: {originalError: JSON.stringify(error)}
            })
        )
        result = {message: 'Unable to parse error json'}
    }
    return result
}

const fetchJsonWithAuthorizationAndRetries = async (
    url: string,
    requestOptions: RequestInit,
    logger?: AdapterLogger,
    experimentInstance?: Experiment
): Promise<Response> =>
    retryTaskAndReport({
        task: () => {
            if (experimentInstance?.isOpen('dm_logFetchV1Save') && url.includes('/v1/save')) {
                const body = JSON.parse(requestOptions.body ? requestOptions.body?.toString() : '{}')
                logger?.interactionStarted('fetch_v1_save', {
                    extras: {
                        actionsCount: body?.actions?.length,
                        correlationId: body?.correlationId,
                        lastTransactionId: body?.metadata?.lastTransactionId
                    }
                })
            }
            return fetch(url, requestOptions)
        },
        checkIfShouldRetry: (e: any) => {
            if (isFetchNetworkError(e)) {
                return {shouldRetry: true, reason: e.message}
            }
            return {shouldRetry: false}
        },
        interactionName: 'retry_fetch',
        maxRetries: 3,
        waitInterval: 100,
        logger
    })

const tryFetch = async (
    url: string,
    method: Method,
    requestOptions: RequestInit,
    body?: string,
    logger?: AdapterLogger,
    experimentInstance?: Experiment
) => {
    try {
        return await fetchJsonWithAuthorizationAndRetries(url, requestOptions, logger, experimentInstance)
    } catch (e: any) {
        logger?.captureError(
            getReportableFromError(e, {
                errorType: 'fetchError',
                message: 'fetch fetchJsonWithAuthorization',
                tags: {op: 'fetchJsonWithAuthorization', url, method},
                extras: {
                    originalErrorObject: JSON.stringify(e),
                    bodySize: body?.length,
                    body: (body?.length || 0) > 0 ? body?.substring(0, 100) : 'empty'
                }
            })
        )
        throw e
    }
}

export async function fetchJsonWithAuthorization<T = any, R = any>(
    url: string,
    instance: string,
    method: Method,
    data?: R,
    logger?: AdapterLogger,
    experimentInstance?: Experiment,
    userInfo?: UserInfo
): Promise<T> {
    const body: string | undefined = JSON.stringify(data)
    const requestOptions: RequestInit = {
        method,
        headers: {
            Authorization: instance,
            [ContentType]: ContentTypeJson
        },
        body,
        redirect: 'follow' as RequestRedirect
    }
    const response: Response = await tryFetch(url, method, requestOptions, body, logger, experimentInstance)

    const parsedResponse = await parseResponse(response)
    if (response.ok) {
        return {...parsedResponse, requestId: searchRequestId(response)}
    }
    const {message} = parsedResponse
    checkForCanaryError(message, response, userInfo)

    if (isJson(response)) {
        throw new ServerError(message, response, parsedResponse)
    }
    throw new ServerTextError(message, response)
}

export async function deleteJson(url: string, instance: string, data: any, logger?: AdapterLogger): Promise<any> {
    return fetchJsonWithAuthorization(url, instance, 'DELETE', data, logger)
}

export async function fetchJson<T = any>(url: string): Promise<T> {
    const response = await fetch(url, {method: GET})
    return await response.json()
}

export async function getJson<T = any>(url: string, instance: string, logger?: AdapterLogger): Promise<T> {
    return fetchJsonWithAuthorization<T>(url, instance, GET, logger)
}

export type QueryValue = boolean | string | number | undefined | null

export type Query = Record<string, QueryValue>

export const toQueryString = (queryParams: Query): string =>
    _(queryParams)
        .pickBy()
        .map((value, key) => `${key}=${value}`)
        .join('&')

export async function getJsonWithParams<T = any>(url: string, queryParams: Query, instance: string): Promise<T> {
    const queryString = toQueryString(queryParams)
    return getJson(`${url}?${queryString}`, instance)
}

export async function postJsonWithAuth<T = any, R = any>(url: string, instance: string, data: R): Promise<T> {
    return postJson(url, instance, data)
}
