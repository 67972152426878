import type {DeserializeComponentParams} from '../types'
import {DATA_TYPES} from '../../../../constants/constants'
import {addIdToMap, getCustomId} from '../utils'
import {addNamespaceToStructure} from '../componentDeserializationStructure'
import type {SerializedCompStructure} from '@wix/document-services-types'
import _ from 'lodash'
import {SERIALIZED_SCOPED_DATA_KEYS} from '../../constants'
import {updateComponentScopedValues} from './scopedValues'

const get_COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS = () => {
    return {
        'responsive.components.Section': true,
        'responsive.components.HeaderSection': true,
        'responsive.components.FooterSection': true,
        'wysiwyg.viewer.components.StripColumnsContainer': true,
        'wysiwyg.viewer.components.ClassicSection': true
    }
}

const isCompTypeIsValidForDesignInVariants = (compType: string) =>
    get_COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS()[compType]

const shouldUseDesignOverVariants = (compStructure: SerializedCompStructure) => {
    const scopedDesigns = _.get(compStructure, SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.design])

    return isCompTypeIsValidForDesignInVariants(compStructure.componentType) && !_.isEmpty(scopedDesigns)
}

export const updateComponentDesignStructure = (deserializationParams: DeserializeComponentParams) => {
    const {createExtArgs, compStructure, pageId, mappers, customId} = deserializationParams

    if (shouldUseDesignOverVariants(compStructure)) {
        updateComponentScopedValues(createExtArgs, compStructure, DATA_TYPES.design, pageId, mappers)
    } else {
        const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}
        deserializationParams.customId = getCustomId(oldToNewIdMap, compStructure.design, customId)
        const designQuery = addNamespaceToStructure(
            deserializationParams,
            compStructure.design,
            DATA_TYPES.design,
            oldToNewIdMap
        )
        if (designQuery) {
            compStructure.designQuery = `#${designQuery}`
            addIdToMap(compStructure.design, designQuery, oldToNewIdMap)
        }
    }
}
