import _ from 'lodash'
import type {DalItem, DalJsNamespace, DalJsStore, ExtensionAPI, Null, SnapshotDal} from '@wix/document-manager-core'
import type {SnapshotExtApi} from '../../snapshots'
import type {LocalEditorExtensionAPI} from '../localEditor'
import {type PossibleSyncTags, SYNC_TAGS} from '../constants'
import {ReportableError} from '@wix/document-manager-utils'

export type SnapshotIdsByTag = Record<PossibleSyncTags, Set<SnapshotDal['id']>>

export class SnapshotSyncManager {
    private syncedSnapshotsBySource: SnapshotIdsByTag = {
        [SYNC_TAGS.EDITOR_SYNC_TAG]: new Set(),
        [SYNC_TAGS.FS_SYNC_TAG]: new Set()
    }
    constructor() {}

    private markSnapshotSynced = (tag: PossibleSyncTags, snapshot: SnapshotDal) => {
        this.syncedSnapshotsBySource[tag].add(snapshot.id)
    }
    private isSyncedFromTag = (tag: PossibleSyncTags, snapshot: SnapshotDal): boolean => {
        return this.syncedSnapshotsBySource[tag].has(snapshot.id)
    }
    public markSnapshotSyncedFromFS = (snapshot: SnapshotDal) => {
        this.markSnapshotSynced(SYNC_TAGS.FS_SYNC_TAG, snapshot)
    }
    public markSnapshotSyncedFromEditor = (snapshot: SnapshotDal) => {
        this.markSnapshotSynced(SYNC_TAGS.EDITOR_SYNC_TAG, snapshot)
    }
    public isSyncedFromEditor = (snapshot: SnapshotDal) => this.isSyncedFromTag(SYNC_TAGS.EDITOR_SYNC_TAG, snapshot)
    public isSyncedFromFS = (snapshot: SnapshotDal) => this.isSyncedFromTag(SYNC_TAGS.FS_SYNC_TAG, snapshot)
}

const getOldValue = (prev: Null<SnapshotDal>, namespace: string, id: string) => prev?.getValue({type: namespace, id})
const getPageId = (val: any): string | undefined => _.get(val, ['metaData', 'pageId'])

const getChangedPagesBetweenSnapshots = (currentSnapshot: SnapshotDal, fromSnapshot: SnapshotDal): Set<string> => {
    const pageIds: Set<string> = new Set()
    const changes: DalJsStore = currentSnapshot.diff(fromSnapshot)
    _.forOwn(changes, (items: DalJsNamespace, namespace: string) => {
        _.forOwn(items, (value: DalItem | undefined, id: string) => {
            const oldValue = getOldValue(fromSnapshot, namespace, id)
            const oldPage: string | undefined = getPageId(oldValue)
            const newPage: string | undefined = getPageId(value)

            if (oldPage) pageIds.add(oldPage)
            if (newPage) pageIds.add(newPage)
        })
    })
    return pageIds
}

export const getUpdatedPageIds = (extensionAPI: ExtensionAPI, snapshotSyncManager: SnapshotSyncManager): string[] => {
    const {snapshots} = extensionAPI as SnapshotExtApi & LocalEditorExtensionAPI
    const calculatedPagesMap: Map<string, boolean> = new Map()

    let currentSnapshot: SnapshotDal = snapshots.getCurrentSnapshot()

    while (!snapshotSyncManager.isSyncedFromEditor(currentSnapshot)) {
        const previousSnapshot = currentSnapshot.getPreviousSnapshot()

        // we met initial snapshot and no editor sync tag found
        // nothing to sync, we probably in broken flow
        if (previousSnapshot === null) {
            throw new ReportableError({
                errorType: 'invalidSnapshotSequence',
                message: 'Initial snapshot reached without meeting editor sync tag'
            })
        }

        const isFsSyncTaggedSnapshot = snapshotSyncManager.isSyncedFromFS(currentSnapshot)
        const currentChangedPages = getChangedPagesBetweenSnapshots(currentSnapshot, previousSnapshot)

        // remember pages that were synced already from fs
        // add to list of changed pages only those that not already synced (overwritten) by fs sync
        currentChangedPages.forEach(pageId => {
            if (!calculatedPagesMap.has(pageId)) {
                calculatedPagesMap.set(pageId, !isFsSyncTaggedSnapshot)
            }
        })

        currentSnapshot = previousSnapshot
    }

    const calculatedPages: string[] = []

    calculatedPagesMap.forEach((isIncluded, pageId) => {
        if (isIncluded) {
            calculatedPages.push(pageId)
        }
    })

    return calculatedPages
}
