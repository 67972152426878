import {
    CreateExtArgs,
    Extension,
    ExtensionAPI,
    type ExtensionPublicAPI,
    InitializeExtArgs,
    pointerUtils
} from '@wix/document-manager-core'
import _ from 'lodash'
import type {
    BehaviourItem,
    CompLayout,
    Component,
    ComponentLayoutObject,
    CompRef,
    CompStructure,
    DeserializationMappers,
    Pointer,
    SerializedCompStructure,
    StyleRef,
    StyleRefOrStyleRefs
} from '@wix/document-services-types'

import type {DefaultDefinitionsAPI} from '../defaultDefinitions/defaultDefinitions'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {ThemeAPI} from '../theme/theme'
import {getComponentType} from '../../utils/dalUtils'
import type {SchemaExtensionAPI} from '../schema/schema'
import {createEmptyStylableStyleItem} from '../../utils/stylableUtils'
import {ReportableError} from '@wix/document-manager-utils'
import type {ComponentDefinitionExtensionAPI} from '../componentDefinition'
import {DATA_TYPES, VIEW_MODES} from '../../constants/constants'

import {
    reportUnknownSystemStyle,
    validateCompConnections,
    validateComponentToAdd,
    validateComponentToSet,
    validateCustomId
} from './validation/validation'
import {
    addComponentToParent,
    addComponentV2Internal,
    setComponentV2,
    updateDisplayedOnlyComponentsDefs
} from './componentSetup'
import type {AddComponentOptionals, Result as ValidationResult} from './types'

import {registerComponentHooks} from './componentHooks/componentHooksRegistrar'
import {updateComponentScopedValues} from './componentDeserialization/namespaces/scopedValues'
import {updateComponentBehaviorsStructure} from './componentDeserialization/namespaces/behaviors'

import type {DeprecationExtensionAPI} from '../deprecation'
import {boundingLayout} from '@wix/santa-core-utils'
import {updateComponentDataStructure} from './componentDeserialization/namespaces/data'
import {sanitizeCompLayout} from './sanitation'
import {sanitizeSerializedComponent} from './serializedComponentSanitation'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import {
    updateComponentStatesStructure,
    updateComponentTriggersStructure
} from './componentDeserialization/namespaces/statesTriggers'
import type {GridLayoutAPI} from '../gridLayout'
import type {ComponentsMetadataAPI} from '../componentsMetadata/componentsMetadata'
import {updateComponentDesignStructure} from './componentDeserialization/namespaces/design'
import {updateComponentReactionsStructure} from './componentDeserialization/namespaces/reactions'
import {updateComponentTransitionsStructure} from './componentDeserialization/namespaces/transitions'
import {updateComponentTransformationsStructure} from './componentDeserialization/namespaces/transformations'
import {updateComponentFeaturesStructure} from './componentDeserialization/namespaces/features'
import {updateComponentEffectsStructure} from './componentDeserialization/namespaces/effects'
import type {LoggerExtAPI} from '../logger'
import {deserializeComponentData} from './componentDeserialization/componentDeserialization'
import {updateComponentVariablesDefinition} from './componentDeserialization/namespaces/variables'
import {updateVariantsInStructure} from './componentDeserialization/namespaces/variants'

export interface AddOptions {
    addDefaultResponsiveLayout?: boolean
}
export interface ComponentsAPI extends ExtensionAPI {
    addComponent(
        parentPointer: Pointer,
        componentStructure: CompStructure,
        compPointerOverride?: Pointer,
        options?: AddOptions
    ): Pointer
    setComponent(componentDefinition: CompStructure, pageId: string, componentPointer: Pointer): void
    addLayoutsAsResponsiveLayout(componentPointer: Pointer, layouts?: Partial<ComponentLayoutObject>): void
    addStyles(componentPointer: Pointer, stylesDefinition?: object): void
    addBreakpointVariants(componentPointer: Pointer, breakpointsDefinition?: any): void
    removeComponent(componentPointer: Pointer): void
    buildDefaultComponentStructure(componentType: string): CompStructure
    buildDefaultStyle(compDefinition: any, componentType: string): StyleRef | string
    getComponentLayout(componentPointer: Pointer): CompLayout
    getChildrenByDepthFirstOrderWithRootRecursive(
        compIdToComponentMap: Record<string, CompStructure>,
        compId: string
    ): CompStructure[]
    sanitation: {
        sanitizeCompLayout(compStructure: CompStructure): void
        sanitizeSerializedComponent(
            compStructure: CompStructure,
            extensionAPI: ExtensionAPI,
            experimentInstance: any,
            logger: any
        ): void
    }
    addComponentV2(
        componentPointer: Pointer,
        containerPointer: Pointer,
        compStructure: SerializedCompStructure,
        optionals?: AddComponentOptionals
    ): Pointer
    setComponentV2(
        compPointer: Pointer,
        containerPointer: Pointer | null,
        compStructure: SerializedCompStructure,
        optionals: AddComponentOptionals
    ): Pointer
    validation: {
        validateCompConnections(compStructure: CompStructure): void
        validateCustomId(customId?: string): void
        reportUnknownSystemStyle(compStructure: CompStructure): void
        validateComponentToSet(
            componentStructure: SerializedCompStructure,
            optionalCustomId: string | undefined,
            containerPointer: Pointer | null,
            isPage?: boolean
        ): ValidationResult
        validateComponentToAdd(
            componentPointer: Pointer,
            componentStructure: SerializedCompStructure,
            containerPointer: Pointer,
            optionalIndex?: number
        ): ValidationResult
    }
    updateDisplayedOnlyComponentsDefs(
        pageId: string,
        compToAddPointer: Pointer,
        structureToAdd: CompStructure,
        serializedComp: SerializedCompStructure,
        isPage: boolean,
        mappers: DeserializationMappers
    ): void
    updateComponentVariablesDefinition(
        pageId: string,
        containerPointer: Pointer,
        isPage: boolean,
        mappers: DeserializationMappers,
        compStructure: SerializedCompStructure,
        isAncestorsChecked?: boolean
    ): void
    updateVariantsInStructure(
        pageId: string,
        containerPointer: Pointer,
        isPage: boolean,
        mappers: DeserializationMappers,
        compStructure: SerializedCompStructure,
        isAncestorsChecked?: boolean
    ): void
    deserialization: {
        deserializeComponentData(
            compStructure: SerializedCompStructure,
            pageId: string,
            mappers?: DeserializationMappers,
            isPage?: boolean,
            customId?: string,
            stylesPerPage?: boolean
        ): void
        updateComponentBehaviorsStructure(
            compStructure: any,
            pageId: string,
            optionalCustomId: string,
            mappers: DeserializationMappers,
            behaviorItemFixerHook: (behaviorItem: BehaviourItem) => void
        ): void
    }
    isRepeater(compPointer: CompRef): boolean
}

export interface ComponentsExtensionAPI extends ExtensionAPI {
    components: ComponentsAPI
}

export const EVENTS = {
    COMPONENTS: {
        BEFORE_REMOVE: 'COMPONENT_BEFORE_REMOVE',
        AFTER_REMOVE: 'COMPONENT_AFTER_REMOVE',
        AFTER_ADD_FROM_EXT: 'COMPONENT_AFTER_ADD_FROM_EXT',
        BEFORE_ADD: 'COMPONENT_BEFORE_ADD',
        BEFORE_ADD_ROOT: 'COMPONENT_BEFORE_ADD_ROOT',
        AFTER_ADD: 'COMPONENT_AFTER_ADD'
    }
}

export const DEFAULT_COMP_LAYOUT = {
    width: 100,
    height: 100,
    x: 0,
    y: 0
}

const createExtension = (): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): ExtensionAPI => {
        const {dal, pointers, extensionAPI, eventEmitter} = createExtArgs
        const defaultDefinitions = () => extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
        const componentMetaData = () => (extensionAPI as ComponentsMetadataAPI).componentsMetadata
        const gridLayout = () => extensionAPI.gridLayout as GridLayoutAPI
        const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel
        const theme = () => extensionAPI.theme as ThemeAPI
        const deprecation = () => (extensionAPI as DeprecationExtensionAPI).deprecation

        const addData = (componentPointer: Pointer, pageId: string, dataDefinition?: object) => {
            if (dataDefinition) {
                dataModel().components.addItem(componentPointer, 'data', dataDefinition)
            }
        }

        const addDesign = (componentPointer: Pointer, pageId: string, designDefinition?: object) => {
            if (designDefinition) {
                dataModel().components.addItem(componentPointer, 'design', designDefinition)
            }
        }

        const addProperties = (componentPointer: Pointer, pageId: string, propertiesDefinition?: object) => {
            if (propertiesDefinition) {
                dataModel().components.addItem(componentPointer, 'props', propertiesDefinition)
            }
        }

        const addPresets = (componentPointer: Pointer, presetsDefinition?: object) => {
            if (presetsDefinition) {
                dataModel().components.addItem(componentPointer, DATA_TYPES.presets, presetsDefinition)
            }
        }

        const addStyles = (componentPointer: Pointer, stylesDefinition?: object) => {
            if (stylesDefinition) {
                dataModel().components.addItem(componentPointer, DATA_TYPES.theme, stylesDefinition)
            }
        }

        const addMobileHints = (componentPointer: Pointer, mobileHintsDefintion?: object) => {
            if (mobileHintsDefintion) {
                dataModel().components.addItem(componentPointer, DATA_TYPES.mobileHints, mobileHintsDefintion)
            }
        }

        const createDalComponent = (componentDefinition: CompStructure, pageId: string, componentPointer: Pointer) => {
            const component = {
                layout: componentDefinition.layout ? componentDefinition.layout : {},
                metaData: {pageId},
                type: componentDefinition.type ?? 'Component',
                id: componentPointer.id,
                componentType: componentDefinition.componentType
            } as Component

            if (componentDefinition.styleId) {
                component.styleId = componentDefinition.styleId
            }
            if (componentDefinition.skin) {
                component.skin = componentDefinition.skin
            }

            if (componentMetaData().isContainer(componentDefinition.componentType)) {
                component.components = []
            }
            return component
        }

        const addLayoutsAsResponsiveLayout = (componentPointer: Pointer, layouts?: Partial<ComponentLayoutObject>) => {
            const layout = {...layouts, variableConnections: [], type: 'SingleLayoutData'}
            const refArray = defaultDefinitions().createRefArrayDefinition([layout])
            dataModel().components.addItem(componentPointer, 'layout', refArray)
        }

        const addBreakpointVariants = (componentPointer: Pointer, breakpointsDefinition?: any) => {
            const breakpoints = {
                values: breakpointsDefinition,
                type: 'BreakpointsData',
                componentId: componentPointer.id
            }
            dataModel().components.addItem(componentPointer, 'variants', breakpoints)
        }

        const setComponent = (componentDefinition: CompStructure, pageId: string, componentPointer: Pointer) => {
            const component = createDalComponent(componentDefinition, pageId, componentPointer)
            dal.set(componentPointer, component)
        }

        const addSystemStyle = (componentDefinition: CompStructure) => {
            if (componentDefinition.styleId) {
                theme().ensureDefaultStyleItemExists(componentDefinition.componentType, componentDefinition.styleId)
            }
        }

        const addComponent = (
            parentPointer: Pointer,
            compDefinition: CompStructure,
            compPointerOverride?: Pointer,
            options: AddOptions = {}
        ): Pointer => {
            const isResponsive = dal.get(pointers.general.isResponsive())
            const componentDefinition = defaultDefinitions().createComponentDefinition(
                compDefinition,
                {
                    parentPointer
                },
                options
            )
            eventEmitter.emit(EVENTS.COMPONENTS.BEFORE_ADD, compDefinition)
            const parentComponent = dal.get(parentPointer)
            if (!parentComponent) {
                throw new Error('Parent does not exist')
            }
            const componentPointer = compPointerOverride
                ? compPointerOverride
                : pointerUtils.getPointer(generateItemIdWithPrefix('comp'), 'DESKTOP')
            const pagePointer = pointers.structure.getPageOfComponent(parentPointer)
            const pageId = pagePointer.id
            setComponent(componentDefinition, pageId, componentPointer)
            const addResponsiveLayout = _.get(options, ['addDefaultResponsiveLayout'])

            if (addResponsiveLayout || isResponsive) {
                addLayoutsAsResponsiveLayout(componentPointer, componentDefinition.layouts)
            }
            addData(componentPointer, pageId, componentDefinition.data)
            addDesign(componentPointer, pageId, componentDefinition.design)
            addProperties(componentPointer, pageId, componentDefinition.props)
            addComponentToParent(dal, parentPointer, componentPointer)
            if (!componentDefinition.style) {
                addSystemStyle(componentDefinition)
            } else {
                addStyles(componentPointer, componentDefinition.style as StyleRefOrStyleRefs)
            }
            addPresets(componentPointer, componentDefinition.presets)
            addMobileHints(componentPointer, componentDefinition.mobileHints)

            if ((addResponsiveLayout || isResponsive) && parentComponent.type === 'Page') {
                gridLayout().shiftItemsToBeUnderTarget(parentPointer, componentPointer)
            }
            if (componentDefinition.mobileStructure) {
                const mobilePointer = {...componentPointer, type: VIEW_MODES.MOBILE}
                const currStructure = dal.get(componentPointer)
                const mobileStructure = {...currStructure, ...componentDefinition.mobileStructure, metaData: {pageId}}
                dal.set(mobilePointer, mobileStructure)
                addComponentToParent(dal, {id: parentPointer.id, type: VIEW_MODES.MOBILE}, mobilePointer)
            }
            eventEmitter.emit(EVENTS.COMPONENTS.AFTER_ADD_FROM_EXT, componentPointer, pagePointer)
            return componentPointer
        }

        const removeComponentFromParent = (parentPointer: Pointer, removedComponentId: string) => {
            const componentsPtr = pointerUtils.getInnerPointer(parentPointer, 'components')
            const components = dal.get(componentsPtr).filter((id: string) => id !== removedComponentId)
            dal.set(componentsPtr, components)
        }

        const removeComponentFromParentIfParentExists = (parentPointer: Pointer | null, removedComponentId: string) => {
            if (!_.isNil(parentPointer)) {
                removeComponentFromParent(parentPointer, removedComponentId)
            }
        }

        const _removeComponent = (componentPointer: Pointer, removingParent: boolean) => {
            if (componentPointer.type !== VIEW_MODES.DESKTOP) {
                throw Error('removing components from non desktop view mode is not supported')
            }
            const compType = getComponentType(dal, componentPointer)
            const compChildren = dal.get(pointerUtils.getInnerPointer(componentPointer, 'components'))

            for (const childId of compChildren ?? []) {
                _removeComponent(pointerUtils.getPointer(childId, componentPointer.type), true)
            }

            eventEmitter.emit(EVENTS.COMPONENTS.BEFORE_REMOVE, componentPointer)

            const mobilePointer = pointerUtils.getPointer(componentPointer.id, VIEW_MODES.MOBILE)
            if (dal.has(mobilePointer)) {
                const mobileParent = pointers.structure.getParent(mobilePointer)
                dal.remove(mobilePointer)
                removeComponentFromParentIfParentExists(mobileParent, mobilePointer.id)
            }

            const compParent = pointers.structure.getParent(componentPointer)

            dataModel().removeItemRecursively(componentPointer)
            removeComponentFromParentIfParentExists(compParent, componentPointer.id)

            eventEmitter.emit(EVENTS.COMPONENTS.AFTER_REMOVE, componentPointer, compType, removingParent)
        }

        const removeComponent = (componentPointer: Pointer) => {
            _removeComponent(componentPointer, false)
        }

        const buildDefaultStyle = (compDefinition: any, componentType: string) => {
            const dataModelAPI = dataModel()
            const styleId = _.head(_.keys(compDefinition.styles))
            let style: StyleRef | undefined
            if (!styleId) {
                const skin: string | undefined = _.head(compDefinition.skins || [])
                if (skin) {
                    style = dataModelAPI.createStyleItemByType('TopLevelStyle')
                    style.skin = skin
                }
                // TODO: remove once Stylable deprecation is complete
                if (compDefinition.isStylableComp) {
                    style = {
                        ...dataModelAPI.createStyleItemByType('ComponentStyle'),
                        ...createEmptyStylableStyleItem(componentType)
                    } as StyleRef
                }
            }
            return styleId ?? style
        }

        const buildDefaultComponentStructure = (componentType: string): CompStructure => {
            const {schemaAPI} = extensionAPI as SchemaExtensionAPI
            const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI

            const dataModelAPI = dataModel()
            const compDefinition = schemaAPI.getDefinition(componentType)
            if (!_.isString(componentType)) {
                throw new ReportableError({
                    errorType: 'DEFAULT_STRUCTURE_TYPE_MISSING',
                    message: 'Must pass componentType as string'
                })
            }

            if (!compDefinition) {
                throw new ReportableError({
                    errorType: 'DEFAULT_STRUCTURE_TYPE_NOT_SUPPORTED',
                    message: 'Component type is not supported',
                    extras: {componentType}
                })
            }

            const style = buildDefaultStyle(compDefinition, componentType)

            const defaultDataItemType = _.includes(compDefinition.dataTypes, '')
                ? ''
                : _.head(compDefinition.dataTypes as string[])
            let defaultDataItem
            if (defaultDataItemType) {
                defaultDataItem = dataModelAPI.createDataItemByType(defaultDataItemType)
            }

            const defaultDesignItemType = _.includes(compDefinition.designDataTypes, '')
                ? ''
                : _.head(compDefinition.designDataTypes as string[])
            let defaultDesignItem
            if (defaultDesignItemType) {
                defaultDesignItem = dataModelAPI.createDesignItemByType(defaultDesignItemType)
            }

            const defaultPropertiesItemType =
                compDefinition.propertyType ||
                (_.includes(compDefinition.propertyTypes, '') ? '' : _.head(compDefinition.propertyTypes))
            let defaultPropertiesItem
            if (defaultPropertiesItemType) {
                defaultPropertiesItem = dataModelAPI.createPropertiesItemByType(defaultPropertiesItemType)
            }

            const defaultCompStructure: CompStructure = {
                layout: _.clone(DEFAULT_COMP_LAYOUT),
                componentType,
                data: defaultDataItem,
                props: defaultPropertiesItem,
                design: defaultDesignItem,
                style
            }

            if (componentDefinition.isContainer(componentType)) {
                _.assign(defaultCompStructure, {components: []})
            }

            if (compDefinition.requiredChildType) {
                _.assign(defaultCompStructure, {
                    components: [buildDefaultComponentStructure(compDefinition.requiredChildType)]
                })
            }

            return defaultCompStructure
        }

        const getComponentLayout = (componentPointer: Pointer): CompLayout | null => {
            if (!componentPointer) {
                return null
            }

            const layout = _.cloneDeep(dal.get(pointers.getInnerPointer(componentPointer, 'layout')))
            if (!layout) {
                return null
            }

            // this function is missing code that is supposed to handle layouts with docked=true, due to the original code relying on the viewer

            return _.merge(layout, {bounding: boundingLayout.getBoundingLayout(layout)})
        }

        const getChildrenByDepthFirstOrderWithRootRecursive = (
            compIdToComponentMap: Record<string, CompStructure>,
            compId: string
        ): CompStructure[] => {
            const currentComp = compIdToComponentMap[compId]
            if (!currentComp) {
                return []
            }
            const children = (currentComp?.components ?? []).map((childId: string | CompStructure) =>
                getChildrenByDepthFirstOrderWithRootRecursive(compIdToComponentMap, childId as string)
            )
            return [currentComp, ..._.flatten(children)]
        }

        const addComponentV2 = (
            componentPointer: Pointer,
            containerPointer: Pointer,
            compStructure: SerializedCompStructure,
            optionals?: AddComponentOptionals
        ) => {
            const {logger} = extensionAPI as LoggerExtAPI
            logger.breadcrumb('adding component - addComponentV2', {
                extras: {
                    compId: componentPointer.id,
                    compType: compStructure.componentType,
                    containerId: containerPointer.id,
                    containerType: dal.get(containerPointer).componentType
                }
            })
            return addComponentV2Internal(createExtArgs, componentPointer, containerPointer, compStructure, optionals)
        }

        const isRepeater = (compPointer: CompRef) => {
            const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI

            return componentDefinition.isRepeater(getComponentType(dal, compPointer))
        }

        return {
            components: {
                addComponent,
                addComponentV2,
                setComponent,
                setComponentV2: (
                    compPointer: Pointer,
                    containerPointer: Pointer,
                    compStructure: SerializedCompStructure,
                    optionals: AddComponentOptionals
                ) => setComponentV2(createExtArgs, compPointer, containerPointer, compStructure, optionals),
                addLayoutsAsResponsiveLayout,
                addBreakpointVariants,
                addStyles,
                removeComponent,
                buildDefaultComponentStructure,
                buildDefaultStyle,
                getComponentLayout,
                getChildrenByDepthFirstOrderWithRootRecursive,
                sanitation: {
                    sanitizeCompLayout: (compStructure: CompStructure) =>
                        sanitizeCompLayout(createExtArgs, compStructure),
                    sanitizeSerializedComponent
                },
                validation: {
                    validateCompConnections: (compStructure: CompStructure) =>
                        validateCompConnections(createExtArgs, compStructure),
                    validateCustomId,
                    reportUnknownSystemStyle: (compStructure: CompStructure) =>
                        reportUnknownSystemStyle(createExtArgs, compStructure),
                    validateComponentToSet: (
                        componentStructure: SerializedCompStructure,
                        optionalCustomId: string | undefined,
                        containerPointer: Pointer,
                        isPage?: boolean
                    ) =>
                        validateComponentToSet(
                            createExtArgs,
                            componentStructure,
                            optionalCustomId,
                            containerPointer,
                            isPage
                        ),
                    validateComponentToAdd: (
                        componentPointer: Pointer,
                        componentStructure: SerializedCompStructure,
                        containerPointer: Pointer,
                        optionalIndex?: number
                    ) =>
                        validateComponentToAdd(
                            createExtArgs,
                            componentPointer,
                            componentStructure,
                            containerPointer,
                            optionalIndex,
                            deprecation().getShouldThrowOnDeprecation()
                        )
                },
                updateDisplayedOnlyComponentsDefs: (
                    pageId: string,
                    compToAddPointer: Pointer,
                    structureToAdd: CompStructure,
                    serializedComp: SerializedCompStructure,
                    isPage: boolean,
                    mappers: DeserializationMappers
                ) => {
                    updateDisplayedOnlyComponentsDefs(
                        createExtArgs,
                        pageId,
                        compToAddPointer,
                        structureToAdd,
                        serializedComp,
                        isPage,
                        mappers
                    )
                },
                updateComponentVariablesDefinition: (
                    pageId: string,
                    containerPointer: Pointer,
                    isPage: boolean,
                    mappers: DeserializationMappers,
                    compStructure: SerializedCompStructure,
                    isAncestorsChecked?: boolean
                ) => {
                    updateComponentVariablesDefinition(
                        {createExtArgs, pageId, containerPointer, isPage, mappers, compStructure},
                        isAncestorsChecked
                    )
                },
                updateVariantsInStructure: (
                    pageId: string,
                    containerPointer: Pointer,
                    isPage: boolean,
                    mappers: DeserializationMappers,
                    compStructure: SerializedCompStructure,
                    isAncestorsChecked?: boolean
                ) => {
                    updateVariantsInStructure(
                        {createExtArgs, pageId, containerPointer, isPage, mappers, compStructure},
                        isAncestorsChecked ?? false
                    )
                },
                deserialization: {
                    deserializeComponentData: (
                        compStructure: SerializedCompStructure,
                        pageId: string,
                        mappers?: DeserializationMappers,
                        isPage?: boolean,
                        customId?: string,
                        stylesPerPage?: boolean
                    ) => {
                        deserializeComponentData({
                            createExtArgs,
                            compStructure,
                            pageId,
                            mappers,
                            isPage,
                            customId,
                            stylesPerPage
                        })
                    },
                    updateComponentBehaviorsStructure: (
                        compStructure: SerializedCompStructure,
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        behaviorItemFixerHook: (behaviorItem: BehaviourItem) => void
                    ) => {
                        updateComponentBehaviorsStructure(
                            {createExtArgs, compStructure, pageId, customId, mappers},
                            behaviorItemFixerHook
                        )
                    },
                    updateComponentScopedValues: (
                        compStructure: SerializedCompStructure,
                        itemType: string,
                        pageId: string,
                        mappers: DeserializationMappers,
                        stylesPerPage?: boolean
                    ) => {
                        updateComponentScopedValues(
                            createExtArgs,
                            compStructure,
                            itemType,
                            pageId,
                            mappers,
                            stylesPerPage
                        )
                    },
                    updateComponentStatesStructure: (
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        compStructure: SerializedCompStructure
                    ) => {
                        updateComponentStatesStructure({
                            createExtArgs,
                            pageId,
                            customId,
                            mappers,
                            compStructure
                        })
                    },
                    updateComponentTriggersStructure: (
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        compStructure: SerializedCompStructure
                    ) => {
                        updateComponentTriggersStructure({
                            createExtArgs,
                            pageId,
                            customId,
                            mappers,
                            compStructure
                        })
                    },
                    updateComponentDesignStructure: (
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        compStructure: SerializedCompStructure
                    ) => {
                        updateComponentDesignStructure({
                            createExtArgs,
                            pageId,
                            customId,
                            mappers,
                            compStructure
                        })
                    },
                    updateComponentDataStructure: (
                        compStructure: SerializedCompStructure,
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        isPage?: boolean
                    ) => {
                        updateComponentDataStructure({
                            createExtArgs,
                            compStructure,
                            pageId,
                            mappers,
                            isPage,
                            customId
                        })
                    },
                    updateComponentTransitionsStructure: (
                        compStructure: SerializedCompStructure,
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        isPage?: boolean
                    ) => {
                        updateComponentTransitionsStructure({
                            createExtArgs,
                            compStructure,
                            pageId,
                            mappers,
                            isPage,
                            customId
                        })
                    },
                    updateComponentReactionsStructure: (
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        compStructure: SerializedCompStructure
                    ) => {
                        updateComponentReactionsStructure({
                            createExtArgs,
                            pageId,
                            customId,
                            mappers,
                            compStructure
                        })
                    },
                    updateComponentTransformationsStructure: (
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        compStructure: SerializedCompStructure
                    ) => {
                        updateComponentTransformationsStructure({
                            createExtArgs,
                            pageId,
                            customId,
                            mappers,
                            compStructure
                        })
                    },
                    updateComponentFeaturesStructure: (
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        compStructure: SerializedCompStructure
                    ) => {
                        updateComponentFeaturesStructure({
                            createExtArgs,
                            pageId,
                            customId,
                            mappers,
                            compStructure
                        })
                    },
                    updateComponentEffectsStructure: (
                        pageId: string,
                        customId: string,
                        mappers: DeserializationMappers,
                        compStructure: SerializedCompStructure
                    ) => {
                        updateComponentEffectsStructure({
                            createExtArgs,
                            pageId,
                            customId,
                            mappers,
                            compStructure
                        })
                    }
                },
                isRepeater
            }
        }
    }

    const initialize = async (extArgs: InitializeExtArgs) => {
        registerComponentHooks(extArgs)
    }

    const createPublicAPI = ({extensionAPI}: CreateExtArgs): ExtensionPublicAPI => {
        const {components} = extensionAPI as ComponentsExtensionAPI

        return {
            components: {
                is: {
                    repeater: components.isRepeater
                }
            }
        }
    }

    return {
        name: 'components',
        EVENTS,
        initialize,
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
