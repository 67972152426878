import {pointerUtils, type CreateExtArgs} from '@wix/document-manager-core'
import type {ExternalComponentsReadOnlyDriver, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {DATA_TYPES, EXTERNAL_META_DATA_ALLOWED_TYPES} from '../../constants/constants'
import {ReportableError} from '@wix/document-manager-utils'
import type {MetadataMap} from './metadataMap'
import type {ComponentsExtensionAPI} from '../components/components'
import {getComponentType} from '../../utils/dalUtils'

export const isExternalMetaData = (metaDataMap: MetadataMap, componentType: string) => {
    return metaDataMap?.[componentType]?.isExternal ?? false
}

export const isExternalMetaDataField = (metaDataMap: MetadataMap, componentType: string, metaDataKey: string) => {
    return isExternalMetaData(metaDataMap, componentType) && metaDataKey in metaDataMap[componentType]
}

export const createExternalCompDriver = (
    {pointers, extensionAPI, dal}: CreateExtArgs,
    compPointer: Pointer
): ExternalComponentsReadOnlyDriver => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {components} = extensionAPI as ComponentsExtensionAPI

    return {
        layout: {
            get: () => components.getComponentLayout(compPointer)
        },
        data: {
            get: () => dataModel.components.getItem(compPointer, DATA_TYPES.data)
        },
        properties: {
            get: () => dataModel.components.getItem(compPointer, DATA_TYPES.prop)
        },
        design: {
            get: () => dataModel.components.getItem(compPointer, DATA_TYPES.design)
        },
        svg: {
            getInfo: (propName: string) => {
                const data = dataModel.components.getItem(compPointer, DATA_TYPES.data)
                const svgId = data && propName in data ? data[propName] : null
                if (svgId) {
                    const storePointer = pointerUtils.getPointer(svgId, 'svgShapes')
                    const infoPointer = pointers.getInnerPointer(storePointer, 'info')
                    return dal.get(infoPointer)
                }
                return null
            }
        },
        isMobile: () => pointers.structure.isMobile(compPointer),
        getChildrenRecursively: (pointer: Pointer) => pointers.structure.getChildrenRecursively(pointer),
        getComponentType: (pointer: Pointer) => getComponentType(dal, pointer)
    }
}

export const informIllegalMetadataTypes = (
    {coreConfig}: CreateExtArgs,
    componentType: string,
    illegalKeys: string[],
    metaData: any
) => {
    const message = `Unable to use illegal meta data key(s) "${illegalKeys.join(
        ', '
    )}" for component "${componentType}"`

    coreConfig.logger.interactionEnded('illegalMetaDataKey', {
        message,
        tags: {
            metaDataRegistration: true
        },
        extras: {
            illegalKeys,
            metaDataToRegister: metaData
        }
    })
}

export const informUnableToRegisterAlreadyRegisteredComponent = (
    {coreConfig}: CreateExtArgs,
    componentType: string
) => {
    coreConfig.logger.captureError(
        new ReportableError({
            errorType: 'duplicateMetaDataRegistration',
            message: `Unable to register a component metaData for type "${componentType}" that is already registered`,
            tags: {
                metaDataRegistration: true,
                failedMetaDataRegistration: true
            },
            extras: {
                componentType
            }
        })
    )
}

export const filterAndFlagExternalMetadata = (metaData: any) => {
    const filtered = _.pick(metaData, EXTERNAL_META_DATA_ALLOWED_TYPES)
    return {
        ...filtered,
        isExternal: true
    }
}
