import type {MobileAlgoContext, ReadOnlyExtensionAPI} from './types'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {RMApi} from '../rendererModel'
import type {StructureExtensionAPI} from '../structure'
import type {Pointer} from '@wix/document-services-types'
import type {ThemeExtAPI} from '../theme/theme'
import type {PageExtensionAPI} from '../page'
import type {MobileAlgoExtensionApi} from './mobileAlgo'
import type {ComponentsMetadataAPI} from '../componentsMetadata/componentsMetadata'

export const createReadOnlyExtensionApi = (extensionAPI: ExtensionAPI): ReadOnlyExtensionAPI => {
    return {
        getInstance: () => (extensionAPI as RMApi).siteAPI.getInstance(),
        getChildren: (componentPointer: Pointer) =>
            (extensionAPI as StructureExtensionAPI).components.getChildren(componentPointer),
        isTemplate: () => (extensionAPI as RMApi).siteAPI.isTemplate(),
        getTextTheme: () => (extensionAPI as ThemeExtAPI).theme.getTextTheme(),
        getMetaSiteId: () => (extensionAPI as RMApi).rendererModel.getMetaSiteId(),
        getSiteId: () => (extensionAPI as RMApi).rendererModel.getSiteId(),
        getMainPageId: () => (extensionAPI as PageExtensionAPI).page.getMainPageId(),
        getPluginsApi: (ctx: MobileAlgoContext) =>
            (extensionAPI as MobileAlgoExtensionApi).mobileAlgo.plugins.getApis(ctx),
        isFullWidth: (componentPointer: Pointer) =>
            (extensionAPI as ComponentsMetadataAPI).componentsMetadata.isFullWidth(componentPointer)
    }
}
