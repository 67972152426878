import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef, ImportExportOptions, RawComponentExport} from '@wix/document-services-types'
import {importRawComponent} from './raw/import'
import {addPlaceholderComponent, addPlaceholderPage} from './utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import {getRawOptions} from './options'

export const replaceComponent = (
    extensionContext: CreateExtArgs,
    rawComponentExport: RawComponentExport,
    componentToReplace: CompRef,
    options?: ImportExportOptions
) => {
    const clonedExport = deepClone(rawComponentExport)

    importRawComponent(
        extensionContext,
        clonedExport.rootComponent,
        clonedExport,
        componentToReplace,
        getRawOptions(options)
    )
}

export const addComponent = (
    extensionContext: CreateExtArgs,
    newComponentPointer: CompRef,
    rawComponentExport: RawComponentExport,
    containerPointer: CompRef,
    options?: ImportExportOptions
): CompRef => {
    const {dal, pointers} = extensionContext
    const componentToReplace = addPlaceholderComponent(newComponentPointer.id, containerPointer, dal, pointers)
    replaceComponent(extensionContext, rawComponentExport, componentToReplace, options)
    return componentToReplace
}

export const addPage = (
    extensionContext: CreateExtArgs,
    newComponentPointer: CompRef,
    rawPageExport: RawComponentExport,
    options?: ImportExportOptions
): CompRef => {
    const {pointers, extensionAPI} = extensionContext
    const {rootComponent, components} = rawPageExport
    const page = components[rootComponent]
    const pageToReplace = addPlaceholderPage(newComponentPointer.id, page, pointers, extensionAPI)
    replaceComponent(extensionContext, rawPageExport, pageToReplace, options)
    return pageToReplace
}
