import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {notContainableByPopup} from '../utils/pageUtils'
import {blogAppPartNames} from '@wix/santa-core-utils'
import {isBlogSinglePostPage} from '../../../utils/blogUtils'

const containableByStructure = (
    createExtArgs: CreateExtArgs,
    compStructure: CompStructure,
    containerPointer: Pointer
) => {
    const isOnPopup = !notContainableByPopup(createExtArgs, containerPointer)

    const pagePointer = createExtArgs.pointers.structure.getPageOfComponent(containerPointer)
    const isSinglePostPage = isBlogSinglePostPage(createExtArgs, pagePointer.id)
    if (compStructure.data.appPartName === 'ea63bc0f-c09f-470c-ac9e-2a408b499f22') {
        // is singlePost Part
        const {type, appPartName} = compStructure.data
        return (
            isSinglePostPage &&
            !createExtArgs.pointers.data.getDataItemWithPredicate({type, appPartName}, pagePointer.id)
        )
    }

    const isRelatedPostComponent = compStructure.data.appPartName === blogAppPartNames.RELATED_POSTS

    return !(isOnPopup || (isRelatedPostComponent && !isSinglePostPage))
}

export const appPartMetaData = {
    containableByStructure
}
