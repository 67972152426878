import _ from 'lodash'
import type {
    BreakpointRange,
    CompRef,
    Breakpoint,
    BreakpointId,
    BreakpointsMap,
    RawComponentExport,
    RawComponentExportStructure,
    ExternalsMap
} from '@wix/document-services-types'
import {DEFAULT_BREAKPOINTS_MAX_RANGE} from '../../constants'
import {forEachReferredDataItem, type ReferredDataItemsIterateeContext} from '@wix/import-export-utils'
import {namespaceMapping} from '@wix/document-services-json-schemas'
import type {CreateExtArgs, DalValue} from '@wix/document-manager-core'
import {stripHashIfExists} from '../../../../../utils/refArrayUtils'
import type {DataExtensionAPI, DataModelExtensionAPI} from '../../../../..'
import {DATA_TYPES, VARIANTS} from '../../../../../constants/constants'
import type {ComponentDefinitionExtensionAPI} from '../../../../componentDefinition'
import {updateComponentDataItem} from './dataItems'

type PossibleMatchingBreakpoints = Record<BreakpointId, Breakpoint & {id: BreakpointId}>

interface MatchResult {
    matches: Record<BreakpointId, BreakpointId>
    breakpointsToRemove: Set<BreakpointId>
}

const {VARIANT_TYPES} = VARIANTS
const {BREAKPOINTS_DATA, BREAKPOINT_RANGE} = VARIANT_TYPES
const {VARIANTS_DATA_TYPES} = namespaceMapping

export const getBreakpointsIntersectionArea = (
    breakpoint1: Breakpoint,
    breakpoint2: Breakpoint
): number | undefined => {
    const intersectionMin = _.max([breakpoint1.min, breakpoint2.min, 0])!
    const intersectionMax = _.min([breakpoint1.max, breakpoint2.max, DEFAULT_BREAKPOINTS_MAX_RANGE])!
    const intersectionArea = intersectionMax - intersectionMin

    if (intersectionArea > 0) {
        return intersectionArea
    }
}

export const getBestMatchingBreakpoint = (
    breakpoint: Breakpoint,
    possibleMatchingBreakpoints: PossibleMatchingBreakpoints
): BreakpointId | undefined =>
    _(possibleMatchingBreakpoints)
        .values()
        .maxBy(possibleMatch => getBreakpointsIntersectionArea(possibleMatch, breakpoint))?.id

export const matchBreakpoints = (breakpointsMap: BreakpointsMap, pageBreakpoints?: BreakpointRange[]): MatchResult => {
    const matches = {}

    if (!pageBreakpoints?.length) {
        return {
            matches,
            breakpointsToRemove: new Set(_.keys(breakpointsMap))
        }
    }

    const possibleMatchingBreakpoints = _.mapValues(breakpointsMap, (breakpoint: Breakpoint, id: BreakpointId) => ({
        ...breakpoint,
        id
    }))

    for (const pageBreakpoint of pageBreakpoints) {
        if (_.isEmpty(possibleMatchingBreakpoints)) {
            break
        }

        const bestMatchBreakpointId = getBestMatchingBreakpoint(pageBreakpoint, possibleMatchingBreakpoints)
        if (bestMatchBreakpointId) {
            matches[bestMatchBreakpointId] = pageBreakpoint.id
            delete possibleMatchingBreakpoints[bestMatchBreakpointId]
        }
    }

    return {
        matches,
        breakpointsToRemove: new Set(_.keys(possibleMatchingBreakpoints))
    }
}

export const removeUnmatchedBreakpoints = (
    extensionContext: CreateExtArgs,
    components: RawComponentExport['components'],
    breakpointsToRemove: MatchResult['breakpointsToRemove']
) => {
    const {extensionAPI, coreConfig} = extensionContext
    const {schemaService} = coreConfig

    if (breakpointsToRemove.size === 0) {
        return
    }

    const {data} = extensionAPI as DataExtensionAPI
    _.forOwn(components, component => {
        _.forOwn(VARIANTS_DATA_TYPES, (namespace: string) => {
            const dataItem = component.data[namespace]
            if (!dataItem) {
                return
            }

            forEachReferredDataItem(
                schemaService,
                dataItem,
                namespace,
                ({item, itemRefInfo, ownerItem = component.data}: ReferredDataItemsIterateeContext) => {
                    if (itemRefInfo.isRelationalSplit && data.refArray.isRefArray(item)) {
                        _.remove(
                            item.values,
                            (value: DalValue) =>
                                data.variantRelation.isVariantRelation(value) &&
                                value.variants.some((variant: string) =>
                                    breakpointsToRemove.has(stripHashIfExists(variant))
                                )
                        )

                        if (item.values.length === 0) {
                            _.unset(ownerItem, itemRefInfo.path)
                        }
                    }
                }
            )
        })
    })
}

// TODO: This is a temporary fix to keep the breakpoints in local editor, will be resolved as part of DM-9711
const temporaryFixLocalEditorBreakpoints = (
    extensionContext: CreateExtArgs,
    componentToReplace: CompRef,
    page: RawComponentExportStructure,
    externals: ExternalsMap
) => {
    const {breakpointVariants} = page.data

    if (breakpointVariants) {
        updateComponentDataItem(
            extensionContext,
            componentToReplace,
            breakpointVariants,
            DATA_TYPES.variants,
            externals
        )
    }
}

export const mergeBreakpointsToPage = (
    extensionContext: CreateExtArgs,
    componentToReplace: CompRef,
    page: RawComponentExportStructure,
    breakpoints: BreakpointsMap,
    externals: ExternalsMap
) => {
    const dataItem = {
        componentId: page.structure.id,
        type: BREAKPOINTS_DATA,
        values: _.map(breakpoints, (breakpoint: Breakpoint, id: string) => ({
            ...breakpoint,
            id,
            type: BREAKPOINT_RANGE
        }))
    }

    updateComponentDataItem(extensionContext, componentToReplace, dataItem, DATA_TYPES.variants, externals)
}

export const mergePageBreakpoints = (
    extensionContext: CreateExtArgs,
    componentToReplace: CompRef,
    component: RawComponentExportStructure,
    componentExport: RawComponentExport,
    externals: ExternalsMap
) => {
    const {pointers, extensionAPI} = extensionContext
    const {dataModel, componentDefinition} = extensionAPI as DataModelExtensionAPI & ComponentDefinitionExtensionAPI
    const {components, externalRefs, page} = componentExport
    const {breakpoints} = page

    if (_.isEmpty(breakpoints)) {
        return
    }

    page.breakpoints = {}

    // TODO: DM-9711
    // if (componentDefinition.isPage(component.structure.componentType)) {
    //     mergeBreakpointsToPage(extensionContext, componentToReplace, component, breakpoints, externals)
    //     return
    // }

    if (componentDefinition.isPage(component.structure.componentType)) {
        temporaryFixLocalEditorBreakpoints(extensionContext, componentToReplace, component, externals)
    }

    const pagePointer = pointers.structure.getPageOfComponent(componentToReplace) as CompRef
    const pageBreakpointsData = dataModel.components.getItem(pagePointer, DATA_TYPES.variants)
    const {matches, breakpointsToRemove} = matchBreakpoints(breakpoints, pageBreakpointsData?.values)
    _.assign(externalRefs, matches)
    removeUnmatchedBreakpoints(extensionContext, components, breakpointsToRemove)
}
