'use strict'
const _ = require('lodash')
const {createPageManager} = require('../helpers/pageManager')
const {migrateToNewMenuComponent, NEW_MENU} = require('../helpers/oldMenuToNewMenuHelper')
const {
    migrateStylableComponentsByCompType
} = require('../helpers/stylableMigration/migrateStylableComponentsByCompType')
const {NAMESPACE_MAPPING} = require('@wix/document-services-json-schemas/dist/ts/build/namespaceMapping')
const STYLABLE_HORIZONTAL_MENU = 'wixui.StylableHorizontalMenu'

const parseValueWithUnit = valueWithUnit => {
    const numericValue = parseFloat(valueWithUnit)
    const unit = extractUnit(valueWithUnit)

    return {
        value: numericValue,
        unit: unit || 'px'
    }
}

const extractUnit = valueWithUnit => {
    const DECIMALS_REGEX = /-?\d+(\.\d+)?/
    const FALLBACK_UNIT = 'px'
    return valueWithUnit.replace(DECIMALS_REGEX, '') || FALLBACK_UNIT
}

const getComponentsByType = (components, compType) => {
    const filteredComponents = components.filter(comp => comp.componentType === compType)
    return filteredComponents
}
const migrateOverflow = (currentBreakpointStylesProperties, componentProps) => {
    currentBreakpointStylesProperties.overflow = componentProps.menuMode === 'wrap' ? 'Wrap' : 'Scroll'
}
const addDefaultValues = currentBreakpointStylesProperties => {
    currentBreakpointStylesProperties.showDropDownIcon = false
    currentBreakpointStylesProperties.addDividers = false
    currentBreakpointStylesProperties.spacingBetweenLabelAndDropdownIcon = '0px'
    currentBreakpointStylesProperties.orientation = 'horizontal'
}
const migratePadding = currentBreakpointStylesProperties => {
    currentBreakpointStylesProperties['item-vertical-padding'] =
        currentBreakpointStylesProperties['item-padding-top'] || '0px'
    currentBreakpointStylesProperties['item-horizontal-padding'] =
        currentBreakpointStylesProperties['item-padding-right'] || '0px'
}

const migrateSpacing = styles => {
    // in SHM the spacing between items is the same as the margin between items
    // in SHM layout panel, when you set spacing=x, it sets margin-left and margin-right to x/2
    const getSpacing = marginProperty => {
        const {value, unit} = parseValueWithUnit(styles[marginProperty])
        return `${value * 2}${unit}`
    }

    styles['menu-items-main-axis-gap'] = getSpacing('item-margin-left')
    styles['menu-items-cross-axis-gap'] = getSpacing('item-margin-top')
}

const processLayoutStyles = (stylesProps, componentProps) => {
    addDefaultValues(stylesProps)
    migrateOverflow(stylesProps, componentProps)
    migratePadding(stylesProps)
    migrateSpacing(stylesProps)
    stylesProps.isContainerStretched = componentProps.isContainerStretched
}

const migrateStylesAndLayoutPanelForEachBreakpoint = (oldComponent, pageManager) => {
    const styleKeys = pageManager.data.getItem(NAMESPACE_MAPPING.style, oldComponent.styleId)
    const cleanedPropsKey = oldComponent.propertyQuery.replace('#', '')
    const componentProps = pageManager.data.getItem(NAMESPACE_MAPPING.props, cleanedPropsKey)

    styleKeys.values.forEach(styleKey => {
        const cleanedStyleKey = styleKey.replace('#', '')
        const currentBreakpoint = pageManager.data.getItem(NAMESPACE_MAPPING.style, cleanedStyleKey)
        //Different breakoint than the Desktop/root
        if (currentBreakpoint.type === 'VariantRelation') {
            const variantKey = currentBreakpoint.to.replace('#', '')
            const variantStyles = pageManager.data.getItem(NAMESPACE_MAPPING.style, variantKey)?.style?.properties
            processLayoutStyles(variantStyles, componentProps)
        }
        //Desktop/root breakpoint
        if (currentBreakpoint.type === 'ComponentStyle') {
            const componentStyles = currentBreakpoint?.style?.properties
            processLayoutStyles(componentStyles, componentProps)
        }
    })
}

const CSS_PROP_TO_VAR_NAME = {
    label: {
        'text-decoration-line': 'text-decoration',
        'background-color': 'text-highlight'
    },
    scrollButtonIcon: {
        display: 'icon-display',
        width: 'icon-size',
        height: 'icon-size',
        fill: 'icon-color',
        transform: 'icon-rotation'
    }
}

const migrateSHMToNonStylable = ({structure, data, uniqueIdGenerator, clientSpecMap}) => {
    migrateStylableComponentsByCompType({
        structure,
        data,
        uniqueIdGenerator,
        clientSpecMap,
        newSkin: NEW_MENU.SKIN,
        compType: STYLABLE_HORIZONTAL_MENU,
        useCssColor: true,
        withBorderRadiusAsOneParam: true,
        selectorsMappings: {
            '.root': {
                name: 'container'
            },
            '.root::menuItem': {
                name: 'item'
            },
            '.root::menuItem:isCurrentPage': {
                name: 'item-selected',
                root: 'item'
            },
            '.root::menuItem:isHovered': {
                name: 'item-hover',
                root: 'item'
            },
            '.root::menuItem:hover': {
                name: 'item-hover',
                root: 'item'
            },
            '.root::menuItem::label': {
                name: 'item',
                properties: CSS_PROP_TO_VAR_NAME.label
            },
            '.root::menuItem:isCurrentPage::label': {
                name: 'item-selected',
                root: 'item',
                properties: CSS_PROP_TO_VAR_NAME.label
            },
            '.root::menuItem:isHovered::label': {
                name: 'item-hover',
                root: 'item',
                properties: CSS_PROP_TO_VAR_NAME.label
            },
            '.root::menuItem:hover::label': {
                name: 'item-hover',
                root: 'item',
                properties: CSS_PROP_TO_VAR_NAME.label
            },
            '.root::scrollButton': {
                name: 'scroll-button'
            },
            '.root::scrollButton:hover': {
                name: 'scroll-button-hover',
                root: 'scroll-button'
            },
            '.root::scrollButton::icon': {
                name: 'scroll-button',
                properties: CSS_PROP_TO_VAR_NAME.scrollButtonIcon
            },
            '.root::scrollButton:hover::icon': {
                name: 'scroll-button-hover',
                root: 'scroll-button',
                properties: CSS_PROP_TO_VAR_NAME.scrollButtonIcon
            }
        }
    })
}

function migratePage({pageManager, structure, data, uniqueIdGenerator, clientSpecMap}) {
    migrateSHMToNonStylable({structure, data, uniqueIdGenerator, clientSpecMap})
    const {desktopComponents} = pageManager.structure.getAllComponentsByContainingChildType(STYLABLE_HORIZONTAL_MENU)

    _.forEach(desktopComponents, parentComponent => {
        const shmComponents = getComponentsByType(parentComponent.components, STYLABLE_HORIZONTAL_MENU)

        _.forEach(shmComponents, component => {
            migrateStylesAndLayoutPanelForEachBreakpoint(component, pageManager)
            migrateToNewMenuComponent(component)
        })
    })
}

module.exports = {
    name: 'migrateStylableHorizontalMenuToNewMenu',
    version: 0,
    experimentalVersions: [{version: 1, experiment: 'dm_migrateStylableHorizontalMenuToNewMenu'}],
    migrateSHMToNonStylable,
    exec(pageJson, _pageIdsArray, magicObject) {
        if (!magicObject.isExperimentOpen('dm_migrateStylableHorizontalMenuToNewMenu')) {
            return
        }
        const {clientSpecMap} = magicObject
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const pageManager = createPageManager(pageJson, uniqueIdGenerator)
        const {data, structure} = pageJson

        migratePage({pageManager, structure, data, uniqueIdGenerator, clientSpecMap})
    }
}
