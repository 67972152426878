import type {Pointer} from '@wix/document-services-types'
import type {DalItem} from '@wix/document-manager-core'
import * as constants from '../../../constants/constants'

import type {DataModelAPI, DataModelExtensionAPI} from '../../dataModel/dataModel'
import _ from 'lodash'

import type {DeserializeComponentParams} from './types'
import {addStyleToStructure} from './namespaces/style'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'

const {DATA_TYPES} = constants

const createItemByType = (dataModel: DataModelAPI, item: string, itemType: string) => {
    switch (itemType) {
        case DATA_TYPES.data:
            return dataModel.createDataItemByType(item)
        case DATA_TYPES.prop:
            return dataModel.createPropertiesItemByType(item)
        case DATA_TYPES.behaviors:
            return dataModel.createBehaviorsItem(item)
        default:
            return null
    }
}
const addRepeatedDataToStructure = (
    dataModel: DataModelAPI,
    item: DalItem,
    itemType: string,
    pageId: string,
    customId?: string
) => {
    const itemQueryPointer = dataModel.addItem(item.original, itemType, pageId, customId)

    _.forEach(item.overrides, function (overriddenItem, itemId) {
        const uniqueId = displayedOnlyStructureUtil.getUniqueDisplayedId(itemQueryPointer?.id, itemId)
        dataModel.addItem(overriddenItem, itemType, pageId, uniqueId)
    })
    return itemQueryPointer
}

export const addNamespaceToStructure = (
    deserializationParams: DeserializeComponentParams,
    item: DalItem | string | undefined,
    itemType: string,
    oldToNewIdMap: {[key: string]: string},
    stylesPerPage?: boolean
): string | null => {
    const {createExtArgs, compStructure, pageId, customId} = deserializationParams
    const {extensionAPI, coreConfig} = createExtArgs

    const {dataModel} = extensionAPI as DataModelExtensionAPI
    let itemQueryPointer: Pointer | null = null
    if (item) {
        if (itemType === DATA_TYPES.theme) {
            itemQueryPointer = addStyleToStructure(
                extensionAPI,
                coreConfig,
                item,
                pageId,
                compStructure.componentType,
                customId,
                stylesPerPage
            )
        } else if (_.isObject(item)) {
            if (item.type === 'RepeatedData') {
                itemQueryPointer = addRepeatedDataToStructure(dataModel, item, itemType, pageId, customId)
            } else {
                itemQueryPointer = dataModel.addItem(item, itemType, pageId, customId, '', oldToNewIdMap)
            }
        } else if (_.isString(item)) {
            const defaultDataItem = createItemByType(dataModel, item, itemType)
            if (defaultDataItem) {
                itemQueryPointer = dataModel.addItem(defaultDataItem, itemType, pageId, customId)
            }
        }
    }
    return itemQueryPointer?.id ?? null
}
