import type {ValueOf} from './basicTypes'

export interface AutosaveConfig {
    enabled: boolean
    /* how many user action trigger an autosave */
    AUTOSAVE_ACTION_COUNT?: number
    /* how many autosave trigger a save */
    SAVE_AFTER_AUTOSAVE_COUNT?: number
    AUTOSAVE_TIMEOUT?: number
    DEBOUNCE_WAIT?: number
    onDiffSaveStarted?(trigger: string): void
    onDiffSaveFinished?(error: DocumentServiceError, trigger: string): void
    onPartialSaveStarted?(trigger: string): void
    onPartialSaveFinished?(error: DocumentServiceError, trigger: string): void
    onRefreshRequired?(reason: OnRefreshRequiredReason): void
}

export interface AutosaveConfigInfo {
    shouldAutoSave: boolean
}

export interface SaveErrors {
    UNKNOWN_SERVER_ERROR: 'UNKNOWN_SERVER_ERROR'
    HTTP_REQUEST_ERROR: 'HTTP_REQUEST_ERROR'
    SAVE_DISABLED_IN_DOCUMENT_SERVICES: 'SAVE_DISABLED_IN_DOCUMENT_SERVICES'
    SAVE_IN_PROGRESS: 'SAVE_IN_PROGRESS'
    NOT_LOGGED_IN: 'NOT_LOGGED_IN'
    SESSION_EXPIRED: 'SESSION_EXPIRED'
    USER_NOT_AUTHORIZED_FOR_SITE: 'USER_NOT_AUTHORIZED_FOR_SITE'
    WORKSPACE_ID_MISMATCH: 'WORKSPACE_ID_MISMATCH'
    PARTIAL_SAVE_THROTTLE: 'PARTIAL_SAVE_THROTTLE'
    CONCURRENT_SAVE: 'CONCURRENT_SAVE_ERROR'
    SITE_NAME_TAKEN: 'SITE_NAME_TAKEN_ERROR'
    DUPLICATE_COMPONENTS: 'DUPLICATE_COMPONENTS'
    DATA_REFERENCE_MISMATCH: 'DATA_REFERENCE_MISMATCH'
    MISSING_CONTAINERS: 'MISSING_CONTAINERS'
    APP_CONTROLLER_REFERENCE_MISMATCH: 'APP_CONTROLLER_REFERENCE_MISMATCH'
    DESIGN_REFERENCE_MISMATCHES: 'DESIGN_REFERENCE_MISMATCHES'
    MISSING_REFERENCE: 'MISSING_REFERENCE'
    /** @deprecated */
    BEHAVIOR_REFERENCE_MISMATHCHES: 'BEHAVIOR_REFERENCE_MISMATHCHES'
    BEHAVIOR_REFERENCE_MISMATCHES: 'BEHAVIOR_REFERENCE_MISMATCHES'
    PROPERTY_REFERENCE_MISMATCHES: 'PROPERTY_REFERENCE_MISMATCHES'
    STYLE_REFERENCE_MISMATCHES: 'STYLE_REFERENCE_MISMATCHES'
    CONNECTION_LIST_REFERENCE_MISMATCHES: 'CONNECTION_LIST_REFERENCE_MISMATCHES'
    SITE_DESERIALIZATION_ERROR: 'SITE_DESERIALIZATION_ERROR'
    SAVE_PUBLISH_DISABLED_ON_SERVER: 'SAVE_PUBLISH_DISABLED_ON_SERVER'
    SAVE_PUBLISH_RC_FAILED_ON_SERVER: 'SAVE_PUBLISH_RC_FAILED_ON_SERVER'
    CONCURRENT_AUTO_SAVE: 'CONCURRENT_AUTO_SAVE'
    SITE_STALE_STATE_FROM_AUTO_SAVE: 'SITE_STALE_STATE_FROM_AUTO_SAVE'
    AUTOSAVE_DISABLE_FROM_SERVER: 'AUTOSAVE_DISABLE_FROM_SERVER'
    RC_EXISTS: 'RC_EXISTS'
    RC_ROLLED_OUT: 'RC_ROLLED_OUT'
    ABAC_ERROR: 'ABAC_ERROR'
}

export interface SeoErrors {
    KEYWORDS_INVALID_CHARS: 'KEYWORDS_INVALID_CHARS'
    METATAGS_INVALID_CHARS: 'METATAGS_INVALID_CHARS'
    METATAGS_INVALID_FORMAT: 'METATAGS_INVALID_FORMAT'
    METATAGS_SERVER_INVALID_CODE: 'METATAGS_SERVER_INVALID_CODE'
    METATAGS_SERVER_INVALID_TAG: 'METATAGS_SERVER_INVALID_TAG'
    REDIRECT_FROM_URIS_IS_NOT_ARRAY: 'REDIRECT_FROM_URIS_IS_NOT_ARRAY'
    REDIRECT_INVALID_CHARS: 'REDIRECT_INVALID_CHARS'
    REDIRECT_MAPPING_INVALID_FORMAT: 'REDIRECT_MAPPING_INVALID_FORMAT'
    REDIRECT_MAPPING_URIS_NOT_STRING: 'REDIRECT_MAPPING_URIS_NOT_STRING'
    REDIRECT_URI_MAPPING_IS_NOT_OBJECT: 'REDIRECT_URI_MAPPING_IS_NOT_OBJECT'
    SERVER_VALIDATION_TIMEOUT: 'SERVER_VALIDATION_TIMEOUT'
    SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN: 'SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN'
    TEXT_INVALID_CHARS: 'TEXT_INVALID_CHARS'
    TEXT_IS_NOT_STRING: 'TEXT_IS_NOT_STRING'
    TEXT_TOO_LONG: 'TEXT_TOO_LONG'
}

export interface BuildErrors {
    INVALID_VERSION_TYPE: 'INVALID_VERSION_TYPE'
    NO_GRID_APP_ID: 'NO_GRID_APP_ID'
    NO_STUDIO_APP_APP_ID: 'NO_STUDIO_APP_APP_ID'
    UNKNOWN_SERVER_ERROR: 'UNKNOWN_SERVER_ERROR'
    USER_NOT_AUTHORIZED_FOR_APP: 'USER_NOT_AUTHORIZED_FOR_Seo'
}

interface SiteNameErrors {
    SITE_NAME_ALREADY_EXISTS: 'SITE_NAME_ALREADY_EXISTS'
    SITE_NAME_ENDS_WITH_HYPHEN: 'SITE_NAME_ENDS_WITH_HYPHEN'
    SITE_NAME_INVALID_CHARS: 'SITE_NAME_INVALID_CHARS'
    SITE_NAME_IS_EMPTY: 'SITE_NAME_IS_EMPTY'
    SITE_NAME_IS_NOT_STRING: 'SITE_NAME_IS_NOT_STRING'
    SITE_NAME_TOO_LONG: 'SITE_NAME_TOO_LONG'
    SITE_NAME_TOO_SHORT: 'SITE_NAME_TOO_SHORT'
}

export interface DocumentServiceErrorTypes {
    build: BuildErrors
    seo: SeoErrors
    social: {
        facebook: {
            FB_THUMBNAIL_IS_NOT_STRING: 'FB_THUMBNAIL_IS_NOT_STRING'
            FB_USERNAME_INVALID_CHARS: 'FB_USERNAME_INVALID_CHARS'
            FB_USERNAME_IS_NOT_STRING: 'FB_USERNAME_IS_NOT_STRING'
            FB_USERNAME_TOO_LONG: 'FB_USERNAME_TOO_LONG'
        }
    }
    favicon: {
        FAVICON_IS_NOT_STRING: 'FAVICON_IS_NOT_STRING'
    }
    siteName: SiteNameErrors
    save: SaveErrors
}

export type DocumentServiceErrorType = ValueOf<DocumentServiceErrorTypes['save']>

export interface DocumentServiceErrorInfo {
    errorCode: number
    errorType: DocumentServiceErrorType
    errorDescription?: string
}

export interface DocumentServiceError {
    // NOTE: deprecated?
    document?: DocumentServiceErrorInfo
    documentServices?: DocumentServiceErrorInfo
}

/**
 * OUT_OF_SYNC - out of sync
 * VERSION_RESTORED - site version was restored
 * OUTDATED_VERSION - DM version is outdated
 * ERROR_PROCESSING_TRANSACTION - A failure processing updates from Duplexer, so the client is out of sync with the server
 */
export const onRefreshRequiredErrorTypes = {
    OUT_OF_SYNC: 'OUT_OF_SYNC',
    VERSION_RESTORED: 'VERSION_RESTORED',
    OUTDATED_VERSION: 'OUTDATED_VERSION',
    ERROR_PROCESSING_TRANSACTION: 'ERROR_PROCESSING_TRANSACTION'
}

export type OnRefreshRequiredReason = (typeof onRefreshRequiredErrorTypes)[keyof typeof onRefreshRequiredErrorTypes]
