import _ from 'lodash'
import type {
    Pointer,
    ExternalsBuilderMap,
    ExternalsMap,
    Namespace,
    PlainObject,
    ComponentFeatures,
    ComponentFeature,
    PredefinedComponentFeaturesGroups
} from '@wix/document-services-types'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, EDITOR_DATA_TYPES} from '../../../constants/constants'
import type {DalValue, ExtensionAPI} from '@wix/document-manager-core'
import {
    DATASET_APPLICATION_ID,
    DATASET_COMPONENT_TYPE,
    DATASET_CONTROLLER_TYPE,
    ExtendedRefInfo,
    UnmarkedRefInfo,
    getComponentFeatures,
    unmarkedReferences,
    type DetailedValue
} from '@wix/import-export-utils'
// eslint-disable-next-line wix-editor/no-internal-import
import * as referableByExternalNodesJson from '@wix/document-services-json-schemas/dist/referableByExternalNodes.json'

export interface ExternalsHandler<T = DalValue> {
    pack(
        ref: DetailedValue<T>,
        externals: ExternalsBuilderMap,
        refPointer: Pointer,
        item: DalValue,
        extensionAPI: ExtensionAPI
    ): void
    unpack(ref: DetailedValue<T>, externals: ExternalsMap, refFieldInfo: ExtendedRefInfo, item: DalValue): void
}

export const COMPONENT_FEATURES: ComponentFeatures = getComponentFeatures(COMP_DATA_QUERY_KEYS_WITH_STYLE)

const EDITOR_DATA_TYPES_SET: Set<Namespace> = new Set([
    DATA_TYPES.variants,
    DATA_TYPES.mobileHints,
    DATA_TYPES.fixerVersions,
    ...Object.values(EDITOR_DATA_TYPES)
])

export const PREDEFINED_COMPONENT_FEATURES_GROUPS: PredefinedComponentFeaturesGroups = {
    All: COMPONENT_FEATURES,
    PublicViewerData: _.omitBy(COMPONENT_FEATURES, ({namespace}: ComponentFeature) =>
        EDITOR_DATA_TYPES_SET.has(namespace)
    )
}

export const COMPONENT_QUERY_TO_NAMESPACE_MAP = _(COMPONENT_FEATURES)
    .keyBy('query')
    .mapValues(({namespace}: ComponentFeature) => namespace)
    .value()

export const COMPONENT_QUERIES = new Set(Object.keys(COMPONENT_QUERY_TO_NAMESPACE_MAP))

export const UNNECESSARY_PROPS = ['parent', 'metaData', 'mobileMetaData', 'modes', 'componentProperties', 'themeData']
export const BASE_STRUCTURE_PROPERTIES = ['layout']
export const STRUCTURE_PROPERTIES_TO_KEEP = new Set([...UNNECESSARY_PROPS, 'components', 'id'])

export const DEFAULT_BREAKPOINTS_MAX_RANGE = 2 ** 31 - 1
export const DEFAULT_BREAKPOINTS_MIN_RANGE = 320

export const isDatasetAppController = (
    componentType: string,
    applicationId: string,
    controllerType: string
): boolean => {
    return (
        componentType === DATASET_COMPONENT_TYPE &&
        applicationId === DATASET_APPLICATION_ID &&
        controllerType === DATASET_CONTROLLER_TYPE
    )
}

const unmarkedReferrableTypes: Record<Namespace, Record<string, boolean>> = _.mapValues(
    unmarkedReferences,
    (items: Record<string, UnmarkedRefInfo[]>) => {
        const referredTypes = {}
        _.forOwn(items, (references: UnmarkedRefInfo[]) => {
            for (const {refTypes} of references) {
                _.forEach(refTypes, (refType: string) => {
                    referredTypes[refType] = true
                })
            }
        })
        return referredTypes
    }
)

export const REFERABLE_BY_EXTERNAL_NODES = _.merge(referableByExternalNodesJson, unmarkedReferrableTypes)

export const SPECIAL_DATA_NODE_IDS: Record<Namespace, Set<string>> = {
    data: new Set(['APPS_INSTALL_STATE', 'CUSTOM_MAIN_MENU', 'CUSTOM_MENUS', 'MAIN_MENU', 'PAGES_GROUP_COLLECTION']),
    props: new Set(['SITE_PAGES']),
    style: new Set(['THEME_DATA'])
}

export const SPECIAL_DATA_NODE_IDS_MAP: PlainObject = _(SPECIAL_DATA_NODE_IDS)
    .flatMap((idsSet: Set<string>) => Array.from(idsSet))
    .keyBy((id: string) => id)
    .value()
