import {displayComponentsOrderSingleSection, displayComponentsOrderAllSections} from './debugTools/componentsOrder'
import {
    displayGroupsAllSections,
    displayGroupsSingleSection,
    logGroupTree,
    IdMapper,
    sendPageLayoutInputToBo
} from './debugTools/grouping'
import {displayOrderSingleSection, displayOrderAllSections} from './debugTools/order'
import {getDomAndDmContent} from './domAndDmContenxt'
import {createOverlayManager} from './overlayManager/overlayManager'
import type {DAL, DocumentManager} from '@wix/document-manager-core'
import type {MobileAlgoExtensionApi} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/mobileAlgo'
import type {DebugToolsConfig} from './types'
import type {RMApi} from '@wix/document-manager-extensions'

const defaultConfig: DebugToolsConfig = {
    colorPallet: 'triadic'
}

export interface MobileAlgoDebugTools {
    oldAlgo: {
        displayComponentsOrderInSection(sectionId: string): void
        displayComponentsOrderInAllSection(): void
    }
    newAlgo: {
        displayComponentsOrderInSections(sectionId: string): void
        displayComponentsOrderInAllSection(dal: DAL): void
        displayGroupingMapInSections(sectionId: string, config?: DebugToolsConfig): void
        displayGroupingMapInAllSection(config?: DebugToolsConfig): void
        logGroupTree(sectionId: string, idMapper?: IdMapper): void
        sendPageLayoutInputToBo(): void
    }
    cleanup(): void
}

export const createMobileAlgoDebugTools = (documentManager: DocumentManager): MobileAlgoDebugTools => {
    const overlayManager = createOverlayManager()
    const {mobileAlgo} = documentManager.extensionAPI as MobileAlgoExtensionApi
    const siteAPI = documentManager.extensionAPI as RMApi
    const {dal} = documentManager

    return {
        oldAlgo: {
            displayComponentsOrderInSection: (sectionId: string) =>
                displayComponentsOrderSingleSection(getDomAndDmContent().documentServices, overlayManager, sectionId),
            displayComponentsOrderInAllSection: () =>
                displayComponentsOrderAllSections(getDomAndDmContent().documentServices, overlayManager)
        },
        newAlgo: {
            displayComponentsOrderInSections: (sectionId: string) =>
                displayOrderSingleSection(mobileAlgo, sectionId, overlayManager),
            displayComponentsOrderInAllSection: () =>
                displayOrderAllSections(getDomAndDmContent().documentServices, mobileAlgo, overlayManager, dal),
            displayGroupingMapInSections: (sectionId: string, config?: DebugToolsConfig) =>
                displayGroupsSingleSection(mobileAlgo, sectionId, overlayManager, config ?? defaultConfig),
            displayGroupingMapInAllSection: (config?: DebugToolsConfig) =>
                displayGroupsAllSections(
                    getDomAndDmContent().documentServices,
                    mobileAlgo,
                    overlayManager,
                    dal,
                    config ?? defaultConfig
                ),
            logGroupTree: (sectionId: string, idMapper?: IdMapper) => logGroupTree(mobileAlgo, sectionId, idMapper),
            sendPageLayoutInputToBo: () =>
                sendPageLayoutInputToBo(getDomAndDmContent().documentServices, mobileAlgo, siteAPI, dal)
        },
        cleanup: () => overlayManager.cleanup()
    }
}
