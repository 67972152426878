import responsiveConfig from './responsiveConfig'
import _ from 'lodash'

const getResponsiveDeployerConfig = (origin: string, overrides: Record<string, any> = {}) => ({
    ...responsiveConfig,
    acceptTransactionsFromDuplexer: false,
    cedit: false,
    // TODO: this should be fixed when correct serviceTopology received {@see DM-10769}
    extensionsList: _.without(responsiveConfig.extensionsList, 'componentsRegistry'),
    ...overrides
})

export const responsive = getResponsiveDeployerConfig('responsiveWmlSiteDeployer')
export const blocks = getResponsiveDeployerConfig('blocksWmlSiteDeployer', {
    supportsUsingPresetVariants: true,
    modulesList: responsive.modulesList.concat(['appStudioPublicAPI'])
})
