import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {getComponentType} from '../../../utils/dalUtils'
import {isPopup} from '../../page/popupUtils'

const PAGE_COMP_TYPES = [
    'mobile.core.components.Page',
    'core.components.Page',
    'wixapps.integration.components.AppPage'
]
const ANCHOR_COMP_TYPE = 'wysiwyg.viewer.components.ClassicSection'

const containableByStructure = (
    createExtArgs: CreateExtArgs,
    childStructure: CompStructure,
    containerPointer: Pointer
) => {
    if (!containerPointer) {
        return false
    }

    const containerCompType = getComponentType(createExtArgs.dal, containerPointer)
    const pagePointer = createExtArgs.pointers.structure.getPageOfComponent(containerPointer)
    const isContainerPage = _.includes(PAGE_COMP_TYPES, containerCompType) && !isPopup(createExtArgs, pagePointer.id)
    const isContainerClassicSection = containerCompType === ANCHOR_COMP_TYPE

    return isContainerPage || isContainerClassicSection
}

export const anchorMetaData = {
    fullWidthByStructure: true,
    fullWidth: true,
    containableByStructure
}
