import {VIEW_MODES} from '../../constants/constants'
import _ from 'lodash'
import {CreateExtArgs, pointerUtils} from '@wix/document-manager-core'
import type {FeaturesExtensionAPI} from '../features/features'
import {getBlocksComponentNickname, getData, getRootAppWidgetByPage} from './blocksDataModel'
import type {Pointer} from '@wix/document-services-types'

const {getPointer} = pointerUtils

export const MANIFEST_FEATURE_NAME = 'manifest'
const STAGE_DATA = ['gfpp', 'behavior', 'displayName']

const hasLabel = (button: any) => !!button?.label

const convertTranslatableLabelIfNeeded = (button: any, buttonPath: any, translationPathsAccumulator: any) => {
    if (hasLabel(button)) {
        if (button.label.shouldTranslate) {
            translationPathsAccumulator.push(`${buttonPath}.label`)
        }
        return {...button, label: button.label.value}
    }

    return button
}

const getTranslatedGfppData = (gfppData: any, basePath: string) => {
    const gfppTranslationPaths: any[] = []
    const translatedGfppData: any = {
        desktop: {}
    }
    if (gfppData.desktop?.mainAction1) {
        translatedGfppData.desktop.mainAction1 = convertTranslatableLabelIfNeeded(
            gfppData.desktop.mainAction1,
            `${basePath}.gfpp.desktop.mainAction1`,
            gfppTranslationPaths
        )
    }
    if (gfppData.desktop?.mainAction2) {
        translatedGfppData.desktop.mainAction2 = convertTranslatableLabelIfNeeded(
            gfppData.desktop.mainAction2,
            `${basePath}.gfpp.desktop.mainAction2`,
            gfppTranslationPaths
        )
    }
    if (gfppData.desktop?.iconButtons) {
        translatedGfppData.desktop.iconButtons = gfppData.desktop.iconButtons
    }
    return {translatedGfppData, gfppTranslationPaths}
}

const applyGfppToMobile = (translatedGfppData: any, gfppTranslationPaths: any) => {
    translatedGfppData.mobile = _.cloneDeep(translatedGfppData.desktop)
    const mobileTranslations = gfppTranslationPaths.map((path: string) => path.replace('gfpp.desktop', 'gfpp.mobile'))
    return {
        gfppWithMobile: translatedGfppData,
        translationPathsWithMobile: gfppTranslationPaths.concat(mobileTranslations)
    }
}

const getWidgetStageDataWithTranslationPaths = (stageData: any, basePath: string) => {
    const translationPaths = []
    const newStageData: any = {}
    if (stageData.displayName) {
        if (stageData.displayName.shouldTranslate) {
            translationPaths.push(`${basePath}.displayName`)
        }
        newStageData.displayName = stageData.displayName.value
    }
    if (stageData.gfpp) {
        const {translatedGfppData, gfppTranslationPaths} = getTranslatedGfppData(stageData.gfpp, basePath)
        const {gfppWithMobile, translationPathsWithMobile} = applyGfppToMobile(translatedGfppData, gfppTranslationPaths)
        newStageData.gfpp = gfppWithMobile
        translationPaths.push(...translationPathsWithMobile)
    }
    if (stageData.behavior) {
        newStageData.behavior = stageData.behavior
    }
    return {
        stageData: newStageData,
        translationPaths
    }
}

const getInnerCompsStageData = (createExtArgs: CreateExtArgs, rootAppWidgetRef: Pointer) => {
    const {pointers, extensionAPI} = createExtArgs
    const {features} = extensionAPI as FeaturesExtensionAPI

    const translationPaths: any[] = []
    const widgetComps = pointers.structure.getChildrenRecursively(rootAppWidgetRef)
    const connections = _.reduce(
        widgetComps,
        (result, compRef) => {
            const manifestData = features.component.get(compRef, MANIFEST_FEATURE_NAME)
            if (manifestData) {
                const role = getBlocksComponentNickname(createExtArgs, compRef)!
                const {stageData, translationPaths: compTranslationPaths} = getWidgetStageDataWithTranslationPaths(
                    _.pick(manifestData, STAGE_DATA),
                    `default.connections.${role}`
                )
                result[role] = stageData
                translationPaths.push(...compTranslationPaths)
            }
            return result
        },
        {}
    )
    return {
        connections,
        translationPaths
    }
}

export const getWidgetManifest = (createExtArgs: CreateExtArgs, widgetPointer: Pointer) => {
    const {features} = createExtArgs.extensionAPI as FeaturesExtensionAPI

    const widgetData = getData(createExtArgs, widgetPointer)
    const widgetRootRef = getPointer(_.trimStart(widgetData.rootCompId, '#'), VIEW_MODES.DESKTOP)
    const pageRef = createExtArgs.pointers.structure.getPageOfComponent(widgetRootRef)
    const rootAppWidgetRef = getRootAppWidgetByPage(createExtArgs, pageRef)

    if (rootAppWidgetRef) {
        const manifestData = features.component.get(rootAppWidgetRef, 'manifest')
        const {stageData: widgetStageData, translationPaths: widgetTranslationPaths} =
            getWidgetStageDataWithTranslationPaths(_.pick(manifestData, STAGE_DATA) as any, 'default')
        const {connections, translationPaths: connectionsTranslationPaths} = getInnerCompsStageData(
            createExtArgs,
            rootAppWidgetRef
        )

        const widgetConnections: Record<string, any> = {}
        const widgetConnectionsTranslationPaths: string[] = []

        if (manifestData?.connections) {
            Object.entries(manifestData.connections).forEach(([role, manifestStageData]) => {
                const {stageData, translationPaths} = getWidgetStageDataWithTranslationPaths(
                    manifestStageData,
                    `default.connections.${role}`
                )
                widgetConnections[role] = stageData
                widgetConnectionsTranslationPaths.push(...translationPaths)
            })
        }

        if (!_.isEmpty(widgetConnections) || !_.isEmpty(widgetStageData) || !_.isEmpty(connections)) {
            return {
                manifest: {
                    default: {
                        ...widgetStageData,
                        connections: {
                            ...widgetConnections,
                            ...connections
                        }
                    }
                },
                translationPaths: [
                    ...widgetConnectionsTranslationPaths,
                    ...widgetTranslationPaths,
                    ...connectionsTranslationPaths
                ]
            }
        }
    } else {
        return {
            manifest: {
                default: {}
            },
            translationPaths: []
        }
    }
}
