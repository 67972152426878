import type {DalValue, Pointer, VariantsReplacementOperation} from '@wix/document-services-types'

interface RelationData {
    originalVariants: Set<string>
    transformedVariants: Set<string>
    relationPointer?: Pointer
    from?: string
    to?: string
    value?: DalValue
    indexInRefArray?: number
}

interface ScopedValueToMaintain {
    variants: string[]
    value: DalValue
    indexInRefArray?: number
}

interface ScopedValuesToMaintain {
    [variantsKey: string]: ScopedValueToMaintain
}

const NON_SCOPED_KEY = ''

const sortRelationsBySpecificity = (
    {originalVariants: originalVariants1}: RelationData,
    {originalVariants: originalVariants2}: RelationData
) => originalVariants1.size - originalVariants2.size

const sortRelationsByIndexInRefArray = (
    relationData1: RelationData | ScopedValueToMaintain,
    relationData2: RelationData | ScopedValueToMaintain
) => relationData1.indexInRefArray! - relationData2.indexInRefArray!

const applyOperations = (operations: VariantsReplacementOperation[], relationsData: RelationData[]) => {
    for (const operation of operations) {
        const {from, to} = operation

        for (const {originalVariants, transformedVariants} of relationsData) {
            const shouldApplyOperation = from.every(variant => originalVariants.has(variant.id))

            if (shouldApplyOperation) {
                for (const variant of from) {
                    transformedVariants.delete(variant.id)
                }

                for (const variant of to) {
                    transformedVariants.add(variant.id)
                }
            }
        }
    }
}

export const replaceVariantsOnSerialized = (refArrayValues: Object, operations: VariantsReplacementOperation[]) => {
    const relationsData: RelationData[] = []

    for (const [indexInRefArray, [variants, value]] of Object.entries(refArrayValues).entries()) {
        const relationVariants = variants.split(',').filter(variantId => variantId !== NON_SCOPED_KEY)

        relationsData.push({
            originalVariants: new Set(relationVariants),
            transformedVariants: new Set(relationVariants),
            value,
            indexInRefArray
        })
    }

    relationsData.sort(sortRelationsBySpecificity)
    applyOperations(operations, relationsData)

    const scopedValuesToMaintain: ScopedValuesToMaintain = {}
    let defaultValue

    for (const {transformedVariants, value, indexInRefArray} of relationsData) {
        const variants = [...transformedVariants]
        const variantsKey = variants.sort().join(',')

        if (variantsKey === '') {
            defaultValue = value
            continue
        }

        scopedValuesToMaintain[variantsKey] = {variants, value, indexInRefArray}
    }

    /**
     *  Please note the following:
     *
     *  1. The order of variant relations in the refArray is important to the viewer.
     *  2. scopedValues in the refArray are serialized as an object,
     *     so we have no guarantee regarding the actual order.
     *  3. When this code was written, the system already expected to preserve order in this way.
     *     Thus, we had to maintain this behavior (insertion according to order of values in object).
     */

    const scopedValues: Object = Object.values(scopedValuesToMaintain)
        .sort(sortRelationsByIndexInRefArray)
        .reduce(
            (relations: Object, updatedRelation) => ({
                ...relations,
                [updatedRelation.variants.join(',')]: updatedRelation.value
            }),
            {}
        )

    return {defaultValue, scopedValues}
}
