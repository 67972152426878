import type {CoreLogger, DAL, DocumentManager} from '@wix/document-manager-core'
import * as dataUtils from '../../../../../../utils/dataUtils'
import * as translationUtils from '../../../../../../utils/translationUtils'
import type {Pointer, Pointers, ResolvedReference} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import {captureError} from '../index'
import type {DataItem, FormattedTranslationData, VisitedMap, VisitRecord} from '../types'

const MULTILINGUAL_TRANSLATIONS = 'multilingualTranslations'

/** record repeated visits to components for later handling*/
export function handleCollectedReferencesFirstPass(
    documentManager: DocumentManager,
    visitedMap: VisitedMap,
    mlKey: string,
    languageCode: string,
    mlDataItem: DataItem,
    pageId: string,
    ref: ResolvedReference,
    referencedNode: DataItem
) {
    /* early returns - if:
    experiment is not activated/ the reference isn't collected/it's a background/it's of type of a permanentDataNode
    */

    const visitRecord: VisitRecord = {pageId, dataItemId: mlDataItem.id, mlKey, mlDataItem, ref, referencedNode}
    if (visitedMap[ref.id]) {
        visitedMap[ref.id].push(visitRecord)
    } else {
        visitedMap[ref.id] = [visitRecord]
    }
}

const isOnWrongPage = (visit: VisitRecord) => visit.mlDataItem.metaData.pageId !== visit.referencedNode.metaData.pageId

/** second pass is used to actually fix/report. visits from wrong pages, and repeated visits that do not match the references used by the original language data item from others */
export function handleCollectedReferencesSecondPass(
    documentManager: DocumentManager,
    visitedMap: VisitedMap,
    itemsTranslations: Record<string, FormattedTranslationData[]>
) {
    const {dal, logger} = documentManager

    const visitsByType = _.mapValues(visitedMap, visits => {
        const visitsFromWrongPage: VisitRecord[] = visits.filter(isOnWrongPage)

        const correctPageVisits = visits.filter(_.negate(isOnWrongPage))
        const numOfReferrals = _(correctPageVisits).groupBy('dataItemId').keys().size()
        const repeatedVisits = numOfReferrals > 1 ? correctPageVisits : []
        return {visitsFromWrongPage, repeatedVisits}
    })

    _.forEach(visitsByType, ({visitsFromWrongPage, repeatedVisits}, targetDataItem) => {
        const visitsFromNonOriginalLanguage: VisitRecord[] = _.filter(repeatedVisits, visit => {
            const refValueFromOriginalLanguage = dal.get({
                id: visit.dataItemId,
                type: visit.ref.referencedMap,
                innerPath: visit.ref.refInfo.path as string[]
            })
            const refValueFromTranslation = _.get(visit.mlDataItem, visit.ref.refInfo.path)

            if (_.isArray(refValueFromOriginalLanguage)) {
                const targetDataItemRef = `#${targetDataItem}`
                return (
                    !refValueFromOriginalLanguage.includes(targetDataItem) &&
                    !refValueFromOriginalLanguage.includes(targetDataItemRef)
                )
            }
            return refValueFromOriginalLanguage !== refValueFromTranslation
        })

        //bundling visits from different nodes(translations) of the same dataItem together to create a single replicated instance for them to share
        const repeatedVisitsByDataItemSource = _.groupBy(visitsFromNonOriginalLanguage, 'dataItemId')
        _.forEach(repeatedVisitsByDataItemSource, (visitsFromDI, sourceDataItemId) => {
            handleRefsCollectedFromMultipleSources(
                documentManager,
                visitsFromDI,
                targetDataItem,
                false,
                itemsTranslations[targetDataItem],
                logger,
                sourceDataItemId
            )
        })

        //bundling visits from different nodes(translations) of the same dataItem together to create a single replicated instance for them to share
        const wrongPageVisitsByDataItemSource = _.groupBy(visitsFromWrongPage, 'dataItemId')
        _.forEach(wrongPageVisitsByDataItemSource, (visitsFromDI, sourceDataItemId) => {
            handleRefsCollectedFromMultipleSources(
                documentManager,
                visitsFromDI,
                targetDataItem,
                true,
                itemsTranslations[targetDataItem],
                logger,
                sourceDataItemId
            )
        })
    })
}

function replaceReference(idToReplace: string, newId: string, innerValue: any) {
    const newIdRef = `#${newId}`
    const idToReplaceRef = `#${idToReplace}`

    if (_.isArray(innerValue)) {
        const location = _.findIndex(innerValue, element => element === idToReplace || element === idToReplaceRef)
        if (location !== -1) {
            innerValue[location] = newIdRef
        }
        return innerValue
    }
    return newIdRef
}

function handleRefsCollectedFromMultipleSources(
    documentManager: DocumentManager,
    visitsFromSameDI: VisitRecord[], //translations of the same DI
    targetDataItemId: string,
    isWrongPage: boolean,
    translations: FormattedTranslationData[],
    logger: CoreLogger,
    sourceDataItemId: string
) {
    const {dal, pointers} = documentManager

    const originalLanguageSourceDataItem = dal.get({id: sourceDataItemId, type: 'data'})
    if (!originalLanguageSourceDataItem) {
        return
    }

    const isStyledText = originalLanguageSourceDataItem.type === 'StyledText'
    let newId: string
    if (isStyledText /* || isWrongPage*/) {
        newId = duplicateReferredDataItem(
            dal,
            pointers,
            targetDataItemId,
            originalLanguageSourceDataItem.metaData.pageId,
            translations
        )
    }

    const visitsFromSameDIByPath: Record<string, VisitRecord[]> = _.groupBy(
        visitsFromSameDI,
        'ref.refInfo.path'
    ) as Record<string, VisitRecord[]>
    _.forEach(visitsFromSameDIByPath, (visitsFromPath: VisitRecord[], path: string) => {
        const originalLanguageRef = _.get(originalLanguageSourceDataItem, path.split(','))

        _.forEach(visitsFromPath, visit => {
            if (isStyledText) {
                const mlPointer = {id: visit.mlKey, type: MULTILINGUAL_TRANSLATIONS}
                const updatedDataItem = replaceRefForStyledText(
                    dal,
                    logger,
                    mlPointer,
                    visit.ref.refInfo.path,
                    visit.ref.id,
                    newId
                )
                const inner = _.get(updatedDataItem, visit.ref.refInfo.path)
                const newInner = replaceReference(visit.ref.id, newId, inner)
                _.set(updatedDataItem, visit.ref.refInfo.path, newInner)
                dal.set(mlPointer, updatedDataItem)
            } else {
                //use original language reference
                const mlPointerInner: Pointer = {
                    id: visit.mlKey,
                    type: MULTILINGUAL_TRANSLATIONS,
                    innerPath: visit.ref.refInfo.path as string[]
                }
                dal.set(mlPointerInner, originalLanguageRef)
            }
        })
        const logMessage = `Multiple references to ref: ${targetDataItemId}, referring from: ${_.map(
            visitsFromPath,
            'mlKey'
        )}, type: ${originalLanguageSourceDataItem.type}, ref path: ${path}, ${
            isStyledText ? ` replacing with new ref: ${newId}` : ' reusing original language value'
        }`
        captureError(logger, logMessage, 'MissingCollected', 'fix')
    })
}

function duplicateReferredDataItem(
    dal: DAL,
    pointers: Pointers,
    sourceDataItemId: string,
    targetPageId: string,
    translations: FormattedTranslationData[]
): string {
    const newId = dataUtils.generateUniqueIdByType('data' /*ref.referencedMap*/, targetPageId, dal, pointers)

    function duplicate(sourcePointer: Pointer, targetPointer: Pointer) {
        const newRefValue = _.merge(deepClone(dal.get(sourcePointer)), {
            id: newId,
            metaData: {pageId: targetPageId}
        })
        dal.set(targetPointer, newRefValue)
    }

    const sourcePointer = {id: sourceDataItemId, type: 'data'}
    const targetPointer = {id: newId, type: 'data'}
    duplicate(sourcePointer, targetPointer)
    _.forEach(translations, translation => {
        const {mlKey, languageCode} = translation
        const mlSourcePointer = {id: mlKey, type: MULTILINGUAL_TRANSLATIONS}
        const mlTargetPointer = {
            id: translationUtils.getTranslationItemKey(languageCode, newId),
            type: MULTILINGUAL_TRANSLATIONS
        }
        duplicate(mlSourcePointer, mlTargetPointer)
    })
    return newId
}

function replaceRefForStyledText(
    dal: DAL,
    logger: CoreLogger,
    mlPointer: Pointer,
    path: readonly string[],
    id: string,
    newId: string
): DataItem {
    const freshModifiedReferringNode = deepClone(dal.get(mlPointer))

    if (path.length > 1) {
        const logMessage = `unexpected path of length >1  path:${JSON.stringify({
            path,
            id: freshModifiedReferringNode.id,
            replacedId: id
        })}`
        captureError(logger, logMessage, 'MissingCollected', 'report')
        return freshModifiedReferringNode
    }
    freshModifiedReferringNode.text = freshModifiedReferringNode?.text?.replace(new RegExp(`\\b${id}\\b`, 'gm'), newId)

    return freshModifiedReferringNode
}
