import experiment from 'experiment-amd'
import _ from 'lodash'
import dsUtils from '../../utils/utils'
import type {CompStructure, PS} from '@wix/document-services-types'
import dataModel from '../../dataModel/dataModel'

function getCompData(component: CompStructure, ps: PS, pageId: string) {
    const dataQuery = dsUtils.stripHashIfExists(component.dataQuery)
    return dataModel.getDataItemById(ps, dataQuery, pageId)
}

function shouldStretchInMobile(ps: PS, compData: Record<string, any>) {
    const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
    const clientSpecMap = ps.dal.getNoClone(clientSpecMapPointer)
    const appData = _.find(clientSpecMap, {appDefinitionId: compData.appDefinitionId})

    return appData?.widgets?.[compData.widgetId]?.shouldBeStretchedByDefaultMobile ?? true
}

function isBlocksWidget(component: CompStructure, ps: PS, pageId: string) {
    const compData = getCompData(component, ps, pageId)
    return compData?.type === 'WidgetRef' && !!compData?.appDefinitionId
}

function isScreenWidth(ps: PS, component: CompStructure, pageId: string) {
    return !isBlocksWidget(component, ps, pageId)
}

function stretchHorizontally(ps: PS, component: CompStructure, pageId: string) {
    if (!isBlocksWidget(component, ps, pageId)) {
        return true
    }
    return experiment.isOpen('dm_dontStretchBlocksWidgetsInMobileConversion')
        ? !isBlocksWidget(component, ps, pageId)
        : shouldStretchInMobile(ps, getCompData(component, ps, pageId))
}

const mobileConversionConfig = experiment.isOpen('dm_dontStretchBlocksWidgetsInMobileConversion')
    ? {stretchHorizontally}
    : {stretchHorizontally, isScreenWidth}

export default {
    shouldAutoSetNickname: false,
    mobileConversionConfig,
    canContainByStructure: true,
    canBeStretched: true
}
