import type {DocumentManager} from '@wix/document-manager-core'
import * as constants from '../../../../../constants/constants'
import type {Experiment, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {runOnPageComponents} from './migrationUtil'

export const createComponentToContainerMigrator = (name: string, componentsTypes: string[]) => {
    const migratePage = (documentManager: DocumentManager, pageId: string) => {
        const compTypesObj = _.keyBy(componentsTypes)
        _.forEach(constants.VIEW_MODES, (viewMode: string) => {
            runOnPageComponents(
                documentManager,
                pageId,
                viewMode,
                (dm: DocumentManager, pid: string, pointer: Pointer) => {
                    const comp = dm.dal.get(pointer)
                    if (_.has(compTypesObj, dm.dal.get(pointer).componentType)) {
                        if (comp.type === 'Component' || !comp.components) {
                            dm.dal.set(pointer, {...comp, components: comp.components || [], type: 'Container'})
                        }
                    }
                }
            )
        })
    }

    return {migratePage, name, version: 2}
}

export const createComponentToContainerMigratorWithExperiment = (
    name: string,
    componentsType: string[],
    experimentName: string
) => {
    const migrator: any = createComponentToContainerMigrator(name, componentsType)
    const migrationFunc = migrator.migratePage
    migrator.migratePage = (documentManager: DocumentManager, pageId: string, experimentInstance: Experiment) => {
        if (experimentInstance.isOpen(experimentName)) {
            migrationFunc(documentManager, pageId)
        }
    }

    migrator.version = 1
    migrator.experimentalVersions = [{version: 2, experiment: experimentName}]
    return migrator
}
