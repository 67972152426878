function getRotationValue(value) {
    return value.replace('rotate(', '').replace('deg)', '')
}

function handleRotationProperty(property) {
    if (property.cssProp === 'transform') {
        const rotateValue = getRotationValue(property.value)
        return {
            ...property,
            value: rotateValue
        }
    }
    return property
}

function getUpdatedRotationProperties(properties) {
    return properties.map(property => handleRotationProperty(property))
}

/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
function migrateIconRotation(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem
        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (acc, [selector, {properties, mobileProperties}]) => {
                const updatedProperties = getUpdatedRotationProperties(properties)
                const updatedMobileProperties = getUpdatedRotationProperties(mobileProperties)

                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties
                    }
                }
            },
            parsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: updatedParsedStyle
        }
    })
}

module.exports = migrateIconRotation
