interface SyncTags {
    EDITOR_SYNC_TAG: 'EDITOR_SYNC_TAG'
    FS_SYNC_TAG: 'FS_SYNC_TAG'
}

export type PossibleSyncTags = keyof SyncTags

export const SYNC_TAGS: SyncTags = {
    EDITOR_SYNC_TAG: 'EDITOR_SYNC_TAG',
    FS_SYNC_TAG: 'FS_SYNC_TAG'
}

export const EVENTS = {
    LOCAL_EDITOR: {
        AFTER_SYNC_FROM_FS: 'LOCAL_EDITOR_AFTER_SYNC_FROM_FS'
    }
}
