import type {Pointer} from '@wix/document-services-types'
import {ConversionDalNamespaces} from './constants'

export const getStructurePointer = ({id}: Pick<Pointer, 'id'>) => ({id, type: ConversionDalNamespaces.structure})

export const getVirtualStructurePointer = ({id}: Pick<Pointer, 'id'>) => ({
    id,
    type: ConversionDalNamespaces.virtualStructure
})

export const getMeasurementsPointer = ({id}: Pick<Pointer, 'id'>) => ({id, type: ConversionDalNamespaces.measurements})

export const getLayoutPointer = ({id}: Pick<Pointer, 'id'>) => ({id, type: ConversionDalNamespaces.layout})

export const getStylePointer = ({id}: Pick<Pointer, 'id'>) => ({id, type: ConversionDalNamespaces.style})
