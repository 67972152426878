import type {CompRef, ExternalComponentsReadOnlyDriver, PS, Pointer} from '@wix/document-services-types'
import component from '../../component/component'
import dataModel from '../../dataModel/dataModel'
import dsUtils from '../../utils/utils'

export default (ps: PS, compPointer: CompRef): ExternalComponentsReadOnlyDriver => ({
    layout: {
        get: () => component.layout.get(ps, compPointer)
    },
    data: {
        get: () => dataModel.getDataItem(ps, compPointer)
    },
    properties: {
        get: () => component.properties.get(ps, compPointer)
    },
    design: {
        get: () => component.design.getDesignItem(ps, compPointer)
    },
    svg: {
        getInfo: (propName: string) => {
            const data = dataModel.getDataItem(ps, compPointer)
            const svgId = data && propName in data ? data[propName] : null
            if (svgId) {
                const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
                const infoPointer = ps.pointers.getInnerPointer(storePointer, 'info')
                return ps.dal.get(infoPointer)
            }
            return null
        }
    },
    isMobile: () => ps.pointers.components.isMobile(compPointer),
    getComponentType: (pointer: Pointer) => dsUtils.getComponentType(ps, pointer),
    getChildrenRecursively: (pointer: Pointer) => ps.pointers.structure.getChildrenRecursively(pointer)
})
