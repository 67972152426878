import _ from 'lodash'
import {namespaceMapping} from '@wix/document-services-json-schemas'
const {FRAGMENT_NAMESPACES} = namespaceMapping

const {
    NAMESPACE_MAPPING,
    VIEWER_PAGE_DATA_TYPES,
    DATA_TYPES,
    VIEWER_DATA_TYPES,
    EDITOR_DATA_TYPES,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPE_BY_COMP_DATA_QUERY,
    COMP_DATA_QUERY_KEYS,
    DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT,
    VARIANTS_DATA_TYPES: VARIANTABLE_DATA_TYPES
} = namespaceMapping

const PAGE_DATA_TYPES = _.omit(NAMESPACE_MAPPING, 'default')
const PAGE_SCHEMA = {
    structure: 'structure',
    data: 'data',
    translations: 'translations',
    title: 'title',
    pageUriSEO: 'pageUriSEO'
}

const SCOPE = 'scope'

const MULTILINGUAL_TYPES = {
    multilingualTranslations: 'multilingualTranslations'
}

const DM_POINTER_TYPES = {
    pageDM: 'pageDM',
    deletedPagesMap: 'deletedPagesMap'
}

const VIEWER_DATA_TYPE_TO_POINTER_TYPE = _.transform(
    VIEWER_PAGE_DATA_TYPES,
    (acc: any, v: string, k: string) => {
        acc[v] = k
    },
    {}
)

const PAGE_DATA_TYPE_TO_POINTER_TYPE = _.transform(
    PAGE_DATA_TYPES,
    (acc: any, v: string, k: string) => {
        acc[v] = k
    },
    {}
)

const VIEW_MODES = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE'
} as const

const ALLOWED_MOBILE_COMPONENTS = {
    'wysiwyg.viewer.components.mobile.TinyMenu': 'wysiwyg.viewer.components.mobile.TinyMenu',
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton':
        'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton',
    'wysiwyg.viewer.components.BackToTopButton': 'wysiwyg.viewer.components.BackToTopButton',
    'wysiwyg.viewer.components.mobile.ExitMobileModeButton': 'wysiwyg.viewer.components.mobile.ExitMobileModeButton',
    'wysiwyg.viewer.components.QuickActionBar': 'wysiwyg.viewer.components.QuickActionBar',
    'wysiwyg.viewer.components.QuickActionBarItem': 'wysiwyg.viewer.components.QuickActionBarItem',
    'wysiwyg.viewer.components.MenuToggle': 'wysiwyg.viewer.components.MenuToggle',
    'wysiwyg.viewer.components.MenuContainer': 'wysiwyg.viewer.components.MenuContainer',
    'wysiwyg.viewer.components.ExpandableMenu': 'wysiwyg.viewer.components.ExpandableMenu'
}

const MENU_CONTAINER_COMP_TYPE = 'wysiwyg.viewer.components.MenuContainer'

const COMP_IDS = {
    PAGE_GROUP: 'SITE_PAGES',
    PAGES_CONTAINER: 'PAGES_CONTAINER',
    HEADER: 'SITE_HEADER',
    FOOTER: 'SITE_FOOTER',
    BACKGROUND: 'SITE_BACKGROUND',
    QUICK_ACTION_BAR: 'QUICK_ACTION_BAR',
    WIX_ADS: 'WIX_ADS',
    WINDOW: 'WINDOW'
}

const LANDING_PAGES_COMP_IDS = {
    PAGES_CONTAINER: 'PAGES_CONTAINER',
    QUICK_ACTION_BAR: 'QUICK_ACTION_BAR'
}

const MASTER_PAGE_ID = 'masterPage'

const SNAPSHOTS = {
    DAL_INITIAL: 'initialState',
    BEFORE_AUTOSAVE_APPLY: 'before-autosave-apply',
    MOBILE_MERGE: 'mobileMerge'
}

const CHILDREN_PROPERTY_NAMES = ['mobileComponents', 'components', 'children']

const RELATION_DATA_TYPES = {
    VARIANTS: 'VariantRelation',
    BREAKPOINTS: 'BreakpointRelation'
}

const REF_ARRAY_DATA_TYPE = 'RefArray'

const REPEATER_STRUCTURE_TYPE = 'RepeaterContainer'

const UNDOABLE_TYPES = {
    ..._.keyBy([
        'save',
        'wixCodeUndoable',
        'platform',
        'rendererModel',
        'deletedPagesMap',
        'pagesPlatformApplications',
        'appsState',
        'appsInstallState'
    ]),
    ...PAGE_DATA_TYPES,
    ...VIEW_MODES,
    ...MULTILINGUAL_TYPES
}

const NON_UNDOABLE_KEYS = {
    save: _.keyBy(['publishSaveInitiator', 'silentSaveInitiator']),
    rendererModel: _.keyBy([
        'accessiblePagesToken',
        'clientSpecMap',
        'geo',
        'locale',
        'mediaAuthToken',
        'metaSiteId',
        'pageList',
        'pageListTopology',
        'passwordProtectedPages',
        'platformAppsOnPage',
        'premiumFeatures',
        'previewMode',
        'runningExperiments',
        'seo',
        'siteInfo',
        'siteMediaToken',
        'sitePropertiesInfo',
        'urlFormatModel',
        'useSandboxInHTMLComp',
        'userId',
        'wixCodeModel'
    ])
}

export const MOBILE_ONLY_COMPS = {
    TINY_MENU: 'TINY_MENU',
    MENU_AS_CONTAINER: 'MENU_AS_CONTAINER',
    MENU_AS_CONTAINER_TOGGLE: 'MENU_AS_CONTAINER_TOGGLE',
    BACK_TO_TOP_BUTTON: 'BACK_TO_TOP_BUTTON',
    MENU_AS_CONTAINER_EXPANDABLE_MENU: 'MENU_AS_CONTAINER_EXPANDABLE_MENU',
    QUICK_ACTION_BAR: 'QUICK_ACTION_BAR'
}

const WIX_CODE_SHARED = {
    SHARED_PATH_CHANGES_MAP: 'sharedPathChangesMap'
}

const PAGE_TYPES = {
    Page: 'Page',
    AppPage: 'AppPage'
}

const BASE_PROPS_SCHEMA_TYPE = 'DefaultProperties'

const DATA_TYPES_VALUES_MAP = _.mapKeys(DATA_TYPES, _.identity)
const DATA_TYPES_VALUES_WITH_HASH = _.pick(DATA_TYPES_VALUES_MAP, [
    DATA_TYPES.design,
    DATA_TYPES.layout,
    DATA_TYPES.data,
    DATA_TYPES.breakpoints
])
const PERMISSIONS = {
    SAVE: 'html-editor.save'
}

export const METADATA_TYPES = {
    CAN_CONNECT_TO_CODE: 'canConnectToCode',
    MOBILE_ONLY: 'mobileOnly',
    NICKNAME: 'nickname',
    SHOULD_AUTO_SET_NICKNAME: 'shouldAutoSetNickname',
    IS_REPEATABLE: 'isRepeatable',
    IS_CONTAIN_CHECK_RECURSIVE: 'isContainCheckRecursive',
    ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH: 'enforceContainerChildLimitsByWidth',
    ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT: 'enforceContainerChildLimitsByHeight',
    DISABLEABLE: 'disableable',
    ROTATABLE: 'rotatable',
    FLIPPABLE: 'flippable',
    MOVE_DIRECTIONS: 'moveDirections',
    RESIZABLE_SIDES: 'resizableSides',
    FIXED_POSITION: 'canBeFixedPosition',
    LAYOUT_LIMITS: 'layoutLimits',
    MOBILE_CONVERSION_CONFIG: 'mobileConversionConfig',
    DEFAULT_MOBILE_PROPERTIES: 'defaultMobileProperties',
    STYLE_CAN_BE_APPLIED: 'styleCanBeApplied',
    CAN_BE_STRETCHED: 'canBeStretched',
    ALLOWED_CHILD_TYPES: 'allowedChildTypes',
    ALLOWED_PARENT_TYPES: 'allowedParentTypes',
    IS_PUBLIC_CONTAINER: 'isPublicContainer',
    A11Y_CONFIGURABLE: 'a11yConfigurable',
    WIDTH_AUTO: 'widthAuto',
    HEIGHT_AUTO: 'heightAuto',
    CAN_CONTAIN: 'canContain',
    CONTAINER: 'container',
    ALWAYS_CONTAIN_RECURSIVELY: 'alwaysContainRecursively',
    FULL_WIDTH_BY_STRUCTURE: 'fullWidthByStructure',
    FULL_WIDTH: 'fullWidth',
    IS_REPEATER: 'isRepeater',
    MAXIMUM_CHILDREN_NUMBER: 'maximumChildrenNumber',
    CONTAINABLE_BY_STRUCTURE: 'containableByStructure',
    CAN_CONTAIN_BY_STRUCTURE: 'canContainByStructure'
}

const EXTERNAL_META_DATA_ALLOWED_TYPES = [
    METADATA_TYPES.DISABLEABLE,
    METADATA_TYPES.ROTATABLE,
    METADATA_TYPES.FLIPPABLE,
    METADATA_TYPES.MOVE_DIRECTIONS,
    METADATA_TYPES.RESIZABLE_SIDES,
    METADATA_TYPES.FIXED_POSITION,
    METADATA_TYPES.LAYOUT_LIMITS,
    METADATA_TYPES.MOBILE_CONVERSION_CONFIG,
    METADATA_TYPES.DEFAULT_MOBILE_PROPERTIES,
    METADATA_TYPES.IS_REPEATABLE,
    METADATA_TYPES.STYLE_CAN_BE_APPLIED,
    METADATA_TYPES.CAN_BE_STRETCHED,
    METADATA_TYPES.ALLOWED_CHILD_TYPES,
    METADATA_TYPES.ALLOWED_PARENT_TYPES,
    METADATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH,
    METADATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT,
    METADATA_TYPES.IS_PUBLIC_CONTAINER,
    METADATA_TYPES.A11Y_CONFIGURABLE,
    METADATA_TYPES.WIDTH_AUTO,
    METADATA_TYPES.HEIGHT_AUTO,
    METADATA_TYPES.CAN_CONNECT_TO_CODE,
    METADATA_TYPES.CAN_CONTAIN,
    METADATA_TYPES.IS_CONTAIN_CHECK_RECURSIVE
]

export const COMP_TYPES = {
    CONTROLLER_TYPE: 'platform.components.AppController',
    APP_WIDGET_TYPE: 'platform.components.AppWidget',
    REF_TYPE: 'wysiwyg.viewer.components.RefComponent',
    SLOT_PLACEHOLDER: 'wixui.SlotsPlaceholder',
    PAGE_TYPE: 'mobile.core.components.Page',
    WRICHTEXT: 'wysiwyg.viewer.components.WRichText',
    STYLABLE_BUTTON: 'wixui.StylableButton',
    CONTAINER: 'mobile.core.components.Container',
    MEDIA_PLAYER: 'wysiwyg.viewer.components.MediaPlayer',
    MEDIA_CONTROLS: 'wysiwyg.viewer.components.MediaControls',
    MEDIA_OVERLAY_CONTROLS: 'wysiwyg.viewer.components.MediaOverlayControls',
    VERTICAL_ANCHORS_MENU: 'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
    ANCHOR: 'wysiwyg.common.components.anchor.viewer.Anchor',
    MEGA_MENU_CONTAINER_ITEM: 'wixui.MegaMenuContainerItem',
    STYLABLE_HORIZONTAL_MENU: 'wixui.StylableHorizontalMenu',
    STRIP_COLUMNS_CONTAINER: 'wysiwyg.viewer.components.StripColumnsContainer',
    REPEATER: 'wysiwyg.viewer.components.Repeater',
    DYNAMIC_REPEATER: 'wixui.DynamicRepeater'
}

export const COMPS_DATA_TYPE = {
    BLOCK_WIDGET: 'WidgetRef'
}

export const VARIANTS = {
    VARIANT_TYPES: {
        SELECTED: 'Selected',
        HOVER: 'Hover',
        MOBILE: 'Mobile',
        STATE: 'State',
        TRIGGER: 'Trigger',
        BREAKPOINTS_DATA: 'BreakpointsData',
        PRESET: 'Preset',
        REPEATER_PATTERN: 'RepeaterPattern',
        REPEATER_PATTERN_LIST: 'RepeaterPatternList',
        VARIANTS_LIST: 'VariantsList',
        BREAKPOINT_RANGE: 'BreakpointRange'
    },
    TRIGGER_TYPES: [
        'click',
        'tap',
        'mouse-in',
        'mouse-out',
        'hover',
        'viewport-enter',
        'viewport-leave',
        'view-progress',
        'scroll-progress',
        'pointer-move'
    ],
    VARIANTS_QUERY: 'variantsQuery',
    MOBILE_VARIANT_ID: 'MOBILE-VARIANT'
}

const TRIGGER_TYPES = {
    CLICK: 'click',
    TAP: 'tap',
    MOUSE_IN: 'mouse-in',
    MOUSE_OUT: 'mouse-out',
    POINTER_MOVE: 'pointer-move',
    HOVER: 'hover',
    VIEWPORT_ENTER: 'viewport-enter',
    VIEWPORT_LEAVE: 'viewport-leave',
    VIEW_PROGRESS: 'view-progress',
    SCROLL_PROGRESS: 'scroll-progress',
    ANIMATION_START: 'animation-start',
    ANIMATION_END: 'animation-end',
    PAGE_VISIBLE: 'page-visible'
}

export const DATA_ITEM_TYPES = {
    PRESET_DATA: 'PresetData'
}
const DEAD_MOBILE_COMPONENT_TYPE = 'to.be.removed.by.MobileMerge'
const CONTROLLER_TYPE = 'platform.components.AppController'
const APP_WIDGET_TYPE = 'platform.components.AppWidget'
const CONTROLLER_DATA = {
    DEFAULT_STATE: 'default',
    CONNECTIONS: 'connections',
    WILDCARD_ROLE: '*'
}
const CUSTOM_MENUS = 'CUSTOM_MENUS'
const CUSTOM_MAIN_MENU = 'CUSTOM_MAIN_MENU'

const NICKNAMES = {
    VALIDATIONS: {
        VALID: 'VALID',
        ALREADY_EXISTS: 'ALREADY_EXISTS',
        TOO_SHORT: 'TOO_SHORT',
        TOO_LONG: 'TOO_LONG',
        INVALID_NAME: 'INVALID_NAME'
    }
}

const ATOMIC_SCOPES = {
    TYPE: 'AtomicScope',
    URI_PREFIX: 'URI'
}

const BASED_ON_SIGNATURE_NS = 'basedOnSignature'

const TPA_CONSTANTS = {
    DATA_TYPE: {
        TPA_SECTION: 'TPA',
        TPA_MULTI_SECTION: 'TPAMultiSection',
        TPA_WIDGET: 'TPAWidget'
    }
}

const TPA_COMP_TYPES = {
    TPA_SECTION: 'wysiwyg.viewer.components.tpapps.TPASection',
    TPA_MULTI_SECTION: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    TPA_GLUED_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    TPA_WORKER: 'tpa.viewer.classes.TPAWorker'
}

const APP_STATUS = {
    INSTALLED: 'INSTALLED',
    REMOVED: 'REMOVED'
}

const NAMESPACE_TYPES = {
    EFFECTS_LIST_TYPE: 'EffectsList',
    TRIGGERS_TYPE: 'Triggers',
    REACTIONS_TYPE: 'Reactions'
}
const APPS_TO_LOAD_ON_MOBILE_BUILDER = [
    'wix-code',
    'dataBinding',
    '46b2ad43-5720-41d2-8436-2058979cb53f' /*wixBlocks*/,
    '1973457f-c021-4da5-941f-58444ff761d4' /*CSM*/
]
const MOBILE_WIDGET_BUILDER_BRANCH_ID = '1a04cd63-178d-4e59-afd2-ddd6c4be5f00'

const DOM_ID_PREFIX = {
    DESKTOP: '',
    MOBILE: 'mobile_',
    DEAD_MOBILE_COMP: 'dead-'
}

const STYLES = {
    TYPES: {
        SYSTEM: 'system',
        CUSTOM: 'custom'
    },
    COMPONENT_STYLE: 'ComponentStyle',
    TOP_LEVEL_STYLE: 'TopLevelStyle'
}

const MESH_LAYOUT_TYPES = {
    MESH_ITEM_LAYOUT: 'MeshItemLayout',
    MESH_CONTAINER_LAYOUT: 'MeshContainerLayout',
    MASTER_PAGE_ITEM_LAYOUT: 'MasterPageItemLayout'
}

const VARIABLES_CONNECTIONS = 'variablesConnections'

const AUTO_CONTENT_HEIGHT = 'auto'

const NEW_COLOR_PALETTE_LENGTH = 66
const PAGE_COME_TYPES = ['mobile.core.components.Page', 'wixapps.integration.components.AppPage']

export {
    APPS_TO_LOAD_ON_MOBILE_BUILDER,
    MOBILE_WIDGET_BUILDER_BRANCH_ID,
    FRAGMENT_NAMESPACES,
    BASE_PROPS_SCHEMA_TYPE,
    DM_POINTER_TYPES,
    PAGE_DATA_TYPES,
    VIEWER_DATA_TYPE_TO_POINTER_TYPE,
    PAGE_DATA_TYPE_TO_POINTER_TYPE,
    VIEWER_DATA_TYPES,
    VIEWER_PAGE_DATA_TYPES,
    EDITOR_DATA_TYPES,
    DATA_TYPES,
    VARIANTABLE_DATA_TYPES,
    DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT,
    TRIGGER_TYPES,
    VIEW_MODES,
    COMP_IDS,
    LANDING_PAGES_COMP_IDS,
    MASTER_PAGE_ID,
    SNAPSHOTS,
    MULTILINGUAL_TYPES,
    CHILDREN_PROPERTY_NAMES,
    PAGE_SCHEMA,
    REPEATER_STRUCTURE_TYPE,
    RELATION_DATA_TYPES,
    REF_ARRAY_DATA_TYPE,
    UNDOABLE_TYPES,
    NON_UNDOABLE_KEYS,
    WIX_CODE_SHARED,
    PAGE_TYPES,
    COMP_DATA_QUERY_KEYS,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPE_BY_COMP_DATA_QUERY,
    DATA_TYPES_VALUES_WITH_HASH,
    PERMISSIONS,
    ALLOWED_MOBILE_COMPONENTS,
    DEAD_MOBILE_COMPONENT_TYPE,
    DATA_TYPES_VALUES_MAP,
    CONTROLLER_DATA,
    CONTROLLER_TYPE,
    APP_WIDGET_TYPE,
    SCOPE,
    NICKNAMES,
    CUSTOM_MENUS,
    CUSTOM_MAIN_MENU,
    ATOMIC_SCOPES,
    BASED_ON_SIGNATURE_NS,
    TPA_CONSTANTS,
    TPA_COMP_TYPES,
    NAMESPACE_TYPES,
    APP_STATUS,
    MENU_CONTAINER_COMP_TYPE,
    STYLES,
    DOM_ID_PREFIX,
    MESH_LAYOUT_TYPES,
    AUTO_CONTENT_HEIGHT,
    EXTERNAL_META_DATA_ALLOWED_TYPES,
    VARIABLES_CONNECTIONS,
    NEW_COLOR_PALETTE_LENGTH,
    PAGE_COME_TYPES
}
