import {
    CreateExtArgs,
    CreateExtensionArgument,
    createSnapshotChain,
    DAL,
    DalValue,
    debug,
    DmApis,
    DmStore,
    Extension,
    ExtensionAPI,
    LoggerDriver,
    Null,
    pointerUtils,
    SnapshotDal,
    store as dmStore,
    TransactionRejectionError
} from '@wix/document-manager-core'
import {
    classic,
    defaultAttempt,
    getReportableFromError,
    Notifier,
    ReportableError,
    ReportableWarning,
    retryTaskAndReport,
    saveErrors,
    serverSaveErrorCodes,
    taskWithRetries,
    withTimeout
} from '@wix/document-manager-utils'
import type {
    DSConfig,
    DocumentServiceError,
    DocumentServiceErrorInfo,
    UserInfoObject
} from '@wix/document-services-types'
import _ from 'lodash'
import long, {Long} from 'long'
import {FRAGMENT_NAMESPACES, MULTILINGUAL_TYPES, SNAPSHOTS} from '../../constants/constants'
import type {SaveStateApi} from '../saveState'
import {CreateRevisionRes, CreateTransactionRequest, InitiatorType, PendingTransaction, Transaction} from '../../types'
import type {GetDocumentResponse} from '@wix/ambassador-editor-document-store/types'
import {AsyncQueue, createQueue, QueueFunction} from '../../utils/asyncQueue'
import {Channel, ChannelEvents} from '../channelUtils/duplexer'
import type {DocumentServicesModelExtApi} from '../documentServicesModel'
import type {RMApi} from '../rendererModel'
import type {SafeRemovalError, SchemaExtensionAPI} from '../schema/schema'
import type {SnapshotApi, SnapshotExtApi} from '../snapshots'
import * as converter from './csaveConverter'
import {removeFromLoadStore} from './csaveDataRules'
import {
    convertToDSError,
    documentStoreErrors,
    FirstTransactionRejectionError,
    InvalidLastTransactionIdError,
    isNetworkError,
    isStaleTransactionError,
    isStaleVersionError,
    makeReportable,
    MissingTransactionInServerPayloadError,
    StaleStateOnForeignTransactionError,
    TransactionAlreadyApproveError
} from './csaveErrors'
import type {
    Approved,
    ApprovedNonEmpty,
    ContinuousSaveServer,
    CreateRevArgs,
    CSaveHooks,
    Rejected,
    StaleEditorEnvironment,
    SaveTransactionResponse,
    TransactionResult,
    BatchTransactions
} from './csaveTypes'
import {DuplexerOrderCheck, reportTransactionId} from './duplexerOrderCheck'
import {EmptyCSaveExt} from './emptyCSaveExt'
import {longGt} from './long'
import {GetTransactionRes, MockCEditTestServer} from './MockCEditTestServer'
import {MockCSDuplexer} from './mockCSChannel'
import {Action, ActionOperation, CreateRevisionReq, GetTransactionsResponse, SaveRequest} from './serverProtocol'
import {tagsFromError, validateCSaveTransaction} from './validateCSaveTransaction'
import {EmptySnapshotMap} from './emptySnapshotMap'
import type {RelationshipsAPI} from '../relationships'
import type {DistributorExtensionAPI} from '../distributor/distributor'
import {getTranslationItemKey} from '../../utils/translationUtils'

const {createStore} = dmStore
const {getPointer} = pointerUtils

interface FragmentDetails {
    workspace: string
    type: string
    innerNamespace: string
}

const ACTIONS_TO_AUTOSAVE_COUNT_DENOMINATOR = 15
const MAX_TRANSACTIONS_BASE = 300

const COLLATOR = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'})

export const actionCountToAutosaveActionCount = (actionCount: number): number =>
    _.floor(actionCount / ACTIONS_TO_AUTOSAVE_COUNT_DENOMINATOR)

export const CS_EVENTS = {
    CSAVE: {
        SITE_SAVED: 'SITE_SAVED',
        DO_CSAVE: 'DO_CSAVE',
        NON_RECOVERABLE_ERROR: 'CAVE_NON_RECOVERABLE_ERROR'
    },
    SAVE: {
        FINISHED_SAVING: 'FINISHED_SAVING'
    }
}

let log: LoggerDriver

const CSAVE_TAG = 'CSAVE_TAG'
const CSAVE_PENDING_TAG = 'CSAVE_PENDING_TAG'
const CSAVE_INTERACTION = 'csave'
const CSAVE_VALIDATION_INTERACTION = 'csave-validation'
const CSAVE_TRANSACTION_INTERACTION = 'csave-approval-rate'
const CSAVE_TRANSACTION_REJECTED = 'csave-transaction-rejected'
const CSAVE_PAYLOAD_SIZE = 'csave-payload-size'
const channelName = 'dm_transactions'

export interface UpdateSiteDTO {
    restrictedDataDelta?: any
    logsMarkers?: any //LogMarkers
    translationsDelta?: any
    dataDelta?: any
    cedit?: boolean
    metaSiteData?: {
        siteName: string
    }
    metaSiteActions?: {actions: {}[]; maybeBranchId?: string}
    wixCodeAppData?: {codeAppId: string}
    dataNodeIdsToDelete: any
    branchId: any
    lastTransactionId: string
    initiator: string
    pagesPlatformApplications: any
    id: string
    protectedPagesData: any | {}
    version: string
    routers: any | {}
    signatures: any
    revision: string
}

export interface CSaveApi extends ExtensionAPI {
    continuousSave: {
        createRevision(args: CreateRevArgs, dataToSave: UpdateSiteDTO): Promise<CreateRevisionRes>
        getLastTransactionId(): string | undefined
        getLastSaveDate(): Promise<Date | undefined>
        initCSave(partialPages?: string[]): Promise<boolean>
        initHooks(saveHooksMap: CSaveHooks): void
        getWrappedHooks(hooks: CSaveHooks): CSaveHooks
        save(): Promise<void | SaveTransactionResponse>
        saveAndWaitForResult(): Promise<void>
        forceSaveAndWaitForResult(): Promise<void>
        setEnabled(enabled: boolean): void
        setSaving(isSaving: boolean): void
        disableSaveDuringRequiredAndPrimary(shouldDisableSave: boolean): void
        shouldSave(): boolean
        isCSaveOpen(): boolean
        isCEditOpen(): boolean
        isCreateRevisionOpen(): boolean
        isValidationRecovery(): boolean
        // for development purposes
        getTransactions(
            afterTransactionId?: string,
            untilTransactionId?: string,
            branchId?: string
        ): Promise<GetTransactionsResponse>
        getTransactionsFromLastRevision(
            untilTransactionId?: string,
            branchId?: string
        ): Promise<GetTransactionsResponse>
        getStore(
            branchId?: string,
            afterTransactionId?: string,
            untilTransactionId?: string
        ): Promise<GetDocumentResponse>
        approveForeignTransaction(t: GetTransactionRes): Promise<void>
        deleteTx(): Promise<void>
        rejectNext(): void
        registerToTransactionApproved(cb: (txStore: DmStore) => Promise<void>): void
        waitForResponsesToBeProcessed(): Promise<void>
        test(): CSaveTestApi
        onRevisionChange(): void
        isUnappliedTransactionThresholdPassed(): boolean
        getActionCount(): number
        getUnappliedActionCountSinceLastRevision(): number
        cSaveErrors: {
            convertToDSError(e: any, userInfo: UserInfoObject): DocumentServiceError
        }
        isLocalTransaction(transactionId: string): boolean
    }
}

export type CSaveExtApi = CSaveApi['continuousSave']

export class CSaveTestApi {
    constructor(private ext: CSaveExtension) {}

    approveTransaction(correlationId: string, transactionId: string) {
        // @ts-expect-error
        this.ext.duplexer?.simulateApproval(correlationId, transactionId)
    }

    simulateOutOfSync() {
        // @ts-expect-error
        this.ext.duplexer?.simulateSubscriptionSucceeded({isSynced: false})
    }

    getUnappliedTransactionsCount(): number {
        return this.ext.getUnappliedTransactionsCount()
    }

    getMaxUnappliedTransactionsCount(): number {
        return this.ext.getMaxUnappliedTransactionsCount()
    }
}

const getLastCsaveSnapshot = (snapshots: SnapshotApi): SnapshotDal =>
    snapshots.getLastSnapshotByTagName(CSAVE_TAG) ?? snapshots.getInitialSnapshot()

export function rebase(dal: DAL, snapshots: SnapshotApi, actions: Action[], label: string) {
    const lastSnapshot = getLastCsaveSnapshot(snapshots)
    rebaseFromSnapshot(dal, lastSnapshot, actions, label)
    snapshots.tagSnapshot(CSAVE_TAG, snapshots.getLastSnapshot()!)
}

export function rebaseFromSnapshot(dal: DAL, lastSnapshot: SnapshotDal, actions: Action[], label: string) {
    const store = createStore()
    _.forEach(actions, (action: Action) => {
        store.set({type: action.namespace!, id: action.id!}, action.value)
    })
    dal.rebase(store, lastSnapshot.id, label)
}

const strToLong = (str: string | undefined): Long => (_.isEmpty(str) ? long.ZERO : long.fromString(str!))

const notifierTimeout = 1000 * 60 * 5
const notifierSendToServerTimeout = 1000 * 60 * 1.5

interface CreateTransactionReq {
    payload?: SaveRequest
    error?: SafeRemovalError
}

/** All propertied marked optional since this is not a well-defined contract between server and client */
interface TransactionRejectionDuplexerPayload {
    correlationId?: string
    errorCode?: string
    reason?: string
}

export class VersionInfoUpdateError extends ReportableError {
    public reason: any

    constructor(reason: any) {
        super({
            message: 'Failed to update version info',
            extras: {
                reason: JSON.stringify(reason)
            },
            errorType: 'VersionInfoUpdateError'
        })

        Object.setPrototypeOf(this, VersionInfoUpdateError.prototype)
        this.reason = reason
    }

    static isVersionInfoUpdateError(error: any): error is VersionInfoUpdateError {
        return error.errorType === 'VersionInfoUpdateError'
    }

    static shouldThrowVersionInfoUpdateError(payload?: SaveRequest): boolean {
        const transactions = payload?.transactions ?? []
        return (
            transactions.length === 1 &&
            transactions[0].actions?.[0]?.namespace === 'documentServicesModel' &&
            transactions[0].actions?.[0]?.id === 'versionInfo'
        )
    }
}

class CSaveExtension implements Extension {
    readonly name: string = 'continuousSave'
    readonly EVENTS: Record<string, any> = CS_EVENTS
    readonly dependencies: ReadonlySet<string> = new Set([
        'snapshots',
        'documentServicesModel',
        'rendererModel',
        'distributor'
    ])
    private saving: boolean = false
    private isSaveDisabledDuringRequiredAndPrimary: boolean = false
    private pendingIndex: number = -1
    private readonly hooks: CSaveHooks = {
        onDiffSaveStarted: _.noop,
        onDiffSaveFinished: _.noop,
        onPartialSaveStarted: _.noop,
        onPartialSaveFinished: _.noop,
        onRefreshRequired: _.noop
    }
    private emptySnapshotsMap = new EmptySnapshotMap()

    private isEnabled: boolean = true
    private isInitialized: boolean = false
    private _validationRecovery = false
    private get validationRecovery(): boolean {
        return this._validationRecovery && this.enableValidationRecovery
    }

    private set validationRecovery(value: boolean) {
        this._validationRecovery = value
    }

    private firstCorrelationId: string | null = null
    private revisionTransaction: Long = long.ZERO
    private unappliedActionCountSinceLastRevision: number = 0
    private actionCountByCorrelationId: Record<string, number> = {}

    constructor(
        private readonly server: ContinuousSaveServer,
        private readonly config: DSConfig,
        private readonly maxTransactionsModifier: number,
        private readonly enableValidationRecovery: boolean
    ) {}

    getMaxUnappliedTransactionsCount(): number {
        return MAX_TRANSACTIONS_BASE + this.maxTransactionsModifier
    }

    getUnappliedTransactionsCount(): number {
        const last = strToLong(this.server.getLast())
        const result = last.sub(this.revisionTransaction)
        if (result.isNegative()) {
            return 0
        }
        return result.toNumber()
    }

    createExtensionAPI({coreConfig, dal, pointers, extensionAPI, eventEmitter}: CreateExtArgs): CSaveApi {
        const {snapshots} = extensionAPI as SnapshotExtApi
        const {siteAPI, rendererModel} = extensionAPI as RMApi
        const {siteAPI: dsSiteApi} = extensionAPI as DocumentServicesModelExtApi
        const {distributor} = extensionAPI as DistributorExtensionAPI
        const {logger, experimentInstance} = coreConfig
        const queue: AsyncQueue = createQueue({
            queueName: 'CSave',
            useTimeout: true,
            defaultTimeout: 1000 * 60 * 5
        })
        const {server, hooks} = this
        const deferredTimeoutMap: Map<string, number> = new Map()
        let duplexer: Channel | null = null
        let duplexerIsReconnecting: boolean = false
        let transactionApprovedCb: (txStore: DmStore) => Promise<void>
        const orderCheck = new DuplexerOrderCheck('0', logger)

        const onRevisionChange = () => {
            this.revisionTransaction = strToLong(this.server.getLast())
            this.unappliedActionCountSinceLastRevision = 0
        }

        const isCreateRevisionOpen = () => !!this.config.createRevision

        const isUnappliedTransactionThresholdPassed = () => {
            const count = this.getUnappliedTransactionsCount()
            const max = this.getMaxUnappliedTransactionsCount()
            const isMarkedFromInit = this.validationRecovery && !isCreateRevisionOpen()
            return count > max || isMarkedFromInit
        }

        const initUnappliedTransactions = (transactionResult: GetDocumentResponse) => {
            this.revisionTransaction = strToLong(transactionResult.firstTransactionId)
            const count = this.getUnappliedTransactionsCount()
            const max = this.getMaxUnappliedTransactionsCount()
            log.info(`${count} unapplied csave/cedit transactions. Maximum is ${max}`)
        }

        const setUnappliedActionCountSinceLastRevision = (unappliedActions?: number) => {
            if (_.isNumber(unappliedActions)) {
                this.unappliedActionCountSinceLastRevision = unappliedActions
                log.info(`${this.unappliedActionCountSinceLastRevision} unapplied csave/cedit actions.`)
            } else {
                logger.interactionStarted('FailedSettingUnappliedActionCount', {
                    method: 'setUnappliedActionCountSinceLastRevision'
                })
            }
        }

        const reportFirstTransactionRejection = (correlationId: string): void => {
            logger.captureError(new FirstTransactionRejectionError(correlationId))
        }

        const handleProcessingTransactionError = (
            csaveOp: string,
            error: unknown,
            extras?: Record<string, string | boolean>
        ) => {
            const err = error as Error
            const baseExtras = error instanceof ReportableError ? error.extras : {}
            const baseTags = error instanceof ReportableError ? error.tags : {}
            const errorType = error instanceof ReportableError ? error.errorType : 'ERROR_PROCESSING_TRANSACTION'
            const e = getReportableFromError(err, {
                errorType,
                message: 'error when processing transaction',
                tags: {...baseTags, duplexer: true, csaveOp},
                extras: {
                    ...baseExtras,
                    ...extras,
                    reason: {name: err.name, message: err.message},
                    isWixInstanceExpired: rendererModel.isWixInstanceExpired()
                }
            })

            logger.captureError(e, {tags: e.tags, extras: e.extras})
            this.hooks.onRefreshRequired?.('ERROR_PROCESSING_TRANSACTION')
        }

        const notifier = new Notifier<string, void, any>(
            logger,
            {
                onReject: (correlationId: string) => {
                    if (this.firstCorrelationId && this.firstCorrelationId === correlationId) {
                        reportFirstTransactionRejection(correlationId)
                    }
                    log.info(`Notifier rejected ${correlationId}`)
                },
                onResolve: (correlationId: string) => {
                    log.info(`Notifier approved ${correlationId}`)
                },
                onTimeout: onNotifierTimeout
            },
            this.config.cSaveResponseTimeLimit ??
                (experimentInstance.isOpen('dm_resetTimeoutBeforeSendingCsaveToServer')
                    ? notifierTimeout
                    : notifierSendToServerTimeout)
        )

        async function onNotifierTimeout(correlationId: string, cancelRejectOnTimeout: () => void) {
            try {
                logger.interactionStarted('tryingToLoadTransactionsOnDeferredTimeout', {
                    extras: {correlationId}
                })

                await processFullSyncWithRetries()

                if (!notifier.isRegistered(correlationId)) {
                    log.info(`${correlationId} was approved by server but Notifier reached Request timed out`)
                    cancelRejectOnTimeout()
                    logger.interactionEnded('tryingToLoadTransactionsOnDeferredTimeout', {
                        extras: {correlationId, isTransactionApproved: true}
                    })
                } else {
                    log.info(`Notifier rejected ${correlationId} Request timed out`)
                    deferredTimeoutMap.set(correlationId, Date.now())
                    logger.interactionEnded('tryingToLoadTransactionsOnDeferredTimeout', {
                        extras: {correlationId, isTransactionApproved: false}
                    })
                }
                if (duplexer && !duplexer?.connected) {
                    await reconnectToDuplexerAndSubscribeToChannel()
                }
            } catch (e) {
                handleProcessingTransactionError('loadTransactionsFailedOnDeferredTimeout', e as Error, {
                    correlationId
                })
            }
        }

        const setEnabled = (enabled: boolean) => {
            this.isEnabled = enabled ?? true
        }

        const disableCSave = () => (this.config.disableSave = true)

        const disablePartialUpdate = () => {
            const saveStateApi: SaveStateApi = extensionAPI as SaveStateApi
            saveStateApi.saveState.setSaveAllowed(false)
        }

        const disableAllSaves = () => {
            disableCSave()
            disablePartialUpdate()
        }

        const isSavePermitted = (): boolean => {
            const {disableSave} = this.config
            const result = !disableSave && !this.validationRecovery
            return result
        }

        const shouldAutoSaveFromDsSiteApi = (): boolean => dsSiteApi.getAutosaveInfo()?.shouldAutoSave

        const isCSaveEnabled = (): boolean => {
            const isDraftMode = dsSiteApi.getIsDraft()
            const {disableAutoSave} = this.config
            const isAutosaveOn = !disableAutoSave
            const result = isAutosaveOn && this.isEnabled && shouldAutoSaveFromDsSiteApi()
            return result && !isDraftMode
        }

        const shouldSave = (): boolean => isSavePermitted() && isCSaveEnabled()

        const getIdFromAction = (action: Action) => {
            const {namespace, id} = action

            if (namespace === MULTILINGUAL_TYPES.multilingualTranslations && /^[^\^]+\^[^\^]+\^[^\^]+/.test(id!)) {
                const [, lang, dataItemId] = id!.split('^')
                return getTranslationItemKey(lang, dataItemId)
            }

            return id
        }

        const setValueToStore = (store: DmStore, action: Action): DalValue => {
            const {namespace, id, value, op} = action
            const pointer = getPointer(id!, namespace!)

            if (!value || (!action.basedOnSignature && !dal.hasSignature(pointer))) {
                store.set(pointer, value)
                return
            }

            store.set(pointer, value)
            const basedOnSignature = action.basedOnSignature ?? (op === ActionOperation.ADD ? undefined : null)
            store.set(dal.getBasedOnSignaturePointer(pointer), basedOnSignature)
        }

        const actionsToDalChanges = (actions: Action[]): DmStore => {
            const store = createStore()
            _.forEach(actions, (action: Action) => {
                setValueToStore(store, action)
            })
            return store
        }
        const isHeadless = () =>
            _.get(this.config, ['origin']) === 'DMMigrator' || _.get(this.config, ['origin']) === 'DMMigratorX'
        const isNewFromTemplate = (): boolean => siteAPI.isTemplate() && !dsSiteApi.getAutosaveInfo()?.shouldAutoSave

        const fetchTransaction = async (payload: ApprovedNonEmpty) => {
            log.info(`fetching remote transaction ${payload.correlationId}`)
            const tx = await this.server.getTransaction(payload.transactionId, dsSiteApi.getBranchId())
            if (!tx?.transaction) {
                throw new MissingTransactionInServerPayloadError(payload.correlationId, payload.transactionId)
            }
            return tx.transaction
        }

        const reportForeignVersionInfoChange = (foreignTxStore: DmStore) => {
            const revision = foreignTxStore.get(pointers.save.getSiteRevision())
            const version = foreignTxStore.get(pointers.documentServicesModel.getSiteVersion())
            const notEmpty = _.negate(_.isEmpty)
            const hasChange = _.some([revision, version], notEmpty)
            if (hasChange) {
                logger.interactionEnded('versionInfoUpdate', {
                    tags: {
                        revision,
                        version,
                        source: 'foreign_transaction'
                    }
                })
            }
        }

        const processForeignActions = async (actions: Action[], txId: string | null, correlationId?: string) => {
            if (txId && !longGt(txId, server.getLast()!)) {
                log.info(`TransactionAlreadyApproveError correlationId=${correlationId} transactionId=${txId}`)
                logger.captureError(
                    new TransactionAlreadyApproveError({
                        lastTxId: `${server.getLast()}`,
                        transactionId: txId,
                        correlationId: `${correlationId}`,
                        source: 'processForeignTransactions'
                    })
                )
                return dal.getLastApprovedSnapshot().id
            }
            const txStore = actionsToDalChanges(actions)
            reportForeignVersionInfoChange(txStore)
            if (_.isFunction(transactionApprovedCb)) {
                await transactionApprovedCb(txStore)
            }
            const lastApproved = dal.getLastApprovedSnapshot()
            const snapshot = dal.rebaseForeignChange(txStore, lastApproved.id, correlationId)
            const lastCSave = getLastCsaveSnapshot(snapshots)
            if (lastCSave === lastApproved) {
                dal.tagManager.addSnapshot(CSAVE_TAG, snapshot)
            }
            dal.approve(snapshot.id)
            return snapshot.id!
        }

        const isEmptyTx = (payload: Approved) => _.isNull(payload.transactionId)

        const isNonEmptyTx = (payload: Approved): payload is ApprovedNonEmpty => !isEmptyTx(payload)

        const isTransactionFromValidInitiator = (transaction: Transaction) => {
            const initiatorType = transaction?.metadata?.initiator?.initiatorType
            return initiatorType && [InitiatorType.DMS, InitiatorType.EDITOR_SERVER].includes(initiatorType)
        }

        const shouldProcessForeignTransaction = (transaction: Transaction) => {
            if (this.config.origin === classic) {
                return (
                    (this.config.cedit || this.config.acceptTransactionsFromDuplexer) &&
                    isTransactionFromValidInitiator(transaction)
                )
            }
            return true
        }

        const processForeignApproved = async (payload: Approved, transaction?: Transaction) => {
            if (!isNonEmptyTx(payload)) {
                return
            }
            logger.interactionStarted('processForeignApproved', {
                extras: {
                    correlationId: payload.correlationId
                }
            })
            log.info(`processing remote transaction ${payload.correlationId}`)

            const tx = _.isEmpty(transaction) ? await fetchTransaction(payload) : transaction

            this.unappliedActionCountSinceLastRevision += tx.actions?.length ?? 0

            if (shouldProcessForeignTransaction(tx)) {
                await processForeignActions(tx.actions!, payload.transactionId, payload.correlationId)
                processLastTxId(payload)
            } else {
                const staleStateError = new StaleStateOnForeignTransactionError(
                    payload.correlationId,
                    payload.transactionId,
                    tx?.metadata
                )
                logger.captureError(staleStateError)
                throw new Error(staleStateError.message)
            }
            distributor.foreignTransactionApplied(payload.correlationId)
            logger.interactionEnded('processForeignApproved', {
                extras: {
                    correlationId: payload.correlationId,
                    transactionId: payload.transactionId
                }
            })
        }

        const getPendingUpTo = (upTo: SnapshotDal): string[] => {
            const pendingSnapshots = dal.getLastApprovedSnapshot()!.createSnapshotChainTo(upTo)
            return _.map(pendingSnapshots, 'id')
        }

        const pendingUpToId = (id: string): string[] => getPendingUpTo(dal._snapshots.findById(id)!)

        const pendingTransactions = (): string[] => getPendingUpTo(dal.getLastSnapshot()!)

        const isLocalTransaction = (transactionId: string) => {
            const pending = pendingTransactions()
            return _.some(pending, (id: string) => id === transactionId)
        }

        const endCSaveTransactionInteraction = (correlationId: string) => {
            if (this.config.cedit) {
                logger.interactionEnded(CSAVE_TRANSACTION_INTERACTION, {extras: {correlationId}})
            }
        }

        const checkIfTransactionTimedOut = (correlationId: string) => {
            const timeoutTime = deferredTimeoutMap.get(correlationId)
            if (timeoutTime) {
                const approvalTime = Date.now()
                const diffMs = approvalTime - timeoutTime
                logger.captureError(
                    new ReportableWarning({
                        errorType: 'ApproveCorrelationAfterTimeout',
                        message: `Got approval from duplexer after deferred timeout for correlationId ${correlationId}`,
                        tags: {
                            correlationId,
                            timeoutTime: new Date(timeoutTime).toISOString(),
                            approvalTime: new Date(approvalTime).toISOString(),
                            diffMs
                        }
                    })
                )
                deferredTimeoutMap.delete(correlationId)
            }
        }

        const approveEmptyTransactions = (correlationId: string, pendingSnapshots: string[]) => {
            const emptyTransactions = this.emptySnapshotsMap.get(correlationId)
            const txToApprove = _.uniq([...emptyTransactions, ...pendingSnapshots]).sort(COLLATOR.compare)
            const lastTransactionId = txToApprove[txToApprove.length - 1]

            dal.approve(lastTransactionId)
            txToApprove.forEach(id => {
                distributor.transactionApproved(id, correlationId)
                notifier.resolve(id)
            })
            checkIfTransactionTimedOut(lastTransactionId)

            this.emptySnapshotsMap.delete(correlationId)
        }

        const processLocalApproved = (payload: Approved) => {
            const {correlationId, transactionId} = payload
            endCSaveTransactionInteraction(correlationId)
            logger.interactionStarted('processLocalApproved', {
                extras: {
                    correlationId
                }
            })

            log.info(`processing local transaction ${correlationId}`)
            // also resolve up to correlationId
            if (this.actionCountByCorrelationId[correlationId]) {
                this.unappliedActionCountSinceLastRevision += this.actionCountByCorrelationId[correlationId]
            } else {
                logger.interactionStarted('FailedSettingUnappliedActionCount', {
                    correlationId,
                    method: 'processLocalApproved'
                })
            }

            const pendingSnapshots = pendingUpToId(correlationId)
            if (pendingSnapshots.find(id => id === correlationId) === undefined) {
                logger.captureError(
                    new ReportableWarning({
                        errorType: 'CorrelationNotInPending',
                        message: `Cannot find correlationId ${correlationId} in pendingSnapshots. May result in timeout from saveAndWait`,
                        tags: {correlationId, pendingSnapshots}
                    })
                )
            }

            approveEmptyTransactions(correlationId, pendingSnapshots)
            logger.interactionEnded('processLocalApproved', {
                extras: {
                    correlationId,
                    transactionId
                }
            })
        }

        const startCSaveTransactionInteraction = (correlationId: string) => {
            if (this.config.cedit) {
                logger.interactionStarted(CSAVE_TRANSACTION_INTERACTION, {extras: {correlationId}})
            }
        }

        const validateTxId = (id: string | null) => {
            if (_.isUndefined(id) || id === '' || id === '0') {
                logger.captureError(new InvalidLastTransactionIdError(id))
            }
        }

        const safeLongGt = (a?: string, b?: string) => a && b && longGt(a, b)

        const isFirstTransaction = () => {
            const last = this.server.getLast()
            // the first transaction could be numbered differently
            return last === dsSiteApi.getAutosaveInfo()?.lastTransactionId
        }

        const isGreaterThanLast = (transactionId: string) => safeLongGt(transactionId, this.server.getLast())

        const updateLastTxId = (transactionId: string | null) => {
            if (transactionId && (isFirstTransaction() || isGreaterThanLast(transactionId))) {
                this.server.setLast(transactionId)
                this.server.setLastSaveDate(new Date())
            }
            this.validationRecovery = false
        }

        function processLastTxId(payload: Approved): void {
            if (isNonEmptyTx(payload)) {
                validateTxId(payload.transactionId)
                updateLastTxId(payload.transactionId)
            }
        }

        const getUnappliedActionCountSinceLastRevision = () => this.unappliedActionCountSinceLastRevision

        const processTransaction = async (payload: Approved, transaction?: Transaction): Promise<void> => {
            logger.interactionStarted('processTransaction', {
                extras: {
                    isLocal: isLocalTransaction(payload.correlationId),
                    transactionId: payload.transactionId,
                    correlationId: payload.correlationId
                }
            })
            if (isLocalTransaction(payload.correlationId)) {
                processLocalApproved(payload)
                processLastTxId(payload)
            } else {
                await processForeignApproved(payload, transaction)
            }
            logger.interactionEnded('processTransaction', {
                extras: {
                    isLocal: isLocalTransaction(payload.correlationId),
                    transactionId: payload.transactionId,
                    correlationId: payload.correlationId
                }
            })
        }

        //Transactions must be processed in the order that they were received, otherwise we might cause a corruption
        async function processTransactions(transactions: Transaction[]): Promise<void> {
            for (const transaction of transactions) {
                await processTransaction(
                    {
                        transactionId: transaction.transactionId!,
                        correlationId: transaction.metadata!.correlationId!
                    },
                    transaction
                )
            }
        }

        async function processFullSync() {
            const lastTransactionId = server.getLast()
            log.info('sync from lastTransactionId =', lastTransactionId)
            const branchId = dsSiteApi.getBranchId()
            logger.interactionStarted('processFullSync', {
                extras: {
                    lastTransactionId,
                    branchId,
                    time: new Date(),
                    isWixInstanceExpired: rendererModel.isWixInstanceExpired()
                }
            })
            const result = await server.getTransactions(lastTransactionId, undefined, branchId)
            await processTransactions(result.transactions!)
            logger.interactionEnded('processFullSync', {
                extras: {lastTransactionId, time: new Date()}
            })
        }

        async function processFullSyncWithRetries() {
            const timeout = 1000 * 60 * 0.5
            const timeoutErrorMessage = 'processFullSync timed out'
            const processFullSyncWithTimeout = withTimeout(processFullSync, timeout, {
                message: timeoutErrorMessage
            }) as () => Promise<void>
            const shouldRetry = (error: any) => {
                return error.message === timeoutErrorMessage
            }
            await taskWithRetries(processFullSyncWithTimeout, shouldRetry, 2, 1000)
        }

        const getProcessFullSync = (): QueueFunction => async (): Promise<void> => {
            try {
                await processFullSyncWithRetries()
            } catch (e) {
                handleProcessingTransactionError('processFullSync', e)
                throw e
            }
        }

        const getProcessApproved =
            (payload: Approved): QueueFunction =>
            async (): Promise<void> => {
                try {
                    logger.interactionStarted('processApproveTransaction', {
                        extras: {correlationId: payload.correlationId, isEmptyTransaction: isEmptyTx(payload)}
                    })
                    await processTransaction(payload)
                    logger.interactionEnded('processApproveTransaction', {
                        extras: {correlationId: payload.correlationId, isEmptyTransaction: isEmptyTx(payload)}
                    })
                } catch (e) {
                    handleProcessingTransactionError('processApproved', e as Error, {
                        transactionId: payload.transactionId ?? '',
                        correlationId: payload.correlationId
                    })
                    throw e
                }
            }

        const approveEmptyTransactionsAfterRejection = (
            correlationId: string,
            pendingSnapshots: string[],
            errorCode?: string
        ) => {
            pendingSnapshots.forEach(id => notifier.reject(id, new TransactionRejectionError(id, errorCode)))

            const emptyTransactions = this.emptySnapshotsMap.get(correlationId)
            const txToApprove = _.without(emptyTransactions, ...pendingSnapshots)
            if (!txToApprove.length) {
                return
            }

            txToApprove.forEach(id => notifier.resolve(id))
            const lastTxToApprove = txToApprove[txToApprove.length - 1]
            if (COLLATOR.compare(lastTxToApprove, correlationId) > 0) {
                dal.approve(lastTxToApprove)
            }
        }

        const notifyDMError = (payload?: TransactionRejectionDuplexerPayload) => {
            if (!payload) {
                return
            }

            const {correlationId, errorCode} = payload
            logger.captureError(
                new ReportableError({
                    errorType: 'RejectedTransaction',
                    message: `Got Rejection for local transaction ${correlationId} from duplexer`,
                    extras: {errorCode, originalError: payload}
                })
            )
            if (!errorCode || errorCode === documentStoreErrors.CONFLICT_DETECTED) {
                return
            }
            const userInfo = dal.get(pointers.documentServicesModel.getUserInfo())
            this.hooks.onDiffSaveFinished?.(convertToDSError(payload, userInfo))
        }

        const getProcessRejected =
            (correlationId: string, payload?: TransactionRejectionDuplexerPayload): QueueFunction =>
            (): void => {
                const pendingSnapshots = defaultAttempt([], pendingUpToId, correlationId)
                const isLocal = isLocalTransaction(correlationId)
                try {
                    if (isLocal) {
                        log.info(`processed reject for ${correlationId}`)
                        dal.reject(correlationId)
                    } else {
                        log.info(`ignoring remote reject for ${correlationId}`)
                    }
                } catch (e) {
                    handleProcessingTransactionError('processRejected', e as Error, {correlationId})
                    throw e
                } finally {
                    const snapshotsForReports = _.dropRight(pendingSnapshots, 1)
                    const hasRegisteredPending = snapshotsForReports.some(id => notifier.isRegistered(id))
                    logger.captureError(
                        new ReportableWarning({
                            errorType: 'PendingSnapshotRejection',
                            tags: {hasRegisteredPending},
                            message: 'Rejected pending snapshot'
                        })
                    )
                    approveEmptyTransactionsAfterRejection(correlationId, pendingSnapshots, payload?.errorCode)

                    if (isLocal) {
                        notifyDMError(payload)
                    }
                }
            }

        const createAsyncDuplexer = (): Channel => {
            if (this.config.cedit || this.config.acceptTransactionsFromDuplexer) {
                const branchId = dsSiteApi.getBranchId()
                return this.server.createDuplexer(() => siteAPI.getInstance(), this.config.origin, logger, branchId)
            }
            return new MockCSDuplexer()
        }

        const onApprovedTx = (payload: Approved): void => {
            const {correlationId, transactionId} = payload
            logger.interactionStarted('onApprovedTx', {})
            orderCheck.check(transactionId, correlationId, {usingCEdit: this.config.cedit})
            log.info(`approved ${correlationId}`)
            queue.add(getProcessApproved(payload))
            logger.interactionEnded('onApprovedTx', {})
        }

        const logRejection = (correlationId: string, reason?: string) => {
            const conflictDetected = 'conflict detected: '
            if (reason?.startsWith(conflictDetected)) {
                const json = reason?.substring(conflictDetected.length)
                const details = JSON.parse(json)
                log.info(`rejected ${correlationId} conflict detected: `, details)
            } else {
                log.info(`rejected ${correlationId} ${reason}`)
            }
        }

        const reportRejection = (payload: Rejected): void => {
            const {correlationId, reason} = payload
            logger.interactionStarted(CSAVE_TRANSACTION_REJECTED, {
                tags: {
                    isLocal: isLocalTransaction(correlationId)
                },
                extras: {
                    reason
                }
            })
            logRejection(correlationId, reason)
        }

        const onRejectedTx = (payload: Rejected): void => {
            const {correlationId} = payload
            reportRejection(payload)
            queue.add(getProcessRejected(correlationId, payload))
        }

        const loadTransactions = async () => {
            queue.add(getProcessFullSync())
        }

        async function reconnectToDuplexerAndSubscribeToChannel() {
            if (duplexerIsReconnecting) {
                return
            }
            try {
                duplexerIsReconnecting = true
                if (duplexer) {
                    duplexer.removeChannelEventListeners()
                }
                duplexer = createAsyncDuplexer()
                await duplexer.subscribe(channelName, experimentInstance)

                const approvedHandler = (payload: Approved) => {
                    logger.interactionStarted('gotApproveFromDuplexer', {
                        extras: {
                            correctionId: payload.correlationId,
                            transactionId: payload.transactionId,
                            lastTransactionIdFromFacade: server.getLast()
                        }
                    })
                    if (server.getLast()) {
                        onApprovedTx(payload)
                    }
                    logger.interactionEnded('gotApproveFromDuplexer', {
                        extras: {correctionId: payload.correlationId}
                    })
                }
                const rejectHanlder = (payload: Rejected) => {
                    logger.interactionStarted('gotRejectFromDuplexer', {
                        extras: {
                            correctionId: payload.correlationId,
                            reason: payload.reason
                        }
                    })
                    onRejectedTx(payload)
                    logger.interactionEnded('gotRejectFromDuplexer', {
                        extras: {
                            correctionId: payload.correlationId
                        }
                    })
                }

                duplexer.on(ChannelEvents.batchTransactions, (payload: BatchTransactions) => {
                    const batchItems = Object.fromEntries(
                        payload.transactionsResults?.map(x => [
                            `${x.rejected?.correlationId ?? x.approved?.correlationId}`,
                            x.approved ? 'Approved' : 'Rejected'
                        ]) ?? []
                    )
                    logger.interactionStarted('batchTransactionsFromDuplexer', {
                        extras: {
                            correlationIds: batchItems,
                            lastTransactionIdFromFacade: server.getLast()
                        }
                    })
                    payload.transactionsResults?.forEach(transactionResult => {
                        if (transactionResult.approved) {
                            approvedHandler(transactionResult.approved)
                        }
                        if (transactionResult.rejected) {
                            rejectHanlder(transactionResult.rejected)
                        }
                    })
                    logger.interactionEnded('batchTransactionsFromDuplexer', {
                        extras: {correlationIds: batchItems}
                    })
                })

                duplexer.on(ChannelEvents.approved, approvedHandler)
                duplexer.on(ChannelEvents.rejected, rejectHanlder)
                duplexer.on(ChannelEvents.majorSiteChange, (payload?: StaleEditorEnvironment) => {
                    if (payload?.reason === 'staleEditorEnvironment') {
                        log.info('majorSiteChange event received OUTDATED_VERSION')
                        hooks.onRefreshRequired?.('OUTDATED_VERSION')
                    } else {
                        log.info('majorSiteChange event received VERSION_RESTORED')
                        hooks.onRefreshRequired?.('VERSION_RESTORED')
                    }
                })
                duplexer.on(ChannelEvents.outOfSync, async () => {
                    logger.interactionStarted('processDuplexerOutOfSync')
                    log.info('Duplexer out of sync - loading transactions')
                    try {
                        await loadTransactions()
                        logger.interactionEnded('processDuplexerOutOfSync')
                    } catch (e: any) {
                        handleProcessingTransactionError('loadTransactions', e)
                        throw e
                    }
                })
                await server.onChannelReady?.()
            } catch (e: any) {
                log.error('error connecting to duplexer', e)
                logger.interactionStarted('subscribeToDuplexerFailed', {
                    extras: {error: JSON.stringify(e)}
                })
            } finally {
                duplexerIsReconnecting = false
            }
        }

        function handleRemovalError(error: SafeRemovalError) {
            const {exception, invalidData, namespace, dataType} = error
            logger.captureError(
                new ReportableError({
                    errorType: _.get(exception, ['errorType'], 'unknownSaveError'),
                    message: exception.message,
                    tags: {
                        additionalProperties: true,
                        namespace,
                        dataType,
                        saveFailure: 'handleRemovalError'
                    },
                    extras: {invalidData}
                })
            )
            eventEmitter.emit(CS_EVENTS.CSAVE.NON_RECOVERABLE_ERROR)
        }

        const isRejectionError = (e: any): boolean => {
            const newExp = experimentInstance.isOpen('dm_avoidDalRejectAlways')
            if (newExp) {
                return e?.details?.applicationError?.code === documentStoreErrors.SCHEMA_VALIDATION_FAILED
            }
            if (VersionInfoUpdateError.isVersionInfoUpdateError(e)) {
                logger.captureError(
                    new ReportableWarning({
                        errorType: 'VersionInfoIgnoreRejection',
                        message:
                            'A VersionInfo transactions is about to be rejected. Ignoring it. It will be sent in the nexst csave',
                        extras: {
                            rejectionError: JSON.stringify(e)
                        }
                    })
                )
                return false
            }
            return !isNetworkError(e)
        }

        const didUserHavePermissionOnLoad = (permission: string) => {
            const permissionsOnLoad = _.get(window, ['documentServicesModel', 'permissionsInfo', 'permissions'], [])
            return permissionsOnLoad.includes(permission)
        }

        const handleCSaveError = (e: any, fromSnapshot: Null<SnapshotDal>, toSnapshot: SnapshotDal) => {
            if (e.status === 403 || e.extras?.status === 403) {
                logger.captureError(
                    new ReportableWarning({
                        errorType: 'csave-403',
                        message: 'USER_NOT_AUTHORIZED_FOR_SITE (403 error on csave)',
                        tags: {
                            savePermissionOnLoad: didUserHavePermissionOnLoad('html-editor.save'),
                            editPermissionOnSave: didUserHavePermissionOnLoad('html-editor.edit')
                        },
                        extras: {
                            originalError: JSON.stringify(e)
                        }
                    })
                )
            }
            const userInfo = dal.get(pointers.documentServicesModel.getUserInfo())
            if (isRejectionError(e)) {
                // Revert the snapshot in the dal and report
                if (toSnapshot?.id !== undefined) {
                    dal.reject(toSnapshot.id)
                }
                logger.captureError(
                    new ReportableWarning(
                        getReportableFromError(e, {
                            errorType: 'cSaveRejectError',
                            message: e.message,
                            tags: {
                                dm_avoidDalRejectAlways: experimentInstance.isOpen('dm_avoidDalRejectAlways'),
                                status: e.extras?.status,
                                type: e.errorType
                            }
                        })
                    )
                )
                if (!experimentInstance.isOpen('dm_returnErrorToEditorOnCsaveNetworkError')) {
                    this.hooks.onDiffSaveFinished?.(convertToDSError(e, userInfo))
                }
            } else {
                // Remove the CSAVE_TAG from the latest CSAVE snapshot, without removing the snapshot itself from the dal
                // This means that this snapshot will be sent with the *next* csave
                snapshots.removeLastSnapshot(CSAVE_TAG)
            }
            if (experimentInstance.isOpen('dm_returnErrorToEditorOnCsaveNetworkError')) {
                this.hooks.onDiffSaveFinished?.(convertToDSError(e, userInfo))
            }
            if (
                experimentInstance.isOpen('dm_disableSaveAfterStaleError') &&
                (isStaleTransactionError(e) || isStaleVersionError(e))
            ) {
                disableAllSaves()
            }
            log.error('Error while trying to save continuously', e)
        }

        interface RetryServerSaveTaskOptions {
            getReasonForRetry?(e: any): string | undefined
        }

        const retryServerSaveTask = async <T>(
            name: string,
            fn: () => Promise<T>,
            options?: RetryServerSaveTaskOptions
        ): Promise<T> =>
            retryTaskAndReport({
                task: fn,
                checkIfShouldRetry: (e: any) => {
                    let reason
                    if (e?.status === 503) {
                        reason = '503'
                    } else if (
                        _.get(e, ['details', 'applicationError', 'code']) ===
                        serverSaveErrorCodes.IDENTITY_UNKNOWN_RUNTIME_ERROR
                    ) {
                        reason = serverSaveErrorCodes.IDENTITY_UNKNOWN_RUNTIME_ERROR
                    } else {
                        reason = options?.getReasonForRetry?.(e)
                    }
                    if (reason) {
                        return {shouldRetry: true, reason}
                    }
                    return {shouldRetry: false}
                },
                interactionName: name,
                maxRetries: 1,
                logger
            })

        const saveSync = async (payload: CreateTransactionRequest, correlationId: string) => {
            payload.correlationId = correlationId
            const {transactionId} = await retryServerSaveTask('syncSave', () => server.save(payload))
            reportTransactionId(logger, log, transactionId, correlationId, server.getLast())
            processLocalApproved({correlationId, transactionId: transactionId!})
            updateLastTxId(transactionId!)
        }

        const saveSyncWithMultipleTransactions = async (payload: SaveRequest) => {
            const response = await retryServerSaveTask('saveSyncWithMultipleTransactions', () =>
                server.saveSyncWithMultipleTransactions(payload)
            )
            const rejectedTransactions = _.filter(response.results, 'rejected')
            const approvedTransactions = _.filter(response.results, 'approved')
            _.forEach(approvedTransactions, (transaction: TransactionResult) => {
                const {correlationId, transactionId} = transaction.approved!
                reportTransactionId(logger, log, transactionId, correlationId, server.getLast())
                processLocalApproved({correlationId: correlationId!, transactionId: transactionId!})
                updateLastTxId(transactionId!)
            })
            _.forEach(rejectedTransactions, transaction => {
                const {correlationId} = transaction.rejected!
                const fn = getProcessRejected(correlationId!, transaction.rejected!)
                fn()
            })
            return response
        }
        const shouldNotSquashTransactions = () =>
            _.isBoolean(this.config.shouldSquashTransactions) && !this.config.shouldSquashTransactions

        const splitSaveByTransactionsLimit = async (payload: SaveRequest) => {
            const {transactions} = payload
            const splitTransactions = _.chunk(transactions, this.config.csaveTransactionsLimit)
            log.info(
                `splitting save request by transactions limit of ${this.config.csaveTransactionsLimit} to ${splitTransactions.length} chunks`
            )
            for (const transactionBatch of splitTransactions) {
                const splitPayload = {...payload, transactions: transactionBatch}
                await retryServerSaveTask('asyncSave', () => server.asyncSave(splitPayload))
            }
        }

        const sendSaveToServer = async (payload: SaveRequest, correlationId: string) => {
            if (this.config.cedit) {
                return await splitSaveByTransactionsLimit(payload)
            } else if (shouldNotSquashTransactions()) {
                return await saveSyncWithMultipleTransactions(payload)
            }
            return await saveSync(_.head<CreateTransactionRequest>(payload.transactions)!, correlationId)
        }

        const saveDiff = async (payload: SaveRequest, toSnapshot: SnapshotDal) => {
            const correlationId = toSnapshot.id
            if (!this.firstCorrelationId) {
                this.firstCorrelationId = correlationId
            }
            this.hooks.onDiffSaveStarted?.()

            snapshots.tagSnapshot(CSAVE_TAG, toSnapshot)
            if (this.isSaveDisabledDuringRequiredAndPrimary) {
                logger.captureError(
                    new ReportableError({
                        message: 'saveDiff',
                        errorType: 'cSaveDuringFullSave',
                        extras: {
                            pendingIndex: this.pendingIndex,
                            correlationId
                        }
                    })
                )
            }

            startCSaveTransactionInteraction(correlationId)
            if (experimentInstance.isOpen('dm_resetTimeoutBeforeSendingCsaveToServer')) {
                notifier.resetTimer(correlationId, {timeout: notifierSendToServerTimeout})
            }
            const res = await sendSaveToServer(payload, correlationId)
            this.hooks.onDiffSaveFinished?.()
            return res
        }

        const createTransaction = (toSnapshot: SnapshotDal) => {
            const siteVersion = `${dsSiteApi.getSiteVersion()}`
            const {result: diff, error} = converter.convertData(
                toSnapshot.getPreviousSnapshot()!,
                toSnapshot,
                logger,
                experimentInstance,
                (extensionAPI as SchemaExtensionAPI).schemaAPI,
                extensionAPI as RelationshipsAPI,
                (extensionAPI as DistributorExtensionAPI).distributor
            )
            if (error) {
                throw error
            }
            const transaction: CreateTransactionRequest = {
                correlationId: toSnapshot.id,
                actions: diff! as Action[],
                metadata: {siteVersion, initiator: {initiatorType: InitiatorType.DM_CLIENT}},
                envSessionId: dsSiteApi.getEnvSessionId()
            }
            return transaction
        }

        const addEmptyTransactionToSnapshotMap = (
            emptyTx: CreateTransactionRequest,
            allTxs: CreateTransactionRequest[]
        ) => {
            const txIndex = allTxs.indexOf(emptyTx)
            const nextTransaction = allTxs.slice(txIndex, allTxs.length).find(tx => tx.actions?.length !== 0)

            let previousTransaction
            if (!nextTransaction) {
                previousTransaction = allTxs
                    .slice(0, txIndex)
                    .reverse()
                    .find((tx: CreateTransactionRequest) => tx.actions?.length !== 0)
            }

            const transactionKey = nextTransaction?.correlationId || previousTransaction?.correlationId
            if (!transactionKey) {
                return
            }

            this.emptySnapshotsMap.set(transactionKey, emptyTx.correlationId!)
        }

        const extractFragmentsDataFromNamespaceName = (namespaceName: string): FragmentDetails => {
            // The logic for extracting fragments data from the namespace name
            // will be moved to the fragment extension in the next fragment-related PR.
            // It's even more important than usual to reduce duplication since this logic will likely change
            if (namespaceName === 'fragments_index') {
                return {workspace: 'fragments_index', type: 'WorkspaceIndex', innerNamespace: 'fragments_index'}
            }
            const [, workspace, type, innerNamespace] = namespaceName.split('_')
            return {workspace, type, innerNamespace}
        }

        const isActionRelatedToFragments = (action: Action): boolean => FRAGMENT_NAMESPACES.includes(action.namespace!)

        const filterOutEmptyTransactions = (transactions: CreateTransactionRequest[]): CreateTransactionRequest[] => {
            return transactions.filter(edsTx => {
                if (edsTx.actions?.length === 0) {
                    addEmptyTransactionToSnapshotMap(edsTx, transactions)
                    return false
                }
                return true
            })
        }

        const createTransactionsCEdit = (
            fromSnapshot: Null<SnapshotDal>,
            toSnapshot: SnapshotDal
        ): CreateTransactionReq => {
            const chain = createSnapshotChain(fromSnapshot, toSnapshot, 'createTransactionsCEdit')
            try {
                const branchId = dsSiteApi.getBranchId()
                const transactions = filterOutEmptyTransactions(chain.map(createTransaction))
                const payload = {transactions, branchId}
                return {payload: payload as SaveRequest}
            } catch (e) {
                return {error: e as SafeRemovalError}
            }
        }

        const createTransactionsCSave = (
            fromSnapshot: Null<SnapshotDal>,
            toSnapshot: SnapshotDal
        ): CreateTransactionReq => {
            const siteVersion = `${dsSiteApi.getSiteVersion()}`
            const {result: diff, error} = converter.convertData(
                fromSnapshot,
                toSnapshot,
                logger,
                experimentInstance,
                (extensionAPI as SchemaExtensionAPI).schemaAPI,
                extensionAPI as RelationshipsAPI,
                (extensionAPI as DistributorExtensionAPI).distributor
            )
            if (error) {
                return {error}
            }
            if (_.isEmpty(diff)) {
                return {
                    payload: {
                        transactions: []
                    }
                }
            }
            const transaction: CreateTransactionRequest | PendingTransaction = {
                actions: diff! as Action[],
                metadata: {
                    lastTransactionId: this.server.getLast(),
                    siteVersion,
                    initiator: {initiatorType: InitiatorType.DM_CLIENT}
                },
                envSessionId: dsSiteApi.getEnvSessionId(),
                branchId: dsSiteApi.getBranchId()
            }
            const payload = {
                transactions: [transaction]
            }
            return {payload: payload as SaveRequest}
        }

        const actionColor = (action: Action): FragmentDetails | null => {
            if (isActionRelatedToFragments(action)) {
                // We can use action.namespace! since `isActionRelatedToFragments` will return false if it's missing
                return extractFragmentsDataFromNamespaceName(action.namespace!)
            }
            return null
        }

        const createTransactions = (fromSnapshot: Null<SnapshotDal>, toSnapshot: SnapshotDal): CreateTransactionReq => {
            const shouldUseCedit = this.config.cedit || shouldNotSquashTransactions()
            const result = shouldUseCedit
                ? createTransactionsCEdit(fromSnapshot, toSnapshot)
                : createTransactionsCSave(fromSnapshot, toSnapshot)

            // Validation - A transaction can either:
            // - Contain actions that all change one fragment type
            // - Contain actions that don't change any fragments at all
            // But not both
            if (result.error) {
                return result
            }

            // We can use payload! since we already checked for errors
            const actions: Action[] = _.flatMap(result.payload!.transactions, 'actions')
            const firstAction = actions[0]
            if (!firstAction) {
                return result
            }

            const firstActionColor = actionColor(firstAction)
            if (actions.some(action => !_.isEqual(actionColor(action), firstActionColor))) {
                throw new Error('A transaction cannot contain actions that change different fragment types')
            }
            return result
        }

        const getToSnapshotWithinActionsLimit = (
            fromSnapshot: Null<SnapshotDal>,
            toSnapshot: SnapshotDal
        ): SnapshotDal => {
            const getSnapshotActionsCount = (snapshot: SnapshotDal) => snapshot.getStore().size()
            const snapshotsChain = createSnapshotChain(fromSnapshot, toSnapshot, 'getSnapshotActionsCount')

            let actionsSum = 0
            const finalSnapshot = _.reduce(
                snapshotsChain,
                (lastValidSnapshot, candidateSnapshot) => {
                    actionsSum += getSnapshotActionsCount(candidateSnapshot)
                    if (actionsSum > this.config.csaveSnapshotActionsLimit) {
                        return lastValidSnapshot
                    }
                    return candidateSnapshot
                },
                snapshotsChain[0]
            )

            return finalSnapshot
        }

        const resetPendingIndexForFirstChunk = (isSavingChunk: boolean) => {
            if (!isSavingChunk) {
                this.pendingIndex = -1
            }
        }

        const shouldReportBigActionData = (action: Action) => {
            return action.namespace === 'tpaSharedState' && action?.value?.data && !action.id?.includes('THEME_CHANGE')
        }
        const getTransactionsContent = (payload: SaveRequest) => {
            const sharedStateActions: any[] = []
            const transactions_content = _.map(payload.transactions, ({correlationId, actions = []}) => {
                actions.forEach(action => {
                    if (shouldReportBigActionData(action)) {
                        const size = JSON.stringify(action.value.data).length
                        if (size > 1024) {
                            sharedStateActions.push({
                                id: action.id,
                                size
                            })
                        }
                    }
                })
                return {
                    correlationId,
                    content: _.countBy(actions, 'namespace')
                }
            })
            return {transactions_content, sharedStateActions}
        }

        const getActionCountByCorrelationIdForNonCedit = (payload: SaveRequest, correlationId?: string) => {
            const result: Record<string, number> = {}
            if (correlationId) {
                result[correlationId] = _.sum(
                    payload?.transactions?.map(transaction => transaction.actions?.length ?? 0)
                )
            }
            return result
        }

        const getActionCountByCorrelationIdForCedit = (payload: SaveRequest) => {
            const result: Record<string, number> = {}
            const transactions =
                payload?.transactions?.filter(transaction => !!transaction.correlationId && !!transaction.actions) ?? []
            for (const transaction of transactions) {
                result[transaction.correlationId!] = transaction.actions!.length
            }

            return result
        }

        const updateActionCountByCorrelationId = (payload: SaveRequest, correlationId: string) => {
            const actionCountByCorrelationId = this.config.cedit
                ? getActionCountByCorrelationIdForCedit(payload)
                : getActionCountByCorrelationIdForNonCedit(payload, correlationId)

            this.actionCountByCorrelationId = Object.assign(this.actionCountByCorrelationId, actionCountByCorrelationId)
        }

        const logCsavePayloadSize = (payload: SaveRequest) => {
            const actionCount = _.sum(payload.transactions?.map(t => t.actions?.length))

            logger.interactionStarted(CSAVE_PAYLOAD_SIZE, {
                extras: {
                    actionCountSent: actionCount,
                    unappliedActionCountSinceLastRevision: this.unappliedActionCountSinceLastRevision
                }
            })
            logger.interactionEnded(CSAVE_PAYLOAD_SIZE)
        }

        const saveSnapshotsDiff = async (
            fromSnapshot: Null<SnapshotDal>,
            toSnapshot: SnapshotDal,
            isSavingChunk: boolean = false
        ): Promise<void | SaveTransactionResponse> => {
            if (toSnapshot === fromSnapshot) {
                log.info('trying to save diff between same snapshots')
                notifier.resolve(toSnapshot.id)
                return
            }
            logger.interactionStarted(CSAVE_INTERACTION, {
                stack: true,
                extras: {correlation_id: toSnapshot.id, fromSnapshot: fromSnapshot?.id}
            })
            resetPendingIndexForFirstChunk(isSavingChunk)
            const snapshotToSave = getToSnapshotWithinActionsLimit(fromSnapshot, toSnapshot)
            const isLastChunk = snapshotToSave.id === toSnapshot.id

            const {error, payload} = createTransactions(fromSnapshot, snapshotToSave)

            if (error) {
                handleRemovalError(error)
                snapshots.tagSnapshot(CSAVE_TAG, snapshots.getLastSnapshot()!)
                this.pendingIndex = -1
                notifier.resolve(snapshotToSave.id)
                if (experimentInstance.isOpen('dm_rejectDalOnBuildingPayloadForCsaveError')) {
                    dal.reject(snapshotToSave.id)
                }
                return
            }
            if (_.isEmpty(payload?.transactions)) {
                log.info('no diff, skipping save')
                if (!this.config.cedit) {
                    processLocalApproved({correlationId: snapshotToSave.id, transactionId: null})
                    snapshots.tagSnapshot(CSAVE_TAG, snapshotToSave)
                }
                notifier.resolve(snapshotToSave.id)
                logger.interactionEnded(CSAVE_INTERACTION, {
                    tags: {skipped: true},
                    extras: {correlation_id: snapshotToSave.id, fromSnapshot: fromSnapshot?.id}
                })
                await handleTheRestOfTheChunksAndThePendingSnapshots(snapshotToSave, toSnapshot, isLastChunk)
                return
            }
            let res
            try {
                logCsavePayloadSize(payload!)
                updateActionCountByCorrelationId(payload!, snapshotToSave.id)
                res = await saveDiff(payload!, snapshotToSave)
            } catch (e: any) {
                const avoidDalRejectOnVersionInfo = experimentInstance.isOpen('dm_avoidDalRejectOnVersionInfo')
                const shouldThrowVersionError =
                    avoidDalRejectOnVersionInfo && VersionInfoUpdateError.shouldThrowVersionInfoUpdateError(payload)
                throw shouldThrowVersionError ? new VersionInfoUpdateError(e) : e
            }

            await handleTheRestOfTheChunksAndThePendingSnapshots(snapshotToSave, toSnapshot, isLastChunk)

            const extras = {correlation_id: toSnapshot.id, fromSnapshot: fromSnapshot?.id} as any
            const {sharedStateActions} = getTransactionsContent(payload!)

            if (sharedStateActions.length > 0) {
                extras.sharedStateActions = sharedStateActions
            }
            logger.interactionEnded(CSAVE_INTERACTION, {
                tags: {skipped: false},
                extras
            })
            return res
        }

        const handlePendingSnapshots = async () => {
            if (this.pendingIndex > -1) {
                const fromLastCsaveSnapshot = getLastCsaveSnapshot(snapshots)
                const toPendingSnapshot = snapshots.getSnapshotByTagAndIndex(CSAVE_PENDING_TAG, this.pendingIndex)
                await saveSnapshotsDiff(fromLastCsaveSnapshot, toPendingSnapshot)
            }
        }

        async function handleTheRestOfTheChunksAndThePendingSnapshots(
            fromChunkSnapshot: SnapshotDal,
            toSnapshot: SnapshotDal,
            isLastChunk: boolean
        ) {
            if (!isLastChunk) {
                await saveSnapshotsDiff(fromChunkSnapshot, toSnapshot, true)
            } else {
                await handlePendingSnapshots()
            }
        }

        const setSaving = (isSaving: boolean) => {
            log.info('state.saving', isSaving)
            if (experimentInstance.isOpen('dm_handlePendingSnapshotsAfterFinishedAllSaves')) {
                if (this.saving && !isSaving && !this.isSaveDisabledDuringRequiredAndPrimary) {
                    this.saving = isSaving
                    eventEmitter.emit(CS_EVENTS.SAVE.FINISHED_SAVING)
                    return
                }
            }
            this.saving = isSaving
        }

        const disableSaveDuringRequiredAndPrimary = (shouldDisableSave: boolean) => {
            if (experimentInstance.isOpen('dm_handlePendingSnapshotsAfterFinishedAllSaves')) {
                if (this.isSaveDisabledDuringRequiredAndPrimary && !shouldDisableSave && !this.saving) {
                    this.isSaveDisabledDuringRequiredAndPrimary = shouldDisableSave
                    eventEmitter.emit(CS_EVENTS.SAVE.FINISHED_SAVING)
                    return
                }
            }
            this.isSaveDisabledDuringRequiredAndPrimary = shouldDisableSave
        }

        const saveFromLastCSaveSnapshot = async (snapshot: SnapshotDal): Promise<void | SaveTransactionResponse> => {
            if (this.isSaveDisabledDuringRequiredAndPrimary && !this.saving) {
                logger.captureError(
                    new ReportableError({
                        message: `saveFromLastCSaveSnapshot`,
                        errorType: 'cSaveDuringFullSave',
                        extras: {
                            pendingIndex: this.pendingIndex
                        }
                    })
                )
            }

            if (this.saving || this.isSaveDisabledDuringRequiredAndPrimary) {
                log.info('save is in progress, skipping save')
                this.pendingIndex = snapshots.tagSnapshot(CSAVE_PENDING_TAG, snapshot)
            } else {
                const csaveSnap = getLastCsaveSnapshot(snapshots)
                try {
                    setSaving(true)
                    return await saveSnapshotsDiff(csaveSnap, snapshot)
                } catch (e) {
                    const recentCsaveSnapshot = getLastCsaveSnapshot(snapshots)
                    if (recentCsaveSnapshot?.id !== undefined) {
                        notifier.reject(recentCsaveSnapshot.id, e)
                    }
                    handleCSaveError(e, csaveSnap, recentCsaveSnapshot)
                    throw makeReportable(e)
                } finally {
                    setSaving(false)
                }
            }
        }

        const save = async () => {
            if (shouldSave()) {
                const lastSnapshot = snapshots.getLastSnapshot()!
                if (lastSnapshot === undefined) {
                    logger.captureError(
                        new ReportableWarning({
                            errorType: 'NoSnapshot',
                            message: 'No snapshot to save from',
                            tags: {
                                csaveOp: 'save'
                            }
                        })
                    )
                }
                const lastCSave = getLastCsaveSnapshot(snapshots)
                if (lastSnapshot === lastCSave) {
                    return
                }

                notifier
                    .register(lastSnapshot.id, {
                        extras: {
                            cedit: this.config.cedit,
                            isDuplexerDisconnected: !duplexer?.connected
                        },
                        rejectionOnTimeoutCb: (error: any) => {
                            const userInfo = dal.get(pointers.documentServicesModel.getUserInfo())
                            this.hooks.onDiffSaveFinished?.(convertToDSError(error, userInfo))
                        }
                    })
                    .catch(_.noop)

                try {
                    return await saveFromLastCSaveSnapshot(lastSnapshot)
                } catch (e) {
                    logger.captureError(e as Error, {tags: {csaveOp: 'save'}})
                    if (lastSnapshot?.id !== undefined) {
                        notifier.reject(lastSnapshot.id, e)
                    }
                    throw e
                }
            }
        }

        const createDummyTx = () => {
            const somePointerOnMasterPage = {id: 'masterPage', type: 'fixerVersions'}
            dal.touch(somePointerOnMasterPage)
            dal.commitTransaction()
        }

        const saveAndWait = async (triggeredFromForcedSave: boolean): Promise<void> => {
            if (!this.isInitialized) {
                logger.captureError(
                    new ReportableWarning({
                        errorType: 'NotInitialized',
                        message: 'Save and wait called before initialization',
                        tags: {
                            csaveOp: 'saveAndWait',
                            triggeredFromForcedSave
                        }
                    })
                )
            }
            let snapshot = snapshots.getLastSnapshot()!
            if (snapshot === undefined) {
                logger.captureError(
                    new ReportableWarning({
                        errorType: 'NoSnapshot',
                        message: 'No snapshot to save from',
                        tags: {
                            csaveOp: 'saveAndWait',
                            triggeredFromForcedSave
                        }
                    })
                )
            }
            const lastCSave = getLastCsaveSnapshot(snapshots)
            if (snapshot === lastCSave) {
                if (experimentInstance.isOpen('dm_waitForPendingCsaveIfNothingToSaveOnSaveAndWait')) {
                    return await notifier.waitForCurrentPending()
                }
                return
            }
            if (experimentInstance.isOpen('dm_addDummyTxToSaveAndWait')) {
                //adding a meaningless non empty transaction so there will be definitely a csave sent to server.
                //we need it because we want to be sure that when this dummy tx will be approved the csaves that were sent beforehand are already approved/rejected by the server
                createDummyTx()
                snapshot = snapshots.getLastSnapshot()
            }
            const promise = notifier.register(snapshot.id, {
                extras: {
                    cedit: this.config.cedit,
                    isDuplexerDisconnected: !duplexer?.connected
                }
            })
            try {
                // if saving, will wait on promise until next save
                await saveFromLastCSaveSnapshot(snapshot)
                // it's possible using stack here is not optimal
                // however, I dont even think we have an end for every start (in case of duplexer approval)
                // so for now, we will use stack - and if we have close to 100% success, then we can discuss optimizing actual duration
                logger.interactionStarted('saveAndWaitWaitingForResponse', {stack: true})
                // in case of success the promise is resolved by the duplexer approval
            } catch (e) {
                logger.captureError(makeReportable(e), {tags: {csaveOp: 'saveAndWait', triggeredFromForcedSave}})
                log.error('Error saving from last csave snapshot', e as Error)
                notifier.reject(snapshot.id, e)
            }

            return promise
        }

        const saveAndWaitForResult = async (): Promise<void> => {
            const triggeredFromForcedSave = false
            if (shouldSave()) {
                await saveAndWait(triggeredFromForcedSave)
            }
        }

        const forceSaveAndWaitForResult = async (): Promise<void> => {
            const triggeredFromForcedSave = true
            if (isSavePermitted()) {
                logger.interactionStarted('forceSaveAndWaitForResult', {stack: true})
                await saveAndWait(triggeredFromForcedSave)
                logger.interactionEnded('saveAndWaitWaitingForResponse')
                logger.interactionEnded('forceSaveAndWaitForResult')
            }
        }

        const createRevision = async (
            args: CreateRevArgs,
            updateSiteDto: UpdateSiteDTO
        ): Promise<CreateRevisionRes> => {
            const recovery = this.validationRecovery
            const editorSessionId = dsSiteApi.getEditorSessionId()

            const req: CreateRevisionReq = {
                dsOrigin: args.dsOrigin,
                initiatorOrigin: args.initiatorOrigin,
                editorVersion: args.editorVersion,
                initiator: args.initiator,
                viewerName: args.viewerName,
                editorSessionId,
                // @ts-expect-error
                metaSiteActions: updateSiteDto.metaSiteActions,
                branchId: updateSiteDto.branchId,
                siteName: updateSiteDto.metaSiteData?.siteName,
                closedWixCodeAppId: updateSiteDto?.wixCodeAppData?.codeAppId,
                suppressUnappliedTransactions: recovery,
                lastTransactionId: recovery ? undefined : server.getLast()
            }
            const res = await retryServerSaveTask('createRevision', () => server.createRevision(req), {
                getReasonForRetry: (e: any) => {
                    if (_.get(e, ['details', 'applicationError', 'code']) === saveErrors.CLONE_GRID_APP_FAILED) {
                        return saveErrors.CLONE_GRID_APP_FAILED
                    }
                }
            })
            server.setLastSaveDate(new Date())
            log.info('createRevision called, result: ', res)
            dsSiteApi.setSiteRevision(res.siteRevision.revision)
            dsSiteApi.setSiteVersion(res.siteRevision.version)
            return res
        }

        const mergeActions = (actionsToApply: Action[]): void => {
            const changes = actionsToDalChanges(actionsToApply)
            const autosaveInfoPointer = pointers.general.getAutosaveInfo()
            const autoSaveInfo = _.cloneDeep(dal.get(autosaveInfoPointer)) ?? {}
            autoSaveInfo.changesApplied = true
            changes.set(autosaveInfoPointer, autoSaveInfo)
            dal.mergeToApprovedStore(changes, 'get-transactions')
        }

        const validate = (actionsToApply: Action[]) => {
            try {
                const store: DmStore = actionsToDalChanges(actionsToApply)
                const transactionStore = store.asJson()
                logger.interactionStarted(CSAVE_VALIDATION_INTERACTION)
                validateCSaveTransaction(
                    dal,
                    dal._getApprovedStoreAsJson(),
                    transactionStore,
                    (extensionAPI as SchemaExtensionAPI).schemaAPI,
                    logger,
                    experimentInstance
                )
                logger.interactionEnded(CSAVE_VALIDATION_INTERACTION)
            } catch (e) {
                logger.captureError(e as Error, tagsFromError(e as Error))
                return false
            }
            return true
        }

        const initChannel = async () => {
            if (!duplexer) {
                await reconnectToDuplexerAndSubscribeToChannel()
            }
        }

        const hasTransactionsSinceRevision = (transactionResult?: GetDocumentResponse): boolean =>
            !!transactionResult?.lastTransactionId

        const migrateActions = (actions: Action[]): Action[] => {
            let shouldReportBI = false

            for (const action of actions) {
                const id = getIdFromAction(action)
                shouldReportBI = shouldReportBI || action.id !== id
                action.id = id
            }

            if (shouldReportBI) {
                logger.interactionStarted('OldMLKeysInTransaction', {
                    extras: {
                        message:
                            'Transaction contains old ML keys of the form pageId^langCode^dataItem-id instead of langCode^dataItem-id'
                    }
                })
            }

            return actions
        }

        const initCSaveInternal = async (partialPages: string[]) => {
            const lastTransactionId = dsSiteApi.getAutosaveInfo()?.lastTransactionId ?? '0'
            server.setLast(lastTransactionId)
            log.info(`lastTransactionId=${lastTransactionId}`)
            const branchId = dsSiteApi.getBranchId()
            const transactionResult = await server.getStore(
                branchId,
                lastTransactionId,
                this.config.untilTransactionId,
                partialPages
            )
            const partialPagesSet = new Set(partialPages)
            transactionResult.actions =
                partialPages.length > 0
                    ? transactionResult?.actions?.filter(
                          action =>
                              !action?.value?.metaData?.pageId || partialPagesSet.has(action?.value?.metaData?.pageId)
                      )
                    : transactionResult.actions
            if (!hasTransactionsSinceRevision(transactionResult)) {
                return false
            }
            server.setLast(transactionResult.lastTransactionId!)
            server.setLastSaveDate(transactionResult.lastTransactionDateCreated!)
            initUnappliedTransactions(transactionResult)
            const actionsToApply = migrateActions(removeFromLoadStore(transactionResult.actions!))
            if (!this.config.disableCSaveValidationOnInitialization) {
                const valid = validate(actionsToApply)
                if (!valid || this.config.autosaveRestore === 'false') {
                    this.validationRecovery = true
                    return false
                }
            }
            setUnappliedActionCountSinceLastRevision(transactionResult.originalActionsCount)
            snapshots.takeSnapshot(SNAPSHOTS.BEFORE_AUTOSAVE_APPLY)
            snapshots.takeSnapshot(SNAPSHOTS.MOBILE_MERGE)
            mergeActions(actionsToApply)
            dsSiteApi.setActionsCount(actionCountToAutosaveActionCount(actionsToApply.length))
            return true
        }

        const initCSave = async (partialPages: string[] = []) => {
            if (!isHeadless() && isNewFromTemplate()) {
                log.info('template site skipping initCSave')
                return false
            }
            this.server.setInstanceProvider(() => siteAPI.getInstance())
            const result = await initCSaveInternal(partialPages)
            snapshots.takeSnapshot(CSAVE_TAG)
            await initChannel()
            this.isInitialized = true
            return result
        }

        eventEmitter.addListener(CS_EVENTS.CSAVE.SITE_SAVED, async () => {
            if (!this.isInitialized) {
                log.info('FIRST SAVE - initCSave')
                await initCSave()
            }
            this.validationRecovery = false
        })

        eventEmitter.on(CS_EVENTS.SAVE.FINISHED_SAVING, async () => {
            if (experimentInstance.isOpen('dm_fixCsaveAfterFinishedSaving')) {
                if (this.pendingIndex > -1) {
                    const toPendingSnapshot = snapshots.getSnapshotByTagAndIndex(CSAVE_PENDING_TAG, this.pendingIndex)
                    this.pendingIndex = -1
                    await saveFromLastCSaveSnapshot(toPendingSnapshot)
                }
            } else {
                handlePendingSnapshots()
            }
        })

        const rejectNext = () => {
            duplexer!.rejectNext()
        }

        const testApi = new CSaveTestApi(this)
        const test = () => testApi

        const isBrokenRefAddedError = (error: DocumentServiceErrorInfo) => {
            const {errorDescription} = error
            return !!errorDescription && errorDescription.includes('transaction adds a broken ref')
        }

        const getConfigExtras = () => _.pick(this.config, ['acceptTransactionsFromDuplexer', 'cedit', 'createRevision'])

        const wrappedHookSymbol = Symbol('wrappedHook')

        const getWrappedHook = (hook: Function, hookName: string) => {
            if (hook[wrappedHookSymbol]) return hook

            const wrappedHook = (...hookArgs: any[]) => {
                if (hookName === 'onDiffSaveFinished' && hookArgs.length) {
                    const error = hookArgs[0]?.document ?? hookArgs[0]?.documentServices
                    logger.captureError(
                        new ReportableError({
                            errorType: _.get(error, ['errorType'], 'unknownSaveError'),
                            message: 'save diff was called with error',
                            tags: {saveFailure: 'onDiffSaveFinished'},
                            extras: {
                                originalError: JSON.stringify(error),
                                cSaveError: isBrokenRefAddedError(error),
                                ...getConfigExtras()
                            }
                        })
                    )
                }
                try {
                    hook(...hookArgs)
                } catch (e: any) {
                    logger.captureError(e, {tags: {csaveHook: true}, extras: {hookName}})
                }
            }

            wrappedHook[wrappedHookSymbol] = true

            return wrappedHook
        }

        const getWrappedHooks = (saveHooks: CSaveHooks) => {
            const callbacks = {}

            _.forEach(saveHooks, (val, key) => {
                if (_.isFunction(val)) {
                    callbacks[key] = getWrappedHook(val, key)
                }
            })

            return callbacks
        }

        const getActionCount = () => {
            const snap = snapshots.getCurrentSnapshot()
            if (!snap) {
                return 0
            }
            const lastCsaveSnap = getLastCsaveSnapshot(snapshots)
            const chain = createSnapshotChain(lastCsaveSnap, snap, 'getActionCount')
            return _.sum(chain.map(s => s.getStore().size()))
        }

        const getLastSaveDate = async (): Promise<Date | undefined> => {
            const lastSaveDate = this.server.getLastSaveDate()
            if (lastSaveDate) {
                return lastSaveDate
            }

            const lastTransactionId = this.server.getLast()
            if (lastTransactionId) {
                const tx = await this.server.getTransaction(lastTransactionId, dsSiteApi.getBranchId())
                if (tx.transaction) {
                    return tx.transaction.dateCreated
                }
            }
        }

        return {
            continuousSave: {
                createRevision,
                save,
                saveAndWaitForResult,
                forceSaveAndWaitForResult,
                initCSave,
                deleteTx: async () => {
                    const t = await this.server.deleteTransactions()
                    log.info('deleteTransaction', t)
                },
                setSaving,
                disableSaveDuringRequiredAndPrimary,
                getLastTransactionId: () => this.server.getLast(),
                getLastSaveDate,
                initHooks: (saveHooks: CSaveHooks) => {
                    const wrappedHooks = getWrappedHooks(saveHooks)
                    _.forEach(wrappedHooks, (val, key) => {
                        this.hooks[key] = val
                    })
                },
                getWrappedHooks,
                setEnabled,
                shouldSave,
                /**
                 * for testing purposes, rejects the next transaction
                 */
                rejectNext,
                /**
                 * for rendererModel#clientSpecMap reloader, don't use for other purposes
                 */
                registerToTransactionApproved: (cb: (txStore: DmStore) => Promise<void>) => {
                    if (_.isFunction(transactionApprovedCb)) {
                        throw new Error('registerToTransactionApproved is not allowed twice')
                    }
                    transactionApprovedCb = cb
                },
                /**
                 * debug method, prints store from a transaction id
                 * @param branch
                 * @param afterTransactionId
                 * @param untilTransactionId
                 * @returns {Promise<void>}
                 */
                getStore: async (branch?: string, afterTransactionId?: string, untilTransactionId?: string) =>
                    await this.server.getStore(branch, afterTransactionId, untilTransactionId),
                //@ts-ignore
                getTransactions: async (afterTransactionId?: string, untilTransactionId?: string, branchId?: string) =>
                    await this.server.getTransactions(afterTransactionId, untilTransactionId, branchId),
                getTransactionsFromLastRevision: async (untilTransactionId?: string, branchId?: string) => {
                    const revisionTransactionId = dal.get(
                        pointers.autoSave.getAutoSaveInnerPointer('lastTransactionId')
                    )
                    return await this.server.getTransactions(revisionTransactionId, untilTransactionId, branchId)
                },
                approveForeignTransaction: async (t: GetTransactionRes) => {
                    if (this.server instanceof MockCEditTestServer) {
                        await this.server.approveForeignTransaction(t)
                    }
                },
                test,
                isCSaveOpen: () => true,
                isCEditOpen: () => !!this.config.cedit,
                isCreateRevisionOpen,
                waitForResponsesToBeProcessed: async () => {
                    await queue.toBeEmpty()
                },
                isValidationRecovery: () => this.validationRecovery,
                onRevisionChange,
                getActionCount,
                getUnappliedActionCountSinceLastRevision,
                isUnappliedTransactionThresholdPassed,
                cSaveErrors: {
                    convertToDSError
                },
                isLocalTransaction
            }
        }
    }

    async initialize({extensionAPI, dal, pointers}: DmApis): Promise<void> {
        const {snapshots} = extensionAPI as SnapshotExtApi
        const {schemaAPI} = extensionAPI as SchemaExtensionAPI
        const {siteAPI: dsSiteApi} = extensionAPI as DocumentServicesModelExtApi
        const {siteAPI: rmSiteApi} = extensionAPI as RMApi
        this.server.setInstanceProvider(() => rmSiteApi.getInstance())
        const {continuousSave} = extensionAPI as CSaveApi
        if (this.validationRecovery && continuousSave.isCreateRevisionOpen()) {
            const res = await continuousSave.createRevision(
                {
                    dsOrigin: this.config.origin,
                    initiator: 'validationRecovery',
                    initiatorOrigin: '',
                    editorVersion: dsSiteApi.getSiteVersion().toString(),
                    viewerName: ''
                },
                {} as UpdateSiteDTO
            )
            dal.set(pointers.general.getIsDraft(), false)
            const actionsWithClientStyleNamespaces = converter.convertActionsNamespacesFromServerStyle(
                schemaAPI,
                res.actions
            )
            rebase(dal, snapshots, actionsWithClientStyleNamespaces, `revision-${res.siteRevision.revision}`)
            this.validationRecovery = false
        }
    }
}

const createExtension = ({dsConfig, environmentContext, experimentInstance}: CreateExtensionArgument): Extension => {
    if (dsConfig.continuousSave) {
        if (!environmentContext?.serverFacade) {
            throw new Error('Illegal attempt to register CSave without providing server facade implementation')
        }
        log = debug('csave', environmentContext.loggerDriver)
        const maxTransactionsModifier = environmentContext.csaveMaxTransactionsModifier ?? _.random(1, 50)
        return new CSaveExtension(
            environmentContext.serverFacade,
            dsConfig,
            maxTransactionsModifier,
            !experimentInstance.isOpen('dm_noRecovery2')
        )
    }
    return new EmptyCSaveExt()
}

export {createExtension, CSAVE_TAG, MAX_TRANSACTIONS_BASE}
