import _ from 'lodash'
import {DocumentManager, pointerUtils, DalItem} from '@wix/document-manager-core'
import type {PageMigrator} from '../dataMigrationRunner'
import type {PageExtensionAPI, PageAPI} from '../../../page'
import {isReferredId} from '../../../../utils/inflationUtils'
import type {ResolvedReference} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'
import {stripHashIfExists} from '../../../../utils/refArrayUtils'

const {getPointer} = pointerUtils

interface FixerContext {
    isPartiallyLoaded: boolean
}
interface FixerConfig {
    context: FixerContext
    namespacesToCheck: string[]
}

const createFixerConfig = (documentManager: DocumentManager): FixerConfig => {
    const {extensionAPI} = documentManager
    const {page} = extensionAPI as PageExtensionAPI
    return {
        context: {isPartiallyLoaded: page.isPartiallyLoaded()},
        namespacesToCheck: [
            'data',
            'MOBILE',
            'design',
            'style',
            'multilingualTranslations',
            'presets',
            'DESKTOP',
            'effects',
            'variables'
        ]
    }
}

interface FixOptions {
    allowEmpty?: boolean
}

interface SchemaOptions {
    ignoreAlways?: boolean
    ignoreWhenPartiallyLoaded?: boolean
    paths?: Record<string, FixOptions>
}

const SCHEMA_OPTIONS: Record<string, Record<string, SchemaOptions>> = {
    data: {
        StyledText: {ignoreAlways: true},
        InternalRef: {ignoreAlways: true},
        Page: {ignoreWhenPartiallyLoaded: true}
    },
    design: {
        BackgroundMedia: {
            paths: {
                imageOverlay: {allowEmpty: true}
            }
        }
    }
}

const resolveSchemaOptions = (namespace: string, schema: string) => {
    return SCHEMA_OPTIONS[namespace]?.[schema] ?? {}
}

const shouldFixItem = (dataItem: DalItem, namespace: string, pointerId: string, context: FixerContext) => {
    if (isReferredId(pointerId)) {
        return false
    }

    const schemaOptions = resolveSchemaOptions(namespace, dataItem.type!)
    if (schemaOptions.ignoreAlways || (schemaOptions.ignoreWhenPartiallyLoaded && context.isPartiallyLoaded)) {
        return false
    }

    return true
}

const resolveFixOptions = (namespace: string, schema: string, path: readonly string[]) => {
    return resolveSchemaOptions(namespace, schema).paths?.[path.join(',')] ?? {}
}

const shouldFixPath = (dataItem: DalItem, namespace: string, path: readonly string[]) => {
    const fixOptions = resolveFixOptions(namespace, dataItem.type!, path)
    if (fixOptions.allowEmpty && _.get(dataItem, path) === '') {
        return false
    }

    return true
}

const reportRemoval = (
    documentManager: DocumentManager,
    namespace: string,
    pointerId: string,
    schema: string,
    references: ResolvedReference[]
) => {
    documentManager.logger.interactionStarted('referenceRemoved', {
        extras: {
            namespace,
            id: pointerId,
            schema,
            refs: references.map(({id}) => id),
            paths: references.map(({refInfo}) => refInfo.path)
        }
    })
}

const reportRemovalError = (
    documentManager: DocumentManager,
    namespace: string,
    pointerId: string,
    schema: string,
    references: ResolvedReference[],
    error: any
) => {
    documentManager.logger.captureError(
        new ReportableError({
            message: 'Unable to remove broken ref',
            errorType: 'requiredReferenceRemoval',
            extras: {
                namespace,
                id: pointerId,
                schema,
                refs: references.map(({id}) => id),
                paths: references.map(({refInfo}) => refInfo.path),
                originalError: JSON.stringify(error)
            }
        })
    )
}

const removeReferences = (
    documentManager: DocumentManager,
    namespace: string,
    pointerId: string,
    dataItem: DalItem,
    brokenReferences: ResolvedReference[]
) => {
    const {dal} = documentManager
    const updatedItem = _.cloneDeep(dataItem)

    brokenReferences.forEach(resolvedReference => {
        const {id, refInfo} = resolvedReference
        if (refInfo.isList) {
            _.remove(_.get(updatedItem, refInfo.path), (ref: string) => stripHashIfExists(ref) === id)
        } else {
            _.unset(updatedItem, refInfo.path)
        }
    })

    try {
        dal.schema.validate(updatedItem.type!, updatedItem, namespace)
        dal.set(getPointer(pointerId, namespace), updatedItem)
        reportRemoval(documentManager, namespace, pointerId, updatedItem.type!, brokenReferences)
    } catch (error: any) {
        reportRemovalError(documentManager, namespace, pointerId, updatedItem.type!, brokenReferences, error)
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    if (!documentManager.experimentInstance.isOpen('dm_brokenReferenceFixer')) {
        return
    }

    const {dal, extensionAPI} = documentManager
    const {namespacesToCheck, context} = createFixerConfig(documentManager)
    for (const namespace of namespacesToCheck) {
        const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
        const dataItemsToCheck: Record<string, DalItem> = dal.query(namespace, pageCompFilter)
        for (const [pointerId, dataItem] of Object.entries(dataItemsToCheck)) {
            if (!shouldFixItem(dataItem, namespace, pointerId, context)) {
                continue
            }

            const brokenReferences = dal.schema
                .getReferences(namespace, dataItem)
                .filter(
                    ({id, refInfo, referencedMap}: ResolvedReference) =>
                        refInfo.shouldValidate &&
                        !dal.has(getPointer(id, referencedMap)) &&
                        shouldFixPath(dataItem, namespace, refInfo.path)
                )

            if (brokenReferences.length > 0) {
                removeReferences(documentManager, namespace, pointerId, dataItem, brokenReferences)
            }
        }
    }
}

const name = 'brokenReferenceFixer'
const version = 0
const experimentalVersions = [{experiment: 'dm_brokenReferenceFixer', version: 1}]

export const brokenReferenceFixer: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions,
    fixerRequiresReruns: true
}
