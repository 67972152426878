import {createImmutableProxy, deepClone} from '@wix/wix-immutable-proxy'
import type {ConversionDal, ValueTransformer} from '../types'
import type {Pointer} from '@wix/document-services-types'
import {
    createStore,
    createStoreFromJS,
    createList,
    createTagManager,
    type DalJsStore,
    deepCompare,
    type DmStore,
    type SnapshotDal
} from '@wix/document-manager-core'

export const createConversionDal = (initialStore: DalJsStore): ConversionDal => {
    const store = createStore()
    const snapshots = createList()
    const tagManager = createTagManager()
    let openSnapshotStore = createStore()

    const getFromStores = (stores: DmStore[], pointer: Pointer): any => {
        const foundStore = stores.find((s: DmStore) => s.has(pointer))
        return foundStore ? foundStore.get(pointer) : undefined
    }

    const isChanged = (pointer: Pointer, value: any) => {
        const currentValue = getFromStores([openSnapshotStore, store], pointer)

        return !deepCompare(currentValue, value)
    }

    const _set = (pointer: Pointer, value: any): void => {
        openSnapshotStore.set(pointer, deepClone(value))
    }

    const setIfChanged = (pointer: Pointer, value: any): void => {
        if (isChanged(pointer, value)) {
            _set(pointer, value)
        }
    }

    const set = (pointer: Pointer, value: any): void => {
        setIfChanged(pointer, value)
    }

    const getRawValue = (pointer: Pointer): any => {
        return getFromStores([openSnapshotStore, store], pointer)
    }

    const get = (pointer: Pointer): void => {
        return createImmutableProxy(getRawValue(pointer))
    }

    const modify = (pointer: Pointer, transformer: ValueTransformer): void => {
        return set(pointer, transformer(get(pointer)))
    }

    const commitChanges = (tag: string): SnapshotDal => {
        if (openSnapshotStore.isEmpty()) {
            return snapshots.last()!
        }

        store.merge(openSnapshotStore)
        const snapshot = snapshots.add(openSnapshotStore, snapshots.generateId(tag))
        tagManager.addSnapshot(tag, snapshot)
        openSnapshotStore = createStore()

        return snapshot
    }

    const mergeToStore = (changes: DmStore, label: string) => {
        const clonedChanges = changes.clone()
        const snapshotId = snapshots.generateId(label)

        store.merge(clonedChanges)
        snapshots.add(clonedChanges, snapshotId)
    }

    const getLastSnapshot = () => snapshots.last()

    if (initialStore) {
        mergeToStore(createStoreFromJS(initialStore), 'initial store')
    }

    return {
        set,
        modify,
        get,
        commitChanges,
        getLastSnapshot
    }
}
