import _ from 'lodash'
import {getComponentsEditorParams} from './componentsEditorParams'

const SVG_SKIN_DEFINITION = 'skins.viewer.svgshape.SvgShapeDefaultSkin'
export const systemStyles = {
    'skins.core.InlineSkin': {
        properties: {
            'alpha-bg': '0',
            bg: 'color_11'
        },
        propertiesSource: {
            'alpha-bg': 'value',
            bg: 'theme'
        }
    }
}

const getSkinDefinitionInternal = (skinClassName: string) => {
    const componentsEditorParams = getComponentsEditorParams()
    return skinClassName?.includes('svgshape.')
        ? componentsEditorParams[SVG_SKIN_DEFINITION]
        : componentsEditorParams[skinClassName]
}
export const getSkinDefinition = (skinClassName: string) => {
    return _.clone(getSkinDefinitionInternal(skinClassName))
}
