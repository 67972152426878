import type {BlocksEntity, Callback, Pointer, PS, CompRef, WidgetPluginDescriptor} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import componentStructureInfo from '../component/componentStructureInfo'
import dataIds from '../dataModel/dataIds'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import pageData from '../page/pageData'
import constants from '../platform/common/constants'
import mlUtils from '../utils/multilingual'
import utils from '../utils/utils'
import getAppStudioData, {getShallowAppStudioData} from './getAppStudioData'
import {getDataPointerById, getDeepDataById, getDeepData, getShallowDataById} from './blocksDataModel'
import experiment from 'experiment-amd'

let WIDGETS_CACHE = {}
let VARIATIONS_CACHE = {}

const INTERNAL_REF_TYPES = new Set(['InternalRef', 'InternalBlocksRef'])

function cleanCache() {
    WIDGETS_CACHE = {}
    VARIATIONS_CACHE = {}
}

const {DATA_TYPES} = santaCoreUtils.constants

const getDescriptorPointerById = (ps: PS, widgetId: string) => ps.pointers.data.getDataItemFromMaster(widgetId)

const getDefinitionPointerByDefinitionId = (ps: PS, definitionId: string) =>
    ps.pointers.data.getDataItemFromMaster(definitionId)

function getNewDataId() {
    return dataIds.generateNewId(DATA_TYPES.data)
}

const getData = (ps: PS, pointer: Pointer) => {
    const nonTranslatablePointer = mlUtils.getNonTranslatablePointer(ps, pointer)
    return ps.dal.get(nonTranslatablePointer)
}

function getNewDataItemPointer(ps: PS) {
    const dataId = getNewDataId()!
    return ps.pointers.data.getDataItemFromMaster(dataId)
}

function serializeWidget(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    const data: any = _.pick(widgetData, [
        'name',
        'widgetApi',
        'rootCompId',
        'devCenterWidgetId',
        'defaultSize',
        'plugin',
        'kind'
    ])

    data.panels = _(widgetData.panels || [])
        .map(panelId => utils.stripHashIfExists(panelId))
        .map(panelId => {
            const panelData = dataModel.getDataItemById(ps, panelId)

            if (!panelData) {
                throw new Error('Invalid data. Panel was not found in dataModel')
            }
            return _.pick(panelData, ['rootCompId', 'height', 'helpId', 'kind', 'name', 'pageUrl', 'title'])
        })
        .keyBy(({rootCompId}) => utils.stripHashIfExists(rootCompId))
        .value()

    data.variations = _.map(widgetData.variations, variationId => {
        const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(variationId))
        const variationData = ps.dal.get(pointer)
        return _.pick(variationData, ['rootCompId', 'name'])
    })
    data.presets = _.map(widgetData.presets, presetId => {
        const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(presetId))
        const presetData = getData(ps, pointer)
        return _.pick(presetData, ['presetId', 'name', 'defaultSize'])
    })
    return data
}

function getWidgetPanelsPointers(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    return _.map(widgetData.panels || [], panelId =>
        ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(panelId))
    )
}

function getWidgetPanelsData(ps: PS, widgetPointer: Pointer): any[] {
    const widgetData = getData(ps, widgetPointer)
    return widgetData.panels.map((panelId: string) => dataModel.getDataItemById(ps, utils.stripHashIfExists(panelId)))
}

function getAllPanelIds(ps: PS): string[] {
    const allWidgets = getAllWidgetIds(ps)

    return _.flatMap(allWidgets, widgetId => getShallowDataById(ps, widgetId).panels ?? [])
}

function getPanelInfo(ps: PS, panelId: string) {
    const pointer = getDataPointerById(ps, panelId)
    const panelData = getDeepData(ps, pointer)

    return {
        pointer,
        ...panelData
    }
}

function getAllPanels(ps: PS) {
    if (experiment.isOpen('dm_optimizeAppStudioModel')) {
        return getAllPanelIds(ps).map(panelId => getPanelInfo(ps, panelId))
    }

    const appStudioData = getAppStudioData(ps) || {}
    return _(appStudioData.widgets)
        .map(widget => widget.panels)
        .flatten()
        .map(panel => ({
            pointer: panel && ps.pointers.data.getDataItemFromMaster(panel.id),
            ...panel
        }))
        .value()
}

function getAllSerializedWidgets(ps: PS) {
    return _.map(getAllWidgets(ps), widget => serializeWidget(ps, widget.pointer))
}

export interface WidgetInfo {
    pointer: Pointer
    name: string
    panels?: any
    variations?: any
    plugin?: WidgetPluginDescriptor
}

function getAllWidgetIds(ps: PS): string[] {
    const appStudioData = getShallowAppStudioData(ps)
    return appStudioData?.widgets ?? []
}

function getWidgetInfo(ps: PS, widgetId: string): WidgetInfo {
    const widgetPointer = getDataPointerById(ps, widgetId)
    const widgetDataItem = getData(ps, widgetPointer)

    return {
        pointer: widgetPointer,
        name: widgetDataItem.name,
        panels: widgetDataItem.panels?.map((dataId: string) => getDeepDataById(ps, dataId)),
        variations: widgetDataItem.variations?.map((dataId: string) => getDeepDataById(ps, dataId)),
        plugin: widgetDataItem.plugin
    }
}

function getAllWidgetPointers(ps: PS): Pointer[] {
    const allWidgetIds = getAllWidgetIds(ps)

    return allWidgetIds.map(widgetId => getDataPointerById(ps, widgetId))
}

function getWidgetsCount(ps: PS): number {
    return getAllWidgetIds(ps).length
}

function getAllWidgets(ps: PS): WidgetInfo[] {
    if (experiment.isOpen('dm_optimizeAppStudioModel')) {
        return getAllWidgetIds(ps).map(widgetId => getWidgetInfo(ps, widgetId))
    }

    const appStudioData = getAppStudioData(ps) || {}

    return _.map(appStudioData.widgets, function (widget) {
        const widgetPointer = getDescriptorPointerById(ps, widget.id)
        const widgetDataItem = getData(ps, widgetPointer)

        return {
            pointer: widgetPointer,
            name: widgetDataItem.name,
            panels: widgetDataItem.panels,
            variations: widgetDataItem.variations,
            plugin: widgetDataItem.plugin
        }
    })
}

function findWidgetByPageId(ps: PS, pageId: string): any {
    if (experiment.isOpen('dm_optimizeAppStudioModel')) {
        const allWidgetIds = getAllWidgetIds(ps)

        const widgetId: string | undefined = allWidgetIds.find(id => {
            const widgetData = getShallowDataById(ps, id)
            return widgetData && widgetData.rootCompId === `#${pageId}`
        })

        if (widgetId) {
            return getWidgetInfo(ps, widgetId)
        }

        return _.get(findVariationByPageId(ps, pageId), 'widget')
    }

    const allWidgets = getAllWidgets(ps)

    const widget = _.find(allWidgets, currentWidget => {
        const widgetData = getData(ps, currentWidget.pointer)
        return widgetData && widgetData.rootCompId === `#${pageId}`
    })

    return widget ?? _.get(findVariationByPageId(ps, pageId), 'widget')
}

function findPanelByPageId(ps: PS, pageId: string) {
    if (experiment.isOpen('dm_optimizeAppStudioModel')) {
        const allPanelIds = getAllPanelIds(ps)

        const panelId: string | undefined = allPanelIds.find(id => {
            const panelData = getShallowDataById(ps, id)
            return panelData && panelData.rootCompId === `#${pageId}`
        })

        if (panelId) {
            return getPanelInfo(ps, pageId)
        }
    }

    const allPanels = getAllPanels(ps)
    const result = _.find(allPanels, panel => {
        const panelData = getData(ps, panel.pointer)
        return panelData && panelData.rootCompId === `#${pageId}`
    })
    return result
}

function isWidgetPage(ps: PS, pageId: string) {
    if (!pageData.doesPageExist(ps, pageId)) {
        return false
    }

    if (_.isUndefined(WIDGETS_CACHE[pageId])) {
        WIDGETS_CACHE[pageId] = Boolean(findWidgetByPageId(ps, pageId))
    }
    return WIDGETS_CACHE[pageId] || isVariationPage(ps, pageId)
}

function isPanelPage(ps: PS, pageId: string) {
    if (!pageData.doesPageExist(ps, pageId)) {
        return false
    }
    return Boolean(findPanelByPageId(ps, pageId))
}

function isDashboardPage(ps: PS, pageRef: Pointer) {
    return ps.extensionAPI.blocks.isDashboardPage(pageRef)
}

function isDefaultPage(ps: PS, pageRef: Pointer) {
    return ps.extensionAPI.blocks.isDefaultPage(pageRef)
}

function getEntityByPage(ps: PS, pageRef: Pointer): BlocksEntity {
    return ps.extensionAPI.blocks.getEntityByPage(pageRef)
}

function isBlocksComponentPage(ps: PS, pageRef: Pointer) {
    return isWidgetPage(ps, pageRef.id) || isPanelPage(ps, pageRef.id) || isDashboardPage(ps, pageRef)
}

function findVariationByPageId(ps: PS, pageId: string) {
    const allWidgets = getAllWidgets(ps)
    let variationId
    const widget = _.find(allWidgets, currentWidget => {
        variationId = _.find(currentWidget.variations, currentVariationId => {
            const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(currentVariationId))
            const variationData = ps.dal.get(pointer)
            return _.get(variationData, 'rootCompId') === `#${pageId}`
        })
        return variationId
    })
    return {
        variationId,
        widget
    }
}

function isVariationPage(ps: PS, pageId: string) {
    if (!pageData.doesPageExist(ps, pageId)) {
        return false
    }

    if (_.isUndefined(VARIATIONS_CACHE[pageId])) {
        VARIATIONS_CACHE[pageId] = Boolean(_.get(findVariationByPageId(ps, pageId), 'variationId'))
    }

    return VARIATIONS_CACHE[pageId]
}

function getAllSerializedCustomDefinitions(ps: PS) {
    const appStudioData = getAppStudioData(ps) || {}

    return _.map(appStudioData.customDefinitions, 'structure')
}

function getAllCustomDefinitions(ps: PS) {
    const appStudioData = getAppStudioData(ps) || {}

    return _.map(appStudioData.customDefinitions, function (definition) {
        const pointer = getDefinitionPointerByDefinitionId(ps, definition.id)
        const definitionData = definition.structure
        const definitionName = _.head(_.keys(definitionData))
        const definitionTitle = definitionData[definitionName].title
        return {
            pointer,
            name: definitionName,
            title: definitionTitle,
            type: definitionData[definitionName].$ref || definitionData[definitionName].type
        }
    })
}

function getSerializedCustomDefinition(ps: PS, pointer: Pointer) {
    return _.get(ps.dal.get(pointer), 'structure')
}

function getAppStudioMetaData(ps: PS) {
    const appStudioData = experiment.isOpen('dm_optimizeAppStudioModel')
        ? getShallowAppStudioData(ps)
        : getAppStudioData(ps)
    return appStudioData ? _.pick(appStudioData, ['name', 'description']) : undefined
}

function getWidgetDevCenterId(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    return widgetData.devCenterWidgetId
}

function setWidgetDevCenterId(ps: PS, widgetPointer: Pointer, devCenterWidgetId: string) {
    const widgetData = getData(ps, widgetPointer)
    widgetData.devCenterWidgetId = devCenterWidgetId
    setWidgetData(ps, widgetPointer, widgetData)
}

const findRootAppWidgetComponent = (ps: PS, compRef: CompRef): CompRef => {
    const [firstChild] = componentStructureInfo.getChildren(ps, compRef)
    if (!firstChild || isAppWidgetComponent(ps, firstChild)) {
        return firstChild
    }
    return findRootAppWidgetComponent(ps, firstChild)
}

function getDefaultSize(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    return widgetData.defaultSize
}

function setDefaultSize(ps: PS, widgetPointer: Pointer, size, callback?: Callback) {
    const widgetData = getData(ps, widgetPointer)
    widgetData.defaultSize = size
    setWidgetData(ps, widgetPointer, widgetData)
    if (callback) {
        callback()
    }
}

const getRootAppWidgetByPage = (ps: PS, pagePointer: CompRef) => {
    if (!isBlocksComponentPage(ps, pagePointer)) {
        return
    }
    return findRootAppWidgetComponent(ps, pagePointer)
}

function updateAppStudioMetaData(ps: PS, appStudioData) {
    const currentAppStudioData = getAppStudioData(ps)

    if (_.isUndefined(currentAppStudioData)) {
        throw new Error('appStudio: there is no app studio to update')
    }

    const newAppStudioData = _.assign({}, currentAppStudioData, appStudioData)
    updateAppStudioOnMasterPage(ps, newAppStudioData)
}

function updateAppStudioOnMasterPage(ps: PS, appStudioData) {
    const masterPagePointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
    const {id, type} = getData(ps, masterPagePointer)
    const masterPageData = {id, type, appStudioData}
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, true)
    dataModel.addSerializedDataItemToPage(
        ps,
        santaCoreUtils.siteConstants.MASTER_PAGE_ID,
        masterPageData,
        santaCoreUtils.siteConstants.MASTER_PAGE_ID,
        useLanguage
    )
}

/**
 * Get a map of all widgets each pointing from widgetPointerId to an array of contained widgets' widgetPointerIds
 */
function getContainingWidgetsMap(ps: PS) {
    const widgets = experiment.isOpen('dm_optimizeAppStudioModel')
        ? getAllWidgetPointers(ps)
        : _.map(getAllWidgets(ps), 'pointer')
    const widgetsMap = {}
    _.forEach(widgets, widgetPointer => {
        if (!widgetsMap[widgetPointer.id]) {
            widgetsMap[widgetPointer.id] = getContainedWidgets(ps, widgetPointer, widgetsMap)
        }
    })
    return widgetsMap
}

/**
 * Get all widgets contained in widget (widgetPointer)
 * @param ps
 * @param widgetPointer - containing widget
 * @param widgetsMap - a map of all widgets, each pointing from a widgetPointerId to an array of contained widgets - each represented by widgetPointerId
 */
function getContainedWidgets(ps: PS, widgetPointer: Pointer, widgetsMap) {
    const refChildren = getFirstLevelRefChildren(ps, widgetPointer)
    let containedWidgets: string[] = []

    _.forEach(refChildren, ref => {
        const widgetChildPointer = getWidgetPointerByRefComp(ps, ref)
        if (widgetChildPointer) {
            if (!widgetsMap[widgetChildPointer.id]) {
                widgetsMap[widgetChildPointer.id] = getContainedWidgets(ps, widgetChildPointer, widgetsMap)
            }
            containedWidgets = _.concat(containedWidgets, widgetChildPointer.id, widgetsMap[widgetChildPointer.id])
        }
    })
    return _.uniq(containedWidgets)
}

/**
 * Get first layer of widgets contained in widget pointer
 * @param ps
 * @param widgetPointer - Containing widget
 * @return array of compRefs all of type  wysiwyg.viewer.components.RefComponent
 */
function getFirstLevelRefChildren(ps: PS, widgetPointer: Pointer) {
    const widgetRef = getAppWidgetRefFromPointer(ps, widgetPointer)
    const children = componentStructureInfo.getChildrenFromFull(ps, widgetRef, true)
    return _.filter(
        children,
        child => componentStructureInfo.getType(ps, child) === 'wysiwyg.viewer.components.RefComponent'
    )
}

/**
 * Get appWidgetCompRef from its widgetPointer
 * @param ps
 * @param widgetPointer
 * @return appWidget compRef
 */
function getAppWidgetRefFromPointer(ps: PS, widgetPointer: Pointer) {
    const pageRef = getPageByWidgetPointer(ps, widgetPointer)
    return getRootAppWidgetByPage(ps, pageRef)
}

const getRootContainerByWidgetPointer = (ps: PS, widgetPointer: Pointer) => {
    const pageRef = getPageByWidgetPointer(ps, widgetPointer)
    return _.head(componentStructureInfo.getChildren(ps, pageRef))
}

const isAppWidgetComponent = (ps: PS, compRef: Pointer) =>
    componentStructureInfo.getType(ps, compRef) === constants.CONTROLLER_TYPES.APP_WIDGET

function getRootCompIdByPointer(ps: PS, pointer: Pointer) {
    const widgetData = getData(ps, pointer)

    if (widgetData?.rootCompId) {
        return _.replace(widgetData.rootCompId, '#', '')
    }
}

function getPageByWidgetPointer(ps: PS, pointer: Pointer) {
    const pageId = getRootCompIdByPointer(ps, pointer)
    return ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
}

/**
 * Get widgetPointer by refCompRef
 * @param ps
 * @param refComp - ref of component of type wysiwyg.viewer.components.RefComponent
 * @return widgetPointer
 */
function getWidgetPointerByRefComp(ps: PS, refComp: Pointer) {
    const {pageId: widgetPageId, type} = dataModel.getDataItem(ps, refComp)
    if (INTERNAL_REF_TYPES.has(type)) {
        return getWidgetByRootCompId(ps, widgetPageId)
    }
}

function getWidgetByRootCompId(ps: PS, rootCompId: string) {
    const widget = findWidgetByPageId(ps, rootCompId)
    return widget?.pointer
}

const updateWidgetContainedWidgets = (ps: PS) => {
    const containedWidgetsMap = getContainingWidgetsMap(ps)
    _.forEach(containedWidgetsMap, (containedWidgets, containingWidgetPointerId) => {
        const widgetPointer = ps.pointers.data.getDataItemFromMaster(containingWidgetPointerId)
        const widgetData = getData(ps, widgetPointer)
        widgetData.containedWidgets = _.map(containedWidgets, widgetPointerId => `#${widgetPointerId}`)
        setWidgetData(ps, widgetPointer, widgetData)
    })
}

function updateWidgetApi(ps: PS, widgetPointer: Pointer, widgetData, apiPart, newData) {
    widgetData.widgetApi[apiPart] = newData
    setWidgetData(ps, widgetPointer, widgetData)
}

function setWidgetData(ps: PS, widgetPointer: Pointer, newData) {
    ps.extensionAPI.schemaAPI.addDefaultsAndValidate(newData.type, newData, 'data')
    setData(ps, widgetPointer, newData)
}

const setData = (ps: PS, pointer: Pointer, data) => {
    const nonTranslatablePointer = mlUtils.getNonTranslatablePointer(ps, pointer)
    return ps.dal.set(nonTranslatablePointer, data)
}

const mergeData = (ps: PS, pointer: Pointer, data) => {
    const nonTranslatablePointer = mlUtils.getNonTranslatablePointer(ps, pointer)
    return ps.dal.merge(nonTranslatablePointer, data)
}

const getPanelsCount = (ps: PS): number => getAllPanelIds(ps).length

const moveWidgetEntity = (
    ps: PS,
    widgetPointer: Pointer,
    entity: 'panels' | 'presets',
    fromIndex: number,
    toIndex: number
) => {
    const widgetData = getData(ps, widgetPointer)
    const newItems = utils.moveItemInArray([...widgetData[entity]], fromIndex, toIndex)

    setWidgetData(ps, widgetPointer, {...widgetData, [entity]: newItems})
}

const getWidgetPanelsCount = (ps: PS, widgetPointer: Pointer): number => {
    const widgetData = getData(ps, widgetPointer)

    return (widgetData?.panels ?? []).length
}

export default {
    getNewDataId,
    getNewDataItemPointer,
    getAppWidgetRefFromPointer,
    getEntityByPage,
    getWidgetPanelsPointers,
    getAllPanels,
    setWidgetData,
    getData,
    setData,
    mergeData,
    isDefaultPage,
    isWidgetPage,
    isDashboardPage,
    updateWidgetContainedWidgets,
    updateWidgetApi,
    getWidgetPointerByRefComp,
    getRootAppWidgetByPage,
    isBlocksComponentPage,
    getRootContainerByWidgetPointer,
    getFirstLevelRefChildren,
    getWidgetByRootCompId,
    getRootCompIdByPointer,
    getPageByWidgetPointer,
    updateAppStudioOnMasterPage,
    updateAppStudioMetaData,
    getContainedWidgets,
    getContainingWidgetsMap,
    isVariationPage,
    getDescriptorPointerById,
    findWidgetByPageId,
    findVariationByPageId,
    getAllWidgets,
    getWidgetsCount,
    getAppStudioData,
    getAppStudioMetaData,
    serializeWidget,
    getAllSerializedWidgets,
    getAllCustomDefinitions,
    getAllSerializedCustomDefinitions,
    getSerializedCustomDefinition,
    getWidgetDevCenterId,
    setWidgetDevCenterId,
    setDefaultSize,
    getDefaultSize,
    getWidgetPanelsData,
    getPanelsCount,
    moveWidgetEntity,
    getWidgetPanelsCount,

    //only used in tests
    cleanCache
}
