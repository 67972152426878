'use strict'
const coreUtils = require('@wix/santa-core-utils')
const constants = require('../../helpers/constants')
const {dataUtils} = coreUtils

const {DESKTOP, MOBILE, SLOTS_NAMESPACE, VARIANT_RELATION_TYPE} = constants
const TPA_TYPES = ['wysiwyg.viewer.components.tpapps.TPAWidget']
const SINGLE_LAYOUT_DATA_TYPE = 'SingleLayoutData'

const getAllTpaComponents = (structure, viewMode) => {
    return dataUtils.getAllCompsInStructure(structure, viewMode === MOBILE, component =>
        TPA_TYPES.includes(component.componentType)
    )
}

const deleteLayout = (pageJson, component) => {
    const {layout_data} = pageJson.data
    const layoutQuery = component.layoutQuery?.replace('#', '')

    if (!layoutQuery) return

    const refArray = layout_data[layoutQuery]
    if (refArray?.values) {
        refArray.values.forEach(value => {
            const layoutId = value.replace('#', '')
            const layoutEntry = layout_data[layoutId]

            if (layoutEntry) {
                if (layoutEntry.type === SINGLE_LAYOUT_DATA_TYPE) {
                    delete layout_data[layoutId]
                } else if (layoutEntry.type === VARIANT_RELATION_TYPE) {
                    delete layout_data[layoutEntry.to.replace('#', '')]
                    delete layout_data[layoutId]
                }
            }
        })
    }
    delete layout_data[layoutQuery]
}

const deleteTpaChildrenWithoutCorrespondingSlots = (pageJson, tpaComponents) => {
    if (!tpaComponents.length || !pageJson.data.layout_data) return

    for (let i = 0; i < tpaComponents.length; i++) {
        const tpa = tpaComponents[i]
        if (!tpa.components?.length) continue

        const {slotsQuery} = tpa
        const slots = pageJson.data[SLOTS_NAMESPACE]?.[slotsQuery]?.slots

        const slotsIds = slots ? Object.values(slots) : []
        tpa.components = tpa.components.filter(child => {
            const shouldKeepChild = slotsIds.includes(child.id)
            if (!shouldKeepChild) {
                deleteLayout(pageJson, child)
            }
            return shouldKeepChild
        })
    }
}

const exec = pageJson => {
    const desktopTpaComponentsMap = getAllTpaComponents(pageJson.structure, DESKTOP)
    const mobileTpaComponentsMap = getAllTpaComponents(pageJson.structure, MOBILE)

    if (desktopTpaComponentsMap && Object.keys(desktopTpaComponentsMap).length) {
        deleteTpaChildrenWithoutCorrespondingSlots(pageJson, Object.values(desktopTpaComponentsMap))
    }
    if (mobileTpaComponentsMap && Object.keys(mobileTpaComponentsMap).length) {
        deleteTpaChildrenWithoutCorrespondingSlots(pageJson, Object.values(mobileTpaComponentsMap))
    }
}

module.exports = {
    name: 'deleteTpaChildrenWithoutCorrespondingSlots',
    version: 1,
    exec
}
