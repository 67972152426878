import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ErrorResult, Result} from './types'
import type {LoggerExtAPI} from '../logger'
import _ from 'lodash'
import type {DeserializationMappers} from '@wix/document-services-types'

const REPEATER_DELIMITER = '__'

export const isError = (r: Result): r is ErrorResult => r.success === false

export const sendBreadCrumbOnValidationError = (
    {extensionAPI}: CreateExtArgs,
    validationError: string,
    extras?: any
) => {
    const {logger} = extensionAPI as LoggerExtAPI
    logger.breadcrumb(validationError, {extras})
}

export const getRepeaterTemplateId = (uniqueItemId: string | undefined) =>
    _.isString(uniqueItemId) ? uniqueItemId.split(REPEATER_DELIMITER)[0] : undefined

export const mapOldIdToNewId = (
    serializedComp: {id?: string},
    compPointer: {id: string},
    mappers?: DeserializationMappers
) => {
    if (mappers?.oldToNewIdMap && serializedComp.id) {
        mappers.oldToNewIdMap[serializedComp.id] = compPointer.id
    }
}
