import type {
    CompRef,
    ExternalsMap,
    RawComponentExport,
    RawComponentExportStructure,
    RawImportExportOptions
} from '@wix/document-services-types'
import type {CreateExtArgs, DAL} from '@wix/document-manager-core'
import {importAllComponents} from '../import'
import type {ComponentsExtensionAPI} from '../../../../components/components'
import {addPlaceholderComponent} from '../../utils'
import _ from 'lodash'

type ChildrenMap = Record<string, CompRef>

interface ChildMatch {
    childId: CompRef['id']
    componentToReplace?: CompRef
}

interface ChildrenMatch {
    matches: ChildMatch[]
    toRemove: CompRef[]
}

const buildKey = (id: string, componentType: string): string => `${id}&&${componentType}`

export const buildChildrenMap = (childrenPointers: CompRef[], dal: DAL): ChildrenMap => {
    const childrenMap = {}

    for (const childPointer of childrenPointers) {
        const childKey = buildKey(childPointer.id, dal.get(childPointer).componentType)
        childrenMap[childKey] = childPointer
    }

    return childrenMap
}

export const matchChildren = (
    exportChildrenIds: string[],
    childrenMap: ChildrenMap,
    componentExport: RawComponentExport
): ChildrenMatch => {
    const {components, externalRefs} = componentExport
    const matches: ChildMatch[] = []

    for (const childId of exportChildrenIds) {
        const match: ChildMatch = {childId}
        const child = components[childId]
        const childKey = buildKey(externalRefs[childId] ?? childId, child.structure.componentType)
        const componentToReplace = childrenMap[childKey]
        if (componentToReplace) {
            delete childrenMap[childKey]
            match.componentToReplace = componentToReplace
        }

        matches.push(match)
    }

    return {
        matches,
        toRemove: _.values(childrenMap)
    }
}

export const updateChildren = (
    extensionContext: CreateExtArgs,
    componentPointer: CompRef,
    componentStructure: RawComponentExportStructure['structure'],
    componentExport: RawComponentExport,
    externals: ExternalsMap,
    options: RawImportExportOptions
) => {
    const {dal, pointers, extensionAPI} = extensionContext
    const children = componentStructure.components ?? []
    const origChildren = pointers.structure.getChildren(componentPointer) as CompRef[]
    const childrenMap = buildChildrenMap(origChildren, dal)
    const {matches, toRemove} = matchChildren(children, childrenMap, componentExport)
    if (matches.length === 0 && toRemove.length === 0) {
        return
    }

    const {components} = extensionAPI as ComponentsExtensionAPI
    for (const componentToRemove of toRemove) {
        const parentPointer = pointers.structure.getParent(componentToRemove)
        if (pointers.components.isSameComponent(parentPointer, componentPointer)) {
            components.removeComponent(componentToRemove)
        }
    }

    const {externalRefs} = componentExport
    const newChildren = []
    for (const {
        childId,
        componentToReplace = addPlaceholderComponent(externalRefs[childId], componentPointer, dal, pointers)
    } of matches) {
        newChildren.push(componentToReplace.id)
        importAllComponents(extensionContext, childId, componentExport, componentToReplace, externals, options)
    }

    dal.set(pointers.structure.getChildrenContainer(componentPointer), newChildren)
}
