import {setElementStyle} from './elementStyleBuilder'
const getElementsTightBox = (document: Document, ids: string[]) => {
    return ids.reduce(
        (res, id) => {
            const element = document.getElementById(id)!
            const {x, y, width, height} = element.getBoundingClientRect()
            return [Math.min(res[0], x), Math.min(res[1], y), Math.max(res[2], x + width), Math.max(res[3], y + height)]
        },
        [Infinity, Infinity, 0, 0]
    )
}

export const createOverlayElement = (window: Window, ids: string[], id: string, color: string) => {
    const {document} = window
    const [x1, y1, x2, y2] = getElementsTightBox(document, ids)
    const overlayElement = document.createElement('div')
    setElementStyle(overlayElement, {
        position: 'absolute',
        left: `${window.scrollX + x1}px`,
        top: `${window.scrollY + y1}px`,
        width: `${x2 - x1}px`,
        height: `${y2 - y1}px`,
        border: `3px solid ${color}`
    })
    overlayElement.id = id
    document.body.appendChild(overlayElement)
    return overlayElement
}

export const removeOverlayElement = (document: Document, overlayElement: HTMLElement) => {
    document.body.removeChild(overlayElement)
}
