'use strict'

const NEW_MENU = {
    TYPE: 'wixui.Menu',
    SKIN: 'wixui.skins.Menu'
}

const entriesToChange = {
    componentType: NEW_MENU.TYPE,
    skin: NEW_MENU.SKIN,
    type: 'Container'
}

const keysToKeep = [
    'id',
    'styleId',
    'layout',
    'classnamesQuery',
    'connectionQuery',
    'dataQuery',
    'layoutQuery',
    'slotsQuery',
    'components'
]

const migrateToNewMenuComponent = component => {
    Object.keys(component).forEach(key => keysToKeep.includes(key) || delete component[key])
    Object.entries(entriesToChange).forEach(([key, value]) => (component[key] = value))
}

module.exports = {
    NEW_MENU,
    migrateToNewMenuComponent
}
