import _ from 'lodash'
import type {DistributeRequest} from '@wix/ambassador-document-management-document-store-v1-transaction/types'
import {
    CreateExtArgs,
    CreateExtensionArgument,
    DmApis,
    Extension,
    ExtensionAPI,
    pointerUtils
} from '@wix/document-manager-core'
import type {DocumentServicesModelExtApi} from '../documentServicesModel'
import type {RMApi} from '../rendererModel'
import type {DistributeMessage} from './messages'
import {Channel, ChannelEvents} from '../channelUtils/duplexer'
import {classic, ReportableError, taskWithRetries} from '@wix/document-manager-utils'
import {MockDistributorDuplexer} from './mockDistributorDuplexer'
import {guidUtils} from '@wix/santa-core-utils'
import type {DistributedCallback, MessageInfo} from './types'
import {deepClone} from '@wix/wix-immutable-proxy'

const {getGUID: generateGUID} = guidUtils
const {getPointer} = pointerUtils

const channelName = 'dm_metadata'

const pendingMessagesPointerType = 'pendingToDistribute'
const DUPLEXER_RETRY_TIME = 1000 * 5
const DISTRIBUTOR_DUPLEXER_INIT_RETRY_NUMBER = 5

class ContextMap {
    private map: Record<string, MessageInfo> = {}

    get(messageType: string): MessageInfo {
        this.map[messageType] ??= {callbacks: [], messagesSent: []}
        return this.map[messageType]
    }
}

interface DistributorAPI extends ExtensionAPI {
    distributeMessage<T extends DistributeMessage>(messageType: T['messageType'], data: T['data']): void
    subscribeToMessage<T extends DistributeMessage>(
        messageType: T['messageType'],
        listener: DistributedCallback<T>
    ): void
    distributeMessageAfterApproval<T extends DistributeMessage>(messageType: T['messageType'], data: T['data']): void
    attachMessageToTransactionApproval(message: DistributeMessage, correlationId: string): void
    transactionApproved(localApprovedCorrelation: string, csaveCorrelation: string): void
    foreignTransactionApplied(correlationId: string): void
    isConnected(): boolean
}

interface DistributorExtensionAPI extends ExtensionAPI {
    distributor: DistributorAPI
}

/**
 * @returns {Extension}
 */
const createExtension = ({environmentContext, dsConfig, experimentInstance}: CreateExtensionArgument): Extension => {
    const messagesContext: ContextMap = new ContextMap()
    const waitingForApproval: Record<string, {message: DistributeMessage}[]> = {}
    const waitingForForeignApply: Record<string, {message: DistributeMessage}[]> = {}
    let isDuplexerConnected = false

    const duplexerEnabled =
        dsConfig.cedit || (!experimentInstance.isOpen('dm_ceditSingleUserForClassic') && dsConfig.origin === classic)

    const getPendingToDistributePointer = (id: string) => getPointer(id, pendingMessagesPointerType)

    const attachMessageToForeignTransactionApproval = (message: DistributeMessage, correlationId: string) => {
        if (!waitingForForeignApply[correlationId]) {
            waitingForForeignApply[correlationId] = []
        }
        waitingForForeignApply[correlationId].push({message})
    }

    const createExtensionAPI = ({dal, coreConfig: {logger}}: CreateExtArgs): DistributorExtensionAPI => {
        const subscribeToMessage = <T extends DistributeMessage>(
            messageType: DistributeMessage['messageType'],
            callback: DistributedCallback<T>
        ) => {
            const context = messagesContext.get(messageType)
            context.callbacks.push(callback)
        }

        const createMessage = <T extends DistributeMessage>(
            messageType: T['messageType'],
            data: T['data'],
            id?: T['id']
        ) => {
            return {
                id: id ?? generateGUID(),
                messageType,
                data
            } as T
        }

        const distributeMessageInternal = <T extends DistributeMessage>(message: T) => {
            const {messagesSent} = messagesContext.get(message.messageType)
            const server = environmentContext.serverFacade

            logger.interactionStarted('distributeMessage', {
                extras: {
                    id: message.id,
                    messageType: message.messageType
                }
            })
            messagesSent?.push(message.id)
            server.distributeMessage([message])
        }

        const distributeMessage = <T extends DistributeMessage>(messageType: T['messageType'], data: T['data']) => {
            const message = createMessage(messageType, data)
            distributeMessageInternal(message)
        }

        const distributeMessageAfterApproval = <T extends DistributeMessage>(
            messageType: T['messageType'],
            data: T['data']
        ) => {
            const message = createMessage<T>(messageType, data)
            const pendingToDistributePointer = getPendingToDistributePointer(message.id)
            dal.set(pendingToDistributePointer, {type: pendingMessagesPointerType, id: message.id, message})
        }

        const attachMessageToTransactionApproval = (message: DistributeMessage, correlationId: string) => {
            if (!waitingForApproval[correlationId]) {
                waitingForApproval[correlationId] = []
            }
            waitingForApproval[correlationId].push({message})
        }

        const transactionApproved = (localApprovedCorrelation: string, csaveCorrelation: string) => {
            if (waitingForApproval[localApprovedCorrelation]) {
                _.forEach(waitingForApproval[localApprovedCorrelation], ({message}) => {
                    const enhancedMessage = _.merge(deepClone(message), {data: {correlationId: csaveCorrelation}})
                    distributeMessageInternal(enhancedMessage)
                    const pendingToDistributePointer = getPendingToDistributePointer(message.id)
                    dal.remove(pendingToDistributePointer)
                })

                delete waitingForApproval[localApprovedCorrelation]
            }
        }

        const foreignTransactionApplied = (correlationId: string) => {
            if (waitingForForeignApply[correlationId]) {
                _.forEach(waitingForForeignApply[correlationId], ({message}) => {
                    const {callbacks} = messagesContext.get(message.messageType)
                    _.forEach(callbacks, callback => callback(message))
                })
            }
        }

        const enableForMultipleUsers =
            (f: Function) =>
            (...rest: any[]): void => {
                if (dsConfig.concurrentWorkEnabled) {
                    return f(...rest)
                }
            }

        function isConnected() {
            return isDuplexerConnected
        }

        return {
            distributor: {
                subscribeToMessage,
                distributeMessage: enableForMultipleUsers(distributeMessage),
                distributeMessageAfterApproval: enableForMultipleUsers(distributeMessageAfterApproval),
                attachMessageToTransactionApproval,
                transactionApproved,
                foreignTransactionApplied,
                isConnected
            }
        }
    }
    const initializeChannelSubscriptions = async (args: DmApis) => {
        const {extensionAPI, coreConfig, dal} = args
        const {siteAPI: dsSiteApi} = extensionAPI as DocumentServicesModelExtApi
        const {siteAPI: rmSiteApi} = extensionAPI as RMApi

        const initDistributorDuplexer = async () => {
            if (isNewFromTemplate()) {
                return
            }
            try {
                const duplexer = createDuplexer()
                duplexer.on(ChannelEvents.messageDistribute, onMessageReceived)
                await duplexer.subscribe(channelName, experimentInstance)
                isDuplexerConnected = true
            } catch (e) {
                report(e, 'duplexerDistributorCreationError')
                throw e
            }
        }

        function isNewFromTemplate(): boolean {
            return rmSiteApi.isTemplate() && !dsSiteApi.getAutosaveInfo()?.shouldAutoSave
        }

        function createDuplexer(): Channel {
            const server = environmentContext.serverFacade
            if (server && duplexerEnabled) {
                return server.createDuplexer(
                    () => rmSiteApi.getInstance(),
                    dsConfig.origin,
                    coreConfig.logger,
                    dsSiteApi.getBranchId()
                )
            }
            return new MockDistributorDuplexer()
        }

        function onMessageReceived({payloads}: DistributeRequest) {
            payloads?.forEach(payload => {
                const {id, messageType} = payload
                const {messagesSent, callbacks} = messagesContext.get(messageType!)
                const isOwner = messagesSent.includes(id!)
                if (isOwner) {
                    _.remove(messagesSent, messageId => messageId === id!)
                    return
                }

                const {correlationId} = payload.data!
                if (!correlationId || dal._snapshots.findById(correlationId)) {
                    _.forEach(callbacks, callback => callback(payload))
                } else {
                    attachMessageToForeignTransactionApproval(payload as DistributeMessage, correlationId)
                }
            })
        }

        function report(e: any, errorType: string) {
            const err = e as Error
            const {logger} = coreConfig
            logger.captureError(
                new ReportableError({
                    errorType,
                    message: err.message
                })
            )
        }

        try {
            await taskWithRetries(
                initDistributorDuplexer,
                () => true,
                DISTRIBUTOR_DUPLEXER_INIT_RETRY_NUMBER,
                DUPLEXER_RETRY_TIME
            )
        } catch (e) {
            report(e, 'duplexerDistributorCreationErrorAfterRetry')
        }
    }

    return {
        name: 'distributor',
        createExtensionAPI,
        initializeChannelSubscriptions
    }
}

export {createExtension, DistributorExtensionAPI, DistributorAPI, pendingMessagesPointerType}
