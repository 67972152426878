import type {MenuData, MenuItem, Pointer, PS, AppDefinitionId} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import componentStructureInfo from '../component/componentStructureInfo'
import dataModel from '../dataModel/dataModel'
import menuUtils from './menuUtils'
import constants from '../constants/constants'
import mlUtils from '../utils/multilingual'
import language from '../siteMetadata/language'
import hooks from '../hooks/hooks'
import {constants as extConsts} from '@wix/document-manager-extensions'
const {DATA_TYPES} = extConsts
import {tpa} from '@wix/santa-ds-libs'
import clientSpecMapService from '../tpa/services/clientSpecMapService'

const {VIEW_MODES} = constants

const CUSTOM_MAIN_MENU = 'CUSTOM_MAIN_MENU'
const ALLOWED_MENUS = [
    'wysiwyg.viewer.components.menus.DropDownMenu',
    'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
    'wysiwyg.viewer.components.mobile.TinyMenu',
    'wysiwyg.viewer.components.ExpandableMenu',
    'wixui.StylableHorizontalMenu',
    'wixui.Breadcrumbs',
    'wixui.Menu'
]
const ALLOWED_MENUS_AS_MAP = _.keyBy(ALLOWED_MENUS)

const supportedConnectType = ['CustomMenuDataRef', 'TinyMenu', 'BreadcrumbsData']
const cannotLinkThisCompToMenuData = _.template(
    `Cannot link a menu data item to a component that is not one of: ${ALLOWED_MENUS.join(
        ', '
    )}. Given component type was <%= compType %>.`
)

const NO_SUPPORT_FOR_2_LEVELS = 'Currently, components do not support menus which are more than 2 levels deep.'

function mutateItems(mutators: ((item: MenuItem) => void)[], item: MenuItem) {
    _.forEach(mutators, mutator => {
        mutator(item)
    })
    if (item.items) {
        _.forEach(item.items, subItem => {
            mutateItems(mutators, subItem)
        })
    }
}

function normalizeMenuItems(menuData: Partial<MenuData>) {
    _.forEach(menuData.items, item => {
        mutateItems([withoutId, withoutLinkId, asBasicMenuItem], item)
    })
}

const withoutId = _.partialRight(_.unset, 'id')
const withoutLinkId = _.partialRight(_.unset, ['link', 'id'])
const asBasicMenuItem = _.partialRight(_.set, 'type', 'BasicMenuItem')

/**
 *
 * @param ps
 * @param newMenuId - this is injected
 * @param [menuData]
 * @returns {*}
 */
function createMenu(ps: PS, newMenuId: string, menuData?: Partial<MenuData>) {
    return ps.extensionAPI.menus.createMenu(newMenuId, menuData)
}

function getMenuIdToCreate(ps?: PS, menuData?: MenuData, optionalCustomId?: string) {
    return optionalCustomId ?? dataModel.generateNewDataItemId()
}

/**
 * Updates the menu with the provided menu data, in current language
 * @param ps Private Services instance
 * @param menuId The menu ID to update
 * @param menuData The menu data to update
 */
function updateMenu(ps: PS, menuId: string, menuData: Partial<MenuData>) {
    const useLanguage = language.current.get(ps)
    updateMenuInLang(ps, menuId, menuData, useLanguage)
}

/**
 * Updates the menu with the provided menu data
 * @param ps Private Services instance
 * @param menuId The menu ID to update
 * @param menuData The menu data to update
 * @param useLanguage the language code to use
 */
function updateMenuInLang(ps: PS, menuId: string, menuData: Partial<MenuData>, useLanguage: string) {
    validateMenuExists(ps, menuId)
    hooks.executeHook(hooks.HOOKS.CLIENT_SPEC_MAP.MIGRATE_APPID_TO_APPDEF, null, [ps, menuData, 'menuAPI'])

    const isUpdatingMainMenu = menuId === CUSTOM_MAIN_MENU
    if (!isUpdatingMainMenu) {
        const originalLang = mlUtils.getLanguageByUseOriginal(ps, true)
        if (useLanguage === originalLang) {
            menuUtils.splitMenusInSecondaryLanguagesIfAltered(ps, menuId)
        }
    }
    ps.extensionAPI.dataModel.addItemWithRefReuse(menuData, DATA_TYPES.data, 'masterPage', menuId, useLanguage)
    if (isUpdatingMainMenu) {
        menuUtils.updateTranslatedMenuItems(ps, menuData as any)
    }
    if (menuId === CUSTOM_MAIN_MENU) {
        ps.extensionAPI.menus.fixItemsWithDifferentIdAndSamePageId(menuId)
    }
}

function validateMenuExists(ps: PS, menuId: string) {
    const menuDataPointer = ps.pointers.data.getDataItemFromMaster(menuId)

    if (!menuId || !ps.dal.full.isExist(menuDataPointer)) {
        throw new Error(`Menu with id ${menuId} does not exist.`)
    }
}

function validateCompConnectionToMenu(ps: PS, menuCompPointer: Pointer, menuId: string) {
    const compType = componentStructureInfo.getType(ps, menuCompPointer)

    if (!_.includes(ALLOWED_MENUS, compType)) {
        throw new Error(cannotLinkThisCompToMenuData({compType}))
    }

    validateMenuExists(ps, menuId)
}

function connectComponentToMenu(ps: PS, menuCompPointer: Pointer, menuId: string) {
    validateCompConnectionToMenu(ps, menuCompPointer, menuId)
    const {type: currentDataType = 'CustomMenuDataRef'} = dataModel.getDataItem(ps, menuCompPointer) || {}
    dataModel.updateDataItem(ps, menuCompPointer, {
        menuRef: `#${menuId}`,
        type: _.includes(supportedConnectType, currentDataType) ? currentDataType : 'CustomMenuDataRef'
    })
}

function getAllMenus(ps: PS): MenuData[] {
    return dataModel.getDataItemById(ps, 'CUSTOM_MENUS', 'masterPage').menus
}

function getMenuById(ps: PS, menuId: string, useOriginalLanguage = false): MenuData | null {
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
    return getMenuByIdInLang(ps, menuId, useLanguage)
}

/**
 *
 * @param {ps} ps
 * @param {string} menuId
 * @param {string|undefined} [useLanguage=undefined]
 * @return {MenuData|null}
 */
function getMenuByIdInLang(ps: PS, menuId: string, useLanguage: string = undefined): MenuData | null {
    const pointer = ps.pointers.data.getDataItemFromMaster(menuId)
    if (ps.dal.isExist(pointer)) {
        return dataModel.getDataItemByIdInLang(ps, menuId, undefined, undefined, useLanguage)
    }
    return null
}

function getMenusByType(ps: PS, menuType: string) {
    const menuPointers = menuUtils.getMenusByFilter(ps, {menuType})
    return _.map(menuPointers, menuPtr => dataModel.getDataItemById(ps, menuPtr.id, 'masterPage'))
}

function isConnectedToMenuData(ps: PS, menuId: string) {
    return function (comp: Pointer) {
        const compData = dataModel.getDataItem(ps, comp)
        return compData.menuRef === `#${menuId}`
    }
}

const isMenuComponent = (ps: PS) => (ptr: Pointer) =>
    !!ALLOWED_MENUS_AS_MAP[ps.dal.full.get(ps.pointers.getInnerPointer(ptr, 'componentType'))]

function hasCompsConnectedToMenu(ps: PS, menuId: string) {
    const desktop = componentDetectorAPI.getAllComponentsFromFull(ps, null, isMenuComponent(ps), VIEW_MODES.DESKTOP)
    const mobile = componentDetectorAPI.getAllComponentsFromFull(ps, null, isMenuComponent(ps), VIEW_MODES.MOBILE)
    return _.some(desktop.concat(mobile), isConnectedToMenuData(ps, menuId))
}

function removeMenu(ps: PS, menuId: string) {
    ps.extensionAPI.menus.removeMenu(menuId)
}

function addItem(ps: PS, newMenuItemId: string, menuId: string, newItem: any) {
    const menuDataForValidation = {items: [newItem]}
    normalizeMenuItems(menuDataForValidation as any)
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, false)
    const menuData = getMenuByIdInLang(ps, menuId, useLanguage)
    const newMenuItem = ps.extensionAPI.dataModel.createDataItemByType(
        'BasicMenuItem',
        _.assign(newItem, {id: newMenuItemId})
    )
    menuData.items.push(newMenuItem)
    updateMenu(ps, menuId, menuData)
}

function removeTreeItemDeep(items: any[], itemId: any, shouldKeepSubitems: any) {
    if (!items) {
        return items
    }

    return items.reduce((acc, item) => {
        if (item.id === itemId) {
            if (shouldKeepSubitems && item.items) {
                acc = acc.concat(item.items)
            }
        } else {
            item.items = removeTreeItemDeep(item.items, itemId, shouldKeepSubitems)
            acc.push(item)
        }

        return acc
    }, [])
}

function removeItemInLang(ps: PS, menuId: string, itemId: string, shouldKeepSubitems: boolean, useLanguage: string) {
    validateMenuExists(ps, menuId)
    const menuData = getMenuByIdInLang(ps, menuId, useLanguage)
    menuData.items = removeTreeItemDeep(menuData.items, itemId, shouldKeepSubitems)
    updateMenuInLang(ps, menuId, menuData, useLanguage)
}

function removeItem(ps: PS, menuId: string, itemId: string, shouldKeepSubItems: boolean = false) {
    const originalLang = mlUtils.getLanguageByUseOriginal(ps, true)
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, false)

    if (originalLang !== useLanguage) {
        removeItemInLang(ps, menuId, itemId, shouldKeepSubItems, useLanguage)
    } else {
        // Remove in all languages
        removeItemInLang(ps, menuId, itemId, shouldKeepSubItems, originalLang)
        const translationLanguages = ps.extensionAPI.multilingualTranslations.getLanguagesForItem(menuId)
        translationLanguages.forEach((translatedLanguageCode: string) =>
            removeItemInLang(ps, menuId, itemId, shouldKeepSubItems, translatedLanguageCode)
        )
    }

    dataModel.removeDataByPointer(ps, ps.pointers.data.getDataItem(itemId, 'masterPage'))
}

function updateItem(ps: PS, menuId: string, itemId: string, partialMenuItem: Partial<MenuItem>) {
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, false)
    updateItemInLang(ps, menuId, itemId, partialMenuItem, useLanguage)
}

/**
 * @param ps
 * @param menuId
 * @param itemId
 * @param partialMenuItem
 * @param useLanguage the language code to use
 */
function updateItemInLang(
    ps: PS,
    menuId: string,
    itemId: string,
    partialMenuItem: Partial<MenuItem>,
    useLanguage: string
) {
    validateMenuExists(ps, menuId)
    const menuItemPointer = ps.pointers.data.getDataItemFromMaster(itemId)

    if (!ps.dal.isExist(menuItemPointer)) {
        throw new Error(`Menu item with id ${itemId} does not exist.`)
    }

    if (partialMenuItem.items?.length) {
        const menuData = getMenuById(ps, menuId)
        if (!_.find(menuData.items, {id: itemId})) {
            //this means we will make it 2 levels deep
            throw new Error(
                `Updating menu item with id ${itemId} with items will cause the menu to be more than 2 levels deep. ${NO_SUPPORT_FOR_2_LEVELS}`
            )
        }
    }

    dataModel.addSerializedDataItemToPage(
        ps,
        ps.pointers.data.getPageIdOfData(menuItemPointer),
        _.assign(partialMenuItem, {
            id: itemId,
            type: 'BasicMenuItem'
        }),
        itemId,
        useLanguage
    )
}

/** Updates the menu with the provided menu data for the desired language
 *
 * @param {ps} ps Private Services instance
 * @param {string} languageCode the language code to use
 * @param {string} menuId The menu ID to update
 * @param {menuData} menuData The menu data to update
 */
function multilingualMenuUpdate(ps: PS, languageCode: string, menuId: string, menuData: MenuData) {
    return updateMenuInLang(ps, menuId, menuData, languageCode)
}

/** gets the menu data for the desired language
 *
 * @param {ps} ps Private Services instance
 * @param {string} languageCode the language code to use
 * @param {string} menuId The menu ID to update
 */
function multilingualMenuGet(ps: PS, languageCode: string, menuId: string) {
    const menuWithOriginalLanguageOverides = getMenuByIdInLang(ps, menuId, languageCode)
    const originalLanguageCode = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
    if (originalLanguageCode === languageCode) {
        return menuWithOriginalLanguageOverides
    }

    const allMenuAndItemIds = menuUtils.getFlatMenuWithMetaData(ps, menuId, languageCode)
    // @ts-expect-error
    return _.some(allMenuAndItemIds, {isTranslatedItem: true}) ? menuWithOriginalLanguageOverides : undefined
}

function multilingualItemUpdate(ps: PS, languageCode: string, menuId: string, itemId: string, data: Partial<MenuItem>) {
    return updateItemInLang(ps, menuId, itemId, data, languageCode)
}

const isTPAPageMarkedAsHideFromMenu = function (ps: PS, appDefinitionId: AppDefinitionId, tpaPageId: string) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    return tpa.common.utils.isPageMarkedAsHideFromMenu(appData, tpaPageId)
}

export default {
    update: updateMenu,
    create: createMenu,
    getMenuIdToCreate,
    getAll: getAllMenus,
    getById: getMenuById,
    getByType: getMenusByType,
    remove: removeMenu,
    connect: connectComponentToMenu,
    hasConnectedComps: hasCompsConnectedToMenu,
    addItem,
    removeItem,
    updateItem,
    updateItemInLang,
    multilingual: {
        get: multilingualMenuGet,
        update: multilingualMenuUpdate,
        updateItem: multilingualItemUpdate
    },
    isTPAPageMarkedAsHideFromMenu
}
