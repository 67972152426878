import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef} from '@wix/document-services-types'
import {guidUtils, displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import {DATA_TYPES} from '../../../constants/constants'

const updateComponentData = (
    {extensionAPI}: CreateExtArgs,
    compPointer: CompRef,
    fixedText: string,
    newLinks: string[],
    languageCode?: string
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const compData = dataModel.components.getItem(compPointer, DATA_TYPES.data, languageCode)
    compData.text = fixedText
    compData.linkList = newLinks
    dataModel.components.addItem(compPointer, DATA_TYPES.data, compData, languageCode)
}

const createTextLinkReferences = (
    createExtArgs: CreateExtArgs,
    compPointer: CompRef,
    newLinkList: any[],
    text: string,
    languageCode?: string
) => {
    let textToFix: string = _.clone(text)
    // eslint-disable-next-line lodash/prefer-filter
    _.forEach(newLinkList, linkData => {
        if (linkData.id) {
            const newId = guidUtils.getUniqueId('link', '-', {bucket: 'links'})
            textToFix = textToFix.replace(`#${linkData.id}`, `#${newId}`)
            linkData.id = newId
        }
    })

    updateComponentData(createExtArgs, compPointer, textToFix, newLinkList, languageCode)
}

type LinkList = Record<string, any>

interface ComponentSerializedCustomData {
    originalCompId?: string
    linkList?: LinkList
    translations?: {
        linkList?: Record<string, LinkList>
    }
}

export const duplicateLinksDataItems = (createExtArgs: CreateExtArgs, data: any) => {
    const {compToAddPointer, componentDefinition} = data
    const {pointers, coreConfig} = createExtArgs

    const {linkList, originalCompId, translations}: ComponentSerializedCustomData = componentDefinition.custom ?? {}

    if (!linkList && !translations?.linkList) {
        return {success: true, description: 'Duplicated text links data items'}
    }

    const repeatedComponents = pointers.structure.getAllDisplayedOnlyComponents(compToAddPointer)

    _.forEach(repeatedComponents, (componentPointer: CompRef) => {
        const repeatedItemId = displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
        const linkListKey = repeatedItemId ? repeatedItemId : originalCompId
        const repeatedData = repeatedItemId
            ? componentDefinition.data.overrides[repeatedItemId]
            : componentDefinition.data
        if (linkList) {
            const newLinks = deepClone(linkList[linkListKey])
            createTextLinkReferences(createExtArgs, componentPointer, newLinks, repeatedData.text)
        }

        if (translations?.linkList && coreConfig.experimentInstance.isOpen('dm_multilingualTextLinkSupport')) {
            Object.entries(translations.linkList as LinkList).forEach(([languageCode, translatedLinkList]) => {
                const newLinks = deepClone(translatedLinkList[linkListKey])
                const translatedCompData = componentDefinition.translations[languageCode]

                createTextLinkReferences(
                    createExtArgs,
                    componentPointer,
                    newLinks,
                    translatedCompData.text,
                    languageCode
                )
            })
        }
    })

    return {success: true, description: 'Duplicated text links data items'}
}
