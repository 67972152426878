import type {
    AppDefinitionId,
    Callback,
    Callback1,
    CompRef,
    Layout,
    Point,
    Pointer,
    PS
} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import component from '../../component/component'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import dsConstants from '../../constants/constants'
import page from '../../page/page'
import theme from '../../theme/theme'
import tpaConstants from '../constants'
import appMarketService from './appMarketService'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import tpaComponentService from './tpaComponentService'
import tpaSectionService from './tpaSectionService'
import tpaWidgetService from './tpaWidgetService'
import {ReportableError} from '@wix/document-manager-utils'
import clientSpecMapDS from '../../siteMetadata/clientSpecMap'

const compTypes = {
    WIDGET: 'WIDGET',
    PAGE: 'PAGE'
}

const getNewCompStyleId = function (ps: PS, copyStyle, styleId: string, compId: string) {
    let newStyleId
    if (copyStyle) {
        if (styleId) {
            if (_.includes(theme.styles.getAllIds(ps), styleId)) {
                newStyleId = styleId
            }
        } else {
            const comp = componentDetectorAPI.getComponentById(ps, compId)
            newStyleId = componentStylesAndSkinsAPI.style.getId(ps, comp)
        }
    }
    return newStyleId
}

function getWidgetParams(ps: PS, appData, options, compId: string) {
    const curPageId = ps.siteAPI.getFocusedRootId()
    const pageId = _.get(options, 'widget.wixPageId') || curPageId
    const widget = clientSpecMapService.getWidgetDataFromTPAWidgetId(
        ps,
        appData.appDefinitionId,
        _.get(options, 'widget.tpaWidgetId')
    )
    return {
        pageId,
        widgetId: _.get(widget, 'widgetId'),
        showOnAllPages: _.get(options, 'widget.allPages'),
        styleId: getNewCompStyleId(ps, options.copyStyle, options.styleId, compId)
    }
}

function provisionWidget(options, ps: PS, compId: string, componentToAddRef: Pointer, appData) {
    _.assign(options, getWidgetParams(ps, appData, options, compId))
    tpaComponentService.provisionWidget(ps, componentToAddRef, appData.appDefinitionId, options)
}

const provisionWidgetComponent = function (ps: PS, componentToAddRef: Pointer, appData, compId: string, options) {
    const curPageId = ps.siteAPI.getFocusedRootId()
    const pageId = _.get(options, 'widget.wixPageId') || curPageId
    if (ps.pointers.page.isExists(pageId) || options.widget.allPages) {
        const widget = clientSpecMapService.getWidgetDataFromTPAWidgetId(
            ps,
            appData.appDefinitionId,
            _.get(options, 'widget.tpaWidgetId')
        )
        if (widget) {
            provisionWidget(options, ps, compId, componentToAddRef, appData)
        } else {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: 'No widget found.'
            })
        }
    } else {
        ps.setOperationsQueue.asyncPreDataManipulationComplete({
            onError: 'pageId was not found.'
        })
    }
}

const provisionComponentImpl = function (ps: PS, componentToAddRef: Pointer, compId: string, appData, options) {
    if (appData) {
        switch (options.componentType) {
            case compTypes.WIDGET:
                provisionWidgetComponent(ps, componentToAddRef, appData, compId, options)
                break
            case compTypes.PAGE:
                provisionPageComponent(ps, componentToAddRef, appData, compId, options)
                break
            default:
                ps.setOperationsQueue.asyncPreDataManipulationComplete({
                    onError: 'componentType not supported.'
                })
        }
    } else {
        ps.setOperationsQueue.asyncPreDataManipulationComplete({
            onError: 'General Error.'
        })
    }
}

const getSectionParams = function (ps: PS, options, compId: string) {
    return {
        pageId: _.get(options, 'page.pageId'),
        title: _.get(options, 'page.title'),
        styleId: getNewCompStyleId(ps, options.copyStyle, options.styleId, compId),
        layout: getLayoutFromOptions(options),
        isHidden: _.get(options, 'page.isHidden'),
        requireLogin: _.get(options, 'page.requireLogin')
    }
}

function provisionMultiSection(ps: PS, pageToAddRef: Pointer, options, appData, compId: string) {
    _.assign(options, getSectionParams(ps, options, compId))
    tpaComponentService.provisionMultiSection(ps, pageToAddRef, appData.appDefinitionId, options)
}

function handleSubSection(ps: PS, options, compId: string, appData) {
    _.assign(options, getSectionParams(ps, options, compId))
    ps.setOperationsQueue.asyncPreDataManipulationComplete(appData)
}

const provisionPageComponent = function (ps: PS, pageToAddRef: Pointer, appData, compId: string, options) {
    const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(
        ps,
        appData.appDefinitionId,
        _.get(options, 'page.pageId')
    )
    if (widgetData) {
        if (clientSpecMapService.isMultiSectionInstanceEnabled(appData, widgetData.widgetId)) {
            provisionMultiSection(ps, pageToAddRef, options, appData, compId)
        } else if (
            !installedTpaAppsOnSiteService.isSectionInstalledByTpaPageId(
                ps,
                appData.appDefinitionId as AppDefinitionId,
                _.get(widgetData, 'appPage.id')
            )
        ) {
            handleSubSection(ps, options, compId, appData)
        } else {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: 'Page already installed.'
            })
        }
    } else {
        ps.setOperationsQueue.asyncPreDataManipulationComplete({
            onError: 'No page found.'
        })
    }
}

const getLayoutFromOptions = function (options) {
    const layout: Layout = {} as Layout
    if (options) {
        if (_.isNumber(options.x)) {
            layout.x = options.x
        }
        if (_.isNumber(options.y)) {
            layout.y = options.y
        }
        if (_.isNumber(options.width)) {
            layout.width = options.width
        }
        if (_.isNumber(options.height)) {
            layout.height = options.height
        }
    }
    return layout
}

const getCompRef = function (ps: PS, appDefId: string, options: ProvisionAppOptions) {
    if (options.componentType === compTypes.WIDGET) {
        const pageId = ps.siteAPI.getPrimaryPageId()
        const pagePointer = ps.pointers.page.getPagePointer(pageId)
        return component.getComponentToAddRef(ps, pagePointer, options, _.get(options, 'optionalCustomId'))
    }
    return page.getPageIdToAdd(ps)
}

const provisionComp = function (ps: PS, options: any = {}) {
    const {appDefinitionId} = options
    if (appDefinitionId) {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (clientSpecMapService.isAppActive(ps, appData)) {
            options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
            provisionComponentImpl(ps, options.componentToAddRef, options.compId, appData, options)
        } else {
            const compOptions = _.clone(options)
            _.forEach(_.keys(options), function (key) {
                delete options[key]
            })
            options.appFlow = true
            options.componentOptions = compOptions
            options.componentOptions.layout = getLayout(compOptions)
            options.callback = compOptions.callback
            provisionAndAddToDocument(ps, appDefinitionId, options)
        }
    } else {
        ps.setOperationsQueue.asyncPreDataManipulationComplete({
            onError: 'appDefinitionId is mandatory.'
        })
    }
}

const isWidgetInstalled = function (appData, options) {
    const widgetData = _.find(appData.widgets, function (widget) {
        if (options.componentType === compTypes.WIDGET) {
            return widget.tpaWidgetId === options.widget.tpaWidgetId
        } else if (options.componentType === compTypes.PAGE) {
            return _.get(widget, 'appPage.id') === options.page.pageId
        }
        return null
    })
    return !!_.get(widgetData, 'autoAddToSite')
}

const getCompAdded = function (ps: PS, appData, widgetData, callback: Callback1<any>) {
    if (widgetData) {
        const comps = installedTpaAppsOnSiteService.getAllAppCompsByAppDefIds(ps, [appData.appDefinitionId])
        return _.find(comps, {widgetId: _.get(widgetData, 'widgetId')}) || _.find(comps, {widgetId: null})
    }
    callback({
        onError: 'No widget found.'
    })
    return null
}

const triggerCallback = function (ps: PS, options, appData, callback: Callback1<any>) {
    let widgetData, comp
    if (options.componentType === compTypes.WIDGET) {
        widgetData = clientSpecMapService.getWidgetDataFromTPAWidgetId(
            ps,
            appData.appDefinitionId,
            options.widget.tpaWidgetId
        )
        comp = getCompAdded(ps, appData, widgetData, callback)
        if (comp) {
            const compRef = componentDetectorAPI.getComponentById(ps, comp.id)
            tpaWidgetService.invokeWidgetCallback({callback}, compRef, true)
        }
    } else if (options.componentType === compTypes.PAGE) {
        widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, options.page.pageId)
        comp = getCompAdded(ps, appData, widgetData, callback)
        if (comp) {
            const pageRef = ps.pointers.components.getPage(comp.pageId, ps.siteDataAPI.siteData.getViewMode())
            tpaSectionService.invokeSectionCallback({callback}, pageRef, comp.id, true)
        }
    }
}

const addComponent = function (
    ps: PS,
    data,
    options: {
        componentToAddRef: CompRef
        componentType: string
        compId?: string
        callback: Callback1<any>
        appFlow?: any
        widgetId?: any
        componentOptions?: {componentType: string; appDefinitionId: string}
    }
) {
    if (data.onError) {
        if (_.isFunction(options.callback)) {
            options.callback(data)
        }
    } else {
        if (options.appFlow) {
            const callback = options.callback || _.noop
            options.callback = () => {
                const appData = clientSpecMapService.getAppDataByAppDefinitionId(
                    ps,
                    options.componentOptions.appDefinitionId
                )
                if (isWidgetInstalled(appData, options.componentOptions)) {
                    triggerCallback(ps, options.componentOptions, appData, callback)
                } else {
                    const params = {
                        componentType: options.componentOptions.componentType,
                        componentToAddRef: getCompRef(
                            ps,
                            options.componentOptions.appDefinitionId,
                            options.componentOptions
                        ),
                        callback
                    }
                    if (params.componentType === compTypes.WIDGET) {
                        _.assign(params, getWidgetParams(ps, appData, options.componentOptions, options.compId))
                    } else if (params.componentType === compTypes.PAGE) {
                        _.assign(params, getSectionParams(ps, options.componentOptions, options.compId))
                    }
                    addComponent(ps, data, params)
                }
            }
        }

        switch (options.componentType) {
            case compTypes.WIDGET:
                tpaComponentService.addAppWrapper(
                    tpaConstants.TYPE.TPA_WIDGET,
                    ps,
                    data,
                    options.componentToAddRef,
                    data.appDefinitionId,
                    options,
                    undefined,
                    options.callback
                )
                break
            case compTypes.PAGE:
                if (options.appFlow) {
                    tpaComponentService.addSection(ps, data, options.componentToAddRef, data.appDefinitionId, options)
                } else {
                    const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(
                        ps,
                        data.appDefinitionId,
                        _.get(options, 'pageId')
                    )
                    if (widgetData) {
                        if (clientSpecMapService.isMultiSectionInstanceEnabled(data, widgetData.widgetId)) {
                            tpaComponentService.addMultiSection(
                                ps,
                                data,
                                options.componentToAddRef,
                                data.appDefinitionId,
                                options
                            )
                        } else if (
                            !installedTpaAppsOnSiteService.isSectionInstalledByTpaPageId(
                                ps,
                                data.appDefinitionId as AppDefinitionId,
                                _.get(widgetData, 'appPage.id')
                            )
                        ) {
                            tpaComponentService.addSubSection(
                                ps,
                                options.componentToAddRef,
                                data.appDefinitionId,
                                options
                            )
                        }
                    }
                }
                break
        }
    }
}

export interface ProvisionAppOptions {
    componentType?: string
    componentToAddRef?: Pointer
    callback?: Callback
    layout?: Partial<Point>
    pageId?: string
    x?: number
    y?: number
}

const provisionAndAddToDocument = function (
    ps: PS,
    appDefinitionId: AppDefinitionId,
    options: ProvisionAppOptions = {}
) {
    if (!appDefinitionId) {
        ps.setOperationsQueue.asyncPreDataManipulationComplete({
            onError: 'appDefinitionId is mandatory.'
        })
        return
    }
    const {callback} = options
    options.callback = (...args) => {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        const version = clientSpecMapDS.getAppVersion(appData)
        ps.extensionAPI.appsInstallState.setAppInstalled(appDefinitionId, {version}, 'provisionAndAddToDocument')
        return callback(...args)
    }

    // eslint-disable-next-line promise/prefer-await-to-then
    appMarketService.getAppMarketDataAsync(ps, appDefinitionId).then(function (marketData: any) {
        if (!marketData || marketData.error) {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: `Application with ${appDefinitionId} appDefinitionId do not exist.`
            })
            return
        }
        options.componentType = marketData.hasSection ? compTypes.PAGE : compTypes.WIDGET
        options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
        if (options.componentType === compTypes.WIDGET) {
            options.layout = getWidgetLayout(options.layout)
            if (options.pageId && !ps.pointers.page.isExists(options.pageId)) {
                delete options.pageId
            }
            tpaComponentService.provisionWidget(ps, options.componentToAddRef, appDefinitionId, options)
        } else if (options.componentType === compTypes.PAGE) {
            if (tpaSectionService.alreadyInstalled(ps, appDefinitionId)) {
                ps.setOperationsQueue.asyncPreDataManipulationComplete({
                    onError: 'Section already installed'
                })
            } else {
                options.layout = getSectionLayoutFromOptions(options)
                tpaComponentService.provisionSection(
                    ps,
                    options.componentToAddRef,
                    appDefinitionId,
                    options,
                    options.callback
                )
            }
        }
    })
}

const addApp = function (ps: PS, data, appDefinitionId: AppDefinitionId, options) {
    if (data.onError) {
        if (_.isFunction(options.callback)) {
            options.callback(data)
        }
    } else {
        switch (options.componentType) {
            case compTypes.WIDGET:
                tpaComponentService.addAppWrapper(
                    tpaConstants.TYPE.TPA_WIDGET,
                    ps,
                    data,
                    options.componentToAddRef,
                    appDefinitionId,
                    options
                )
                break
            case compTypes.PAGE:
                tpaComponentService.addSection(ps, data, options.componentToAddRef, appDefinitionId, options)
                break
        }
    }
}

const getWidgetLayout = function (options: ProvisionAppOptions): Partial<Point> {
    let layout: Partial<Point> = {}
    if (options && _.isNumber(options.x) && _.isNumber(options.y)) {
        layout = {
            x: options.x,
            y: options.y
        }
    }
    return layout
}

const getLayout = function (componentOptions) {
    if (_.get(componentOptions, 'componentType') === compTypes.WIDGET) {
        return getWidgetLayout(componentOptions)
    }
    return getSectionLayoutFromOptions(componentOptions)
}

const getSectionLayoutFromOptions = function (options?: Partial<Layout>): Partial<Layout> {
    const layout: Partial<Layout> = {}
    if (options) {
        if (_.isNumber(options.x)) {
            layout.x = options.x
        }
        if (_.isNumber(options.y)) {
            layout.y = options.y
        }
        if (_.isNumber(options.width)) {
            layout.width = options.width
        }
        if (_.isNumber(options.height)) {
            layout.height = options.height
        }
    }
    return layout
}

const getAddTPAInteractionParams = (ps: PS, data, appDefinitionId: AppDefinitionId) => ({app_id: appDefinitionId})

const getAddTPAAppInteractionParams = (ps: PS, appDefinitionId: AppDefinitionId) => ({app_id: appDefinitionId})

const addWidgetAfterProvision = (
    ps: PS,
    data,
    appDefinitionId: AppDefinitionId,
    options,
    onSuccess,
    onError,
    additionalTags?: Record<string, any>
) => {
    ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_WIDGET_AFTER_PROVISION, {
        tags: {
            appDefinitionId,
            ...additionalTags
        },
        extras: {app_id: appDefinitionId, options, firstInstall: data?.firstInstall}
    })
    options.componentType = compTypes.WIDGET
    options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
    options.layout = getLayoutFromOptions(options.layout)
    if (options.pageId && !ps.pointers.page.isExists(options.pageId)) {
        delete options.pageId
    }

    const wrapOnSuccess = (...args: any[]) => {
        ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_WIDGET_AFTER_PROVISION, {
            tags: {
                appDefinitionId,
                ...additionalTags
            },
            extras: {app_id: appDefinitionId, options, firstInstall: data?.firstInstall}
        })
        onSuccess(...args)
    }

    const wrapOnError = (...args: any[]) => {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                errorType: dsConstants.PLATFORM_ERRORS.ADD_WIDGET_AFTER_PROVISION,
                message: 'failed to add widget after provision',
                tags: {
                    appDefinitionId,
                    ...additionalTags
                },
                extras: {
                    errName: dsConstants.PLATFORM_ERRORS.ADD_WIDGET_AFTER_PROVISION,
                    app_id: appDefinitionId,
                    originalError: {...args},
                    firstInstall: data?.firstInstall
                }
            })
        )
        onError(...args)
    }

    tpaComponentService.addAppWrapper(
        tpaConstants.TYPE.TPA_WIDGET,
        ps,
        data,
        options.componentToAddRef,
        appDefinitionId,
        options,
        wrapOnSuccess,
        wrapOnError,
        false
    )
}

const addSectionAfterProvision = (
    ps: PS,
    data,
    appDefinitionId: AppDefinitionId,
    options,
    onSuccess,
    onError,
    additionalTags?: Record<string, any>
) => {
    const eventGuid = _.random(10000, true)
    ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_SECTION_AFTER_PROVISION, {
        eventGuid,
        tags: {
            appDefinitionId,
            ...additionalTags
        },
        extras: {app_id: appDefinitionId, options, firstInstall: data?.firstInstall}
    })
    options.componentType = compTypes.PAGE
    options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
    options.layout = getSectionLayoutFromOptions(options)
    const sectionId = `${tpaConstants.TYPE.TPA_SECTION}_${santaCoreUtils.guidUtils.getUniqueId(undefined, undefined)}`
    options.sectionId = sectionId
    options = _.merge(options, _.get(data, 'additionalOptions'))
    const appData = _.omit(data, 'additionalOptions')
    _.assign(options.componentToAddRef, {sectionId})

    const wrapOnSuccess = (...args: any[]) => {
        ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_SECTION_AFTER_PROVISION, {
            eventGuid,
            tags: {
                appDefinitionId,
                ...additionalTags
            },
            extras: {app_id: appDefinitionId, options, firstInstall: data?.firstInstall}
        })
        onSuccess(...args)
    }

    const wrapOnError = (...args: any[]) => {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                errorType: dsConstants.PLATFORM_ERRORS.ADD_SECTION_AFTER_PROVISION,
                message: 'failed to add section after provision',
                tags: {
                    appDefinitionId,
                    ...additionalTags
                },
                extras: {
                    errName: dsConstants.PLATFORM_ERRORS.ADD_SECTION_AFTER_PROVISION,
                    app_id: appDefinitionId,
                    originalError: {...args},
                    firstInstall: data?.firstInstall
                }
            })
        )
        onError(...args)
    }

    tpaComponentService.addAppWrapper(
        tpaConstants.TYPE.TPA_SECTION,
        ps,
        appData,
        options.componentToAddRef,
        appDefinitionId,
        options,
        wrapOnSuccess,
        wrapOnError,
        false
    )
}

export default {
    provisionComp,
    addComponent,
    addWidgetAfterProvision,
    addSectionAfterProvision,
    provisionApp: provisionAndAddToDocument,
    getAddTPAInteractionParams,
    getAddTPAAppInteractionParams,
    addApp
}
