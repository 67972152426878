import _ from 'lodash'
import type {DeserializeComponentParams} from '../types'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import {updateComponentScopedValues} from './scopedValues'
import {addIdToMap, getCustomId} from '../utils'
import {addNamespaceToStructure} from '../componentDeserializationStructure'
import {DATA_TYPES} from '../../../../constants/constants'

export const FEATURES_DATA_TYPE = 'features'

const {
    namespaceMapping: {getNamespaceConfig, featureNamespaces}
} = jsonSchemas

export const updateComponentFeaturesStructure = (deserializationParams: DeserializeComponentParams) => {
    const {createExtArgs, compStructure, pageId, mappers, customId} = deserializationParams
    {
        const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}

        _.forEach(featureNamespaces, featureNamespace => {
            const {query, supportsVariants} = getNamespaceConfig(featureNamespace)
            if (supportsVariants) {
                updateComponentScopedValues(createExtArgs, compStructure, DATA_TYPES[featureNamespace], pageId, mappers)
            } else {
                deserializationParams.customId = getCustomId(oldToNewIdMap, compStructure[featureNamespace], customId)
                const featureQuery = addNamespaceToStructure(
                    deserializationParams,
                    compStructure[featureNamespace],
                    featureNamespace,
                    oldToNewIdMap
                )

                if (featureQuery && query) {
                    compStructure[query] = featureQuery
                    addIdToMap(compStructure[featureNamespace], featureQuery, oldToNewIdMap)
                }
            }
        })
    }
}
