'use strict'

const _ = require('lodash')
const dataUtils = require('./dataUtils')

const IMAGEX_EFFECTS_MAP = {
    parallax: 'ImageParallax',
    fixed: 'BgReveal',
    none: ''
}

const getImageXScrollEffectsFromCompStructure = (compStructure, data) => {
    const {dataQuery} = compStructure
    const dataObject = data.document_data[dataUtils.stripHashIfExists(dataQuery)]
    if (!dataObject || !_.isArray(dataObject.scopedData)) {
        return
    }
    const {scopedData} = dataObject

    // in case there is no default we need to add an empty default
    const extendedScopedData = scopedData.find(datum => !datum.breakpoint) ? scopedData : [{}].concat(scopedData)

    if (extendedScopedData.every(datum => !datum.scrollEffect || datum.scrollEffect === 'none')) {
        return
    }
    return extendedScopedData.map(datum => ({
        action: 'bgScrub',
        name: IMAGEX_EFFECTS_MAP[datum.scrollEffect || 'none'],
        viewMode: datum.breakpoint ? undefined : 'DESKTOP',
        breakpointVariantId: dataUtils.stripHashIfExists(datum.breakpoint)
    }))
}

const cleanImageXScrollEffects = (compStructure, data) => {
    const {dataQuery} = compStructure
    const dataObject = data.document_data[dataUtils.stripHashIfExists(dataQuery)]
    if (!dataObject || !_.isArray(dataObject.scopedData)) {
        return
    }

    _.forEach(dataObject.scopedData, scopedDataItem => {
        delete scopedDataItem.scrollEffect
    })
}

module.exports = {
    getImageXScrollEffectsFromCompStructure,
    cleanImageXScrollEffects
}
