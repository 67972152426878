import {SemanticTypes} from '../../preprocess/conversionDataBuilders'

export const TOP_BOTTOM_RELATION = {
    [SemanticTypes.TEXT]: {
        [SemanticTypes.BOX]: 24,
        [SemanticTypes.IMAGE]: 24,
        [SemanticTypes.TEXT]: 12,
        [SemanticTypes.BUTTON]: 32,
        [SemanticTypes.HORIZONTAL_LINE]: 32,
        [SemanticTypes.SVG]: 16
    },
    [SemanticTypes.IMAGE]: {
        [SemanticTypes.BOX]: 24,
        [SemanticTypes.IMAGE]: 24,
        [SemanticTypes.TEXT]: 12,
        [SemanticTypes.BUTTON]: 32,
        [SemanticTypes.HORIZONTAL_LINE]: 32,
        [SemanticTypes.SVG]: 24
    },
    [SemanticTypes.BOX]: {
        [SemanticTypes.BOX]: 24,
        [SemanticTypes.IMAGE]: 24,
        [SemanticTypes.TEXT]: 24,
        [SemanticTypes.BUTTON]: 32,
        [SemanticTypes.HORIZONTAL_LINE]: 32,
        [SemanticTypes.SVG]: 24
    },
    [SemanticTypes.HORIZONTAL_LINE]: {
        [SemanticTypes.BOX]: 32,
        [SemanticTypes.IMAGE]: 32,
        [SemanticTypes.TEXT]: 32,
        [SemanticTypes.BUTTON]: 32,
        [SemanticTypes.HORIZONTAL_LINE]: 32,
        [SemanticTypes.SVG]: 32
    },
    [SemanticTypes.BUTTON]: {
        [SemanticTypes.BOX]: 32,
        [SemanticTypes.IMAGE]: 32,
        [SemanticTypes.TEXT]: 32,
        [SemanticTypes.BUTTON]: 32,
        [SemanticTypes.HORIZONTAL_LINE]: 32,
        [SemanticTypes.SVG]: 32
    },
    [SemanticTypes.SVG]: {
        [SemanticTypes.BOX]: 32,
        [SemanticTypes.IMAGE]: 32,
        [SemanticTypes.TEXT]: 12,
        [SemanticTypes.BUTTON]: 32,
        [SemanticTypes.HORIZONTAL_LINE]: 32,
        [SemanticTypes.SVG]: 24
    }
}
export const TOP_PADDING = {
    [SemanticTypes.SECTION]: 40,
    [SemanticTypes.BOX]: 24
}
export const BOTTOM_PADDING = {
    [SemanticTypes.SECTION]: 40,
    [SemanticTypes.BOX]: 24
}
export const BUTTON_FONT_SIZE_TO_HEIGHT = {
    16: 48
}
export const LIST_ITEM_PADDING_TOP = 40
export const DEFAULT_PADDING_BOTTOM = 40
