import type {Experiment} from '@wix/document-services-types'
import type {DocumentManager} from '@wix/document-manager-core'
import type {MeshLayoutExtApi} from '../../../meshLayout/meshLayout'
import type {PageMigrator} from '../dataMigrationRunner'

const migratePage = (documentManager: DocumentManager, pageId: string, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen('dm_meshLayout')) {
        return
    }

    if (!experimentInstance.isOpen('dm_meshLayoutSafeguard')) {
        throw new Error('Mesh migration is not allowed, to bypass this safeguard open dm_meshLayoutSafeguard in url')
    }

    const {meshLayout} = documentManager.extensionAPI as MeshLayoutExtApi

    if (!meshLayout.siteQualifiesForMeshLayout()) {
        return
    }

    if (!meshLayout.pageUsesMeshLayout(pageId)) {
        meshLayout.convertToMeshLayout(pageId)
    }
}

const name = 'meshLayoutMigrator'
const version = 0
const experimentalVersions = [
    {version: 1, experiment: 'dm_meshLayout'},
    {version: 2, experiment: 'dm_meshLayoutSafeguard'}
]
export const meshLayoutMigrator: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions
}
