export interface ResourceLoaderDefinition {
    resourceType: string
    load(resourceId: string): Promise<void>
    getAvailableResources(): string[]
}
export interface ResourceStatus {
    status: 'loaded' | 'unloaded' | 'pending' | 'error'
}

export interface ResourceStatusError extends ResourceStatus {
    status: 'error'
    reason: string
}
export interface ResourceLoaderAPI {
    registerResourceLoader(resourceLoaderDefinition: ResourceLoaderDefinition): void
    loadResource(resourceType: string, resourceId: string): Promise<void>
    listResources(resourceType: string): string[]
    getResourceStatus(resourceType: string, resourceId: string): ResourceStatus | ResourceStatusError
}
const createResourceLoader = (): ResourceLoaderAPI => {
    const resourceLoaders = new Map<string, ResourceLoaderDefinition>()
    const resourceStatusMaps: Record<string, Map<string, ResourceStatus | ResourceStatusError>> = {}
    const listResources = (resourceType: string) => {
        if (!resourceLoaders.has(resourceType)) {
            throw new Error(`no resourceLoader defined for resourceType {${resourceType}}`)
        }
        const loader = resourceLoaders.get(resourceType)!
        return loader.getAvailableResources()
    }
    const loadResource = async (resourceType: string, resourceId: string) => {
        if (!resourceLoaders.has(resourceType)) {
            throw new Error(`no resourceLoader defined for resourceType {${resourceType}}`)
        }
        const loader = resourceLoaders.get(resourceType)!
        if (!loader.getAvailableResources().includes(resourceId)) {
            throw new Error(`no resourceLoader with id {${resourceId}} available for resourceType {${resourceType}}`)
        }
        resourceStatusMaps[resourceType].set(resourceId, {status: 'pending'})
        try {
            await loader.load(resourceId)
        } catch (e: any) {
            resourceStatusMaps[resourceType].set(resourceId, {status: 'error', reason: e.message})
            throw e
        }
        resourceStatusMaps[resourceType].set(resourceId, {status: 'loaded'})
    }
    const registerResourceLoader = async (resourceLoaderDefinition: ResourceLoaderDefinition) => {
        resourceStatusMaps[resourceLoaderDefinition.resourceType] = new Map<string, ResourceStatus>()
        resourceLoaders.set(resourceLoaderDefinition.resourceType, resourceLoaderDefinition)
    }
    const getResourceStatus = (resourceType: string, resourceId: string) => {
        const status = resourceStatusMaps[resourceType].get(resourceId)
        if (!status) {
            const loader = resourceLoaders.get(resourceType)!
            if (!loader.getAvailableResources().includes(resourceId)) {
                throw new Error(
                    `no resourceLoader with id {${resourceId}} available for resourceType {${resourceType}}`
                )
            }
            return {status: 'unloaded'} satisfies ResourceStatus
        }
        return status
    }

    return {
        registerResourceLoader,
        loadResource,
        listResources,
        getResourceStatus
    }
}

export {createResourceLoader}
