import type {
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry,
    StructureStageData
} from '../../types'
import {
    BOTTOM_PADDING,
    DEFAULT_PADDING_BOTTOM,
    LIST_ITEM_PADDING_TOP,
    TOP_BOTTOM_RELATION,
    TOP_PADDING
} from './constants'
import {SemanticTypes} from '../../preprocess/conversionDataBuilders'
import {VIRTUAL_TAG} from '../grouping/constants'
import type {MeasurementsEntryItem, StructureEntryItem, StyleEntryItem} from '../../conversionDal/types'
import {
    getMeasurementsPointer,
    getStructurePointer,
    getStylePointer,
    getVirtualStructurePointer
} from '../../conversionDal/pointerUtils'

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const getMarginsBetweenComps = (
        previousComponent: StructureEntryItem,
        currentComponent: StructureEntryItem
    ): number =>
        TOP_BOTTOM_RELATION[currentComponent.semanticType]?.[previousComponent.semanticType] ?? DEFAULT_PADDING_BOTTOM

    const verticalScaleAndSize = ({conversionDal, initialSnapshot, structureIterators}: StructureStageData) => {
        structureIterators.forEachComponentPostOrder((component: StructureEntryItem) => {
            const componentPointer = getStructurePointer({id: component.id})
            const componentMeasurementsPointer = getMeasurementsPointer(componentPointer)
            const componentStylePointer = getStylePointer(componentPointer)
            const containerPointer = getStructurePointer({id: component.parent})
            const container = conversionDal.get(containerPointer)

            const getFitToContentHeight = () => {
                const lastCompChildMeasurementsPointer = getMeasurementsPointer({
                    id: conversionDal.get(componentPointer).components!.at(-1)!
                })
                const lastCompChildMeasurements: MeasurementsEntryItem = conversionDal.get(
                    lastCompChildMeasurementsPointer
                )

                return (
                    lastCompChildMeasurements.height +
                    lastCompChildMeasurements.y +
                    BOTTOM_PADDING[component.semanticType]
                )
            }

            const getSectionHeight = () => {
                if (!component.components?.length) {
                    return 200
                }

                return getFitToContentHeight()
            }

            const getBoxHeight = () => {
                const originalMeasurements = initialSnapshot.getValue(componentMeasurementsPointer)

                if (component.components?.length) {
                    if (originalMeasurements.height < 101) {
                        return Math.max(getFitToContentHeight(), 100)
                    }

                    return Math.max(getFitToContentHeight(), 200)
                }

                if (originalMeasurements.height < 101) {
                    return 100
                }

                return 200
            }

            const getButtonHeight = () => {
                const {fontSize}: StyleEntryItem = conversionDal.get(componentStylePointer)

                if (fontSize! < 17) {
                    return 48
                }

                if (fontSize! < 22) {
                    return 56
                }

                if (fontSize! < 27) {
                    return 64
                }

                return 72
            }

            const getImageHeight = () => {
                const {width, height} = initialSnapshot.getValue(componentMeasurementsPointer)
                const maxHeight = width < 201 ? 600 : 300

                return Math.max(Math.min(height, maxHeight), 48)
            }

            const getAspectRatioHeight = () => {
                const originalMeasurements = initialSnapshot.getValue(componentMeasurementsPointer)
                const {width} = conversionDal.get(componentMeasurementsPointer)

                return originalMeasurements.height * (width / originalMeasurements.width)
            }

            const getComponentHeight = () => {
                if (component.semanticType === SemanticTypes.SECTION) {
                    return getSectionHeight()
                }

                if (component.semanticType === SemanticTypes.BOX) {
                    return getBoxHeight()
                }

                if (component.semanticType === SemanticTypes.BUTTON) {
                    return getButtonHeight()
                }

                if (component.semanticType === SemanticTypes.IMAGE) {
                    return getImageHeight()
                }

                if (
                    component.semanticType === SemanticTypes.SVG ||
                    component.semanticType === SemanticTypes.EMBEDDED_MEDIA
                ) {
                    return getAspectRatioHeight()
                }

                if (component.semanticType === SemanticTypes.TEXT) {
                    return 48
                }

                return initialSnapshot.getValue(componentMeasurementsPointer).height
            }

            const getYValue = () => {
                if (!container) {
                    return 0
                }

                const orderedContainerChildren = container.components!
                const currentCompIndex = orderedContainerChildren.indexOf(component.id)
                const isFirstChild = currentCompIndex === 0
                const virtualParent = conversionDal.get(getVirtualStructurePointer({id: component.virtualParentId}))
                const isFirstInListItem =
                    virtualParent.componentType === VIRTUAL_TAG && virtualParent.components!.indexOf(component.id) === 0

                if (isFirstChild) {
                    return TOP_PADDING[container.semanticType]
                }

                const previousSiblingPointer = getStructurePointer({id: orderedContainerChildren[currentCompIndex - 1]})
                const previousSibling = conversionDal.get(previousSiblingPointer)
                const previousSiblingMeasurementsPointer = getMeasurementsPointer(previousSiblingPointer)
                const previousSiblingMeasurements = conversionDal.get(previousSiblingMeasurementsPointer)

                if (isFirstInListItem) {
                    return previousSiblingMeasurements.y + previousSiblingMeasurements.height + LIST_ITEM_PADDING_TOP
                }

                return (
                    previousSiblingMeasurements.y +
                    previousSiblingMeasurements.height +
                    getMarginsBetweenComps(previousSibling, component)
                )
            }

            const y = getYValue()
            const height = getComponentHeight()

            conversionDal.modify(componentMeasurementsPointer, (value: MeasurementsEntryItem) => ({
                ...value,
                y,
                height
            }))
        })
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.POSITION, verticalScaleAndSize)
    }

    const name = 'verticalScaleAndSize'
    return {register, name, dependencies: []}
}
