import type {HeuristicRegistry} from '../mobileAlgo'
import type {BaseStageData, PluginContextHelper, PluginHeuristicsRegistry, PluginStageHandler, Stage} from '../types'
import {createPluginContext} from './pluginContext'

export const createPluginHeuristicsRegistry = (
    heuristicRegistryApi: HeuristicRegistry,
    contextHelper: PluginContextHelper
): PluginHeuristicsRegistry => {
    const register = <T extends BaseStageData>(stage: Stage<T>, handler: PluginStageHandler<T>) => {
        const handlerToRegister = async (d: T) => {
            if (contextHelper.isEnabled(d.globalContext)) {
                await handler(d, createPluginContext(contextHelper, d.globalContext))
            }
        }
        heuristicRegistryApi.registerHeuristic(stage, handlerToRegister)
    }

    return {register}
}
