import * as constants from '../../../constants/constants'
import type {DeserializeComponentParams} from './types'
import {updateComponentPropsStructure} from './namespaces/props'
import {updateComponentMobileHintsStructure} from './namespaces/mobileHints'
import {updateComponentDataStructure} from './namespaces/data'
import {updateComponentAnchorStructure} from './namespaces/anchors'
import {updateComponentConnectionsStructure} from './namespaces/connections'
import {updateComponentPatternsStructure} from './namespaces/patterns'
import {updateComponentBehaviorsStructure} from './namespaces/behaviors'
import {updateComponentStylesStructure} from './namespaces/style'
import {updateComponentLayoutStructure} from './namespaces/layout'
import {updateComponentDesignStructure} from './namespaces/design'
import {updateComponentReactionsStructure} from './namespaces/reactions'
import {updateComponentTransitionsStructure} from './namespaces/transitions'
import {updateComponentTransformationsStructure} from './namespaces/transformations'
import {FEATURES_DATA_TYPE, updateComponentFeaturesStructure} from './namespaces/features'
import {updateComponentEffectsStructure} from './namespaces/effects'

const {DATA_TYPES} = constants

type DeserializeComponentImplementation = (deserializationParams: DeserializeComponentParams) => void

const namespaceMapping: Map<string, DeserializeComponentImplementation> = new Map([
    [DATA_TYPES.data, updateComponentDataStructure],
    [DATA_TYPES.prop, updateComponentPropsStructure],
    [DATA_TYPES.mobileHints, updateComponentMobileHintsStructure],
    [DATA_TYPES.anchors, updateComponentAnchorStructure],
    [DATA_TYPES.connections, updateComponentConnectionsStructure],
    [DATA_TYPES.patterns, updateComponentPatternsStructure],
    [DATA_TYPES.theme, updateComponentStylesStructure],
    [DATA_TYPES.layout, updateComponentLayoutStructure],
    [DATA_TYPES.design, updateComponentDesignStructure],
    [DATA_TYPES.transitions, updateComponentTransitionsStructure],
    [DATA_TYPES.reactions, updateComponentReactionsStructure],
    [DATA_TYPES.transformations, updateComponentTransformationsStructure],
    [FEATURES_DATA_TYPE, updateComponentFeaturesStructure],
    [DATA_TYPES.effects, updateComponentEffectsStructure],
    [DATA_TYPES.behaviors, updateComponentBehaviorsStructure]
])

export const deserializeComponentData = (deserializationParams: DeserializeComponentParams) => {
    namespaceMapping.forEach(updateNamespaceImpl => {
        updateNamespaceImpl(deserializationParams)
    })
}
