import type {Extension, InitializeExtArgs, CreateExtArgs, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointer, RoutersDefinition, IConnectionItem} from '@wix/document-services-types'
import * as atomicScopesUtils from './atomicScopesUtils'
import _ from 'lodash'
import * as constants from '../../constants/constants'
import type {PageExtensionAPI} from '../page'
import type {DataExtensionAPI} from '../data'
import {ComponentAfterAddDataEvent, COMPONENTS_HOOKS} from '../components/hooks'
import type {HooksExtensionApi} from '../hooks/hooks'

const {
    ATOMIC_SCOPES: {URI_PREFIX}
} = constants
const NICKNAMES_KEY_PREFIX = 'wcid_'
const CLEAR_NICKNAMES_DEBOUNCE_TIME = 60000
const DELETE_NICKNAMES_COUNT_THRESHOLD = 200
export interface AtomicScopesAPI extends ExtensionAPI {
    getRouterAtomicScopeId(routerPointer: Pointer): string
    removeAtomicScope(id: string): void
    changeAtomicScope(oldId: string, newId: string): void
    addAtomicScope(id: string): void
    getAtomicScopePointer(id: string): Pointer
    getPageUriAtomicScopeId(id: string): string
}

export interface AtomicScopesExtAPI extends ExtensionAPI {
    atomicScopes: AtomicScopesAPI
}
const createExtension = (): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): AtomicScopesExtAPI => ({
        atomicScopes: {
            getRouterAtomicScopeId: (routerPointer: Pointer) =>
                atomicScopesUtils.getRouterAtomicScopeId(createExtArgs, routerPointer),
            removeAtomicScope: (id: string) => atomicScopesUtils.removeAtomicScope(createExtArgs, id),
            changeAtomicScope: (oldId: string, newId: string) =>
                atomicScopesUtils.changeAtomicScope(createExtArgs, oldId, newId),
            addAtomicScope: (id: string) => atomicScopesUtils.addAtomicScope(createExtArgs, id),
            getAtomicScopePointer: (id: string) => atomicScopesUtils.getAtomicScopePointer(createExtArgs, id),
            getPageUriAtomicScopeId: (pageId: string) =>
                atomicScopesUtils.getPageUriAtomicScopeId(createExtArgs, pageId)
        }
    })

    const initialize = async (extArgs: InitializeExtArgs) => {
        const {dal} = extArgs
        const beforeRouterRemove = (routerPointer: Pointer) => {
            const id = atomicScopesUtils.getRouterAtomicScopeId(extArgs, routerPointer)
            atomicScopesUtils.removeAtomicScope(extArgs, id)
        }

        const beforePageDataChanged = (pagePointer: Pointer, dataToChange: Record<string, any>) => {
            if (!dataToChange) {
                return
            }
            const {pageUriSEO} = dataToChange
            const {page} = extArgs.extensionAPI as PageExtensionAPI
            const currentPageUriSEO = page.getPageUriSEO(pagePointer.id)
            if (pageUriSEO && !_.isEqual(pageUriSEO, currentPageUriSEO)) {
                const newId = `${URI_PREFIX}-${pageUriSEO}`
                const oldId = `${URI_PREFIX}-${currentPageUriSEO}`
                atomicScopesUtils.changeAtomicScope(extArgs, oldId, newId)
            }
        }

        const beforeRouterUpdate = (routerPointer: Pointer, dataToChange: RoutersDefinition) => {
            if (!dataToChange) {
                return
            }
            const {prefix} = dataToChange
            const currentRouterData = dal.get(routerPointer)
            const currentPrefix = currentRouterData.prefix
            if (prefix && !_.isEqual(prefix, currentPrefix)) {
                const newId = `${URI_PREFIX}-${prefix}`
                const oldId = `${URI_PREFIX}-${currentPrefix}`
                atomicScopesUtils.changeAtomicScope(extArgs, oldId, newId)
            }
        }
        const afterRouterAdd = (routerPointer: Pointer) => {
            const id = atomicScopesUtils.getRouterAtomicScopeId(extArgs, routerPointer)
            atomicScopesUtils.addAtomicScope(extArgs, id)
        }

        const getAllNicknameKeys = () =>
            (extArgs.extensionAPI as DataExtensionAPI).data.queryKeys(
                'atomicScopes',
                null,
                (value: any, key: string): boolean => value && key.startsWith(NICKNAMES_KEY_PREFIX)
            )
        const clearAtomicNicknames = () => {
            const keys = getAllNicknameKeys()
            if (keys.length >= DELETE_NICKNAMES_COUNT_THRESHOLD) {
                keys.forEach((id: string) => {
                    atomicScopesUtils.removeAtomicScope(extArgs, id)
                })
            }
        }

        const afterPageAdd = (pagePointer: Pointer) => {
            const id = atomicScopesUtils.getPageUriAtomicScopeId(extArgs, pagePointer.id)
            atomicScopesUtils.addAtomicScope(extArgs, id)
        }
        const getAtomicScopeNicknameKey = (nickname: string) => `${NICKNAMES_KEY_PREFIX}${nickname}`

        const debounce = (() => {
            let timeoutId: any
            return (callback: Function, delay: number) => {
                clearTimeout(timeoutId)
                timeoutId = setTimeout(callback, delay)
            }
        })()

        const addNicknameToAtomicScopes = (nickname: string) => {
            atomicScopesUtils.addAtomicScope(extArgs, getAtomicScopeNicknameKey(nickname))
            debounce(() => clearAtomicNicknames(), CLEAR_NICKNAMES_DEBOUNCE_TIME)
        }
        const afterComponentAdd = ({componentDefinition}: ComponentAfterAddDataEvent) => {
            const nickname = componentDefinition.connections?.items?.find(
                (e: IConnectionItem) => e.type === 'WixCodeConnectionItem'
            )?.role
            if (nickname) {
                addNicknameToAtomicScopes(nickname)
            }
        }

        const {eventEmitter, EVENTS, extensionAPI} = extArgs
        const {hooks} = extensionAPI as HooksExtensionApi
        eventEmitter.on(EVENTS.ROUTERS.AFTER_ADD, afterRouterAdd)
        eventEmitter.on(EVENTS.ROUTERS.BEFORE_UPDATE, beforeRouterUpdate)
        eventEmitter.on(EVENTS.ROUTERS.BEFORE_REMOVE, beforeRouterRemove)
        eventEmitter.on(EVENTS.PAGE.DATA_UPDATE_BEFORE, beforePageDataChanged)
        eventEmitter.on(EVENTS.PAGE.PAGE_DATA_ADDED, afterPageAdd)
        hooks.registerHook(COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id, afterComponentAdd)
    }

    return {
        name: 'atomicScopes',
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
