import {variants, utils} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                variants: {
                    get: publicMethodUtils.defineGetter(variants.getByComponentAndType),
                    getPointer: publicMethodUtils.defineGetter(variants.getPointerWithVariants),
                    getPointerWithoutVariants: publicMethodUtils.defineGetter(variants.getPointerWithoutVariants),
                    getAllAffectingVariantsGroupedByVariantType: publicMethodUtils.defineGetter(
                        variants.getAllAffectingVariantsGroupedByVariantType
                    ),
                    getAllOwnedVariantsGroupedByVariantType: publicMethodUtils.defineGetter(
                        variants.getAllOwnedVariantsGroupedByVariantType
                    ),
                    getAllAffectingVariantsForPresets: publicMethodUtils.defineGetter(
                        variants.getAllAffectingVariantsForPresets
                    ),
                    getEnabled: publicMethodUtils.defineGetter(variants.getComponentEnabledVariants),
                    hasOverrides: publicMethodUtils.defineGetter(variants.hasOverridesForNamespace),
                    getContexts: publicMethodUtils.extensionPublicAPI.getter('components.variants.getContexts'),
                    enable: publicMethodUtils.defineDataManipulationAction(variants.enableForComponent),
                    disable: publicMethodUtils.defineDataManipulationAction(variants.disableForComponent)
                }
            },
            variants: {
                create: publicMethodUtils.defineDataManipulationAction(variants.create, {
                    isUpdatingAnchors: utils.NO,
                    getReturnValue: variants.getVariantToAddRef
                }),
                get: publicMethodUtils.defineGetter(variants.getData),
                remove: publicMethodUtils.defineDataManipulationAction(variants.remove),
                hasOverrides: publicMethodUtils.defineGetter(variants.hasOverrides),
                getComponentsWithOverrides: publicMethodUtils.defineGetter(variants.getComponentsWithOverrides),
                enable: publicMethodUtils.defineDataManipulationAction(variants.enable),
                disable: publicMethodUtils.defineDataManipulationAction(variants.disable),
                getHoverType: publicMethodUtils.defineGetter(variants.getHoverType),
                getMobileType: publicMethodUtils.defineGetter(variants.getMobileType),
                getPresetType: publicMethodUtils.defineGetter(variants.getPresetType),
                mobile: {
                    get: publicMethodUtils.defineGetter(variants.getMobileVariant),
                    create: publicMethodUtils.defineDataManipulationAction(variants.createMobileVariant, {
                        isUpdatingAnchors: utils.DONT_CARE,
                        getReturnValue: variants.getMobileVariantRef
                    })
                },
                getVariantOwner: publicMethodUtils.defineGetter(variants.getVariantOwner),
                fromPointer: publicMethodUtils.defineGetter(variants.getVariantsFromPointer)
            }
        }
    }
}
